import { serverApi } from '..';
import { InsulationThicknessDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const insulationThicknessApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getInsulationThicknesses: builder.query<InsulationThicknessDto[], InsulationThicknessQueryParams>({
            query: (params) => buildSearchQueryRoute(`insulationthickness`, params),
            providesTags: [{ type: 'Insulation Thickness' }]
        })
    })
});

export const {
    useGetInsulationThicknessesQuery
} = insulationThicknessApi;

export interface InsulationThicknessQueryParams {
    includeInactive?: boolean;
}