import { AddCircle, History } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormLabel, Grid, IconButton, Switch, Typography } from '@mui/material';
import React from 'react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { LeakLocationDto, emptyGuid } from '../../../models';
import { ContactDto } from '../../../models/ContactDto';
import { useGetContactsQuery } from '../../../store/apis/contact-api';
import { useGetLeakLocationsByFacilityQuery } from '../../../store/apis/leak-location-api';
import { roundNumber } from '../../../util/roundNumber';
import { AddContactDialog } from '../../../Views/Contact';
import { IWorkOrderContractorProps } from './types';
import { WorkOrderLeakLocationDialog } from './WorkOrderLeakLocationDialog';
import { WorkOrderLeakLocationHistoryDialog } from './WorkOrderLeakLocationHistoryDialog';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { FormNumberInput } from '../FormFields';

export const WorkOrderContractors: FunctionComponent<IWorkOrderContractorProps> = React.memo((props) => {
    const { contractor, facilityId, setFormChanged, initValues, contractorInfo, setContractorInfo, readOnly } = props;
    const [technician, setTechnician] = useState<ContactDto | null>(initValues?.contact ?? null);
    const [leakLocation, setLeakLocation] = useState<LeakLocationDto | null>(initValues?.leakLocation ?? null);
    const [serviceDays, setServiceDays] = useState<number | undefined>(initValues ? initValues.daysToService : 0);
    const [reportDays, setReportDays] = useState<number | undefined>(initValues ? initValues.daysToReport : 0);
    const [subKInvoiceAmount, setSubKInvoiceAmount] = useState<number | undefined>(initValues ? initValues.subKInvoiceAmount : 0);
    const [laborHours, setLaborHours] = useState<number | undefined>(initValues ? initValues.laborHours : 0);
    const [laborRateMultiplier, setLaborRateMultiplier] = useState<number | undefined>(initValues ? initValues.laborRateMultiplier : 0);
    const [roofSmartLaborAmount, setRoofSmartLaborAmount] = useState<number | undefined>(initValues ? initValues.roofSmartLaborAmount : 0);
    const [roofSmartLaborAmountMultiplier, setRoofSmartLaborAmountMultiplier] = useState<number | undefined>(
        initValues ? initValues.roofSmartLaborMultiplier : 0
    );
    const [laborRate, setLaborRate] = useState<number | undefined>(initValues ? initValues.laborRate : 0);
    const [isProposal, setIsProposal] = useState(initValues ? initValues.isProposal : false);
    const [isRepeatLeak, setIsRepeatLeak] = useState(initValues ? initValues.isRepeatLeak : false);
    const [leakLoctionDialogOpen, setLeakLocationDialogOpen] = useState(false);
    const [leakLocationHistoryDialogOpen, setLeakLocationHistoryDialogOpen] = useState(false);
    const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false);

    useEffect(() => {
        if (!contractor) {
            setTechnician(null);
            setLeakLocation(null);
            setServiceDays(0);
            setReportDays(0);
            setSubKInvoiceAmount(0);
            setLaborHours(0);
            setLaborRateMultiplier(0);
            setRoofSmartLaborAmount(0);
            setRoofSmartLaborAmountMultiplier(0);
            setLaborRate(0);
            setIsProposal(false);
            setIsRepeatLeak(false);
            if (setContractorInfo) {
                setContractorInfo(undefined);
            }
        }
    }, [contractor, setContractorInfo]);

    const { data: contacts, isLoading: contactsLoading } = useGetContactsQuery({
        parentId: contractor?.id ?? emptyGuid,
        params: {
            searchText: '',
            includeInactive: true,
            includeOther: false,
        },
    });

    const { data: leakLocations, isLoading: leakLocationsLoading } = useGetLeakLocationsByFacilityQuery({
        facilityId: facilityId ?? '',
        includeInactive: true,
    });

    const isMaxDigits = useCallback((value: number, digitThreshold: number) => {
        if (value > digitThreshold) return true;
        else return false;
    }, []);

    const handleCalculation = useCallback((changedValue: string, newNumber: number) => {
        if (changedValue === 'ROOFSMART_MULTIPLIER') {
            setRoofSmartLaborAmountMultiplier(newNumber);
            setRoofSmartLaborAmount(roundNumber((laborHours ?? 0) * (newNumber ?? 0), 2));
            if (setContractorInfo) {
                setContractorInfo({
                    ...contractorInfo,
                    roofSmartLaborMultiplier: newNumber,
                    roofSmartLaborAmount: roundNumber((laborHours ?? 0) * (newNumber ?? 0), 2),
                });
            }
        } else if (changedValue === 'ROOFSMART_AMOUNT') {
            setRoofSmartLaborAmount(roundNumber(newNumber, 2));
            setRoofSmartLaborAmountMultiplier(roundNumber((newNumber ?? 0) / (laborHours ?? 1), 5));
            if (setContractorInfo) {
                setContractorInfo({
                    ...contractorInfo,
                    roofSmartLaborMultiplier: roundNumber((newNumber ?? 0) / (laborHours ?? 1), 5),
                    roofSmartLaborAmount: roundNumber(newNumber, 2),
                });
            }
        } else if (changedValue === 'RATE_MUlTIPLIER') {
            setLaborRateMultiplier(newNumber);
            setLaborRate(roundNumber((laborHours ?? 0) * (newNumber ?? 0), 2));
            if (setContractorInfo) {
                setContractorInfo({
                    ...contractorInfo,
                    laborRateMultiplier: newNumber,
                    laborRate: roundNumber((laborHours ?? 0) * (newNumber ?? 0), 2),
                });
            }
        } else if (changedValue === 'LABOR_RATE') {
            setLaborRate(roundNumber(newNumber, 2));
            setLaborRateMultiplier(roundNumber((newNumber ?? 0) / (laborHours ?? 1), 5));
            if (setContractorInfo) {
                setContractorInfo({
                    ...contractorInfo,
                    laborRateMultiplier: roundNumber((newNumber ?? 0) / (laborHours ?? 1), 5),
                    laborRate: roundNumber(newNumber, 2),
                });
            }
        } else if (changedValue === 'LABOR_HOURS') {
            setLaborHours(newNumber);
            setRoofSmartLaborAmount(roundNumber((newNumber ?? 0) * (roofSmartLaborAmountMultiplier ?? 0), 2));
            setLaborRate(roundNumber((newNumber ?? 0) * (laborRateMultiplier ?? 0), 2));
            if (setContractorInfo) {
                setContractorInfo({
                    ...contractorInfo,
                    laborHours: newNumber,
                    roofSmartLaborAmount: roundNumber((newNumber ?? 0) * (roofSmartLaborAmountMultiplier ?? 0), 2),
                    laborRate: roundNumber((newNumber ?? 0) * (laborRateMultiplier ?? 0), 2),
                });
            }
        }
    }, [contractorInfo, laborHours, laborRateMultiplier, roofSmartLaborAmountMultiplier, setContractorInfo]);

    const handleAddContactDialogClose = useCallback((contact: ContactDto) => {
        if (contact && contact.contractorId === contractor?.id) {
            setTechnician(contact);
        }
        setIsAddContactDialogOpen(false);
    }, [contractor?.id]);

    const handleTechnicianChange = useCallback((_event: any, value: ContactDto | null) => {
        setFormChanged!(true);
        setTechnician(value);
        if (setContractorInfo) {
            setContractorInfo({ ...contractorInfo, technicianId: value?.id ?? undefined });
        }
    }, [contractorInfo, setContractorInfo, setFormChanged]);

    const handleLeakLocationChange = useCallback((_event: any, value: LeakLocationDto | null) => {
        setFormChanged!(true);
        setLeakLocation(value);
        if (setContractorInfo) {
            setContractorInfo({ ...contractorInfo, leakLocationId: value?.id ?? undefined });
        }
    }, [contractorInfo, setContractorInfo, setFormChanged]);

    const handleServiceDaysChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        if (!isMaxDigits(event.target.value.length, 3) && !event.target.value.includes('.')) {
            setServiceDays(event.target.valueAsNumber);
            if (setContractorInfo) {
                setContractorInfo({ ...contractorInfo, serviceDays: event.target.valueAsNumber });
            }
        }
    }, [contractorInfo, isMaxDigits, setContractorInfo, setFormChanged]);

    const handleReportDaysChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        if (!isMaxDigits(event.target.value.length, 3) && !event.target.value.includes('.')) {
            setReportDays(event.target.valueAsNumber);
            if (setContractorInfo) {
                setContractorInfo({ ...contractorInfo, reportDays: event.target.valueAsNumber });
            }
        }
    }, [contractorInfo, isMaxDigits, setContractorInfo, setFormChanged]);

    const handleSubKInvoiceAmountChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        if (!isMaxDigits(event.target.value.length, 8)) {
            setSubKInvoiceAmount(roundNumber(event.target.valueAsNumber, 2));
            if (setContractorInfo) {
                setContractorInfo({ ...contractorInfo, subKInvoiceAmount: roundNumber(event.target.valueAsNumber, 2) });
            }
        }
    }, [contractorInfo, isMaxDigits, setContractorInfo, setFormChanged]);

    const handleLaborHoursChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        if (!isMaxDigits(event.target.value.length, 6)) {
            handleCalculation('LABOR_HOURS', event.target.valueAsNumber);
        }
    }, [handleCalculation, isMaxDigits, setFormChanged]);

    const handleLaborRateMultiplierChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        handleCalculation('RATE_MUlTIPLIER', event.target.valueAsNumber);
    }, [handleCalculation, setFormChanged]);

    const handleRoofSmartLaborAmountChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        handleCalculation('ROOFSMART_AMOUNT', event.target.valueAsNumber);
    }, [handleCalculation, setFormChanged]);

    const handleRoofSmartLaborAmountMultiplierChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        handleCalculation('ROOFSMART_MULTIPLIER', event.target.valueAsNumber);
    }, [handleCalculation, setFormChanged]);

    const handleLaborRateChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        handleCalculation('LABOR_RATE', event.target.valueAsNumber);
    }, [handleCalculation, setFormChanged]);

    const handleRepeatLeakToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        setIsRepeatLeak(event.target.checked);
        if (setContractorInfo) {
            setContractorInfo({ ...contractorInfo, isRepeatLeak: event.target.checked });
        }
    }, [contractorInfo, setContractorInfo, setFormChanged]);

    const handleProposalToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged!(true);
        setIsProposal(event.target.checked);
        if (setContractorInfo) {
            setContractorInfo({ ...contractorInfo, isProposal: event.target.checked });
        }
    }, [contractorInfo, setContractorInfo, setFormChanged]);

    const handleLeakLocationDialogClose = () => {
        setLeakLocationDialogOpen(false);
    };

    const handleAddLeakLocation = () => {
        setLeakLocationDialogOpen(true);
    };

    const handleViewLeakLocationHistory = () => {
        setLeakLocationHistoryDialogOpen(true);
    };

    const handleLeakLocationHistoryDialogClose = () => {
        setLeakLocationHistoryDialogOpen(false);
    };

    return (
        <Grid item container direction='column' sx={{ padding: '20px' }} spacing={2}>
            <Grid item container direction='row' alignItems='center' xs={6} spacing={4} wrap='nowrap'>
                <Grid item xs={4}>
                    <FormControl fullWidth disabled={readOnly}>
                        <FormLabel>Technician</FormLabel>
                        <IEntityAutocomplete
                            options={contacts?.pageResults}
                            onChange={handleTechnicianChange}
                            value={technician}
                            getOptionLabel={(option: ContactDto) => `${option.firstName} ${option.lastName}`}
                            isLoading={contactsLoading}
                            disabled={readOnly}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <IconButton color='primary' size='large' sx={{ marginTop: '10px' }} disabled={readOnly} onClick={() => {
                        if (initValues?.contractorId) {
                            setIsAddContactDialogOpen(true);
                        }
                    }}>
                        <AddCircle fontSize='inherit' />
                    </IconButton>
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={serviceDays}
                        onChange={handleServiceDaysChange}
                        disabled={readOnly}
                        label='Days to Service'
                        name='daysToService'
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={reportDays}
                        onChange={handleReportDaysChange}
                        disabled={readOnly}
                        label='Days to Report'
                        name='daysToReport'
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={subKInvoiceAmount}
                        onChange={handleSubKInvoiceAmountChange}
                        disabled={readOnly}
                        label='SubK Invoice Amt'
                        name='subk'
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Switch checked={isRepeatLeak} onChange={handleRepeatLeakToggleChange} />}
                        label='Repeat Leak'
                        labelPlacement='start'
                        sx={{ marginLeft: '0px' }}
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Switch checked={isProposal} onChange={handleProposalToggleChange} />}
                        label='Proposal'
                        labelPlacement='start'
                        disabled={readOnly}
                    />
                </Grid>
            </Grid>
            <Grid item container direction='row' xs={6} spacing={8} alignItems={'center'}>
                <Grid item container xs={7} spacing={2}>
                    <Grid item xs={2}>
                        <FormNumberInput
                            value={laborHours}
                            onChange={handleLaborHoursChange}
                            disabled={readOnly}
                            label='Labor Hours'
                            name='laborHours'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormNumberInput
                            value={roofSmartLaborAmountMultiplier}
                            onChange={handleRoofSmartLaborAmountMultiplierChange}
                            disabled={readOnly}
                            label='Multiplier'
                            name='multiplier'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormNumberInput
                            value={roofSmartLaborAmount}
                            onChange={handleRoofSmartLaborAmountChange}
                            disabled={readOnly}
                            label='RoofSMART Labor'
                            name='roofSmartLabor'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormNumberInput
                            value={laborRateMultiplier}
                            onChange={handleLaborRateMultiplierChange}
                            disabled={readOnly}
                            label='Multiplier'
                            name='laborRateMultiplier'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormNumberInput
                            value={laborRate}
                            onChange={handleLaborRateChange}
                            disabled={readOnly}
                            label='Labor Rate'
                            name='laborRate'
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={2}>
                    <Typography sx={{ fontWeight: 'bold', marginTop: '15px', color: readOnly ? 'rgba(0, 0, 0, 0.38)' : 'default' }}>
                        Labor Savings:{' '}
                        {(laborRate ?? 0) - (roofSmartLaborAmount ?? 0) > 0
                            ? '$' + ((laborRate ?? 0) - (roofSmartLaborAmount ?? 0)).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            : '$0.00'}
                    </Typography>
                </Grid>
                <Grid item container direction='row' xs={3} alignItems={'center'}>
                    <Grid item container xs={8}>
                        <FormControl fullWidth disabled={readOnly}>
                            <FormLabel>Leak Location</FormLabel>
                            <IEntityAutocomplete
                                options={leakLocations?.pageResults}
                                onChange={handleLeakLocationChange}
                                value={leakLocation}
                                getOptionLabel={(option: LeakLocationDto) => option.name}
                                isLoading={leakLocationsLoading}
                                disabled={readOnly}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container xs={4} spacing={1}>
                        <Grid item xs={6}>
                            <IconButton
                                size='large'
                                sx={{ marginTop: '15px' }}
                                color='primary'
                                onClick={handleAddLeakLocation}
                                disabled={readOnly}>
                                <AddCircle fontSize='inherit' />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton
                                size='large'
                                sx={{ marginTop: '15px' }}
                                color='primary'
                                onClick={handleViewLeakLocationHistory}
                                disabled={!contractor || leakLocation !== null}>
                                <History fontSize='inherit' />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <WorkOrderLeakLocationDialog
                open={leakLoctionDialogOpen}
                onClose={handleLeakLocationDialogClose}
                facilityId={facilityId}
                contractorInfo={contractorInfo}
                setLeakLocation={setLeakLocation}
                setContractorInfo={setContractorInfo}
                setFormChanged={setFormChanged}
                readOnly={readOnly}
            />
            <WorkOrderLeakLocationHistoryDialog
                open={leakLocationHistoryDialogOpen}
                onClose={handleLeakLocationHistoryDialogClose}
                facilityId={facilityId}
                leakLocationId={leakLocation?.id ?? undefined} />
            <AddContactDialog
                open={isAddContactDialogOpen}
                onClose={handleAddContactDialogClose}
                contractor={contractor}
            />
        </Grid>
    );
});