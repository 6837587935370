import { useAuth0 } from '@auth0/auth0-react';
import { AccountBox, Assignment, AttachMoney, Build, Folder, Handyman, Home, InsertChart, People } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { AuthRoles } from '../Components/core/AuthRoles';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import NavigationDrawer, { NavigationDrawerRouteDTO } from '../Components/core/NavigationDrawer';
import { useLazyGetUserByAuth0IdQuery } from '../store/apis/user-api';

export const NavigationDrawerContainer: React.FunctionComponent = (props) => {
    const { user, isLoading, error } = useAuth0();
    const [userRole, setUserRole] = useState('');
    const [getUserData, { data: userData }] = useLazyGetUserByAuth0IdQuery();

    useEffect(() => {
        const rolesFieldName = process.env.REACT_APP_AUTH0_Roles!;
        if (user && user[rolesFieldName].length > 0) {
            setUserRole(user[rolesFieldName][0]);
        }
        if (user?.sub) {
            getUserData(user?.sub);
        }
    }, [user, getUserData]);

    const employeeRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <Home />,
            name: 'Home',
            route: '/',
        },
        {
            icon: <People />,
            name: 'Clients',
            route: '/clients',
            requiredPermissions: ['nav:clients'],
        },
        {
            icon: <AttachMoney />,
            name: 'Budgets',
            route: '/budgets',
            requiredPermissions: ['nav:budgets'],
        },
        {
            icon: <Assignment />,
            name: 'Work Orders',
            route: '/workorders',
            requiredPermissions: ['nav:workorders'],
        },
        {
            icon: <Handyman />,
            name: 'Capital Projects',
            route: '/capitalprojects',
            requiredPermissions: ['nav:capitalprojects'],
        },
        {
            icon: <InsertChart />,
            name: 'Client Reports',
            route: '/clientreports',
            requiredPermissions: ['nav:reports'],
        },
    ];

    const adminRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <Build />,
            name: 'Contractors',
            route: '/contractors',
            requiredPermissions: ['nav:contractors'],
        },
        {
            icon: <AccountBox />,
            name: 'Users',
            route: '/users',
            requiredPermissions: ['nav:users'],
        },
        {
            icon: <Folder />,
            name: 'Default Folders',
            route: '/defaultFolders',
            requiredPermissions: ['nav:defaultDocumentFolders'],
        }
    ];

    const clientManagerPages: NavigationDrawerRouteDTO[] = [
        {
            icon: <Home />,
            name: 'Home',
            route: `/clients/${userData?.clientId}`,
        },
    ];

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <p>{error.message}</p>;
    }

    return (
        <div>
            <NavigationDrawer
                routes={userRole ? (userRole === AuthRoles.ClientManager ? clientManagerPages : employeeRoutes) : []}
                userRole={userRole}
                adminRoutes={adminRoutes}>
                {props.children}
            </NavigationDrawer>
        </div>
    );
};
