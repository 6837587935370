import { FunctionComponent, useMemo } from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import { ReportProblem, RemoveRedEye } from '@mui/icons-material';
import { IClientCardProps } from './types';
import {
    useGetClientWorkOrdersQuery
} from '../../../store/apis/work-order-api';
import { useGetWorkOrderTypesQuery } from '../../../store/apis/work-order-type-api';

export interface IOutstandingProposalsCardProps extends IClientCardProps {
    handleViewProposals: () => void;
}

export const OutstandingProposalsCard: FunctionComponent<IOutstandingProposalsCardProps> = (props) => {
    const { clientId, facility, handleViewProposals } = props;

    const { data: types } = useGetWorkOrderTypesQuery({
        pageSize: 100000,
    });

    const workOrderType = useMemo(() => types?.pageResults.find((t) => t.name === 'Proposal Acceptance Pending')!, [types]);

    const { data } = useGetClientWorkOrdersQuery({
        parentId: clientId,
        params: {
            includeInactive: false,
            clientId: clientId,
            workOrderTypeId: workOrderType?.id,
            facilityId: facility?.id
        },
    });

    const numOutstandingProposals = useMemo(() => data?.totalQueryResults ?? 0, [data]);
    const warningOutstandingProposalsText = useMemo(() => `${numOutstandingProposals ?? 0} Outstanding Proposals`, [numOutstandingProposals]);
    const totalCostOutstandingProposals = useMemo(() => data?.pageResults.reduce((acc, wo) => acc + (wo.proposedCost ?? 0), 0) ?? 0, [data]);
    const totalCostText = useMemo(() => `Total: $${totalCostOutstandingProposals?.toFixed(2) ?? 0}`, [totalCostOutstandingProposals]);

    if (numOutstandingProposals === 0) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <Card variant='outlined' sx={{ height: 'auto', minHeight: '100px', backgroundColor: '#FA7602' }}>
                <Grid container direction='row' alignItems='stretch' justifyContent='space-between'>
                    <Grid item container direction='row' xs={6} alignItems='center'>
                        <ReportProblem sx={{ fontSize: 30, color: 'white', margin: '10px' }} />
                        <Typography variant='h6' sx={{ color: 'white', margin: '10px' }}>
                            {warningOutstandingProposalsText}
                        </Typography>
                    </Grid>
                    <Grid item container direction='row' xs={6} alignItems='center' justifyContent='flex-end'>
                        <Button
                            onClick={handleViewProposals}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#cf670c', // Changes the background color on hover
                                    // Adjust the color and opacity as needed
                                }
                            }}
                        >
                            <RemoveRedEye sx={{ fontSize: 30, color: 'white', margin: '10px' }} />
                            <Typography variant='h6' sx={{ color: 'white', margin: '10px' }}>
                                View Proposals
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant='h6' sx={{ color: 'white', margin: '10px' }}>
                        {totalCostText}
                    </Typography>
                </Grid>
            </Card>
        </Grid>

    );
};
