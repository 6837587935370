import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { UserForm } from '../../Components/Forms/UserForm';
import { UserDto } from '../../models';
import { useGetUserQuery, useUpdateUserMutation } from '../../store/apis/user-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditUserView: FunctionComponent = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: user, isLoading, error, refetch } = useGetUserQuery(id!);
    const [updateUser, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateUserMutation();

    useFailedUpdateSnackbar('user', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('User', isUpdateSuccess, () => {
        navigate(0);
    });

    const handleSave = (values: UserDto) => {
        updateUser(values);
    };

    const handleCancel = () => {
        navigate('/users');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <UserForm save={handleSave} cancel={handleCancel} initValues={user} />;
};
