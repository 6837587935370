import {
    MenuItem,
    Select
} from '@mui/material';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { BaseDto } from '../../../models';
import { IEntitySelectProps } from './types';

export const IEntitySelect: FunctionComponent<IEntitySelectProps> = (props) => {
    const { value, onChange, menuItems, getItem, getItemText, onBlur, error, required, disabled } = props;
    const [selectValue, setSelectValue] = useState(value);

    useEffect(() => {
        if (value !== selectValue) {
            setSelectValue(value);
        }
    }, [selectValue, value]);

    const onChangeCallback = useCallback(onChange, [onChange]);

    const getMenuItem = useCallback((item: BaseDto) => {
        if (item.id === value || item.isActive) {
            if (getItem != null) {
                return < MenuItem key={item.id} value={item.id} >{getItem(item)}</MenuItem>;
            } else if (getItemText != null) {
                return < MenuItem key={item.id} value={item.id} >
                    {getItemText(item) + (item.isActive ? "" : " (Inactive)")}
                </MenuItem>;
            } else {
                return <></>;
            }
        }
    }, [value, getItemText, getItem]);

    const getSelect = useCallback(() => {
        return (
            <Select
                value={selectValue}
                onChange={(event, child) => {
                    setSelectValue(event.target.value);
                    onChangeCallback(event, child);
                }}
                onBlur={onBlur}
                error={error}
                required={required}
                disabled={disabled}
            >
                <MenuItem key='none' value=''>
                    <em>None</em>
                </MenuItem>
                {menuItems && menuItems.map((item: BaseDto) => getMenuItem(item))}
            </Select >
        );
    }, [disabled, error, getMenuItem, menuItems, onBlur, onChangeCallback, required, selectValue]);

    return (
        <>{getSelect()}</>
    );
};
