import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { WarrantyForm } from '../../Components/Forms/WarrantyForm';
import { WarrantyDto } from '../../models';
import { useGetClientQuery } from '../../store/apis/client-api';
import { useCreateWarrantyMutation } from '../../store/apis/warranty-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddWarrantyView: FunctionComponent = (props) => {
    const { clientId } = useParams();
    const navigate = useNavigate();
    const { data: client, error: clientError, isLoading: clientLoading, refetch: clientRefetch } = useGetClientQuery(clientId!);
    const [createWarranty, { isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateWarrantyMutation();

    useFailedCreateSnackbar('warranty', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Warranty', isCreateSuccess, () => {
        navigate(`/clients/${clientId!}`);
    });

    const handleSave = (values: WarrantyDto) => {
        createWarranty(values);
    };

    const handleCancel = () => {
        navigate(`/clients/${clientId!}`);
    };

    if (clientLoading) {
        return <LoadingIndicator />;
    }

    if (clientError) {
        return <ApiError onReloadClick={clientRefetch} />;
    }

    return <WarrantyForm client={client!} save={handleSave} cancel={handleCancel} />;
};
