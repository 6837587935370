import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { ContactForm } from '../../Components/Forms/ContactForm';
import { ContactDto } from '../../models/ContactDto';
import { useGetContactQuery, useUpdateContactMutation } from '../../store/apis/contact-api';
import { useGetContractorQuery } from '../../store/apis/contractor-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditContactView: FunctionComponent = (props) => {
    const { contractorId, id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading: contactLoading, error: contactError, refetch: contactRefetch } = useGetContactQuery({ parentId: contractorId!, childId: id! });
    const [updateContact, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateContactMutation();
    const { data: contractor, isLoading: contractorLoading, isError: contractorError, refetch: contractorRefetch } = useGetContractorQuery(contractorId!);

    useFailedUpdateSnackbar('contact', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Contact', isUpdateSuccess);

    const handleSave = (values: ContactDto) => {
        updateContact(values);
    };

    const handleCancel = () => {
        navigate(`/contractors/edit/${contractorId}`);
    };

    if (contactLoading || contractorLoading) {
        return <LoadingIndicator />;
    }

    if (contactError) {
        return <ApiError onReloadClick={contactRefetch} />;
    }

    if (contractorError) {
        return <ApiError onReloadClick={contractorRefetch} />;
    }

    return <ContactForm contractor={contractor!} save={handleSave} cancel={handleCancel} initValues={data} />;
};
