import { ClientDto, FacilityDto } from '../models';
import { ContractorDto } from '../models/ContractorDto';

export const createAddressStringFromFacility = (facility: FacilityDto | null | undefined, client: ClientDto | null | undefined, billingClient: ClientDto | ContractorDto | null | undefined) => {
    if (!facility) {
        return '';
    }
    var addressString = (
        'Facility: ' +
        facility?.name +
        ', ' +
        facility?.addressLine1 +
        '' +
        (facility?.addressLine2 ? ' ' + facility?.addressLine2 : '') +
        ', ' +
        facility?.city +
        ', ' +
        facility?.state +
        ' ' +
        facility?.zipCode
    );

    if (!!client) {
        addressString = (
            addressString + 
            '\nOwner: ' +
            client?.name +
            ', ' +
            client?.addressLine1 +
            '' +
            (client?.addressLine2 ? ' ' + client?.addressLine2 : '') +
            ', ' +
            client?.city +
            ', ' +
            client?.state +
            ' ' +
            client?.zipCode
        );
    }

    if (!!billingClient) {
        addressString = (
            addressString + 
            '\nClient: ' +
            billingClient?.name +
            ', ' +
            billingClient?.addressLine1 +
            '' +
            (billingClient?.addressLine2 ? ' ' + billingClient?.addressLine2 : '') +
            ', ' +
            billingClient?.city +
            ', ' +
            billingClient?.state +
            ' ' +
            billingClient?.zipCode
        );
    }

    return addressString;
};
