import { useMemo } from "react";
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from "../../../../util";
import { WorkOrderDto } from "../../../../models";
import { isLocked } from "../WorkOrderDispatchSection";
import { useCreateDispatchMutation, useRecallDispatchMutation, useUnlockDispatchMutation } from "../../../../store/apis/dispatch-api";
import { DispatchListViewDto } from "../../../../models/DispatchListViewDto";

export function useWorkOrderActions(workOrder?: WorkOrderDto, contractorId?: string, currentDispatch?: DispatchListViewDto) {
    const [recallDispatch, { isSuccess: isRecallDispatchSuccess, isError: isRecallDispatchError, reset: resetRecallDispatch }] = useRecallDispatchMutation();
    const [createDispatch, { isSuccess: isCreateDispatchSuccess, isError: isCreateDispatchError, reset: resetCreateDispatch }] = useCreateDispatchMutation();
    const [unlockDispatch, { isSuccess: isUnlockDispatchSuccess, isError: isUnlockDispatchError, reset: resetUnlockDispatch }] = useUnlockDispatchMutation();

    const canView: boolean = useMemo(() => currentDispatch !== undefined, [currentDispatch]);
    const canRecall: boolean = useMemo(() => !!(currentDispatch && currentDispatch.isActive && !isLocked(currentDispatch)), [currentDispatch]);
    const canUnlock: boolean = useMemo(() => !!(currentDispatch && currentDispatch.isActive && isLocked(currentDispatch)), [currentDispatch]);
    const canCreate: boolean = useMemo(() => !!(workOrder && workOrder.contractor && contractorId && workOrder.contractorId === contractorId), [contractorId, workOrder]);

    useFailedActionSnackbar('unlock', 'work order', isUnlockDispatchError, resetUnlockDispatch);
    useSuccessfulActionSnackbar('Unlocked', 'Work order', isUnlockDispatchSuccess, () => {
        resetUnlockDispatch();
    });

    useFailedActionSnackbar('dispatch', 'work order', isCreateDispatchError, resetCreateDispatch);
    useSuccessfulActionSnackbar('Dispatched ' + (workOrder?.contractor?.name ? ' to ' + workOrder?.contractor?.name : ''), 'Work order', isCreateDispatchSuccess, () => {
        resetCreateDispatch();
    });

    useFailedActionSnackbar('recall', 'work order', isRecallDispatchError, resetRecallDispatch);
    useSuccessfulActionSnackbar('Recalled ' + (workOrder?.contractor?.name ? ' from ' + workOrder?.contractor?.name : ''), 'Work order', isRecallDispatchSuccess, () => {
        resetRecallDispatch();
    });

    return {
        recallDispatch,
        createDispatch,
        unlockDispatch,
        canView,
        canRecall,
        canUnlock,
        canCreate
    };
}