import { format } from 'date-fns';
import { DataTableColumn } from '../Components/core/DataTable';
import { CapitalProjectDto, WorkOrderDto } from '../models';
import { IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';
import { DownloadIconForWorkOrder } from '../Components/Dashboard/ClientDashboard/DownloadIconForWorkOrder';
import { StatusConverterMap } from '../Components/Dashboard/ClientDashboard/StatusConverterMap';

const mapStatus = (extendedTableView: boolean, status: string) => {
    if (extendedTableView) {
        return status;
    }

    return StatusConverterMap.convert(status);
};

const downloadPdf = (url?: string) => {
    if (url) {
        window.open(url);
    }
};

export const getStandardWorkOrderTableColumns = (userCanViewWorkOrderCosts: boolean, extendedTableView: boolean, woNumberLabel: 'WO #' | 'Project #', isCapitalProjectView?: boolean, isOutstandingProposal?: boolean) => {
    const columns: DataTableColumn<CapitalProjectDto | WorkOrderDto>[] = [];
    const addColumn = (key: string, label: string, sortKey: string, fieldMapper?: (row: CapitalProjectDto | WorkOrderDto) => any, align?: "left" | "center" | "right" | "justify" | "inherit" | undefined, unsorted?: boolean) =>
        columns.push({ key, label, sortKey, fieldMapper, align, unsorted });

    addColumn('woNumber', woNumberLabel, 'WO_NUMBER');
    extendedTableView && columns.push({ key: 'client', label: 'Owner', sortKey: 'CLIENT', width: '75px', fieldMapper: (row) => row.facilityClientName });
    addColumn('facility', 'Facility', 'FACILITY', (row) => row.facilityName ?? '');
    isOutstandingProposal || addColumn('type', 'Type', 'TYPE', (row) => row.type?.name ?? '');
    extendedTableView && columns.push({ key: 'division', label: 'Division', sortKey: 'DIVISION', width: '75px', fieldMapper: (row) => (row.division?.name) });
    extendedTableView || isCapitalProjectView || addColumn('poNumber', 'PO #', 'PO_NUMBER');
    isOutstandingProposal || addColumn('dispatchDate', isCapitalProjectView ? 'Completion Date' : 'Dispatch Date', 'DISPATCH_DATE', (row) => row.dispatchDate ? format(new Date(row.dispatchDate.toString()), 'M/d/yyyy') : '');
    isCapitalProjectView || isOutstandingProposal || addColumn('scheduledDate', 'Scheduled Date', 'SCHEDULED_DATE', (row) => row.scheduledDate ? format(new Date(row.scheduledDate.toString()), 'M/d/yyyy') : '');
    isCapitalProjectView && addColumn('roofAreas', 'Roof Areas', 'ROOF_AREAS');
    extendedTableView && columns.push({ key: 'dispatchId', label: 'Dispatch ID', sortKey: 'DISPATCH', width: '75px', fieldMapper: (row) => row.contractor?.dispatchId });
    if (userCanViewWorkOrderCosts) {
        isOutstandingProposal || columns.push({
            key: 'cost',
            label: 'Cost',
            sortKey: 'COST',
            align: 'right',
            fieldMapper: (row) =>
                row.cost
                    ? row.cost.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                    })
                    : '',
        });
        isOutstandingProposal && columns.push({
            key: 'proposedCost',
            label: 'Proposed Cost',
            sortKey: 'PROPOSED_COST',
            align: 'right',
            fieldMapper: (row) =>
                row.proposedCost
                    ? row.proposedCost.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                    })
                    : '',
        });
    }
    isOutstandingProposal || addColumn('status', 'Status', 'STATUS', (row) => mapStatus(extendedTableView, row.status?.name ?? ''));
    isOutstandingProposal || (!extendedTableView && !isCapitalProjectView && addColumn('closeOutReportPdfLink', 'Close Out Report', 'CLOSE_OUT_REPORT_PDF_LINK', (row) => <IconButton disabled={!row.closeOutReportPdfLink} onClick={() => downloadPdf(row.closeOutReportPdfLink)}><Download /></IconButton>, 'center', true));
    isOutstandingProposal && addColumn('proposalDocumentPdfLink', 'Proposal Document', 'PROPOSAL_DOCUMENT_PDF_LINK', (row) => <DownloadIconForWorkOrder workOrderId={row.id} />, 'center', true);
    return columns;
};