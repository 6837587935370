import { FunctionComponent, useState } from 'react';
import { ClientDto, FacilityDto } from '../../../models';
import { useGetDocumentsQuery } from '../../../store/apis/document-api';
import { ApiError } from '../../core/ApiError';
import { PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { DocumentsFormSection } from '../../core/Documents';
import { useGetRoofSectionsQuery } from '../../../store/apis/roof-section-api';
import { DashboardCard } from '../DashboardCard';
import { Description } from '@mui/icons-material';

export interface IClientDashboardDocumentsCardProps {
    client: ClientDto;
    facility?: FacilityDto;
    isClientDashboardView?: boolean;
}

export const ClientDashboardDocumentsCard: FunctionComponent<IClientDashboardDocumentsCardProps> = (props) => {
    const { client, facility, isClientDashboardView } = props;
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);

    const { data: roofSectionsData, isLoading: roofSectionsLoading } = useGetRoofSectionsQuery({
        searchText: '',
        sortKey: 'ROOF_NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 250,
        includeInactive: false,
        facilityId: facility?.id,
    });
    const { data, isLoading, error, refetch } = useGetDocumentsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        clientId: client.id,
        facilityId: facility?.id,
        isClientDashboardView: isClientDashboardView
    });

    if (isLoading || roofSectionsLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <DashboardCard headerTitle='Documents' headerIcon={<Description />}>
            <DocumentsFormSection isDashboardView={true} searchText={searchText} setSearchText={setSearchText} setShowInactive={setShowInactive} showInactive={showInactive} facilityId={facility?.id} setPaginatedProps={setPaginatedProps} isLoading={isLoading} roofSectionsData={roofSectionsData!} data={data!} />
        </DashboardCard>
    );
};
