import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { FunctionComponent } from 'react';
import { IPhotosFormSection } from './types';
import { useState } from 'react';
import { PhotoLibrary } from '@mui/icons-material';
import _ from 'lodash';
import { PhotoDto } from '../../../models';
import { useLazyGetSasLinkQuery } from '../../../store/apis/cloud-storage-api';
import { uploadFileToCloud } from '../../../util';
import { DashboardCard } from '../../Dashboard';
import { Attachment, FormSection } from '../../Forms';
import { MultiPhotoUpload } from './MultiPhotoUpload';
import { PhotoEditorDialog } from './PhotoEditorDialog';
import { PhotosDisplay } from './PhotosDisplay';
import { ViewPhotoDialog } from './ViewPhotoDialog';
import { useLazyGetPhotoFileLinkQuery } from '../../../store/apis/photo-api';

export const PhotosFormSection: FunctionComponent<IPhotosFormSection> = React.memo(props => {
    const { photos, setPhotos, photoCaptions, setPhotoCaptions, initValues, disabled, headerTitle } = props;

    const [photoHoverIndex, setPhotoHoverIndex] = useState<number | undefined>();
    const [addPhotoIsOpen, setAddPhotoIsOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState<Attachment>();
    const [selectedCaption, setSelectedCaption] = useState<string>('');
    const [viewPhotoIsOpen, setViewPhotoIsOpen] = useState(false);
    const [editPhotoIsOpen, setEditPhotoIsOpen] = useState(false);
    const [getSasLink] = useLazyGetSasLinkQuery();
    const [getFileLink] = useLazyGetPhotoFileLinkQuery();


    const deleteSelectedPhoto = useCallback((index: number) => {
        if (photos.length === 0) {
            setPhotos([]);
            setPhotoCaptions([]);
        } else {
            const newCaptionList = [...photoCaptions];
            newCaptionList.splice(index, 1);
            setPhotoCaptions(newCaptionList);

            const newPhotoList = [...photos];
            newPhotoList.splice(index, 1);
            setPhotos(newPhotoList);
        }
    }, [photoCaptions, photos, setPhotoCaptions, setPhotos]);


    const createPreviewLink = useCallback(
        async (attachment: PhotoDto): Promise<Attachment> => {
            let response = await getFileLink(attachment.id, false);
            return { id: attachment.id, photo: attachment, previewLink: response.data?.link };
        },
        [getFileLink]
    );

    const initializePhotos = useCallback(() => {
        if (initValues?.photos) {
            Promise.all(
                initValues.photos.map(async (attachment) => {
                    return await createPreviewLink(attachment);
                })
            ).then((attachmentsList) => {
                const list = _.orderBy(attachmentsList, (x) => x.photo.order);
                setPhotos(list);
                setPhotoCaptions(list.map((attachment) => attachment.photo.caption ?? ''));
            });
        }
    }, [createPreviewLink, initValues?.photos, setPhotos, setPhotoCaptions]);

    useEffect(() => {
        initializePhotos();
    }, [initializePhotos]);


    const photoPreviewOnClick = useCallback((photo: Attachment, index: number) => {
        setSelectedPhoto(photo);
        setSelectedCaption(photoCaptions[index]);
        setViewPhotoIsOpen(true);
    }, [photoCaptions]);

    const handlePhotoEditOnClick = useCallback((photo: Attachment, index: number) => {
        setSelectedPhoto(photo);
        setSelectedCaption(photoCaptions[index]);
        setEditPhotoIsOpen(true);
    }, [photoCaptions]);

    const handleCaptionInput = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { value } = event.target;
        const updatedCaptionList = [...photoCaptions];
        updatedCaptionList[index] = value;
        setPhotoCaptions(updatedCaptionList);
    }, [photoCaptions, setPhotoCaptions]);

    const afterPhotoUpload = useCallback((id: string, photo: PhotoDto, previewLink: string) => {
        setPhotos((currentPhotos) => currentPhotos.concat({ id: id, photo: photo, previewLink: previewLink }));
        setPhotoCaptions(photoCaptions.concat(''));
    }, [photoCaptions, setPhotoCaptions, setPhotos]);


    const handleUpdateEditedPhoto = useCallback(async (updatedPhoto: File, attachmentId: string) => {
        getSasLink().then(async (response) => {
            if (response.data?.link) {
                const uploadResponse = await uploadFileToCloud(response.data?.link, updatedPhoto);
                if (uploadResponse.isSuccessful) {
                    setPhotos(currentPhotos => currentPhotos.map(currentPhoto => {
                        if (currentPhoto.id === attachmentId) {
                            return {
                                ...currentPhoto,
                                photo: { ...currentPhoto.photo, sourceName: uploadResponse.fileName ?? '' },
                                previewLink: URL.createObjectURL(updatedPhoto),
                            };
                        } else {
                            return currentPhoto;
                        }
                    }));
                    setEditPhotoIsOpen(false);
                }
            }
        });
    }, [getSasLink, setPhotos]);

    return (
        <>
            <Grid item container direction='row'>
                <Grid item xs={12}>
                    <DashboardCard headerTitle={headerTitle ? headerTitle : 'Photos'} headerIcon={<PhotoLibrary />}>
                        <FormSection>
                            <Grid item sx={{ padding: '24px' }}>
                                <PhotosDisplay
                                    attachments={photos}
                                    setAttachments={setPhotos}
                                    attachmentCaptions={photoCaptions}
                                    setAttachmentCaptions={setPhotoCaptions}
                                    attachmentHoverIndex={photoHoverIndex}
                                    setAddPhotoIsOpen={setAddPhotoIsOpen}
                                    setAttachmentHoverIndex={setPhotoHoverIndex}
                                    deleteSelectedPhoto={deleteSelectedPhoto}
                                    photoPreviewOnClick={photoPreviewOnClick}
                                    photoEditOnClick={handlePhotoEditOnClick}
                                    handleCaptionInput={handleCaptionInput}
                                    disabled={disabled}
                                />
                            </Grid>
                        </FormSection>
                    </DashboardCard>
                </Grid>
            </Grid>
            <MultiPhotoUpload
                open={addPhotoIsOpen}
                onClose={() => setAddPhotoIsOpen(false)}
                afterPhotoUpload={afterPhotoUpload}
                allowedFileTypes={['jpg', 'jpeg', 'png']}
            />
            {
                viewPhotoIsOpen && (
                    <ViewPhotoDialog
                        open={viewPhotoIsOpen}
                        onClose={() => setViewPhotoIsOpen(false)}
                        attachment={selectedPhoto}
                        previewLink={selectedPhoto?.previewLink}
                        caption={selectedCaption}
                    />
                )
            }
            {
                editPhotoIsOpen && selectedPhoto && (
                    <PhotoEditorDialog
                        open={editPhotoIsOpen}
                        onClose={() => setEditPhotoIsOpen(false)}
                        onUpdate={handleUpdateEditedPhoto}
                        attachment={selectedPhoto}
                    />
                )
            }
        </>
    );
});
