import { useMemo } from 'react';
import { useStandardSnackbar } from './useStandardSnackbar';
import { checkForCustomErrorMessage } from './utils';

export function useFailedActionSnackbar(
    presentTenseVerb: string,
    entityName: string,
    isVisible: boolean,
    onSnackbarDisplay?: () => void,
    errorObject: any = null
) {
    const message = useMemo(() => {
        const customErrorMessage = checkForCustomErrorMessage(errorObject);
        if (!!customErrorMessage) {
            return customErrorMessage;
        }
        return `An error occurred while attempting to ${presentTenseVerb} ${entityName}`;
    }, [entityName, presentTenseVerb, errorObject]);
    useStandardSnackbar(isVisible, message, 'error', onSnackbarDisplay);
}
