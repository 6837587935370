import { FunctionComponent } from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { INavBreadcrumbsProps } from './types';

export const NavBreadcrumbs: FunctionComponent<INavBreadcrumbsProps> = (props) => {
    const { links, currentPageLabel } = props;
    const navigate = useNavigate();

    const handleClick = (navLink: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        navigate(navLink);
    };

    return (
        <Breadcrumbs separator={<NavigateNext fontSize='small' />}>
            {links.map((link, index) =>
                link.isText ? (
                    <Typography key={index}>{link.label}</Typography>
                ) : (
                    <Link key={`${index}-${link.navLink}`} underline='hover' color='primary' href={link.navLink} onClick={handleClick(link.navLink)}>
                        {link.label}
                    </Link>
                )
            )}
            <Typography>{currentPageLabel}</Typography>
        </Breadcrumbs>
    );
};
