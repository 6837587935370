/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormatListBulleted } from '@mui/icons-material';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText,
    FormLabel, Grid, OutlinedInput, Radio,
    Switch, Typography
} from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { emptyGuid } from '../../../models';
import { useCreateJsiReportRolloverItemMutation, useUpdateJsiReportRolloverItemMutation } from '../../../store/apis/jsi-report-rollover-item-api';
import { useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../../util/customHooks';
import { DateTimePicker, FormInput } from '../FormFields';
import { IAddEditJsiReportRolloverItemDialogProps, isDate } from './types';

export const AddEditJsiReportRolloverItemDialog: FunctionComponent<IAddEditJsiReportRolloverItemDialogProps> = (props) => {
    const { initValues, report, open, onClose } = props;
    const [itemDate, setItemDate] = useState<Date | null | undefined>(initValues?.itemDate ? new Date(initValues.itemDate) : null);
    const [changed, setFormChanged] = useState(false);
    const [area, setArea] = useState(initValues?.area ? initValues.area : '');
    const [actionBy, setActionBy] = useState(initValues?.actionBy ? initValues.actionBy : '');
    const [dueDate, setDueDate] = useState<Date | null | undefined>(initValues?.dueDate && isDate(initValues?.dueDate) ? new Date(initValues.dueDate) : null);
    const [dueDateDescription, setDueDateDescription] = useState(initValues?.dueDate && !isDate(initValues?.dueDate) ? initValues.dueDate : '');
    const [dateCompleted, setDateCompleted] = useState<Date | null | undefined>(initValues?.dateCompleted ? initValues.dateCompleted : null);
    const [description, setDescription] = useState(initValues?.description ? initValues.description : '');
    const [isDueDateADate, setIsDueDateADate] = useState(initValues?.dueDate ? isDate(initValues?.dueDate ?? '') : true);
    const [addNewAfterSave, setAddNewAfterSave] = useState(false);

    const [
        createJsiReportRolloverItem,
        { isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate },
    ] = useCreateJsiReportRolloverItemMutation();

    const [
        updateJsiReportRolloverItem,
        { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate },
    ] = useUpdateJsiReportRolloverItemMutation();

    const isLocked = (date?: Date) => {
        if (date && new Date() >= new Date(date)) {
            return true;
        }
        return false;
    };
    const [isReportLocked] = useState(report ? isLocked(report.lockDate) : false);

    const [fieldErrors, setFieldErrors] = useState({
        ITEM_DATE: '',
        DESCRIPTION: '',
    });

    const beforeClose = useCallback(
        (shouldAddNewAfterSave: boolean) => {
            setItemDate(null);
            setArea('');
            setActionBy('');
            setDueDate(null);
            setDueDateDescription('');
            setDateCompleted(null);
            setDescription('');
            setFormChanged(false);
            resetCreate();
            resetUpdate();
            onClose(shouldAddNewAfterSave);
        },
        [onClose, resetCreate, resetUpdate]
    );

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'ITEM_DATE') {
            if (itemDate) {
                fieldErrors.ITEM_DATE = '';
                isValid = true;
            } else {
                fieldErrors.ITEM_DATE = 'Date is required';
                isValid = false;
            }
        } else if (fieldName === 'DESCRIPTION') {
            if (description && description.length <= 300) {
                fieldErrors.DESCRIPTION = '';
                isValid = true;
            } else {
                if (description && description.length > 300) {
                    fieldErrors.DESCRIPTION = 'Description is limited to 300 characters';

                } else {
                    fieldErrors.DESCRIPTION = 'Description is required';
                }
                isValid = false;
            }
        }
        setFieldErrors({
            ITEM_DATE: fieldErrors.ITEM_DATE,
            DESCRIPTION: fieldErrors.DESCRIPTION,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isItemDateValid = validate('ITEM_DATE');
        let isDescriptionValid = validate('DESCRIPTION');

        if (isItemDateValid && isDescriptionValid) {
            return true;
        }
    };

    const handleSave = async () => {
        if (formIsValid()) {
            const formValues = {
                id: initValues?.id ?? emptyGuid,
                isActive: true,
                jsiReportId: report.id,
                itemDate: itemDate,
                area: area,
                actionBy: actionBy,
                dueDate: isDueDateADate ? dueDate?.toISOString() ?? '' : dueDateDescription,
                dateCompleted: dateCompleted,
                description: description,
            };

            if (initValues?.id) {
                await updateJsiReportRolloverItem({
                    ...formValues,
                });
            } else {
                await createJsiReportRolloverItem({
                    ...formValues,
                });
            }
            setFormChanged(false);
        }
    };

    useFailedCreateSnackbar('rollover item', isCreateError, resetCreate);
    useFailedUpdateSnackbar('rollover item', isUpdateError, resetUpdate);
    useSuccessfulCreateSnackbar('Rollover item', isCreateSuccess, () => {
        if (open) {
            beforeClose(addNewAfterSave);
        }
        resetCreate();
    });

    useSuccessfulUpdateSnackbar('Rollover item', isUpdateSuccess, () => {
        if (open) {
            beforeClose(addNewAfterSave);
        }
        resetUpdate();
    });

    const onAddNewAfterSaveChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setAddNewAfterSave(checked);
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const handleItemDateChanged = (value: Date | null | undefined) => {
        setFormChanged(true);
        setItemDate(value);
    };

    const handleDateCompletedChanged = (value: Date | null | undefined) => {
        setFormChanged(true);
        setDateCompleted(value);
    };

    const handleDueDateChanged = (value: Date | null | undefined) => {
        setFormChanged(true);
        setDueDate(value);
    };

    const handleAreaChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setArea(event.target.value);
    };

    const handleActionByChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setActionBy(event.target.value);
    };

    const handleDueDateDescriptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setDueDateDescription(event.target.value);
    };

    const handleDescriptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setDescription(event.target.value);
    };

    const handleDueDateTypeChanged = (value: boolean) => {
        if (!value) {
            setDueDate(null);
        } else {
            setDueDateDescription('');
        }
        setFormChanged(true);
        setIsDueDateADate(value);
    };

    return (
        <Dialog maxWidth='xl' fullWidth open={open}>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <FormatListBulleted />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        {initValues?.id ? 'Edit' : 'New'} Rollover List Item
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid component='form' container direction='column' mt='16px' spacing={3} autoComplete='off' onSubmit={handleSave}>
                    <Grid item container direction='row' spacing={3} xs={12} alignItems='center' wrap='nowrap'>
                        <Grid item>
                            <FormControl error={fieldErrors.ITEM_DATE !== ''} fullWidth required>
                                <DateTimePicker
                                    label='Date'
                                    value={itemDate}
                                    onChange={handleItemDateChanged}
                                    dateOnly={true}
                                    onBlur={onFieldBlur('ITEM_DATE')}
                                    onAcceptDate={() => {
                                        setFieldErrors({
                                            ...fieldErrors,
                                            ITEM_DATE: '',
                                        });
                                    }}
                                    disabled={isReportLocked}
                                />
                                <FormHelperText>{fieldErrors.ITEM_DATE}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormInput
                                value={area}
                                onChange={handleAreaChanged}
                                disabled={isReportLocked}
                                label='Area'
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <FormInput
                                value={actionBy}
                                onChange={handleActionByChanged}
                                disabled={isReportLocked}
                                label='Action By'
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Radio
                                sx={{ marginTop: '15px' }}
                                disabled={isReportLocked || isDueDateADate}
                                checked={isDueDateADate}
                                onClick={() => handleDueDateTypeChanged(true)}
                            />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <DateTimePicker
                                    label='Due Date'
                                    value={dueDate}
                                    onChange={handleDueDateChanged}
                                    dateOnly={true}
                                    disabled={isReportLocked || !isDueDateADate}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sx={{ marginTop: '15px' }}>
                            <span>Or</span>
                        </Grid>
                        <Grid item>
                            <Radio
                                sx={{ marginTop: '15px' }}
                                disabled={isReportLocked || !isDueDateADate}
                                checked={!isDueDateADate}
                                onClick={() => handleDueDateTypeChanged(false)}
                            />
                        </Grid>
                        <Grid item>
                            <FormInput
                                value={dueDateDescription}
                                onChange={handleDueDateDescriptionChanged}
                                disabled={isReportLocked || isDueDateADate}
                                label='Due Date Description'
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <DateTimePicker
                                    label='Date Completed'
                                    value={dateCompleted}
                                    onChange={handleDateCompletedChanged}
                                    dateOnly={true}
                                    disabled={isReportLocked}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormInput
                            value={description}
                            onChange={handleDescriptionChanged}
                            onBlur={onFieldBlur('DESCRIPTION')}
                            disabled={isReportLocked}
                            label='Description'
                            fullWidth
                            error={fieldErrors.DESCRIPTION !== ''}
                            errorText={fieldErrors.DESCRIPTION}
                            required
                            rows={4}
                            maxLength={300}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={addNewAfterSave} onChange={onAddNewAfterSaveChange} />}
                            label='Add new rollover list item after save'
                        />
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            {changed || !initValues ? (
                                <Button
                                    variant='outlined'
                                    style={{ boxShadow: 'none' }}
                                    onClick={() => {
                                        beforeClose(false);
                                    }}>
                                    Cancel
                                </Button>
                            ) : (
                                <Button
                                    variant='outlined'
                                    style={{ boxShadow: 'none' }}
                                    onClick={() => {
                                        beforeClose(false);
                                    }}>
                                    Close
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={handleSave}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
