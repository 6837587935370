import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { TransferListColumn } from './TransferListColumn';
import _ from 'lodash';
import { AboveOrBelow, ITransferListItem } from './TransferListItem';

export interface IBasicTransferListProps {
    targetColumnName?: string;
    sourceColumnName?: string;
    currentItems: ITransferListItem[];
    allItems: ITransferListItem[];
    onCurrentItemsChanged: (updatedItems: ITransferListItem[]) => void;
    height?: string | number;
    orderable?: boolean;
    disabled?: boolean;
}

export const BasicTransferList: FC<IBasicTransferListProps> = (props) => {
    const { targetColumnName = 'Selected', sourceColumnName = 'Available', currentItems, allItems, onCurrentItemsChanged, height = 200, orderable = false, disabled } = props;
    const availableItems = useMemo(() => _.differenceBy(allItems, currentItems, 'value'), [currentItems, allItems]);
    const [selectedCurrentItems, setSelectedCurrentItems] = useState<ITransferListItem[]>([]);
    const [selectedAvailableItems, setSelectedAvailableItems] = useState<ITransferListItem[]>([]);

    const onAddClicked = useCallback(() => {
        onCurrentItemsChanged([...currentItems, ...selectedAvailableItems]);
        setSelectedCurrentItems([]);
        setSelectedAvailableItems([]);
    }, [currentItems, selectedAvailableItems, onCurrentItemsChanged]);

    const onRemoveClicked = useCallback(() => {
        var selectedItemValues = selectedCurrentItems.map(item => item.value);
        var updatedCurrentItems = currentItems.filter(item => !selectedItemValues.includes(item.value));
        onCurrentItemsChanged(updatedCurrentItems);
        setSelectedAvailableItems([]);
        setSelectedCurrentItems([]);
    }, [selectedCurrentItems, currentItems, onCurrentItemsChanged]);

    const handleReorderRequest = useCallback((sourceItem: string, targetItem: string, aboveOrBelow: AboveOrBelow) => {
        var sourceItemIndex = currentItems.findIndex(x => x.value === sourceItem);
        var targetItemIndex = currentItems.findIndex(x => x.value === targetItem);
        if (sourceItemIndex === -1 || targetItemIndex === -1) {
            console.error('Unable to reorder transfer list items because the original indexes could not be found');
            return;
        }
        if (sourceItemIndex === targetItemIndex) {
            console.info('Attempted to move transfer list item to the same location it already is. Request ignored.');
            return;
        }

        var updatedCurrentItems = _.cloneDeep(currentItems);
        var itemToMove = updatedCurrentItems.splice(sourceItemIndex, 1)[0];
        var indexOffset = sourceItemIndex < targetItemIndex ? -1 : 0;
        indexOffset = aboveOrBelow === 'above' ? indexOffset : indexOffset + 1;
        const adjustedTargetIndex = targetItemIndex + indexOffset;
        updatedCurrentItems.splice(adjustedTargetIndex, 0, itemToMove);
        onCurrentItemsChanged(updatedCurrentItems);
    }, [currentItems, onCurrentItemsChanged]);

    return (
        <Grid container direction='row' alignItems='stretch' height={height}>
            <TransferListColumn
                columnLabel={targetColumnName}
                options={currentItems}
                selectedOptions={selectedCurrentItems}
                onSelectedItemsChange={setSelectedCurrentItems}
                orderable={orderable}
                handleReorderRequest={handleReorderRequest}
                disabled={disabled}
            />
            <Grid item width={80} display='flex' justifyContent='center' flexDirection='column' p={1} gap={1}>
                <Button variant='outlined' onClick={onAddClicked} disabled={disabled}>
                    <ArrowLeft />
                </Button>
                <Button variant='outlined' onClick={onRemoveClicked} disabled={disabled}>
                    <ArrowRight />
                </Button>
            </Grid>
            <TransferListColumn
                columnLabel={sourceColumnName}
                options={availableItems}
                selectedOptions={selectedAvailableItems}
                onSelectedItemsChange={setSelectedAvailableItems}
                // TODO: we don't want this right list to be orderable so the quick solution for that is to do this but we should really make this a different component or make the properties optional
                orderable={false}
                handleReorderRequest={() => { }}
                disabled={disabled}
            />
        </Grid>
    );
};
