import { ContentCopy } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { DatePicker } from '@mui/lab';
import { BudgetDto } from '../models';
import { useCreateBudgetCopyMutation } from '../store/apis/budget-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../util';

interface ICopyBudgetModal {
    open: boolean;
    onCancel: () => void;
    budgetData: BudgetDto | null;
}

export const CopyBudgetModal: FunctionComponent<ICopyBudgetModal> = React.memo((props) => {
    const { open, onCancel, budgetData } = props;

    const [createCopyBudget, { isSuccess: copySuccess, isError: isCopyError, reset: resetCopy }] = useCreateBudgetCopyMutation();
    const [isIncludedInBudgetSummaries, setIsIncludedInBudgetSummaries] = useState<boolean>(true);
    const [startYear, setStartYear] = useState<Date | null>(budgetData && budgetData.startYear ? new Date(budgetData.startYear) : null);

    useSuccessfulActionSnackbar('copied', 'Budget', copySuccess, () => {
        resetCopy();
    });
    useFailedActionSnackbar('copy', 'Budget', isCopyError, resetCopy);

    useEffect(() => {
        setStartYear(budgetData && budgetData.startYear ? new Date(budgetData.startYear) : null);
    }, [budgetData]);

    const handleStartYearChange = useCallback((date: Date | null) => {
        setStartYear(date);
    }, []);

    const handleIsIncludedInBudgetSummariesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsIncludedInBudgetSummaries(event.target.checked);
    };

    const handleCancel = () => {
        setIsIncludedInBudgetSummaries(true);
        onCancel();
    };

    const handleSelect = () => {
        if (!budgetData) {
            return;
        }
        const budgetCopy = {
            id: budgetData.id,
            startYear: startYear,
            isIncludedInBudgetSummaries: isIncludedInBudgetSummaries,
        };

        createCopyBudget(budgetCopy);
        onCancel();
    };

    return (
        <Dialog open={open}>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                <Box display='flex' alignItems='center' gap={1}>
                    <ContentCopy />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Copy Budget with New Start Year
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display='flex' flexDirection='column' pt={4} gap={2}>
                    <Typography variant='h6'>Please enter the Start Year for the new Budget.</Typography>
                    <DatePicker
                        label='Start Year'
                        views={['year']}
                        onChange={handleStartYearChange}
                        value={startYear}
                        renderInput={(params) => <TextField {...params} required={true} />}
                    />

                    <Box>
                        <FormControlLabel
                            sx={{ marginLeft: '0' }}
                            control={<Switch checked={isIncludedInBudgetSummaries} onChange={handleIsIncludedInBudgetSummariesChange} />}
                            label='Hide Previous Budget From Budget Summaries'
                            labelPlacement='start'
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display='flex' justifyContent='flex-end' gap={2} p={2}>
                    <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button disabled={!startYear} variant='contained' color='primary' onClick={handleSelect}>
                        Copy
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
});
