import { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JsiReportForm } from '../../Components/Forms/JsiReportForm';
import { emptyGuid, JsiReportDto } from '../../models';
import { useCreateJsiReportMutation } from '../../store/apis/jsi-report-api';
import { useGetWorkOrderQuery } from '../../store/apis/work-order-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { convertChecklistItemsToJsiReportChecklistItemDtos } from '../../Components/Forms/JsiReportForm/types';

export const AddJsiReportView: FunctionComponent = () => {
    const { workOrderId } = useParams();
    const navigate = useNavigate();
    const [createJsiReport, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateJsiReportMutation();
    const { data: capitalProject, isLoading } = useGetWorkOrderQuery(workOrderId!);
    const [jsiReport] = useState<JsiReportDto | undefined>({
        id: emptyGuid,
        isActive: true,
        reportDate: null,
        reportName: '',
        workOrder: capitalProject,
        workOrderId: capitalProject?.id ?? '',
        roofSections: '',
        totalSquareFootage: 0,
        phoneNumber: '',
        weather: '',
        qaInspector: '',
        othersPresent: '',
        contractorId: '',
        foremanOnJob: '',
        foremanPhoneNumber: '',
        crewSizeOnsite: 0,
        workCompliant: false,
        projectSummary: '',
        inspectorNotes: '',
        checklistItems: [],
        photos: [],
        isVisibleToClients: false,
        isVisibleToEmployees: true,
        visibility: 'computed', // the value is computed on the back-end so this initial value does not actually matter
    });

    useFailedCreateSnackbar('JSI report', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('JSI report', isCreateSuccess, () => {
        navigate(`/jsireports/edit/${data?.id}`);
    });

    const handleSave = (values: JsiReportDto) => {
        createJsiReport(values);
    };

    const handleCancel = () => {
        if (workOrderId) {
            navigate(`/capitalprojects/edit/${workOrderId}`);
        } else {
            navigate('/clientreports');
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <JsiReportForm
            save={handleSave}
            cancel={handleCancel}
            workOrderId={workOrderId}
            initValues={{
                ...jsiReport,
                id: emptyGuid,
                isActive: true,
                reportDate: null,
                reportName: '',
                workOrder: capitalProject,
                workOrderId: capitalProject?.id ?? '',
                roofSections: '',
                totalSquareFootage: 0,
                phoneNumber: '',
                weather: '',
                qaInspector: '',
                othersPresent: '',
                contractorId: '',
                foremanOnJob: '',
                foremanPhoneNumber: '',
                crewSizeOnsite: 0,
                workCompliant: false,
                projectSummary: '',
                inspectorNotes: '',
                checklistItems: convertChecklistItemsToJsiReportChecklistItemDtos(),
                photos: [],
                presentedToClientName: capitalProject?.facilityClientName,
                presentedToFacilityName: capitalProject?.facilityName,
                presentedToAddress1: capitalProject?.facility?.addressLine1,
                presentedToAddress2: capitalProject?.facility?.addressLine2,
                presentedToCity: capitalProject?.facility?.city,
                presentedToState: capitalProject?.facility?.state,
                presentedToContactName: capitalProject?.billedToClient?.contactName ?? capitalProject?.billedToContractor?.name,
                presentedToZip: capitalProject?.facility?.zipCode,
                isVisibleToClients: false,
                isVisibleToEmployees: true,
                visibility: 'computed', // the value is computed on the back-end so this initial value does not actually matter
            }}
        />
    );
};
