import { serverApi } from '..';
import { ExecutiveSummaryBudgetDto } from '../../models';

const budgetItemApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getClientBudgetItems: builder.query<ExecutiveSummaryBudgetDto, {clientId: string, facilityId?: string}>({
            query: (params) => ({ url: `client/${params.clientId}/clientBudgetItem` + (params.facilityId ? `?facilityId=${params.facilityId}` : '') }),
            providesTags: [{ type: 'Budget Item' }]
        })
    })
});

export const {
    useGetClientBudgetItemsQuery
} = budgetItemApi;