export const formatCurrency = (val?: number, returnEmptyString?: boolean) => {
    var zero = 0;
    return val
        ? val.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
          })
        : returnEmptyString
        ? ''
        : zero.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
          });
};
