import { RoofProfilesSearchQueryParams, serverApi } from '..';
import { QueryResultsDto, RoofProfileDto } from '../../models';
import { buildRoofProfileQueryRoute, buildSearchQueryRoute } from '../../util';
import { RoofProfileQueryParams } from '../types';

const roofProfileApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoofProfiles: builder.query<QueryResultsDto<RoofProfileDto>, RoofProfilesSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('roofProfile', params),
            providesTags: [{ type: 'Roof Profile' }],
        }),
        getRoofProfile: builder.query<RoofProfileDto, RoofProfileQueryParams>({
            query: (params) => buildRoofProfileQueryRoute(`roofprofile/${params.id}`, params.includeSvg),
            providesTags: [{ type: 'Roof Profile' }],
        }),
        createRoofProfile: builder.mutation<RoofProfileDto, RoofProfileDto>({
            query: (roofProfileDto) => ({
                url: 'roofProfile',
                method: 'POST',
                body: roofProfileDto,
            }),
            invalidatesTags: [{ type: 'Roof Profile' }],
        }),
        updateRoofProfile: builder.mutation<void, RoofProfileDto>({
            query: (roofProfileDto) => ({
                url: 'roofProfile',
                method: 'PUT',
                body: roofProfileDto,
            }),
            invalidatesTags: [{ type: 'Roof Profile' }],
        }),
        resetRoofProfileMap: builder.mutation<void, string>({
            query: (id) => ({
                url: `roofProfile/resetRoofProfileMap/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Roof Profile' }],
        }),
        archiveRoofProfile: builder.mutation<void, string>({
            query: (id) => ({
                url: `roofProfile/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Roof Profile' }],
        }),
        unarchiveRoofProfile: builder.mutation<void, string>({
            query: (id) => ({
                url: `roofProfile/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Roof Profile' }],
        }),
    }),
});

export const {
    useGetRoofProfilesQuery,
    useGetRoofProfileQuery,
    useCreateRoofProfileMutation,
    useUpdateRoofProfileMutation,
    useArchiveRoofProfileMutation,
    useUnarchiveRoofProfileMutation,
    useResetRoofProfileMapMutation,
} = roofProfileApi;
