import {
    Avatar,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { IMenuButtonProps } from './types';

export const MenuButton: FunctionComponent<IMenuButtonProps> = React.memo((props) => {
    const { tooltip, children, color, disabled } = props;

    const displayColor = disabled ? '#B9B9B9' : (color ?? '#F7941D');

    return (
        <Tooltip
            title={
                <Typography
                    sx={{ fontSize: '14px' }}
                >
                    {tooltip}
                </Typography>
            }
        >
            <span>
                <Avatar
                    variant='circular'
                    sx={{
                        backgroundColor: '#fafafa',
                        border: `1px solid ${displayColor}`,
                        height: '48px',
                        width: '48px'
                    }}
                >
                    <IconButton
                        sx={{
                            color: displayColor,
                            height: '48px',
                            width: '48px'
                        }}
                        {...props}
                    >
                        {children}
                    </IconButton>
                </Avatar >
            </span>
        </Tooltip >
    );
});
