import { AddCircle, AddPhotoAlternate, Delete, DownloadForOfflineRounded, HideImage, MoreVert, Search } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Menu,
    MenuItem,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import { ChangeEvent, FC, memo, useCallback, useEffect, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../../../auth';
import { RoofLeakDto, RoofSectionDto } from '../../../models';
import { useLazyGetActiveRoofLeaksForFacilityQuery, useUpdateRoofLeakMutation } from '../../../store/apis/roof-leak-api';
import { useResetRoofProfileMapMutation } from '../../../store/apis/roof-profile-api';
import {
    useArchiveRoofSectionMutation,
    useGetRoofSectionsQuery,
    useLinkRoofSectionMutation,
    useUnarchiveRoofSectionMutation,
    useUnlinkRoofSectionMutation,
} from '../../../store/apis/roof-section-api';
import { ColorSwatch, exportToPdf } from '../../../util';
import { useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulActionSnackbar, useSuccessfulUpdateSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { NoMapFoundError } from '../../core/NoMapFoundError';
import { RoofLeakDetailsDialog } from '../../core/RoofLeakDialog';
import { SvgEditor } from '../../svg/SvgEditor';
import { RoofSectionDialog } from '../RoofSectionForm/RoofSectionDialog';
import { SelectRoofSectionsDialog } from '../SelectRoofSection/SelectRoofSectionsDialog';
import { ConfirmMapDeleteDialog } from './ConfirmMapDeleteDialog';
import { RoofMapDrawer } from './RoofMapDrawer';
import { IRoofMapProps, Point } from './types';
import { UploadRoofMapDialog } from './UploadRoofMapDialog';
import { usePermissionChecker } from '../../../Hooks';
import { SvgWrapper } from '../../svg';
import { useLazyGetFacilityRoofMapPdfQuery } from '../../../store/apis/facility-api';

export const RoofMap: FC<IRoofMapProps> = memo((props) => {
    const {
        facility,
        roofProfileId,
        svgContents,
        setSvgContents,
        showTableInit,
        canLinkSections,
        reloadProfile,
        editOnMapClick,
        setNeedsToSaveSvg,
        setFormChanged,
    } = props;
    const navigate = useNavigate();
    const { userHasAnyPermission } = usePermissionChecker();
    const [selectedFacilityId, setFacilityId] = useState(facility.id);
    const [sectionDialogOpen, setSectionDialogOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [isTableView, setIsTableView] = useState<Boolean>(showTableInit);
    const [selectedSection, setSelectedSection] = useState<RoofSectionDto | undefined | null>();
    const [selectedElement, setSelectedElement] = useState<string | null>();
    const [isSelectRoofSectionsDialogOpen, setIsSelectRoofSectionsDialogOpen] = useState(false);
    const [roofSections, setRoofSections] = useState<RoofSectionDto[]>([]);
    const {
        data,
        isLoading,
        error,
        refetch: refetchRoofSection,
    } = useGetRoofSectionsQuery({
        facilityId: facility.id,
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
    });
    const [updateRoofLeak, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateRoofLeakMutation();
    const [archiveRoofSection, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveRoofSectionMutation();
    const [unarchiveRoofSection, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveRoofSectionMutation();
    const [resetRoofProfileMap, { isError: isResetError, isSuccess: isResetSuccess, reset: resetReset }] = useResetRoofProfileMapMutation();
    const [roofMapDialogOpen, setRoofMapDialogOpen] = useState(false);
    const [confirmMapDeleteDialogOpen, setMapDeleteDialogOpen] = useState(false);
    const [linkRoofSection, { isSuccess: isLinkSuccess, isError: isLinkError, reset: resetLink }] = useLinkRoofSectionMutation();
    const [unlinkRoofSection, { isSuccess: isUnlinkSuccess, isError: isUnlinkError, reset: resetUnlink }] = useUnlinkRoofSectionMutation();
    const [showRoofLeaks, setShowRoofLeaks] = useState(false);
    const [showRoofConditions, setShowRoofConditions] = useState(true);
    const [showUnderWarranty, setShowUnderWarranty] = useState(false);
    const [selectedRoofLeak, setSelectedRoofLeak] = useState<RoofLeakDto>();
    const [selectedRoofLeakAnchorEl, setSelectedRoofLeakAnchorEl] = useState<null | any>(null);
    const [getRoofLeaks, { data: activeRoofLeaks, isFetching: isFetchingRoofLeaks }] = useLazyGetActiveRoofLeaksForFacilityQuery();
    const [roofLeaks, setRoofLeaks] = useState<RoofLeakDto[]>();

    const [generateRoofMapPdf, { isFetching: downloadLoading }] = useLazyGetFacilityRoofMapPdfQuery();

    const handlePrint = useCallback(() => {
        generateRoofMapPdf({
            facilityId: facility.id,
            underWarrantyFilter: showUnderWarranty,
            roofConditionFilter: showRoofConditions,
            roofLeaksFilter: showRoofLeaks,
        }).then((response: any) => {
            if (response.data) {
                exportToPdf(response.data, `${facility.name}-RoofMap`);
            }
        });
    }, [facility, generateRoofMapPdf, showRoofConditions, showRoofLeaks, showUnderWarranty]);

    useEffect(() => {
        if (selectedFacilityId !== facility.id || !activeRoofLeaks) {
            getRoofLeaks(facility.id).then((response) => {
                setRoofLeaks(response.data);
                setFacilityId(facility.id);
            });
        }
    }, [activeRoofLeaks, facility.id, getRoofLeaks, roofLeaks, selectedFacilityId]);

    useFailedUpdateSnackbar('roof section', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Roof section', isUpdateSuccess, resetUpdate);
    useFailedActionSnackbar('inactivate', 'Roof section', isArchiveError, resetArchive);
    useSuccessfulActionSnackbar('Inactivated', 'Roof section', isArchiveSuccess, resetArchive);
    useFailedActionSnackbar('activate', 'Roof section', isUnarchiveError, resetUnarchive);
    useSuccessfulActionSnackbar('Activated', 'Roof section', isUnarchiveSuccess, resetUnarchive);
    useFailedActionSnackbar('link', 'Roof section', isLinkError, resetLink);
    useSuccessfulActionSnackbar('Linked', 'Roof section', isLinkSuccess, resetLink);
    useFailedActionSnackbar('unlink', 'Roof section', isUnlinkError, resetUnlink);
    useSuccessfulActionSnackbar('Unlinked', 'Roof section', isUnlinkSuccess, resetUnlink);
    useFailedActionSnackbar('reset', 'Roof section', isResetError, resetReset);
    useSuccessfulActionSnackbar('reset', 'Roof section', isResetSuccess, resetReset);

    const svgGroupSelected = useCallback(
        (id: string) => {
            setSelectedElement(id);
            if (canLinkSections) {
                const existingRoofSectionLink = data?.pageResults.find((x) => x.mapSections?.find((y) => y.id === id && y.roofProfileId === roofProfileId));
                if (!existingRoofSectionLink) {
                    setRoofSections([]);
                    setIsSelectRoofSectionsDialogOpen(true);
                } else {
                    setSelectedSection(existingRoofSectionLink);
                    setSectionDialogOpen(true);
                }
            }
        },
        [canLinkSections, data?.pageResults, roofProfileId]
    );

    const tableColumns: DataTableColumn<RoofSectionDto>[] = [
        { key: 'roofNumber', label: 'Roof #', sortKey: 'ROOF_NUMBER' },
        { key: 'squareFootage', label: 'Sq Ft', sortKey: 'SQUARE_FOOTAGE', align: 'right', fieldMapper: (row) => row.squareFootage.toLocaleString() },
        { key: 'roofSystem', label: 'Roof Type', sortKey: 'ROOF_TYPE', fieldMapper: (row) => row.roofSystem?.name },
        { key: 'roofCondition', label: 'Condition', sortKey: 'CONDITION', fieldMapper: (row) => <ColorSwatch condition={row.roofCondition} /> },
        { key: 'estimatedAge', label: 'Estimated Install Year', sortKey: 'ESTIMATED_AGE' },
        {
            key: 'installDate',
            label: 'Install Date',
            sortKey: 'INSTALL_DATE',
            fieldMapper: (row) => (row.installDate ? format(new Date(row.installDate.toString()), 'M/d/yyyy') : ''),
        },
        { key: 'manufacturer', label: 'Manufacturer', sortKey: 'MANUFACTURER', fieldMapper: (row) => row.manufacturer?.name },
        {
            key: 'assetValue',
            label: 'Asset Value',
            align: 'right',
            sortKey: 'ASSET_VALUE',
            fieldMapper: (row) =>
                row.assetValue
                    ? row.assetValue.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                      })
                    : '',
        },
        {
            key: 'map',
            label: 'Assigned to Map?',
            sortKey: '',
            unsorted: true,
            fieldMapper: (row) => ((row.mapSections ? row.mapSections.filter((x) => x.roofProfileId === roofProfileId).length : 0) > 0 ? 'Yes' : 'No'),
        },
    ];

    const handleLinkRoofSection = useCallback(
        (selectedRoof: RoofSectionDto) => {
            if (selectedElement && selectedRoof) {
                linkRoofSection({
                    id: selectedElement,
                    roofSectionId: selectedRoof.id,
                    roofProfileId: roofProfileId,
                }).then(() => {
                    if (reloadProfile) {
                        reloadProfile();
                    }
                });
            }
        },
        [linkRoofSection, reloadProfile, roofProfileId, selectedElement]
    );

    const handleUnlinkRoofSections = useCallback(
        async (unlinkedRoofSections: RoofSectionDto[]) => {
            setIsSelectRoofSectionsDialogOpen(false);
            if (unlinkedRoofSections && unlinkedRoofSections.length > 0) {
                const promises = [] as Promise<any>[];

                unlinkedRoofSections.forEach((roofSection) => {
                    const mapSection = roofSection.mapSections?.find((x) => x.roofProfileId === roofProfileId);
                    if (mapSection) {
                        promises.push(
                            unlinkRoofSection({
                                id: mapSection.id,
                                roofSectionId: mapSection.roofSectionId,
                                roofProfileId: mapSection.roofProfileId,
                            })
                        );
                    }
                });

                await Promise.all(promises).then(() => {
                    if (reloadProfile) {
                        reloadProfile();
                    }
                });
            }
        },
        [reloadProfile, roofProfileId, unlinkRoofSection]
    );

    const handleCancelRoofSectionSelectionDialog = useCallback(() => {
        setIsSelectRoofSectionsDialogOpen(false);
        setRoofSections([]);
    }, []);

    const handleSectionDialogClose = useCallback(() => {
        setSelectedSection(null);
        setSectionDialogOpen(false);
    }, []);

    const handleSectionDialogOpen = useCallback(() => {
        setSectionDialogOpen(true);
    }, []);

    const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleActiveToggleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) {
                afterClose();
            }
        },
        []
    );

    const handleConfirmRoofSectionSelectionDialog = useCallback(
        (selectedRoof: RoofSectionDto) => {
            setIsSelectRoofSectionsDialogOpen(false);
            handleLinkRoofSection(selectedRoof);
            setRoofSections([]);
        },
        [handleLinkRoofSection]
    );

    const handleCreateNew = useCallback(() => {
        handleSectionDialogOpen();
    }, [handleSectionDialogOpen]);

    const handleMenuEdit = useCallback(() => {
        setSelectedSection(data?.pageResults[selected[0]]);
        setSectionDialogOpen(true);
    }, [data?.pageResults, selected]);

    const handleRowEdit = useCallback(
        (id: string) => {
            setSelectedSection(data?.pageResults.find((x) => x.id === id));
            setSectionDialogOpen(true);
        },
        [data?.pageResults]
    );

    const handleRoofSectionClick = useCallback(
        (id: string) => {
            if (!editOnMapClick) {
                navigate(`roofsection/${id}`);
            }
        },
        [editOnMapClick, navigate]
    );

    const handleMapSectionClick = useCallback(
        (sectionId: string) => {
            var section = data?.pageResults?.find((x) => x.mapSections?.find((y) => y.id === sectionId));
            if (section) {
                if (editOnMapClick) {
                    svgGroupSelected(sectionId);
                    return;
                }
                navigate(`roofsection/${section?.id!}`);
                return;
            }
            setSelectedElement(sectionId);
            setIsSelectRoofSectionsDialogOpen(true);
        },
        [data?.pageResults, editOnMapClick, navigate, svgGroupSelected]
    );

    const handleSetActive = useCallback(() => {
        selected.forEach((index) => {
            unarchiveRoofSection(data?.pageResults[index].id!);
        });
        setSelected([]);
    }, [data?.pageResults, selected, unarchiveRoofSection]);

    const handleSetInactive = useCallback(() => {
        selected.forEach((index) => {
            archiveRoofSection(data?.pageResults[index].id!);
        });
        setSelected([]);
    }, [archiveRoofSection, data?.pageResults, selected]);

    const canSetActive = useCallback(() => {
        return selected.some((index) => !data?.pageResults[index]?.isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useCallback(() => {
        return selected.some((index) => data?.pageResults[index]?.isActive);
    }, [data?.pageResults, selected]);

    const handleViewChange = useCallback(
        (value: boolean) => () => {
            setIsTableView(value);
        },
        []
    );

    const handlePromptConfirmation = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setMapDeleteDialogOpen(true);
    }, []);

    const handleRemoveMap = useCallback(() => {
        resetRoofProfileMap(roofProfileId!);
        setMapDeleteDialogOpen(false);
    }, [resetRoofProfileMap, roofProfileId]);

    const handleOnPinClick = useCallback(
        (event: any, _idx?: number, id?: string) => {
            setSelectedRoofLeakAnchorEl(undefined);
            setSelectedRoofLeak(undefined);
            if (roofLeaks && id !== undefined) {
                setSelectedRoofLeakAnchorEl(event);
                const roofLeak = roofLeaks.find((x) => x.id === id);
                setSelectedRoofLeak(roofLeak);
            }
        },
        [roofLeaks]
    );

    const moveRoofLeak = useCallback(
        (position: Point) => {
            if (roofLeaks && selectedRoofLeak !== undefined) {
                const temp = [
                    ...roofLeaks.map((x) => {
                        return { ...x };
                    }),
                ];
                const idx = temp.findIndex((x) => x.id === selectedRoofLeak.id);

                if (temp[idx].y === undefined) {
                    temp[idx].y = 0;
                }
                if (temp[idx].x === undefined) {
                    temp[idx].x = 0;
                }

                temp[idx].x = position.x;
                temp[idx].y = position.y;
                setSelectedRoofLeak({ ...temp[idx] });
                setRoofLeaks([...temp]);
            }
        },
        [roofLeaks, selectedRoofLeak]
    );

    const getRoofLeakIndex = useCallback(
        (selectedRoofLeak: RoofLeakDto) => {
            const dispatchRoofLeaks = roofLeaks?.filter((x) => x.workOrderDispatchId === selectedRoofLeak.workOrderDispatchId) ?? [];

            const sorted = _.orderBy(dispatchRoofLeaks, (x) => x.createdOn);

            return sorted.findIndex((x) => x.id === selectedRoofLeak.id);
        },
        [roofLeaks]
    );

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetchRoofSection} />;
    }

    return (
        <>
            <RoofMapDrawer facilityId={facility.id} roofProfileId={roofProfileId}>
                <Grid container direction='column' spacing={3} sx={{ padding: '24px' }} maxWidth={'100% !important'}>
                    {roofProfileId ? (
                        <>
                            <Grid item container direction='row'>
                                <Grid item container direction='column' justifyContent='start'>
                                    <Grid
                                        item
                                        container
                                        direction='row'
                                        alignItems='baseline'
                                        justifyContent={'space-between'}
                                        spacing={1}
                                        sx={{ height: '64px' }}
                                        xs={12}>
                                        <Grid item container direction='row' xs={6} spacing={1}>
                                            <Grid item>
                                                <Typography sx={{ fontWeight: 'bold' }}>View by:</Typography>
                                            </Grid>
                                            <Grid item>
                                                {isTableView ? (
                                                    <Link color='secondary' underline='hover' onClick={handleViewChange(false)} sx={{ cursor: 'pointer' }}>
                                                        Roof Map
                                                    </Link>
                                                ) : (
                                                    <Typography>Roof Map</Typography>
                                                )}
                                            </Grid>
                                            <AuthenticatedComponent
                                                requiredPermissions={['read:roofSections']}
                                                component={
                                                    <>
                                                        <Grid item>
                                                            <Typography>|</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            {!isTableView ? (
                                                                <Link
                                                                    color='secondary'
                                                                    underline='hover'
                                                                    onClick={handleViewChange(true)}
                                                                    sx={{ cursor: 'pointer' }}>
                                                                    Data Table
                                                                </Link>
                                                            ) : (
                                                                <Typography>Data Table</Typography>
                                                            )}
                                                        </Grid>
                                                    </>
                                                }
                                            />
                                        </Grid>
                                        {!isTableView && svgContents ? (
                                            <Grid item container direction={'row'} xs={12} justifyContent='flex-end' alignItems='center' spacing={1}>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={showUnderWarranty}
                                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                    setShowUnderWarranty(e.target.checked);
                                                                    if (e.target.checked && showRoofConditions) {
                                                                        setShowRoofConditions(false);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label='Under Warranty'
                                                        labelPlacement='start'
                                                        sx={{ marginTop: '14px' }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={showRoofConditions}
                                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                    setShowRoofConditions(e.target.checked);
                                                                    if (e.target.checked && showUnderWarranty) {
                                                                        setShowUnderWarranty(false);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label='Roof Condition'
                                                        labelPlacement='start'
                                                        sx={{ marginTop: '14px' }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={showRoofLeaks}
                                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                    setShowRoofLeaks(e.target.checked);
                                                                }}
                                                            />
                                                        }
                                                        label='Roof Leaks'
                                                        labelPlacement='start'
                                                        sx={{ marginTop: '14px' }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button variant='contained' startIcon={<HideImage />} color='error' onClick={handlePromptConfirmation}>
                                                        REMOVE
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant='contained'
                                                        startIcon={<DownloadForOfflineRounded />}
                                                        onClick={handlePrint}
                                                        disabled={downloadLoading}>
                                                        {downloadLoading ? (
                                                            <>
                                                                DOWNLOADING... <CircularProgress size={16} />
                                                            </>
                                                        ) : (
                                                            'DOWNLOAD'
                                                        )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            isTableView && (
                                                <Grid item container direction='row' alignItems='center' xs={6} spacing={1} justifyContent='flex-end'>
                                                    <AuthenticatedComponent
                                                        requiredPermissions={['delete:roofSections']}
                                                        component={
                                                            <Grid item xs={3}>
                                                                <FormControlLabel
                                                                    control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                                                    label='Show Inactive'
                                                                    labelPlacement='start'
                                                                />
                                                            </Grid>
                                                        }
                                                    />
                                                    <AuthenticatedComponent
                                                        requiredPermissions={['delete:roofSections']}
                                                        component={
                                                            <Grid item xs={1}>
                                                                <Tooltip title='Make Inactive'>
                                                                    <span>
                                                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive()}>
                                                                            <Delete fontSize='inherit' />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Grid>
                                                        }
                                                    />
                                                    <AuthenticatedComponent
                                                        requiredPermissions={['create:roofSections']}
                                                        component={
                                                            <Grid item xs={1}>
                                                                <Tooltip title='Add New'>
                                                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                                                        <AddCircle fontSize='inherit' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        }
                                                    />
                                                    <AuthenticatedComponent
                                                        requiredPermissions={['create:roofSections', 'edit:roofSections', 'delete:roofSections']}
                                                        logic='or'
                                                        component={
                                                            <Grid item xs={1}>
                                                                <IconButton size='large' onClick={handleMenuOpen}>
                                                                    <MoreVert fontSize='inherit' />
                                                                </IconButton>
                                                                <Menu
                                                                    anchorEl={menuAnchorEl}
                                                                    open={menuOpen}
                                                                    onClose={handleMenuClose()}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}>
                                                                    <AuthenticatedComponent
                                                                        requiredPermissions={['create:roofSections']}
                                                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                                                    />
                                                                    <AuthenticatedComponent
                                                                        requiredPermissions={['edit:roofSections']}
                                                                        component={
                                                                            <MenuItem
                                                                                onClick={handleMenuClose(handleMenuEdit)}
                                                                                disabled={selected.length !== 1}>
                                                                                Edit
                                                                            </MenuItem>
                                                                        }
                                                                    />
                                                                    <AuthenticatedComponent
                                                                        requiredPermissions={['delete:roofSections']}
                                                                        component={
                                                                            <>
                                                                                <Divider />
                                                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                                                                    Make Active
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    onClick={handleMenuClose(handleSetInactive)}
                                                                                    disabled={!canSetInactive()}>
                                                                                    Make Inactive
                                                                                </MenuItem>
                                                                            </>
                                                                        }
                                                                    />
                                                                </Menu>
                                                            </Grid>
                                                        }
                                                    />
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            id='search-box'
                                                            value={searchText}
                                                            onChange={handleSearchChange}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position='start'>
                                                                        <Search />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            label='Search'
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction='row'>
                                {isTableView ? (
                                    <Grid item xs={12} maxWidth={'100% !important'}>
                                        <PaginatedDataTable
                                            columns={tableColumns}
                                            loading={isLoading}
                                            queryResults={data}
                                            defaultSortKey='ROOF_NUMBER'
                                            setPagination={setPaginatedProps}
                                            selected={selected}
                                            setSelected={setSelected}
                                            editPermissions={{ requiredPermissions: ['edit:roofSections'] }}
                                            onEdit={handleRowEdit}
                                            onRowClick={handleRoofSectionClick}
                                            disableSelection={!userHasAnyPermission(['create:roofSections', 'edit:roofSections', 'delete:roofSections'])}
                                        />
                                    </Grid>
                                ) : (
                                    <>
                                        {svgContents ? (
                                            showRoofLeaks && isFetchingRoofLeaks ? (
                                                <LoadingIndicator />
                                            ) : (
                                                <Grid item xs={12}>
                                                    <div>
                                                        <SvgWrapper>
                                                            <SvgEditor
                                                                svgContents={svgContents}
                                                                selectedElement={selectedElement}
                                                                onGroupSelected={handleMapSectionClick}
                                                                colorSections={true}
                                                                roofLeaks={showRoofLeaks ? roofLeaks : []}
                                                                superMapView={true}
                                                                showRoofConditions={showRoofConditions}
                                                                showUnderWarranty={showUnderWarranty}
                                                                onPinClick={handleOnPinClick}
                                                            />
                                                        </SvgWrapper>
                                                    </div>
                                                </Grid>
                                            )
                                        ) : (
                                            <Grid container item xs={12}>
                                                <AuthenticatedComponent
                                                    requiredPermissions={['create:roofMaps']}
                                                    component={
                                                        setSvgContents ? (
                                                            <Grid
                                                                item
                                                                container
                                                                direction='column'
                                                                justifyItems='center'
                                                                alignItems={'center'}
                                                                onClick={() => {
                                                                    setRoofMapDialogOpen(true);
                                                                }}
                                                                sx={{ cursor: 'pointer' }}>
                                                                <Grid item>
                                                                    <AddPhotoAlternate color='primary' sx={{ fontSize: '96px', opacity: '50%' }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography textAlign={'center'}>Click here to add a new roof map</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <Box position='relative' sx={{ width: '100%', height: '375px' }}>
                                                                <NoMapFoundError errorText='Map not available for this facility.' />
                                                            </Box>
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Grid item container direction='row' justifyContent='center'>
                            <Grid item>Please select a roof profile before creating or editing a roof map</Grid>
                        </Grid>
                    )}
                </Grid>
            </RoofMapDrawer>
            <AuthenticatedComponent
                requiredPermissions={['edit:roofSections']}
                component={
                    sectionDialogOpen && (
                        <RoofSectionDialog
                            open={sectionDialogOpen}
                            onClose={handleSectionDialogClose}
                            facilityId={facility.id}
                            roofProfileId={roofProfileId}
                            initValues={selectedSection}
                            reloadProfile={reloadProfile}
                        />
                    )
                }
            />
            <AuthenticatedComponent
                requiredPermissions={['assign:roofSections']}
                component={
                    <SelectRoofSectionsDialog
                        facilityId={facility.id}
                        open={isSelectRoofSectionsDialogOpen}
                        onCancel={handleCancelRoofSectionSelectionDialog}
                        onConfirm={handleConfirmRoofSectionSelectionDialog}
                        onCreateNew={handleCreateNew}
                        onUnlink={handleUnlinkRoofSections}
                        setRoofSections={setRoofSections}
                        currentRoofSections={roofSections}
                        isMultipleSelectable={false}
                        currentRoofProfileId={roofProfileId}
                    />
                }
            />
            {roofMapDialogOpen && (
                <UploadRoofMapDialog
                    open={roofMapDialogOpen}
                    onClose={() => setRoofMapDialogOpen(false)}
                    setSvgContents={setSvgContents!}
                    setFormChanged={setFormChanged}
                    setNeedsToSaveSvg={setNeedsToSaveSvg}
                />
            )}
            {confirmMapDeleteDialogOpen && (
                <ConfirmMapDeleteDialog
                    open={confirmMapDeleteDialogOpen}
                    onClose={() => setMapDeleteDialogOpen(false)}
                    onConfirm={() => handleRemoveMap()}></ConfirmMapDeleteDialog>
            )}
            {selectedRoofLeak !== undefined && activeRoofLeaks && (
                <RoofLeakDetailsDialog
                    anchorEl={selectedRoofLeakAnchorEl}
                    onClose={() => {
                        setSelectedRoofLeak(undefined);
                    }}
                    idx={getRoofLeakIndex(selectedRoofLeak)}
                    onConfirm={(roofSectionId) => {
                        if (roofLeaks) {
                            const temp = [
                                ...roofLeaks.map((x) => {
                                    return { ...x };
                                }),
                            ];

                            const idx = temp.findIndex((x) => x.id === selectedRoofLeak.id);

                            temp[idx] = { ...selectedRoofLeak, roofSectionId: roofSectionId };

                            updateRoofLeak(temp[idx]);
                            setSelectedRoofLeak(undefined);
                            setRoofLeaks([...temp]);
                        }
                    }}
                    open={selectedRoofLeak !== undefined}
                    roofLeak={selectedRoofLeak}
                    handleRoofLeakMove={moveRoofLeak}
                    roofSections={data?.pageResults ?? []}
                    showDetails={true}
                />
            )}
        </>
    );
});
