import { ImmediateRepairsChecklistItemDto, QueryResultsDto } from "../../models";
import { buildSearchQueryRoute } from "../../util";
import { serverApi } from "../api";
import { ChildQueryParams, ImmediateRepairsChecklistItemsChildSearchQueryParams } from "../types";

const immediateRepairsChecklistItemApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getImmediateRepairsChecklistItems: builder.query<QueryResultsDto<ImmediateRepairsChecklistItemDto>, ImmediateRepairsChecklistItemsChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`report/${params.parentId}/immediateRepairsChecklistItem`, params.params),
            providesTags: [{ type: 'Immediate Repairs Checklist Item' }]
        }),
        createImmediateRepairsChecklistItem: builder.mutation<ImmediateRepairsChecklistItemDto, ImmediateRepairsChecklistItemDto>({
            query: (immediateRepairsChecklistItem) => ({
                url: `report/${immediateRepairsChecklistItem.reportId}/immediateRepairsChecklistItem`,
                method: 'POST',
                body: immediateRepairsChecklistItem
            }),
            invalidatesTags: [{ type: 'Immediate Repairs Checklist Item' }]
        }),
        archiveImmediateRepairsChecklistItem: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `report/${params.parentId}/immediateRepairsChecklistItem/${params.childId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Immediate Repairs Checklist Item' }]
        }),
        unarchiveImmediateRepairsChecklistItem: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `report/${params.parentId}/immediateRepairsChecklistItem/${params.childId}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Immediate Repairs Checklist Item' }]
        }),
        updateImmediateRepairsChecklistItem: builder.mutation<void, ImmediateRepairsChecklistItemDto>({
            query: (immediateRepairsChecklistItem) => ({
                url: `report/${immediateRepairsChecklistItem.reportId}/immediateRepairsChecklistItem`,
                method: 'PUT',
                body: immediateRepairsChecklistItem
            }),
            invalidatesTags: [{ type: 'Immediate Repairs Checklist Item' }]
        })
    })
});
export const {
    useGetImmediateRepairsChecklistItemsQuery,
    useCreateImmediateRepairsChecklistItemMutation,
    useArchiveImmediateRepairsChecklistItemMutation,
    useUnarchiveImmediateRepairsChecklistItemMutation,
    useUpdateImmediateRepairsChecklistItemMutation
} = immediateRepairsChecklistItemApi;