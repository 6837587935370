import { serverApi } from '..';
import { DrainageDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const drainageApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDrainages: builder.query<DrainageDto[], DrainageQueryParams>({
            query: (params) => buildSearchQueryRoute(`drainage`, params),
            providesTags: [{ type: 'Drainage' }],
        }),
        createDrainageType: builder.mutation<DrainageDto, DrainageDto>({
            query: (dto) => ({
                url: `drainage`,
                method: 'POST',
                body: dto,
            }),
            invalidatesTags: [{ type: 'Drainage' }],
        }),
    }),
});

export const { useGetDrainagesQuery, useCreateDrainageTypeMutation, useLazyGetDrainagesQuery } = drainageApi;

export interface DrainageQueryParams {
    includeInactive?: boolean;
}
