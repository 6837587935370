import { Folder } from '@mui/icons-material';
import { FunctionComponent, useCallback } from 'react';
import { DocumentFolderDto, emptyGuid } from '../../../models';
import { useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../../util/customHooks';
import { QuickAddDialog } from '../../core/QuickAddDialog';
import { useCreateDocumentFolderMutation, useUpdateDocumentFolderMutation } from '../../../store/apis/document-folder-api';

export interface IAddDocumentFolderDialogProps {
    open: boolean;
    onClose: () => void;
    initValues?: DocumentFolderDto | null;
    workOrderId: string;
}

export const AddDocumentFolderDialog: FunctionComponent<IAddDocumentFolderDialogProps> = (props) => {
    const { open, onClose, initValues, workOrderId } = props;
    const [create, { isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateDocumentFolderMutation();
    const [update, { isError: isUpdateError, isSuccess: isUpdateSuccess, reset: resetUpdate }] = useUpdateDocumentFolderMutation();

    const save = useCallback((formValue: string) => {
        if (initValues && initValues?.id !== emptyGuid) {
            update({
                id: initValues.id,
                isActive: true,
                createdOn: initValues.createdOn,
                name: formValue,
                workOrderId: initValues?.workOrderId
            });
        } else {
            create({
                id: emptyGuid,
                name: formValue,
                isActive: true,
                workOrderId
            });
        }
    }, [initValues, create, update, workOrderId]);

    useFailedCreateSnackbar('folder', isCreateError, resetCreate);
    useFailedUpdateSnackbar('folder', isUpdateError, resetUpdate);
    useSuccessfulCreateSnackbar('Folder', isCreateSuccess, resetCreate);
    useSuccessfulUpdateSnackbar('Folder', isUpdateSuccess, resetUpdate);

    return (
        <QuickAddDialog
            open={open}
            onClose={onClose}
            formLabel={`Name${initValues?.isRequired ? ' (Required and cannot be changed)' : ''}`}
            title={initValues?.id ? 'Edit Folder' : 'New Folder'}
            titleIcon={<Folder />}
            cancelText={"Cancel"}
            confirmText={"Save"}
            initValue={initValues?.name}
            save={save}
            disabled={initValues?.isRequired}
        />
    );
};

