import { serverApi } from '..';
import { QueryResultsDto, WorkOrderStatusDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';
import { SearchQueryParams } from '../types';

const workOrderStatusApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkOrderStatuses: builder.query<QueryResultsDto<WorkOrderStatusDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('workorder/status', params),
            providesTags: [{ type: 'Work Order Status' }]
        })
    })
});

export const {
    useGetWorkOrderStatusesQuery,
} = workOrderStatusApi;