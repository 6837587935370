export const sortByNumericPrefix = <T>(arr: T[], propertyName: keyof T): T[] => {
    const sortedArr = arr.sort((a, b) => {
        const aValue: unknown = a[propertyName];
        const bValue: unknown = b[propertyName];

        const regex = /^(\d+)?([A-Za-z]*)$/;
        const matchA = String(aValue).replace(/\s/g, '').match(regex);
        const matchB = String(bValue).replace(/\s/g, '').match(regex);

        const numPartA = matchA && matchA[1] ? parseInt(matchA[1], 10) : 0;
        const numPartB = matchB && matchB[1] ? parseInt(matchB[1], 10) : 0;

        const alphaPartA = (matchA && matchA[2]) || '';
        const alphaPartB = (matchB && matchB[2]) || '';

        if (numPartA !== numPartB) {
            return numPartA - numPartB;
        }
        return alphaPartA.localeCompare(alphaPartB);
    });

    return sortedArr;
};
