import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { RoofSectionReportForm } from '../../Components/Forms/RoofSectionReportForm';
import { RoofSectionReportDto } from '../../models/RoofSectionReportDto';
import { useGetReportQuery } from '../../store/apis/report-api';
import { useGetRoofSectionReportQuery, useUpdateRoofSectionReportMutation } from '../../store/apis/roof-section-report-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditRoofSectionReportView: FC = () => {
    const { reportId, id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading: roofSectionReportLoading, error: roofSectionReportError, refetch: roofSectionReportRefetch } = useGetRoofSectionReportQuery({ parentId: reportId!, childId: id! });
    const [updateRoofSectionReport, { isSuccess: isUpdateSuccess, isError: isUpdateError, error, reset: resetUpdate }] = useUpdateRoofSectionReportMutation();
    const { data: clientReport, isLoading: clientReportLoading, isError: clientReportError, refetch: clientReportRefetch } = useGetReportQuery(reportId!);

    useFailedUpdateSnackbar('roof section', isUpdateError, resetUpdate, error);
    useSuccessfulUpdateSnackbar('Roof section', isUpdateSuccess);

    const handleSave = (values: RoofSectionReportDto) => {
        updateRoofSectionReport(values);
    };

    const handleCancel = () => {
        navigate(`/clientreports/edit/${reportId}`);
    };

    if (roofSectionReportLoading || clientReportLoading) {
        return <LoadingIndicator />;
    }

    if (roofSectionReportError) {
        return <ApiError onReloadClick={roofSectionReportRefetch} />;
    }

    if (clientReportError) {
        return <ApiError onReloadClick={clientReportRefetch} />;
    }

    return <RoofSectionReportForm clientReport={clientReport!} save={handleSave} cancel={handleCancel} initValues={data} />;
};
