import { serverApi } from '..';
import { QueryResultsDto, WarrantyDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const warrantyApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getClientWarranties: builder.query<QueryResultsDto<WarrantyDto>, WarrantySearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`warranty`, params),
            providesTags: [{ type: 'Warranty' }]
        }),
        getWarranty: builder.query<WarrantyDto, string>({
            query: (id) => ({ url: `warranty/${id}` }),
            providesTags: [{ type: 'Warranty' }]
        }),
        createWarranty: builder.mutation<WarrantyDto, WarrantyDto>({
            query: (warrantyDto) => ({
                url: 'warranty',
                method: 'POST',
                body: warrantyDto
            }),
            invalidatesTags: [{ type: 'Warranty' }]
        }),
        updateWarranty: builder.mutation<void, WarrantyDto>({
            query: (warrantyDto) => ({
                url: 'warranty',
                method: 'PUT',
                body: warrantyDto
            }),
            invalidatesTags: [{ type: 'Warranty' }]
        }),
        archiveWarranty: builder.mutation<void, string>({
            query: (id) => ({
                url: `warranty/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Warranty' }]
        }),
        unarchiveWarranty: builder.mutation<void, string>({
            query: (id) => ({
                url: `warranty/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Warranty' }]
        })
    })
});

export const {
    useGetClientWarrantiesQuery,
    useGetWarrantyQuery,
    useCreateWarrantyMutation,
    useUpdateWarrantyMutation,
    useArchiveWarrantyMutation,
    useUnarchiveWarrantyMutation
} = warrantyApi;

export interface WarrantySearchQueryParams {
    searchText?: string;
    sortKey?: string;
    sortAsc?: boolean;
    page?: number;
    pageSize?: number;
    includeInactive?: boolean;
    clientId?: string;
    facilityId?: string;
    issuedYear?: number;
    type?: string;
}
