import { serverApi } from '..';
import { InsulationDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const insulationApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getInsulations: builder.query<InsulationDto[], InsulationQueryParams>({
            query: (params) => buildSearchQueryRoute(`insulation`, params),
            providesTags: [{ type: 'Insulation' }],
        }),
        createInsulationType: builder.mutation<InsulationDto, InsulationDto>({
            query: (dto) => ({
                url: `insulation`,
                method: 'POST',
                body: dto,
            }),
            invalidatesTags: [{ type: 'Insulation' }],
        }),
    }),
});

export const { useGetInsulationsQuery, useCreateInsulationTypeMutation } = insulationApi;

export interface InsulationQueryParams {
    includeInactive?: boolean;
}
