import { useAuth0 } from '@auth0/auth0-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingIndicator from './Components/core/LoadingIndicator';
import { NotAuthorizedError } from './Components/core/NotAuthorizedError/NotAuthorizedError';
import Shell from './Components/core/Shell';
import { publicRoutes, routes } from './routing/Routes';
import { IRoute } from './routing/types';
import { setToken, useAppDispatch } from './store';
import theme from './Theme';
import jwt from 'jsonwebtoken';
import { userHasPermissions } from './auth';
import { UserPermissionContext } from './Contexts';
import { clarity } from 'clarity-js';


function App() {
    const auth = useAuth0();
    const dispatch = useAppDispatch();
    const [accessTokenSet, setAccessTokenSet] = useState(false);
    const [userPermissions, setUserPermissions] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        (async () => {
            if (auth.isAuthenticated) {
                if (!userPermissions) {
                    const accessToken = await auth.getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
                    const decodedToken = jwt.decode(accessToken);
                    const permissions = ((decodedToken as any)?.permissions as string[]) ?? [];

                    setUserPermissions(permissions);
                    dispatch(setToken(accessToken));
                    setAccessTokenSet(true);
                }
            }
        })();
    }, [userPermissions, setUserPermissions, auth, dispatch]);

    useEffect(() => {
        let clarityProjectId = process.env.REACT_APP_CLARITY_PROJECT_ID;
        if (auth.isAuthenticated && clarityProjectId) {
            clarity.consent();
            clarity.identify(auth.user?.email ?? "");

            clarity.start({
                projectId: clarityProjectId,
                upload: 'https://m.clarity.ms/collect',
                track: true,
                content: true,
            });
            return () => {
                clarity.stop();
            };
        }
    }, [auth]);


    const renderRoute = useCallback(
        (route: IRoute) => {
            if (userPermissions && userHasPermissions(route.requiredPermissions ?? [], userPermissions, route.logic)) {
                return <Route key={route.path} path={route.path} element={route.component} />;
            } else {
                return <Route key={route.path} path={route.path} element={<NotAuthorizedError />} />;
            }
        },
        [userPermissions]
    );

    if (auth.isLoading || (auth.isAuthenticated && !accessTokenSet)) {
        return <LoadingIndicator />;
    }

    if (auth.error) {
        return <p>{auth.error.message}</p>;
    }

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SnackbarProvider maxSnack={3}>
                            <UserPermissionContext.Provider
                                value={{
                                    permissions: userPermissions ?? [],
                                }}>
                                <Routes>
                                    <Route path='/' element={<Shell isProtected={true} />}>
                                        {routes.map(renderRoute)}
                                    </Route>
                                    <Route path='/' element={<Shell />}>
                                        {publicRoutes.map(renderRoute)}
                                    </Route>
                                </Routes>
                            </UserPermissionContext.Provider>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </CssBaseline>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
