import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { RoofProfileForm } from '../../Components/Forms/RoofProfileForm';
import { RoofProfileDto } from '../../models';
import { useGetFacilityQuery } from '../../store/apis/facility-api';
import { useGetRoofProfileQuery, useUpdateRoofProfileMutation } from '../../store/apis/roof-profile-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditRoofProfileView: FunctionComponent = () => {
    const { id, facilityId } = useParams();
    const navigate = useNavigate();
    const { data: facility, isLoading: facilityLoading, error: facilityError, refetch: facilityRefetch } = useGetFacilityQuery(facilityId!);
    const {
        data: roofProfileData,
        isLoading: roofProfileLoading,
        error: roofProfileError,
        refetch: roofProfileRefetch,
    } = useGetRoofProfileQuery({ id: id!, includeSvg: true });
    const [updateRoofProfile, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateRoofProfileMutation();
    const [roofProfile, setRoofProfile] = useState<RoofProfileDto>();

    useFailedUpdateSnackbar('roof plan', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Roof plan', isUpdateSuccess, () => {
        navigate(0);
    });

    useEffect(() => {
        if (roofProfileData && !roofProfileLoading) {
            setRoofProfile(roofProfileData);
        }
    }, [roofProfile, setRoofProfile, roofProfileLoading, roofProfileData, roofProfileRefetch]);

    const handleReload = () => {
        roofProfileRefetch();
        setRoofProfile(roofProfileData);
    };

    const handleSave = (values: RoofProfileDto) => {
        updateRoofProfile(values);
    };

    const handleCancel = () => {
        navigate(`/clients/${facility?.clientId}/facilities/edit/${facility?.id}`);
    };

    if (facilityLoading || roofProfileLoading || !roofProfile) {
        return <LoadingIndicator />;
    }

    if (facilityError) {
        return <ApiError onReloadClick={facilityRefetch} />;
    }

    if (roofProfileError) {
        return <ApiError onReloadClick={roofProfileRefetch} />;
    }

    return (
        <RoofProfileForm
            client={facility!.client}
            facility={facility!}
            save={handleSave}
            cancel={handleCancel}
            initValues={roofProfile}
            reloadProfile={handleReload}
        />
    );
};
