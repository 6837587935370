import { FunctionComponent, useCallback, useState } from 'react';
import { FormControlLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Switch } from '@mui/material';
import { AddCircle, Business, Edit } from '@mui/icons-material';
import { DashboardCard } from '../DashboardCard';
import { IClientCardProps } from './types';
import { useGetClientFacilitiesQuery } from '../../../store/apis/facility-api';
import { ApiError } from '../../core/ApiError';
import LoadingIndicator from '../../core/LoadingIndicator';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../../../auth';

export const FacilitiesCard: FunctionComponent<IClientCardProps> = (props) => {
    const { clientId, facility } = props;
    const navigate = useNavigate();
    const [showInactive, setShowInactive] = useState(false);
    const [hoverRow, setHoverRow] = useState('');
    const { data, isLoading, error, refetch } = useGetClientFacilitiesQuery({
        parentId: clientId,
        params: {
            sortKey: 'NAME',
            includeInactive: showInactive,
        },
    });

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setShowInactive(event.target.checked);
    }, []);

    const handleRowOnMouseEnter = useCallback((id: string) => {
        setHoverRow(id);
    }, []);

    const handleRowOnMouseLeave = useCallback(
        (id: string) => {
            if (hoverRow === id) {
                setHoverRow('');
            }
        },
        [hoverRow]
    );

    const handleAddFacility = useCallback(() => {
        navigate(`/clients/${clientId}/facilities/create`);
    }, [clientId, navigate]);

    const handleEditFacility = useCallback(
        (id: string) => () => {
            navigate(`/clients/${clientId}/facilities/edit/${id}`);
        },
        [clientId, navigate]
    );

    const handleSelectFacility = useCallback(
        (id: string) => () => {
            navigate(`/clients/${clientId}/facility/${id}`);
        },
        [clientId, navigate]
    );

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <DashboardCard headerIcon={<Business />} headerTitle='Facilities'>
            <Grid container direction='column' justifyContent='space-between' sx={{ height: '560px' }}>
                <Grid item sx={{ overflowY: 'auto', height: '508px' }}>
                    <List>
                        {data!.pageResults.map((facilityRow) => (
                            <ListItem
                                key={facilityRow.id}
                                sx={facilityRow.isActive ? {} : { borderLeft: '#AAAAAA 10px solid' }}
                                disablePadding
                                onMouseEnter={() => handleRowOnMouseEnter(facilityRow.id)}
                                onMouseLeave={() => handleRowOnMouseLeave(facilityRow.id)}
                                secondaryAction={
                                    facilityRow.id === hoverRow && (
                                        <AuthenticatedComponent
                                            requiredPermissions={['edit:facilities']}
                                            component={
                                                <IconButton edge='end' onClick={handleEditFacility(facilityRow.id)}>
                                                    <Edit />
                                                </IconButton>
                                            }
                                        />
                                    )
                                }>
                                <ListItemButton
                                    onClick={handleSelectFacility(facilityRow.id)}
                                    selected={facilityRow.id === hoverRow || (facility?.id !== undefined && facilityRow.id === facility?.id)}>
                                    <ListItemText primary={facilityRow.name} sx={facilityRow.isActive ? {} : { marginLeft: '-10px', color: '#AAAAAA' }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item container direction='row' justifyContent='space-between'>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:facilities']}
                        component={
                            <Grid item container direction='row' alignItems='center' xs={9}>
                                <FormControlLabel
                                    control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                    label='Show Inactive'
                                    labelPlacement='start'
                                />
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:facilities']}
                        component={
                            <Grid item>
                                <IconButton color='primary' size='large' onClick={handleAddFacility}>
                                    <AddCircle fontSize='inherit' />
                                </IconButton>
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
        </DashboardCard>
    );
};
