import { Lock } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { FormButton } from '..';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { FormWrapperProps } from './types';

export const FormWrapper: FC<FormWrapperProps> = (props) => {
    const { breadCrumbProps, cancel, icon, isFormDirty, name, save, isLocked, submit, saveButtonText = 'Save', submitButtonText = 'Submit', allowSaveOnLocked = false, unlock, disabledSave, disabledSubmit } = props;

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off'>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        {icon} {name}
                    </Typography>
                    <NavBreadcrumbs {...breadCrumbProps} />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    {isLocked && <Grid item marginTop={'8px'} >
                        <IconButton onClick={unlock} disabled={!unlock}>
                            <Lock sx={{ color: 'black' }} />
                        </IconButton>
                    </Grid>}
                    <Grid item>
                        <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={cancel}>
                            {isFormDirty ? 'Cancel' : 'Close'}
                        </FormButton>
                    </Grid>
                    <Grid item>
                        <FormButton itemType='button' variant='contained' color='primary' type='button' onClick={save} disabled={disabledSave || (isLocked && !allowSaveOnLocked)}>
                            {saveButtonText}
                        </FormButton>
                    </Grid>
                    {submit && <Grid item>
                        <FormButton itemType='button' variant='contained' color='secondary' type='button' onClick={submit} disabled={disabledSubmit || (isLocked && !allowSaveOnLocked)}>
                            {submitButtonText}
                        </FormButton>
                    </Grid>}
                </Grid>
            </Grid>
            {props.children}
        </Grid>
    );
};
