import { useEffect, useMemo, useState } from "react";
import { AuthRoles } from "../../Components/core/AuthRoles";
import { useAuth0 } from "@auth0/auth0-react";

export function useUserRoleChecker() {
    const auth = useAuth0();
    const [userRole, setUserRole] = useState('');
    const isContractor = useMemo(() => [AuthRoles.Contractor, AuthRoles.EmployeeContractor].includes(userRole as AuthRoles), [userRole]);
    const isAdmin = useMemo(() => userRole === AuthRoles.Admin, [userRole]);
    const isEmployee = useMemo(() => [AuthRoles.Employee, AuthRoles.EmployeeContractor].includes(userRole as AuthRoles), [userRole]);
    
    useEffect(() => {
        if (!auth.isLoading && auth.isAuthenticated && auth.user) {
            const rolesFieldName = process.env.REACT_APP_AUTH0_Roles!;
            if (auth.user && auth.user[rolesFieldName].length > 0) {
                setUserRole(auth.user[rolesFieldName][0]);
            }
        }
    }, [auth]);

    return {
        isContractor,
        isAdmin,
        isEmployee
    };
}