import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkOrderAndCapitalProjectForm } from '../../Components/Forms/WorkOrderForm';
import { WorkOrderDto } from '../../models';
import { useCreateWorkOrderMutation } from '../../store/apis/work-order-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddWorkOrderView: FunctionComponent = () => {
    const { clientId, facilityId } = useParams();
    const navigate = useNavigate();
    const [saveError, setSaveError] = useState('');
    const [createWorkOrder, { data, error, isError, isSuccess, reset }] = useCreateWorkOrderMutation();

    useFailedCreateSnackbar('Work Order', isError, reset);
    useSuccessfulCreateSnackbar('Work Order', isSuccess, () => navigate(`/workorders/edit/${data?.id}`));

    useEffect(() => {
        if (error && 'data' in error) {
            if (error.data === 'Work Order number already exists') {
                setSaveError(error.data);
            }
        }
    }, [error, setSaveError]);

    const handleSave = (values: WorkOrderDto) => {
        createWorkOrder(values);
    };

    const handleCancel = () => {
        navigate(`/workorders`);
    };

    return (
        <WorkOrderAndCapitalProjectForm
            save={handleSave}
            cancel={handleCancel}
            woNumberError={saveError}
            clientId={clientId ?? undefined}
            facilityId={facilityId ?? undefined}
        />
    );
};
