import { AddCircle, ArrowDropDown, ArrowDropUp, Delete } from '@mui/icons-material';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { forwardRef, Ref, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import {
    AttachmentMethodDto,
    CoreLayerTypeDto,
    CoreLayerTypeIds,
    DeckTypeDto,
    emptyGuid,
    InsulationDto,
    InsulationThicknessDto,
    RoofLayerDto,
    RoofSystemDto,
    SurfacingDto,
} from '../../../models';
import { useGetAttachmentMethodsQuery } from '../../../store/apis/attachment-method-api';
import { useGetCoreLayerTypesQuery } from '../../../store/apis/core-layer-type-api';
import { useCreateDeckTypeMutation, useGetDeckTypesQuery } from '../../../store/apis/deck-type-api';
import { useCreateInsulationTypeMutation, useGetInsulationsQuery } from '../../../store/apis/insulation-api';
import { useGetInsulationThicknessesQuery } from '../../../store/apis/insulation-thickness-api';
import { useCreateRoofSystemMutation, useGetRoofSystemsQuery } from '../../../store/apis/roof-api';
import { useCreateSurfacingTypeMutation, useGetSurfacingTypesQuery } from '../../../store/apis/surfacing-type-api';
import { ApiError } from '../../core/ApiError';
import LoadingIndicator from '../../core/LoadingIndicator';
import { FormInput, FormNumberInput, patterns } from '../FormFields';
import { IRoofMapSectionCoreInfoProps, ISectionRef } from '../RoofMap/types';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import _ from 'lodash';
import { useFailedCreateSnackbar, useStandardSnackbar, useSuccessfulCreateSnackbar } from '../../../util/customHooks';
import { getErrorMessage } from '../../../util/errorHelper';

export const RoofSectionCoreInfo = forwardRef((props: IRoofMapSectionCoreInfoProps, ref: Ref<ISectionRef>) => {
    const { roofLayers, setRoofLayers, viewOnly } = props;

    useImperativeHandle(ref, () => ({ isValid }));
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const [selectedSubIndex, setSelectedSubIndex] = useState<number>();
    const [hoverButton, setHoverButton] = useState(false);
    const {
        data: coreLayerTypes,
        error: coreLayerTypesError,
        isLoading: coreLayerTypesLoading,
        refetch: refetchCoreLayerTypes,
    } = useGetCoreLayerTypesQuery({ includeInactive: true });
    const {
        data: insulationTypes,
        error: insulationTypesError,
        isLoading: insulationTypesLoading,
        refetch: refetchInsulationTypes,
    } = useGetInsulationsQuery({ includeInactive: true });
    const { data: deckTypes, error: deckTypesError, isLoading: deckTypesLoading, refetch: refetchDeckTypes } = useGetDeckTypesQuery({ includeInactive: true });
    const {
        data: insulationAttachmentMethods,
        error: insulationAttachmentMethodsError,
        isLoading: insulationAttachmentMethodsLoading,
        refetch: refetchInsulationAttachmentMethods,
    } = useGetAttachmentMethodsQuery({ layerType: CoreLayerTypeIds.INSULATION, includeInactive: true });
    const {
        data: roofSystemAttachmentMethods,
        error: roofSystemAttachmentMethodsError,
        isLoading: roofSystemAttachmentMethodsLoading,
        refetch: refetchRoofSystemAttachmentMethods,
    } = useGetAttachmentMethodsQuery({ layerType: CoreLayerTypeIds.ROOF_SYSTEM, includeInactive: true });
    const {
        data: thicknesses,
        error: thicknessesError,
        isLoading: thicknessesLoading,
        refetch: refetchThicknesses,
    } = useGetInsulationThicknessesQuery({ includeInactive: true });
    const {
        data: roofSystems,
        error: roofSystemsError,
        isLoading: roofSystemsLoading,
        refetch: refetchRoofSystems,
    } = useGetRoofSystemsQuery({ includeInactive: true });
    const {
        data: surfacingTypes,
        error: surfacingTypesError,
        isLoading: surfacingTypesLoading,
        refetch: refetchSurfacingTypes,
    } = useGetSurfacingTypesQuery({ includeInactive: true });

    const [createDeckType, { isSuccess: isCreateDeckTypeSuccess, isError: isCreateDeckTypeError, error: createDeckTypeError, reset: resetDeckType, data: newDeckType }] = useCreateDeckTypeMutation();
    const isDeckTypeUniqueError = !!createDeckTypeError && (getErrorMessage(createDeckTypeError) === "Deck Type already exists");
    useFailedCreateSnackbar('deck Type', isCreateDeckTypeError && !isDeckTypeUniqueError, resetDeckType);
    useStandardSnackbar(isDeckTypeUniqueError, "Deck Type already exists", 'error', resetDeckType);
    useSuccessfulCreateSnackbar('Deck Type', isCreateDeckTypeSuccess, () => {
        onDeckTypeChange(_, newDeckType ?? null);
        resetDeckType();
    });

    const [createInsulationType, { isSuccess: isCreateInsulationTypeSuccess, isError: isCreateInsulationTypeError, error: createInsulationTypeError, reset: resetInsulationType, data: newInsulationType }] = useCreateInsulationTypeMutation();
    const isInsulationTypeUniqueError = !!createInsulationTypeError && (getErrorMessage(createInsulationTypeError) === "Insulation Type already exists");
    useFailedCreateSnackbar('insulation Type', isCreateInsulationTypeError && !isInsulationTypeUniqueError, resetInsulationType);
    useStandardSnackbar(isInsulationTypeUniqueError, "Insulation Type already exists", 'error', resetInsulationType);
    useSuccessfulCreateSnackbar('Insulation Type', isCreateInsulationTypeSuccess, () => {
        onInsulationTypeChange(_, newInsulationType ?? null);
        resetInsulationType();
    });

    const [createSurfacingType, { isSuccess: isCreateSurfacingTypeSuccess, isError: isCreateSurfacingTypeError, error: createSurfacingTypeError, reset: resetSurfacingType, data: newSurfacingType }] = useCreateSurfacingTypeMutation();
    const isSurfacingTypeUniqueError = !!createSurfacingTypeError && (getErrorMessage(createSurfacingTypeError) === "Surfacing Type already exists");
    useFailedCreateSnackbar('surfacing Type', isCreateSurfacingTypeError && !isSurfacingTypeUniqueError, resetSurfacingType);
    useStandardSnackbar(isSurfacingTypeUniqueError, "Surfacing Type already exists", 'error', resetSurfacingType);
    useSuccessfulCreateSnackbar('Surfacing Type', isCreateSurfacingTypeSuccess, () => {
        onSurfacingTypeChange(_, newSurfacingType ?? null);
        resetSurfacingType();
    });

    const [createRoofSystem, { isSuccess: isCreateRoofSystemSuccess, isError: isCreateRoofSystemError, error: createRoofSystemError, reset: resetRoofSystem, data: newRoofSystem }] = useCreateRoofSystemMutation();
    const isRoofSystemUniqueError = !!createRoofSystemError && (getErrorMessage(createRoofSystemError) === "Roof System already exists");
    useFailedCreateSnackbar('roof System', isCreateRoofSystemError && !isRoofSystemUniqueError, resetRoofSystem);
    useStandardSnackbar(isRoofSystemUniqueError, "Roof System already exists", 'error', resetRoofSystem);
    useSuccessfulCreateSnackbar('Roof System', isCreateRoofSystemSuccess, () => {
        onRoofSystemChange(_, newRoofSystem ?? null);
        resetRoofSystem();
    });

    const saveDeckType = useCallback((formValue: string) => {
        createDeckType({
            id: emptyGuid,
            name: formValue,
            isActive: true
        });
    }, [createDeckType]);

    const saveInsulationType = useCallback((formValue: string) => {
        createInsulationType({
            id: emptyGuid,
            name: formValue,
            isActive: true
        });
    }, [createInsulationType]);

    const saveSurfacingType = useCallback((formValue: string) => {
        createSurfacingType({
            id: emptyGuid,
            name: formValue,
            isActive: true
        });
    }, [createSurfacingType]);

    const saveRoofSystem = useCallback((formValue: string) => {
        createRoofSystem({
            id: emptyGuid,
            name: formValue,
            isActive: true
        });
    }, [createRoofSystem]);

    const [isTapered, setIsTapered] = useState(false);
    const [errorText, setErrorText] = useState({
        CORE_LAYER_TYPE: '',
        NAME: '',
        DECK_TYPE: '',
        INSULATION_TYPE: '',
        ATTACHMENT_METHOD: '',
        INSULATION_THICKNESS: '',
        ROOF_SYSTEM: '',
        ROOF_SYSTEM_THICKNESS: '',
        SURFACING_TYPE: '',
    });

    const refetch = useCallback(() => {
        refetchCoreLayerTypes();
        refetchInsulationTypes();
        refetchDeckTypes();
        refetchInsulationAttachmentMethods();
        refetchRoofSystemAttachmentMethods();
        refetchThicknesses();
        refetchRoofSystems();
        refetchSurfacingTypes();
    }, [refetchCoreLayerTypes, refetchDeckTypes, refetchInsulationAttachmentMethods, refetchInsulationTypes, refetchRoofSystemAttachmentMethods, refetchRoofSystems, refetchSurfacingTypes, refetchThicknesses]);

    const moveSelectedLayer = useCallback((direction: number) => {
        let tempRoofLayerGroups = _.groupBy(roofLayers, x => x.order);
        const targetIndex = selectedIndex! + direction;

        tempRoofLayerGroups[selectedIndex!] = tempRoofLayerGroups[selectedIndex!].map((x) => ({
            ...x,
            order: targetIndex,
        }));
        tempRoofLayerGroups[targetIndex!] = tempRoofLayerGroups[targetIndex!].map((x) => ({
            ...x,
            order: selectedIndex!,
        }));

        let tempRoofLayers: RoofLayerDto[] = [];
        for (const key in tempRoofLayerGroups) {
            tempRoofLayers = tempRoofLayers.concat(...tempRoofLayerGroups[key]);
        }
        setSelectedIndex(targetIndex);
        const emptyRoofLayers: RoofLayerDto[] = [];
        setRoofLayers(emptyRoofLayers.concat(tempRoofLayers)); // triggers view state update
        setHoverButton(false);
    }, [roofLayers, selectedIndex, setRoofLayers]);

    const deleteSelectedLayer = useCallback(() => {
        let tempRoofLayerGroups = _.groupBy(roofLayers, x => x.order);
        tempRoofLayerGroups[selectedIndex!].splice(selectedSubIndex!, 1);
        tempRoofLayerGroups[selectedIndex!] = tempRoofLayerGroups[selectedIndex!].map((x, index) => ({
            ...x,
            subLayerOrder: index,
        }));

        let tempRoofLayers: RoofLayerDto[] = [];
        let order = 0;
        for (const key in tempRoofLayerGroups) {
            if (tempRoofLayerGroups[key].length > 0) {
                // eslint-disable-next-line no-loop-func
                tempRoofLayers = tempRoofLayers.concat(...tempRoofLayerGroups[key].map((x) => ({
                    ...x,
                    order: order
                })));
                order += 1;
            }
        }

        const emptyRoofLayers: RoofLayerDto[] = [];
        setRoofLayers(emptyRoofLayers.concat(tempRoofLayers)); // triggers view state update
        setSelectedIndex(undefined);
        setSelectedSubIndex(undefined);
        setHoverButton(false);
    }, [roofLayers, selectedIndex, selectedSubIndex, setRoofLayers]);

    const updateSelectedLayer = useCallback((newLayer: RoofLayerDto) => {
        let tempRoofLayers = roofLayers.map((x) => x);
        tempRoofLayers[tempRoofLayers.findIndex(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex)] = newLayer;

        const emptyRoofLayers: RoofLayerDto[] = [];
        setRoofLayers(emptyRoofLayers.concat(tempRoofLayers)); // triggers view state update
    }, [roofLayers, selectedIndex, selectedSubIndex, setRoofLayers]);

    const isValid = useCallback(() => {
        if (!roofLayers || selectedIndex === undefined || selectedSubIndex === undefined) {
            return true;
        }
        const roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return true;
        }
        if (roofLayer.coreLayerTypeId === '') {
            setErrorText({
                CORE_LAYER_TYPE: 'Type is required',
                NAME: '',
                DECK_TYPE: '',
                INSULATION_TYPE: '',
                ATTACHMENT_METHOD: '',
                INSULATION_THICKNESS: '',
                ROOF_SYSTEM: '',
                ROOF_SYSTEM_THICKNESS: '',
                SURFACING_TYPE: '',
            });
            return false;
        }
        let isValid = true;
        if (roofLayer.name !== '') {
            errorText.NAME = '';
        } else {
            isValid = false;
            errorText.NAME = 'Name is required';
        }
        if (roofLayer.coreLayerTypeId === CoreLayerTypeIds.DECK) {
            if (roofLayer.deckTypeId) {
                errorText.DECK_TYPE = '';
            } else {
                isValid = false;
                errorText.DECK_TYPE = 'Deck Type is required';
            }
        } else if (roofLayer.coreLayerTypeId === CoreLayerTypeIds.INSULATION) {
            if (roofLayer.insulationId) {
                errorText.INSULATION_TYPE = '';
            } else {
                isValid = false;
                errorText.INSULATION_TYPE = 'Insulation Type is required';
            }
            if (roofLayer.attachmentMethodId) {
                errorText.ATTACHMENT_METHOD = '';
            } else {
                isValid = false;
                errorText.ATTACHMENT_METHOD = 'Attachment Method is required';
            }
            if (!roofLayer.roofSystemThickness) {
                isValid = false;
                errorText.ROOF_SYSTEM_THICKNESS = 'Thickness is required';
            } else if (!patterns.Inches.validate.test(roofLayer.roofSystemThickness!)) {
                isValid = false;
                errorText.ROOF_SYSTEM_THICKNESS = 'Thickness must be in increments of 0.25';
            } else {
                errorText.ROOF_SYSTEM_THICKNESS = '';
            }
        } else if (roofLayer.coreLayerTypeId === CoreLayerTypeIds.ROOF_SYSTEM) {
            if (roofLayer.roofSystemId) {
                errorText.ROOF_SYSTEM = '';
            } else {
                isValid = false;
                errorText.ROOF_SYSTEM = 'Roof Type is required';
            }
        } else if (roofLayer.coreLayerTypeId === CoreLayerTypeIds.SURFACING) {
            if (roofLayer.surfacingTypeId) {
                errorText.SURFACING_TYPE = '';
            } else {
                isValid = false;
                errorText.SURFACING_TYPE = 'Surfacing Type is required';
            }
        }
        setErrorText({
            CORE_LAYER_TYPE: '',
            NAME: errorText.NAME,
            DECK_TYPE: errorText.DECK_TYPE,
            INSULATION_TYPE: errorText.INSULATION_TYPE,
            ATTACHMENT_METHOD: errorText.ATTACHMENT_METHOD,
            INSULATION_THICKNESS: errorText.INSULATION_THICKNESS,
            ROOF_SYSTEM: errorText.ROOF_SYSTEM,
            ROOF_SYSTEM_THICKNESS: errorText.ROOF_SYSTEM_THICKNESS,
            SURFACING_TYPE: errorText.SURFACING_TYPE,
        });
        return isValid;
    }, [errorText, roofLayers, selectedIndex, selectedSubIndex]);

    const selectLayer = useCallback((index: number, subIndex: number) => {
        if (!hoverButton && isValid()) {
            setSelectedIndex(index);
            setSelectedSubIndex(subIndex);
            setIsTapered(roofLayers.find(x => x.order === index && x.subLayerOrder === subIndex)?.isTapered!);
        }
    }, [hoverButton, isValid, roofLayers]);

    const addNewLayer = useCallback(() => {
        if (isValid()) {
            setSelectedIndex(0);
            setSelectedSubIndex(0);
            setRoofLayers(
                [
                    {
                        id: emptyGuid,
                        isActive: true,
                        name: '',
                        order: 0,
                        subLayerOrder: 0,
                        coreLayerTypeId: '',
                    },
                ].concat(roofLayers.map((layer) => ({ ...layer, order: layer.order + 1 })))
            );
        }
    }, [isValid, roofLayers, setRoofLayers]);

    const addNewSubLayer = useCallback((layerIndex: number) => {
        if (isValid()) {
            setSelectedIndex(layerIndex);
            let subLayerOrder = 0;
            const layersAtIndex = roofLayers.filter(x => x.order === layerIndex).sort((a, b) => b.subLayerOrder - a.subLayerOrder);
            if (layersAtIndex.length >= 5) {
                return;
            } else if (layersAtIndex.length > 0) {
                subLayerOrder = layersAtIndex[0].subLayerOrder + 1;
            }
            setSelectedSubIndex(subLayerOrder);
            setRoofLayers(
                [
                    {
                        id: emptyGuid,
                        isActive: true,
                        name: '',
                        order: layerIndex,
                        subLayerOrder: subLayerOrder,
                        coreLayerTypeId: '',
                    },
                ].concat(roofLayers.map((layer) => ({ ...layer })))
            );
        }
    }, [isValid, roofLayers, setRoofLayers]);

    const onCoreLayerTypeChange = useCallback((_e: any, selectedLayer: CoreLayerTypeDto | null) => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        roofLayer = {
            id: emptyGuid,
            isActive: true,
            order: roofLayer.order,
            subLayerOrder: roofLayer.subLayerOrder,
            coreLayerTypeId: selectedLayer?.id ?? "",
            coreLayerType: selectedLayer ?? undefined,
            name: selectedLayer?.name!,
        };
        updateSelectedLayer(roofLayer);
        setErrorText({
            CORE_LAYER_TYPE: '',
            NAME: '',
            DECK_TYPE: '',
            INSULATION_TYPE: '',
            ATTACHMENT_METHOD: '',
            INSULATION_THICKNESS: '',
            ROOF_SYSTEM: '',
            ROOF_SYSTEM_THICKNESS: '',
            SURFACING_TYPE: '',
        });
    }, [roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        roofLayer = { ...roofLayer, name: event.target.value };
        updateSelectedLayer(roofLayer);
    }, [roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onNameBlur = useCallback(() => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);

        if (!roofLayer) {
            return;
        }

        if (roofLayer.name === '') {
            setErrorText({
                ...errorText,
                NAME: 'Name is required',
            });
        } else {
            setErrorText({
                ...errorText,
                NAME: '',
            });
        }
    }, [errorText, roofLayers, selectedIndex, selectedSubIndex]);

    const onDeckTypeChange = useCallback((_e: any, selectedType: DeckTypeDto | null) => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        roofLayer = {
            ...roofLayer,
            deckTypeId: selectedType?.id ?? "",
            deckType: selectedType ?? undefined,
        };
        updateSelectedLayer(roofLayer);
        setErrorText({
            ...errorText,
            DECK_TYPE: '',
        });
    }, [errorText, roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onInsulationTypeChange = useCallback((_e: any, selectedType: InsulationDto | null) => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        } roofLayer = {
            ...roofLayer,
            insulationId: selectedType?.id ?? "",
            insulation: selectedType ?? undefined,
        };
        updateSelectedLayer(roofLayer);
        setErrorText({
            ...errorText,
            INSULATION_TYPE: '',
        });
    }, [errorText, roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onAttachmentMethodChange = useCallback((layerTypeId: string) => (_e: any, selected: AttachmentMethodDto | null) => {
        let selectedMethod;
        if (layerTypeId === CoreLayerTypeIds.INSULATION) {
            selectedMethod = insulationAttachmentMethods?.find((layer) => layer.id === selected?.id);
        } else if (layerTypeId === CoreLayerTypeIds.ROOF_SYSTEM) {
            selectedMethod = roofSystemAttachmentMethods?.find((layer) => layer.id === selected?.id);
        }
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        roofLayer = {
            ...roofLayer,
            attachmentMethodId: selectedMethod?.id ?? "",
            attachmentMethod: selectedMethod,
        };
        updateSelectedLayer(roofLayer);
        setErrorText({
            ...errorText,
            ATTACHMENT_METHOD: '',
        });
    }, [errorText, insulationAttachmentMethods, roofLayers, roofSystemAttachmentMethods, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onInsulationThicknessChange = useCallback((_e: any, selectedType: InsulationThicknessDto | null) => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        roofLayer = {
            ...roofLayer,
            insulationThicknessId: selectedType?.id ?? "",
            insulationThickness: selectedType ?? undefined,
        };
        updateSelectedLayer(roofLayer);
        setErrorText({
            ...errorText,
            INSULATION_THICKNESS: '',
        });
    }, [errorText, roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onRoofSystemChange = useCallback((_e: any, selectedType: RoofSystemDto | null) => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        roofLayer = {
            ...roofLayer,
            roofSystemId: selectedType?.id ?? "",
            roofSystem: selectedType ?? undefined,
        };
        updateSelectedLayer(roofLayer);
        setErrorText({
            ...errorText,
            ROOF_SYSTEM: '',
        });
    }, [errorText, roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onRoofThicknessChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
            if (!roofLayer) {
                return;
            }
            roofLayer = {
                ...roofLayer,
                roofSystemThickness: event.target.value,
            };
            updateSelectedLayer(roofLayer);
        }
    }, [roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onRoofThicknessBlur = useCallback(() => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        if (!roofLayer.roofSystemThickness) {
            setErrorText({
                ...errorText,
                ROOF_SYSTEM_THICKNESS: 'Thickness is required',
            });
        } else if (!patterns.Inches.validate.test(roofLayer.roofSystemThickness!)) {
            setErrorText({
                ...errorText,
                ROOF_SYSTEM_THICKNESS: 'Thickness must be in increments of 0.25',
            });
        } else {
            setErrorText({
                ...errorText,
                ROOF_SYSTEM_THICKNESS: '',
            });
        }
    }, [errorText, roofLayers, selectedIndex, selectedSubIndex]);

    const onIsTaperedChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        roofLayer = {
            ...roofLayer,
            isTapered: checked,
        };
        updateSelectedLayer(roofLayer);
        setIsTapered(checked);
    }, [roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const onSurfacingTypeChange = useCallback((_e: any, selectedType: SurfacingDto | null) => {
        let roofLayer = roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
        if (!roofLayer) {
            return;
        }
        roofLayer = {
            ...roofLayer,
            surfacingTypeId: selectedType?.id ?? "",
            surfacingType: selectedType ?? undefined,
        };
        updateSelectedLayer(roofLayer);
        setErrorText({
            ...errorText,
            SURFACING_TYPE: '',
        });
    }, [errorText, roofLayers, selectedIndex, selectedSubIndex, updateSelectedLayer]);

    const maxIndex = useMemo(() => {
        if (roofLayers.length > 0) {
            return Math.max(...roofLayers.map(x => x.order));
        } else {
            return 0;
        }
    }, [roofLayers]);

    const getRoofLayerRows = useCallback(() => {
        if (!roofLayers) {
            return <></>;
        }
        const layerGroups = _.groupBy(roofLayers, x => x.order);
        const roofLayerList = [];
        for (const key in layerGroups) {
            roofLayerList.push([...layerGroups[key]]);
        }

        return (<>{roofLayerList.map((layer, index) => (
            <Grid key={index} item container direction='row' wrap='nowrap'>
                {layer.sort((a, b) => a.subLayerOrder - b.subLayerOrder).map((subLayer, subIndex) => (
                    <Grid key={subIndex} item container direction='row' xs={12}>
                        <Grid
                            item
                            container
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                                border: index === selectedIndex && subIndex === selectedSubIndex ? '2px solid #F7941D' : '1px solid white',
                                padding: index === selectedIndex && subIndex === selectedSubIndex ? '0' : '5px 0',
                                cursor: viewOnly ? '' : 'pointer',
                                backgroundColor: subLayer.coreLayerType ? subLayer.coreLayerType.colorCode : '#ffffff',
                                width: '100%',
                            }}
                            onClick={() => {
                                if (!viewOnly) {
                                    selectLayer(index, subIndex);
                                }
                            }}
                        >
                            {index === selectedIndex && subIndex === selectedSubIndex && !viewOnly && (
                                <Grid item xs={2}>
                                    <IconButton
                                        size='small'
                                        onClick={deleteSelectedLayer}
                                        onMouseEnter={() => setHoverButton(true)}
                                        onMouseLeave={() => setHoverButton(false)}
                                        sx={{ margin: 0 }}
                                    >
                                        <Delete sx={{ color: subLayer.coreLayerType ? '#ffffff' : '#333333' }} />
                                    </IconButton>
                                </Grid>
                            )}
                            <Grid item container direction='column' alignItems='center' xs={6}>
                                <Grid item>
                                    <Typography sx={{ color: subLayer.coreLayerType ? '#ffffff' : '#333333' }}>
                                        {subLayer.name !== '' ? subLayer.name : 'Untitled Layer'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ color: subLayer.coreLayerType ? '#ffffff' : '#333333', fontSize: '12px' }}>
                                        {subLayer.coreLayerTypeId === CoreLayerTypeIds.DECK && <>{subLayer.deckType?.name}</>}
                                        {subLayer.coreLayerTypeId === CoreLayerTypeIds.INSULATION && (
                                            <>
                                                {subLayer.insulation?.name}, {subLayer.attachmentMethod?.name}, {`${subLayer.roofSystemThickness ?? ''}"`}
                                                {subLayer.isTapered ? ', Tapered' : ''}
                                            </>
                                        )}
                                        {subLayer.coreLayerTypeId === CoreLayerTypeIds.ROOF_SYSTEM && (
                                            <>
                                                {[subLayer.roofSystem?.name, subLayer.attachmentMethod?.name, subLayer.insulationThickness?.name]
                                                    .filter((x) => x)
                                                    .join(', ')}
                                            </>
                                        )}
                                        {subLayer.coreLayerTypeId === CoreLayerTypeIds.SURFACING && <>{subLayer.surfacingType?.name}</>}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {index === selectedIndex && subIndex === selectedSubIndex && !viewOnly && (
                                <Grid item container direction='row' justifyContent='flex-end' xs={2} spacing={1}>
                                    <Grid item xs={6}>
                                        {index > 0 && (
                                            <IconButton
                                                size='small'
                                                onClick={() => moveSelectedLayer(-1)}
                                                onMouseEnter={() => setHoverButton(true)}
                                                onMouseLeave={() => setHoverButton(false)}
                                                sx={{ margin: 0 }}
                                            >
                                                <ArrowDropUp sx={{ color: subLayer.coreLayerType ? '#ffffff' : '#333333' }} />
                                            </IconButton>
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {index < maxIndex && (
                                            <IconButton
                                                size='small'
                                                onClick={() => moveSelectedLayer(1)}
                                                onMouseEnter={() => setHoverButton(true)}
                                                onMouseLeave={() => setHoverButton(false)}
                                                sx={{ margin: 0 }}
                                            >
                                                <ArrowDropDown sx={{ color: subLayer.coreLayerType ? '#ffffff' : '#333333' }} />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                ))}
                {!viewOnly && layer.length < 5 && (
                    <Grid
                        item
                        alignSelf='center'
                        sx={{ padding: '12px 0' }}
                        onClick={() => addNewSubLayer(index)}
                        xs={3}>
                        <Tooltip title='+ New Sub Layer'>

                            <IconButton
                                size='medium'
                                color='success'
                                onClick={() => addNewSubLayer(index)}
                                sx={{ margin: 0 }}
                            >
                                <AddCircle />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
        ))}</>);

    }, [addNewSubLayer, deleteSelectedLayer, maxIndex, moveSelectedLayer, roofLayers, selectLayer, selectedIndex, selectedSubIndex, viewOnly]);

    const selectedRoofLayer = useMemo(() => {
        return roofLayers.find(x => x.order === selectedIndex && x.subLayerOrder === selectedSubIndex);
    }, [roofLayers, selectedIndex, selectedSubIndex]);

    if (
        coreLayerTypesLoading ||
        insulationTypesLoading ||
        deckTypesLoading ||
        insulationAttachmentMethodsLoading ||
        roofSystemAttachmentMethodsLoading ||
        thicknessesLoading ||
        roofSystemsLoading ||
        surfacingTypesLoading
    ) {
        return <LoadingIndicator />;
    }

    if (
        coreLayerTypesError ||
        insulationTypesError ||
        deckTypesError ||
        insulationAttachmentMethodsError ||
        roofSystemAttachmentMethodsError ||
        thicknessesError ||
        roofSystemsError ||
        surfacingTypesError
    ) {
        return <ApiError onReloadClick={refetch} />;
    }



    return (
        <Grid container direction='row' spacing={2} sx={{ minHeight: viewOnly ? '0px' : '600px' }}>
            <Grid item container direction='column' gap={1} xs={viewOnly ? 12 : 8}>
                {!viewOnly && (
                    <Grid
                        item
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        sx={{ border: '1px solid #000000', padding: '12px 0', cursor: 'pointer' }}
                        onClick={addNewLayer}>
                        <Typography>+ New Layer</Typography>
                    </Grid>
                )}
                {getRoofLayerRows()}
            </Grid>
            {selectedIndex !== undefined && selectedSubIndex !== undefined && selectedRoofLayer && !viewOnly && (
                <Grid item container direction='column' spacing={2} xs={4}>
                    <Grid item>
                        <FormControl error={errorText.CORE_LAYER_TYPE !== ''} fullWidth required>
                            <FormLabel>Type</FormLabel>
                            <IEntityAutocomplete
                                options={coreLayerTypes}
                                onChange={onCoreLayerTypeChange}
                                value={selectedRoofLayer?.coreLayerType}
                                getOptionLabel={(option: CoreLayerTypeDto) => option.name}
                                isLoading={coreLayerTypesLoading}
                                error={errorText.CORE_LAYER_TYPE !== ''}
                            />
                            <FormHelperText>{errorText.CORE_LAYER_TYPE}</FormHelperText>
                        </FormControl>
                    </Grid>
                    {selectedRoofLayer?.coreLayerTypeId && (
                        <Grid item>
                            <FormInput
                                label='Name'
                                onBlur={onNameBlur}
                                value={selectedRoofLayer.name}
                                fullWidth
                                required
                                error={errorText.NAME !== ''}
                                errorText={errorText.NAME}
                                onChange={onNameChange}
                            />
                        </Grid>
                    )}
                    {selectedRoofLayer?.coreLayerTypeId === CoreLayerTypeIds.DECK && (
                        <Grid item>
                            <FormControl error={errorText.DECK_TYPE !== ''} fullWidth required>
                                <FormLabel>Deck Type</FormLabel>
                                <IEntityAutocomplete
                                    options={deckTypes}
                                    onChange={onDeckTypeChange}
                                    value={selectedRoofLayer?.deckType}
                                    getOptionLabel={(option: DeckTypeDto) => option.name}
                                    isLoading={deckTypesLoading}
                                    error={errorText.DECK_TYPE !== ''}
                                    quickAddProps={{
                                        requiredPermissions: ['create:deckTypes'],
                                        objectName: 'Deck Type',
                                        handleSave: saveDeckType
                                    }}
                                />
                                <FormHelperText>{errorText.DECK_TYPE}</FormHelperText>
                            </FormControl>
                        </Grid>
                    )}
                    {selectedRoofLayer?.coreLayerTypeId === CoreLayerTypeIds.INSULATION && (
                        <>
                            <Grid item>
                                <FormControl error={errorText.INSULATION_TYPE !== ''} fullWidth required>
                                    <FormLabel>Insulation Type</FormLabel>
                                    <IEntityAutocomplete
                                        options={insulationTypes}
                                        onChange={onInsulationTypeChange}
                                        value={selectedRoofLayer?.insulation}
                                        getOptionLabel={(option: InsulationDto) => option.name}
                                        isLoading={insulationTypesLoading}
                                        error={errorText.INSULATION_TYPE !== ''}
                                        quickAddProps={{
                                            requiredPermissions: ['create:insulationType'],
                                            objectName: 'Insulation Type',
                                            handleSave: saveInsulationType
                                        }}
                                    />
                                    <FormHelperText>{errorText.INSULATION_TYPE}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl error={errorText.ATTACHMENT_METHOD !== ''} fullWidth required>
                                    <FormLabel>Attachment Method</FormLabel>
                                    <IEntityAutocomplete
                                        options={insulationAttachmentMethods}
                                        onChange={onAttachmentMethodChange(CoreLayerTypeIds.INSULATION)}
                                        value={selectedRoofLayer?.attachmentMethod}
                                        getOptionLabel={(option: AttachmentMethodDto) => option.name}
                                        isLoading={insulationAttachmentMethodsLoading}
                                        error={errorText.ATTACHMENT_METHOD !== ''}
                                    />
                                    <FormHelperText>{errorText.ATTACHMENT_METHOD}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item container direction='row' alignItems='center'>
                                <Grid item container direction='row' alignItems='center' spacing={1} xs={6}>
                                    <Grid item xs={5}>
                                        <FormNumberInput
                                            label='Thickness'
                                            name='thickness'
                                            onBlur={onRoofThicknessBlur}
                                            value={selectedRoofLayer.roofSystemThickness}
                                            fullWidth
                                            required
                                            error={errorText.ROOF_SYSTEM_THICKNESS !== ''}
                                            errorText={errorText.ROOF_SYSTEM_THICKNESS}
                                            onChange={onRoofThicknessChange}
                                            inputProps={{ inputMode: 'numeric', pattern: patterns.Inches.input.source }}

                                        />
                                    </Grid>
                                    <Grid item xs='auto'>
                                        <Typography sx={{ marginTop: '16px' }}>inches</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ marginTop: '16px' }}>
                                    <FormControlLabel control={<Checkbox checked={isTapered} onChange={onIsTaperedChange} />} label='Tapered' />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {selectedRoofLayer?.coreLayerTypeId === CoreLayerTypeIds.ROOF_SYSTEM && (
                        <>
                            <Grid item>
                                <FormControl error={errorText.ROOF_SYSTEM !== ''} fullWidth required>
                                    <FormLabel>Roof Type</FormLabel>
                                    <IEntityAutocomplete
                                        options={roofSystems}
                                        onChange={onRoofSystemChange}
                                        value={selectedRoofLayer?.roofSystem}
                                        getOptionLabel={(option: RoofSystemDto) => option.name}
                                        isLoading={roofSystemsLoading}
                                        error={errorText.ROOF_SYSTEM !== ''}
                                        quickAddProps={{
                                            requiredPermissions: ['create:roofSystems'],
                                            objectName: 'Roof Type',
                                            handleSave: saveRoofSystem
                                        }}
                                    />
                                    <FormHelperText>{errorText.ROOF_SYSTEM}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl error={errorText.ATTACHMENT_METHOD !== ''} fullWidth>
                                    <FormLabel>Attachment Method</FormLabel>
                                    <IEntityAutocomplete
                                        options={roofSystemAttachmentMethods}
                                        onChange={onAttachmentMethodChange(CoreLayerTypeIds.ROOF_SYSTEM)}
                                        value={selectedRoofLayer?.attachmentMethod}
                                        getOptionLabel={(option: AttachmentMethodDto) => option.name}
                                        isLoading={roofSystemAttachmentMethodsLoading}
                                        error={errorText.ATTACHMENT_METHOD !== ''}
                                    />
                                    <FormHelperText>{errorText.ATTACHMENT_METHOD}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl error={errorText.INSULATION_THICKNESS !== ''} fullWidth>
                                    <FormLabel>Thickness</FormLabel>
                                    <IEntityAutocomplete
                                        options={thicknesses}
                                        onChange={onInsulationThicknessChange}
                                        value={selectedRoofLayer?.insulationThickness}
                                        getOptionLabel={(option: InsulationThicknessDto) => option.name}
                                        isLoading={thicknessesLoading}
                                        error={errorText.INSULATION_THICKNESS !== ''}
                                    />
                                    <FormHelperText>{errorText.INSULATION_THICKNESS}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    {selectedRoofLayer?.coreLayerTypeId === CoreLayerTypeIds.SURFACING && (
                        <Grid item>
                            <FormControl error={errorText.SURFACING_TYPE !== ''} fullWidth required>
                                <FormLabel>Surfacing Type</FormLabel>
                                <IEntityAutocomplete
                                    options={surfacingTypes}
                                    onChange={onSurfacingTypeChange}
                                    value={selectedRoofLayer?.surfacingType}
                                    getOptionLabel={(option: SurfacingDto) => option.name}
                                    isLoading={surfacingTypesLoading}
                                    error={errorText.SURFACING_TYPE !== ''}
                                    quickAddProps={{
                                        requiredPermissions: ['create:surfacingType'],
                                        objectName: 'Surfacing Type',
                                        handleSave: saveSurfacingType
                                    }}
                                />
                                <FormHelperText>{errorText.SURFACING_TYPE}</FormHelperText>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
});
