import { Send } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { WorkOrderDto } from '../../../../models';
import { DispatchListViewDto } from '../../../../models/DispatchListViewDto';
import { DispatchRequestDto } from '../../../../models/DispatchRequestDto';
import { StandardDialogHeader } from '../../StandardDialogHeader';
import { RecallDispatchDialog } from './RecallDispatchDialog';
import { SendDispatchDialog } from './SendDispatchDialog';

export interface IAddDispatchDialogProps {
    open: boolean;
    onClose: (actionName: string) => void;
    workOrder?: WorkOrderDto;
    dispatch?: DispatchListViewDto;
    unlockDispatch: (id: string) => void;
    recallDispatch: (id: string) => void;
    createDispatch: (dispatchRequest: DispatchRequestDto) => void;
    reportDetails?: {
        id: string;
        type: number;
        lockDate?: Date;
        onBackClicked?: () => void;
    };
}

export const DispatchDialogView: FC<IAddDispatchDialogProps> = React.memo(
    ({ open, onClose, workOrder, dispatch, unlockDispatch, recallDispatch, createDispatch, reportDetails }) => {
        const modalTitle = useMemo(() => {
            let titleSuffix = '';
            if (reportDetails) {
                titleSuffix = reportDetails.type === 0 ? 'RoofSMART' : 'Moisture Guard';
            } else {
                titleSuffix = 'Close Out';
            }

            return `Dispatch Work Order - ${titleSuffix}`;
        }, [reportDetails]);

        return (
            <Dialog onClose={() => onClose('')} open={open} maxWidth='lg' fullWidth>
                <StandardDialogHeader icon={<Send sx={{ color: 'white' }} />} title={modalTitle} />
                {dispatch ? (
                    <RecallDispatchDialog unlockDispatch={unlockDispatch} recallDispatch={recallDispatch} dispatchId={dispatch.id} onClose={onClose} />
                ) : (
                    <SendDispatchDialog
                        workOrder={workOrder}
                        onClose={onClose}
                        createDispatch={createDispatch}
                        reportDetails={reportDetails ? {
                            id: reportDetails.id,
                            lockDate: reportDetails.lockDate,
                        } : undefined}
                        onBackClicked={reportDetails?.onBackClicked}
                    />
                )}
            </Dialog>
        );
    }
);
