import { serverApi } from '..';
import { DivisionDto, QueryResultsDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';
import { SearchQueryParams } from '../types';

const divisionApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDivisions: builder.query<QueryResultsDto<DivisionDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('division', params),
            providesTags: [{ type: 'Division' }]
        })
    })
});

export const {
    useGetDivisionsQuery,
} = divisionApi;