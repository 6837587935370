import { AddCircle, ArrowRight, Delete, FilterList, InsertChart, Lock, MoreVert, Search } from '@mui/icons-material';
import { Chip, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../auth';
import { ApiError } from '../Components/core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../Components/core/DataTable';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import { NavBreadcrumbs } from '../Components/core/NavBreadcrumbs';
import { ReportType } from '../Components/Forms';
import { FilterReportsDialog } from '../Components/Forms/ClientReportForm/FilterReportsDialog';
import { ClientDto, FacilityDto, ReportVisibilityChangeDto, VisibilityGroup, WorkOrderDto } from '../models';
import { ReportListItemDto } from '../models/ReportListItemDto';
import { ReportPdfStatus } from '../models/ReportPdfDto';
import {
    useArchiveBPReportMutation,
    useLazyGetBPReportPdfQuery,
    useUnarchiveBPReportMutation,
    useUnlockBPReportMutation,
} from '../store/apis/binder-portfolio-report-api';
import {
    useArchiveCloseOutReportMutation,
    useLazyGetCloseOutReportPdfQuery,
    useUnarchiveCloseOutReportMutation,
    useUnlockCloseOutReportMutation,
} from '../store/apis/close-out-report-api';
import {
    useArchiveJsiReportMutation,
    useLazyGetJsiReportPdfQuery,
    useUnarchiveJsiReportMutation,
    useUnlockJsiReportMutation,
} from '../store/apis/jsi-report-api';
import { useArchiveReportMutation, useLazyGetReportPdfQuery, useUnarchiveReportMutation, useUnlockReportMutation } from '../store/apis/report-api';
import { useGetReportListItemsQuery, useSetReportsPrivateMutation, useSetReportsPublicMutation } from '../store/apis/report-list-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../util/customHooks';

export interface IClientReportsListViewProps {
    clientId?: string;
    facility?: FacilityDto;
    workOrder?: WorkOrderDto;
    isCapitalProjectView?: boolean;
    isClientDashboardView?: boolean;
}

export const ClientReportsListView: FC<IClientReportsListViewProps> = ({ clientId, facility, workOrder, isCapitalProjectView, isClientDashboardView }) => {
    const navigate = useNavigate();
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [reportMenuAnchorEl, setReportMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const menuOpen = Boolean(menuAnchorEl);
    const reportMenuOpen = Boolean(reportMenuAnchorEl);
    const subMenuOpen = Boolean(subMenuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [fromDateFilter, setFromDateFilter] = useState<Date | null | undefined>();
    const [toDateFilter, setToDateFilter] = useState<Date | null | undefined>();
    const [clientFilter, setClientFilter] = useState<ClientDto | null>();
    const [facilityFilter, setFacilityFilter] = useState<FacilityDto | null>();
    const [typeFilter, setTypeFilter] = useState<number | undefined>();
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

    const { data, isLoading, error, refetch } = useGetReportListItemsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        facilityId: facility?.id ?? facilityFilter?.id,
        clientId: clientId ?? clientFilter?.id,
        woNumber: workOrder ? workOrder.woNumber : undefined,
        reportType: typeFilter || typeFilter === 0 ? typeFilter : null,
        fromReportDate: fromDateFilter ? fromDateFilter.toISOString() : '',
        toReportDate: toDateFilter ? toDateFilter.toISOString() : '',
        isClientDashboardView,
    });

    const [unlockBinderPortfolioReport] = useUnlockBPReportMutation();
    const [unlockCloseOutReport] = useUnlockCloseOutReportMutation();
    const [unlockJsiReport] = useUnlockJsiReportMutation();
    const [unlockReport] = useUnlockReportMutation();

    const [getBinderPortfolioReportPdf] = useLazyGetBPReportPdfQuery();
    const [getCloseOutReportPdf] = useLazyGetCloseOutReportPdfQuery();
    const [getJsiReportPdf] = useLazyGetJsiReportPdfQuery();
    const [getReportPdf] = useLazyGetReportPdfQuery();

    const [
        archiveBinderPortfolioReport,
        { isError: isArchiveBinderPortfolioError, isSuccess: isArchiveBinderPortfolioSuccess, reset: resetBinderPortfolioArchive },
    ] = useArchiveBPReportMutation();
    const [archiveCloseOutReport, { isError: isArchiveCloseOutError, isSuccess: isArchiveCloseOutSuccess, reset: resetCloseOutArchive }] =
        useArchiveCloseOutReportMutation();
    const [archiveJsiReport, { isError: isArchiveJsiError, isSuccess: isArchiveJsiSuccess, reset: resetJsiArchive }] = useArchiveJsiReportMutation();
    const [archiveReport, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveReportMutation();

    const [
        unarchiveBinderPortfolioReport,
        { isError: isUnarchiveBinderPortfolioError, isSuccess: isUnarchiveBinderPortfolioSuccess, reset: resetBinderPortfolioUnarchive },
    ] = useUnarchiveBPReportMutation();
    const [unarchiveCloseOutReport, { isError: isUnarchiveCloseOutError, isSuccess: isUnarchiveCloseOutSuccess, reset: resetCloseOutUnarchive }] =
        useUnarchiveCloseOutReportMutation();
    const [unarchiveJsiReport, { isError: isUnarchiveJsiError, isSuccess: isUnarchiveJsiSuccess, reset: resetJsiUnarchive }] = useUnarchiveJsiReportMutation();
    const [unarchiveReport, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveReportMutation();

    const [setReportsPrivateClient, { isError: isSetPrivateClientError, isSuccess: isSetPrivateClientSuccess, reset: resetSetPrivateClient }] = useSetReportsPrivateMutation();
    const [setReportsPublicClient, { isError: isSetPublicClientError, isSuccess: isSetPublicClientSuccess, reset: resetSetPublicClient }] = useSetReportsPublicMutation();
    useSuccessfulActionSnackbar('hidden from Clients', 'Reports', isSetPrivateClientSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetSetPrivateClient);
    });
    useSuccessfulActionSnackbar('made visible to Clients', 'Reports', isSetPublicClientSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetSetPublicClient);
    });
    useFailedActionSnackbar('make hidden to Clients', 'Reports', isSetPrivateClientError, () => {
        handleActionRequestCompletion(resetSetPrivateClient);
    });
    useFailedActionSnackbar('make visible to Clients', 'Reports', isSetPublicClientError, () => {
        handleActionRequestCompletion(resetSetPublicClient);
    });
    const [setReportsPrivateEmployee, { isError: isSetPrivateEmployeeError, isSuccess: isSetPrivateEmployeeSuccess, reset: resetSetPrivateEmployee }] = useSetReportsPrivateMutation();
    const [setReportsPublicEmployee, { isError: isSetPublicEmployeeError, isSuccess: isSetPublicEmployeeSuccess, reset: resetSetPublicEmployee }] = useSetReportsPublicMutation();
    useSuccessfulActionSnackbar('hidden from Employees', 'Reports', isSetPrivateEmployeeSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetSetPrivateEmployee);
    });
    useSuccessfulActionSnackbar('made visible to Employees', 'Reports', isSetPublicEmployeeSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetSetPublicEmployee);
    });
    useFailedActionSnackbar('make hidden to Employees', 'Reports', isSetPrivateEmployeeError, () => {
        handleActionRequestCompletion(resetSetPrivateEmployee);
    });
    useFailedActionSnackbar('make visible to Employees', 'Reports', isSetPublicEmployeeError, () => {
        handleActionRequestCompletion(resetSetPublicEmployee);
    });

    const isLocked = (date?: Date | undefined) => {
        return date && new Date() >= new Date(date);
    };

    const tableColumns: DataTableColumn<ReportListItemDto>[] = [
        {
            key: 'reportDate',
            label: 'Report Date',
            sortKey: 'REPORT_DATE',
            fieldMapper: (row) => (row.reportDate ? format(new Date(row.reportDate.toString()), 'M/d/yyyy') : ''),
        },
        { key: 'client', label: 'Owner', sortKey: 'CLIENT', fieldMapper: (row) => row.client, width: '150px' },
        { key: 'facility', label: 'Facility', sortKey: 'FACILITY', fieldMapper: (row) => row.facility },
        { key: 'workOrder', label: 'WO / Cap Proj #', sortKey: 'WO_NUMBER', fieldMapper: (row) => row.woNumber },
        {
            key: 'lockDate',
            label: 'Locked',
            sortKey: 'LOCK_DATE',
            fieldMapper: (row) => (isLocked(row.lockDate) ? <Lock /> : <></>),
        },
        {
            key: 'reportName',
            label: 'Report Name',
            sortKey: 'REPORT_NAME',
            fieldMapper: (row) =>
                row.pdfStatus === ReportPdfStatus.Created ? (
                    <Link sx={{ cursor: 'pointer' }} onClick={() => handleDownloadPdf(row.id, row.reportType)}>
                        {row.reportName}
                    </Link>
                ) : (
                    <>{row.reportName}</>
                ),
            width: '175px'
        },
        { key: 'reportType', label: 'Report Type', sortKey: 'REPORT_TYPE', fieldMapper: (row) => ReportType[row.reportType ?? 0] },
        { key: 'visibility', label: 'Visibility', sortKey: 'VISIBILITY' },
    ];

    const getEntityName = () => {
        return selected.length === 1 ? 'Report' : 'Reports';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
        refetch();
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => {
        handleActionRequestCompletion(resetUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetUnarchive);
    });

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveBinderPortfolioError, () => {
        handleActionRequestCompletion(resetBinderPortfolioArchive);
    });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveBinderPortfolioError, () => {
        handleActionRequestCompletion(resetBinderPortfolioUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveBinderPortfolioSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetBinderPortfolioArchive);
    });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveBinderPortfolioSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetBinderPortfolioUnarchive);
    });

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveCloseOutError, () => {
        handleActionRequestCompletion(resetCloseOutArchive);
    });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveCloseOutError, () => {
        handleActionRequestCompletion(resetCloseOutUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveCloseOutSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetCloseOutArchive);
    });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveCloseOutSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetCloseOutUnarchive);
    });

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveJsiError, () => {
        handleActionRequestCompletion(resetJsiArchive);
    });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveJsiError, () => {
        handleActionRequestCompletion(resetJsiUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveJsiSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetJsiArchive);
    });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveJsiSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetJsiUnarchive);
    });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleReportMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setReportMenuAnchorEl(event.currentTarget);
    };

    const handleReportMenuClose = (afterClose?: () => void) => () => {
        setReportMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSubMenuAnchorEl(event.currentTarget);
    };

    const handleSubMenuClose = (afterClose?: () => void) => () => {
        setSubMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const handleCreateNewClientReport = () => {
        if (workOrder) {
            navigate(`/clientreports/create/${workOrder.id}`);
        } else {
            navigate('/clientreports/create');
        }
    };

    const handleCreateNewJsiReport = () => {
        if (workOrder) {
            navigate(`/jsireports/create/${workOrder.id}`);
        } else {
            navigate('/jsireports/create');
        }
    };

    const handleCreateNewBinderPortfolioReport = () => {
        if (workOrder) {
            navigate(`/binderPortfolioReports/create/client/${workOrder.facility?.clientId}`);
        } else {
            navigate('/binderPortfolioReports/create');
        }
    };

    const handleCreateNewCloseOutReport = () => {
        if (workOrder) {
            navigate(`/closeOutReports/create/${workOrder.id}`);
        } else {
            navigate('/closeOutReports/create');
        }
    };

    const routeToEditBasedOnReportType = (selectedReportId: string, selectedReportType: number) => {
        switch (selectedReportType) {
            case ReportType['RoofSMART Report']:
            case ReportType['Moisture Guard']:
                if (workOrder) {
                    navigate(`/clientreports/${workOrder.id}/edit/${selectedReportId}`);
                } else {
                    navigate(`/clientreports/edit/${selectedReportId}`);
                }
                break;
            case ReportType['JSI Report']:
                if (workOrder) {
                    navigate(`/jsireports/${workOrder.id}/edit/${selectedReportId}`);
                } else {
                    navigate(`/jsireports/edit/${selectedReportId}`);
                }
                break;
            case ReportType['Binder Portfolio']:
                if (workOrder) {
                    navigate(`/binderPortfolioReports/edit/${selectedReportId}/client/${workOrder.facility?.clientId}`);
                } else {
                    navigate(`/binderPortfolioReports/edit/${selectedReportId}`);
                }
                break;
            case ReportType['Close Out Report']:
                navigate(`/closeOutReports/edit/${selectedReportId}`);
                break;
        }
    };

    const handleMenuEdit = () => {
        const selectedReportId = data?.pageResults[selected[0]].id;
        const selectedReportType = data?.pageResults[selected[0]].reportType;

        if (selectedReportId && selectedReportType !== undefined) {
            routeToEditBasedOnReportType(selectedReportId, selectedReportType);
        }
    };

    const handleTableEdit = (id: string) => {
        var row = data?.pageResults.find((x) => x.id === id);
        if (row?.reportType !== undefined) {
            routeToEditBasedOnReportType(row.id, row.reportType);
        }
    };

    const handleSetActive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            const reportType = data?.pageResults[index].reportType;
            const id = data?.pageResults[index].id!;
            if (reportType === 2) {
                unarchiveJsiReport(id);
            } else if (reportType === 3) {
                unarchiveBinderPortfolioReport(id);
            } else if (reportType === 4) {
                unarchiveCloseOutReport(id);
            } else {
                unarchiveReport(id);
            }
        });
        setIsUpdatingStatus(false);
    };

    const handleSetInactive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            const reportType = data?.pageResults[index].reportType;
            const id = data?.pageResults[index].id!;
            if (reportType === 2) {
                archiveJsiReport(id);
            } else if (reportType === 3) {
                archiveBinderPortfolioReport(id);
            } else if (reportType === 4) {
                archiveCloseOutReport(id);
            } else {
                archiveReport(id);
            }
        });
        setIsUpdatingStatus(false);
    };

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index].isActive);
    };

    const buildVisibilityChangeRequestBody = useCallback(
        (visibilityGroup: VisibilityGroup) => {
            return selected
                .map((tblIdx) => data?.pageResults[tblIdx]!)
                .map<ReportVisibilityChangeDto>((rep) => ({
                    reportId: rep.id,
                    reportTypeName: ReportType[rep.reportType ?? 0],
                    visibilityGroup: visibilityGroup,
                }));
        },
        [data, selected]
    );

    const sendMakePublicRequest = useCallback(
        (visibilityGroup: VisibilityGroup) => {
            setIsUpdatingStatus(true);
            switch (visibilityGroup) {
                case VisibilityGroup.Client:
                    setReportsPublicClient(buildVisibilityChangeRequestBody(visibilityGroup));
                    break;
                case VisibilityGroup.Employee:
                    setReportsPublicEmployee(buildVisibilityChangeRequestBody(visibilityGroup));
                    break;
            }
            setIsUpdatingStatus(false);
        },
        [buildVisibilityChangeRequestBody, setReportsPublicClient, setReportsPublicEmployee]
    );

    const sendMakePrivateRequest = useCallback(
        (visibilityGroup: VisibilityGroup) => {
            setIsUpdatingStatus(true);
            switch (visibilityGroup) {
                case VisibilityGroup.Client:
                    setReportsPrivateClient(buildVisibilityChangeRequestBody(visibilityGroup));
                    break;
                case VisibilityGroup.Employee:
                    setReportsPrivateEmployee(buildVisibilityChangeRequestBody(visibilityGroup));
                    break;
            }
            setIsUpdatingStatus(false);
        },
        [buildVisibilityChangeRequestBody, setReportsPrivateClient, setReportsPrivateEmployee]
    );

    const handleShowEmployees = useCallback(() => sendMakePublicRequest(VisibilityGroup.Employee), [sendMakePublicRequest]);
    const handleHideEmployees = useCallback(() => sendMakePrivateRequest(VisibilityGroup.Employee), [sendMakePrivateRequest]);
    const handleShowClients = useCallback(() => sendMakePublicRequest(VisibilityGroup.Client), [sendMakePublicRequest]);
    const handleHideClients = useCallback(() => sendMakePrivateRequest(VisibilityGroup.Client), [sendMakePrivateRequest]);

    const selectedRecords = useMemo(() => (data ? selected.map((tblIdx) => data.pageResults[tblIdx]) : []), [selected, data]);
    const canShowEmployee = useCallback(() => selectedRecords.some((rec) => !rec.isVisibleToEmployees), [selectedRecords]);
    const canHideEmployee = useCallback(() => selectedRecords.some((rec) => rec.isVisibleToEmployees), [selectedRecords]);
    const canShowClient = useCallback(() => selectedRecords.some((rec) => !rec.isVisibleToClients), [selectedRecords]);
    const canHideClient = useCallback(() => selectedRecords.some((rec) => rec.isVisibleToClients), [selectedRecords]);

    const handleFilter = () => {
        setFilterOpen(true);
    };

    const handleUnlock = () => {
        const reportType = data?.pageResults[selected[0]].reportType;
        const id = data?.pageResults[selected[0]].id!;
        const lockDate = data?.pageResults[selected[0]].lockDate;
        if (id && isLocked(lockDate)) {
            if (reportType === 2) {
                unlockJsiReport(id).then(() => {
                    refetch();
                });
            } else if (reportType === 3) {
                unlockBinderPortfolioReport(id).then(() => {
                    refetch();
                });
            } else if (reportType === 4) {
                unlockCloseOutReport(id).then(() => {
                    refetch();
                });
            } else {
                unlockReport(id).then(() => {
                    refetch();
                });
            }
        }
    };

    const handleDownloadPdf = (id: string, reportType?: number) => {
        if (reportType === 2) {
            getJsiReportPdf(id).then((response) => {
                if (!response.isError && response.data) {
                    window.open(response.data.downloadUrl);
                }
            });
        } else if (reportType === 3) {
            getBinderPortfolioReportPdf(id).then((response) => {
                if (!response.isError && response.data) {
                    window.open(response.data.downloadUrl);
                }
            });
        } else if (reportType === 4) {
            getCloseOutReportPdf(id).then((response) => {
                if (!response.isError && response.data) {
                    window.open(response.data.downloadUrl);
                }
            });
        } else {
            getReportPdf(id).then((response) => {
                if (!response.isError && response.data) {
                    window.open(response.data.downloadUrl);
                }
            });
        }
    };

    const handleReportDateFilterDelete = () => {
        setFromDateFilter(undefined);
        setToDateFilter(undefined);
    };

    const handleClientFilterDelete = () => {
        setClientFilter(undefined);
    };

    const handleFacilityFilterDelete = () => {
        setFacilityFilter(undefined);
    };

    const handleTypeFilterDelete = () => {
        setTypeFilter(undefined);
    };

    const getJsiReportCreateOption = () => {
        return (
            <AuthenticatedComponent
                requiredPermissions={['create:reports']}
                component={<MenuItem onClick={handleReportMenuClose(handleCreateNewJsiReport)}>JSI Report</MenuItem>}
            />
        );
    };

    const getMoistureGuardCreateOption = () => {
        return (
            <AuthenticatedComponent
                requiredPermissions={['create:reports']}
                component={<MenuItem onClick={handleReportMenuClose(handleCreateNewClientReport)}>Moisture Guard / RoofSMART Report</MenuItem>}
            />
        );
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    {!workOrder &&
                        !isClientDashboardView && ( // Do not show when displayed on the Work Order / Capital Project page
                            <>
                                <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                                    <InsertChart /> Client Reports
                                </Typography>
                                <NavBreadcrumbs links={[{ label: 'Home', navLink: '/' }]} currentPageLabel='Client Reports' />
                            </>
                        )}
                </Grid>
                <Grid item container direction='row' alignItems='center' xs={6} justifyContent='end'>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:reports']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive()}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <Grid item xs={1}>
                        <Tooltip title={'Filter'}>
                            <IconButton color='primary' size='large' onClick={handleFilter}>
                                <FilterList fontSize='inherit' />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['create:reports']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New Report'>
                                    <IconButton color='primary' size='large' onClick={handleReportMenuOpen}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>

                                <Menu
                                    anchorEl={reportMenuAnchorEl}
                                    open={reportMenuOpen}
                                    onClose={handleReportMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    {isCapitalProjectView === undefined && (
                                        <AuthenticatedComponent
                                            requiredPermissions={['create:reports']}
                                            component={
                                                <MenuItem onClick={handleReportMenuClose(handleCreateNewBinderPortfolioReport)}>Binder Portfolio</MenuItem>
                                            }
                                        />
                                    )}
                                    {isCapitalProjectView !== true && (
                                        <AuthenticatedComponent
                                            requiredPermissions={['create:reports']}
                                            component={<MenuItem onClick={handleReportMenuClose(handleCreateNewCloseOutReport)}>Close Out Report</MenuItem>}
                                        />
                                    )}
                                    {isCapitalProjectView === undefined ? (
                                        <>
                                            {getJsiReportCreateOption()}
                                            {getMoistureGuardCreateOption()}
                                        </>
                                    ) : isCapitalProjectView !== undefined && isCapitalProjectView === true ? (
                                        <>{getJsiReportCreateOption()}</>
                                    ) : (
                                        <>{getMoistureGuardCreateOption()}</>
                                    )}
                                </Menu>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:reports', 'edit:reports', 'delete:reports', 'unlock:reports']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:reports']}
                                        component={
                                            <>
                                                <MenuItem onClick={handleSubMenuOpen}>
                                                    Add New
                                                    <ArrowRight
                                                        style={{
                                                            marginLeft: 'auto',
                                                        }}
                                                    />
                                                </MenuItem>

                                                <Menu
                                                    anchorEl={subMenuAnchorEl}
                                                    open={subMenuOpen}
                                                    onClose={handleSubMenuClose()}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}>
                                                    <AuthenticatedComponent
                                                        requiredPermissions={['create:reports']}
                                                        component={
                                                            <MenuItem onClick={handleReportMenuClose(handleCreateNewBinderPortfolioReport)}>
                                                                Binder Portfolio
                                                            </MenuItem>
                                                        }
                                                    />
                                                    <AuthenticatedComponent
                                                        requiredPermissions={['create:reports']}
                                                        component={
                                                            <MenuItem onClick={handleReportMenuClose(handleCreateNewCloseOutReport)}>Close Out Report</MenuItem>
                                                        }
                                                    />
                                                    {isCapitalProjectView === undefined ? (
                                                        <>
                                                            {getJsiReportCreateOption()}
                                                            {getMoistureGuardCreateOption()}
                                                        </>
                                                    ) : isCapitalProjectView !== undefined && isCapitalProjectView === true ? (
                                                        <>{getJsiReportCreateOption()}</>
                                                    ) : (
                                                        <>{getMoistureGuardCreateOption()}</>
                                                    )}
                                                </Menu>
                                            </>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:reports']}
                                        component={
                                            <>
                                                <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                    Edit
                                                </MenuItem>
                                                <Divider />
                                            </>
                                        }
                                    />
                                    <MenuItem onClick={handleMenuClose(handleFilter)}>Filter</MenuItem>
                                    <AuthenticatedComponent
                                        requiredPermissions={['unlock:reports']}
                                        component={
                                            <MenuItem
                                                onClick={handleUnlock}
                                                disabled={
                                                    selected.length !== 1 ||
                                                    (data && data.pageResults.length > 0 && !isLocked(data?.pageResults[selected[0]].lockDate))
                                                }>
                                                Unlock
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:reports']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:reports', 'edit:visibility']}
                                        logic='and'
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleShowEmployees)} disabled={!canShowEmployee()}>
                                                    Show Employees
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleHideEmployees)} disabled={!canHideEmployee()}>
                                                    Hide Employees
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleShowClients)} disabled={!canShowClient()}>
                                                    Show Clients
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleHideClients)} disabled={!canHideClient()}>
                                                    Hide Clients
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        }
                    />
                    <Grid item xs={4} alignSelf={'end'}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='center' xs={6} spacing={1} sx={{ height: '100%', paddingLeft: '12px', marginTop: '0px' }}>
                {fromDateFilter && toDateFilter && (
                    <Grid item>
                        <Chip
                            label={`Report Date : ${format(new Date(fromDateFilter.toString()), 'M/d/yyyy')} - ${format(
                                new Date(toDateFilter.toString()),
                                'M/d/yyyy'
                            )}`}
                            color='secondary'
                            onDelete={handleReportDateFilterDelete}
                        />
                    </Grid>
                )}
                {fromDateFilter && !toDateFilter && (
                    <Grid item>
                        <Chip
                            label={`Report Date : ${format(new Date(fromDateFilter.toString()), 'M/d/yyyy')} - Present`}
                            color='secondary'
                            onDelete={handleReportDateFilterDelete}
                        />
                    </Grid>
                )}
                {toDateFilter && !fromDateFilter && (
                    <Grid item>
                        <Chip
                            label={`Report Date : On or Before ${format(new Date(toDateFilter.toString()), 'M/d/yyyy')}`}
                            color='secondary'
                            onDelete={handleReportDateFilterDelete}
                        />
                    </Grid>
                )}
                {clientFilter && (
                    <Grid item>
                        <Chip label={`Client: ${clientFilter.name}`} color='secondary' onDelete={handleClientFilterDelete} />
                    </Grid>
                )}
                {facilityFilter && (
                    <Grid item>
                        <Chip label={`Facility: ${facilityFilter.name}`} color='secondary' onDelete={handleFacilityFilterDelete} />
                    </Grid>
                )}
                {(typeFilter || typeFilter === 0) && (
                    <Grid item>
                        <Chip label={`Report Type: ${ReportType[typeFilter]}`} color='secondary' onDelete={handleTypeFilterDelete} />
                    </Grid>
                )}
            </Grid>
            <Grid item maxWidth={'100% !important'}>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='REPORT_DATE'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:reports'] }}
                    onEdit={handleTableEdit}
                />
            </Grid>
            {filterOpen && (
                // TODO: disable client and facility filters when on client dashboard
                <FilterReportsDialog
                    open={filterOpen}
                    onClose={() => setFilterOpen(false)}
                    fromDateFilter={fromDateFilter}
                    setFromDateFilter={setFromDateFilter}
                    toDateFilter={toDateFilter}
                    setToDateFilter={setToDateFilter}
                    clientFilter={clientFilter}
                    setClientFilter={setClientFilter}
                    facilityFilter={facilityFilter}
                    setFacilityFilter={setFacilityFilter}
                    typeFilter={typeFilter}
                    setTypeFilter={setTypeFilter}
                />
            )}
        </Grid>
    );
};
