import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { CloseOutReportForm } from '../../Components/Forms/CloseOutReportForm/CloseOutReportForm';
import { CloseOutReportProps } from '../../Components/Forms/CloseOutReportForm/types';
import { useGetCloseOutReportQuery, useUpdateCloseOutReportMutation } from '../../store/apis/close-out-report-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditCloseOutReportView: FunctionComponent = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading: clientReportLoading, error: clientReportError, refetch: clientReportRefetch } = useGetCloseOutReportQuery(id!);
    const [updateCloseOutReport, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateCloseOutReportMutation();

    useFailedUpdateSnackbar('close out report', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Close out report', isUpdateSuccess);

    const handleSave = (values: CloseOutReportProps) => {
        updateCloseOutReport(values.dto!);
    };

    const handleCancel = () => {
        navigate(`/clientreports/`);
    };

    if (clientReportLoading) {
        return <LoadingIndicator />;
    }

    if (clientReportError) {
        return <ApiError onReloadClick={clientReportRefetch} />;
    }

    return <CloseOutReportForm save={handleSave} cancel={handleCancel} isEditView initValues={{
            dto: data,
            edit: true
        }}
    />;
};
