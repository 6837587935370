import { Assignment, FormatListBulletedOutlined, LockOpen, Map } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardCard } from '../../Components/Dashboard';
import { FormSection } from '../../Components/Forms';
import { ClientReportRoofSectionsCard } from '../../Components/Forms/ClientReportForm/ClientReportRoofSectionsCard';
import { ContractorImmediateRepairsChecklistItemListView } from '../../Components/Forms/ClientReportForm/ContractorImmediateRepairsChecklistItemListView';
import { FormWrapper } from '../../Components/Forms/FormFields';
import { DispatchInformation } from '../../Components/Forms/WorkOrderForm/WorkOrderDispatch/DispatchInformation';
import { ConfirmDialog } from '../../Components/core/ConfirmDialog';
import { INavBreadcrumbsProps } from '../../Components/core/NavBreadcrumbs';
import { MGOrRSReportDispatchDto } from '../../models/MGOrRSReportDispatchDto';
import {
    useCompleteMGOrRSReportDispatchMutation,
    useGetMGOrRSReportDispatchRoofSectionReportsQuery,
    useUpdateMGOrRSReportDispatchMutation,
} from '../../store/apis/report-dispatch-api';
import { useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulActionSnackbar, useSuccessfulUpdateSnackbar, useUserRoleChecker } from '../../util';
import { DispatchNeedsAttentionSection } from './DispatchNeedsAttentionSection';
import { useSubmitDispatchForm } from './useSubmitDispatchForm';

export interface ISubmitMGOrRSReportDispatchFormProps {
    dispatch: MGOrRSReportDispatchDto;
}

export const SubmitMGOrRSReportDispatchForm: FC<ISubmitMGOrRSReportDispatchFormProps> = ({ dispatch }) => {
    const navigate = useNavigate();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [scheduledDate, setScheduledDate] = useState<Date | null | undefined>(dispatch.scheduledDate ? new Date(dispatch.scheduledDate) : null);
    const [fieldErrors, setFieldErrors] = useState({
        SCHEDULED_DATE: '',
    });

    const { isContractor } = useUserRoleChecker();

    const onCloseOutComplete = useCallback(() => {
        navigate(`/clientreports/${dispatch.workOrderId}/edit/${dispatch.reportId}`);
    }, [dispatch, navigate]);

    const { closeOutDispatch, getSubmitButtonText, isLocked, isFullEdit, confirmUnlockOpen, setConfirmUnlockOpen, handleUnlock } =
        useSubmitDispatchForm(dispatch, onCloseOutComplete);

    const [updateDispatch, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: updateReset }] = useUpdateMGOrRSReportDispatchMutation();
    useFailedUpdateSnackbar(`${dispatch.typeName} dispatch`, isUpdateError, updateReset);
    useSuccessfulUpdateSnackbar(`${dispatch.typeName} dispatch`, isUpdateSuccess, updateReset);

    const [submitDispatch, { isSuccess: isSubmitSuccess, isError: isSubmitError, reset: submitReset }] = useCompleteMGOrRSReportDispatchMutation();
    useFailedActionSnackbar('submit', `${dispatch.typeName} dispatch`, isSubmitError, submitReset);
    useSuccessfulActionSnackbar('submitted', `${dispatch.typeName} dispatch`, isSubmitSuccess, () => {
        submitReset();
        navigate(`/`);
    });

    const buildPageTitle = useCallback(() => {
        return `Work Order #${dispatch.woNumber} - ${dispatch.typeName}`;
    }, [dispatch]);

    const pageBreadCrumbs: INavBreadcrumbsProps = {
        links: [{ label: 'Home', navLink: '/' }],
        currentPageLabel: buildPageTitle(),
    };

    const validateField = useCallback(
        (fieldName: string) => {
            let isValid = false;
            if (fieldName === 'SCHEDULED_DATE') {
                if (scheduledDate && scheduledDate.toString() !== 'Invalid Date') {
                    fieldErrors.SCHEDULED_DATE = '';
                    isValid = true;
                } else {
                    fieldErrors.SCHEDULED_DATE = 'Scheduled Date is required to accept work order';
                    isValid = false;
                }
            }
            setFieldErrors({
                ...fieldErrors,
            });
            return isValid;
        },
        [fieldErrors, scheduledDate]
    );

    const validateForm = useCallback(() => {
        return validateField('SCHEDULED_DATE');
    }, [validateField]);

    const handleSave = useCallback(
        (event: React.SyntheticEvent) => {
            if (validateForm()) {
                event.preventDefault();
                updateDispatch({
                    dispatchId: dispatch.id,
                    scheduledDate: scheduledDate!,
                });
                setIsFormDirty(false);
            }
        },
        [validateForm, updateDispatch, scheduledDate, dispatch.id]
    );

    const handleSubmit = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            if (validateForm()) {
                if (dispatch.status === 'Completed') {
                    closeOutDispatch({ dispatchId: dispatch.id, shouldCopyOverDispatchPhotos: false });
                } else {
                    submitDispatch({
                        dispatchId: dispatch.id,
                        scheduledDate: scheduledDate!,
                    });
                }
                setIsFormDirty(false);
            }
        },
        [validateForm, submitDispatch, scheduledDate, dispatch.status, dispatch.id, closeOutDispatch]
    );

    const handleCancel = useCallback(() => {
        if (isContractor) {
            navigate('/');
        } else {
            navigate(`/clientreports/${dispatch?.workOrderId}/edit/${dispatch.reportId}`);
        }
    }, [navigate, dispatch, isContractor]);

    const disabledForCurrentUser = useMemo(() => {
        if (isLocked) {
            return true;
        }

        if (isContractor) {
            return ["Completed", "Recalled"].includes(dispatch.status ?? "");
        } else {
            return ["Dispatched", "Accepted", "Needs Attention"].includes(dispatch.status ?? "");
        }
    }, [isContractor, dispatch.status, isLocked]);

    const canSubmit = useMemo(() => {
        if (isContractor) {
            return ["Accepted", "Needs Attention"].includes(dispatch.status ?? "");
        } else {
            return ["Completed"].includes(dispatch.status ?? "");
        }
    }, [isContractor, dispatch.status]);

    return (
        <FormWrapper
            icon={<Assignment />}
            breadCrumbProps={pageBreadCrumbs}
            isFormDirty={isFormDirty}
            name={buildPageTitle()}
            save={handleSave}
            submit={handleSubmit}
            submitButtonText={getSubmitButtonText()}
            cancel={handleCancel}
            disabledSubmit={!canSubmit}
            isLocked={isLocked}
            unlock={
                isFullEdit
                    ? () => {
                          setConfirmUnlockOpen(true);
                      }
                    : undefined
            }>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row'>
                        <DispatchInformation
                            dispatch={dispatch}
                            hideWONumber={true}
                            scheduledDate={{
                                scheduledDate: scheduledDate,
                                setScheduledDate: setScheduledDate,
                                validate: validateField,
                                setFieldErrors: setFieldErrors,
                                fieldErrors: fieldErrors,
                                disabled: disabledForCurrentUser,
                            }}
                        />
                    </Grid>
                    <DispatchNeedsAttentionSection dispatch={dispatch} isLocked={isLocked} />
                </FormSection>
            </Grid>
            <Grid item>
                <DashboardCard headerTitle='Roof Sections' headerIcon={<Map />}>
                    <ClientReportRoofSectionsCard
                        reportId={dispatch.reportId}
                        dispatchId={dispatch.id}
                        disabled={disabledForCurrentUser}
                        getRoofSectionsQuery={useGetMGOrRSReportDispatchRoofSectionReportsQuery}
                    />
                </DashboardCard>
            </Grid>
            <Grid item>
                <DashboardCard headerTitle='Immediate Repairs Checklist' headerIcon={<FormatListBulletedOutlined />}>
                    <ContractorImmediateRepairsChecklistItemListView
                        disabled={disabledForCurrentUser}
                        dispatchId={dispatch.id}
                        reportId={dispatch.report.id}
                    />
                </DashboardCard>
            </Grid>
            <ConfirmDialog
                open={confirmUnlockOpen}
                onClose={() => setConfirmUnlockOpen(false)}
                content={<Typography>Do you want to unlock this dispatch?</Typography>}
                titleIcon={<LockOpen />}
                title={<Typography variant='h3'>Unlock Dispatch?</Typography>}
                cancelText='No'
                onConfirm={() => handleUnlock(dispatch!.id)}
            />
        </FormWrapper>
    );
};
