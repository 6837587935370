import { serverApi } from '..';
import { WorkOrderDto, QueryResultsDto, DocumentDto, WorkOrderListViewDto, WorkOrderCapitalProjectsQueryResultsDto } from '../../models';
import { ContractorMetricRecordDto } from '../../models/ContractorMetricRecordDto';
import { OpenWorkOrdersSummaryDto } from '../../models/OpenWorkOrdersSummaryDto';
import { buildSearchQueryRoute } from '../../util';
import {
    ChildSearchQueryParams,
    ClientFacilityWorkOrderChildSearchQueryParams,
    ContractorMetricQueryParams,
    LeakLocationQueryParams,
    RoofSmartCalculationQueryParams,
    SummaryQueryParams,
    WorkOrderSearchQueryParams,
} from '../types';

const workOrderApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkOrders: builder.query<QueryResultsDto<WorkOrderListViewDto>, WorkOrderSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('workorder', params),
            providesTags: [{ type: 'Work Order' }],
        }),
        getClientWorkOrders: builder.query<WorkOrderCapitalProjectsQueryResultsDto<WorkOrderDto>, ClientFacilityWorkOrderChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`workorder/client/${params.parentId}`, params.params),
            providesTags: [{ type: 'Work Order' }],
        }),
        getLeakLocationHistory: builder.query<QueryResultsDto<WorkOrderDto>, LeakLocationQueryParams>({
            query: (params) => buildSearchQueryRoute(`workorder/leakLocation/${params.leakLocationId}`, params),
            providesTags: [{ type: 'Leak Location' }],
        }),
        getWorkOrder: builder.query<WorkOrderDto, string>({
            query: (workOrderId) => ({ url: `workorder/${workOrderId}` }),
            providesTags: [{ type: 'Work Order' }],
        }),
        getWorkOrderNumber: builder.query<number, void>({
            query: () => ({ url: 'workorder/number' }),
            providesTags: [{ type: 'Work Order' }],
        }),
        createWorkOrder: builder.mutation<WorkOrderDto, WorkOrderDto>({
            query: (workOrderDto) => ({
                url: 'workorder',
                method: 'POST',
                body: workOrderDto,
            }),
            invalidatesTags: [{ type: 'Work Order' }, { type: 'Work Order Status History' }, { type: 'Document' }],
        }),
        duplicateWorkOrder: builder.mutation<string, string>({
            query: (workOrderId) => ({
                url: `workorder/${workOrderId}/duplicate`,
                method: 'POST',
            }),
            invalidatesTags: [{ type: 'Work Order' }, { type: 'Work Order Status History' }],
        }),
        updateWorkOrder: builder.mutation<void, WorkOrderDto>({
            query: (workOrderDto) => ({
                url: 'workorder',
                method: 'PUT',
                body: workOrderDto,
            }),
            invalidatesTags: [{ type: 'Work Order' }, { type: 'Work Order Status History' }, { type: 'Roof Leak' }, { type: 'Document' }],
        }),
        archiveWorkOrder: builder.mutation<void, string>({
            query: (id) => ({
                url: `workorder/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Work Order' }, { type: 'Work Order Status History' }, { type: 'Document' }],
        }),
        unarchiveWorkOrder: builder.mutation<void, string>({
            query: (id) => ({
                url: `workorder/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Work Order' }, { type: 'Work Order Status History' }, { type: 'Document' }],
        }),
        getWorkOrderDocuments: builder.query<QueryResultsDto<DocumentDto>, ChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`workorder/${params.parentId}/documents`, params.params),
            providesTags: [{ type: 'Document' }],
        }),
        getOpenWorkOrdersSummary: builder.query<OpenWorkOrdersSummaryDto, SummaryQueryParams>({
            query: (params) => buildSearchQueryRoute(`workorder/summary`, params),
        }),
        getRoofSmartCalculation: builder.query<number, RoofSmartCalculationQueryParams>({
            query: (params) => buildSearchQueryRoute(`workorder/roofSmartCalculation`, params),
        }),
        getContractorMetrics: builder.query<ContractorMetricRecordDto, ContractorMetricQueryParams>({
            query: (params) => buildSearchQueryRoute(`workorder/contractorMetric`, params),
        }),
    }),
});

export const {
    useGetWorkOrdersQuery,
    useLazyGetWorkOrdersQuery,
    useGetClientWorkOrdersQuery,
    useLazyGetClientWorkOrdersQuery,
    useGetContractorMetricsQuery,
    useGetWorkOrderQuery,
    useLazyGetWorkOrderQuery,
    useGetLeakLocationHistoryQuery,
    useLazyGetWorkOrderNumberQuery,
    useCreateWorkOrderMutation,
    useDuplicateWorkOrderMutation,
    useUpdateWorkOrderMutation,
    useArchiveWorkOrderMutation,
    useUnarchiveWorkOrderMutation,
    useGetWorkOrderDocumentsQuery,
    useGetOpenWorkOrdersSummaryQuery,
    useLazyGetRoofSmartCalculationQuery,
    useLazyGetContractorMetricsQuery,
} = workOrderApi;
