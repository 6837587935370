import { ContentCopy, FormatListBulleted, Launch, OpenInFull } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Tab,
    TextField,
    Typography,
} from '@mui/material';
import _, { isNaN } from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BudgetDto, BudgetLineItemDto, BudgetLineItemYearDto, RoofConditionDto, RoofSectionDto, RoofSystemDto, emptyGuid } from '../../../models';
import { useCreateBudgetLineItemMutation, useGetBudgetLineItemsQuery, useUpdateBudgetLineItemMutation } from '../../../store/apis/budget-line-item-api';
import { useGetRoofConditionsQuery, useGetRoofSystemsQuery } from '../../../store/apis/roof-api';
import { useCreateRoofSectionMutation, useGetRoofSectionsQuery } from '../../../store/apis/roof-section-api';
import { ColorSwatch } from '../../../util';
import {
    useFailedActionSnackbar,
    useFailedCreateSnackbar,
    useFailedUpdateSnackbar,
    useSuccessfulCreateSnackbar,
    useSuccessfulUpdateSnackbar,
} from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { IEntitySelect } from '../../core/IEntitySelect';
import LoadingIndicator from '../../core/LoadingIndicator';
import { FormNumberInput, patterns } from '../FormFields';
import { SelectRoofSectionsDialog } from '../SelectRoofSection';
import { BudgetLineItemPreview } from './BudgetLineItemPreview';
import { BudgetLineItemYearLine, IBudgetLineItemYearLineErrors } from './BudgetLineItemYearLine';
import { CreateRoofSectionDialog } from './CreateRoofSectionDialog';

const DEFAULT_BUDGET_LINE_ITEM_YEAR: BudgetLineItemYearDto = {
    id: emptyGuid,
    createdOn: undefined,
    isActive: true,
    year: new Date().getFullYear(),
    multiplier: 1.0,
    amount: undefined,
    conditionId: undefined,
    needsImmediateRepairs: false,
};

interface IAddNewBudgetLineItemDialog {
    facilityId: string;
    budget?: BudgetDto;
    open: boolean;
    onClose: () => void;
    initValues?: BudgetLineItemDto;
    roofProfileId?: string;
}

export const AddNewBudgetLineItemDialog: FC<IAddNewBudgetLineItemDialog> = ({ initValues, facilityId, budget, open, onClose, roofProfileId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [tab, setTab] = useState('0');
    const [isAddRoofSectionDialogOpen, setIsAddRoofSectionDialogOpen] = useState(false);
    const [isExistingRoofSectionSelected, setIsExistingRoofSectionSelected] = useState(!!initValues?.roofSectionId);
    const [keepOpen, setKeepOpen] = useState(false);
    const [isSelectRoofSectionsDialogOpen, setIsSelectRoofSectionsDialogOpen] = useState(false);
    const [budgetLineItemId, steBudgetLineItemId] = useState(initValues?.id ?? emptyGuid);
    const [roofSections, setRoofSections] = useState<RoofSectionDto[]>(initValues?.roofSection ? [initValues?.roofSection] : []);
    const [roofNumber, setRoofNumber] = useState<string>(initValues?.roofSection ? initValues.roofSection.roofNumber : '');
    const [squareFeet, setSquareFeet] = useState(initValues ? initValues?.roofSection?.squareFootage : null);
    const [assetValueMultiplier, setAssetValueMultiplier] = useState(initValues ? initValues?.roofSection?.assetValueMultiplier : null);
    const [assetValue, setAssetValue] = useState(initValues ? initValues?.roofSection?.assetValue : null);
    const [warranty, setWarranty] = useState<string>(initValues ? initValues?.roofSection?.warrantyStatus ?? '' : '');
    const [roofSystem, setRoofSystem] = useState<RoofSystemDto | null>(initValues?.roofSection?.roofSystem ?? null);
    const [currentCondition, setCurrentCondition] = useState<string>(initValues?.roofSection?.roofConditionId ?? '');
    const [total, setTotal] = useState<number>(initValues?.total ? initValues.total : 0);
    const [years, setYears] = useState<BudgetLineItemYearDto[]>(initValues?.budgetLineItemYears ? initValues.budgetLineItemYears : []);
    const [copyBudgetLineItem, setCopyBudgetLineItem] = useState<BudgetLineItemDto | null>(null);
    const [changed, setFormChanged] = useState(false);
    const numBudgetYears = useMemo(() => (budget ? budget.budgetYearCount : 5), [budget]);

    const {
        data: roofSystems,
        error: roofSystemsError,
        isLoading: roofSystemsLoading,
        refetch: refetchRoofSystems,
    } = useGetRoofSystemsQuery({ includeInactive: true });
    const {
        data: roofConditions,
        isLoading: roofConditionsLoading,
        error: roofConditionsError,
        refetch: roofConditionsRefetch,
    } = useGetRoofConditionsQuery({ includeInactive: true });
    const {
        data: roofSectionsData,
        isLoading: roofSectionsLoading,
        error: roofSectionsError,
        refetch: roofSectionsRefetch,
    } = useGetRoofSectionsQuery({
        searchText: roofNumber,
        sortKey: 'ROOF_NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 250,
        includeInactive: true,
        facilityId: facilityId,
    });
    const {
        data: allBudgetLineItems,
        isLoading: budgetLineItemsLoading,
        isError: isGetBudgetLineItemsError,
        refetch: reloadBudgetLineItems,
    } = useGetBudgetLineItemsQuery({
        parentId: budget?.id ?? '',
        params: {
            sortKey: 'ROOF_NUMBER',
            sortAsc: true,
            page: 0,
            pageSize: 10000,
            includeInactive: true,
        },
    });
    const [createLineItem, { data: createdLineItem, isSuccess: isCreateLineItemSuccess, isError: isCreateLineItemError, reset: resetCreateLineItem }] =
        useCreateBudgetLineItemMutation();
    const [updateLineItem, { isSuccess: isUpdateLineItemSuccess, isError: isUpdateLineItemError, reset: resetUpdateLineItem }] =
        useUpdateBudgetLineItemMutation();
    const [createRoofSection, { isError: isCreateRoofSectionError, reset: resetCreateRoofSection }] = useCreateRoofSectionMutation();
    const [fieldErrors, setFieldErrors] = useState({
        ROOF_NUMBER: '',
        ASSET_VALUE: '',
        SQUARE_FOOTAGE: '',
        CURRENT_CONDITION: '',
        YEARS: (initValues?.budgetLineItemYears ? initValues.budgetLineItemYears : []).map((_) => {
            return { amountError: '', conditionError: '' };
        }) as { amountError: string; conditionError: string }[],
    });

    const beforeClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const clearAllField = useCallback(() => {
        steBudgetLineItemId(emptyGuid);
        setRoofNumber('');
        setSquareFeet(0);
        setAssetValueMultiplier(0);
        setAssetValue(0);
        setWarranty('');
        setRoofSystem(null);
        setCurrentCondition('');
        setTotal(0);
        setYears([]);
        setIsExistingRoofSectionSelected(false);
    }, []);

    useFailedActionSnackbar('retrieving', 'Budget Line Items', isGetBudgetLineItemsError);
    useFailedCreateSnackbar('roof section', isCreateRoofSectionError, resetCreateRoofSection);
    useFailedCreateSnackbar('budget line item', isCreateLineItemError, resetCreateLineItem);
    useFailedUpdateSnackbar('budget line item', isUpdateLineItemError, resetUpdateLineItem);
    useSuccessfulCreateSnackbar('Budget Line Item', isCreateLineItemSuccess, () => {
        if (open) {
            if (keepOpen) {
                reloadBudgetLineItems();
                clearAllField();
                setCopyBudgetLineItem(createdLineItem ?? null);
            } else {
                beforeClose();
            }
            resetCreateLineItem();
        }
        roofSectionsRefetch();
    });

    useSuccessfulUpdateSnackbar('Budget Line Item', isUpdateLineItemSuccess, () => {
        if (open) {
            if (keepOpen) {
                reloadBudgetLineItems();
                clearAllField();
                setCopyBudgetLineItem(allBudgetLineItems?.pageResults?.find((bli) => bli.id === budgetLineItemId) ?? null);
            } else {
                beforeClose();
            }
            resetUpdateLineItem();
        }
        roofSectionsRefetch();
    });

    const handleSelectRoofSectionsDialogClose = useCallback(() => {
        setIsSelectRoofSectionsDialogOpen(false);
    }, []);

    const handleSelectRoofSectionsDialogConfirm = useCallback(() => {
        if (roofSections.length > 0) {
            setRoofNumber(roofSections[0]?.roofNumber);
        }
        setIsSelectRoofSectionsDialogOpen(false);
    }, [roofSections]);

    const handleSquareFeetChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFormChanged(true);
            const sqFeetInput = event.target.valueAsNumber;
            const rSqFeetInput = Math.round(sqFeetInput);
            setSquareFeet(rSqFeetInput);
            const assetValue = rSqFeetInput * (assetValueMultiplier ?? 0);
            setAssetValue(Math.round(assetValue));
            const updatedYears = [...years];
            setYears(
                updatedYears.map((year) => {
                    return { ...year, amount: Math.round(sqFeetInput * year.multiplier) };
                })
            );
        },
        [assetValueMultiplier, years]
    );

    const handleAssetValueMultiplierChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFormChanged(true);
            setAssetValueMultiplier(event.target.valueAsNumber);
            const assetValue = event.target.valueAsNumber * (squareFeet ?? 0);
            const truncated = parseFloat(assetValue.toFixed(5));
            setAssetValue(Math.round(truncated));
        },
        [squareFeet]
    );

    const handleAssetValueChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFormChanged(true);
            const rValue = Math.round(event.target.valueAsNumber);
            setAssetValue(rValue);
            if (squareFeet) {
                const multiplier = rValue / squareFeet;
                const truncated = parseFloat(multiplier.toFixed(5));
                setAssetValueMultiplier(truncated);
            } else {
                setAssetValueMultiplier(1.0);
            }
        },
        [squareFeet]
    );

    const handleWarrantyChange = useCallback((event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        setWarranty(event.target.value);
    }, []);

    const handleCurrentConditionChange = useCallback((event: SelectChangeEvent) => {
        setFormChanged(true);
        setCurrentCondition(event.target.value);
    }, []);

    const handleTotalChange = useCallback(() => {
        let totalCalculation: number = 0;
        years.forEach((x) => {
            totalCalculation += x.amount ?? 0;
        });
        setTotal(totalCalculation);
    }, [years]);

    useEffect(() => {
        if (years) {
            handleTotalChange();
        }
    }, [handleTotalChange, years]);

    const onKeepOpenChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setKeepOpen(checked);
    }, []);

    const validate = useCallback(
        (fieldName: string) => {
            setFormChanged(true);
            let isValid = false;
            if (fieldName === 'ROOF_NUMBER') {
                if (roofNumber) {
                    fieldErrors.ROOF_NUMBER = '';
                    isValid = true;
                    if (roofNumber.length > 4) {
                        fieldErrors.ROOF_NUMBER = 'Roof # must be 4 or less characters';
                        isValid = false;
                    }
                } else {
                    fieldErrors.ROOF_NUMBER = 'Roof # is required';
                    isValid = false;
                }
            } else if (fieldName === 'ASSET_VALUE') {
                if (assetValue !== undefined && assetValue !== null) {
                    fieldErrors.ASSET_VALUE = '';
                    isValid = true;
                } else {
                    fieldErrors.ASSET_VALUE = 'Asset Value must be in decimal format';
                    isValid = false;
                }
            } else if (fieldName === 'SQUARE_FOOTAGE') {
                if (squareFeet) {
                    fieldErrors.SQUARE_FOOTAGE = '';
                    isValid = true;
                } else {
                    fieldErrors.SQUARE_FOOTAGE = 'Square Footage must be in decimal format';
                    isValid = false;
                }
            } else if (fieldName === 'CURRENT_CONDITION') {
                if (currentCondition && currentCondition !== '') {
                    fieldErrors.CURRENT_CONDITION = '';
                    isValid = true;
                } else {
                    fieldErrors.CURRENT_CONDITION = 'Current condition must be selected';
                    isValid = false;
                }
            } else if (fieldName === 'YEARS') {
                isValid = true;
                years.forEach((year, idx) => {
                    if (!validateYear(idx, year.amount, year.conditionId, fieldErrors)) {
                        isValid = false;
                    }
                });
            }
            setFieldErrors({
                ROOF_NUMBER: fieldErrors.ROOF_NUMBER,
                ASSET_VALUE: fieldErrors.ASSET_VALUE,
                SQUARE_FOOTAGE: fieldErrors.SQUARE_FOOTAGE,
                CURRENT_CONDITION: fieldErrors.CURRENT_CONDITION,
                YEARS: fieldErrors.YEARS,
            });
            return isValid;
        },
        [assetValue, currentCondition, fieldErrors, roofNumber, squareFeet, years]
    );

    const onFieldBlur = useCallback(
        (fieldName: string) => () => {
            validate(fieldName);
        },
        [validate]
    );

    const onRoofNumberBlur = useCallback(() => {
        const selectedRoofSection = roofSectionsData?.pageResults.find((roofSection) => roofSection.roofNumber === roofNumber);
        validate('ROOF_NUMBER');
        if (selectedRoofSection) {
            setIsExistingRoofSectionSelected(true);
            setSquareFeet(selectedRoofSection.squareFootage);
            setAssetValueMultiplier(selectedRoofSection.assetValueMultiplier);
            setAssetValue(selectedRoofSection.assetValue);
            setWarranty(selectedRoofSection.warrantyStatus ?? 'No');
            setCurrentCondition(selectedRoofSection.roofConditionId ?? '');
            setRoofSystem(selectedRoofSection?.roofSystem ?? null);
        } else {
            if (roofNumber) {
                setIsExistingRoofSectionSelected(false);
                setSquareFeet(undefined);
                setAssetValueMultiplier(undefined);
                setAssetValue(undefined);
                setWarranty('');
                setRoofSystem(null);
                setCurrentCondition('');
            }
        }
    }, [roofNumber, roofSectionsData?.pageResults, validate]);

    const handleRoofNumberChange = useCallback(
        (value: RoofSectionDto | null | undefined | string) => {
            setFormChanged(true);
            if (value === null || value === undefined) {
                setRoofNumber('');
                return;
            }
            if (_.isString(value)) {
                setRoofNumber(value);
            }
            onFieldBlur('ROOF_NUMBER');
        },
        [onFieldBlur]
    );

    function validateYear(idx: number, amount: number | null | undefined, condition: string | undefined, fieldErrors: any) {
        let isValid = true;
        if (amount !== undefined && amount !== null && !condition) {
            fieldErrors['YEARS'][idx].conditionError = 'Category must be selected if an amount is provided';
            isValid = false;
        } else {
            fieldErrors['YEARS'][idx].conditionError = '';
        }
        if (isNaN(amount ?? 0)) {
            fieldErrors['YEARS'][idx].amountError = 'Invalid amount';
            isValid = false;
        } else {
            fieldErrors['YEARS'][idx].amountError = '';
        }
        return isValid;
    }

    const fillInMissingYears = useCallback(() => {
        const currentNumberOfYears = years.length;
        const updatedYears = _.cloneDeep(years);
        const updatedFieldErrors = _.cloneDeep(fieldErrors);

        for (let budgetYear = currentNumberOfYears; budgetYear < numBudgetYears; budgetYear++) {
            let newYear = {
                ...DEFAULT_BUDGET_LINE_ITEM_YEAR,
                year: budget?.startYear ? new Date(budget.startYear).getFullYear() + budgetYear : 1,
            };
            updatedYears.push(newYear);
            updatedFieldErrors.YEARS.push({ amountError: '', conditionError: '' });
        }

        if (updatedFieldErrors.YEARS.length !== fieldErrors.YEARS.length) {
            setFieldErrors(updatedFieldErrors);
        }
        if (updatedYears.length !== years.length) {
            setYears(updatedYears);
        }
    }, [budget, fieldErrors, years, numBudgetYears]);

    const removeSurplusYears = useCallback(() => {
        let updatedYears = _.cloneDeep(years);
        let updatedFieldErrors = _.cloneDeep(fieldErrors);

        updatedYears = updatedYears.slice(0, numBudgetYears);
        fieldErrors.YEARS = fieldErrors.YEARS.slice(0, numBudgetYears);

        if (updatedFieldErrors.YEARS.length !== fieldErrors.YEARS.length) {
            setFieldErrors(updatedFieldErrors);
        }
        if (updatedYears.length !== years.length) {
            setYears(updatedYears);
        }
    }, [fieldErrors, years, numBudgetYears]);

    useEffect(() => {
        if (years.length < numBudgetYears) {
            fillInMissingYears();
        } else if (years.length > numBudgetYears) {
            removeSurplusYears();
        }
    }, [fillInMissingYears, removeSurplusYears, initValues?.id, years.length, numBudgetYears]);

    const formIsValid = useCallback(() => {
        let isRoofNumberValid = validate('ROOF_NUMBER');
        let isAssetValueValid = validate('ASSET_VALUE');
        let isSquareFootageValid = validate('SQUARE_FOOTAGE');
        let isCurrentConditionValid = validate('CURRENT_CONDITION');
        let areYearsValid = validate('YEARS');
        if (isRoofNumberValid && isAssetValueValid && isSquareFootageValid && isCurrentConditionValid && areYearsValid) {
            return true;
        }
    }, [validate]);

    const refetch = useCallback(() => {
        roofSectionsRefetch();
        refetchRoofSystems();
        roofConditionsRefetch();
    }, [refetchRoofSystems, roofConditionsRefetch, roofSectionsRefetch]);

    const allOtherBudgetLineItems = useCallback(() => {
        return allBudgetLineItems?.pageResults.filter((bli) => bli.id !== budgetLineItemId) ?? [];
    }, [allBudgetLineItems?.pageResults, budgetLineItemId]);

    const handleCopyBudgetLineItemIdClicked = useCallback(() => {
        if (!copyBudgetLineItem) {
            enqueueSnackbar('Please select a Roof Section to copy from', { variant: 'warning' });
            return;
        }
        const selectedBudgetLineItem = copyBudgetLineItem;
        if (!selectedBudgetLineItem) {
            enqueueSnackbar('Unable to find selected Roof Section to copy from', { variant: 'error' });
        }
        setAssetValueMultiplier(selectedBudgetLineItem?.roofSection?.assetValueMultiplier);
        setRoofSystem(selectedBudgetLineItem?.roofSection?.roofSystem ?? null);
        setWarranty(selectedBudgetLineItem?.roofSection?.warrantyStatus ?? '');
        setCurrentCondition(selectedBudgetLineItem?.roofSection?.roofConditionId ?? '');
        setYears(
            selectedBudgetLineItem?.budgetLineItemYears?.map((x) => {
                const newYear = { ...x };
                newYear.id = emptyGuid;
                return newYear;
            }) ?? []
        );

        setFieldErrors({
            ...fieldErrors,
            YEARS: (selectedBudgetLineItem?.budgetLineItemYears ? selectedBudgetLineItem.budgetLineItemYears : []).map((_) => {
                return { amountError: '', conditionError: '' };
            }),
        });

        setFormChanged(true);
    }, [copyBudgetLineItem, enqueueSnackbar, fieldErrors]);

    const previewVisible = useMemo(() => {
        return tab === '1';
    }, [tab]);

    const budgetLineItemYearRow = useCallback(
        (idx: number, budgetLineItemYear: BudgetLineItemYearDto) => {
            const handleYearChange = (updatedValue: BudgetLineItemYearDto) => {
                const updatedYears = [...years];
                updatedYears[idx] = updatedValue;
                setYears(updatedYears);
                setFormChanged(true);
            };

            const handleCopyDown = (idx: number) => {
                let updatedYears = _.cloneDeep(years);
                updatedYears[idx].multiplier = updatedYears[idx - 1].multiplier;
                updatedYears[idx].amount = updatedYears[idx - 1].amount;
                updatedYears[idx].conditionId = updatedYears[idx - 1].conditionId;
                setYears(updatedYears);
                setFormChanged(true);
            };

            const errors: IBudgetLineItemYearLineErrors = {
                amount: fieldErrors.YEARS.length > 0 ? fieldErrors?.YEARS[idx]?.amountError ?? '' : '',
                condition: fieldErrors.YEARS.length > 0 ? fieldErrors?.YEARS[idx]?.conditionError ?? '' : '',
            };

            const isFirstYear = idx === 0;

            return (
                <BudgetLineItemYearLine
                    key={`bliyl-${idx}`}
                    value={budgetLineItemYear}
                    onChange={handleYearChange}
                    errors={errors}
                    showLabel={isFirstYear}
                    squareFeet={squareFeet ?? undefined}
                    roofConditions={roofConditions ?? []}
                    showCopyDown={!isFirstYear}
                    onCopyDownClicked={() => handleCopyDown(idx)}
                />
            );
        },
        [fieldErrors.YEARS, years, roofConditions, squareFeet]
    );

    const currentRoofSection = useMemo(() => {
        const roofSections = roofSectionsData?.pageResults.filter((x) => x.roofNumber === roofNumber);
        // If there are multiple roof sections with the same roof number, return the active one
        if (roofSections && roofSections.length > 1) {
            return roofSections.find((x) => x.isActive);
        }
        return roofSectionsData?.pageResults.find((x) => x.roofNumber === roofNumber);
    }, [roofSectionsData, roofNumber]);

    const handleSave = useCallback(
        async (createRoofConfirmed: boolean = false) => {
            if (formIsValid()) {
                if (!isExistingRoofSectionSelected && !createRoofConfirmed) {
                    setIsAddRoofSectionDialogOpen(true);
                    return;
                }

                const formValues = {
                    id: budgetLineItemId,
                    isActive: true,
                    budgetId: budget?.id!,
                    roofName: '',
                    budgetLineItemYears: years,
                } as BudgetLineItemDto;
                if (createRoofConfirmed) {
                    await createRoofSection({
                        id: emptyGuid,
                        isActive: true,
                        roofNumber: roofNumber,
                        assetValue: assetValue!,
                        squareFootage: squareFeet!,
                        assetValueMultiplier: assetValueMultiplier ?? 1.0,
                        warrantyStatus: warranty,
                        roofConditionId: currentCondition,
                        roofSystemId: roofSystem?.id,
                        facilityId: facilityId,
                        roofProfileRoofSections: [
                            {
                                roofProfileId: roofProfileId,
                                roofSectionId: emptyGuid,
                            },
                        ],
                    }).then((response: any) => {
                        if (budgetLineItemId !== emptyGuid) {
                            updateLineItem({
                                ...formValues,
                                roofSectionId: response.data?.id,
                            });
                        } else {
                            createLineItem({
                                ...formValues,
                                roofSectionId: response.data?.id,
                            });
                        }
                    });
                } else {
                    if (budgetLineItemId !== emptyGuid) {
                        updateLineItem({
                            ...formValues,
                            roofSectionId: currentRoofSection?.id,
                        });
                    } else {
                        await createLineItem({
                            ...formValues,
                            roofSectionId: currentRoofSection?.id,
                        });
                    }
                }
            }
        },
        [
            assetValue,
            assetValueMultiplier,
            budget?.id,
            budgetLineItemId,
            createLineItem,
            createRoofSection,
            currentCondition,
            currentRoofSection?.id,
            facilityId,
            formIsValid,
            isExistingRoofSectionSelected,
            roofNumber,
            roofProfileId,
            roofSystem?.id,
            squareFeet,
            updateLineItem,
            warranty,
            years,
        ]
    );

    const handleCreateRoofConfirmationClose = useCallback(
        (isConfirmed: boolean) => {
            setIsAddRoofSectionDialogOpen(false);
            if (isConfirmed) {
                handleSave(isConfirmed);
            }
        },
        [handleSave]
    );

    if (roofSystemsLoading || roofConditionsLoading || roofSectionsLoading) {
        return <LoadingIndicator />;
    }

    if (roofSystemsError || roofConditionsError || roofSectionsError) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Dialog maxWidth='xl' fullWidth open={open}>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <FormatListBulleted />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        {initValues?.id ? 'Edit' : 'New'} Budget Line Item
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' spacing={3} mt='16px' columns={16} justifyItems='space-between'>
                    <Grid item container direction='row' justifyItems='space-between' xs={12} wrap='nowrap' alignItems='center'>
                        <Grid item container direction='row' spacing={2} alignItems='center'>
                            <Grid item xs={4}>
                                <FormControl fullWidth disabled={budgetLineItemsLoading || !!isGetBudgetLineItemsError}>
                                    <FormLabel sx={{ fontSize: '12px' }}>Copy From</FormLabel>
                                    <IEntityAutocomplete
                                        options={allOtherBudgetLineItems()}
                                        onChange={(e, value) => {
                                            setFormChanged(true);
                                            setCopyBudgetLineItem(value);
                                        }}
                                        value={copyBudgetLineItem}
                                        getOptionLabel={(option: BudgetLineItemDto) => `Roof #${option.roofSection?.roofNumber}`}
                                        isLoading={budgetLineItemsLoading}
                                        disabled={previewVisible}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    sx={{ marginTop: 2 }}
                                    onClick={() => handleCopyBudgetLineItemIdClicked()}
                                    disabled={previewVisible}>
                                    <Grid container direction='row' spacing={2} wrap='nowrap' alignItems='center'>
                                        <Grid item>
                                            <ContentCopy fontSize='small' sx={{ marginTop: '10px' }} />
                                        </Grid>
                                        <Grid item>Copy</Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography fontWeight={'bold'} fontSize={24} textAlign='right'>
                                Total:{' '}
                                {total?.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item flexGrow={1}>
                            <FormControl error={fieldErrors.ROOF_NUMBER !== ''} fullWidth required>
                                <FormLabel sx={{ fontSize: '12px' }}>Roof #</FormLabel>
                                <Grid item container alignItems={'center'} direction='row'>
                                    <Grid item flexGrow={1}>
                                        <Autocomplete
                                            value={roofNumber ?? null}
                                            onChange={(event, value, reason, details) => {
                                                if (typeof value === 'string') {
                                                    setTimeout(() => {
                                                        setRoofNumber(value.replace(' (Inactive)', '').substring(0, 4));
                                                    });
                                                }
                                            }}
                                            options={roofSectionsData?.pageResults.map((x) => `${x.roofNumber}${x.isActive ? '' : ' (Inactive)'}`)! ?? []}
                                            getOptionLabel={(option: string) => {
                                                return option;
                                            }}
                                            onBlur={onRoofNumberBlur}
                                            selectOnFocus
                                            handleHomeEndKeys
                                            renderOption={(props, option) => <li {...props}>{option}</li>}
                                            freeSolo
                                            disableClearable
                                            disabled={previewVisible}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{ ...params.inputProps, maxLength: 4 }}
                                                    error={fieldErrors.ROOF_NUMBER !== ''}
                                                    onChange={(event) => handleRoofNumberChange(event.target.value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => setIsSelectRoofSectionsDialogOpen(true)} disabled={previewVisible}>
                                            <OpenInFull sx={{ fontSize: 36 }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            onClick={() => {
                                                window.open(
                                                    `/clients/${currentRoofSection?.facility?.clientId}/facility/${currentRoofSection?.facilityId}/roofSection/${currentRoofSection?.id}`,
                                                    '_blank'
                                                );
                                            }}
                                            disabled={!currentRoofSection}>
                                            <Launch sx={{ fontSize: 36 }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <FormHelperText>{fieldErrors.ROOF_NUMBER}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormNumberInput
                                value={squareFeet ?? ''}
                                onChange={handleSquareFeetChange}
                                onBlur={onFieldBlur('SQUARE_FOOTAGE')}
                                label='Sq Feet'
                                labelFontSize='12px'
                                name='sqFeet'
                                fullWidth
                                required
                                disabled={previewVisible || isExistingRoofSectionSelected}
                                inputProps={{ inputMode: 'numeric', pattern: patterns.Integer.input.source, style: { textAlign: 'right' } }}
                                error={fieldErrors.SQUARE_FOOTAGE !== ''}
                                errorText={fieldErrors.SQUARE_FOOTAGE}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormNumberInput
                                value={assetValueMultiplier ?? ''}
                                onChange={handleAssetValueMultiplierChange}
                                label='Multiplier'
                                labelFontSize='12px'
                                name='multiplier'
                                fullWidth
                                disabled={previewVisible || isExistingRoofSectionSelected}
                                inputProps={{ inputMode: 'numeric', pattern: patterns.Decimal.input.source, style: { textAlign: 'right' } }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormNumberInput
                                value={assetValue ?? ''}
                                onChange={handleAssetValueChange}
                                label='Asset Value'
                                labelFontSize='12px'
                                name='assetValue'
                                fullWidth
                                required
                                disabled={previewVisible || isExistingRoofSectionSelected}
                                inputProps={{ inputMode: 'numeric', pattern: patterns.Decimal.input.source, style: { textAlign: 'right' } }}
                                error={fieldErrors.ASSET_VALUE !== ''}
                                errorText={fieldErrors.ASSET_VALUE}
                            />
                        </Grid>
                        <Grid item width={100}>
                            <FormControl fullWidth>
                                <FormLabel sx={{ fontSize: '12px' }}>Warranty</FormLabel>
                                <Select value={warranty} onChange={handleWarrantyChange} disabled={previewVisible || isExistingRoofSectionSelected}>
                                    <MenuItem key='none' value=''>
                                        <em>None</em>
                                    </MenuItem>
                                    {[
                                        <MenuItem key={0} value={'No'}>
                                            {'No'}
                                        </MenuItem>,
                                        <MenuItem key={1} value={'Yes'}>
                                            {'Yes'}
                                        </MenuItem>,
                                        <MenuItem key={2} value={'Yes (Documented)'} disabled>
                                            {'Yes (Documented)'}
                                        </MenuItem>,
                                    ]}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item width={250}>
                            <FormControl fullWidth>
                                <FormLabel sx={{ fontSize: '12px' }}>Roof System</FormLabel>
                                <IEntityAutocomplete
                                    options={roofSystems}
                                    onChange={(e, value) => {
                                        setFormChanged(true);
                                        setRoofSystem(value);
                                    }}
                                    value={roofSystem}
                                    getOptionLabel={(option: RoofSystemDto) => option.name}
                                    isLoading={roofSystemsLoading}
                                    disabled={previewVisible || isExistingRoofSectionSelected}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth error={fieldErrors.CURRENT_CONDITION !== ''} required>
                                <FormLabel sx={{ fontSize: '12px' }}>Current Condition</FormLabel>
                                <IEntitySelect
                                    getItem={(item: RoofConditionDto) => <ColorSwatch condition={item} />}
                                    value={currentCondition ?? ''}
                                    onChange={handleCurrentConditionChange}
                                    menuItems={roofConditions}
                                    disabled={previewVisible || isExistingRoofSectionSelected}
                                />
                                <FormHelperText>{fieldErrors.CURRENT_CONDITION}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item direction='row' container spacing={3} justifyContent='space-between'>
                        <Grid item>
                            <Typography fontWeight={'bold'} fontSize={'18px'}>
                                Current and Future Assessment
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item direction='column' container>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    onChange={(e, value) => {
                                        if (value === '1' && !formIsValid()) {
                                            return;
                                        } else {
                                            setTab(value);
                                        }
                                    }}>
                                    <Tab label='DATA' value='0' />
                                    <Tab label='PREVIEW' value='1' />
                                </TabList>
                            </Box>
                            <TabPanel value={'0'}>
                                <Grid item direction='column'>
                                    {years.map((year, idx) => budgetLineItemYearRow(idx, year))}
                                </Grid>
                            </TabPanel>
                            <TabPanel value={'1'}>
                                <BudgetLineItemPreview
                                    isVisible={tab === '1'}
                                    budget={budget}
                                    roofSectionId={currentRoofSection?.id}
                                    squareFeet={squareFeet ?? undefined}
                                    lineItemYears={years}
                                />
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
                <CreateRoofSectionDialog
                    open={isAddRoofSectionDialogOpen}
                    onClose={handleCreateRoofConfirmationClose}
                    roofNumber={roofNumber}
                    setRoofNumber={setRoofNumber}
                />
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={keepOpen} onChange={onKeepOpenChange} />} label='Add new budget line item after save' />
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                                {changed ? 'Cancel' : 'Close'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
            <SelectRoofSectionsDialog
                open={isSelectRoofSectionsDialogOpen}
                onCancel={handleSelectRoofSectionsDialogClose}
                onConfirm={handleSelectRoofSectionsDialogConfirm}
                setRoofSections={setRoofSections}
                currentRoofSections={currentRoofSection ? [currentRoofSection] : []}
                isMultipleSelectable={false}
                facilityId={facilityId}
                roofProfileId={roofProfileId}
            />
        </Dialog>
    );
};
