import { AddCircle, Delete, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../../../auth';
import { FacilityDto } from '../../../models';
import { useArchiveFacilityMutation, useGetClientFacilitiesQuery, useUnarchiveFacilityMutation } from '../../../store/apis/facility-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { IClientFacilitiesProps } from './types';

export const ClientFacilities: FunctionComponent<IClientFacilitiesProps> = (props) => {
    const { clientId } = props;
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data, error, isLoading, refetch } = useGetClientFacilitiesQuery({
        parentId: clientId,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
        },
    });
    const [archiveFacility, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveFacilityMutation();
    const [unarchiveFacility, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveFacilityMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<FacilityDto>[] = useMemo(() => [
        { key: 'name', label: 'Facility', sortKey: 'NAME' },
        { key: 'city', label: 'City', sortKey: 'CITY' },
        { key: 'state', label: 'State', sortKey: 'STATE' },
        { key: 'phone', label: 'Phone', sortKey: 'PHONE' },
        { key: 'email', label: 'Email', sortKey: 'EMAIL', fieldMapper: (row) => (row.email ? <Link href={`mailto: ${row.email}`}>{row.email}</Link> : '') },
        { key: 'yearEnd', label: 'Year End', sortKey: 'YEAR_END', fieldMapper: (row) => (row.yearEnd ? row.yearEnd.toString() : '') },
    ], []);

    const entityName = useMemo(() => {
        return selected.length === 1 ? 'Facility' : 'Facilities';
    }, [selected.length]);

    const handleActionRequestCompletion = useCallback((resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    }, []);

    useFailedActionSnackbar('inactivate', entityName, isArchiveError, () => { handleActionRequestCompletion(resetArchive); });
    useFailedActionSnackbar('activate', entityName, isUnarchiveError, () => { handleActionRequestCompletion(resetUnarchive); });
    useSuccessfulActionSnackbar('Inactivated', entityName, isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', entityName, isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback((afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    }, []);

    const handleCreateNew = useCallback(() => {
        navigate(`/clients/${clientId}/facilities/create`);
    }, [clientId, navigate]);

    const handleMenuEdit = useCallback(() => {
        const facility = data?.pageResults[selected[0]];
        if (facility) {
            navigate(`/clients/${clientId}/facilities/edit/${facility.id}`);
        }
    }, [clientId, data?.pageResults, navigate, selected]);

    const handleRowEdit = useCallback((id: string) => {
        const facility = data?.pageResults.find((x) => x.id === id);
        if (facility) {
            navigate(`/clients/${clientId}/facilities/edit/${facility.id}`);
        }
    }, [clientId, data?.pageResults, navigate]);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveFacility(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [data?.pageResults, selected, unarchiveFacility]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveFacility(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [archiveFacility, data?.pageResults, selected]);

    const canSetActive = useMemo(() => {
        return selected.some((index) => !data?.pageResults[index]?.isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useMemo(() => {
        return selected.some((index) => data?.pageResults[index]?.isActive);
    }, [data?.pageResults, selected]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }
    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                <Grid item container direction='row' alignItems='center' xs={6}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:facilities']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:facilities']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:facilities', 'edit:facilities', 'delete:facilities']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:facilities']}
                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:facilities']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:facilities']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        } />
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='NAME'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:facilities'] }}
                    onEdit={handleRowEdit}
                />
            </Grid>
        </Grid>
    );
};
