import { serverApi } from '..';
import { QueryResultsDto } from '../../models';
import { ReportDto } from '../../models/ReportDto';
import { ReportPdfDto } from '../../models/ReportPdfDto';
import { buildSearchQueryRoute } from '../../util';
import { ReportSearchQueryParams } from '../types';

const ReportApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getReports: builder.query<QueryResultsDto<ReportDto>, ReportSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('report', params),
            providesTags: [{ type: 'Report' }]
        }),
        getReport: builder.query<ReportDto, string>({
            query: (reportId) => ({ url: `report/${reportId}` }),
            providesTags: [{ type: 'Report' }]
        }),
        getReportPdf: builder.query<ReportPdfDto, string>({
            query: (reportId) => ({ url: `report/${reportId}/reportPdf` }),
            providesTags: [{ type: 'Report' }]
        }),
        createReport: builder.mutation<ReportDto, ReportDto>({
            query: (reportDto) => ({
                url: 'report',
                method: 'POST',
                body: reportDto
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        createReportCopy: builder.mutation<ReportDto, string>({
            query: (reportId) => ({
                url: `report/copy/${reportId}`,
                method: 'POST'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        createPdf: builder.mutation<ReportPdfDto, string>({
            query: (reportId) => ({
                url: `report/${reportId}/pdf`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        unlockReport: builder.mutation<ReportPdfDto, string>({
            query: (reportId) => ({
                url: `report/${reportId}/unlock`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        updateReport: builder.mutation<void, ReportDto>({
            query: (reportDto) => ({
                url: 'report',
                method: 'PUT',
                body: reportDto
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        archiveReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `report/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        unarchiveReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `report/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        })
    })
});

export const {
    useLazyGetReportQuery,
    useLazyGetReportPdfQuery,
    useGetReportQuery,
    useCreatePdfMutation,
    useCreateReportMutation,
    useUnlockReportMutation,
    useUpdateReportMutation,
    useArchiveReportMutation,
    useUnarchiveReportMutation,
    useGetReportsQuery,
    useCreateReportCopyMutation,
} = ReportApi;