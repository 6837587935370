import { Typography, Grid } from '@mui/material';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { ImportLineItemsStepThreeProps } from '.';
import { ImportLineItemDto } from '../../../../models/ImportLineItemDto';
import { formatCurrency, sortByRoofNumber } from '../../../../util';
import { DataTableColumn } from '../../../core/DataTable';
import { LocalDataTable } from '../../../core/LocalDataTable';

export const ImportLineItemsStepThree: FunctionComponent<ImportLineItemsStepThreeProps> = (props) => {
    const { importResults, isLoading } = props;
    const mapStatus = useCallback((row: any) => {
        return row.status !== 'Good' ? <Typography sx={{ color: '#DF623B' }}>{row.status}</Typography> : null;
    }, []);

    const tableColumns: DataTableColumn<ImportLineItemDto>[] = useMemo(() => [
        { key: 'roofNumber', label: 'Roof #', sortKey: 'ROOF_NUMBER' },
        { key: 'sqFeet', label: 'Sq Ft', align: 'right', sortKey: 'SQUARE_FOOTAGE' },
        {
            key: 'assetValue',
            label: 'Asset Value',
            sortKey: 'ASSET_VALUE',
            align: 'right',
            fieldMapper: (row) => (row.assetValue ? formatCurrency(row.assetValue) : ''),
        },
        { key: 'warrantyYN', label: 'Warranty', sortKey: 'WARRANTY' },
        { key: 'roofSystem', label: 'Roof System', sortKey: 'ROOF_SYSTEM' },
        { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: mapStatus },
    ], [mapStatus]);

    const sortedResults = useMemo(() => sortByRoofNumber<any>(importResults?.parsedFile?.filter((x) => x.status !== 'Good') ?? [], (x: any) => x.roofNumber), [importResults?.parsedFile]);
    const showLocalDataTable = useMemo(() => {
        return importResults && importResults.parsedFile?.some((x) => x.status !== 'Good' && x.status !== 'Roof # already exists.');
    }, [importResults]);

    return (
        <Grid container direction='column' spacing={1}>
            {!isLoading && importResults !== undefined && (
                <>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>Summary:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {importResults.recordsAdded!} {importResults.recordsAdded! === 1 ? 'record' : 'records'} added.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {importResults.duplicateRecords!} {importResults.duplicateRecords! === 1 ? 'record' : 'records'} updated.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {importResults.recordsSkipped!} {importResults.recordsSkipped! === 1 ? 'record' : 'records'} skipped.
                        </Typography>
                    </Grid>
                    <Grid item marginTop={2}>
                        {showLocalDataTable && (
                            <LocalDataTable height={375} columns={tableColumns} loading={isLoading} rowData={sortedResults} />
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};
