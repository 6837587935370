import { serverApi } from '..';
import { WarrantyTypeDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const warrantyTypeApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWarrantyTypes: builder.query<WarrantyTypeDto[], WarrantyTypeQueryParams>({
            query: (params) => buildSearchQueryRoute(`warrantytype`, params),
            providesTags: [{ type: 'Warranty Type' }]
        }),
        createWarrantyType: builder.mutation<WarrantyTypeDto, WarrantyTypeDto>({
            query: (warrantyDto) => ({
                url: 'warrantytype',
                method: 'POST',
                body: warrantyDto
            }),
            invalidatesTags: [{ type: 'Warranty Type' }]
        }),
    })
});

export const {
    useGetWarrantyTypesQuery,
    useCreateWarrantyTypeMutation
} = warrantyTypeApi;

export interface WarrantyTypeQueryParams {
    includeInactive?: boolean;
}