import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkOrderAndCapitalProjectForm } from '../../Components/Forms/WorkOrderForm';
import { WorkOrderDto } from '../../models';
import { useCreateWorkOrderMutation } from '../../store/apis/work-order-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddCapitalProjectView: FunctionComponent = () => {
    const { clientId, facilityId } = useParams();
    const navigate = useNavigate();
    const [saveError, setSaveError] = useState('');
    const [createWorkOrder, { data, error: createError, isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate }] = useCreateWorkOrderMutation();

    useFailedCreateSnackbar('capital project', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Capital project', isCreateSuccess, () => {
        navigate(`/capitalprojects/edit/${data?.id}`);
    });

    useEffect(() => {
        if (createError && 'data' in createError) {
            if (createError.data === 'Capital Project number already exists') {
                setSaveError(createError.data);
            }
        }
    }, [createError]);

    const handleSave = (values: WorkOrderDto) => {
        createWorkOrder(values);
    };

    const handleCancel = () => {
        navigate('/capitalprojects');
    };

    return (
        <WorkOrderAndCapitalProjectForm
            save={handleSave}
            cancel={handleCancel}
            woNumberError={saveError}
            isCapitalProject={true}
            clientId={clientId ?? undefined}
            facilityId={facilityId ?? undefined}
        />
    );
};
