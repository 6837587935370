import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { OrderType, emptyGuid } from '../../../models';
import { useCreateWorkOrderTypeMutation } from '../../../store/apis/work-order-type-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../util/customHooks';
import { IWorkOrderTypeDialogProps } from './types';
import { FormInput } from '../FormFields';

export const WorkOrderTypeDialog: FC<IWorkOrderTypeDialogProps> = React.memo((props) => {
    const { setWorkOrderType, open, onClose: afterClose, isCapitalProject } = props;
    const [newType, setNewType] = useState('');
    const [orderType, setOrderType] = useState<OrderType | undefined>(isCapitalProject ? OrderType.CapitalProject : OrderType.WorkOrder);
    const [typeError, setTypeError] = useState('');
    const [orderTypeError, setOrderTypeError] = useState('');
    const [createWorkOrderType, { data, isLoading, error: createError, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateWorkOrderTypeMutation();

    useFailedCreateSnackbar('type', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Type', isCreateSuccess, () => {
        if (data) {
            setWorkOrderType(data);
            setNewType('');
            setOrderType(undefined);
            setTypeError('');
            resetCreate();
            afterClose();
        }
    });

    useEffect(() => {
        if (createError && 'data' in createError) {
            if (createError.data === 'Work Order Type already exists') {
                setTypeError('Type already exists');
            }
        }
    }, [createError]);

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypeError('');
        setNewType(event.target.value);
    };

    const handleOrderTypeChange = useCallback((event: SelectChangeEvent) => {
        setOrderTypeError('');
        if (event.target.value !== undefined) {
            setOrderType(event.target.value === 'Work Order' ? OrderType.WorkOrder : OrderType.CapitalProject);
        } else {
            setOrderType(undefined);
        }
    }, []);

    const onBlur = useCallback(() => {
        if (newType === '') {
            setTypeError('Type is required');
        }
        if (orderType === undefined) {
            setOrderTypeError('Order Type is required');
        }
    }, [newType, orderType]);

    const onSave = useCallback(() => {
        if (newType !== '' && orderType !== undefined && typeError === '' && orderTypeError === '') {
            createWorkOrderType({
                id: emptyGuid,
                isActive: true,
                name: newType,
                type: orderType
            });
        } else if (newType === '') {
            setTypeError('Type is required');
        } else if (orderType === undefined) {
            setOrderTypeError('Order Type is required');
        }
    }, [createWorkOrderType, newType, orderType, orderTypeError, typeError]);

    const onClose = useCallback(() => {
        setNewType('');
        setTypeError('');
        setOrderType(undefined);
        setOrderTypeError('');
        resetCreate();
        afterClose();
    }, [afterClose, resetCreate]);

    return (
        <Dialog onClose={onClose} open={open} maxWidth='xs' fullWidth>
            <DialogTitle>New Type</DialogTitle>
            <DialogContent>
                <FormInput
                    label='Type'
                    onBlur={onBlur}
                    value={newType}
                    disabled={isLoading}
                    fullWidth
                    required
                    error={typeError !== ''}
                    errorText={typeError}
                    onChange={handleTypeChange}
                />
                <FormControl error={orderTypeError !== ''} fullWidth required>
                    <FormLabel>Report Type</FormLabel>
                    <Select value={orderType === OrderType.WorkOrder ? 'WorkOrder' : 'CapitalProject'} onChange={handleOrderTypeChange} onBlur={onBlur}>
                        {!isCapitalProject && <MenuItem key='workOrder' value={'WorkOrder'}>
                            Work Order
                        </MenuItem>}
                        {isCapitalProject && <MenuItem key='capitalProject' value={'CapitalProject'}>
                            Capital Project
                        </MenuItem>}
                    </Select>
                    <FormHelperText>{orderTypeError}</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
});
