import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { NotAuthorizedError } from '../../Components/core/NotAuthorizedError/NotAuthorizedError';
import { AcceptDispatchForm } from '../../Components/Forms/WorkOrderDispatchForm';
import { DispatchAcceptQueryParams, DispatchRejectionQueryParams } from '../../store/types';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../util/customHooks';
import { useAcceptDispatchMutation, useGetDispatchQuery, useRejectDispatchMutation } from '../../store/apis/dispatch-api';

export const AcceptDispatchView: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: dispatch, isLoading: dispatchLoading, isError: dispatchError, refetch: dispatchRefetch, error: getError } = useGetDispatchQuery(id!);
    const [acceptDispatch, { isSuccess: isAcceptSuccess, isError: isAcceptError, isLoading: acceptLoading, reset: acceptReset }] = useAcceptDispatchMutation();
    const [rejectDispatch, { isSuccess: isRejectSuccess, isError: isRejectError, reset: rejectReset, isLoading: rejectLoading }] = useRejectDispatchMutation();

    useFailedActionSnackbar('accept', 'dispatch', isAcceptError, acceptReset);
    useFailedActionSnackbar('reject', 'dispatch', isRejectError, rejectReset);
    useSuccessfulActionSnackbar('accepted', 'Dispatch', isAcceptSuccess, () => {
        acceptReset();
        navigate(`/contractorDashboard/${dispatch!.contractorId!}`);
    });
    useSuccessfulActionSnackbar('rejected', 'Dispatch', isRejectSuccess, () => {
        rejectReset();
        navigate(`/contractorDashboard/${dispatch!.contractorId!}`);
    });

    const handleAccept = (params: DispatchAcceptQueryParams) => {
        acceptDispatch(params);
    };

    const handleReject = (params: DispatchRejectionQueryParams) => {
        rejectDispatch(params);
    };

    if (dispatchLoading || acceptLoading || rejectLoading) {
        return <LoadingIndicator />;
    }

    if (dispatchError) {
        if (getError && (getError as any).originalStatus === 404) {
            return <NotAuthorizedError />;
        }
        return <ApiError onReloadClick={dispatchRefetch} />;
    }

    return <AcceptDispatchForm accept={handleAccept} reject={handleReject} initValues={dispatch} />;
};
