import { Grid, Radio, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { FunctionComponent } from "react";
import { JsiReportChecklistItemDto } from "../../../models/JsiReportChecklistItemDto";
import { IQualityAssuranceChecklistProps } from "../types";
import { ChecklistItemAnswers, ChecklistItemCategories, getCategoryChecklistItems } from "./types";

export const QualityAssuranceChecklist: FunctionComponent<IQualityAssuranceChecklistProps> = React.memo(props => {
    const { checklistItems, handleRadioButtonClicked, disabled } = props;

    const handleRadioButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>, idx: number, category: string) => {
        handleRadioButtonClicked(event, idx, category);
    }, [handleRadioButtonClicked]);

    const createCategorySection = useCallback((categoryChecklistItems: JsiReportChecklistItemDto[]) => {
        if (categoryChecklistItems.length <= 0) {
            return <></>;
        }
        return (
            <>
                <TableRow sx={{ backgroundColor: '#266490', padding: '10px' }}>
                    <TableCell>
                        <Typography sx={{ fontWeight: 'bold', color: 'white' }}>{categoryChecklistItems[0].category}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                        <Typography sx={{ color: 'white' }}>{ChecklistItemAnswers.APPROVED}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                        <Typography sx={{ color: 'white' }}>{ChecklistItemAnswers.DENIED}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                        <Typography sx={{ color: 'white' }}>{ChecklistItemAnswers.WIP}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                        <Typography sx={{ color: 'white' }}>{ChecklistItemAnswers.NA}</Typography>
                    </TableCell>
                </TableRow>
                {categoryChecklistItems.map((item, idx) => {
                    return (
                        <TableRow key={`${categoryChecklistItems[0].category}-${idx}`}>
                            <TableCell>
                                <Typography>{item.question}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Radio disabled={disabled} key={`${item.id}-${ChecklistItemAnswers.APPROVED}`} checked={isSelected(item, ChecklistItemAnswers.APPROVED)} value={ChecklistItemAnswers.APPROVED} onClick={(e) => handleRadioButtonClick(e, idx, item.category)} />
                            </TableCell>
                            <TableCell align='center'>
                                <Radio disabled={disabled} key={`${item.id}-${ChecklistItemAnswers.DENIED}`} checked={isSelected(item, ChecklistItemAnswers.DENIED)} value={ChecklistItemAnswers.DENIED} onClick={(e) => handleRadioButtonClick(e, idx, item.category)} />
                            </TableCell>
                            <TableCell align='center'>
                                <Radio disabled={disabled} key={`${item.id}-${ChecklistItemAnswers.WIP}`} checked={isSelected(item, ChecklistItemAnswers.WIP)} value={ChecklistItemAnswers.WIP} onClick={(e) => handleRadioButtonClick(e, idx, item.category)} />
                            </TableCell>
                            <TableCell align='center'>
                                <Radio disabled={disabled} key={`${item.id}-${ChecklistItemAnswers.NA}`} checked={isSelected(item, ChecklistItemAnswers.NA)} value={ChecklistItemAnswers.NA} onClick={(e) => handleRadioButtonClick(e, idx, item.category)} />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </>
        );
    }, [disabled, handleRadioButtonClick]);

    const isSelected = (item: JsiReportChecklistItemDto, value: string) => {
        return item.answer === value;
    };

    return (
        <>
            <Grid container direction='row' spacing={4}>
                <Grid item xl={6} lg={12}>
                    <Table>
                        <TableBody>
                            {createCategorySection(getCategoryChecklistItems(checklistItems, ChecklistItemCategories.GENERAL))}
                            {createCategorySection(getCategoryChecklistItems(checklistItems, ChecklistItemCategories.ROOF_DECK))}
                            {createCategorySection(getCategoryChecklistItems(checklistItems, ChecklistItemCategories.INSULATION))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xl={6} lg={12}>
                    <Table>
                        <TableBody>
                            {createCategorySection(getCategoryChecklistItems(checklistItems, ChecklistItemCategories.MEMBRANE))}
                            {createCategorySection(getCategoryChecklistItems(checklistItems, ChecklistItemCategories.ACCESSORIES))}
                            {createCategorySection(getCategoryChecklistItems(checklistItems, ChecklistItemCategories.MISCELLANEOUS))}
                            {createCategorySection(getCategoryChecklistItems(checklistItems, ChecklistItemCategories.RESTORATION))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </>
    );
});