import { AttachMoney } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC, useMemo } from 'react';
import { DashboardCard } from '../DashboardCard';
import { ExecutiveSummaryBudgetDto } from '../../../models';
import { ExecutiveSummaryBudgetCollapsibleRow } from './types';
import { formatCurrency } from '../../../util';

export interface IExecutiveSummaryBudgetCard {
    budgetSummary: ExecutiveSummaryBudgetDto;
}

export const ExecutiveSummaryBudgetCard: FC<IExecutiveSummaryBudgetCard> = ({ budgetSummary }) => {
    const maintenanceBudget = budgetSummary?.budgetsSummary?.maintenanceBudgetForecastTotalsByYear;
    const capitalProjectBudget = budgetSummary?.capitalProjectsSummary?.capitalBudgetForecastTotalsByYear;

    const widthPercentageBasedOnVisibleColumns = useMemo(() => {
        const remainingPercentage = 42;
        const visibleYearColumns = [budgetSummary.includeYear1Column, budgetSummary.includeYear2Column, budgetSummary.includeYear3Column, budgetSummary.includeYear4Column, budgetSummary.includeYear5Column].filter((x) => x).length;
        return remainingPercentage / (visibleYearColumns + 1);
    }, [budgetSummary.includeYear1Column, budgetSummary.includeYear2Column, budgetSummary.includeYear3Column, budgetSummary.includeYear4Column, budgetSummary.includeYear5Column]);

    const visibleColumnCount = useMemo(() => {
        return 4 + (budgetSummary.includeYear1Column ? 1 : 0) + (budgetSummary.includeYear2Column ? 1 : 0) + (budgetSummary.includeYear3Column ? 1 : 0) + (budgetSummary.includeYear4Column ? 1 : 0) + (budgetSummary.includeYear5Column ? 1 : 0);
    }, [budgetSummary.includeYear1Column, budgetSummary.includeYear2Column, budgetSummary.includeYear3Column, budgetSummary.includeYear4Column, budgetSummary.includeYear5Column]);

    return (
        <DashboardCard headerIcon={<AttachMoney />} headerTitle='Executive Summary Budget'>
            <TableContainer>
                <Table>
                    <colgroup>
                        <col style={{ width: '38%', minWidth: '75px' }} />
                        <col style={{ width: '10%', minWidth: '75px' }} />
                        <col style={{ width: '10%', minWidth: '75px' }} />
                        {budgetSummary.includeYear1Column && <col style={{ width: `${widthPercentageBasedOnVisibleColumns}%`, minWidth: '75px' }} />}
                        {budgetSummary.includeYear2Column && <col style={{ width: `${widthPercentageBasedOnVisibleColumns}%`, minWidth: '75px' }} />}
                        {budgetSummary.includeYear3Column && <col style={{ width: `${widthPercentageBasedOnVisibleColumns}%`, minWidth: '75px' }} />}
                        {budgetSummary.includeYear4Column && <col style={{ width: `${widthPercentageBasedOnVisibleColumns}%`, minWidth: '75px' }} />}
                        {budgetSummary.includeYear5Column && <col style={{ width: `${widthPercentageBasedOnVisibleColumns}%`, minWidth: '75px' }} />}
                        <col style={{ width: `${widthPercentageBasedOnVisibleColumns}%`, minWidth: '75px' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '18px' }}>Roof Condition/Activity</TableCell>
                            <TableCell align='right'>Square Feet</TableCell>
                            <TableCell align='right'>Immediate Repairs</TableCell>
                            {budgetSummary.includeYear1Column && <TableCell align='right'>{budgetSummary!.startYear}</TableCell>}
                            {budgetSummary.includeYear2Column && <TableCell align='right'>{budgetSummary!.startYear + 1}</TableCell>}
                            {budgetSummary.includeYear3Column && <TableCell align='right'>{budgetSummary!.startYear + 2}</TableCell>}
                            {budgetSummary.includeYear4Column && <TableCell align='right'>{budgetSummary!.startYear + 3}</TableCell>}
                            {budgetSummary.includeYear5Column && <TableCell align='right'>{budgetSummary!.startYear + 4}</TableCell>}
                            <TableCell align='right'>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ExecutiveSummaryBudgetCollapsibleRow
                            budgetSummary={budgetSummary}
                            backgroundColor='#21923E'
                            headerTextColor='#ffffff'
                            headerText='Preventative Maintenance'
                            budgetItems={budgetSummary!.budgetsSummary?.preventativeMaintenanceSummary?.facilityRoofSectionsSummaries ?? []}
                            summaryTotals={budgetSummary!.budgetsSummary?.preventativeMaintenanceSummary?.summaryTotals ?? []}
                            additionalRows={
                                <>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>All Facilities, All Roof Sections</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                                            {budgetSummary?.budgetsSummary?.allFacilitiesAllRoofSectionsTotal?.toLocaleString()}
                                        </TableCell>
                                        <TableCell align='right'></TableCell>
                                        {budgetSummary.includeYear1Column && <TableCell align='right'></TableCell>}
                                        {budgetSummary.includeYear2Column && <TableCell align='right'></TableCell>}
                                        {budgetSummary.includeYear3Column && <TableCell align='right'></TableCell>}
                                        {budgetSummary.includeYear4Column && <TableCell align='right'></TableCell>}
                                        {budgetSummary.includeYear5Column && <TableCell align='right'></TableCell>}
                                        <TableCell align='right'></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Future Eligible Roofing Assets (Eligible When Green)</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                                            {budgetSummary?.budgetsSummary?.futureEligibleRoofingAssetsTotal?.toLocaleString()}
                                        </TableCell>
                                        <TableCell align='right'></TableCell>
                                        {budgetSummary.includeYear1Column && <TableCell align='right'></TableCell>}
                                        {budgetSummary.includeYear2Column && <TableCell align='right'></TableCell>}
                                        {budgetSummary.includeYear3Column && <TableCell align='right'></TableCell>}
                                        {budgetSummary.includeYear4Column && <TableCell align='right'></TableCell>}
                                        {budgetSummary.includeYear5Column && <TableCell align='right'></TableCell>}
                                        <TableCell align='right'></TableCell>
                                    </TableRow>
                                </>
                            }
                        />
                        <ExecutiveSummaryBudgetCollapsibleRow
                            budgetSummary={budgetSummary}
                            backgroundColor='#0378C8'
                            headerTextColor='#ffffff'
                            headerText='Minor Repairs'
                            budgetItems={budgetSummary!.budgetsSummary.minorRepairsSummary.facilityRoofSectionsSummaries}
                            summaryTotals={budgetSummary!.budgetsSummary.minorRepairsSummary.summaryTotals}
                        />
                        <ExecutiveSummaryBudgetCollapsibleRow
                            budgetSummary={budgetSummary}
                            backgroundColor='#FFF836'
                            headerTextColor='#000000'
                            headerText='Aggressive Repairs'
                            budgetItems={budgetSummary!.budgetsSummary.aggressiveRepairsSummary.facilityRoofSectionsSummaries}
                            summaryTotals={budgetSummary!.budgetsSummary.aggressiveRepairsSummary.summaryTotals}
                        />
                        <ExecutiveSummaryBudgetCollapsibleRow
                            budgetSummary={budgetSummary}
                            backgroundColor='#575E56'
                            headerTextColor='#ffffff'
                            headerText='Warranty/Needs Repairs'
                            budgetItems={budgetSummary!.budgetsSummary.warrantyNeedsRepairsSummary.facilityRoofSectionsSummaries}
                            summaryTotals={budgetSummary!.budgetsSummary.warrantyNeedsRepairsSummary.summaryTotals}
                        />
                        <TableRow sx={{ backgroundColor: '#000E9A' }}>
                            <TableCell colSpan={3} sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                Maintenance Budget Forecast Totals by Year:
                            </TableCell>
                            {budgetSummary.includeYear1Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {maintenanceBudget?.year1Budget !== null ? formatCurrency(maintenanceBudget?.year1Budget) : ''}
                            </TableCell>}
                            {budgetSummary.includeYear2Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {maintenanceBudget?.year2Budget !== null ? formatCurrency(maintenanceBudget?.year2Budget) : ''}
                            </TableCell>}
                            {budgetSummary.includeYear3Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {maintenanceBudget?.year3Budget !== null ? formatCurrency(maintenanceBudget?.year3Budget) : ''}
                            </TableCell>}
                            {budgetSummary.includeYear4Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {maintenanceBudget?.year4Budget !== null ? formatCurrency(maintenanceBudget?.year4Budget) : ''}
                            </TableCell>}
                            {budgetSummary.includeYear5Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {maintenanceBudget?.year5Budget !== null ? formatCurrency(maintenanceBudget?.year5Budget) : ''}
                            </TableCell>}
                            <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {maintenanceBudget?.budgetTotal !== null ? formatCurrency(maintenanceBudget?.budgetTotal) : ''}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={visibleColumnCount} align='center' sx={{ color: '#000000', textTransform: 'uppercase', fontSize: '16px', fontWeight: 'bold' }}>
                                Capital Budget
                            </TableCell>
                        </TableRow>
                        <ExecutiveSummaryBudgetCollapsibleRow
                            budgetSummary={budgetSummary}
                            backgroundColor='#553974'
                            headerTextColor='#ffffff'
                            headerText='Restore'
                            budgetItems={budgetSummary!.capitalProjectsSummary.restoreSummary.facilityRoofSectionsSummaries}
                            summaryTotals={budgetSummary!.capitalProjectsSummary.restoreSummary.summaryTotals}
                        />
                        <ExecutiveSummaryBudgetCollapsibleRow
                            budgetSummary={budgetSummary}
                            backgroundColor='#F2642C'
                            headerTextColor='#ffffff'
                            headerText='Recover/Replace Delay Possible'
                            budgetItems={budgetSummary!.capitalProjectsSummary.recoverReplaceSummary.facilityRoofSectionsSummaries}
                            summaryTotals={budgetSummary!.capitalProjectsSummary.recoverReplaceSummary.summaryTotals}
                        />
                        <ExecutiveSummaryBudgetCollapsibleRow
                            budgetSummary={budgetSummary}
                            backgroundColor='#EC0824'
                            headerTextColor='#ffffff'
                            headerText='Replace Repairs'
                            budgetItems={budgetSummary!.capitalProjectsSummary.replaceSummary.facilityRoofSectionsSummaries}
                            summaryTotals={budgetSummary!.capitalProjectsSummary.replaceSummary.summaryTotals}
                        />
                        <TableRow sx={{ backgroundColor: '#000E9A' }}>
                            <TableCell colSpan={3} sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                Capital Budget Forecast Totals by Year:
                            </TableCell>
                            {budgetSummary.includeYear1Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {capitalProjectBudget?.year1Budget !== null ? formatCurrency(capitalProjectBudget?.year1Budget) : ''}
                            </TableCell>}
                            {budgetSummary.includeYear2Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {capitalProjectBudget?.year2Budget !== null ? formatCurrency(capitalProjectBudget?.year2Budget) : ''}
                            </TableCell>}
                            {budgetSummary.includeYear3Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {capitalProjectBudget?.year3Budget !== null ? formatCurrency(capitalProjectBudget?.year3Budget) : ''}
                            </TableCell>}
                            {budgetSummary.includeYear4Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {capitalProjectBudget?.year4Budget !== null ? formatCurrency(capitalProjectBudget?.year4Budget) : ''}
                            </TableCell>}
                            {budgetSummary.includeYear5Column && <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {capitalProjectBudget?.year5Budget !== null ? formatCurrency(capitalProjectBudget?.year5Budget) : ''}
                            </TableCell>}
                            <TableCell align='right' sx={{ color: '#ffffff', fontWeight: '500', fontSize: '15px' }}>
                                {capitalProjectBudget?.budgetTotal !== null ? formatCurrency(capitalProjectBudget?.budgetTotal) : ''}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </DashboardCard>
    );
};
