export const userHasPermissions = (authPermissions: string[], permissions: string[], logic?: 'and' | 'or') => {
    logic = logic ? logic : 'and';
    switch (logic) {
        case "and":
            return userHasAllPermissions(authPermissions, permissions);
        case "or":
            return userHasAnyPermissions(authPermissions, permissions);
        default:
            throw new Error(`Unknown logical operator [${logic}] for comparing permissions.`);
    }
};

export const userHasAllPermissions = (expectedPermissions: string[], userPermissions: string[]) => {
    if (userPermissions.length === 0) return false;
    if (expectedPermissions.length === 0) return true;

    for (const expectedPermission of expectedPermissions) {
        if (!userPermissions.includes(expectedPermission)) {
            return false;
        }
    }
    return true;
};

export const userHasAnyPermissions = (expectedPermissions: string[], userPermissions: string[]) => {
    if (userPermissions.length === 0) return false;
    if (expectedPermissions.length === 0) return true;

    for (const expectedPermission of expectedPermissions) {
        if (userPermissions.includes(expectedPermission)) {
            return true;
        }
    }

    return false;
};
