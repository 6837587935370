import { PinDrop } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { emptyGuid } from '../../../models';
import { useCreateLeakLocationMutation } from '../../../store/apis/leak-location-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../util/customHooks';
import { IWorkOrderLeakLocationDialogProps } from './types';
import { FormInput } from '../FormFields';

export const WorkOrderLeakLocationDialog: FunctionComponent<IWorkOrderLeakLocationDialogProps> = (props) => {
    const { setContractorInfo, setLeakLocation, facilityId, contractorInfo, open, onClose: afterClose, readOnly } = props;
    const [newLeakLocation, setNewLeakLocation] = useState('');
    const [leakLocationError, setLeakLocationError] = useState('');
    const [createLeakLocation, { data, isLoading, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateLeakLocationMutation();

    useFailedCreateSnackbar('leak location', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Leak location', isCreateSuccess, () => {
        if (data) {
            if (setContractorInfo) {
                setContractorInfo({ ...contractorInfo, leakLocationId: data.id });
            }
            if (setLeakLocation) {
                setLeakLocation(data);
            }
            setNewLeakLocation('');
            setLeakLocationError('');
            resetCreate();
            afterClose();
        }
    });

    const handleLeakLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLeakLocationError('');
        setNewLeakLocation(event.target.value);
    };

    const onBlur = () => {
        if (newLeakLocation === '') {
            setLeakLocationError('Leak Location is required');
        }
    };

    const onSave = () => {
        if (newLeakLocation !== '' && leakLocationError === '') {
            createLeakLocation({
                id: emptyGuid,
                facilityId: facilityId,
                isActive: true,
                name: newLeakLocation,
            });
        } else if (newLeakLocation === '') {
            setLeakLocationError('Leak Location is required');
        }
    };

    const onClose = () => {
        setNewLeakLocation('');
        setLeakLocationError('');
        resetCreate();
        afterClose();
    };

    return (
        <Dialog onClose={onClose} open={open} maxWidth='xs' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <PinDrop />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        New Leak Location
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' marginTop={2}>
                    <FormInput
                        label='Leak Location'
                        onBlur={onBlur}
                        value={newLeakLocation}
                        fullWidth
                        required
                        disabled={readOnly || isLoading}
                        error={leakLocationError !== ''}
                        errorText={leakLocationError}
                        onChange={handleLeakLocationChange}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onSave} disabled={readOnly}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
