import { AddCircle, LockOpen, MoreVert, Search, Undo } from '@mui/icons-material';
import { Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../../../auth';
import { ReportDto, WorkOrderDto } from '../../../models';
import { ApiError } from '../../core/ApiError';
import { ConfirmDialog } from '../../core/ConfirmDialog';
import { PaginatedDataTable } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { useWorkOrderActions } from './Hooks/useWorkOrderActions';
import { DispatchDialogView } from './WorkOrderDispatch';
import { useWorkOrderDispatchesTableManager } from './Hooks/useWorkOrderDispatchesTableManager';
import { WorkOrderDispatchSelectReportDialog } from './WorkOrderDispatchSelectReportDialog';
import { DispatchListViewDto } from '../../../models/DispatchListViewDto';
import { DispatchDto } from '../../../models/DispatchDto';

export function isLocked(dispatch?: DispatchListViewDto | DispatchDto) {
    return dispatch ? dispatch.lockedOn !== undefined && dispatch.lockedOn !== null : true;
}

export interface IWorkOrderDispatchSectionProps {
    workOrder: WorkOrderDto;
    handleDispatchAction: (actionName: string) => void;
    contractorId?: string;
}

export const WorkOrderDispatchSection: FC<IWorkOrderDispatchSectionProps> = React.memo(
    ({ workOrder, handleDispatchAction: handleCloseOutDispatchAction, contractorId }) => {
        const navigate = useNavigate();
        const [addDispatchDialogOpen, setAddDispatchDialogOpen] = useState(false);
        const [isSelectReportDialogOpen, setIsSelectReportDialogOpen] = useState(false);
        const [selectedReportToDispatch, setSelectedReportToDispatch] = useState<ReportDto>();
        const [currentDispatch, setCurrentDispatch] = useState<DispatchListViewDto>();
        const [dispatchTableMenuAnchorEl, setDispatchTableMenuAnchorEl] = useState<null | HTMLElement>(null);
        const [addDispatchMenuAnchorEl, setAddDispatchMenuAnchorEl] = useState<null | HTMLElement>(null);

        const { recallDispatch, createDispatch, unlockDispatch, canView, canRecall, canUnlock, canCreate } = useWorkOrderActions(
            workOrder,
            contractorId,
            currentDispatch
        );

        const {
            dispatches,
            selectedDispatches,
            handleSearchChange,
            isDispatchesLoading,
            dispatchesError,
            dispatchesRefetch,
            searchText,
            setPaginatedProps,
            selected,
            setCurrentSelected,
            TABLE_COLUMNS,
        } = useWorkOrderDispatchesTableManager(workOrder, setCurrentDispatch);

        const handleAddCloseOutDispatch = useCallback(() => {
            setCurrentDispatch(undefined);
            setSelectedReportToDispatch(undefined);
            setAddDispatchDialogOpen(true);
        }, []);

        const handleAddMGOrRoofSmartDispatch = useCallback(() => {
            setIsSelectReportDialogOpen(true);
        }, []);

        const handleReportToDispatchSelected = useCallback((report: ReportDto) => {
            setSelectedReportToDispatch(report);
            setCurrentDispatch(undefined);
            setIsSelectReportDialogOpen(false);
            setAddDispatchDialogOpen(true);
        }, []);

        const onCloseOutDispatchClose = useCallback(
            (actionName: string) => {
                setAddDispatchDialogOpen(false);
                handleCloseOutDispatchAction(actionName);
            },
            [handleCloseOutDispatchAction]
        );

        const handleBackOnDispatchModalClicked = useCallback(() => {
            onCloseOutDispatchClose('');
            setIsSelectReportDialogOpen(true);
        }, [onCloseOutDispatchClose]);

        const [confirmRecallOpen, setConfirmRecallOpen] = useState(false);
        const [confirmUnlockOpen, setConfirmUnlockOpen] = useState(false);

        const confirmRecall = useCallback(() => {
            setConfirmRecallOpen(true);
        }, []);

        const confirmUnlock = useCallback(() => {
            setConfirmUnlockOpen(true);
        }, []);

        const handleRecall = useCallback(
            (id: string) => {
                recallDispatch(id);
                setConfirmRecallOpen(false);
                setAddDispatchDialogOpen(false);
                handleCloseOutDispatchAction('Recalled');
            },
            [handleCloseOutDispatchAction, recallDispatch]
        );

        const handleUnlock = useCallback(
            (id: string) => {
                unlockDispatch(id).then(() => setCurrentDispatch((prev) => ({ ...prev!, lockedOn: undefined })));
                setConfirmUnlockOpen(false);
            },
            [unlockDispatch]
        );

        const openDispatchTableMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            setDispatchTableMenuAnchorEl(event.currentTarget);
        }, []);

        const closeDispatchTableMenu = useCallback(
            (afterClose?: () => void) => () => {
                setDispatchTableMenuAnchorEl(null);
                if (afterClose) {
                    afterClose();
                }
            },
            []
        );

        const openAddDispatchMenu = useCallback((event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            setAddDispatchMenuAnchorEl(event.currentTarget);
        }, []);

        const closeAddDispatchMenu = useCallback(
            (afterClose?: () => void) => () => {
                setAddDispatchMenuAnchorEl(null);
                if (afterClose) {
                    afterClose();
                }
            },
            []
        );

        const handleView = useCallback(
            (dispatch?: DispatchListViewDto) => {
                setCurrentDispatch(dispatch);
                if (dispatch?.status === 'Completed' || dispatch?.status === 'Needs Attention') {
                    navigate(`/submitDispatch/${dispatch.id}`);
                } else {
                    setAddDispatchDialogOpen(true);
                }
            },
            [navigate]
        );

        const handleRowView = useCallback(
            (id: string) => {
                if (!dispatches) {
                    return;
                }
                const dispatchToView = dispatches.pageResults.find((x) => x.id === id);
                handleView(dispatchToView);
            },
            [dispatches, handleView]
        );

        const handleMenuView = useCallback(() => {
            handleView(selectedDispatches[0]);
        }, [selectedDispatches, handleView]);

        if (isDispatchesLoading) {
            return <LoadingIndicator />;
        }

        if (dispatchesError) {
            return <ApiError onReloadClick={dispatchesRefetch} />;
        }

        return (
            <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
                <Grid item container direction='row' justifyContent={'space-between'} sx={{ padding: '0 24px' }}>
                    <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                    <Grid item container direction='row' alignItems='center' xs={6} justifyContent='end'>
                        <AuthenticatedComponent
                            requiredPermissions={['recall:dispatches']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title='Recall'>
                                        <span>
                                            <IconButton size='large' onClick={() => confirmRecall()} disabled={!canRecall}>
                                                <Undo fontSize='inherit' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['create:dispatches']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title={!canCreate ? 'Work order must be saved with a Dispatch ID before it can be dispatched.' : 'Add New'}>
                                        <span>
                                            <IconButton color='primary' size='large' onClick={openAddDispatchMenu} disabled={!canCreate}>
                                                <AddCircle fontSize='inherit' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={addDispatchMenuAnchorEl}
                                        open={!!addDispatchMenuAnchorEl}
                                        onClose={closeAddDispatchMenu()}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}>
                                        <MenuItem onClick={closeDispatchTableMenu(closeAddDispatchMenu(handleAddCloseOutDispatch))}>
                                            Close Out Dispatch
                                        </MenuItem>
                                        <MenuItem onClick={closeDispatchTableMenu(closeAddDispatchMenu(handleAddMGOrRoofSmartDispatch))}>
                                            Moisture Guard / RoofSMART Dispatch
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['create:dispatches', 'recall:dispatches', 'unlock:dispatches', 'read:dispatches']}
                            logic='or'
                            component={
                                <Grid item xs={1}>
                                    <IconButton size='large' onClick={openDispatchTableMenu}>
                                        <MoreVert fontSize='inherit' />
                                    </IconButton>
                                    <Menu
                                        anchorEl={dispatchTableMenuAnchorEl}
                                        open={!!dispatchTableMenuAnchorEl}
                                        onClose={closeDispatchTableMenu()}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}>
                                        <AuthenticatedComponent
                                            requiredPermissions={['create:dispatches']}
                                            component={
                                                <MenuItem onClick={openAddDispatchMenu} disabled={!canCreate}>
                                                    Add New
                                                </MenuItem>
                                            }
                                        />
                                        <AuthenticatedComponent
                                            requiredPermissions={['read:dispatches']}
                                            component={
                                                <MenuItem onClick={closeDispatchTableMenu(handleMenuView)} disabled={!canView}>
                                                    View
                                                </MenuItem>
                                            }
                                        />
                                        <Divider />
                                        <AuthenticatedComponent
                                            requiredPermissions={['unlock:dispatches']}
                                            component={
                                                <MenuItem onClick={() => confirmUnlock()} disabled={!canUnlock}>
                                                    Unlock
                                                </MenuItem>
                                            }
                                        />
                                        <AuthenticatedComponent
                                            requiredPermissions={['recall:dispatches']}
                                            component={
                                                <MenuItem onClick={() => confirmRecall()} disabled={!canRecall}>
                                                    Recall
                                                </MenuItem>
                                            }
                                        />
                                    </Menu>
                                </Grid>
                            }
                        />
                        <Grid item xs={5} alignSelf={'end'}>
                            <TextField
                                id='search-box'
                                value={searchText}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Search'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item width='100%'>
                    <PaginatedDataTable
                        columns={TABLE_COLUMNS}
                        loading={isDispatchesLoading}
                        queryResults={dispatches}
                        defaultSortKey='CREATED'
                        defaultSortDesc
                        setPagination={setPaginatedProps}
                        selected={selected}
                        setSelected={setCurrentSelected}
                        editPermissions={{ requiredPermissions: ['edit:dispatches', 'read:dispatches'], logic: 'or' }}
                        onEdit={handleRowView}
                        isViewOnly
                    />
                </Grid>
                <DispatchDialogView
                    workOrder={workOrder}
                    dispatch={currentDispatch}
                    open={addDispatchDialogOpen}
                    onClose={onCloseOutDispatchClose}
                    unlockDispatch={confirmUnlock}
                    recallDispatch={confirmRecall}
                    createDispatch={createDispatch}
                    reportDetails={
                        selectedReportToDispatch
                            ? {
                                  id: selectedReportToDispatch.id,
                                  type: selectedReportToDispatch.reportType ?? 0,
                                  lockDate: selectedReportToDispatch.lockDate,
                                  onBackClicked: handleBackOnDispatchModalClicked,
                              }
                            : undefined
                    }
                />
                <WorkOrderDispatchSelectReportDialog
                    open={isSelectReportDialogOpen}
                    onClose={() => setIsSelectReportDialogOpen(false)}
                    onSelected={handleReportToDispatchSelected}
                    workOrderId={workOrder.id}
                />
                <ConfirmDialog
                    open={confirmRecallOpen}
                    onClose={() => setConfirmRecallOpen(false)}
                    content={<Typography>Do you want to recall this dispatch?</Typography>}
                    titleIcon={<Undo />}
                    title={<Typography variant='h3'>Recall Dispatch?</Typography>}
                    cancelText='No'
                    onConfirm={() => handleRecall(currentDispatch!.id)}
                />
                <ConfirmDialog
                    open={confirmUnlockOpen}
                    onClose={() => setConfirmUnlockOpen(false)}
                    content={<Typography>Do you want to unlock this dispatch?</Typography>}
                    titleIcon={<LockOpen />}
                    title={<Typography variant='h3'>Unlock Dispatch?</Typography>}
                    cancelText='No'
                    onConfirm={() => handleUnlock(currentDispatch!.id)}
                />
            </Grid>
        );
    }
);
