import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC } from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import './photoEditorDialog.css';
import ImageEditor from '@toast-ui/react-image-editor';
import React from 'react';
import { dataURLToFile } from '../../../util';
import { Attachment } from '../../Forms';

export interface IPhotoEditorDialogProps {
    open: boolean;
    onClose: () => void;
    onUpdate: (updatedFile: File, attachmentId: string) => void;
    attachment: Attachment;
}

export const PhotoEditorDialog: FC<IPhotoEditorDialogProps> = React.memo(({ open, onClose, attachment, onUpdate }) => {
    const ref = React.createRef();

    const handleUpdateClicked = () => {
        const editor = (ref as any)?.current?.getInstance();
        onUpdate(dataURLToFile(editor.toDataURL({ format: 'jpeg' }), attachment.photo.sourceName), attachment.id);
    };

    return (
        <Dialog onClose={onClose} open={open} maxWidth={false}>
            <DialogContent>
                <ImageEditor ref={ref}
                    includeUI={{
                        loadImage: {
                            path: attachment.previewLink!,
                            name: attachment.photo.sourceName,
                        },
                        menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'filter'],
                        uiSize: {
                            width: '1280px',
                            height: '720px',
                        },
                        menuBarPosition: 'right',
                    }}
                    cssMaxHeight={500}
                    cssMaxWidth={700}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70,
                    }}
                    usageStatistics={true}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={handleUpdateClicked}>
                    Update Photo
                </Button>
            </DialogActions>
        </Dialog>
    );
});