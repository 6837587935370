import { FunctionComponent } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetRoofLeakQuery } from '../../store/apis/roof-leak-api';
import { SvgEditor, SvgWrapper } from '../../Components/svg';
import { } from '../../models';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import moment from 'moment';

export const RoofLeakView: FunctionComponent = () => {
    const { id } = useParams();
    const { data, isLoading: roofLeakLoading } = useGetRoofLeakQuery(id!);

    if (roofLeakLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item xs={3}>
                <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                    Roof Leak
                </Typography>
                <Typography>{moment.utc(data?.createdOn!).local().format('MMMM Do YYYY, h:mm:ss A')}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>
                    <Box fontWeight='fontWeightBold' display='inline'>
                        Facility:
                    </Box>{' '}
                    {data?.facility?.name ?? ''}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>
                    {' '}
                    <Box fontWeight='fontWeightBold' display='inline'>
                        Reported By:
                    </Box>{' '}
                    {data?.reporterName ?? ''}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>
                    {' '}
                    <Box fontWeight='fontWeightBold' display='inline'>
                        Details:
                    </Box>{' '}
                    {data?.details ?? ''}
                </Typography>
            </Grid>

            {data?.roofProfile?.svgContents && (
                <Grid item xs={3}>
                    <SvgWrapper>
                        <SvgEditor colorSections={false} svgContents={data?.roofProfile?.svgContents} roofLeaks={[data]} />
                    </SvgWrapper>
                </Grid>
            )}
        </Grid>
    );
};
