import { serverApi } from '..';
import { QueryResultsDto, ReportVisibilityChangeDto } from '../../models';
import { ReportListItemDto } from '../../models/ReportListItemDto';
import { buildSearchQueryRoute } from '../../util';
import { ReportSearchQueryParams } from '../types';

const ReportListApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getReportListItems: builder.query<QueryResultsDto<ReportListItemDto>, ReportSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('reportList', params),
            providesTags: [{ type: 'Report' }],
        }),
        getDoesReportExistForWorkOrder: builder.query<boolean, { facilityId: string; clientId: string; reportType: string }>({
            query: (params) => ({ url: `reportList/facilityId/${params.facilityId}/clientId/${params.clientId}/reportType/${params.reportType}` }),
            providesTags: [{ type: 'Report' }],
        }),
        setReportsPublic: builder.mutation<void, ReportVisibilityChangeDto[]>({
            query: (body) => ({
                url: `reportList/public`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'Report' }],
        }),
        setReportsPrivate: builder.mutation<void, ReportVisibilityChangeDto[]>({
            query: (body) => ({
                url: `reportList/private`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'Report' }],
        }),
    }),
});

export const { useGetReportListItemsQuery, useLazyGetDoesReportExistForWorkOrderQuery, useSetReportsPrivateMutation, useSetReportsPublicMutation } =
    ReportListApi;
