import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { FacilityForm } from '../../Components/Forms/FacilityForm';
import { FacilityDto } from '../../models';
import { useGetClientQuery } from '../../store/apis/client-api';
import { useGetFacilityQuery, useUpdateFacilityMutation } from '../../store/apis/facility-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditFacilityView: FunctionComponent = props => {
    const { clientId, id } = useParams();
    const navigate = useNavigate();
    const { data: client, error: clientError, isLoading: clientLoading, refetch: clientRefetch } = useGetClientQuery(clientId!);
    const { data: facility, error: facilityError, isLoading: facilityLoading, refetch: facilityRefetch } = useGetFacilityQuery(id!);
    const [updateFacility, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateFacilityMutation();

    useFailedUpdateSnackbar('facility', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Facility', isUpdateSuccess, () => {
        navigate(`/clients/${clientId}/facility/${id}`);
    });

    const handleSave = (values: FacilityDto) => {
        updateFacility(values);
    };

    const handleCancel = () => {
        navigate(`/clients/${clientId}/facility/${id}`);
    };

    if (clientLoading || facilityLoading) {
        return <LoadingIndicator />;
    }

    if (clientError) {
        return <ApiError onReloadClick={clientRefetch} />;
    }

    if (facilityError) {
        return <ApiError onReloadClick={facilityRefetch} />;
    }

    return (
        <FacilityForm client={client!} save={handleSave} cancel={handleCancel} initValues={facility} />
    );
};