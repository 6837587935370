import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { RoofSectionReportForm } from '../../Components/Forms/RoofSectionReportForm';
import { RoofSectionReportDto } from '../../models/RoofSectionReportDto';
import { useGetReportQuery } from '../../store/apis/report-api';
import { useCreateRoofSectionReportMutation } from '../../store/apis/roof-section-report-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddRoofSectionReportView: FC = () => {
    const navigate = useNavigate();
    const { reportId } = useParams();
    const [createRoofSectionReport, { data, isLoading: isCreateLoading, error, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateRoofSectionReportMutation();
    const { data: clientReport, isLoading: clientReportLoading, isError: clientReportError, refetch: clientReportRefetch } = useGetReportQuery(reportId!);

    useFailedCreateSnackbar('roof section report', isCreateError, resetCreate, error);
    useSuccessfulCreateSnackbar('Roof section report', isCreateSuccess, () => {
        navigate(`/clientreports/${reportId}/roofsectionreport/edit/${data?.id}`);
    });

    const handleSave = (values: RoofSectionReportDto) => {
        createRoofSectionReport(values);
    };

    const handleCancel = () => {
        navigate(`/clientreports/edit/${reportId}`);
    };

    if (clientReportLoading || isCreateLoading) {
        return <LoadingIndicator />;
    }

    if (clientReportError) {
        return <ApiError onReloadClick={clientReportRefetch} />;
    }

    return <RoofSectionReportForm clientReport={clientReport!} save={handleSave} cancel={handleCancel} />;
};
