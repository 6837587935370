import { Description } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { FunctionComponent, useState } from 'react';
import { ReportListItemDto } from '../../../models/ReportListItemDto';
import { useGetReportListItemsQuery } from '../../../store/apis/report-list-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { ReportType } from '../types';
import { ISelectClientReportDialog } from './types';

export const SelectClientReportDialog: FunctionComponent<ISelectClientReportDialog> = React.memo(props => {
    const { open, onCancel, onConfirm, reportType, facilityId, clientId } = props;
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const {
        data,
        isLoading,
        error,
        refetch: refetchReports,
    } = useGetReportListItemsQuery({
        searchText: '',
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: 5,
        includeInactive: false,
        woNumber: undefined,
        facilityId: facilityId,
        clientId: clientId,
        reportType: reportType,
    });

    const tableColumns: DataTableColumn<ReportListItemDto>[] = [
        {
            key: 'reportDate',
            label: 'Report Date',
            sortKey: 'REPORT_DATE',
            fieldMapper: (row) => (row.reportDate ? format(new Date(row.reportDate.toString()), 'M/d/yyyy') : ''),
        },
        { key: 'client', label: 'Client', sortKey: 'CLIENT', fieldMapper: (row) => row.client },
        { key: 'facility', label: 'Facility', sortKey: 'FACILITY', fieldMapper: (row) => row.facility },
        { key: 'workOrder', label: 'WO / Cap Proj #', sortKey: 'WO_NUMBER', fieldMapper: (row) => row.woNumber },
        {
            key: 'reportName',
            label: 'Report Name',
            sortKey: 'REPORT_NAME',
            fieldMapper: (row) => row.reportName
        },
        { key: 'reportType', label: 'Report Type', sortKey: 'REPORT_TYPE', fieldMapper: (row) => ReportType[row.reportType ?? 0] },
    ];

    const beforeClose = () => {
        onCancel();
    };

    const handleSelect = () => {
        var selectedSections = selected?.map((index) => {
            return data?.pageResults[index];
        });
        onConfirm(selectedSections[0]);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetchReports} />;
    }

    return (
        <Dialog maxWidth='xl' fullWidth open={open}>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <Description />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Copy Data from Previous Report?
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' pt={4} spacing={2}>
                    <Grid item>
                        <Typography variant='h6'>Would you like to copy data over from one of these previous reports?</Typography>
                    </Grid>
                    <Grid item>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isLoading}
                            queryResults={data}
                            defaultSortKey='REPORT_DATE'
                            setPagination={setPaginatedProps}
                            selected={selected}
                            setSelected={setSelected}
                            defaultSortDesc={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row'>
                    <Grid item xs={9}>
                        {selected.length > 1 && <Typography color='error'>Selection is limited to one report only.</Typography>}
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs={3} justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={selected.length !== 1}
                                onClick={handleSelect}>
                                Copy
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
});
