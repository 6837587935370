import { Avatar } from "@mui/material";

export interface IGeneratedAvatarProps {
    name?: string;
}

function GeneratedAvatar(props: IGeneratedAvatarProps) {
    const { name } = props;

    const getInitials = () => {
        if (name) {
            const nameParts = name!.split(/[\s]+/);
            if (nameParts.length >= 2) {
                return `${nameParts[0][0]}${nameParts[1][0]}`;
            }
            else if (nameParts.length === 1) {
                if (nameParts[0].length > 1) {
                    return `${nameParts[0][0]}${nameParts[0][1]}`;
                } else {
                    return nameParts[0][0];
                }
            }
        }
        return '?';
    };

    return (
        <Avatar
            sx={{
                background: '#266490',
                height: '40px',
                width: '40px',
            }}
        >
            <div>{getInitials().toUpperCase()}</div>
        </Avatar>
    );
}

export default GeneratedAvatar;