import { FormatListBulletedOutlined, Map, OpenInFull, PhotoLibrary } from '@mui/icons-material';
import {
    Autocomplete,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { FC, useMemo, useRef, useState } from 'react';
import { Attachment, FormButton, FormSection, IFormProps } from '..';
import AuthenticatedComponent from '../../../auth';
import { emptyGuid, ReportDto, RoofConditionDto, RoofSectionDto, RoofSectionReportDto, RoofSystemDto } from '../../../models';
import { ContactDto } from '../../../models/ContactDto';
import { useGetContactsQuery } from '../../../store/apis/contact-api';
import { useGetRoofConditionsQuery, useGetRoofSystemsQuery } from '../../../store/apis/roof-api';
import { useGetRoofSectionsQuery } from '../../../store/apis/roof-section-api';
import { ColorSwatch } from '../../../util';
import { IEntitySelect } from '../../core/IEntitySelect';
import LoadingIndicator from '../../core/LoadingIndicator';
import { MenuButton } from '../../core/MenuButton';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { PhotosFormSection } from '../../core/Photos';
import { DashboardCard } from '../../Dashboard';
import { ImmediateRepairsChecklistItemListView } from '../ClientReportForm/ImmediateRepairsChecklistItemListView';
import { DateTimePicker, FormInput, FormNumberInput } from '../FormFields';
import { SelectRoofSectionsDialog } from '../SelectRoofSection';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';

export interface IRoofSectionReportFormProps extends IFormProps<RoofSectionReportDto> {
    clientReport: ReportDto;
}

export const RoofSectionReportForm: FC<IRoofSectionReportFormProps> = ({ save, cancel, initValues, clientReport }) => {
    const [isSelectRoofSectionsDialogOpen, setIsSelectRoofSectionsDialogOpen] = useState(false);
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [roofNumber, setRoofNumber] = useState(initValues?.roofSection ? initValues.roofSection.roofNumber : '');
    const [currentCondition, setCurrentCondition] = useState(initValues?.currentConditionId ? initValues.currentConditionId : '');
    const [squareFootage, setSquareFootage] = useState<number | undefined>(initValues ? initValues.squareFootage : undefined);
    const [roofSystem, setRoofSystem] = useState<RoofSystemDto | null>(initValues?.roofSystem ?? null);
    const [dateOfInspection, setDateOfInspection] = useState<Date | null | undefined>(
        initValues && initValues.inspectionDate ? new Date(initValues.inspectionDate) : undefined
    );
    const [technician, setTechnician] = useState<ContactDto | null>(initValues?.contact ?? null);
    const [technicianName, setTechnicianName] = useState(initValues ? initValues.technicianName : '');
    const [maintenancePerformed, setMaintenancePerformed] = useState(initValues ? initValues.maintenancePerformed : '');
    const [inspectorComments, setInspectorComments] = useState(initValues ? initValues.inspectorComments : '');
    const [inspectorRecommendations, setInspectorRecommendations] = useState(initValues ? initValues.inspectorRecommendations : '');
    const [photos, setPhotos] = useState<Attachment[]>([]);
    const [photoCaptions, setPhotoCaptions] = useState<string[]>([]);
    const checklistItemRef = useRef<null | HTMLDivElement>(null);
    const photosRef = useRef<null | HTMLDivElement>(null);

    const [fieldErrors, setFieldErrors] = useState({
        ROOF_NUMBER: '',
        SQUARE_FOOTAGE: '',
        ROOF_TYPE: '',
    });
    const [changed, setFormChanged] = useState(false);
    const { data: roofSections, isLoading: isLoadingRoofSections } = useGetRoofSectionsQuery({
        facilityId: clientReport.workOrder?.facilityId,
        roofProfileId: clientReport.workOrder?.roofProfileId,
        sortKey: 'ROOF_NUMBER',
        includeInactive: true,
    });
    const isContractorIdOnWorkOrder = useMemo(() => !!clientReport.workOrder?.contractorId, [clientReport.workOrder?.contractorId]);
    const { data: contacts, isLoading: isLoadingContacts } = useGetContactsQuery({
        parentId: clientReport.workOrder?.contractorId!,
        params: {
            searchText: '',
            includeInactive: true,
            includeOther: false,
        },
    });
    const { data: roofSystems, isLoading: isLoadingRoofSystems } = useGetRoofSystemsQuery({ includeInactive: true });
    const { data: roofConditions, isLoading: isLoadingRoofConditions } = useGetRoofConditionsQuery({ includeInactive: true });

    const isLocked = (date?: Date) => {
        if (date && new Date() >= new Date(date)) {
            return true;
        }
        return false;
    };

    const [isReportLocked] = useState(clientReport ? isLocked(clientReport.lockDate) : false);

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    };

    const handleRoofSystemChange = (_event: any, value: RoofSystemDto | null) => {
        setFormChanged(true);
        setRoofSystem(value);
        setFieldErrors({ ...fieldErrors, ROOF_TYPE: '' });
    };

    const handleInspectionDateChange = (value: Date | null | undefined) => {
        setFormChanged(true);
        setDateOfInspection(value);
    };

    const handleTechnicianChange = (_e: any, value: ContactDto | null) => {
        setFormChanged(true);
        setTechnician(value);
        const contact = contacts?.pageResults.find((x) => x.id === value?.id);
        if (contact) {
            setTechnicianName(`${contact.firstName} ${contact.lastName}`);
        }
    };

    const handleMaintenancePerformedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setMaintenancePerformed(event.target.value);
    };

    const handleSquareFootageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length < 8) {
            setFormChanged(true);
            setSquareFootage(Math.round(event.target.valueAsNumber));
        }
    };

    const handleInspectorCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setInspectorComments(event.target.value);
    };

    const handleInspectorRecommendationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setInspectorRecommendations(event.target.value);
    };

    const handleRoofNumberChange = (value: RoofSectionDto | null | undefined | string) => {
        setFormChanged(true);
        if (value === null || value === undefined) {
            setRoofNumber('');
            return;
        }

        let roofSection: RoofSectionDto | undefined;

        if (_.isString(value)) {
            setRoofNumber(value as string);
        }
        if (typeof value === 'string') {
            const matchingRoofSections = roofSections?.pageResults.filter((x) => x.roofNumber === roofNumber);
            // If there are multiple roof sections with the same roof number, return the active one
            if (matchingRoofSections && matchingRoofSections.length > 1) {
                roofSection = matchingRoofSections.find((x) => x.isActive);
            }
            roofSection = matchingRoofSections?.find((x) => x.roofNumber === roofNumber);
        } else {
            roofSection = roofSections?.pageResults.find((x) => x.id === value.id);
        }
        if (roofSection) {
            setRoofSystem(roofSection.roofSystem ?? null);
            setCurrentCondition(roofSection.roofConditionId ?? '');
            setSquareFootage(roofSection.squareFootage ?? undefined);
            setFieldErrors({ ...fieldErrors, ROOF_TYPE: '', SQUARE_FOOTAGE: '' });
        }

        onFieldBlur('ROOF_NUMBER');
    };

    const handleSelectRoofSectionsDialogClose = () => {
        setIsSelectRoofSectionsDialogOpen(false);
    };

    const handleSelectRoofSectionsDialogConfirm = (sectionSelected: RoofSectionDto) => {
        setFormChanged(true);
        setRoofNumber(sectionSelected.roofNumber);
        setRoofSystem(sectionSelected.roofSystem ?? null);
        setCurrentCondition(sectionSelected.roofConditionId ?? '');
        setSquareFootage(sectionSelected.squareFootage ?? undefined);
        setFieldErrors({ ...fieldErrors, ROOF_TYPE: '', SQUARE_FOOTAGE: '' });
        setIsSelectRoofSectionsDialogOpen(false);
    };

    const handleCurrentConditionChange = (event: SelectChangeEvent) => {
        setFormChanged(true);
        setCurrentCondition(event.target.value);
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'ROOF_NUMBER') {
            if (roofNumber && roofSections?.pageResults.find((x) => x.roofNumber === roofNumber)) {
                fieldErrors.ROOF_NUMBER = '';
                isValid = true;
            } else if (roofNumber) {
                fieldErrors.ROOF_NUMBER = 'Please enter a valid roof number';
                isValid = false;
            } else {
                fieldErrors.ROOF_NUMBER = 'Roof Number is required';
                isValid = false;
            }
        } else if (fieldName === 'SQUARE_FOOTAGE') {
            if (squareFootage) {
                fieldErrors.SQUARE_FOOTAGE = '';
                isValid = true;
            } else {
                fieldErrors.SQUARE_FOOTAGE = 'Square Footage is required';
                isValid = false;
            }
        } else if (fieldName === 'ROOF_TYPE') {
            if (roofSystem) {
                fieldErrors.ROOF_TYPE = '';
                isValid = true;
            } else {
                fieldErrors.ROOF_TYPE = 'Roof type is required';
                isValid = false;
            }
        }

        setFieldErrors({
            ROOF_NUMBER: fieldErrors.ROOF_NUMBER,
            SQUARE_FOOTAGE: fieldErrors.SQUARE_FOOTAGE,
            ROOF_TYPE: fieldErrors.ROOF_TYPE,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('ROOF_NUMBER');
        isValid = validate('ROOF_TYPE') && isValid;
        isValid = validate('SQUARE_FOOTAGE') && isValid;
        return isValid;
    };

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            save({
                id: initValues ? initValues.id : emptyGuid,
                isActive: isActive,
                roofNumber: roofNumber,
                roofSectionId: roofSections?.pageResults.find((x) => x.roofNumber === roofNumber)?.id,
                inspectionDate: dateOfInspection,
                contactId: technician?.id ?? undefined,
                technicianName: technicianName,
                maintenancePerformed: maintenancePerformed,
                inspectorComments: inspectorComments,
                inspectorRecommendations: inspectorRecommendations,
                reportId: clientReport!.id,
                squareFootage: squareFootage,
                roofSystemId: roofSystem?.id,
                photos: photos.map((attachment, index) => {
                    return { ...attachment.photo, caption: photoCaptions[index], order: index };
                }),
                currentConditionId: currentCondition === '' ? undefined : currentCondition,
            });
            setFormChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    const scrollToPhotos = () => {
        photosRef?.current!.scrollIntoView();
    };

    const scrollToChecklistItems = () => {
        checklistItemRef?.current!.scrollIntoView();
    };

    if (isLoadingRoofSections || isLoadingContacts || isLoadingRoofSystems || isLoadingRoofConditions) {
        return <LoadingIndicator />;
    }

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Map /> {roofNumber ? `Roof Section # ${roofNumber}` : `New Roof Section`}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Client Reports', navLink: '/clientreports' },
                            { label: clientReport.reportName!, navLink: `/clientreports/edit/${clientReport.id}` },
                        ]}
                        currentPageLabel={roofNumber ? `Roof Section # ${roofNumber}` : `New Roof Section`}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={isActive} onChange={handleActiveToggleChange} />}
                            label='Active'
                            labelPlacement='start'
                            disabled={isReportLocked}
                        />
                    </Grid>
                    <Grid item>
                        {changed || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave} disabled={isReportLocked}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={2} mt={1}>
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToPhotos} disabled={!initValues} tooltip='PHOTOS'>
                                    <PhotoLibrary />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToChecklistItems} disabled={!initValues} tooltip='Immediate Repairs Checklist'>
                                    <FormatListBulletedOutlined />
                                </MenuButton>
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' spacing={6} alignItems={'baseline'}>
                        <Grid item container xs={2}>
                            <FormControl error={fieldErrors.ROOF_NUMBER !== ''} fullWidth required>
                                <FormLabel>Roof #</FormLabel>
                                <Grid item container alignItems={'center'} direction='row'>
                                    <Grid item xs={11}>
                                        <Autocomplete
                                            value={roofNumber ?? null}
                                            onChange={(_event, value, _reason, _details) => {
                                                if (typeof value === 'string') {
                                                    setTimeout(() => {
                                                        setRoofNumber(value.substring(0, 4));
                                                        const roofSection = roofSections?.pageResults.find((x) => x.roofNumber === value);
                                                        if (roofSection) {
                                                            setRoofSystem(roofSection.roofSystem ?? null);
                                                            setCurrentCondition(roofSection.roofConditionId ?? '');
                                                            setSquareFootage(roofSection.squareFootage ?? undefined);
                                                            setFieldErrors({ ...fieldErrors, ROOF_TYPE: '', SQUARE_FOOTAGE: '' });
                                                        }
                                                    });
                                                }
                                            }}
                                            options={
                                                roofSections?.pageResults
                                                    .filter((option) => option.isActive || option.roofNumber === roofNumber)
                                                    .map((x) => `${x.roofNumber}${x.isActive ? '' : ' (Inactive)'}`) ?? []
                                            }
                                            getOptionLabel={(option: string) => {
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                return option;
                                            }}
                                            disabled={isReportLocked}
                                            onBlur={onFieldBlur('ROOF_NUMBER')}
                                            selectOnFocus
                                            handleHomeEndKeys
                                            renderOption={(props, option) => <li {...props}>{option}</li>}
                                            freeSolo
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{ ...params.inputProps, maxLength: 4 }}
                                                    error={fieldErrors.ROOF_NUMBER !== ''}
                                                    onChange={(event) => handleRoofNumberChange(event.target.value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => setIsSelectRoofSectionsDialogOpen(true)} disabled={isReportLocked}>
                                            <OpenInFull sx={{ fontSize: 36 }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <FormHelperText>{fieldErrors.ROOF_NUMBER}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item container direction='row' xs={4} spacing={4}>
                            <Grid item xs={6}>
                                <FormControl fullWidth required error={fieldErrors.ROOF_TYPE !== ''} disabled={isReportLocked}>
                                    <FormLabel>Roof Type</FormLabel>
                                    <IEntityAutocomplete
                                        options={roofSystems}
                                        onChange={handleRoofSystemChange}
                                        value={roofSystem}
                                        getOptionLabel={(option: RoofSystemDto) => option.name}
                                        isLoading={isLoadingRoofSystems}
                                        validate={onFieldBlur('ROOF_TYPE')}
                                        error={fieldErrors.ROOF_TYPE !== ''}
                                        disabled={isReportLocked}
                                    />
                                    <FormHelperText>{fieldErrors.ROOF_TYPE}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth disabled={isReportLocked}>
                                    <FormLabel>Condition</FormLabel>
                                    <IEntitySelect
                                        getItem={(item: RoofConditionDto) => <ColorSwatch condition={item} />}
                                        value={currentCondition ?? ''}
                                        onChange={handleCurrentConditionChange}
                                        menuItems={roofConditions}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' xs={3} spacing={4}>
                            <Grid item xs={5}>
                                <FormNumberInput
                                    value={squareFootage}
                                    onChange={handleSquareFootageChange}
                                    onBlur={onFieldBlur('SQUARE_FOOTAGE')}
                                    disabled={isReportLocked}
                                    label='Square Footage'
                                    name='sqFootage'
                                    error={fieldErrors.SQUARE_FOOTAGE !== ''}
                                    errorText={fieldErrors.SQUARE_FOOTAGE}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <FormControl fullWidth>
                                    <DateTimePicker
                                        label='Date of Inspection'
                                        value={dateOfInspection}
                                        onChange={handleInspectionDateChange}
                                        disabled={isReportLocked}
                                        dateOnly={true}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth disabled={isReportLocked}>
                                <FormLabel>Technician</FormLabel>
                                <IEntityAutocomplete
                                    options={contacts?.pageResults}
                                    onChange={handleTechnicianChange}
                                    value={technician}
                                    getOptionLabel={(option: ContactDto) => `${option.firstName} ${option.lastName}`}
                                    isLoading={isLoadingContacts}
                                    disabled={isReportLocked}
                                />
                                <FormHelperText>
                                    {!isContractorIdOnWorkOrder && 'No Dispatch ID is set on the Work Order'}
                                </FormHelperText>

                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='column' spacing={4}>
                        <Grid item xs={4}>
                            <FormInput
                                value={maintenancePerformed}
                                onChange={handleMaintenancePerformedChange}
                                disabled={isReportLocked}
                                label='Maintenance Performed'
                                fullWidth
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={inspectorComments}
                                onChange={handleInspectorCommentsChange}
                                disabled={isReportLocked}
                                label='Inspector Comments & Notes'
                                fullWidth
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={inspectorRecommendations}
                                onChange={handleInspectorRecommendationsChange}
                                disabled={isReportLocked}
                                label='Inspector Recommendations'
                                fullWidth
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            {initValues && (
                <>
                    <PhotosFormSection
                        initValues={initValues}
                        photos={photos}
                        setPhotos={setPhotos}
                        photoCaptions={photoCaptions}
                        setPhotoCaptions={setPhotoCaptions}
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        component={
                            clientReport && (
                                <Grid item ref={checklistItemRef}>
                                    <DashboardCard headerTitle='Immediate Repairs Checklist' headerIcon={<FormatListBulletedOutlined />}>
                                        <ImmediateRepairsChecklistItemListView
                                            disabled={isReportLocked}
                                            reportId={clientReport.id}
                                            facilityId={clientReport.workOrder?.facilityId}
                                            roofProfileId={clientReport.workOrder?.roofProfileId}
                                            roofSectionDetails={
                                                initValues.roofSection
                                                    ? {
                                                          id: initValues.roofSection.id,
                                                          roofNumber: initValues.roofSection.roofNumber,
                                                      }
                                                    : undefined
                                            }
                                        />
                                    </DashboardCard>
                                </Grid>
                            )
                        }
                    />
                </>
            )}
            <SelectRoofSectionsDialog
                open={isSelectRoofSectionsDialogOpen}
                onCancel={handleSelectRoofSectionsDialogClose}
                onConfirm={handleSelectRoofSectionsDialogConfirm}
                currentRoofSections={roofSections?.pageResults.filter((x) => x.roofNumber === roofNumber) ?? []}
                isMultipleSelectable={false}
                facilityId={clientReport.workOrder?.facilityId}
                roofProfileId={clientReport.workOrder?.roofProfileId}
            />
        </Grid>
    );
};
