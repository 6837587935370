import { useAuth0 } from '@auth0/auth0-react';
import { AddCircle, Assignment, Autorenew, Build, ContentCopy, Description, Folder, Handyman, History, InsertChart, Map, Notes, Send } from '@mui/icons-material';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { format } from 'date-fns/esm';
import jwt from 'jsonwebtoken';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AuthenticatedComponent, { userHasPermissions } from '../../../auth';
import { ClientDto, DivisionDto, emptyGuid, FacilityDto, OrderType, RoofProfileDto, WorkOrderStatusDto, WorkOrderTypeDto } from '../../../models';
import { ContractorDto } from '../../../models/ContractorDto';
import { useGetClientQuery, useGetClientsQuery } from '../../../store/apis/client-api';
import { useCreateContractorMutation, useGetContractorsQuery } from '../../../store/apis/contractor-api';
import { useGetDivisionsQuery } from '../../../store/apis/division-api';
import { useLazyGetFacilityQuery } from '../../../store/apis/facility-api';
import { useGetUserByAuth0IdQuery } from '../../../store/apis/user-api';
import { useLazyGetWorkOrderNumberQuery } from '../../../store/apis/work-order-api';
import { useGetWorkOrderStatusesQuery } from '../../../store/apis/work-order-status-api';
import { useGetWorkOrderTypesQuery } from '../../../store/apis/work-order-type-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import LoadingIndicator from '../../core/LoadingIndicator';
import { MenuButton } from '../../core/MenuButton';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { DashboardCard } from '../../Dashboard';
import { ContractorForm } from '../ContractorForm';
import { FormButton } from '../FormButton';
import { DateTimePicker, FormCurrencyInput, FormInput, patterns } from '../FormFields';
import { RoofMap } from '../RoofMap';
import { IWorkOrderFormProps, WorkOrderContractorInformation } from './types';
import { WorkOrderContractors } from './WorkOrderContractors';
import { WorkOrderDispatchSection } from './WorkOrderDispatchSection';
import { WorkOrderDocuments } from './WorkOrderDocuments';
import { WorkOrderHistoryDialog } from './WorkOrderHistoryDialog';
import { WorkOrderNotes } from './WorkOrderNotes';
import { WorkOrderTypeDialog } from './WorkOrderTypeDialog';
import { ClientReportsListView } from '../../../Views/ClientReportsListView';
import { ClientFacilityRoofPlanPicker } from '../FormFields/ClientFacilityRoofPlanPicker';
import { createAddressStringFromFacility } from '../../../util';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { usePermissionChecker } from '../../../Hooks';
import { isDateGreaterThanOrEqual } from '../../../util/compareDates';
import { useNavigate } from 'react-router-dom';

export const WorkOrderAndCapitalProjectForm: FunctionComponent<IWorkOrderFormProps> = (props) => {
    const { initValues, save, cancel, woNumberError, isCapitalProject, clientId: passedInClientId, facilityId, handleClone } = props;
    const auth = useAuth0();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [clientType, setClientType] = useState(initValues?.billedToClient ? 'client' : (initValues?.billedToContractor ? 'contractor' : ''));
    const isBilledToClient = useMemo(() => clientType === 'client', [clientType]);
    const [getNewWorkOrderNumber, { isFetching: isLoadingNewWorkOrderNumber }] = useLazyGetWorkOrderNumberQuery();
    const { data: passedInClient } = useGetClientQuery(passedInClientId ?? '');

    const [client, setClient] = useState<ClientDto | undefined>(initValues?.facility?.client ?? undefined);

    const { user } = useAuth0();
    const { data: currentUser } = useGetUserByAuth0IdQuery(user?.sub ?? '');
    const [workOrderNumber, setWorkOrderNumber] = useState(initValues ? initValues.woNumber : '');
    const [facility, setFacility] = useState<FacilityDto | null | undefined>(initValues ? initValues.facility : null);
    const [roofProfile, setRoofProfile] = useState<RoofProfileDto | null | undefined>(initValues?.roofProfile);
    const [svgContents, setSvgContents] = useState<string>('');

    const [getFacility, { data: facilityData }] = useLazyGetFacilityQuery();
    const [calledInBy, setCalledInBy] = useState(initValues ? initValues.calledInBy : '');
    const [statusMessage, setStatusMessage] = useState(initValues?.statusMessage ?? '');
    const [division, setDivision] = useState<DivisionDto | null>(initValues?.division ?? null);
    const [type, setType] = useState<WorkOrderTypeDto | null>(initValues?.type ?? null);
    const [status, setStatus] = useState<WorkOrderStatusDto | null>(initValues?.status ?? null);
    const [contractor, setContractor] = useState<ContractorDto | null>(initValues?.contractor ?? null);

    const [poNumber, setPoNumber] = useState(initValues ? initValues.poNumber : '');
    const [workStartDate, setWorkStartDate] = useState<Date | null | undefined>(
        initValues && initValues.startDate ? new Date(initValues.startDate) : undefined
    );
    const [workEndDate, setWorkEndDate] = useState<Date | null | undefined>(initValues && initValues.endDate ? new Date(initValues.endDate) : undefined);
    const [dispatchDate, setDispatchDate] = useState<Date | null | undefined>(
        initValues && initValues.dispatchDate ? new Date(initValues.dispatchDate) : undefined
    );
    const [renewalDate, setRenewalDate] = useState<Date | null | undefined>(
        initValues && initValues.renewalDate ? new Date(initValues.renewalDate) : undefined
    );
    const [cost, setCost] = useState(initValues ? initValues.cost.toString() : '');
    const [proposedCost, setProposedCost] = useState(initValues ? initValues.proposedCost?.toString() ?? '' : '');
    const [billedClientToOpen, setBilledClientToOpen] = useState(false);
    const [billedContractorToOpen, setBilledContractorToOpen] = useState(false);
    const [billedToClient, setBilledToClient] = useState<ClientDto | null | undefined>(initValues ? initValues.billedToClient : null);
    const [billedToContractor, setBilledToContractor] = useState<ContractorDto | null | undefined>(initValues ? initValues.billedToContractor : null);
    const [fullAddress, setFullAddress] = useState(facility ? createAddressStringFromFacility(facility, client, billedToClient ?? billedToContractor) : '');
    const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
    const [typeDialogOpen, setTypeDialogOpen] = useState(false);
    const roofMapRef = useRef<null | HTMLDivElement>(null);
    const documentsRef = useRef<null | HTMLDivElement>(null);
    const notesRef = useRef<null | HTMLDivElement>(null);
    const contractorsRef = useRef<null | HTMLDivElement>(null);
    const dispatchRef = useRef<null | HTMLDivElement>(null);
    const clientReportsRef = useRef<null | HTMLDivElement>(null);
    const [isFullEdit, setIsFullEdit] = useState(false);
    const [isLimitedEdit, setIsLimitedEdit] = useState(true);
    const [canEditContractors, setCanEditContractors] = useState(false);
    const [activeDispatchExists, setActiveDispatchExists] = useState(initValues?.isActiveDispatch ?? false);
    const [isContractorDialogOpen, setIsContractorDialogOpen] = useState(false);
    const [roofAreas, setRoofAreas] = useState(initValues ? initValues.roofAreas : '');
    const [isWorkOrderProposal, setIsWorkOrderProposal] = useState(initValues ? initValues.isProposal : false);

    const isPartialEdit = isLimitedEdit && !isFullEdit;

    const [contractorInfo, setContractorInfo] = useState<WorkOrderContractorInformation | undefined>({
        technicianId: initValues ? initValues.contactId : undefined,
        leakLocationId: initValues ? initValues.leakLocationId : undefined,
        serviceDays: initValues ? initValues.daysToService : 0,
        reportDays: initValues ? initValues.daysToReport : 0,
        subKInvoiceAmount: initValues ? initValues.subKInvoiceAmount : 0,
        laborHours: initValues ? initValues.laborHours : 0,
        laborRateMultiplier: initValues ? initValues.laborRateMultiplier : 0,
        roofSmartLaborAmount: initValues ? initValues.roofSmartLaborAmount : 0,
        roofSmartLaborMultiplier: initValues ? initValues.roofSmartLaborMultiplier : 0,
        laborRate: initValues ? initValues.laborRate : 0,
        isProposal: initValues ? initValues.isProposal : false,
        isRepeatLeak: initValues ? initValues.isRepeatLeak : false,
    });
    const [problemDescription, setProblemDescription] = useState(initValues ? initValues.problemDescription : '');
    const [summaryWorkPerformed, setSummaryWorkPerformed] = useState(initValues ? initValues.summaryWorkPerformed : '');
    const [formChanged, setFormChanged] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        WORK_ORDER_NUMBER: '',
        FACILITY: '',
        COST: '',
        PROPOSED_COST: '',
        ROOF_PLAN: '',
        START_DATE: '',
        END_DATE: '',
    });
    const { data: clients, isLoading: clientsLoading } = useGetClientsQuery({ includeInactive: true });
    const { data: divisions, isLoading: divisionsLoading, error: divisionsError, refetch: divisionsRefetch } = useGetDivisionsQuery({ includeInactive: true });
    const { data: workOrderTypes, isLoading: typesLoading, error: typesError, refetch: typesRefetch } = useGetWorkOrderTypesQuery({ includeInactive: true, type: isCapitalProject ? OrderType.CapitalProject : OrderType.WorkOrder });
    const {
        data: workOrderStatuses,
        isLoading: statusesLoading,
        error: statusesError,
        refetch: statusesRefetch,
    } = useGetWorkOrderStatusesQuery({ includeInactive: true });
    const {
        data: contractors,
        isLoading: contractorsLoading,
        error: contractorsError,
        refetch: contractorsRefetch,
    } = useGetContractorsQuery({ pageSize: 100000, includeInactive: true, includeNonStandard: false });
    const [createContractor, { data: newContractor, isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate }] = useCreateContractorMutation();

    const reloadProfile = useCallback(async () => {
        if (facility) {
            let response = await getFacility(facility.id!, false);
            setFacility(response.data ?? facility);
            let roofProfile: RoofProfileDto | undefined = response.data?.roofProfiles?.find((x) => x.id === roofProfile?.id);
            setRoofProfile(roofProfile);
            setSvgContents(roofProfile?.svgContents ?? '');
        }
    }, [facility, getFacility]);
    const { userHasPermission } = usePermissionChecker();

    useFailedCreateSnackbar('rollover item', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Rollover item', isCreateSuccess, () => {
        setContractor(newContractor ?? null);
        resetCreate();
    });

    useEffect(() => {
        if (facility) {
            setFullAddress(createAddressStringFromFacility(facility, client, billedToClient ?? billedToContractor));
        }
    }, [facility, client, billedToClient, billedToContractor]);

    useEffect(() => {
        if (facilityId && !facility) {
            getFacility(facilityId).then(() => {
                if (facilityData) {
                    setFacility(facilityData);
                }
            });
        }
    }, [facility, facilityData, facilityId, getFacility]);

    useEffect(() => {
        if (!initValues?.woNumber) {
            getNewWorkOrderNumber().then((response) => {
                if (response.data) {
                    setWorkOrderNumber(response.data.toString());
                }
            });
        }
    }, [initValues?.woNumber, getNewWorkOrderNumber]);

    // TODO: we have hooks that make checking for permissions much easier so this should be updated to use those hooks at some point
    useEffect(() => {
        async function getUserPermissions() {
            const token = await auth.getAccessTokenSilently();

            return jwt.decode(token);
        }

        if (!auth.isLoading && auth.isAuthenticated) {
            getUserPermissions().then((result) => {
                const permissions = ((result as any)?.permissions as string[]) ?? [];

                setIsFullEdit(userHasPermissions(['edit:workordersFull'], permissions));
                setIsLimitedEdit(userHasPermissions(['edit:workordersLimited'], permissions));
                setCanEditContractors(userHasPermissions(['edit:contractors', 'delete:contractors'], permissions, 'or'));
            });
        }
    }, [auth, isFullEdit, setIsFullEdit, isLimitedEdit, setIsLimitedEdit]);

    useEffect(() => {
        if (roofProfile?.svgContents) {
            setSvgContents(roofProfile.svgContents);
        } else {
            setSvgContents('');
        }
    }, [roofProfile]);

    useEffect(() => {
        if (['Proposal Acceptance Pending', 'Proposal in Progress'].includes(type?.name ?? '')) {
            setIsWorkOrderProposal(true);
        }
        else {
            setIsWorkOrderProposal(false);
        }
    }, [type]);

    const updateWorkOrderStatusString = useCallback(
        (actionName: string) => {
            const dateString = format(Date.now(), 'M/d/yyy');
            const timeString = format(Date.now(), 'hh:mm a');
            setStatusMessage(`${actionName} by ${currentUser?.displayName ?? 'you'} on ${dateString} at ${timeString}`);
        },
        [currentUser?.displayName]
    );

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'WORK_ORDER_NUMBER') {
            if (workOrderNumber) {
                fieldErrors.WORK_ORDER_NUMBER = '';
                isValid = true;
            } else {
                isCapitalProject
                    ? (fieldErrors.WORK_ORDER_NUMBER = 'Capital Project # is required')
                    : (fieldErrors.WORK_ORDER_NUMBER = 'Work Order # is required');
                isValid = false;
            }
        } else if (fieldName === 'FACILITY') {
            if (facility) {
                fieldErrors.FACILITY = '';
                isValid = true;
            } else {
                fieldErrors.FACILITY = 'Facility is required';
                isValid = false;
            }
        } else if (fieldName === 'COST') {
            const currencyRegex = patterns.Currency.validate;
            if (currencyRegex.test(cost)) {
                fieldErrors.COST = '';
                isValid = true;
            } else {
                fieldErrors.COST = 'Cost must be in decimal format';
                isValid = false;
            }
        } else if (fieldName === 'PROPOSED_COST') {
            const currencyRegex = patterns.Currency.validate;
            if (currencyRegex.test(proposedCost)) {
                fieldErrors.PROPOSED_COST = '';
                isValid = true;
            } else {
                fieldErrors.PROPOSED_COST = 'Proposed cost must be in decimal format';
                isValid = false;
            }
        } else if (fieldName === 'ROOF_PLAN') {
            if (roofProfile) {
                fieldErrors.ROOF_PLAN = '';
                isValid = true;
            } else {
                fieldErrors.ROOF_PLAN = 'Roof Plan is required';
                isValid = false;
            }
        } else if (fieldName === 'START_DATE') {
            if (workStartDate && workEndDate && (workEndDate ? isDateGreaterThanOrEqual(workEndDate, workStartDate) : false)) {
                fieldErrors.START_DATE = '';
                isValid = true;
            } else if (!workStartDate && !workEndDate) {
                fieldErrors.START_DATE = '';
                isValid = true;
            } else {
                fieldErrors.START_DATE = 'Start Date must be equal to or before end';
                isValid = false;
            }
        } else if (fieldName === 'END_DATE') {
            if (workStartDate && workEndDate && (workStartDate ? isDateGreaterThanOrEqual(workEndDate, workStartDate) : false)) {
                fieldErrors.END_DATE = '';
                isValid = true;
            } else if (!workEndDate && !workStartDate) {
                fieldErrors.END_DATE = '';
                isValid = true;
            } else {
                fieldErrors.END_DATE = 'End Date must be equal to or after start';
                isValid = false;
            }
        }
        setFieldErrors({
            WORK_ORDER_NUMBER: fieldErrors.WORK_ORDER_NUMBER,
            FACILITY: fieldErrors.FACILITY,
            COST: fieldErrors.COST,
            PROPOSED_COST: fieldErrors.PROPOSED_COST,
            ROOF_PLAN: fieldErrors.ROOF_PLAN,
            START_DATE: fieldErrors.START_DATE,
            END_DATE: fieldErrors.END_DATE,
        });
        return isValid;
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const formIsValid = () => {
        let isValid = validate('WORK_ORDER_NUMBER');
        isValid = validate('FACILITY') && isValid;
        isValid = validate('COST') && isValid;
        isValid = validate('PROPOSED_COST') && isValid;
        isValid = validate('ROOF_PLAN') && isValid;
        isValid = validate('START_DATE') && isValid;
        isValid = validate('END_DATE') && isValid;
        return isValid;
    };

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            save({
                ...initValues,
                id: initValues ? initValues.id : emptyGuid,
                isActive: isActive,
                woNumber: workOrderNumber,
                poNumber: poNumber,
                startDate: workStartDate!,
                endDate: workEndDate!,
                dispatchDate: dispatchDate!,
                renewalDate: renewalDate ?? undefined,
                cost: cost ? parseFloat(cost) : 0,
                proposedCost: proposedCost ? parseFloat(proposedCost) : 0,
                calledInBy: calledInBy,
                problemDescription: problemDescription,
                summaryWorkPerformed: summaryWorkPerformed,
                billedToClientId: billedToClient ? billedToClient.id : undefined,
                billedToContractorId: billedToContractor ? billedToContractor.id : undefined,
                facilityId: facility!.id,
                roofProfileId: roofProfile?.id!,
                contractorId: contractor?.id,
                divisionId: division?.id,
                statusId: status?.id,
                typeId: type?.id,
                orderType: isCapitalProject ? 1 : 0, // Save work order as type "Work Order",
                contactId: contractorInfo ? contractorInfo.technicianId : undefined,
                leakLocationId: contractorInfo ? contractorInfo.leakLocationId : undefined,
                daysToService: contractorInfo ? contractorInfo.serviceDays : 0,
                daysToReport: contractorInfo ? contractorInfo.reportDays : 0,
                subKInvoiceAmount: contractorInfo ? contractorInfo.subKInvoiceAmount : 0,
                laborHours: contractorInfo ? contractorInfo.laborHours : 0,
                laborRateMultiplier: contractorInfo ? contractorInfo.laborRateMultiplier : 0,
                roofSmartLaborAmount: contractorInfo ? contractorInfo.roofSmartLaborAmount : 0,
                roofSmartLaborMultiplier: contractorInfo ? contractorInfo.roofSmartLaborMultiplier : 0,
                laborRate: contractorInfo ? contractorInfo.laborRate : 0,
                isProposal: contractorInfo ? contractorInfo.isProposal : false,
                isRepeatLeak: contractorInfo ? contractorInfo.isRepeatLeak : false,
                roofAreas: roofAreas
            });
            updateWorkOrderStatusString('Updated');
            setFormChanged(false);
        }
    };

    const refetch = useCallback(() => {
        divisionsRefetch();
        contractorsRefetch();
        typesRefetch();
        statusesRefetch();
    }, [contractorsRefetch, divisionsRefetch, statusesRefetch, typesRefetch]);

    const getButtonRow = useMemo(() => {
        return (
            <Grid item container direction='row' xs={8} spacing={2}>
                {!isCapitalProject && (
                    <AuthenticatedComponent
                        requiredPermissions={['read:dispatches']}
                        component={
                            <Grid item>
                                <MenuButton
                                    tooltip='Dispatch'
                                    disabled={!initValues}
                                    onClick={() => {
                                        dispatchRef?.current?.scrollIntoView();
                                    }}>
                                    <Send />
                                </MenuButton>
                            </Grid>
                        }
                    />
                )}
                <AuthenticatedComponent
                    requiredPermissions={['read:roofMaps']}
                    component={
                        <Grid item>
                            <MenuButton
                                tooltip='Roof Map'
                                disabled={!initValues}
                                onClick={() => {
                                    roofMapRef?.current!.scrollIntoView();
                                }}>
                                <Map />
                            </MenuButton>
                        </Grid>
                    }
                />
                <AuthenticatedComponent
                    requiredPermissions={['read:documentsEmployee', 'read:documentsClient', 'read:documentsAll']}
                    logic='or'
                    component={
                        <Grid item>
                            <MenuButton
                                tooltip='Documents'
                                disabled={!initValues}
                                onClick={() => {
                                    documentsRef?.current!.scrollIntoView();
                                }}>
                                <Description />
                            </MenuButton>
                        </Grid>
                    }
                />
                <AuthenticatedComponent
                    requiredPermissions={['read:notes']}
                    component={
                        <Grid item>
                            <MenuButton
                                tooltip='Notes'
                                disabled={!initValues}
                                onClick={() => {
                                    notesRef?.current!.scrollIntoView();
                                }}>
                                <Notes />
                            </MenuButton>
                        </Grid>
                    }
                />
                {!isCapitalProject ? (
                    <AuthenticatedComponent
                        requiredPermissions={['read:contractors']}
                        component={
                            <Grid item>
                                <MenuButton
                                    tooltip='Contractor'
                                    disabled={!initValues}
                                    onClick={() => {
                                        contractorsRef?.current?.scrollIntoView();
                                    }}>
                                    <Build />
                                </MenuButton>
                            </Grid>
                        }
                    />
                ) : (
                    <></>
                )}
                <Grid item>
                    <MenuButton
                        tooltip='Client Reports'
                        disabled={!initValues}
                        onClick={() => {
                            clientReportsRef?.current!.scrollIntoView();
                        }}>
                        <InsertChart />
                    </MenuButton>
                </Grid>
                {isCapitalProject &&
                    <AuthenticatedComponent
                        requiredPermissions={['read:documentFolders']}
                        component={
                            <Grid item>
                                <MenuButton
                                    tooltip='Folders'
                                    disabled={!initValues}
                                    onClick={() => {
                                        navigate(`/capitalprojects/edit/${initValues?.id}/documentFolders`);
                                    }}>
                                    <Folder />
                                </MenuButton>
                            </Grid>
                        }
                    />
                }
            </Grid>
        );
    }, [initValues, isCapitalProject, navigate]);

    const getWorkOrderDispatchSection = useMemo(() => {
        return initValues && !isCapitalProject && (
            <AuthenticatedComponent
                requiredPermissions={['read:dispatches']}
                component={
                    <Grid item ref={dispatchRef} maxWidth={'100% !important'}>
                        <DashboardCard headerTitle='Dispatch' headerIcon={<Send />}>
                            <WorkOrderDispatchSection
                                workOrder={initValues}
                                handleDispatchAction={(actionName) => {
                                    switch (actionName) {
                                        case 'Dispatched':
                                            setActiveDispatchExists(true);
                                            updateWorkOrderStatusString('Dispatched');
                                            break;
                                        case 'Recalled':
                                            setActiveDispatchExists(false);
                                            setContractor(null);
                                            updateWorkOrderStatusString('Recalled');
                                            break;
                                    }
                                }}
                                contractorId={contractor?.id}
                            />
                        </DashboardCard>
                    </Grid>
                }
            />
        );
    }, [contractor?.id, initValues, isCapitalProject, updateWorkOrderStatusString]);

    const getRoofMapSection = useMemo(() => {
        return initValues && facility ? (
            <AuthenticatedComponent
                requiredPermissions={['read:roofMaps']}
                component={
                    <Grid item ref={roofMapRef} maxWidth={'100% !important'}>
                        <DashboardCard headerTitle='Roof Map' headerIcon={<Map />}>
                            <RoofMap
                                facility={facility!}
                                showTableInit={true}
                                roofProfileId={initValues.roofProfileId === roofProfile?.id ? roofProfile?.id : undefined}
                                svgContents={svgContents}
                                reloadProfile={() => reloadProfile()}
                                canLinkSections={true}
                                editOnMapClick={true}
                                setFormChanged={setFormChanged}
                            />
                        </DashboardCard>
                    </Grid>
                }
            />
        ) : (
            <></>
        );
    }, [facility, initValues, reloadProfile, roofProfile?.id, svgContents]);

    const getWorkOrderDocumentsSection = useMemo(() => {
        return initValues ? (
            <AuthenticatedComponent
                requiredPermissions={['read:documentsEmployee', 'read:documentsClient', 'read:documentsAll']}
                logic='or'
                component={
                    <Grid item ref={documentsRef} maxWidth={'100% !important'}>
                        <DashboardCard headerTitle='Documents' headerIcon={<Description />}>
                            <WorkOrderDocuments workOrderId={initValues.id} facilityId={facility?.id} isCapitalProject={isCapitalProject} />
                        </DashboardCard>
                    </Grid>
                }
            />
        ) : (
            <></>
        );
    }, [facility?.id, initValues, isCapitalProject]);

    const getWorkOrderNotesSection = useMemo(() => {
        return initValues ? (
            <AuthenticatedComponent
                requiredPermissions={['read:notes']}
                component={
                    <Grid item ref={notesRef} maxWidth={'100% !important'}>
                        <DashboardCard headerTitle='Notes' headerIcon={<Notes />}>
                            <WorkOrderNotes workOrderId={initValues.id} />
                        </DashboardCard>
                    </Grid>
                }
            />
        ) : (
            <></>
        );
    }, [initValues]);

    const getContractorSection = useMemo(() => {
        if (initValues) {
            return !isCapitalProject ? (
                <>
                    <AuthenticatedComponent
                        requiredPermissions={['read:contractors']}
                        component={
                            <Grid item ref={contractorsRef} maxWidth={'100% !important'}>
                                <DashboardCard headerTitle='Contractor' headerIcon={<Build />}>
                                    <WorkOrderContractors
                                        contractor={contractor ?? undefined}
                                        facilityId={facility?.id ?? ''}
                                        setFormChanged={setFormChanged}
                                        initValues={initValues}
                                        contractorInfo={contractorInfo}
                                        setContractorInfo={setContractorInfo}
                                        readOnly={
                                            !canEditContractors ||
                                            !(initValues && initValues.contractor && contractor && initValues.contractorId === contractor.id)
                                        }
                                    />
                                </DashboardCard>
                            </Grid>
                        }
                    />
                </>
            ) : (
                <></>
            );
        } else {
            return <></>;
        }
    }, [canEditContractors, contractor, contractorInfo, facility?.id, initValues, isCapitalProject]);

    const getReportsSection = useMemo(() => {
        if (initValues) {
            return (
                <AuthenticatedComponent
                    requiredPermissions={['read:reports']}
                    component={
                        <Grid item ref={clientReportsRef} maxWidth={'100% !important'}>
                            <DashboardCard headerTitle='Client Reports' headerIcon={<InsertChart />}>
                                <ClientReportsListView workOrder={initValues} isCapitalProjectView={isCapitalProject ?? false} />
                            </DashboardCard>
                        </Grid>
                    }
                />
            );
        }
    }, [initValues, isCapitalProject]);

    const getDivisionSelect = useMemo(() => {
        return (
            <FormControl fullWidth disabled={isPartialEdit}>
                <FormLabel>Division</FormLabel>
                <IEntityAutocomplete
                    options={divisions?.pageResults}
                    onChange={(_e, value) => {
                        setDivision(value);
                        setFormChanged(true);
                    }}
                    value={division}
                    getOptionLabel={(option: DivisionDto) => option.name}
                    isLoading={divisionsLoading}
                    disabled={isPartialEdit}
                />
            </FormControl>
        );
    }, [division, divisions?.pageResults, divisionsLoading, isPartialEdit]);

    const getWorkOrderTypeSelect = useMemo(() => {
        return (
            <FormControl fullWidth disabled={isPartialEdit}>
                <FormLabel>Type</FormLabel>
                <IEntityAutocomplete
                    options={workOrderTypes?.pageResults}
                    onChange={(_e, value) => {
                        setType(value);
                        setFormChanged(true);
                    }}
                    value={type}
                    getOptionLabel={(option: WorkOrderTypeDto) => option.name}
                    isLoading={typesLoading}
                    disabled={isPartialEdit}
                />
            </FormControl>
        );
    }, [isPartialEdit, type, typesLoading, workOrderTypes?.pageResults]);

    const getDispatchIdSelect = useMemo(() => {
        return (
            <FormControl fullWidth disabled={isPartialEdit || activeDispatchExists}>
                <FormLabel>Dispatch ID</FormLabel>
                <IEntityAutocomplete
                    options={contractors?.pageResults}
                    onChange={(_e, value) => {
                        setContractor(value);
                        setFormChanged(true);
                    }}
                    value={contractor}
                    getOptionLabel={(option: ContractorDto) => option.dispatchId ?? ''}
                    isLoading={contractorsLoading}
                    disabled={isPartialEdit}
                />
            </FormControl>
        );
    }, [activeDispatchExists, contractor, contractors?.pageResults, contractorsLoading, isPartialEdit]);

    if (divisionsLoading || contractorsLoading || typesLoading || statusesLoading) {
        return <LoadingIndicator />;
    }

    if (divisionsError || contractorsError || typesError || statusesError) {
        return <ApiError onReloadClick={refetch} />;
    }
    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    {isCapitalProject && (
                        <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                            <Handyman /> {initValues ? 'Edit Capital Project' : 'Add New Capital Project'}
                        </Typography>
                    )}
                    {!isCapitalProject && (
                        <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                            <Assignment /> {`Work Order #${workOrderNumber}`}
                        </Typography>
                    )}
                    {!isCapitalProject && (
                        <NavBreadcrumbs
                            links={
                                passedInClientId || facilityId
                                    ? [
                                        { label: 'Home', navLink: '/' },
                                        { label: 'Clients', navLink: '/clients' },
                                        {
                                            label: passedInClient?.name ?? facility?.client?.name ?? 'Client',
                                            navLink: `/clients/${passedInClient?.id ?? facility?.clientId}`,
                                        },
                                    ]
                                    : [
                                        { label: 'Home', navLink: '/' },
                                        { label: 'Work Orders', navLink: '/workorders' },
                                    ]
                            }
                            currentPageLabel={`Work Order #${workOrderNumber}`}
                        />
                    )}
                    {isCapitalProject && (
                        <NavBreadcrumbs
                            links={
                                passedInClientId || facilityId
                                    ? [
                                        { label: 'Home', navLink: '/' },
                                        { label: 'Clients', navLink: '/clients' },
                                        {
                                            label: passedInClient?.name ?? facility?.client?.name ?? 'Client',
                                            navLink: `/clients/${passedInClient?.id ?? facility?.clientId}`,
                                        },
                                    ]
                                    : [
                                        { label: 'Home', navLink: '/' },
                                        { label: 'Capital Projects', navLink: '/capitalprojects' },
                                    ]
                            }
                            currentPageLabel={initValues ? 'Edit Capital Project' : 'Add New Capital Project'}
                        />
                    )}
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <AuthenticatedComponent
                        requiredPermissions={['create:workorders']}
                        component={
                            <Tooltip title='Copy'>
                                <Grid item mr={-2}>
                                    <IconButton color='primary' disabled={!handleClone} onClick={handleClone}>
                                        <ContentCopy />
                                    </IconButton>
                                </Grid>
                            </Tooltip>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['delete:workorders']}
                        component={
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isActive}
                                            onChange={(event) => {
                                                setIsActive(event.target.checked);
                                                setFormChanged(true);
                                            }}
                                        />
                                    }
                                    label='Active'
                                    labelPlacement='start'
                                />
                            </Grid>
                        }
                    />
                    <Grid item>
                        <IconButton color='primary' disabled={!initValues} onClick={() => setHistoryDialogOpen(true)}>
                            <History />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        {formChanged || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={cancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={cancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
                {getButtonRow}
                <Grid item xs='auto'>
                    <Typography fontSize='14px'>{statusMessage}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <DashboardCard headerTitle={isCapitalProject ? 'Capital Project' : 'Work Order'} headerIcon={<Assignment />}>
                    <Grid item container direction='column' spacing={2} sx={{ padding: '20px' }}>
                        {fullAddress && (
                            <Grid item container direction='row'>
                                <Grid item xs={12}>
                                    <Paper variant='outlined'>
                                        <Typography
                                            sx={{
                                                color: '#ffffff',
                                                backgroundColor: '#6596B9',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                whiteSpace: 'pre-line',
                                            }}
                                            padding={1}>
                                            {fullAddress}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item container direction='row' spacing={8} wrap='nowrap'>
                            <Grid item container direction='row' alignItems='center' spacing={1} xs={4}>
                                <Grid item xs={11}>
                                    <FormInput
                                        value={workOrderNumber}
                                        onChange={(event) => {
                                            setWorkOrderNumber(event.target.value);
                                            setFormChanged(true);
                                        }}
                                        onBlur={onFieldBlur('WORK_ORDER_NUMBER')}
                                        label={isCapitalProject ? 'Project #' : 'Work Order #'}
                                        name='workOrderNumber'
                                        error={fieldErrors.WORK_ORDER_NUMBER !== '' || woNumberError !== ''}
                                        errorText={woNumberError ? woNumberError : fieldErrors.WORK_ORDER_NUMBER}
                                        fullWidth
                                        required
                                        disabled={isPartialEdit || isLoadingNewWorkOrderNumber}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        size='large'
                                        sx={{ marginTop: '15px' }}
                                        disabled={isLoadingNewWorkOrderNumber || !!initValues?.id}
                                        onClick={async () => {
                                            const newNum = await getNewWorkOrderNumber();
                                            if (newNum.data) {
                                                setWorkOrderNumber(newNum.data.toString());
                                                onFieldBlur('WORK_ORDER_NUMBER');
                                            }
                                        }}>
                                        <Autorenew fontSize='inherit' />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <ClientFacilityRoofPlanPicker
                                disabled={isPartialEdit}
                                fieldErrors={fieldErrors}
                                setFacility={setFacility}
                                roofProfile={roofProfile}
                                setRoofProfile={setRoofProfile}
                                setFormChanged={setFormChanged}
                                onFieldBlur={onFieldBlur}
                                facility={facility}
                                client={client}
                                setClient={setClient}
                            />
                        </Grid>
                        <Grid item container direction='row' spacing={8} wrap='nowrap'>
                            <Grid item xs={4}>
                                <FormInput
                                    value={calledInBy}
                                    onChange={(event) => {
                                        setCalledInBy(event.target.value);
                                        setFormChanged(true);
                                    }}
                                    onBlur={() => { }}
                                    label={isCapitalProject ? 'Contact' : 'Reported By'}
                                    name='reportedBy'
                                    fullWidth
                                    disabled={isPartialEdit}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {getDivisionSelect}
                            </Grid>
                            <Grid item xs={4}>
                                <FormInput
                                    value={poNumber}
                                    onChange={(event) => {
                                        setPoNumber(event.target.value);
                                        setFormChanged(true);
                                    }}
                                    onBlur={() => { }}
                                    label='PO #'
                                    name='poNumber'
                                    fullWidth
                                    disabled={isPartialEdit}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={8} wrap='nowrap'>
                            <Grid item container direction='row' alignItems='center' spacing={1} xs={4}>
                                <Grid item xs={11}>
                                    {getWorkOrderTypeSelect}
                                </Grid>
                                <AuthenticatedComponent
                                    requiredPermissions={['create:workOrderTypes']}
                                    component={
                                        <Grid item xs={1}>
                                            <IconButton
                                                color='primary'
                                                size='large'
                                                sx={{ marginTop: '15px' }}
                                                onClick={() => setTypeDialogOpen(true)}
                                                disabled={isPartialEdit}>
                                                <AddCircle fontSize='inherit' />
                                            </IconButton>
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Status</FormLabel>
                                    <IEntityAutocomplete
                                        options={workOrderStatuses?.pageResults}
                                        onChange={(_e, value) => {
                                            setStatus(value);
                                            setFormChanged(true);
                                        }}
                                        value={status}
                                        getOptionLabel={(option: WorkOrderStatusDto) => option.name}
                                        isLoading={statusesLoading}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container direction='row' alignItems='center' spacing={1} xs={4} wrap={'nowrap'}>
                                <Grid item xs={11}>
                                    {getDispatchIdSelect}
                                </Grid>
                                <AuthenticatedComponent
                                    requiredPermissions={['create:dispatches']}
                                    component={
                                        <Grid item xs={1}>
                                            <IconButton
                                                color='primary'
                                                size='large'
                                                sx={{ marginTop: '15px' }}
                                                disabled={isPartialEdit}
                                                onClick={() => {
                                                    setIsContractorDialogOpen(true);
                                                }}>
                                                <AddCircle fontSize='inherit' />
                                            </IconButton>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={8} wrap='nowrap'>
                            <Grid item xs={isCapitalProject ? 4 : 3}>
                                <DateTimePicker
                                    label={isCapitalProject ? 'Completion Date' : 'Dispatch Date'}
                                    value={dispatchDate}
                                    onChange={(value) => {
                                        setDispatchDate(value);
                                        setFormChanged(true);
                                    }}
                                    dateOnly={true}
                                    disabled={isPartialEdit}
                                />
                            </Grid>
                            {!isCapitalProject ? <>
                                <Grid item xs={3}>
                                    <DateTimePicker
                                        label='Work Start Date'
                                        value={workStartDate}
                                        onChange={(value) => {
                                            setWorkStartDate(value);
                                            setFormChanged(true);
                                        }}
                                        dateOnly={true}
                                        disabled={isPartialEdit}
                                        error={fieldErrors.START_DATE !== ''}
                                        errorText={fieldErrors.START_DATE}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateTimePicker
                                        label='Work End Date'
                                        value={workEndDate}
                                        onChange={(value) => {
                                            setWorkEndDate(value);
                                            setFormChanged(true);
                                        }}
                                        dateOnly={true}
                                        disabled={isPartialEdit}
                                        error={fieldErrors.END_DATE !== ''}
                                        errorText={fieldErrors.END_DATE}
                                    />
                                </Grid>
                            </> :
                                <Grid item xs={4}>
                                    <FormInput
                                        value={roofAreas}
                                        onChange={(event) => {
                                            setRoofAreas(event.target.value);
                                            setFormChanged(true);
                                        }}
                                        onBlur={() => { }}
                                        label='Roof Areas'
                                        name='roofAreas'
                                        fullWidth
                                        disabled={isPartialEdit}
                                    />
                                </Grid>}
                            <Grid item xs={isCapitalProject ? 4 : 3}>
                                <DateTimePicker
                                    label='Renewal Date'
                                    value={renewalDate}
                                    onChange={(value) => {
                                        setRenewalDate(value);
                                        setFormChanged(true);
                                    }}
                                    dateOnly={true}
                                    disabled={isPartialEdit}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={8} wrap='nowrap'>
                            <AuthenticatedComponent
                                requiredPermissions={['read:workOrderCosts']}
                                component={(
                                    <Grid item xs={4}>
                                        <FormCurrencyInput
                                            value={proposedCost}
                                            onChange={(values) => {
                                                setProposedCost(values.value);
                                                setFormChanged(true);
                                            }}
                                            onBlur={onFieldBlur('PROPOSED_COST')}
                                            label='Proposed Cost'
                                            name='proposedCost'
                                            error={fieldErrors.PROPOSED_COST !== ''}
                                            errorText={fieldErrors.PROPOSED_COST}
                                            fullWidth
                                            disabled={!isWorkOrderProposal || isPartialEdit || !userHasPermission('edit:workOrderCosts')}
                                        />
                                    </Grid>
                                )}
                            />
                            <AuthenticatedComponent
                                requiredPermissions={['read:workOrderCosts']}
                                component={(
                                    <Grid item xs={4}>
                                        <FormCurrencyInput
                                            value={cost}
                                            onChange={(values) => {
                                                setCost(values.value);
                                                setFormChanged(true);
                                            }}
                                            onBlur={onFieldBlur('COST')}
                                            label='Cost'
                                            name='cost'
                                            error={fieldErrors.COST !== ''}
                                            errorText={fieldErrors.COST}
                                            fullWidth
                                            disabled={isPartialEdit || !userHasPermission('edit:workOrderCosts')}
                                        />
                                    </Grid>
                                )}
                            />
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Client Type</FormLabel>
                                    <Select value={clientType} onChange={(e => {
                                        setClientType(e.target.value);
                                        if (e.target.value === 'client') {
                                            setBilledToClient(client);
                                            setBilledToContractor(undefined);
                                        } else if (e.target.value === 'contractor') {
                                            setBilledToContractor(contractor);
                                            setBilledToClient(undefined);
                                        } else {
                                            setBilledToClient(undefined);
                                            setBilledToContractor(undefined);
                                        }
                                    })}>
                                        <MenuItem value=''>None</MenuItem>
                                        <MenuItem value='client'>Client</MenuItem>
                                        <MenuItem value='contractor'>Contractor</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {clientType && (isBilledToClient ? (
                                <Grid item xs={4}>
                                    <FormControl fullWidth disabled={isPartialEdit}>
                                        <FormLabel>Client</FormLabel>
                                        <Autocomplete
                                            value={billedToClient ?? null}
                                            onChange={(_event, value) => {
                                                setBilledToClient(value);
                                                setBilledToContractor(undefined);
                                                setFormChanged(true);
                                            }}
                                            disabled={isPartialEdit}
                                            open={billedClientToOpen}
                                            onOpen={() => setBilledClientToOpen(true)}
                                            onClose={() => setBilledClientToOpen(false)}
                                            options={clients?.pageResults.filter((option) => option.isActive || option.id === billedToClient?.id) ?? []}
                                            loading={clientsLoading}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            getOptionLabel={(option: ClientDto) => `${option.name}${option.isActive ? '' : ' (Inactive)'}`}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {clientsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            ) : (
                                <Grid item xs={4}>
                                    <FormControl fullWidth disabled={isPartialEdit}>
                                        <FormLabel>Client</FormLabel>
                                        <Autocomplete
                                            value={billedToContractor ?? null}
                                            onChange={(_event, value) => {
                                                setBilledToContractor(value);
                                                setBilledToClient(undefined);
                                                setFormChanged(true);
                                            }}
                                            disabled={isPartialEdit}
                                            open={billedContractorToOpen}
                                            onOpen={() => setBilledContractorToOpen(true)}
                                            onClose={() => setBilledContractorToOpen(false)}
                                            options={contractors?.pageResults.filter((option) => option.isActive || option.id === billedToContractor?.id) ?? []}
                                            loading={contractorsLoading}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            getOptionLabel={(option: ContractorDto) => `${option.name}${option.isActive ? '' : ' (Inactive)'}`}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {contractorsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item container direction='row' spacing={8} wrap='nowrap'>
                            <Grid item xs={12}>
                                <FormInput
                                    value={problemDescription}
                                    onChange={(event) => {
                                        setProblemDescription(event.target.value);
                                        setFormChanged(true);
                                    }}
                                    onBlur={() => { }}
                                    label='Description of Problem'
                                    name='descriptionOfProblem'
                                    fullWidth
                                    disabled={isPartialEdit}
                                    rows={5}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={8} wrap='nowrap'>
                            <Grid item xs={12}>
                                <FormInput
                                    value={summaryWorkPerformed}
                                    onChange={(event) => {
                                        setSummaryWorkPerformed(event.target.value);
                                        setFormChanged(true);
                                    }}
                                    onBlur={() => { }}
                                    label='Summary of Work Performed'
                                    name='summaryWorkPerformed'
                                    fullWidth
                                    disabled={isPartialEdit}
                                    rows={5}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DashboardCard>
            </Grid>
            <Grid item container direction='column' spacing={2}>
                {getWorkOrderNotesSection}
                {getWorkOrderDispatchSection}
                {getRoofMapSection}
                {getWorkOrderDocumentsSection}
                {getReportsSection}
                {getContractorSection}
                {initValues && (
                    <WorkOrderHistoryDialog
                        open={historyDialogOpen}
                        onClose={() => {
                            setHistoryDialogOpen(false);
                        }}
                        workOrderId={initValues.id}
                        workOrderNumber={initValues.woNumber}
                    />
                )}
                <WorkOrderTypeDialog
                    open={typeDialogOpen}
                    onClose={() => {
                        setTypeDialogOpen(false);
                    }}
                    setWorkOrderType={setType}
                    isCapitalProject={isCapitalProject}
                />
                <ContractorForm
                    save={(values) => {
                        createContractor(values);
                        setIsContractorDialogOpen(false);
                    }}
                    open={isContractorDialogOpen}
                    cancel={() => {
                        setIsContractorDialogOpen(false);
                    }}
                    isDialogView={true}
                />
            </Grid>
        </Grid>
    );
};
