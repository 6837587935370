import { Description, FileUpload, OpenInFull } from '@mui/icons-material';
import {
    Autocomplete,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    LinearProgress,
    Radio,
    RadioGroup,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentFolderDto, OrderType, RoofSectionDto, emptyGuid } from '../../../models';
import { useGetSasLinkQuery } from '../../../store/apis/cloud-storage-api';
import { useCreateDocumentMutation, useUpdateDocumentMutation } from '../../../store/apis/document-api';
import { useCreateDocumentFolderMutation, useLazyGetDocumentFoldersQuery } from '../../../store/apis/document-folder-api';
import { useLazyGetFacilityQuery } from '../../../store/apis/facility-api';
import { useGetRoofSectionsQuery } from '../../../store/apis/roof-section-api';
import { useLazyGetWorkOrderQuery } from '../../../store/apis/work-order-api';
import { uploadFileToCloud, useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../util';
import { DEFAULT_VISIBILITY_CONFIG, VisibilityControl } from '../../CommonInputs';
import { ApiError } from '../../core/ApiError';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { FormInput } from '../FormFields';
import { SelectRoofSectionsDialog } from '../SelectRoofSection';
import { StandardDialogHeader } from '../StandardDialogHeader';
import { IUploadDocumentDialogProps } from './types';

type RoofSectionsRadioSelection = 'all' | 'select' | 'none';

export const UploadDocumentDialog: FunctionComponent<IUploadDocumentDialogProps> = (props) => {
    const { open, onClose, workOrderId, facilityId, initValues, edit, workOrderDispatchId, isSimplifiedForm } = props;
    const [visibilityConfig, setVisibilityConfig] = useState(DEFAULT_VISIBILITY_CONFIG);
    const [roofSectionsRadio, setRoofSectionsRadio] = useState<RoofSectionsRadioSelection>('none');
    const [description, setDescription] = useState(initValues ? initValues?.description : '');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [shouldUpload, setShouldUpload] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const fileSelectRef = useRef<null | HTMLInputElement>(null);
    const [getFacility, { data: facilityData }] = useLazyGetFacilityQuery();
    const [getWorkOrder, { data: workOrderData }] = useLazyGetWorkOrderQuery();
    const { data: fileUploadLink, isLoading: isLinkLoading, error, refetch: refetchLink } = useGetSasLinkQuery();
    const { data: roofSectionsData, isLoading: roofSectionsLoading } = useGetRoofSectionsQuery({
        searchText: '',
        sortKey: 'ROOF_NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 250,
        includeInactive: true,
        facilityId: facilityId,
    });
    const [getWorkOrderDocumentFolders, { data: documentFolders, isLoading: isDocumentFoldersLoading }] = useLazyGetDocumentFoldersQuery();
    const [documentType, setDocumentType] = useState(initValues ? initValues?.documentType : '');

    const [linkRefreshed, setLinkRefreshed] = useState(false);

    const [createDocument, { isLoading: saveLoading, isSuccess: saveSuccess, reset: resetSave }] = useCreateDocumentMutation();
    const [createFolder, { isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate, data: newFolder }] = useCreateDocumentFolderMutation();
    useFailedCreateSnackbar('folder', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Folder', isCreateSuccess, () => {
        setDocumentFolder(newFolder ?? null);
        resetCreate();
    });

    const [updateDocument] = useUpdateDocumentMutation();
    const [errorText, setErrorText] = useState('');
    const [roofSections, setRoofSections] = useState<RoofSectionDto[]>(initValues?.roofSections ? initValues.roofSections.map((x) => x.roofSection!) : []);

    const [isSelectRoofSectionsDialogOpen, setIsSelectRoofSectionsDialogOpen] = useState(false);
    const [isRoofSectionOpen, setIsRoofSectionOpen] = useState(false);

    const [fieldErrors, setFieldErrors] = useState({
        DESCRIPTION: '',
        ROOF_SECTIONS: '',
        DOCUMENT_FOLDER: '',
    });

    const [changed, setFormChanged] = useState(false);

    const [documentFolder, setDocumentFolder] = useState<DocumentFolderDto | null>(initValues?.documentFolder ?? null);

    const isCapitalProject = useMemo(() => {
        if (workOrderData) {
            return workOrderData.orderType === OrderType.CapitalProject;
        }
        return false;
    }, [workOrderData]);

    useEffect(() => {
        if (workOrderId && isCapitalProject && !documentFolders) {
            getWorkOrderDocumentFolders({
                workOrderId: workOrderId,
                searchText: '',
                sortKey: 'NAME',
                sortAsc: true,
                page: 0,
                pageSize: 250,
                includeInactive: false,
            });
        }
    }, [documentFolders, facilityId, getWorkOrderDocumentFolders, isCapitalProject, workOrderId]);

    useEffect(() => {
        if (facilityId) {
            getFacility(facilityId);
        }
    }, [facilityId, getFacility]);

    useEffect(() => {
        if (workOrderId || initValues?.workOrderId) {
            getWorkOrder(workOrderId ?? initValues?.workOrderId ?? '');
        }
    }, [getWorkOrder, initValues?.workOrderId, workOrderId]);

    const isWorkOrderDocument = useMemo(() => {
        if (!!workOrderId || !!initValues?.workOrderId) {
            if (workOrderData) {
                return workOrderData.orderType === OrderType.WorkOrder;
            }
        }
        return false;
    }, [initValues?.workOrderId, workOrderData, workOrderId]);

    useEffect(() => {
        if (edit) {
            if (initValues?.roofSections) {
                if (initValues.roofSections.length === roofSectionsData?.pageResults.length) {
                    setRoofSectionsRadio('all');
                } else {
                    setRoofSectionsRadio(initValues?.roofSections.length > 0 ? 'select' : 'none');
                }
            }
        }
    }, [setRoofSections, initValues, edit, roofSectionsData, visibilityConfig]);

    useEffect(() => {
        setDescription(initValues?.description ?? '');
        setVisibilityConfig({
            isVisibleToClients: !!initValues?.isVisibleToClients,
            isVisibleToEmployees: !!initValues?.isVisibleToEmployees
        });
    }, [initValues]);

    useEffect(() => {
        setRoofSections(initValues?.roofSections?.map((x) => x.roofSection!) ?? []);
    }, [open, initValues]);

    useEffect(() => {
        setDocumentType(initValues?.documentType ?? '');
    }, [initValues]);

    const startFileUploadCallback = useCallback(async () => {
        setIsUploading(true);

        const response = await uploadFileToCloud(fileUploadLink!.link, selectedFile!);
        if (response.isSuccessful) {
            createDocument({
                id: emptyGuid,
                clientId: workOrderData?.facility?.clientId ?? facilityData?.clientId ?? emptyGuid,
                facilityId: facilityId ?? emptyGuid,
                isActive: true,
                displayName: selectedFile!.name,
                sourceName: response.fileName!,
                isVisibleToClients: visibilityConfig.isVisibleToClients,
                isVisibleToEmployees: visibilityConfig.isVisibleToEmployees,
                visibility: 'computed', // The value here does not actually matter because it is a computed field in the back-end
                workOrderId: workOrderId,
                description: description,
                roofSections: roofSections.map((x) => {
                    return {
                        roofSectionId: x.id,
                    };
                }),
                workOrderDispatchId: workOrderDispatchId,
                documentFolderId: documentFolder?.id,
                documentType: documentType,
            });
            setSelectedFile(null);
            if (fileSelectRef.current) {
                fileSelectRef.current.value = '';
            }
        } else {
            setErrorText('Error uploading. Please try again.');
        }
        setLinkRefreshed(false);
        setShouldUpload(false);
        setIsUploading(false);
    }, [fileUploadLink, selectedFile, createDocument, workOrderData?.facility?.clientId, facilityData?.clientId, facilityId, visibilityConfig.isVisibleToClients, visibilityConfig.isVisibleToEmployees, workOrderId, description, documentType, roofSections, workOrderDispatchId, documentFolder?.id]);

    useEffect(() => {
        if (fileUploadLink && (selectedFile || edit) && shouldUpload && !isUploading) {
            if (!linkRefreshed) {
                refetchLink(); // to prevent link expiration
                setLinkRefreshed(true);
            } else if (!isLinkLoading) {
                startFileUploadCallback();
            }
        }
        if (saveSuccess) {
            setErrorText('');
            setDescription('');
            setVisibilityConfig(DEFAULT_VISIBILITY_CONFIG);
            setRoofSectionsRadio('none');
            setSelectedFile(null);
            resetSave();
            onClose();
        }
    }, [
        edit,
        fileUploadLink,
        selectedFile,
        shouldUpload,
        isUploading,
        startFileUploadCallback,
        saveSuccess,
        onClose,
        resetSave,
        isLinkLoading,
        linkRefreshed,
        refetchLink,
    ]);

    const saveDocumentFolder = useCallback((formValue: string) => {
        createFolder({
            id: emptyGuid,
            name: formValue,
            isActive: true,
            workOrderId: workOrderId,
        });
    }, [createFolder, workOrderId]);

    const openFilePicker = useCallback((_e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (fileSelectRef.current) {
            fileSelectRef.current.click();
        }
    }, []);

    const onFileSelected = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files?.length > 0) {
            const file = e.target.files.item(0)!;
            setSelectedFile(file);
        }
    }, []);

    const handleDescriptionChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setDescription(event.target.value);
    }, []);

    const validate = useCallback(
        (fieldName: string) => {
            let isValid = false;

            if (fieldName === 'DESCRIPTION') {
                if (description) {
                    fieldErrors.DESCRIPTION = '';
                    isValid = true;
                } else {
                    fieldErrors.DESCRIPTION = 'Description is required';
                    isValid = false;
                }
            } else if (fieldName === 'ROOF_SECTIONS') {
                if (roofSections.length > 0 || roofSectionsRadio !== 'select') {
                    fieldErrors.ROOF_SECTIONS = '';
                    isValid = true;
                } else if (roofSectionsData && roofSectionsData.pageResults.length > 0) {
                    fieldErrors.ROOF_SECTIONS = 'Roof sections are required';
                    isValid = false;
                } else {
                    fieldErrors.ROOF_SECTIONS = 'No roof sections defined for this facility';
                    isValid = false;
                }
            } else if (fieldName === 'DOCUMENT_FOLDER') {
                if (isCapitalProject && !documentFolder) {
                    fieldErrors.DOCUMENT_FOLDER = 'Folder is required';
                    isValid = false;
                } else {
                    fieldErrors.DOCUMENT_FOLDER = '';
                    isValid = true;
                }
            }
            setFieldErrors({
                DESCRIPTION: fieldErrors.DESCRIPTION,
                ROOF_SECTIONS: fieldErrors.ROOF_SECTIONS,
                DOCUMENT_FOLDER: fieldErrors.DOCUMENT_FOLDER,
            });
            return isValid;
        },
        [description, documentFolder, fieldErrors, isCapitalProject, roofSections.length, roofSectionsData, roofSectionsRadio]
    );

    const formIsValid = useCallback(() => {
        let isValid = validate('DESCRIPTION');
        isValid = validate('ROOF_SECTIONS') && isValid;
        isValid = validate('DOCUMENT_FOLDER') && isValid;
        return isValid;
    }, [validate]);

    const beforeClose = useCallback(() => {
        setFormChanged(false);
        setErrorText('');
        setFieldErrors({
            DESCRIPTION: '',
            ROOF_SECTIONS: '',
            DOCUMENT_FOLDER: '',
        });
        setDescription('');
        setRoofSectionsRadio('none');
        setRoofSections([]);
        setVisibilityConfig(DEFAULT_VISIBILITY_CONFIG);
        setSelectedFile(null);
        resetSave();
        onClose();
    }, [onClose, resetSave]);

    const onSave = useCallback(() => {
        if (formIsValid()) {
            if (selectedFile) {
                setErrorText('');
                setShouldUpload(true);
                return;
            }
            if (edit) {
                updateDocument({
                    id: initValues ? initValues.id : '',
                    clientId: initValues ? initValues.clientId : '',
                    facilityId: initValues ? initValues.facilityId : '',
                    displayName: initValues ? initValues?.displayName : '',
                    sourceName: initValues ? initValues?.sourceName : '',
                    isActive: initValues ? initValues.isActive : false,
                    description: description,
                    workOrderId: initValues && initValues.workOrderId,
                    roofSections: roofSections.map((x) => {
                        return {
                            roofSectionId: x.id,
                        };
                    }),
                    workOrderDispatchId: workOrderDispatchId,
                    isVisibleToClients: visibilityConfig.isVisibleToClients,
                    isVisibleToEmployees: visibilityConfig.isVisibleToEmployees,
                    visibility: 'Private',
                    documentType: documentType,
                    documentFolderId: documentFolder?.id,
                });
                beforeClose();
            }
            return;
        }
    }, [formIsValid, selectedFile, edit, updateDocument, initValues, description, documentType, roofSections, workOrderDispatchId, visibilityConfig.isVisibleToClients, visibilityConfig.isVisibleToEmployees, documentFolder?.id, beforeClose]);

    const onFieldBlur = useCallback(
        (fieldName: string) => () => {
            validate(fieldName);
        },
        [validate]
    );

    const handleChipDelete = useCallback(
        (roofNumber: any) => {
            setFormChanged(true);
            setRoofSections(roofSections.filter((section) => section.roofNumber !== roofNumber));
        },
        [roofSections]
    );

    const handleConfirmRoofSectionDialog = useCallback(() => {
        setFormChanged(true);
        setIsSelectRoofSectionsDialogOpen(false);
    }, []);

    const handleCancelRoofSectionDialog = useCallback(() => {
        setIsSelectRoofSectionsDialogOpen(false);
    }, []);

    const handleRoofSectionsChange = useCallback(
        (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
            setFormChanged(true);
            setRoofSections([]);
            setRoofSectionsRadio(value as RoofSectionsRadioSelection);
            if (value === 'all') {
                setRoofSections(roofSectionsData?.pageResults ?? []);
            }
        },
        [roofSectionsData?.pageResults]
    );

    if (error) {
        return <ApiError onReloadClick={refetchLink} />;
    }

    return (
        <Dialog onClose={beforeClose} open={open} maxWidth='lg' fullWidth>
            <StandardDialogHeader icon={edit ? <Description sx={{ color: 'white' }} /> : <FileUpload sx={{ color: 'white' }} />} title={edit ? 'Edit Document' : 'Upload New Document'} />
            <DialogContent>
                <Grid container direction='column' spacing={2} sx={{ padding: 2 }}>
                    <Grid item container direction='row' alignItems='center'>
                        <input ref={fileSelectRef} style={{ display: 'none' }} type='file' onChange={onFileSelected} />
                        <Grid item onClick={edit ? undefined : openFilePicker} xs={3}>
                            <Button variant='contained' color='secondary' disabled={initValues && edit ? true : saveLoading} sx={{ marginTop: '12px' }}>
                                Choose File
                            </Button>
                        </Grid>
                        <Grid item xs={9}>
                            <FormInput
                                label='Filename'
                                value={initValues && edit ? initValues.displayName : selectedFile?.name}
                                fullWidth
                                required
                                disabled
                                onChange={() => { }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormInput
                            label='Description'
                            value={description}
                            fullWidth
                            required
                            error={fieldErrors.DESCRIPTION !== ''}
                            errorText={fieldErrors.DESCRIPTION}
                            onChange={handleDescriptionChanged}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    {!isSimplifiedForm && (
                        <>
                            <Grid item container direction='row' alignItems={'center'} spacing={0.5}>
                                <Grid item xs={1}>
                                    <FormControl required>
                                        <FormLabel>Roof #</FormLabel>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl error={fieldErrors.ROOF_SECTIONS !== ''} fullWidth required>
                                        <Grid item container>
                                            <RadioGroup value={roofSectionsRadio} onChange={handleRoofSectionsChange} row>
                                                <FormControlLabel value='none' control={<Radio />} label='None' />
                                                <FormControlLabel value='all' control={<Radio />} label='All Current Roof Sections' />
                                                <FormControlLabel value='select' control={<Radio />} label='Selected Roof Sections' />
                                            </RadioGroup>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid item container xs={2}>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            multiple
                                            filterSelectedOptions
                                            onChange={(_event, newValue: any) => {
                                                if (newValue) {
                                                    setFormChanged(true);
                                                    setRoofSections(newValue);
                                                }
                                            }}
                                            value={roofSections ?? null}
                                            disabled={roofSectionsRadio === 'all' || roofSectionsRadio === 'none'}
                                            onBlur={() => onFieldBlur('ROOF_SECTIONS')}
                                            open={isRoofSectionOpen}
                                            onOpen={() => setIsRoofSectionOpen(true)}
                                            onClose={() => setIsRoofSectionOpen(false)}
                                            options={
                                                roofSectionsData?.pageResults.filter(
                                                    (x) => x.isActive && !roofSections.some((y) => x.roofNumber === y.roofNumber)
                                                ) ?? []
                                            }
                                            getOptionLabel={(option: RoofSectionDto) => `${option.roofNumber}${option.isActive ? '' : ' (Inactive)'}`}
                                            selectOnFocus
                                            disableClearable
                                            handleHomeEndKeys
                                            renderTags={() => null}
                                            onKeyDown={(event: any) => {
                                                if (event.key === 'Enter') {
                                                    event.defaultMuiPrevented = true;
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={fieldErrors.ROOF_SECTIONS !== ''}
                                                    onBlur={onFieldBlur('ROOF_SECTIONS')}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {roofSectionsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IconButton
                                            onClick={() => setIsSelectRoofSectionsDialogOpen(true)}
                                            disabled={roofSectionsRadio === 'all' || roofSectionsRadio === 'none'}>
                                            <OpenInFull sx={{ fontSize: 36 }} />
                                        </IconButton>
                                    </Grid>
                                    {fieldErrors.ROOF_SECTIONS && (
                                        <FormControl error={fieldErrors.ROOF_SECTIONS !== ''}>
                                            <FormHelperText>{fieldErrors.ROOF_SECTIONS}</FormHelperText>
                                        </FormControl>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item container direction='row' spacing={2}>
                                {roofSectionsRadio === 'select' &&
                                    roofSections.map((section) => {
                                        return (
                                            <Grid item>
                                                <Chip
                                                    label={section.roofNumber}
                                                    color='secondary'
                                                    onDelete={() => {
                                                        handleChipDelete(section.roofNumber);
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                            <Grid item container direction='row' spacing={2} wrap='nowrap' xs={12}>
                                <Tooltip title={documentType === 'proposal' ? 'Proposal Documents must be Public' : ''} children={<Grid item xs={isCapitalProject ? 6 : 4}>
                                    <VisibilityControl
                                        value={visibilityConfig}
                                        onValueChange={(v) => {
                                            setFormChanged(true);
                                            setVisibilityConfig(v);
                                        }}
                                        gridProps={{ sx: { width: 260 } }}
                                        isWorkOrderDocument={isWorkOrderDocument}
                                        disabled={documentType === 'proposal'}
                                    />
                                </Grid>} />
                                <Grid item xs={isCapitalProject ? 6 : 4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Document Type</FormLabel>
                                        <Select
                                            value={documentType ?? ''}
                                            onChange={(e => {
                                                const newValue = e.target.value;
                                                if (typeof newValue === 'string') {
                                                    setDocumentType(newValue);
                                                    if (newValue === 'proposal') {
                                                        setVisibilityConfig({
                                                            isVisibleToClients: true,
                                                            isVisibleToEmployees: true
                                                        });
                                                    } else {
                                                        if (isWorkOrderDocument) {
                                                            setVisibilityConfig(DEFAULT_VISIBILITY_CONFIG);
                                                        }
                                                    }
                                                }
                                            })}
                                        >
                                            <MenuItem value=''>None</MenuItem>
                                            <MenuItem value='proposal'>Proposal</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {isCapitalProject && (<Grid item container direction='row'>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth error={fieldErrors.DOCUMENT_FOLDER !== ''} required>
                                            <FormLabel>Folder</FormLabel>
                                            <IEntityAutocomplete
                                                options={documentFolders?.pageResults ?? []}
                                                onChange={(e, value) => { setDocumentFolder(value ?? null); }}
                                                value={documentFolder}
                                                getOptionLabel={(option: DocumentFolderDto) => option?.name ?? ''}
                                                isLoading={isDocumentFoldersLoading}
                                                error={fieldErrors.DOCUMENT_FOLDER !== ''}
                                                quickAddProps={
                                                    {
                                                        requiredPermissions: ['create:documentFolders'],
                                                        objectName: 'Folder',
                                                        handleSave: saveDocumentFolder
                                                    }
                                                }
                                            />
                                            <FormHelperText>{fieldErrors.DOCUMENT_FOLDER}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>)}
                            </Grid>
                        </>
                    )}
                    {(isUploading || saveLoading) && (
                        <Grid item>
                            <LinearProgress />
                        </Grid>
                    )}
                </Grid>
                <SelectRoofSectionsDialog
                    open={isSelectRoofSectionsDialogOpen}
                    onCancel={handleCancelRoofSectionDialog}
                    onConfirm={handleConfirmRoofSectionDialog}
                    setRoofSections={setRoofSections}
                    currentRoofSections={roofSections}
                    isMultipleSelectable={true}
                    facilityId={facilityId}
                />
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <FormHelperText error={errorText !== ''} sx={{ width: '60%' }}>
                    {errorText}
                </FormHelperText>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                    {changed ? 'Cancel' : 'Close'}
                </Button>
                <Button variant='contained' color='primary' onClick={onSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
