import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { BinderPortfolioForm } from '../../Components/Forms/BinderPortfolioForm';
import { BinderPortfolioReportDto } from '../../models/BinderPortfolioReportDto';
import { useGetBPReportQuery, useUpdateBPReportMutation } from '../../store/apis/binder-portfolio-report-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditBinderPortfolioReportView: FunctionComponent = (props) => {
    const { id, clientId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading: clientReportLoading, error: clientReportError, refetch: clientReportRefetch } = useGetBPReportQuery(id!);
    const [updateBPRReport, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateBPReportMutation();

    useFailedUpdateSnackbar('binder portfolio', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Binder portfolio', isUpdateSuccess, resetUpdate);

    const handleSave = (values: BinderPortfolioReportDto) => {
        updateBPRReport(values);
    };

    const handleCancel = () => {
        navigate(`/clientreports/`);
    };

    if (clientReportLoading) {
        return <LoadingIndicator />;
    }

    if (clientReportError) {
        return <ApiError onReloadClick={clientReportRefetch} />;
    }

    return <BinderPortfolioForm save={handleSave} cancel={handleCancel} initValues={data} isEditView clientId={clientId} />;
};
