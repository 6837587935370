import { serverApi } from '..';
import { AttachmentMethodDto } from '../../models';
import { buildAttachmentMethodQueryRoute } from '../../util';

const attachmentMethodApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getAttachmentMethods: builder.query<AttachmentMethodDto[], AttachmentMethodQueryParams>({
            query: (params) => buildAttachmentMethodQueryRoute('attachmentmethod', params),
            providesTags: [{ type: 'Attachment Method' }]
        })
    })
});

export const {
    useGetAttachmentMethodsQuery
} = attachmentMethodApi;

export interface AttachmentMethodQueryParams {
    layerType?: string;
    includeInactive?: boolean;
}