import { AddCircle, Delete, Folder, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import AuthenticatedComponent from '../auth';
import { ApiError } from '../Components/core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../Components/core/DataTable';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import { NavBreadcrumbs } from '../Components/core/NavBreadcrumbs';
import { DefaultDocumentFolderDto } from '../models';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../util/customHooks';
import { useArchiveDefaultDocumentFolderMutation, useGetDefaultDocumentFoldersQuery, useUnarchiveDefaultDocumentFolderMutation } from '../store/apis/default-document-folder-api';
import { AddDefaultDocumentFolderDialog } from '../Components/Forms/DefaultDocumentFolderForm';

export const DefaultDocumentFoldersListView: FunctionComponent = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [selectedDefaultFolder, setSelectedDefaultFolder] = useState<DefaultDocumentFolderDto | undefined | null>();
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data, isLoading, error, refetch } = useGetDefaultDocumentFoldersQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
    });
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<DefaultDocumentFolderDto>[] = [
        { key: 'name', label: 'Name', sortKey: 'NAME', fieldMapper: (row) => (row.isRequired ? `${row.name} (Required)` : row.name) },
    ];
    const [archiveDefaultDocumentFolder, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveDefaultDocumentFolderMutation();
    const [unarchiveDefaultDocumentFolder, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveDefaultDocumentFolderMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

    const getEntityName = useCallback(() => {
        return selected.length === 1 ? 'Default Folder' : 'Default Folders';
    }, [selected.length]);

    const handleAddClose = useCallback(() => {
        setAddDialogOpen(false);
        setSelectedDefaultFolder(null);
    }, []);

    const handleActionRequestCompletion = useCallback((resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    }, []);

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => { handleActionRequestCompletion(resetArchive); });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => { handleActionRequestCompletion(resetUnarchive); });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback((afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    }, []);

    const handleCreateNew = useCallback(() => {
        setAddDialogOpen(true);
    }, []);

    const handleMenuEdit = useCallback(() => {
        const selectedDefaultDocumentFolderId = data?.pageResults[selected[0]].id;
        if (selectedDefaultDocumentFolderId) {
            setSelectedDefaultFolder(data?.pageResults.find((x) => x.id === selectedDefaultDocumentFolderId) ?? null);
            setAddDialogOpen(true);
        }
    }, [data?.pageResults, selected]);

    const handleTableEdit = useCallback((id: string) => {
        setSelectedDefaultFolder(data?.pageResults.find((x) => x.id === id) ?? null);
        setAddDialogOpen(true);
    }, [data?.pageResults]);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveDefaultDocumentFolder(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [data?.pageResults, selected, unarchiveDefaultDocumentFolder]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveDefaultDocumentFolder(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [archiveDefaultDocumentFolder, data?.pageResults, selected]);

    const canSetActive = useMemo(() => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useMemo(() => {
        return selected.some((index) => data?.pageResults[index].isActive && data?.pageResults[index].isRequired === false);
    }, [data?.pageResults, selected]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start' justifyContent={'space-between'} maxWidth={'100% !important'}>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Folder /> Default Capital Project Folders
                    </Typography>
                    <NavBreadcrumbs links={[{ label: 'Home', navLink: '/' }]} currentPageLabel='Default Capital Project Folders' />
                </Grid>
                <Grid item container direction='row' alignItems='center' xs={6} justifyContent={'end'}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:defaultDocumentFolders']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:defaultDocumentFolders']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:defaultDocumentFolders', 'edit:defaultDocumentFolders', 'delete:defaultDocumentFolders']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:defaultDocumentFolders']}
                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:defaultDocumentFolders']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:defaultDocumentFolders']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        } />
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item maxWidth={'100% !important'}>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='NAME'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:defaultDocumentFolders'] }}
                    onEdit={handleTableEdit}
                />
            </Grid>
            <AddDefaultDocumentFolderDialog
                open={addDialogOpen}
                onClose={handleAddClose}
                initValues={selectedDefaultFolder}
            />
        </Grid>
    );
};
