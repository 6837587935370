import { Person } from '@mui/icons-material';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    FormHelperText,
    FormLabel, Grid, MenuItem,
    Select,
    SelectChangeEvent, Typography
} from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { FormInput } from '../../Components/Forms/FormFields';
import { emptyGuid } from '../../models';
import { ContractorDto } from '../../models/ContractorDto';
import { useCreateContactMutation } from '../../store/apis/contact-api';
import { useGetContractorsQuery } from '../../store/apis/contractor-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';
import { isEmail } from '../../util/isEmail';
import { IAddContactDialogProps } from './types';
import { IEntityAutocomplete } from '../../Components/core/IEntityAutocomplete';

export const AddContactDialog: FunctionComponent<IAddContactDialogProps> = (props) => {
    const { contractor, open, onClose } = props;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [type, setType] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedContractor, setContractor] = useState(contractor ?? null);

    const [fieldErrors, setFieldErrors] = useState({
        FIRST_NAME: '',
        LAST_NAME: '',
        TYPE: '',
        EMAIL: '',
        CONTRACTOR: '',
    });

    const [createContact, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateContactMutation();
    const { data: contractors, isLoading: contractorsLoading } = useGetContractorsQuery({ pageSize: 100000, includeNonStandard: false });

    const clearForm = useCallback(() => {
        setFirstName('');
        setLastName('');
        setType('');
        setEmail('');
        setPhone('');
        setContractor(contractor ?? null);
    }, [contractor]);

    useFailedCreateSnackbar('contact', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Contact', isCreateSuccess, () => {
        clearForm();
        onClose(data);
    });

    const handleFirstNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    }, []);

    const handleLastNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    }, []);

    const handleTypeChange = useCallback((event: SelectChangeEvent<string>) => {
        setType(event.target.value);
    }, []);

    const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const handlePhoneChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    }, []);

    const validate = useCallback(
        (fieldName: string) => {
            let isValid = false;
            if (fieldName === 'FIRST_NAME') {
                if (firstName) {
                    fieldErrors.FIRST_NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.FIRST_NAME = 'First name is required';
                    isValid = false;
                }
            } else if (fieldName === 'LAST_NAME') {
                if (lastName) {
                    fieldErrors.LAST_NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.LAST_NAME = 'Last name is required';
                    isValid = false;
                }
            } else if (fieldName === 'TYPE') {
                if (type) {
                    fieldErrors.TYPE = '';
                    isValid = true;
                } else {
                    fieldErrors.TYPE = 'Type is required';
                    isValid = false;
                }
            } else if (fieldName === 'EMAIL') {
                if (!email || isEmail(email)) {
                    fieldErrors.EMAIL = '';
                    isValid = true;
                } else {
                    fieldErrors.EMAIL = 'Email is invalid';
                    isValid = false;
                }
            } else if (fieldName === 'CONTRACTOR') {
                if (selectedContractor) {
                    fieldErrors.CONTRACTOR = '';
                    isValid = true;
                } else {
                    fieldErrors.CONTRACTOR = 'Contractor is required';
                    isValid = false;
                }
            }
            setFieldErrors({
                FIRST_NAME: fieldErrors.FIRST_NAME,
                LAST_NAME: fieldErrors.LAST_NAME,
                TYPE: fieldErrors.TYPE,
                EMAIL: fieldErrors.EMAIL,
                CONTRACTOR: fieldErrors.CONTRACTOR,
            });
            return isValid;
        },
        [email, fieldErrors, firstName, lastName, type, selectedContractor]
    );

    const onFieldBlur = useCallback(
        (fieldName: string) => () => {
            validate(fieldName);
        },
        [validate]
    );

    const formIsValid = useCallback(() => {
        let isValid = validate('FIRST_NAME');
        isValid = validate('LAST_NAME') && isValid;
        isValid = validate('TYPE') && isValid;
        isValid = validate('EMAIL') && isValid;
        return isValid;
    }, [validate]);

    const handleSave = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            if (formIsValid()) {
                createContact({
                    id: emptyGuid,
                    isActive: true,
                    firstName: firstName,
                    lastName: lastName,
                    type: type,
                    email: email,
                    phoneNumber: phone,
                    contractorId: selectedContractor?.id,
                });
            }
        },
        [createContact, email, firstName, formIsValid, lastName, phone, selectedContractor, type]
    );

    return (
        <Dialog maxWidth='xl' fullWidth open={open} onClose={onClose}>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <Person />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        New Contractor Contact
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid component='form' container direction='column' spacing={4} autoComplete='off' onSubmit={handleSave} sx={{ paddingTop: '20px' }}>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormControl error={fieldErrors.CONTRACTOR !== ''} fullWidth required>
                                <FormLabel>Dispatch ID</FormLabel>
                                <IEntityAutocomplete
                                    options={contractors?.pageResults}
                                    onChange={(_e, value) => { setContractor(value); }}
                                    value={selectedContractor}
                                    getOptionLabel={(option: ContractorDto) => `${option.dispatchId} - ${option.name}`}
                                    isLoading={contractorsLoading}
                                    error={fieldErrors.CONTRACTOR !== ''}
                                    validate={() => {
                                        onFieldBlur('CONTRACTOR');
                                    }}
                                />
                                <FormHelperText>{fieldErrors.CONTRACTOR}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={4} />
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                value={firstName}
                                onChange={handleFirstNameChange}
                                onBlur={onFieldBlur('FIRST_NAME')}
                                label='First Name'
                                name='firstName'
                                error={fieldErrors.FIRST_NAME !== ''}
                                errorText={fieldErrors.FIRST_NAME}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={lastName}
                                onChange={handleLastNameChange}
                                onBlur={onFieldBlur('LAST_NAME')}
                                label='Last Name'
                                name='lastName'
                                error={fieldErrors.LAST_NAME !== ''}
                                errorText={fieldErrors.LAST_NAME}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth error={fieldErrors.TYPE !== ''} required>
                                <FormLabel>Type</FormLabel>
                                <Select value={type} onChange={handleTypeChange} onBlur={onFieldBlur('TYPE')}>
                                    <MenuItem key='none' value=''>
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem key='Technician' value='Technician'>
                                        Technician
                                    </MenuItem>
                                    <MenuItem key='Other' value='Other'>
                                        Other
                                    </MenuItem>
                                </Select>
                                <FormHelperText>{fieldErrors.TYPE}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                value={email}
                                onChange={handleEmailChange}
                                onBlur={onFieldBlur('EMAIL')}
                                label='Email'
                                name='email'
                                error={fieldErrors.EMAIL !== ''}
                                errorText={fieldErrors.EMAIL}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput value={phone} onChange={handlePhoneChange} label='Phone' name='phone' fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container direction='row' spacing={2} xs='auto'>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={handleSave}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
