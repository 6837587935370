import { Email, Error } from '@mui/icons-material';
import { Alert, Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormInput } from '../../Components/Forms';
import { ConfirmDialog } from '../../Components/core/ConfirmDialog';
import AuthenticatedComponent from '../../auth';
import { DispatchDto } from '../../models/DispatchDto';
import { useRequestDispatchNeedsAttentionMutation } from '../../store/apis/dispatch-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util';

export interface IDispatchNeedsAttentionSectionProps {
    dispatch: DispatchDto;
    isLocked: boolean;
}

export const DispatchNeedsAttentionSection: FC<IDispatchNeedsAttentionSectionProps> = ({ dispatch, isLocked }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [needsAttentionInstructions, setNeedsAttentionInstructions] = useState(dispatch.needsAttentionInstructions ?? '');
    const [needsAttentionError, setNeedsAttentionError] = useState('');
    const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(false);
    
    const [requestNeedsAttention, { isSuccess: needsAttentionIsSuccess, isError: isCreateNeedsAttentionError, reset: resetNeedsAttentionInstructionsCreate }] = useRequestDispatchNeedsAttentionMutation();
    useFailedCreateSnackbar('needs attention instructions', isCreateNeedsAttentionError, resetNeedsAttentionInstructionsCreate);
    useSuccessfulCreateSnackbar('Needs attention instructions', needsAttentionIsSuccess, resetNeedsAttentionInstructionsCreate);

    const handleNeedsAttentionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNeedsAttentionInstructions(event.target.value);
    }, []);

    const handleNeedsAttentionAction = useCallback((sendEmail: boolean) => {
        requestNeedsAttention({ id: dispatch.id, instructions: needsAttentionInstructions, sendEmail });
    }, [needsAttentionInstructions, requestNeedsAttention, dispatch.id]);

    const handleNeedsAttentionClick = useCallback(() => {
        if (needsAttentionInstructions && needsAttentionInstructions !== '') {
            setNeedsAttentionError('');
            setSendEmailDialogOpen(true);

        } else {
            setNeedsAttentionError('Needs Attention Instructions are required before sending work order back to contractor.');
        }
    }, [needsAttentionInstructions]);

    const isDispatchInNeedsAttentionStatus = useMemo(() => dispatch.status === 'Needs Attention', [dispatch.status]);

    return (
        <Grid item container direction='row' justifyContent={'space-between'} spacing={2}>
            <AuthenticatedComponent
                requiredPermissions={['edit:dispatches']}
                component={
                    <>
                        <Grid item xs={10}>
                            <FormInput
                                label='Needs Attention Instructions'
                                value={needsAttentionInstructions}
                                fullWidth
                                required
                                error={needsAttentionError !== ''}
                                errorText={needsAttentionError}
                                onChange={handleNeedsAttentionChange}
                                rows={3}
                                disabled={isLocked}
                            />
                        </Grid>
                        <Grid item xs={2} marginTop={3}>
                            <Button startIcon={<Error />} variant='contained' color='error' onClick={handleNeedsAttentionClick} disabled={isLocked}>
                                {isDispatchInNeedsAttentionStatus ? 'Update Instructions' : 'Needs Attention' }
                            </Button>
                        </Grid>
                    </>
                }
                unauthorizedComponent={
                    dispatch.status === 'Needs Attention' && (
                        <AuthenticatedComponent
                            requiredPermissions={['edit:dispatchesLimited']}
                            component={
                                <Grid item xs={12}>
                                    <Alert severity={'error'} icon={<Error style={{ color: '#CC0000' }} />} style={{ backgroundColor: '#F9E4E4' }}>
                                        {dispatch.needsAttentionInstructions}
                                    </Alert>
                                </Grid>
                            }
                        />
                    )
                }
            />
            <ConfirmDialog
                title="Send Email Update?"
                titleIcon={<Email />}
                open={sendEmailDialogOpen}
                content='Do you want to send an email update to the contractor?'
                onClose={() => {
                    setSendEmailDialogOpen(false);
                    handleNeedsAttentionAction(false);
                }}
                onConfirm={() => {
                    setSendEmailDialogOpen(false);
                    enqueueSnackbar('Email update sent to contractor.');
                    handleNeedsAttentionAction(true);
                }}
                cancelText='No'

            />
        </Grid>
    );
};
