import { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import { Assignment } from '@mui/icons-material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { getShortenedString, IRoofLeakInfoCard } from './types';

export const RoofLeakInfoCard: FunctionComponent<IRoofLeakInfoCard> = props => {
    const { roofLeak } = props;
    const navigate = useNavigate();


    if (!roofLeak) {
        return <></>;
    }

    return (
        <Grid container direction='column' sx={{ width: '300px', padding: '20px' }}>
            <Grid container alignItems='center' sx={{ paddingBottom: '10px' }}>
                <Grid item sx={{ paddingRight: '10px' }}>
                    <Assignment />
                </Grid>
                <Grid item>
                    <Typography
                        variant='h6'
                        color='#266490'
                        onClick={() => {
                            navigate(`/workorders/edit/${roofLeak?.workOrderDispatch?.workOrderId}`);
                        }}
                        sx={{ cursor: 'pointer' }}>Work Order #{roofLeak?.workOrderDispatch?.woNumber ?? 'N/A'}</Typography>
                </Grid>
            </Grid>
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <Typography><strong>Reported By:</strong> {roofLeak?.workOrderDispatch?.reportedBy?.displayName ?? 'N/A'}</Typography>
                </Grid>
                <Grid item>
                    <Typography><strong>Start/End:</strong> {roofLeak?.workOrderDispatch?.arrivalDateTime ? format(new Date(roofLeak?.workOrderDispatch?.arrivalDateTime), 'M/d/yyyy') : 'N/A'} - {roofLeak?.workOrderDispatch?.departureDateTime ? format(new Date(roofLeak?.workOrderDispatch?.departureDateTime), 'M/d/yyyy') : 'N/A'}</Typography>
                </Grid>
                <Grid item>
                    <Typography><strong>Type:</strong> {roofLeak?.workOrderDispatch?.workOrder?.type?.name ?? 'N/A'}</Typography>
                </Grid>
                <Grid item container direction='column'>
                    <Grid item>
                        <Typography><strong>Description of Problem:</strong></Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ maxWidth: '300px' }}>
                            {getShortenedString(roofLeak?.workOrderDispatch?.closeOutReport?.descriptionOfProblem)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='column'>
                    <Grid item>
                        <Typography><strong>Summary of Work Performed:</strong></Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ maxWidth: '300px' }}>
                            {getShortenedString(roofLeak?.workOrderDispatch?.closeOutReport?.summaryOfWorkPerformed)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
