/* eslint-disable @typescript-eslint/no-unused-vars */
import { AttachMoney } from '@mui/icons-material';
import { TableCell, TableFooter } from '@mui/material';
import _ from 'lodash';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { BudgetLineItemDto } from '../../../models';
import { useGetBudgetLineItemsForFacilityQuery } from '../../../store/apis/facility-api';
import { formatCurrency, ColorSwatch, sortByRoofNumber } from '../../../util';
import { ApiError } from '../../core/ApiError';
import LoadingIndicator from '../../core/LoadingIndicator';
import { DataTableColumn, LocalDataTable } from '../../core/LocalDataTable';
import { DashboardCard } from '../DashboardCard';

export const FacilityBudgetLineItemsCard: FunctionComponent<{ facilityId: string }> = (props) => {
    const { facilityId } = props;
    const { data: facilityBudgetLineItems, isLoading, error, refetch } = useGetBudgetLineItemsForFacilityQuery(facilityId);
    const [startYear, setStartYear] = useState<number | null>(null);

    const getYearValue = useCallback((startYear: number | null, idx: number, budgetLineItem: BudgetLineItemDto) => {
        if (startYear) {
            if (budgetLineItem?.budgetLineItemYears) {
                const year = budgetLineItem.budgetLineItemYears.find((x) => x.year === startYear + idx);
                if (year) {
                    return year.amount ?? 0;
                }
            }
        }
        return 0;
    }, []);

    useEffect(() => {
        var currentYear = new Date().getFullYear();
        if (facilityBudgetLineItems) {
            var yearsArray = facilityBudgetLineItems.map((x: BudgetLineItemDto) => (x.startYear ? new Date(x.startYear).getFullYear() : currentYear));
            var earliestYear = Math.min.apply(null, yearsArray);
            if (yearsArray.length > 0) {
                setStartYear(earliestYear);
            } else {
                setStartYear(currentYear);
            }
        }
    }, [facilityBudgetLineItems, facilityId]);

    const tableColumns = useMemo(() => {
        let tableColumns: DataTableColumn<BudgetLineItemDto>[] = [
            { key: 'roofNumber', label: 'Roof #', sortKey: 'ROOF_NUMBER', fieldMapper: (row) => row.roofSection?.roofNumber },
            {
                key: 'squareFootage',
                label: 'Sq Ft',
                align: 'right',
                sortKey: 'SQUARE_FOOTAGE',
                fieldMapper: (row) => row.roofSection?.squareFootage.toLocaleString(),
            },
            {
                key: 'assetValue',
                label: 'Asset Value',
                align: 'right',
                sortKey: 'ASSET_VALUE',
                fieldMapper: (row) => formatCurrency(row.roofSection?.assetValue),
            },
            { key: 'warranty', label: 'Warranty', sortKey: 'WARRANTY', fieldMapper: (row) => row.roofSection?.warrantyStatus },
            { key: 'roofSystem', label: 'Roof System', sortKey: 'ROOF_SYSTEM', fieldMapper: (row) => row.roofSection?.roofSystem?.name },
            { key: 'condition', label: 'Condition', sortKey: 'Condition', fieldMapper: (row) => <ColorSwatch condition={row.roofSection?.roofCondition} /> },
            {
                key: 'recommendedAction',
                label: 'Recommended Action',
                sortKey: 'RECOMMENDED_ACTION',
                fieldMapper: (row) => row.roofSection?.roofCondition?.recommendedAction,
            }
        ];

        for (let i = 0; i < 5; i++) {
            var isAtLeastOneValueForYear = startYear && facilityBudgetLineItems?.some((x) => x.budgetLineItemYears?.some((y) => y.year === startYear + i && y.amount !== null));
            if (isAtLeastOneValueForYear) {
                tableColumns.push({
                    key: `year${i + 1}Amount`,
                    label: `${startYear ? startYear + i : ''}`,
                    align: 'right',
                    sortKey: `YEAR_${i + 1}_AMOUNT`,
                    fieldMapper: (row) => formatCurrency(getYearValue(startYear, i, row), true),
                });   
            }
        }

        tableColumns.push({ key: 'total', label: 'Total', align: 'right', sortKey: 'TOTAL', fieldMapper: (row) => (row.total ? formatCurrency(row.total) : '') });

        return tableColumns;
    }, [facilityBudgetLineItems, getYearValue, startYear]);

    const getTableFooter = useCallback(
        (data: BudgetLineItemDto[]) => {
            var visibleYears = [];
            for (let i = 0; i < 5; i++) {
                var isAtLeastOneValueForYear = startYear && data.some((x) => x.budgetLineItemYears?.some((y) => y.year === startYear + i && y.amount !== null));
                if (isAtLeastOneValueForYear) {
                    visibleYears.push(i);
                }
            }
            return (
                <TableFooter sx={{ backgroundColor: '#266490' }}>
                    <TableCell sx={{ color: '#ffffff' }}>
                        Total Roofs <br />
                        {data.length}
                    </TableCell>
                    <TableCell align='right' sx={{ color: '#ffffff' }}>
                        Total Sq Ft <br />
                        {_.sumBy(data, (x) => x.roofSection?.squareFootage || 0).toLocaleString()}
                    </TableCell>
                    <TableCell align='right' sx={{ color: '#ffffff' }}>
                        Total Asset Value <br />
                        {formatCurrency(_.sumBy(data, (x) => x.roofSection?.assetValue || 0))}
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    {visibleYears.map((i) => (
                        <TableCell align='right' sx={{ color: '#ffffff' }}>
                            Total <br />
                            {formatCurrency(_.sumBy(data, (x) => getYearValue(startYear, i, x)), true)}
                        </TableCell>
                    ))}
                    <TableCell align='right' sx={{ color: '#ffffff' }}>
                        Total <br />
                        {formatCurrency(
                            _.sumBy(data, (x) => x.total ?? 0),
                            true
                        )}
                    </TableCell>
                </TableFooter>
            );
        },
        [getYearValue, startYear]
    );

    const sortedFacilityBudgetLineItems = useMemo(
        () => sortByRoofNumber<BudgetLineItemDto>(facilityBudgetLineItems ?? [], (x) => x.roofSection?.roofNumber ?? ''),
        [facilityBudgetLineItems]
    );

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <DashboardCard headerIcon={<AttachMoney />} headerTitle={'Executive Summary Budget'}>
            <LocalDataTable
                columns={tableColumns}
                loading={isLoading}
                rowData={sortedFacilityBudgetLineItems}
                tableFooter={getTableFooter(facilityBudgetLineItems ?? [])}
            />
        </DashboardCard>
    );
};
