import { AddCircle, FormatListBulleted, MoreVert, Search } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import AuthenticatedComponent from '../../../auth';
import { RoofConditionDto, RoofSectionDto } from '../../../models';
import { useGetRoofSectionsQuery } from '../../../store/apis/roof-section-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { useGetFacilityQuery } from '../../../store/apis/facility-api';
import { useGetRoofProfileQuery } from '../../../store/apis/roof-profile-api';

export interface ISelectRoofSectionsDialog {
    open: boolean;
    onCancel: any;
    onConfirm: any;
    onCreateNew?: any;
    setRoofSections?: any;
    currentRoofSections: RoofSectionDto[];
    isMultipleSelectable: boolean;
    currentRoofProfileId?: string;
    onUnlink?: (params?: any) => void;
    facilityId?: string;
    roofProfileId?: string;
}

export const SelectRoofSectionsDialog: FC<ISelectRoofSectionsDialog> = (props) => {
    const {
        open,
        onCancel,
        onConfirm,
        onCreateNew,
        setRoofSections,
        isMultipleSelectable,
        currentRoofSections,
        currentRoofProfileId,
        onUnlink,
        facilityId,
        roofProfileId,
    } = props;
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const {
        data,
        isLoading,
        error,
        refetch: refetchRoofSection,
    } = useGetRoofSectionsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: true,
        facilityId: facilityId,
        roofProfileId: roofProfileId,
    });

    useEffect(() => {
        if (data) {
            var previouslySelectedIndexes = currentRoofSections.map((currentSection) => data.pageResults.findIndex((y) => y.id === currentSection.id));
            setSelected(previouslySelectedIndexes);
        }
    }, [currentRoofSections, data, open]);

    const makeSwatch = (condition: RoofConditionDto | undefined) => {
        return (
            <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={2}>
                    <Box sx={{ display: 'flex', width: '16px', height: '16px', backgroundColor: condition?.colorCode }} />
                </Grid>
                <Grid item xs={10} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {condition?.rating}
                </Grid>
            </Grid>
        );
    };

    const tableColumns: DataTableColumn<RoofSectionDto>[] = [
        { key: 'roofNumber', label: 'Roof #', sortKey: 'ROOF_NUMBER' },
        { key: 'squareFootage', label: 'Sq Ft', sortKey: 'SQUARE_FOOTAGE', align: 'right', fieldMapper: (row) => row.squareFootage.toLocaleString() },
        { key: 'roofSystem', label: 'Roof Type', sortKey: 'ROOF_TYPE', fieldMapper: (row) => row.roofSystem?.name },
        { key: 'roofCondition', label: 'Condition', sortKey: 'CONDITION', fieldMapper: (row) => makeSwatch(row.roofCondition) },
        { key: 'estimatedAge', label: 'Estimated Install Year', sortKey: 'ESTIMATED_AGE' },
        {
            key: 'installDate',
            label: 'Install Date',
            sortKey: 'INSTALL_DATE',
            fieldMapper: (row) => (row.installDate ? format(new Date(row.installDate.toString()), 'M/d/yyyy') : ''),
        },
        { key: 'manufacturer', label: 'Manufacturer', sortKey: 'MANUFACTURER', fieldMapper: (row) => row.manufacturer?.name },
        {
            key: 'assetValue',
            label: 'Asset Value',
            align: 'right',
            sortKey: 'ASSET_VALUE',
            fieldMapper: (row) =>
                row.assetValue
                    ? row.assetValue.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                    })
                    : '',
        },
        {
            key: 'map',
            label: 'Assigned to Map?',
            sortKey: '',
            fieldMapper: (row) =>
                (row.mapSections ? row.mapSections.filter((x) => !currentRoofProfileId || x.roofProfileId === currentRoofProfileId).length : 0) > 0
                    ? 'Yes'
                    : 'No',
        },
    ];

    const beforeClose = () => {
        onCancel();
    };

    const handleSelect = () => {
        var selectedSections = selected.map((index) => {
            return data?.pageResults[index];
        });
        if (setRoofSections) {
            setRoofSections(selectedSections);
        }
        onConfirm(selectedSections[0]);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleCreateNew = () => {
        onCreateNew();
    };

    const handleUnassign = () => {
        if (onUnlink) {
            var selectedSections = selected.map((index) => {
                return data?.pageResults[index];
            });
            onUnlink(selectedSections);
        }
    };

    const areAnySelectedAlreadyMapped = () => {
        let isAlreadyMapped = false;
        if (onUnlink) {
            selected.forEach((index) => {
                if (data?.pageResults[index]?.mapSections?.find((x) => x.roofProfileId === currentRoofProfileId)) {
                    isAlreadyMapped = true;
                }
            });
        }

        return isAlreadyMapped;
    };

    const displayFacilityName = useGetFacilityQuery(facilityId!).data?.name;
    const unformattedDisplaySectionName = useGetRoofProfileQuery({ id: roofProfileId ?? currentRoofProfileId!, includeSvg: false }).data?.name ?? '';
    const displaySectionName = unformattedDisplaySectionName !== '' ? ': ' + unformattedDisplaySectionName : '';

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetchRoofSection} />;
    }

    return (
        <Dialog maxWidth='xl' fullWidth open={open}>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <FormatListBulleted />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Roof Sections
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' pt={4} spacing={4}>
                    <Grid container item direction='row' alignItems='center' justifyContent='space-between' xs={6}>
                        <Grid item xs={8}>
                            <Typography fontWeight={'bold'} fontSize='large'>
                                {displayFacilityName ?? ''}
                                {displaySectionName}
                            </Typography>
                        </Grid>
                        <AuthenticatedComponent
                            requiredPermissions={['create:roofSections']}
                            component={
                                <Grid item>
                                    <Tooltip title='Add New'>
                                        <IconButton color='primary' size='large' onClick={handleCreateNew} disabled={!onCreateNew}>
                                            <AddCircle fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['create:roofSections', 'unassign:roofSections']}
                            logic='or'
                            component={
                                <Grid item>
                                    <IconButton size='large' onClick={handleMenuOpen}>
                                        <MoreVert fontSize='inherit' />
                                    </IconButton>
                                    <Menu
                                        anchorEl={menuAnchorEl}
                                        open={menuOpen}
                                        onClose={handleMenuClose()}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}>
                                        <AuthenticatedComponent
                                            requiredPermissions={['create:roofSections']}
                                            component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                        />
                                        <AuthenticatedComponent
                                            requiredPermissions={['unassign:roofSections']}
                                            component={
                                                onUnlink ? (
                                                    <>
                                                        <Divider />
                                                        <MenuItem
                                                            disabled={!onUnlink || selected.length === 0 || !areAnySelectedAlreadyMapped()}
                                                            onClick={handleMenuClose(handleUnassign)}>
                                                            Unassign Roof Sections
                                                        </MenuItem>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        />
                                    </Menu>
                                </Grid>
                            } />
                        <Grid item xs={3}>
                            <TextField
                                id='search-box'
                                value={searchText}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Search'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isLoading}
                            queryResults={data}
                            defaultSortKey='ROOF_NUMBER'
                            setPagination={setPaginatedProps}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row'>
                    <Grid item xs={9}>
                        {selected.length === 0 ||
                            (areAnySelectedAlreadyMapped() && <Typography color='error'>Selection is already mapped to the current roof profile.</Typography>) ||
                            (!isMultipleSelectable && selected.length > 1 && (
                                <Typography color='error'>Selection is limited to one roof section only.</Typography>
                            ))}
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs={3} justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={selected.length === 0 || areAnySelectedAlreadyMapped() || (!isMultipleSelectable && selected.length > 1)}
                                onClick={handleSelect}>
                                Select
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
