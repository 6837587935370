import { serverApi } from '..';
import { DocumentDto, QueryResultsDto, RoofSectionDto, RoofSectionChangeLogDto } from '../../models';
import { MapSectionDto } from '../../models/MapSectionDto';
import { buildSearchQueryRoute } from '../../util';
import { ChildSearchQueryParams, RoofSectionDocumentQueryParams, RoofSectionsSearchQueryParams } from '../types';

export interface IChangeLogRequestPrams {
    roofSectionId: string;
    changeLogId: string;
};

const roofSectionApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoofSections: builder.query<QueryResultsDto<RoofSectionDto>, RoofSectionsSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('roofsection', params),
            providesTags: [{ type: 'Roof Section' }]
        }),
        getRoofSection: builder.query<RoofSectionDto, string>({
            query: (id) => ({ url: `roofsection/${id}` }),
            providesTags: [{ type: 'Roof Section' }]
        }),
        getRoofSectionChangeLogs: builder.query<QueryResultsDto<RoofSectionChangeLogDto>, ChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`roofsection/${params.parentId}/log`, params.params),
            providesTags: [{ type: 'Roof Section' }],
        }),
        archiveRoofSectionChangeLog: builder.mutation<void, IChangeLogRequestPrams>({
            query: (params) => ({
                url: `roofsection/${params.roofSectionId}/log/${params.changeLogId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Roof Section' }]
        }),
        unarchiveRoofSectionChangeLog: builder.mutation<void, IChangeLogRequestPrams>({
            query: (params) => ({
                url: `roofsection/${params.roofSectionId}/log/${params.changeLogId}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Roof Section' }]
        }),
        createRoofSection: builder.mutation<RoofSectionDto, RoofSectionDto>({
            query: (roofSectionDto) => ({
                url: 'roofsection',
                method: 'POST',
                body: roofSectionDto
            }),
            invalidatesTags: [{ type: 'Roof Section' }]
        }),
        updateRoofSection: builder.mutation<void, RoofSectionDto>({
            query: (roofSectionDto) => ({
                url: 'roofsection',
                method: 'PUT',
                body: roofSectionDto
            }),
            invalidatesTags: [{ type: 'Roof Section' }, { type: 'Roof Leak'}]
        }),
        linkRoofSection: builder.mutation<void, MapSectionDto>({
            query: (mapSectionDto) => ({
                url: 'roofsection/link',
                method: 'PUT',
                body: mapSectionDto
            }),
            invalidatesTags: [{ type: 'Roof Section' }, { type: 'Roof Profile'}]
        }),
        unlinkRoofSection: builder.mutation<void, MapSectionDto>({
            query: (mapSectionDto) => ({
                url: 'roofsection/unlink',
                method: 'PUT',
                body: mapSectionDto
            }),
            invalidatesTags: [{ type: 'Roof Section' }, { type: 'Roof Profile'}]
        }),
        archiveRoofSection: builder.mutation<void, string>({
            query: (id) => ({
                url: `roofsection/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Roof Section' }]
        }),
        unarchiveRoofSection: builder.mutation<void, string>({
            query: (id) => ({
                url: `roofsection/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Roof Section' }]
        }),
        getRoofSectionsDocuments: builder.query<QueryResultsDto<DocumentDto>, RoofSectionDocumentQueryParams>({
            query: (params) => buildSearchQueryRoute(`roofsection/${params.parentId}/documents`, params.params),
            providesTags: [{ type: 'Document' }]
        }),
    })
});

export const {
    useGetRoofSectionsQuery,
    useGetRoofSectionQuery,
    useGetRoofSectionChangeLogsQuery,
    useCreateRoofSectionMutation,
    useUpdateRoofSectionMutation,
    useLinkRoofSectionMutation,
    useUnlinkRoofSectionMutation,
    useArchiveRoofSectionMutation,
    useUnarchiveRoofSectionMutation,
    useGetRoofSectionsDocumentsQuery,
    useArchiveRoofSectionChangeLogMutation,
    useUnarchiveRoofSectionChangeLogMutation,
    useLazyGetRoofSectionQuery
} = roofSectionApi;