import { DialogTitle, Grid, Typography } from '@mui/material';
import {FC} from 'react';

export interface IStandardDialogHeaderProps {
    icon: React.ReactNode;
    title: string;
};

export const StandardDialogHeader: FC<IStandardDialogHeaderProps> = ({
    icon,
    title
}) => {
    return (
        <DialogTitle sx={{ backgroundColor: '#266490' }}>
            <Grid container direction='row' alignItems='center' gap={1}>
                { icon }
                <Typography fontSize='inherit' sx={{ color: 'white' }} variant='h3'>
                    { title }
                </Typography>
            </Grid>
        </DialogTitle>
    );
};