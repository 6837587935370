import { Info } from '@mui/icons-material';
import { Box, Grid, Link, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { formatCurrency, ColorSwatch } from '../../../util';
import { NoMapFoundError } from '../../core/NoMapFoundError';
import { SvgEditor, SvgWrapper } from '../../svg';
import { DashboardCard } from '../DashboardCard';
import { IRoofSectionInfoCardProps } from './types';
import { useNavigate } from 'react-router-dom';

export const RoofSectionInfoCard: FunctionComponent<IRoofSectionInfoCardProps> = (props) => {
    const { roofSection, facility } = props;
    const [svgContents, setSvgContents] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        let profile = facility.roofProfiles?.find((x) => x.isCurrent);
        let svg = profile?.svgContents;
        setSvgContents(svg ?? '');
    }, [facility.roofProfiles]);

    const warrantyStatus = useMemo(() => {
        if (roofSection.warrantyStatus === 'Yes (Documented)') {
            return <Link onClick={() => {
                navigate(`/clients/${roofSection.facility?.clientId}/facility/${roofSection.facilityId}#warranties`);
            }} sx={{ cursor: 'pointer' }}>
                <Typography>{
                    roofSection.warrantyStatus}
                </Typography>
            </Link>;
        } else {
            return <Typography>{roofSection.warrantyStatus}</Typography>;
        }
    }, [navigate, roofSection.facility?.clientId, roofSection.facilityId, roofSection.warrantyStatus]);

    return (
        <Grid item container direction='column'>
            <Grid item container direction='column'>
                <DashboardCard headerTitle='Roof Section Info' headerIcon={<Info />}>
                    <Grid item container direction='row' sx={{ padding: '24px' }} spacing={2}>
                        <Grid item container direction={'column'} spacing={2} xs={6}>
                            <Grid container item direction='row' alignItems={'baseline'}>
                                <Grid item xs={6}>
                                    <Typography fontWeight={'bold'} fontSize={22}>
                                        Condition
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize={22}><ColorSwatch condition={roofSection.roofCondition} fontSize={22} /></Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction='row' alignItems={'baseline'}>
                                <Grid item xs={6}>
                                    <Typography fontWeight={'bold'} fontSize={22}>
                                        Recommended Corrective Action
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize={22}>{roofSection.roofCondition?.recommendedAction}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction='row'>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Roof Name</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{roofSection.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Install Date</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>
                                            {roofSection.installDate ? format(new Date(roofSection?.installDate?.toString()), 'M/d/yyyy') : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction='row'>
                                <Grid item container xs={6} spacing={8}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Manufacturer</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{roofSection.manufacturer?.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Installing Contractor</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{roofSection.contractor?.name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction='row'>
                                <Grid item container xs={6} spacing={8}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Roof Access</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{roofSection.roofAccess?.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Roof Type</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{roofSection.roofSystem?.name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction='row'>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Drainage</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{roofSection.drainage?.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Asset Value</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{formatCurrency(roofSection.assetValue)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction='row'>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Estimated Install Year</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{roofSection.estimatedAge}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Square Footage</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{roofSection.squareFootage ? roofSection.squareFootage.toLocaleString() : 0}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction='row'>
                                <Grid item container spacing={8} xs={6}>
                                    <Grid item xs={4}>
                                        <Typography fontWeight={'bold'}>Warranty Status</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{warrantyStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container direction={'column'} xs={6} pt={0} sx={{ maxWidth: '800px' }}>
                            {svgContents ? (
                                <Grid item paddingBottom={64}>
                                    <SvgWrapper>
                                        <SvgEditor
                                            svgContents={svgContents}
                                            selectedElement={null}
                                            singleFilledSection={roofSection}
                                            colorSections={true}
                                            height={'100%'}
                                            width={'100%'}
                                            disabled
                                        />
                                    </SvgWrapper>
                                </Grid>
                            ) : (
                                <Box width='100%' height='100%' sx={{ position: 'relative' }}>
                                    <NoMapFoundError errorText='Not assigned to a map roof section' />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </DashboardCard>
            </Grid>
        </Grid>
    );
};
