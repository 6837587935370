import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BinderPortfolioForm } from '../../Components/Forms/BinderPortfolioForm';
import { emptyGuid } from '../../models';
import { BinderPortfolioReportDto } from '../../models/BinderPortfolioReportDto';
import { useCreateBPReportMutation } from '../../store/apis/binder-portfolio-report-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddBinderPortfolioReportView: FC = () => {
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [createClientReport, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateBPReportMutation();
    const initialReport: BinderPortfolioReportDto = {
        id: emptyGuid,
        reportName: '',
        reportDate: null,
        clientId: '',
        isActive: true,
        isVisibleToClients: false,
        isVisibleToEmployees: true,
        visibility: 'Private', // the value is computed on the back-end so this initial value does not actually matter
    };

    useFailedCreateSnackbar('binder portfolio', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Binder portfolio', isCreateSuccess, () => {
        navigate(`/binderPortfolioReports/edit/${data?.id}`);
    });

    const handleSave = (values: BinderPortfolioReportDto) => {
        createClientReport(values);
    };

    const handleCancel = () => {
        navigate(`/clientreports`);
    };

    return <BinderPortfolioForm save={handleSave} cancel={handleCancel} initValues={initialReport} clientId={clientId} />;
};
