import { Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { RoofConditionDto } from "../../../models";
import { useGetRoofConditionsQuery } from "../../../store/apis/roof-api";

export const RoofConditionIndexTable: React.FunctionComponent<{ isPrintView?: boolean; }> = (props) => {
    const { isPrintView } = props;
    const { data: roofConditions } = useGetRoofConditionsQuery({ includeInactive: true });

    return (
        <Table size='small' sx={{ border: '2px solid black', maxWidth: isPrintView ? '500px' : '100%', maxHeight: isPrintView ? '200px' : '100%' }}>
            <TableHead sx={{ backgroundColor: '#266490' }}>
                <TableRow>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                        Color
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                        Rating
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                        Condition Category
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {_.orderBy(roofConditions, x => x.order).map((condition: RoofConditionDto) => (
                    <TableRow key={condition.id}>
                        <TableCell>
                            <Box sx={{ display: 'flex', width: isPrintView ? '12px' : '24px', height: isPrintView ? '12px' : '24px', backgroundColor: condition.colorCode }} />
                        </TableCell>
                        {condition.rating === condition.recommendedAction ?
                            <TableCell colSpan={2}>
                                <Typography
                                    sx={{
                                        whiteSpace: 'normal',
                                        overflow: 'hidden',
                                        textOverflow: 'clip',
                                    }}>
                                    {condition.rating}
                                </Typography>
                            </TableCell> : <>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            whiteSpace: 'normal',
                                            overflow: 'hidden',
                                            textOverflow: 'clip',
                                        }}>
                                        {condition.rating}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            whiteSpace: 'normal',
                                            overflow: 'hidden',
                                            textOverflow: 'clip',
                                        }}>
                                        {condition.recommendedAction}
                                    </Typography>
                                </TableCell>
                            </>
                        }
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};