import { AddPhotoAlternate } from '@mui/icons-material';
import { Grid, Box, Typography } from '@mui/material';
import { FunctionComponent, useCallback } from 'react';
import { DndContext, DragEndEvent, MouseSensor, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, rectSwappingStrategy, SortableContext } from '@dnd-kit/sortable';
import { SortablePhoto } from './SortablePhoto';
import { IPhotosDisplay } from './types';
import React from 'react';

export const PhotosDisplay: FunctionComponent<IPhotosDisplay> = React.memo(props => {
	const {
		attachments,
		setAttachments,
		setAttachmentHoverIndex,
		photoPreviewOnClick,
		photoEditOnClick,
		attachmentHoverIndex,
		deleteSelectedPhoto,
		attachmentCaptions,
		setAttachmentCaptions,
		handleCaptionInput,
		setAddPhotoIsOpen,
		disabled,
		hideNoPhotosMessage
	} = props;

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10
			}
		})
	);

	const { setNodeRef } = useDroppable({
		id: 'photo-display-dropzone'
	});

	const onDragEnd = useCallback((event: DragEndEvent) => {
		const { active, over } = event;
		if (over && active.id !== over.id) {
			const oldIndex = attachments.findIndex(x => x.id === active.id);
			const newIndex = attachments.findIndex(x => x.id === over.id);

			setAttachments((items) => {
				const newArray = arrayMove(items, oldIndex, newIndex);
				newArray.map((attachment, index) => {
					return { ...attachment, order: index };
				});
				return newArray;
			});
			setAttachmentCaptions((items) => {
				return arrayMove(items, oldIndex, newIndex);
			});
		}
	}, [attachments, setAttachmentCaptions, setAttachments]);

	if (attachments.length === 0 && !setAddPhotoIsOpen && !hideNoPhotosMessage) {
		return <Typography>No photos added to this roof section.</Typography>;
	}

	return (
		<DndContext
			onDragEnd={onDragEnd}
			sensors={sensors}
		>
			<SortableContext items={attachments} strategy={rectSwappingStrategy}>
				<Box ref={setNodeRef}>
					<Grid container flexDirection='row' spacing={4}>
						{attachments.map((attachment, index) => {
							return (
								<Grid item xs={4}>
									<SortablePhoto
										attachment={attachment}
										index={index}
										setAttachmentHoverIndex={setAttachmentHoverIndex}
										attachmentHoverIndex={attachmentHoverIndex}
										photoPreviewOnClick={photoPreviewOnClick}
										photoEditOnClick={photoEditOnClick}
										deleteSelectedPhoto={deleteSelectedPhoto}
										handleCaptionInput={handleCaptionInput}
										caption={attachmentCaptions[index]}
										disabled={disabled || attachments.length === 0}
									/>
								</Grid>
							);
						})}
						{setAddPhotoIsOpen && !disabled && (
							<Grid
								container
								direction='column'
								item
								xs={4}
								spacing={2}
								wrap='nowrap'
								onClick={() => {
									if (!disabled) {
										setAddPhotoIsOpen(true);
									}
								}}
								justifyContent={'end'}
								sx={{ cursor: 'pointer' }}>
								<Grid container item direction='row' justifyContent='space-around'>
									<Grid item>
										<AddPhotoAlternate color='primary' sx={{ objectFit: 'contain', fontSize: '100px', opacity: '50%' }} />
									</Grid>
								</Grid>
								<Grid container item direction='row' justifyContent='space-around'>
									<Grid item>
										<Typography>Click here to add a new photo</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Box>
			</SortableContext>
		</DndContext>
	);
});
