import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { AddCircle, Delete, Folder, MoreVert, Search } from '@mui/icons-material';
import { IDocumentsFormSection } from './types';
import { DataTableColumn, PaginatedDataTable } from '../DataTable';
import {
    useArchiveDocumentMutation,
    useDeleteDocumentMutation,
    useLazyGetDocumentFileLinkQuery,
    useSetDocumentPrivateMutation,
    useSetDocumentPublicMutation,
    useUnarchiveDocumentMutation,
} from '../../../store/apis/document-api';
import { DocumentDto, RoofSectionDto, VisibilityGroup } from '../../../models';
import { format } from 'date-fns';
import _ from 'lodash';
import AuthenticatedComponent from '../../../auth';
import { UploadDocumentDialog } from '../../Forms/WorkOrderForm';
import React from 'react';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { usePermissionChecker } from '../../../Hooks';
import { DocumentVisibilityChangeDto } from '../../../models/DocumentVisibilityChangeDto';
import { useNavigate } from 'react-router-dom';

export const DocumentsFormSection: FunctionComponent<IDocumentsFormSection> = React.memo((props) => {
    const {
        data,
        roofSectionsData,
        isLoading,
        setPaginatedProps,
        workOrderId,
        facilityId,
        setShowInactive,
        showInactive,
        searchText,
        setSearchText,
        workOrderDispatchId,
        disabled,
        isSimplifiedColumns,
        isHardDeleteMode,
        isSimplifiedAddEdit,
        isDashboardView,
        isCapitalProjectView
    } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [selectedDocument, setSelectedDocument] = useState<DocumentDto | undefined>();
    const { userHasAnyPermission, userHasPermission } = usePermissionChecker();
    const navigate = useNavigate();

    const [selected, setSelected] = useState<readonly number[]>([]);
    const [documentToDownload, setDocumentToDownload] = useState<DocumentDto>();
    const [downloadFile, setDownloadFile] = useState(false);
    const [editing, setEditing] = useState(false);
    const [getDocument, { data: fileDownloadLink, isLoading: fileDownloadLinkLoading }] = useLazyGetDocumentFileLinkQuery();
    const [deleteDocument, { isError: isDeleteError, isSuccess: isDeleteSuccess, reset: resetDelete }] = useDeleteDocumentMutation();
    const [archiveDocument, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveDocumentMutation();
    const [unarchiveDocument, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveDocumentMutation();
    const [setDocumentPrivateClient, { isError: isSetPrivateClientError, isSuccess: isSetPrivateClientSuccess, reset: resetSetPrivateClient }] = useSetDocumentPrivateMutation();
    const [setDocumentPublicClient, { isError: isSetPublicClientError, isSuccess: isSetPublicClientSuccess, reset: resetSetPublicClient }] = useSetDocumentPublicMutation();
    const [setDocumentPrivateEmployee, { isError: isSetPrivateEmployeeError, isSuccess: isSetPrivateEmployeeSuccess, reset: resetSetPrivateEmployee }] = useSetDocumentPrivateMutation();
    const [setDocumentPublicEmployee, { isError: isSetPublicEmployeeError, isSuccess: isSetPublicEmployeeSuccess, reset: resetSetPublicEmployee }] = useSetDocumentPublicMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const onFileLinkClick = useCallback((document: DocumentDto) => {
        setDownloadFile(true);
        setDocumentToDownload(document);
    }, []);

    useEffect(() => {
        if (documentToDownload) {
            getDocument(documentToDownload.id);
        }
    }, [documentToDownload, getDocument]);

    const formatRoofSections = useCallback(
        (roofSections: RoofSectionDto[]) => {
            let sectionsText = '';
            roofSections.sort((x, y) => (x.roofNumber > y.roofNumber ? 1 : -1));
            const existingRoofSectionNumbers = roofSectionsData?.pageResults.map((x) => x.roofNumber);
            const documentRoofSections = roofSections.map((x) => x.roofNumber);
            if (roofSections.length === 0 || existingRoofSectionNumbers?.length === 0) {
                sectionsText = 'None';
            } else if (_.isEqual(existingRoofSectionNumbers, documentRoofSections)) {
                sectionsText = 'All';
            } else {
                roofSections.forEach((roofSection, index) => {
                    sectionsText = sectionsText.concat(`${roofSection.roofNumber}`);
                    if (index < roofSections.length - 1) {
                        sectionsText = sectionsText.concat(', ');
                    }
                });
            }
            return sectionsText;
        },
        [roofSectionsData?.pageResults]
    );

    const getTableColumns = () => {
        const fullColumnList: DataTableColumn<DocumentDto>[] = [
            {
                key: 'filename',
                label: 'Filename',
                sortKey: 'FILENAME',
                fieldMapper: (row) => (
                    <Link sx={{ cursor: 'pointer' }} onClick={() => onFileLinkClick(row)}>
                        {row.displayName}
                    </Link>
                ),
                width: '200px'
            },
            {
                key: 'description',
                label: 'Description',
                sortKey: 'DESCRIPTION',
                width: '150px'
            },
            {
                key: 'roofSections',
                label: 'Roof Section(s)',
                sortKey: 'ROOF_SECTIONS',
                unsorted: true,
                fieldMapper: (row) => (row.roofSections ? formatRoofSections(row.roofSections.map((x) => x.roofSection!)) : 'None'),

            },
            {
                key: 'uploadedOn',
                label: 'Uploaded',
                sortKey: 'UPLOADED_ON',
                fieldMapper: (row) => (row.createdOn ? format(new Date(row.createdOn.toString()), 'M/d/yyyy') : ''),
            }
        ];

        if (userHasPermission('edit:visibility')) {
            fullColumnList.push({ key: 'visibility', label: 'Visibility', sortKey: 'VISIBILITY' });
        }

        if (isSimplifiedColumns) {
            const simplifiedColumnNames = ['filename', 'description', 'uploadedOn'];
            return fullColumnList.filter((x) => simplifiedColumnNames.includes(x.key));
        } else if (isDashboardView) {
            const simplifiedColumnNames = ['filename', 'description', 'uploadedOn', 'roofSections', 'visibility'];
            return fullColumnList.filter((x) => simplifiedColumnNames.includes(x.key));
        } else {
            return fullColumnList;
        }
    };

    const getEntityName = () => {
        return selected.length === 1 ? 'Document' : 'Documents';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => {
        handleActionRequestCompletion(resetUnarchive);
    });
    useFailedActionSnackbar('delete', getEntityName(), isDeleteError, () => {
        handleActionRequestCompletion(resetDelete);
    });
    useFailedActionSnackbar('make hidden to Clients', getEntityName(), isSetPrivateClientError, () => {
        handleActionRequestCompletion(resetSetPrivateClient);
    });
    useFailedActionSnackbar('make hidden to Employees', getEntityName(), isSetPrivateEmployeeError, () => {
        handleActionRequestCompletion(resetSetPrivateEmployee);
    });
    useFailedActionSnackbar('make visible to Clients', getEntityName(), isSetPublicClientError, () => {
        handleActionRequestCompletion(resetSetPublicClient);
    });
    useFailedActionSnackbar('make visible to Employees', getEntityName(), isSetPublicEmployeeError, () => {
        handleActionRequestCompletion(resetSetPublicEmployee);
    });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetUnarchive);
    });
    useSuccessfulActionSnackbar('Deleted', getEntityName(), isDeleteSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetDelete);
    });
    useSuccessfulActionSnackbar('hidden from Clients', getEntityName(), isSetPrivateClientSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetSetPrivateClient);
    });
    useSuccessfulActionSnackbar('made visible to Clients', getEntityName(), isSetPublicClientSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetSetPublicClient);
    });
    useSuccessfulActionSnackbar('hidden from Employees', getEntityName(), isSetPrivateEmployeeSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetSetPrivateEmployee);
    });
    useSuccessfulActionSnackbar('made visible to Employees', getEntityName(), isSetPublicEmployeeSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetSetPublicEmployee);
    });

    useEffect(() => {
        if (fileDownloadLink && !fileDownloadLinkLoading && downloadFile) {
            const link = document.createElement('a');
            link.href = fileDownloadLink.link;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadFile(false);
        }
    }, [fileDownloadLink, fileDownloadLinkLoading, downloadFile, setDownloadFile]);

    const handleSearchChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSelected([]);
            setSearchText(event.target.value);
        },
        [setSearchText]
    );

    const handleActiveToggleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!setShowInactive) {
                console.warn('Toggle inactive called but no setShowInactive prop was provided.');
                return;
            }
            setSelected([]);
            setShowInactive(event.target.checked);
        },
        [setShowInactive]
    );

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) afterClose();
        },
        []
    );

    const handleCreateNew = useCallback(() => {
        setUploadDialogOpen(true);
    }, []);

    const handleMenuEdit = useCallback(() => {
        setSelectedDocument(data?.pageResults[selected[0]]);
        setEditing(true);
        setUploadDialogOpen(true);
    }, [data?.pageResults, selected]);

    const handleRowEdit = useCallback(
        (id: string) => {
            setSelectedDocument(data?.pageResults.find((x) => x.id === id));
            setEditing(true);
            setUploadDialogOpen(true);
        },
        [data?.pageResults]
    );

    const handleUploadDialogClose = useCallback(() => {
        setEditing(false);
        setUploadDialogOpen(false);
    }, []);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveDocument(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [data?.pageResults, selected, unarchiveDocument]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            const documentId = data?.pageResults[index].id!;
            if (isHardDeleteMode) {
                deleteDocument(documentId);
            } else {
                archiveDocument(documentId);
            }
        });
        setIsUpdatingStatus(false);
    }, [archiveDocument, deleteDocument, data?.pageResults, selected, isHardDeleteMode]);

    const canSetActive = useCallback(() => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useCallback(() => {
        return selected.some((index) => data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const buildVisibilityChangeRequestBody = useCallback(
        (visibilityGroup: VisibilityGroup) => {
            return selected
                .map((tblIdx) => data?.pageResults[tblIdx]!)
                .map<DocumentVisibilityChangeDto>((rep) => ({
                    documentId: rep.id,
                    visibilityGroup: visibilityGroup,
                }));
        },
        [data, selected]
    );

    const sendMakePublicRequest = useCallback(
        (visibilityGroup: VisibilityGroup) => {
            setIsUpdatingStatus(true);
            switch (visibilityGroup) {
                case VisibilityGroup.Client:
                    setDocumentPublicClient(buildVisibilityChangeRequestBody(visibilityGroup));
                    break;
                case VisibilityGroup.Employee:
                    setDocumentPublicEmployee(buildVisibilityChangeRequestBody(visibilityGroup));
                    break;
            }
            setIsUpdatingStatus(false);
        },
        [buildVisibilityChangeRequestBody, setDocumentPublicClient, setDocumentPublicEmployee]
    );

    const sendMakePrivateRequest = useCallback(
        (visibilityGroup: VisibilityGroup) => {
            setIsUpdatingStatus(true);
            switch (visibilityGroup) {
                case VisibilityGroup.Client:
                    setDocumentPrivateClient(buildVisibilityChangeRequestBody(visibilityGroup));
                    break;
                case VisibilityGroup.Employee:
                    setDocumentPrivateEmployee(buildVisibilityChangeRequestBody(visibilityGroup));
                    break;
            }
            setIsUpdatingStatus(false);
        },
        [buildVisibilityChangeRequestBody, setDocumentPrivateClient, setDocumentPrivateEmployee]
    );

    const handleShowEmployees = useCallback(() => sendMakePublicRequest(VisibilityGroup.Employee), [sendMakePublicRequest]);
    const handleHideEmployees = useCallback(() => sendMakePrivateRequest(VisibilityGroup.Employee), [sendMakePrivateRequest]);
    const handleShowClients = useCallback(() => sendMakePublicRequest(VisibilityGroup.Client), [sendMakePublicRequest]);
    const handleHideClients = useCallback(() => sendMakePrivateRequest(VisibilityGroup.Client), [sendMakePrivateRequest]);

    const selectedRecords = useMemo(() => (data ? selected.map((tblIdx) => data.pageResults[tblIdx]) : []), [selected, data]);
    const canShowEmployee = useCallback(() => selectedRecords.some((rec) => !rec.isVisibleToEmployees), [selectedRecords]);
    const canHideEmployee = useCallback(() => selectedRecords.some((rec) => rec.isVisibleToEmployees), [selectedRecords]);
    const canShowClient = useCallback(() => selectedRecords.some((rec) => !rec.isVisibleToClients), [selectedRecords]);
    const canHideClient = useCallback(() => selectedRecords.some((rec) => rec.isVisibleToClients), [selectedRecords]);

    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item xs={6} />
                <Grid item container direction='row' alignItems='center' xs={6} spacing={1} justifyContent='end'>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:documents']}
                        component={
                            <Grid item xs={2}>
                                {setShowInactive && (
                                    <FormControlLabel
                                        control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                        label='Show Inactive'
                                        labelPlacement='start'
                                    />
                                )}
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['delete:documents']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title={isHardDeleteMode ? 'Delete' : 'Make Inactive'}>
                                    <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive() || disabled}>
                                        <Delete fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    {isCapitalProjectView && (
                        <AuthenticatedComponent
                            requiredPermissions={['read:documentFolders']}
                            component={
                                <Grid item>
                                    <Grid item xs={1}>
                                        <Tooltip title='Folders'>
                                            <IconButton color='primary' size='large' onClick={() => navigate(`/capitalprojects/edit/${workOrderId}/documentFolders`)}>
                                                <Folder fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            }
                        />
                    )}
                    {!isDashboardView && (
                        <AuthenticatedComponent
                            requiredPermissions={['create:documents']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title='Add New'>
                                        <IconButton color='primary' size='large' onClick={handleCreateNew} disabled={disabled}>
                                            <AddCircle fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                    )}
                    <AuthenticatedComponent
                        requiredPermissions={['create:documents', 'edit:documents', 'delete:documents']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen} disabled={disabled}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    {!isDashboardView && (
                                        <AuthenticatedComponent
                                            requiredPermissions={['create:documents']}
                                            component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                        />
                                    )}
                                    {!isSimplifiedAddEdit && (
                                        <>
                                            <AuthenticatedComponent
                                                requiredPermissions={['edit:documents']}
                                                component={
                                                    <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                        Edit
                                                    </MenuItem>
                                                }
                                            />
                                            {isCapitalProjectView && <AuthenticatedComponent
                                                requiredPermissions={['read:documentFolders']}
                                                component={
                                                    <MenuItem onClick={() => {
                                                        navigate(`/capitalprojects/edit/${workOrderId}/documentFolders`);
                                                    }}>
                                                        View Folders
                                                    </MenuItem>
                                                }
                                            />}
                                            <AuthenticatedComponent
                                                requiredPermissions={['edit:documents', 'edit:visibility']}
                                                logic='and'
                                                component={
                                                    <>
                                                        <Divider />
                                                        <MenuItem onClick={handleMenuClose(handleShowEmployees)} disabled={!canShowEmployee()}>
                                                            Show Employees
                                                        </MenuItem>
                                                        <MenuItem onClick={handleMenuClose(handleHideEmployees)} disabled={!canHideEmployee()}>
                                                            Hide Employees
                                                        </MenuItem>
                                                        <MenuItem onClick={handleMenuClose(handleShowClients)} disabled={!canShowClient()}>
                                                            Show Clients
                                                        </MenuItem>
                                                        <MenuItem onClick={handleMenuClose(handleHideClients)} disabled={!canHideClient()}>
                                                            Hide Clients
                                                        </MenuItem>
                                                    </>
                                                }
                                            />
                                        </>
                                    )}
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:documents']}
                                        component={
                                            <>
                                                {isHardDeleteMode ? (
                                                    <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                                        Delete
                                                    </MenuItem>
                                                ) : (
                                                    <>
                                                        <Divider />
                                                        <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                                            Make Active
                                                        </MenuItem>
                                                        <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                                            Make Inactive
                                                        </MenuItem>
                                                    </>
                                                )}
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        }
                    />
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={getTableColumns()}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='UPLOADED_ON'
                    defaultSortDesc
                    setPagination={setPaginatedProps}
                    selected={selected}
                    editPermissions={{ requiredPermissions: ['edit:documents'] }}
                    onEdit={handleRowEdit}
                    setSelected={setSelected}
                    disableEdit={() => {
                        return disabled ?? false;
                    }}
                    disableSelection={!userHasAnyPermission(['create:documents', 'edit:documents', 'delete:documents'])}
                />
            </Grid>
            <UploadDocumentDialog
                open={uploadDialogOpen}
                onClose={handleUploadDialogClose}
                workOrderId={workOrderId}
                facilityId={facilityId}
                edit={editing}
                initValues={editing ? selectedDocument : undefined}
                workOrderDispatchId={workOrderDispatchId}
                isSimplifiedForm={isSimplifiedAddEdit}
            />
        </Grid>
    );
});
