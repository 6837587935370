import { VariantType, useSnackbar } from 'notistack';
import { useEffect } from 'react';

export function useStandardSnackbar(isDisplayed: boolean, message: string, variant: VariantType, onSnackbarDisplay: () => void = () => {}) {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (isDisplayed) {
            enqueueSnackbar(message, { variant, preventDuplicate: true });
            onSnackbarDisplay();
        }
    }, [isDisplayed, enqueueSnackbar, message, variant, onSnackbarDisplay]);
}
