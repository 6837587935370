import { FunctionComponent } from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
} from '@mui/material';
import { AuthRoles } from '../../core/AuthRoles';
import {IUserFormField} from './types';


export const UserFormField: FunctionComponent<IUserFormField> = (props) => {
    const { expectedRoles, label, clientId, fieldError, userRole, options, handleChange } = props;

    return (
        <Grid item xs={4}>
            <FormControl
                fullWidth
                error={fieldError !== '' && expectedRoles.includes(userRole as AuthRoles) && !clientId}
                required={expectedRoles.includes(userRole as AuthRoles)}>
                <FormLabel>{label}</FormLabel>
                <Select value={clientId} onChange={handleChange}>
                    <MenuItem key='none' value=''>
                        <em>None</em>
                    </MenuItem>
                    {options?.pageResults.map((model) => (
                        <MenuItem key={model.id} value={model.id}>
                            {(expectedRoles.includes(AuthRoles.ClientManager)) ? model.name : ''}
                            {(expectedRoles.includes(AuthRoles.Contractor)) ? ((model.dispatchId ?? 'N/A') +' - '+ model.name) : ''}
                        </MenuItem>
                    ))}
                </Select>
                {expectedRoles.includes(userRole as AuthRoles) && !clientId && <FormHelperText>{fieldError}</FormHelperText>}
            </FormControl>
        </Grid>
    );
};
