import { JsiReportDto } from "../../../models";
import { JsiReportChecklistItemDto } from "../../../models/JsiReportChecklistItemDto";
import { JsiReportRolloverItemDto } from "../../../models/JsiReportRolloverItemDto";
import { format } from 'date-fns';
import { IFormProps } from "../types";

export const ChecklistItemCategories = {
    GENERAL: 'GENERAL',
    ROOF_DECK: 'ROOF DECK',
    INSULATION: 'INSULATION',
    MEMBRANE: 'MEMBRANE',
    ACCESSORIES: 'ACCESSORIES',
    MISCELLANEOUS: 'MISCELLANEOUS',
    RESTORATION: 'RESTORATION'
};

export const ChecklistItemAnswers = {
    APPROVED: 'APPROVED',
    DENIED: 'DENIED',
    WIP: 'WIP',
    NA: 'N/A'
};

export interface ChecklistItem {
    category: string,
    question: string,
    order: number;
    answer: string;
};

export const GeneralChecklistItems = [
    { category: ChecklistItemCategories.GENERAL, question: 'Overall Site Conditions', order: 0, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Conditions @ Dumpster', order: 1, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Stage Area/Material Storage', order: 2, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Fall Protection/Warning Lines', order: 3, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Material Storage/Roof Level', order: 4, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Sidewalk/Grounds Damage', order: 5, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Ladder Set-Up', order: 6, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Building Protection', order: 7, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Occupants Protection', order: 8, answer: '' },
    { category: ChecklistItemCategories.GENERAL, question: 'Other:', order: 9, answer: '' }
] as ChecklistItem[];

export const RoofDeckChecklistItems = [
    { category: ChecklistItemCategories.ROOF_DECK, question: 'Tear Off Procedure', order: 0, answer: '' },
    { category: ChecklistItemCategories.ROOF_DECK, question: 'Condition of Roof Deck', order: 1, answer: '' },
    { category: ChecklistItemCategories.ROOF_DECK, question: 'New Decking', order: 2, answer: '' },
    { category: ChecklistItemCategories.ROOF_DECK, question: 'Fastening Pattern of Deck', order: 3, answer: '' },
    { category: ChecklistItemCategories.ROOF_DECK, question: 'Air/Vapor Barrier/Retarder', order: 4, answer: '' },
] as ChecklistItem[];

export const InsulationChecklistItems = [
    { category: ChecklistItemCategories.INSULATION, question: 'As Specified', order: 0, answer: '' },
    { category: ChecklistItemCategories.INSULATION, question: 'Storage', order: 1, answer: '' },
    { category: ChecklistItemCategories.INSULATION, question: 'Layout/Staggered Seams', order: 2, answer: '' },
    { category: ChecklistItemCategories.INSULATION, question: 'Proper Taper/Saddles', order: 3, answer: '' },
    { category: ChecklistItemCategories.INSULATION, question: 'Drain Sumps', order: 4, answer: '' },
    { category: ChecklistItemCategories.INSULATION, question: 'Attachment @ Corners', order: 5, answer: '' },
    { category: ChecklistItemCategories.INSULATION, question: 'Attachment @ Perimeter', order: 6, answer: '' },
    { category: ChecklistItemCategories.INSULATION, question: 'Attachment in Field', order: 7, answer: '' },
    { category: ChecklistItemCategories.INSULATION, question: 'Other:', order: 8, answer: '' },
] as ChecklistItem[];

export const MembraneChecklistItems = [
    { category: ChecklistItemCategories.MEMBRANE, question: 'As Specified', order: 0, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'Storage', order: 1, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'Layout/Relax Sheet?', order: 2, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'MF/FA Attachment', order: 3, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'Perimeter Attachment (Field)', order: 4, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'Seams', order: 5, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'Wall Flashings', order: 6, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'Perimeter Details', order: 7, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'Unit/Proj./Flashings', order: 8, answer: '' },
    { category: ChecklistItemCategories.MEMBRANE, question: 'Flashing Heights', order: 9, answer: '' },
] as ChecklistItem[];

export const AccessoryChecklistItems = [
    { category: ChecklistItemCategories.ACCESSORIES, question: 'As Specified', order: 0, answer: '' },
    { category: ChecklistItemCategories.ACCESSORIES, question: 'Storage-Adhesives', order: 1, answer: '' },
] as ChecklistItem[];

export const MiscellaneousChecklistItems = [
    { category: ChecklistItemCategories.MISCELLANEOUS, question: 'Perimeter Metal', order: 0, answer: '' },
    { category: ChecklistItemCategories.MISCELLANEOUS, question: 'Metal Flashing', order: 1, answer: '' },
    { category: ChecklistItemCategories.MISCELLANEOUS, question: 'Termination Bar', order: 2, answer: '' },
    { category: ChecklistItemCategories.MISCELLANEOUS, question: 'Metal Counter Flashing', order: 3, answer: '' },
    { category: ChecklistItemCategories.MISCELLANEOUS, question: 'Expansion Joints', order: 4, answer: '' },
    { category: ChecklistItemCategories.MISCELLANEOUS, question: 'Temporary Tie-in', order: 5, answer: '' },
    { category: ChecklistItemCategories.MISCELLANEOUS, question: 'Daily Cleanup - Roof', order: 6, answer: '' },
    { category: ChecklistItemCategories.MISCELLANEOUS, question: 'Drain Assemblies', order: 7, answer: '' },
] as ChecklistItem[];

export const RestorationChecklistItems = [
    { category: ChecklistItemCategories.RESTORATION, question: 'Surface Preparation', order: 0, answer: '' },
    { category: ChecklistItemCategories.RESTORATION, question: 'Seam Reinforcement', order: 1, answer: '' },
    { category: ChecklistItemCategories.RESTORATION, question: 'Coating System', order: 2, answer: '' },
] as ChecklistItem[];

export const convertChecklistItemsToJsiReportChecklistItemDtos = () => {
    return [...GeneralChecklistItems, ...RoofDeckChecklistItems, ...InsulationChecklistItems, ...MembraneChecklistItems, ...AccessoryChecklistItems, ...MiscellaneousChecklistItems, ...RestorationChecklistItems] as JsiReportChecklistItemDto[];
};

export const getCategoryChecklistItems = (checklistItems: JsiReportChecklistItemDto[],category: string) => {
    return checklistItems.filter(x => x.category === category).sort((a, b) => a.order - b.order);
};

export interface IJsiReportRolloverItemListViewProps {
    report: JsiReportDto;
    disabled?: boolean;
}

export interface IAddEditJsiReportRolloverItemDialogProps {
    report: JsiReportDto,
    open: boolean;
    onClose: any;
    initValues?: JsiReportRolloverItemDto;
}

export interface IJsiReportFormProps extends IFormProps<JsiReportDto> {
    workOrderId?: string;
};

export const isDate = (dateString: string) => {
    return (!isNaN(Date.parse(dateString)));
};

export const getDueDate = (dateString: string) => {
    if (isDate(dateString)) {
        return format(new Date(dateString), 'M/d/yyyy');
    } else {
        return dateString;
    }
};