import { serverApi } from '..';
import { QueryResultsDto } from '../../models';
import { CloseOutReportDto } from '../../models/CloseOutReportDto';
import { ReportPdfDto } from '../../models/ReportPdfDto';
import { buildSearchQueryRoute } from '../../util';
import { CloseOutReportSearchQueryParams } from '../types';

const ReportApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getCloseOutReports: builder.query<QueryResultsDto<CloseOutReportDto>, CloseOutReportSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('closeOutReport', params),
            providesTags: [{ type: 'Report' }]
        }),
        getCloseOutReport: builder.query<CloseOutReportDto, string>({
            query: (reportId) => ({ url: `closeOutReport/${reportId}` }),
            providesTags: [{ type: 'Report' }]
        }),
        getCloseOutReportPdf: builder.query<ReportPdfDto, string>({
            query: (reportId) => ({ url: `closeOutReport/${reportId}/reportPdf` }),
            providesTags: [{ type: 'Report' }]
        }),
        createCloseOutReport: builder.mutation<CloseOutReportDto, CloseOutReportDto>({
            query: (reportDto) => ({
                url: 'closeOutReport',
                method: 'POST',
                body: reportDto
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        createCloseOutPdf: builder.mutation<ReportPdfDto, string>({
            query: (reportId) => ({
                url: `closeOutReport/${reportId}/pdf`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        unlockCloseOutReport: builder.mutation<ReportPdfDto, string>({
            query: (reportId) => ({
                url: `closeOutReport/${reportId}/unlock`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        updateCloseOutReport: builder.mutation<void, CloseOutReportDto>({
            query: (reportDto) => ({
                url: 'closeOutReport',
                method: 'PUT',
                body: reportDto
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        archiveCloseOutReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `closeOutReport/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        unarchiveCloseOutReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `closeOutReport/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        })
    })
});

export const {
    useLazyGetCloseOutReportQuery,
    useLazyGetCloseOutReportPdfQuery,
    useGetCloseOutReportQuery,
    useCreateCloseOutPdfMutation,
    useCreateCloseOutReportMutation,
    useUnlockCloseOutReportMutation,
    useUpdateCloseOutReportMutation,
    useArchiveCloseOutReportMutation,
    useUnarchiveCloseOutReportMutation,
    useGetCloseOutReportsQuery,
} = ReportApi;