import { useContext } from 'react';
import { UserPermissionContext } from '../Contexts';
import { userHasPermissions } from '../auth';

export const usePermissionChecker = () => {
    const { permissions: currentUserPermissions } = useContext(UserPermissionContext);

    const userHasAnyPermission = (permissionsToCheck: string[]) => {
        return userHasPermissions(permissionsToCheck, currentUserPermissions, 'or');
    };

    const userHasAllPermissions = (permissionsToCheck: string[]) => {
        return userHasPermissions(permissionsToCheck, currentUserPermissions, 'and');
    };

    const userHasPermission = (permissionToCheck: string) => {
        return userHasPermissions([permissionToCheck], currentUserPermissions, 'and');
    };

    return {
        userHasAnyPermission,
        userHasAllPermissions,
        userHasPermission
    };
};
