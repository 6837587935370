import { FormatListBulletedOutlined, InsertChart, Map, OpenInFull, PhotoLibrary, PictureAsPdf } from '@mui/icons-material';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Attachment, FormButton, FormSection } from '..';
import AuthenticatedComponent from '../../../auth';
import { PhotoDto, ReportPdfStatus, WorkOrderListViewDto, emptyGuid, usStates } from '../../../models';
import { ContractorDto } from '../../../models/ContractorDto';
import { ReportListItemDto } from '../../../models/ReportListItemDto';
import { useLazyGetSasLinkQuery } from '../../../store/apis/cloud-storage-api';
import { useGetContractorsQuery } from '../../../store/apis/contractor-api';
import { useCreateJsiReportCopyMutation, useCreateJsiReportPdfMutation, useLazyGetJsiReportPdfQuery } from '../../../store/apis/jsi-report-api';
import { useLazyGetPhotoFileLinkQuery } from '../../../store/apis/photo-api';
import { useLazyGetDoesReportExistForWorkOrderQuery } from '../../../store/apis/report-list-api';
import { useGetWorkOrdersQuery } from '../../../store/apis/work-order-api';
import { uploadFileToCloud } from '../../../util';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { VisibilityConfig, VisibilityControl, currentVisibilityOrDefault } from '../../CommonInputs';
import { DashboardCard } from '../../Dashboard';
import { ConfirmPdfDialog } from '../../core/ConfirmPdfDialog/ConfirmPdfDialog';
import LoadingIndicator from '../../core/LoadingIndicator';
import { MenuButton } from '../../core/MenuButton';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { PhotoEditorDialog, PhotosDisplay } from '../../core/Photos';
import { MultiPhotoUpload } from '../../core/Photos/MultiPhotoUpload';
import { DateTimePicker, FormInput, FormNumberInput } from '../FormFields';
import { ViewPhotoDialog } from '../RoofMap';
import { SelectClientReportDialog } from '../SelectClientReport';
import { SelectWorkOrdersDialog } from '../SelectWorkOrder';
import { JsiReportRolloverItemListView } from './JsiReportRolloverItemsListView';
import { QualityAssuranceChecklist } from './QualityAssuranceChecklist';
import { IJsiReportFormProps } from './types';

export const JsiReportForm: FC<IJsiReportFormProps> = (props) => {
    const { save, cancel, initValues, workOrderId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [name, setName] = useState(initValues ? initValues.reportName : '');
    const [visibilityConfig, setVisibilityConfig] = useState(currentVisibilityOrDefault(initValues));
    const [roofSections, setRoofSections] = useState(initValues ? initValues.roofSections : '');
    const [workOrder, setWorkOrder] = useState<WorkOrderListViewDto | null | undefined>(initValues ? initValues.workOrder : null);
    const [workOrderOpen, setWorkOrderOpen] = useState(false);
    const [contractor, setContractor] = useState<ContractorDto | null | undefined>(initValues ? initValues.contractor : undefined);
    const [contractorOpen, setContractorOpen] = useState(false);
    const [reportDate, setReportDate] = useState<Date | null | undefined>(initValues && initValues.reportDate ? new Date(initValues.reportDate) : new Date());
    const [phoneNumber, setPhoneNumber] = useState(initValues ? initValues.phoneNumber : '');
    const [squareFootage, setSquareFootage] = useState(initValues ? initValues.totalSquareFootage : undefined);
    const [weather, setWeather] = useState(initValues ? initValues.weather : '');
    const [qaInspector, setQaInspector] = useState(initValues ? initValues.qaInspector : '');
    const [othersPresent, setOthersPresent] = useState(initValues ? initValues.othersPresent : '');
    const [officeContact, setOfficeContact] = useState(initValues ? initValues.officeContact : '');
    const [contractorPhoneNumber, setContractorPhoneNumber] = useState(initValues ? initValues.contractorPhoneNumber : '');
    const [contractorEmail, setContractorEmail] = useState(initValues ? initValues.contractorEmail : '');
    const [foremanOnJob, setForemanOnJob] = useState(initValues ? initValues.foremanOnJob : '');
    const [foremanPhoneNumber, setForemanPhoneNumber] = useState(initValues ? initValues.foremanPhoneNumber : '');
    const [crewSizeOnsite, setCrewSizeOnsite] = useState(initValues ? initValues.crewSizeOnsite : undefined);
    const [workCompliant, setWorkCompliant] = useState(initValues ? initValues.workCompliant : false);
    const [projectSummary, setProjectSummary] = useState(initValues ? initValues.projectSummary : '');
    const [qualityAssuranceChecklistItems, setQualityAssuranceChecklistItems] = useState(
        initValues ? initValues.checklistItems : []
    );
    const [inspectorNotes, setInspectorNotes] = useState(initValues ? initValues.inspectorNotes : '');
    const [photos, setPhotos] = useState<Attachment[]>([]);
    const [photoCaptions, setPhotoCaptions] = useState<string[]>([]);
    const [photoHoverIndex, setPhotoHoverIndex] = useState<number | undefined>();
    const [addPhotoIsOpen, setAddPhotoIsOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState<Attachment>();
    const [selectedCaption, setSelectedCaption] = useState<string>('');
    const [viewPhotoIsOpen, setViewPhotoIsOpen] = useState(false);
    const [editPhotoIsOpen, setEditPhotoIsOpen] = useState(false);
    const [isSelectWorkOrdersDialogOpen, setIsSelectWorkOrdersDialogOpen] = useState(false);
    const [isSelectExistingReportDialogOpen, setIsSelectExistingReportDialogOpen] = useState(false);
    const jsiReportType = -2;
    const [isScrolled, setIsScrolled] = useState(false);
    const [createReportPdf, { data: reportPdfData, isLoading: isGeneratingReport }] = useCreateJsiReportPdfMutation();
    const [createReportCopy, { data: copy, isSuccess: copySuccess, isError: isCopyError, reset: resetCopy }] = useCreateJsiReportCopyMutation();
    const [getReportPdf] = useLazyGetJsiReportPdfQuery();
    const [reportPdf, setReportPdf] = useState(initValues?.reportPdf);
    const rolloverItemRef = useRef<null | HTMLDivElement>(null);
    const photosRef = useRef<null | HTMLDivElement>(null);

    const { data: contractors, isLoading: contractorsLoading } = useGetContractorsQuery({ pageSize: 100000, sortKey: 'DISPATCH_ID', includeInactive: true });
    const [getFileLink] = useLazyGetPhotoFileLinkQuery();
    const [getSasLink] = useLazyGetSasLinkQuery();
    const [doesReportExistForWOrkOrderQuery] = useLazyGetDoesReportExistForWorkOrderQuery();

    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (initValues) {
            setWorkOrder(initValues.workOrder);
        }
    }, [initValues]);

    const handlePdfDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const generatePdf = useCallback(() => {
        enqueueSnackbar('Report PDF Requested. Please wait while it is generated.');
        createReportPdf(initValues?.id ?? '');
    }, [createReportPdf, enqueueSnackbar, initValues?.id]);

    const handleGeneratePdf = useCallback(
        (event: React.SyntheticEvent) => {
            if (reportPdf && reportPdf.status === ReportPdfStatus.Created) {
                handlePdfDialog();
            } else {
                event.preventDefault();
                generatePdf();
            }
        },
        [generatePdf, handlePdfDialog, reportPdf]
    );

    const isLocked = useCallback((date?: Date) => {
        if (date && new Date() >= new Date(date)) {
            return true;
        }

        return false;
    }, []);

    const [isReportLocked] = useState(initValues?.id ? isLocked(initValues.lockDate) : false);

    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        ROOF_SECTIONS: '',
        WO_NUMBER: '',
        REPORT_DATE: '',
        PRESENTED_TO_CLIENT: '',
        PRESENTED_TO_FACILITY: '',
        PRESENTED_TO_CONTACT_NAME: '',
        PRESENTED_TO_ADDRESS: '',
        PRESENTED_TO_CITY: '',
        PRESENTED_TO_STATE: '',
        PRESENTED_TO_ZIP: '',
        PROJECT_SUMMARY: '',
        SQUARE_FOOTAGE: '',
        PHONE_NUMBER: '',
        QA_INSPECTOR: '',
        CONTRACTOR: '',
        OFFICE_CONTACT: '',
        CONTRACTOR_PHONE_NUMBER: '',
        CONTRACTOR_EMAIL: '',
        FOREMAN_ON_JOB: '',
        FOREMAN_PHONE_NUMBER: '',
        CREW_SIZE_ONSITE: '',
    });
    const [changed, setFormChanged] = useState(false);
    const { data: workOrders, isLoading: workOrdersLoading } = useGetWorkOrdersQuery({
        getOnlyOpen: false,
        orderType: 1,
        includeInactive: true,
    });

    const [presentedToClientName, setPresentedToClientName] = useState(initValues ? initValues.presentedToClientName : '');
    const [presentedToFacilityName, setPresentedToFacilityName] = useState(initValues ? initValues.presentedToFacilityName : '');
    const [presentedToContactName, setPresentedToContactName] = useState(initValues ? initValues.presentedToContactName : '');
    const [presentedToAddressLine1, setPresentedToAddressLine1] = useState(initValues ? initValues.presentedToAddress1 : '');
    const [presentedToAddressLine2, setPresentedToAddressLine2] = useState(initValues ? initValues.presentedToAddress2 : '');
    const [presentedToCity, setPresentedToCity] = useState(initValues ? initValues.presentedToCity : '');
    const [presentedToState, setPresentedToState] = useState(initValues ? initValues.presentedToState : '');
    const [presentedToZip, setPresentedToZip] = useState(initValues ? initValues.presentedToZip : '');

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    }, []);

    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setName(event.target.value);
    }, []);

    const handleVisibilityChange = useCallback((updatedVisibility: VisibilityConfig) => {
        setFormChanged(true);
        setVisibilityConfig(updatedVisibility);
    }, []);

    const handleRoofSectionsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setRoofSections(event.target.value);
    }, []);

    const handleReportDateChange = useCallback((value: Date | null | undefined) => {
        setFormChanged(true);
        setReportDate(value);
    }, []);

    const handlePresentedToClientNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToClientName(event.target.value);
    }, []);

    const handlePresentedToFacilityNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToFacilityName(event.target.value);
    }, []);

    const handlePresentedToContactNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToContactName(event.target.value);
    }, []);

    const handlePresentedToAddressLine1Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToAddressLine1(event.target.value);
    }, []);

    const handlePresentedToAddressLine2Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToAddressLine2(event.target.value);
    }, []);

    const handlePresentedToCityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToCity(event.target.value);
    }, []);

    const handlePresentedToStateChange = useCallback((event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        setPresentedToState(event.target.value);
    }, []);

    const handlePresentedToZipChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToZip(event.target.value);
    }, []);

    const handleSquareFootageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setSquareFootage(Math.round(event.target.valueAsNumber));
    }, []);

    const handlePhoneNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPhoneNumber(event.target.value);
    }, []);

    const handleWeatherChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setWeather(event.target.value);
    }, []);

    const handleQaInspectorChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setQaInspector(event.target.value);
    }, []);

    const handleOthersPresentChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setOthersPresent(event.target.value);
    }, []);

    const handleOfficeContactChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setOfficeContact(event.target.value);
    }, []);

    const handleContractorPhoneNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setContractorPhoneNumber(event.target.value);
    }, []);

    const handleContractorEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setContractorEmail(event.target.value);
    }, []);

    const handleForemanOnJobChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setForemanOnJob(event.target.value);
    }, []);

    const handleForemanPhoneNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setForemanPhoneNumber(event.target.value);
    }, []);

    const handleCrewSizeOnsiteChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setCrewSizeOnsite(Math.round(event.target.valueAsNumber));
    }, []);

    const handleWorkCompliantChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setWorkCompliant(event.target.checked);
    }, []);

    const handleProjectSummaryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setProjectSummary(event.target.value);
    }, []);

    const handleInspectorNotesChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setInspectorNotes(event.target.value);
    }, []);

    const handleRadioButtonClicked = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, idx: number, category: string) => {
            setFormChanged(true);
            const value = (event.target as any).value;
            const checklistItems = [...qualityAssuranceChecklistItems];
            const changedIndex = checklistItems.findIndex((x) => x.category === category && x.order === idx);
            if (checklistItems[changedIndex].answer !== value) {
                checklistItems[changedIndex] = { ...checklistItems[changedIndex], answer: value };
            } else {
                checklistItems[changedIndex] = { ...checklistItems[changedIndex], answer: '' };
            }
            setQualityAssuranceChecklistItems(checklistItems);
        },
        [qualityAssuranceChecklistItems]
    );

    const validate = useCallback(
        (fieldName: string) => {
            let isValid = false;
            if (fieldName === 'NAME') {
                if (name) {
                    fieldErrors.NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.NAME = 'Report name is required';
                    isValid = false;
                }
            } else if (fieldName === 'ROOF_SECTIONS') {
                if (workOrder) {
                    fieldErrors.ROOF_SECTIONS = '';
                    isValid = true;
                } else {
                    fieldErrors.ROOF_SECTIONS = 'Roof Sections are required';
                    isValid = false;
                }
            } else if (fieldName === 'WO_NUMBER') {
                if (workOrder) {
                    fieldErrors.WO_NUMBER = '';
                    isValid = true;
                } else {
                    fieldErrors.WO_NUMBER = 'Work Order # is required';
                    isValid = false;
                }
            } else if (fieldName === 'CONTRACTOR') {
                if (contractor) {
                    fieldErrors.CONTRACTOR = '';
                    isValid = true;
                } else {
                    fieldErrors.CONTRACTOR = 'Contractor is required';
                    isValid = false;
                }
            } else if (fieldName === 'REPORT_DATE') {
                if (reportDate) {
                    fieldErrors.REPORT_DATE = '';
                    isValid = true;
                } else {
                    fieldErrors.REPORT_DATE = 'Report Date is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CLIENT') {
                if (presentedToClientName) {
                    fieldErrors.PRESENTED_TO_CLIENT = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CLIENT = 'Client is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_FACILITY') {
                if (presentedToFacilityName) {
                    fieldErrors.PRESENTED_TO_FACILITY = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_FACILITY = 'Facility is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CONTACT_NAME') {
                if (presentedToContactName) {
                    fieldErrors.PRESENTED_TO_CONTACT_NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CONTACT_NAME = 'Contact Name is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_ADDRESS') {
                if (presentedToAddressLine1) {
                    fieldErrors.PRESENTED_TO_ADDRESS = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_ADDRESS = 'Address Line 1 is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CITY') {
                if (presentedToCity) {
                    fieldErrors.PRESENTED_TO_CITY = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CITY = 'City is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_STATE') {
                if (presentedToState) {
                    fieldErrors.PRESENTED_TO_STATE = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_STATE = 'State is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_ZIP') {
                if (presentedToZip) {
                    fieldErrors.PRESENTED_TO_ZIP = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_ZIP = 'Zip Code is required';
                    isValid = false;
                }
            } else if (fieldName === 'SQUARE_FOOTAGE') {
                if (squareFootage) {
                    fieldErrors.SQUARE_FOOTAGE = '';
                    isValid = true;
                } else {
                    fieldErrors.SQUARE_FOOTAGE = 'Square Footage is required';
                    isValid = false;
                }
            } else if (fieldName === 'PHONE_NUMBER') {
                if (phoneNumber) {
                    fieldErrors.PHONE_NUMBER = '';
                    isValid = true;
                } else {
                    fieldErrors.PHONE_NUMBER = 'Phone is required';
                    isValid = false;
                }
            } else if (fieldName === 'QA_INSPECTOR') {
                if (qaInspector) {
                    fieldErrors.QA_INSPECTOR = '';
                    isValid = true;
                } else {
                    fieldErrors.QA_INSPECTOR = 'QA Inspector is required';
                    isValid = false;
                }
            } else if (fieldName === 'OFFICE_CONTACT') {
                if (officeContact) {
                    fieldErrors.OFFICE_CONTACT = '';
                    isValid = true;
                } else {
                    fieldErrors.OFFICE_CONTACT = 'Office Contact is required';
                    isValid = false;
                }
            } else if (fieldName === 'CONTRACTOR_PHONE_NUMBER') {
                if (contractorPhoneNumber) {
                    fieldErrors.CONTRACTOR_PHONE_NUMBER = '';
                    isValid = true;
                } else {
                    fieldErrors.CONTRACTOR_PHONE_NUMBER = 'Contractor Phone Number is required';
                    isValid = false;
                }
            } else if (fieldName === 'CONTRACTOR_EMAIL') {
                if (contractorEmail) {
                    fieldErrors.CONTRACTOR_EMAIL = '';
                    isValid = true;
                } else {
                    fieldErrors.CONTRACTOR_EMAIL = 'Contractor Email is required';
                    isValid = false;
                }
            } else if (fieldName === 'FOREMAN_ON_JOB') {
                if (foremanOnJob) {
                    fieldErrors.FOREMAN_ON_JOB = '';
                    isValid = true;
                } else {
                    fieldErrors.FOREMAN_ON_JOB = 'Foreman on Job is required';
                    isValid = false;
                }
            } else if (fieldName === 'FOREMAN_PHONE_NUMBER') {
                if (foremanPhoneNumber) {
                    fieldErrors.FOREMAN_PHONE_NUMBER = '';
                    isValid = true;
                } else {
                    fieldErrors.FOREMAN_PHONE_NUMBER = 'Foreman Cell # is required';
                    isValid = false;
                }
            } else if (fieldName === 'CREW_SIZE_ONSITE') {
                if (crewSizeOnsite) {
                    fieldErrors.CREW_SIZE_ONSITE = '';
                    isValid = true;
                } else {
                    fieldErrors.CREW_SIZE_ONSITE = 'Crew Size Onsite is required';
                    isValid = false;
                }
            } else if (fieldName === 'PROJECT_SUMMARY') {
                if (projectSummary.length <= 3200) {
                    fieldErrors.PROJECT_SUMMARY = '';
                    isValid = true;
                } else {
                    fieldErrors.PROJECT_SUMMARY = 'Project Summary is limited to 3200 characters.';
                    isValid = false;
                }
            }
            setFieldErrors({
                NAME: fieldErrors.NAME,
                ROOF_SECTIONS: fieldErrors.ROOF_SECTIONS,
                WO_NUMBER: fieldErrors.WO_NUMBER,
                CONTRACTOR: fieldErrors.CONTRACTOR,
                REPORT_DATE: fieldErrors.REPORT_DATE,
                PRESENTED_TO_CLIENT: fieldErrors.PRESENTED_TO_CLIENT,
                PRESENTED_TO_FACILITY: fieldErrors.PRESENTED_TO_FACILITY,
                PRESENTED_TO_ADDRESS: fieldErrors.PRESENTED_TO_ADDRESS,
                PRESENTED_TO_CITY: fieldErrors.PRESENTED_TO_CITY,
                PRESENTED_TO_CONTACT_NAME: fieldErrors.PRESENTED_TO_CONTACT_NAME,
                PRESENTED_TO_STATE: fieldErrors.PRESENTED_TO_STATE,
                PRESENTED_TO_ZIP: fieldErrors.PRESENTED_TO_ZIP,
                PROJECT_SUMMARY: fieldErrors.PROJECT_SUMMARY,
                SQUARE_FOOTAGE: fieldErrors.SQUARE_FOOTAGE,
                PHONE_NUMBER: fieldErrors.PHONE_NUMBER,
                QA_INSPECTOR: fieldErrors.QA_INSPECTOR,
                OFFICE_CONTACT: fieldErrors.OFFICE_CONTACT,
                CONTRACTOR_PHONE_NUMBER: fieldErrors.CONTRACTOR_PHONE_NUMBER,
                CONTRACTOR_EMAIL: fieldErrors.CONTRACTOR_EMAIL,
                FOREMAN_ON_JOB: fieldErrors.FOREMAN_ON_JOB,
                FOREMAN_PHONE_NUMBER: fieldErrors.FOREMAN_PHONE_NUMBER,
                CREW_SIZE_ONSITE: fieldErrors.CREW_SIZE_ONSITE,
            });
            return isValid;
        },
        [
            contractor,
            contractorEmail,
            contractorPhoneNumber,
            crewSizeOnsite,
            fieldErrors,
            foremanOnJob,
            foremanPhoneNumber,
            name,
            officeContact,
            phoneNumber,
            presentedToAddressLine1,
            presentedToCity,
            presentedToClientName,
            presentedToContactName,
            presentedToFacilityName,
            presentedToState,
            presentedToZip,
            projectSummary,
            qaInspector,
            reportDate,
            squareFootage,
            workOrder,
        ]
    );

    const onFieldBlur = useCallback(
        (fieldName: string) => () => {
            validate(fieldName);
        },
        [validate]
    );

    const formIsValid = useCallback(() => {
        let isValid = validate('NAME');
        isValid = validate('ROOF_SECTIONS') && isValid;
        isValid = validate('WO_NUMBER') && isValid;
        isValid = validate('CONTRACTOR') && isValid;
        isValid = validate('REPORT_DATE') && isValid;
        isValid = validate('PRESENTED_TO_CLIENT') && isValid;
        isValid = validate('PRESENTED_TO_FACILITY') && isValid;
        isValid = validate('PRESENTED_TO_ADDRESS') && isValid;
        isValid = validate('PRESENTED_TO_CITY') && isValid;
        isValid = validate('PRESENTED_TO_CONTACT_NAME') && isValid;
        isValid = validate('PRESENTED_TO_STATE') && isValid;
        isValid = validate('PRESENTED_TO_ZIP') && isValid;
        isValid = validate('PROJECT_SUMMARY') && isValid;
        isValid = validate('SQUARE_FOOTAGE') && isValid;
        isValid = validate('PHONE_NUMBER') && isValid;
        isValid = validate('QA_INSPECTOR') && isValid;
        isValid = validate('OFFICE_CONTACT') && isValid;
        isValid = validate('CONTRACTOR_PHONE_NUMBER') && isValid;
        isValid = validate('CONTRACTOR_EMAIL') && isValid;
        isValid = validate('FOREMAN_ON_JOB') && isValid;
        isValid = validate('FOREMAN_PHONE_NUMBER') && isValid;
        isValid = validate('CREW_SIZE_ONSITE') && isValid;
        return isValid;
    }, [validate]);

    const handleSave = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            if (formIsValid()) {
                save({
                    id: initValues ? initValues.id : emptyGuid,
                    isActive: isActive,
                    reportName: name,
                    reportDate: reportDate,
                    workOrderId: workOrder?.id ?? '',
                    roofSections: roofSections,
                    presentedToContactName: presentedToContactName,
                    presentedToClientName: presentedToClientName,
                    presentedToFacilityName: presentedToFacilityName,
                    presentedToAddress1: presentedToAddressLine1,
                    presentedToAddress2: presentedToAddressLine2,
                    presentedToState: presentedToState,
                    presentedToCity: presentedToCity,
                    presentedToZip: presentedToZip,
                    totalSquareFootage: squareFootage ?? 0,
                    phoneNumber: phoneNumber,
                    weather: weather,
                    qaInspector: qaInspector,
                    othersPresent: othersPresent,
                    contractorId: contractor?.id ?? '',
                    officeContact: officeContact,
                    contractorPhoneNumber: contractorPhoneNumber,
                    contractorEmail: contractorEmail,
                    foremanOnJob: foremanOnJob,
                    foremanPhoneNumber: foremanPhoneNumber,
                    crewSizeOnsite: crewSizeOnsite ?? 0,
                    workCompliant: workCompliant,
                    projectSummary: projectSummary,
                    checklistItems: qualityAssuranceChecklistItems,
                    inspectorNotes: inspectorNotes,
                    photos: photos.map((attachment, index) => {
                        return { ...attachment.photo, caption: photoCaptions[index], order: index };
                    }),
                    reportPdfId: initValues?.reportPdfId,
                    isVisibleToClients: visibilityConfig.isVisibleToClients,
                    isVisibleToEmployees: visibilityConfig.isVisibleToEmployees,
                    visibility: 'computed' // this is a computed field on the back-end the value set here does not matter
                });
                setFormChanged(false);
            }
        },
        [
            contractor?.id,
            contractorEmail,
            contractorPhoneNumber,
            crewSizeOnsite,
            foremanOnJob,
            foremanPhoneNumber,
            formIsValid,
            initValues,
            inspectorNotes,
            isActive,
            name,
            officeContact,
            othersPresent,
            phoneNumber,
            photoCaptions,
            photos,
            presentedToAddressLine1,
            presentedToAddressLine2,
            presentedToCity,
            presentedToClientName,
            presentedToContactName,
            presentedToFacilityName,
            presentedToState,
            presentedToZip,
            projectSummary,
            qaInspector,
            qualityAssuranceChecklistItems,
            reportDate,
            roofSections,
            save,
            squareFootage,
            weather,
            workCompliant,
            workOrder?.id,
            visibilityConfig
        ]
    );

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    const handleExistingReportDialogOpen = useCallback(() => {
        setIsSelectExistingReportDialogOpen(true);
    }, []);

    const handleExistingReportDialogClose = useCallback(() => {
        setIsSelectExistingReportDialogOpen(false);
    }, []);

    const handleExistingReportDialogConfirm = useCallback(
        (selectedReport: ReportListItemDto) => {
            setIsSelectExistingReportDialogOpen(false);
            createReportCopy(selectedReport.id);
        },
        [createReportCopy]
    );

    const handleWorkOrderOpen = useCallback(() => {
        setIsSelectWorkOrdersDialogOpen(true);
    }, []);

    const handleWorkOrdersDialogClose = useCallback(() => {
        setIsSelectWorkOrdersDialogOpen(false);
    }, []);

    const handleWorkOrdersDialogConfirm = useCallback(
        (sectionSelected: WorkOrderListViewDto) => {
            setFormChanged(true);
            setWorkOrder(sectionSelected);
            setPresentedToClientName(sectionSelected.facilityClientName);
            setPresentedToFacilityName(sectionSelected.facilityName);
            setPresentedToContactName(sectionSelected.facilityClientContactName);
            setPresentedToAddressLine1(sectionSelected.facilityAddress?.addressLine1);
            setPresentedToAddressLine2(sectionSelected.facilityAddress?.addressLine2);
            setPresentedToCity(sectionSelected.facilityAddress?.city);
            setPresentedToState(sectionSelected.facilityAddress?.state);
            setPresentedToZip(sectionSelected.facilityAddress?.zipCode);
            setContractor(sectionSelected.contractor);
            setContractorPhoneNumber(sectionSelected.contractor?.phoneNumber);
            setContractorEmail(sectionSelected.contractor?.email);
            doesReportExistForWOrkOrderQuery({
                facilityId: sectionSelected.facilityId,
                clientId: sectionSelected.facilityClientId ?? emptyGuid,
                reportType: 'jsiReports',
            }).then((response) => {
                if (response && response.data) {
                    handleExistingReportDialogOpen();
                }
            });
            setIsSelectWorkOrdersDialogOpen(false);
        },
        [doesReportExistForWOrkOrderQuery, handleExistingReportDialogOpen]
    );

    useEffect(() => {
        if (initValues && !reportPdf && !reportPdf && initValues.reportPdfId) {
            getReportPdf(initValues.id).then((response) => {
                if (!response.isError) {
                    setReportPdf(response.data);
                }
            });
        }
    }, [initValues, reportPdf, setReportPdf, getReportPdf]);

    useSuccessfulActionSnackbar('Generated', 'Report PDF', reportPdfData?.status === ReportPdfStatus.Created, () => {
        setReportPdf(reportPdfData);
    });
    useFailedActionSnackbar('generate', 'Report PDF', reportPdfData?.status === ReportPdfStatus.Error, () => {
        setReportPdf(reportPdfData);
    });

    const downloadPdf = () => {
        if (reportPdf?.downloadUrl) {
            window.open(reportPdf?.downloadUrl);
        }
    };

    const deleteSelectedPhoto = useCallback((index: number) => {
        if (photos.length === 0) {
            setPhotos([]);
            setPhotoCaptions([]);
        } else {
            const newCaptionList = [...photoCaptions];
            newCaptionList.splice(index, 1);
            setPhotoCaptions(newCaptionList);

            const newPhotoList = [...photos];
            newPhotoList.splice(index, 1);
            setPhotos(newPhotoList);
        }
    }, [photoCaptions, photos, setPhotoCaptions, setPhotos]);

    const photoPreviewOnClick = useCallback(
        (photo: Attachment, index: number) => {
            setSelectedPhoto(photo);
            setSelectedCaption(photoCaptions[index]);
            setViewPhotoIsOpen(true);
        },
        [photoCaptions]
    );

    const handlePhotoEditOnClick = useCallback(
        (photo: Attachment, index: number) => {
            setSelectedPhoto(photo);
            setSelectedCaption(photoCaptions[index]);
            setEditPhotoIsOpen(true);
        },
        [photoCaptions]
    );

    const handleUpdateEditedPhoto = useCallback(
        async (updatedPhoto: File, attachmentId: string) => {
            getSasLink().then(async (response) => {
                if (response.data?.link) {
                    const uploadResponse = await uploadFileToCloud(response.data?.link, updatedPhoto);
                    if (uploadResponse.isSuccessful) {
                        setPhotos(currentPhotos => currentPhotos.map(currentPhoto => {
                            if (currentPhoto.id === attachmentId) {
                                return {
                                    ...currentPhoto,
                                    photo: { ...currentPhoto.photo, sourceName: uploadResponse.fileName ?? '' },
                                    previewLink: URL.createObjectURL(updatedPhoto),
                                };
                            } else {
                                return currentPhoto;
                            }
                        }));
                        setEditPhotoIsOpen(false);
                    }
                }
            });
        },
        [getSasLink]
    );

    const handleCaptionInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
            const { value } = event.target;
            const updatedCaptionList = [...photoCaptions];
            updatedCaptionList[index] = value;
            setPhotoCaptions(updatedCaptionList);
        },
        [photoCaptions]
    );

    const afterPhotoUpload = useCallback(
        (id: string, photo: PhotoDto, previewLink: string) => {
            setPhotos((currentPhotos) => currentPhotos.concat({ id: id, photo: photo, previewLink: previewLink }));
            setPhotoCaptions(photoCaptions.concat(''));
        },
        [photoCaptions]
    );

    const createPreviewLink = useCallback(
        async (attachment: PhotoDto): Promise<Attachment> => {
            let response = await getFileLink(attachment.id, false);
            return { id: attachment.id, photo: attachment, previewLink: response.data?.link };
        },
        [getFileLink]
    );

    const initializePhotos = useCallback(() => {
        if (initValues?.photos) {
            Promise.all(
                initValues.photos.map(async (attachment) => {
                    return await createPreviewLink(attachment);
                })
            ).then((attachmentsList) => {
                const list = _.orderBy(attachmentsList, (x) => x.photo.order);
                setPhotos(list);
                setPhotoCaptions(list.map((attachment) => attachment.photo.caption ?? ''));
            });
        }
    }, [createPreviewLink, initValues?.photos]);

    useEffect(() => {
        initializePhotos();
    }, [initializePhotos]);

    useEffect(() => {
        if (initValues?.id === emptyGuid && initValues?.workOrderId) {
            if (initValues?.workOrder?.facilityId && initValues?.workOrder?.facility) {
                doesReportExistForWOrkOrderQuery({
                    facilityId: initValues.workOrder.facilityId,
                    clientId: initValues.workOrder.facility.clientId,
                    reportType: 'jsiReports',
                }).then((response) => {
                    if (response && response.data) {
                        handleExistingReportDialogOpen();
                    }
                });
            }
        }
    }, [doesReportExistForWOrkOrderQuery, handleExistingReportDialogOpen, initValues]);

    useSuccessfulActionSnackbar('created', 'Report', copySuccess, () => {
        navigate(`/jsireports/edit/${copy?.id}`);
        resetCopy();
    });
    useFailedActionSnackbar('copy', 'Report', isCopyError, resetCopy);


    const scrollToPhotos = () => {
        photosRef?.current!.scrollIntoView();
    };

    const scrollToRolloverItems = () => {
        rolloverItemRef?.current!.scrollIntoView();
    };


    const listenScrollEvent = (event: any) => {
        if (window.scrollY > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    if (contractorsLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave} xs={12}>
            <Grid item container direction='column' xs={12} spacing={2} sx={{ position: 'sticky', top: '72px', backgroundColor: isScrolled ? 'white' : undefined, zIndex: 1 }}>
                <Grid item container direction='row' alignItems='center' xs={12}>
                    <Grid item container direction='column' justifyContent='start' xs={8}>
                        <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                            <InsertChart /> {name ? name : 'New JSI Report'}
                        </Typography>
                        <NavBreadcrumbs
                            links={workOrderId ?
                                [
                                    { label: 'Home', navLink: '/' },
                                    { label: 'Capital Projects', navLink: '/capitalprojects' },
                                    { label: `Capital Project #${workOrder?.woNumber ?? 'N/A'}`, navLink: `/capitalprojects/edit/${workOrderId}` },
                                ] :
                                [
                                    { label: 'Home', navLink: '/' },
                                    { label: 'Client Reports', navLink: '/clientreports' },
                                ]}
                            currentPageLabel={name ? name : 'New JSI Report'}
                        />
                    </Grid>
                    <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                        <Grid item>
                            <FormControlLabel
                                control={<Switch checked={isActive} onChange={handleActiveToggleChange} />}
                                label='Active'
                                labelPlacement='start'
                                disabled={isReportLocked}
                            />
                        </Grid>
                        <Grid item>
                            {changed || !initValues ? (
                                <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                    Cancel
                                </FormButton>
                            ) : (
                                <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                    Close
                                </FormButton>
                            )}
                        </Grid>
                        <Grid item>
                            <FormButton variant='contained' color='primary' type='submit' onClick={handleSave} disabled={isReportLocked}>
                                Save
                            </FormButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
                    <Grid item container direction='row' xs={6} spacing={2}>
                        <AuthenticatedComponent
                            requiredPermissions={['read:reports']}
                            component={
                                <Grid item>
                                    <MenuButton onClick={scrollToPhotos} disabled={initValues?.id === emptyGuid} tooltip='Photos'>
                                        <Map />
                                    </MenuButton>
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['read:reports']}
                            logic='or'
                            component={
                                <Grid item>
                                    <MenuButton onClick={scrollToRolloverItems} disabled={initValues?.id === emptyGuid} tooltip='Rollover List Items'>
                                        <FormatListBulletedOutlined />
                                    </MenuButton>
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['read:reports']}
                            logic='or'
                            component={
                                <Grid item>
                                    <MenuButton
                                        onClick={handleGeneratePdf}
                                        disabled={
                                            initValues?.id === emptyGuid ||
                                            (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ||
                                            isGeneratingReport ||
                                            (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ||
                                            isReportLocked
                                        }
                                        tooltip='Generate PDF'>
                                        <PictureAsPdf />
                                    </MenuButton>
                                </Grid>
                            }
                        />
                        {dialogOpen && <ConfirmPdfDialog open={dialogOpen} close={() => setDialogOpen(false)} generatePdf={generatePdf} />}
                    </Grid>
                    {isGeneratingReport || (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ? (
                        <Grid item container xs={6} justifyContent='end' alignItems='center' wrap='nowrap'>
                            <Grid item>
                                <i>Please check again later... Your PDF is still generating</i>
                            </Grid>
                            <Grid item>
                                <LoadingIndicator />
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {reportPdf && reportPdf.status === ReportPdfStatus.Error ? (
                        <Grid item>
                            <i style={{ color: 'red' }}>The PDF failed to generate. Please try again.</i>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {reportPdf && reportPdf.status === ReportPdfStatus.Created && reportPdf.createdOn && !isGeneratingReport ? (
                        <Grid item container direction='column' xs={6} alignItems='end'>
                            <Grid item container direction='row' spacing={1} justifyContent='end'>
                                <Grid item>
                                    <PictureAsPdf />
                                </Grid>
                                <Grid item>
                                    <Link onClick={downloadPdf} sx={{ cursor: 'pointer' }}>
                                        {reportPdf.displayName}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <i>
                                    Generated on {new Date(reportPdf.updatedOn ? reportPdf.updatedOn : reportPdf.createdOn).toLocaleDateString()} at{' '}
                                    {new Date(reportPdf.updatedOn ? reportPdf.updatedOn : reportPdf.createdOn).toLocaleString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    })}
                                </i>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid >
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' columnSpacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                value={name}
                                onChange={handleNameChange}
                                onBlur={onFieldBlur('NAME')}
                                disabled={isReportLocked}
                                label='Report Name'
                                name='reportName'
                                error={fieldErrors.NAME !== ''}
                                errorText={fieldErrors.NAME}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item container xs={4} spacing={4}>
                            <Grid item container xs={5}>
                                <FormControl error={fieldErrors.REPORT_DATE !== ''} fullWidth required>
                                    <DateTimePicker
                                        label='Report Date'
                                        value={reportDate}
                                        onChange={handleReportDateChange}
                                        dateOnly={true}
                                        onBlur={onFieldBlur('REPORT_DATE')}
                                        onAcceptDate={() => {
                                            setFieldErrors({
                                                ...fieldErrors,
                                                REPORT_DATE: '',
                                            });
                                        }}
                                        disabled={isReportLocked}
                                    />
                                    <FormHelperText>{fieldErrors.REPORT_DATE}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={7}>
                                <FormControl error={fieldErrors.WO_NUMBER !== ''} fullWidth required>
                                    <FormLabel>Capital Project #</FormLabel>
                                    <Grid item container alignItems={'center'} direction='row'>
                                        <Grid item xs={10}>
                                            <Autocomplete
                                                value={workOrder ?? null}
                                                onChange={(_event, value) => {
                                                    if (value) {
                                                        handleWorkOrdersDialogConfirm(value);
                                                    }
                                                }}
                                                open={workOrderOpen}
                                                onOpen={() => setWorkOrderOpen(true)}
                                                onClose={() => setWorkOrderOpen(false)}
                                                options={workOrders?.pageResults.filter((option) => option.isActive || option.id === workOrder?.id) ?? []}
                                                loading={workOrdersLoading}
                                                isOptionEqualToValue={(option, value) => option.woNumber === value.woNumber}
                                                getOptionLabel={(option: WorkOrderListViewDto) => `${option.woNumber}${option.isActive ? '' : ' (Inactive)'}`}
                                                disabled={isReportLocked || initValues?.workOrder !== undefined}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={fieldErrors.WO_NUMBER !== ''}
                                                        onBlur={onFieldBlur('WO_NUMBER')}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {workOrdersLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton disabled={isReportLocked || initValues?.workOrder !== undefined} onClick={() => handleWorkOrderOpen()}>
                                                <OpenInFull sx={{ fontSize: 36 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <FormHelperText>{fieldErrors.WO_NUMBER}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <VisibilityControl gridProps={{ xs: 4 }} value={visibilityConfig} onValueChange={handleVisibilityChange} disabled={isReportLocked} />
                        <Grid item>
                            <FormInput
                                value={roofSections}
                                onChange={handleRoofSectionsChange}
                                onBlur={onFieldBlur('ROOF_SECTIONS')}
                                disabled={isReportLocked}
                                label='Roof Section(s)'
                                name='roofSections'
                                error={fieldErrors.ROOF_SECTIONS !== ''}
                                errorText={fieldErrors.ROOF_SECTIONS}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>Project Information</Typography>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                value={presentedToClientName}
                                onChange={handlePresentedToClientNameChange}
                                onBlur={onFieldBlur('PRESENTED_TO_CLIENT')}
                                disabled={isReportLocked}
                                label='Client'
                                name='client'
                                error={fieldErrors.PRESENTED_TO_CLIENT !== ''}
                                errorText={fieldErrors.PRESENTED_TO_CLIENT}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={presentedToFacilityName}
                                onChange={handlePresentedToFacilityNameChange}
                                onBlur={onFieldBlur('PRESENTED_TO_FACILITY')}
                                disabled={isReportLocked}
                                label='Facility'
                                name='facility'
                                errorText={fieldErrors.PRESENTED_TO_FACILITY}
                                error={fieldErrors.PRESENTED_TO_FACILITY !== ''}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormNumberInput
                                value={squareFootage}
                                onChange={handleSquareFootageChange}
                                onBlur={onFieldBlur('SQUARE_FOOTAGE')}
                                disabled={isReportLocked}
                                label='Project Total Square Footage'
                                name='totalSquareFootage'
                                errorText={fieldErrors.SQUARE_FOOTAGE}
                                error={fieldErrors.SQUARE_FOOTAGE !== ''}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                value={presentedToContactName}
                                onChange={handlePresentedToContactNameChange}
                                onBlur={onFieldBlur('PRESENTED_TO_CONTACT_NAME')}
                                disabled={isReportLocked}
                                label='Contact Name'
                                name='presentedToContactName'
                                error={fieldErrors.PRESENTED_TO_CONTACT_NAME !== ''}
                                errorText={fieldErrors.PRESENTED_TO_CONTACT_NAME}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={presentedToAddressLine1}
                                onChange={handlePresentedToAddressLine1Change}
                                onBlur={onFieldBlur('PRESENTED_TO_ADDRESS')}
                                disabled={isReportLocked}
                                label='Address Line 1'
                                name='presentedToAddress1'
                                error={fieldErrors.PRESENTED_TO_ADDRESS !== ''}
                                errorText={fieldErrors.PRESENTED_TO_ADDRESS}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={presentedToAddressLine2}
                                onChange={handlePresentedToAddressLine2Change}
                                disabled={isReportLocked}
                                label='Address Line 2'
                                name='presentedToAddress2'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                value={presentedToCity}
                                onChange={handlePresentedToCityChange}
                                onBlur={onFieldBlur('PRESENTED_TO_CITY')}
                                disabled={isReportLocked}
                                label='City'
                                name='presentedToCity'
                                error={fieldErrors.PRESENTED_TO_CITY !== ''}
                                errorText={fieldErrors.PRESENTED_TO_CITY}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl error={fieldErrors.PRESENTED_TO_STATE !== ''} fullWidth required>
                                <FormLabel>State</FormLabel>
                                <Select
                                    value={presentedToState ?? ''}
                                    onChange={handlePresentedToStateChange}
                                    onBlur={onFieldBlur('PRESENTED_TO_STATE')}
                                    disabled={isReportLocked}>
                                    {usStates.map((usState) => (
                                        <MenuItem key={usState.abbreviation} value={usState.abbreviation}>
                                            {usState.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{fieldErrors.PRESENTED_TO_STATE}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={presentedToZip}
                                onChange={handlePresentedToZipChange}
                                onBlur={onFieldBlur('PRESENTED_TO_ZIP')}
                                disabled={isReportLocked}
                                label='Zip Code'
                                name='presentedToZip'
                                error={fieldErrors.PRESENTED_TO_ZIP !== ''}
                                errorText={fieldErrors.PRESENTED_TO_ZIP}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                onBlur={onFieldBlur('PHONE_NUMBER')}
                                disabled={isReportLocked}
                                label='Phone'
                                name='phoneNumber'
                                error={fieldErrors.PHONE_NUMBER !== ''}
                                errorText={fieldErrors.PHONE_NUMBER}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput value={weather} onChange={handleWeatherChange} disabled={isReportLocked} label='Weather' name='weather' fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={qaInspector}
                                onChange={handleQaInspectorChange}
                                onBlur={onFieldBlur('QA_INSPECTOR')}
                                disabled={isReportLocked}
                                label='QA Inspector'
                                name='qaInspector'
                                error={fieldErrors.QA_INSPECTOR !== ''}
                                errorText={fieldErrors.QA_INSPECTOR}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={othersPresent}
                                onChange={handleOthersPresentChange}
                                disabled={isReportLocked}
                                label='Others Present'
                                name='othersPresent'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>Contractor Information</Typography>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item container xs={4}>
                            <FormControl error={fieldErrors.CONTRACTOR !== ''} fullWidth required>
                                <FormLabel>Contractor</FormLabel>
                                <Grid item container alignItems={'center'} direction='row'>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            value={contractor ?? null}
                                            onChange={(event, value) => {
                                                setFormChanged(true);
                                                if (value) {
                                                    setContractor(value);
                                                    setContractorPhoneNumber(value.phoneNumber);
                                                    setContractorEmail(value.email);
                                                } else {
                                                    setContractor(undefined);
                                                    setContractorPhoneNumber('');
                                                    setContractorEmail('');
                                                }
                                            }}
                                            open={contractorOpen}
                                            onOpen={() => setContractorOpen(true)}
                                            onClose={() => setContractorOpen(false)}
                                            options={contractors?.pageResults.filter((option) => option.isActive || option.id === contractor?.id) ?? []}
                                            loading={contractorsLoading}
                                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                                            getOptionLabel={(option: ContractorDto) =>
                                                `${option.dispatchId} - ${option.name}${option.isActive ? '' : ' (Inactive)'}`
                                            }
                                            disabled={isReportLocked || initValues?.id !== emptyGuid}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={fieldErrors.CONTRACTOR !== ''}
                                                    onBlur={onFieldBlur('CONTRACTOR')}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {contractorsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <FormHelperText>{fieldErrors.CONTRACTOR}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={officeContact}
                                onChange={handleOfficeContactChange}
                                onBlur={onFieldBlur('OFFICE_CONTACT')}
                                disabled={isReportLocked}
                                label='Office Contact'
                                name='officeContact'
                                error={fieldErrors.OFFICE_CONTACT !== ''}
                                errorText={fieldErrors.OFFICE_CONTACT}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={contractorPhoneNumber}
                                onChange={handleContractorPhoneNumberChange}
                                onBlur={onFieldBlur('CONTRACTOR_PHONE_NUMBER')}
                                disabled={isReportLocked}
                                label='Phone'
                                name='contractorPhoneNumber'
                                error={fieldErrors.CONTRACTOR_PHONE_NUMBER !== ''}
                                errorText={fieldErrors.CONTRACTOR_PHONE_NUMBER}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                value={contractorEmail}
                                onChange={handleContractorEmailChange}
                                onBlur={onFieldBlur('CONTRACTOR_EMAIL')}
                                disabled={isReportLocked}
                                label='Contact Email'
                                name='contractorEmail'
                                error={fieldErrors.CONTRACTOR_EMAIL !== ''}
                                errorText={fieldErrors.CONTRACTOR_EMAIL}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={foremanOnJob}
                                onChange={handleForemanOnJobChange}
                                onBlur={onFieldBlur('FOREMAN_ON_JOB')}
                                disabled={isReportLocked}
                                label='Foreman on Job'
                                name='foremanOnJob'
                                error={fieldErrors.FOREMAN_ON_JOB !== ''}
                                errorText={fieldErrors.FOREMAN_ON_JOB}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={foremanPhoneNumber}
                                onChange={handleForemanPhoneNumberChange}
                                onBlur={onFieldBlur('FOREMAN_PHONE_NUMBER')}
                                disabled={isReportLocked}
                                label='Foreman Cell #'
                                name='foremanPhoneNumber'
                                error={fieldErrors.FOREMAN_PHONE_NUMBER !== ''}
                                errorText={fieldErrors.FOREMAN_PHONE_NUMBER}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8} alignItems='center'>
                        <Grid item xs={4}>
                            <FormNumberInput
                                value={crewSizeOnsite}
                                onChange={handleCrewSizeOnsiteChange}
                                onBlur={onFieldBlur('CREW_SIZE_ONSITE')}
                                disabled={isReportLocked}
                                label='Crew Size Onsite'
                                name='crewSizeOnsite'
                                error={fieldErrors.CREW_SIZE_ONSITE !== ''}
                                errorText={fieldErrors.CREW_SIZE_ONSITE}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Switch checked={workCompliant} onChange={handleWorkCompliantChange} />}
                                label='Work Compliant?'
                                labelPlacement='start'
                                disabled={isReportLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>Summary</Typography>
                    </Grid>
                    <Grid item>
                        <FormInput
                            value={projectSummary}
                            onChange={handleProjectSummaryChange}
                            disabled={isReportLocked}
                            label='Project Summary'
                            name='projectSummary'
                            rows={4}
                            fullWidth
                            error={fieldErrors.PROJECT_SUMMARY !== ''}
                            errorText={fieldErrors.PROJECT_SUMMARY}
                            maxLength={3200}
                            onBlur={onFieldBlur('PROJECT_SUMMARY')}
                        />
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>Quality Assurance Checklist</Typography>
                    </Grid>
                    <Grid item>
                        <QualityAssuranceChecklist
                            checklistItems={qualityAssuranceChecklistItems}
                            handleRadioButtonClicked={handleRadioButtonClicked}
                            disabled={isReportLocked}
                        />
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>Notes</Typography>
                    </Grid>
                    <Grid item>
                        <FormInput
                            value={inspectorNotes}
                            onChange={handleInspectorNotesChange}
                            disabled={isReportLocked}
                            label='Inspector Notes'
                            name='inspectorNotes'
                            rows={4}
                            fullWidth
                        />
                    </Grid>
                </FormSection>
            </Grid>
            {
                initValues && initValues.id !== emptyGuid && (
                    <>
                        <Grid item container direction='row'>
                            <Grid item xs={12}>
                                <DashboardCard headerTitle='Photos' headerIcon={<PhotoLibrary />}>
                                    <Grid item sx={{ padding: '24px' }} ref={photosRef}>
                                        <PhotosDisplay
                                            attachments={photos}
                                            setAttachments={setPhotos}
                                            attachmentCaptions={photoCaptions}
                                            setAttachmentCaptions={setPhotoCaptions}
                                            attachmentHoverIndex={photoHoverIndex}
                                            setAddPhotoIsOpen={setAddPhotoIsOpen}
                                            setAttachmentHoverIndex={setPhotoHoverIndex}
                                            deleteSelectedPhoto={deleteSelectedPhoto}
                                            photoPreviewOnClick={photoPreviewOnClick}
                                            handleCaptionInput={handleCaptionInput}
                                            photoEditOnClick={handlePhotoEditOnClick}
                                            disabled={isReportLocked}
                                        />
                                    </Grid>
                                </DashboardCard>
                            </Grid>
                        </Grid>
                        <AuthenticatedComponent
                            requiredPermissions={['read:reports']}
                            component={
                                <Grid item ref={rolloverItemRef}>
                                    <DashboardCard headerTitle='Rollover List Items' headerIcon={<FormatListBulletedOutlined />}>
                                        <JsiReportRolloverItemListView report={initValues} disabled={isReportLocked} />
                                    </DashboardCard>
                                </Grid>
                            }
                        />
                    </>
                )
            }
            <SelectWorkOrdersDialog
                open={isSelectWorkOrdersDialogOpen}
                onCancel={handleWorkOrdersDialogClose}
                onConfirm={handleWorkOrdersDialogConfirm}
                isMultipleSelectable={false}
                currentWorkOrders={workOrders?.pageResults.filter((x) => x.id === workOrder?.id) ?? []}
                workOrderType={1}
            />
            <MultiPhotoUpload
                open={addPhotoIsOpen}
                onClose={() => setAddPhotoIsOpen(false)}
                afterPhotoUpload={afterPhotoUpload}
                allowedFileTypes={['jpg', 'jpeg', 'png']}
            />
            {
                viewPhotoIsOpen && (
                    <ViewPhotoDialog
                        open={viewPhotoIsOpen}
                        onClose={() => setViewPhotoIsOpen(false)}
                        attachment={selectedPhoto}
                        previewLink={selectedPhoto?.previewLink}
                        caption={selectedCaption}
                    />
                )
            }
            {
                editPhotoIsOpen && selectedPhoto && (
                    <PhotoEditorDialog
                        open={editPhotoIsOpen}
                        onClose={() => setEditPhotoIsOpen(false)}
                        onUpdate={handleUpdateEditedPhoto}
                        attachment={selectedPhoto}
                    />
                )
            }
            {
                workOrder && (
                    <SelectClientReportDialog
                        open={isSelectExistingReportDialogOpen}
                        onCancel={handleExistingReportDialogClose}
                        onConfirm={handleExistingReportDialogConfirm}
                        reportType={jsiReportType}
                        facilityId={workOrder.facilityId}
                        clientId={workOrder.facilityClientId ?? emptyGuid}
                    />
                )
            }
        </Grid >
    );
};
