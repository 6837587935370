import { SearchQueryParams, serverApi } from '..';
import { ManufacturerDto, QueryResultsDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const manufacturerApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getManufacturers: builder.query<QueryResultsDto<ManufacturerDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('manufacturer', params),
            providesTags: [{ type: 'Manufacturer' }],
        }),
        createManufacturer: builder.mutation<ManufacturerDto, ManufacturerDto>({
            query: (dto) => ({
                url: `manufacturer`,
                method: 'POST',
                body: dto,
            }),
            invalidatesTags: [{ type: 'Manufacturer' }],
        }),
    }),
});

export const { useGetManufacturersQuery, useCreateManufacturerMutation } = manufacturerApi;
