import { serverApi } from '..';
import { DocumentDto, QueryResultsDto, WorkOrderDispatchDto } from '../../models';
import { ChildSearchQueryParams } from '../types';

const workOrderDispatchApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkOrderDispatchDocuments: builder.query<QueryResultsDto<DocumentDto>, ChildSearchQueryParams>({
            query: (params) => ({
                url: `workorderdispatch/${params.parentId}/documents`,
                params,
            }),
            providesTags: [{ type: 'Dispatch' }, { type: 'Document' }],
        }),
        updateWorkOrderDispatch: builder.mutation<void, WorkOrderDispatchDto>({
            query: (workOrderDispatchDto) => ({
                url: 'workorderdispatch',
                method: 'PUT',
                body: workOrderDispatchDto,
            }),
            invalidatesTags: [{ type: 'Dispatch' }],
        }),
        completeWorkOrderDispatch: builder.mutation<void, WorkOrderDispatchDto>({
            query: (workOrderDispatchDto) => ({
                url: `workorderdispatch/complete`,
                method: 'PUT',
                body: workOrderDispatchDto,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Work Order Status History' }],
        })
    }),
});

export const {
    useUpdateWorkOrderDispatchMutation,
    useCompleteWorkOrderDispatchMutation,
    useGetWorkOrderDispatchDocumentsQuery
} = workOrderDispatchApi;
