import { FilterList, MoreVert, Search, Warning } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Tooltip,
    TextField,
    InputAdornment,
    MenuItem,
    Divider,
    Menu
} from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { StandardDialogHeader } from '../../Forms/StandardDialogHeader';
import AuthenticatedComponent from '../../../auth/AuthenticatedComponent';
import {
    useArchiveWorkOrderMutation,
    useGetClientWorkOrdersQuery,
    useLazyGetClientWorkOrdersQuery,
    useUnarchiveWorkOrderMutation,
} from '../../../store/apis/work-order-api';
import { Delete } from '@mui/icons-material';
import { Chip, FormControlLabel, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePermissionChecker } from '../../../Hooks';
import { useWorkOrderExporter } from '../../../Hooks/useWorkOrderExporter';
import { ClientDto, FacilityDto, WorkOrderStatusDto, WorkOrderTypeDto } from '../../../models';
import { useGetClientQuery } from '../../../store/apis/client-api';
import { useGetWorkOrderTypesQuery } from '../../../store/apis/work-order-type-api';
import { ContractorDto } from '../../../models/ContractorDto';
import { getStandardWorkOrderTableColumns } from '../../../util';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { exportToCsv } from '../../../util/fileDownloadHelper';
import { ApiError } from '../../core/ApiError';
import { PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { FilterWorkOrdersAndCapitalProjectsDialog } from './FilterWorkOrdersAndCapitalProjectsDialog';
import { IClientCardProps } from './types';

export interface IOutstandingProposalsModalProps extends IClientCardProps {
    open: boolean;
    onClose: () => void;
    isClientDashboardView?: boolean;
}

export const OutstandingProposalsModal: FunctionComponent<IOutstandingProposalsModalProps> = (props) => {
    const { clientId, facility, isClientDashboardView, open, onClose } = props;
    const { data: client } = useGetClientQuery(clientId!);
    const { data: types } = useGetWorkOrderTypesQuery({
        pageSize: 100000,
    });
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [selected, setSelected] = useState<readonly number[]>([]);
    const navigate = useNavigate();
    const { userHasAnyPermission, userHasPermission } = usePermissionChecker();
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [showInactive, setShowInactive] = useState(false);
    const [statusFilter, setStatusFilter] = useState<WorkOrderStatusDto>();
    const [dispatchDateAfterFilter, setDispatchDateAfterFilter] = useState<Date | null | undefined>();
    const [dispatchDateBeforeFilter, setDispatchDateBeforeFilter] = useState<Date | null | undefined>();
    const [contractorFilter, setContractorFilter] = useState<ContractorDto>();
    const [clientFilter, setClientFilter] = useState<ClientDto | null | undefined>();
    const [facilityFilter, setFacilityFilter] = useState<FacilityDto | null>();
    const [typeFilter, setTypeFilter] = useState<WorkOrderTypeDto | undefined>();
    const [filterOpen, setFilterOpen] = useState(false);
    const { data, isLoading, error, refetch } = useGetClientWorkOrdersQuery({
        parentId: clientId,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
            clientId: clientFilter?.id,
            workOrderTypeId: typeFilter?.id,
            facilityId: facility?.id ?? facilityFilter?.id,
            dispatchDateAfter: dispatchDateAfterFilter?.toISOString(),
            dispatchDateBefore: dispatchDateBeforeFilter?.toISOString(),
            contractorId: contractorFilter?.id,
            status: statusFilter?.id,
            isClientDashboardView: isClientDashboardView
        },
    });
    const [getWorkOrdersForExport] = useLazyGetClientWorkOrdersQuery();
    const [archiveWorkOrder, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveWorkOrderMutation();
    const [unarchiveWorkOrder, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveWorkOrderMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const userCanViewWorkOrderCosts = useMemo(() => userHasPermission('read:workOrderCosts'), [userHasPermission]);
    const tableColumns = useMemo(() => getStandardWorkOrderTableColumns(userCanViewWorkOrderCosts, false, 'WO #', false, true), [userCanViewWorkOrderCosts]);
    const { getExportData } = useWorkOrderExporter();

    const totalCostOutstandingProposals = useMemo(() => data?.pageResults?.reduce((acc, wo) => acc + (wo.isActive ? wo.proposedCost ?? 0 : 0), 0) ?? 0, [data]);
    const title = useMemo(() => `Outstanding Proposals - Total: $${totalCostOutstandingProposals?.toFixed(2) ?? 0}`, [totalCostOutstandingProposals]);

    const entityName = useMemo(() => {
        return selected.length === 1 ? 'Work Order' : 'Work Orders';
    }, [selected.length]);

    const handleActionRequestCompletion = useCallback((resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    }, []);

    useFailedActionSnackbar('inactivate', entityName, isArchiveError, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useFailedActionSnackbar('activate', entityName, isUnarchiveError, () => {
        handleActionRequestCompletion(resetUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', entityName, isArchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useSuccessfulActionSnackbar('Activated', entityName, isUnarchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetUnarchive);
    });

    const handleFilter = useCallback(() => {
        setFilterOpen(true);
    }, []);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) afterClose();
        },
        []
    );

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleMenuEdit = useCallback(() => {
        const selectedProjectId = data?.pageResults[selected[0]].id;
        if (selectedProjectId) {
            if (facility) {
                navigate(`/workorders/edit/${selectedProjectId}/facility/${facility.id}`);
            } else if (clientId) {
                navigate(`/workorders/edit/${selectedProjectId}/client/${clientId}`);
            } else {
                navigate(`/workorders/edit/${selectedProjectId}`);
            }
        }
    }, [clientId, data?.pageResults, facility, navigate, selected]);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleDispatchAfterFilterDelete = useCallback(() => {
        setDispatchDateAfterFilter(undefined);
    }, []);

    const handleDispatchBeforeFilterDelete = useCallback(() => {
        setDispatchDateBeforeFilter(undefined);
    }, []);

    const handleStatusFilterDelete = useCallback(() => {
        setStatusFilter(undefined);
    }, []);

    const handleDispatchFilterDelete = useCallback(() => {
        setContractorFilter(undefined);
    }, []);

    const handleFacilityFilterDelete = useCallback(() => {
        setFacilityFilter(undefined);
    }, []);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveWorkOrder(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [data?.pageResults, selected, unarchiveWorkOrder]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveWorkOrder(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [archiveWorkOrder, data?.pageResults, selected]);

    const canSetActive = useMemo(() => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useMemo(() => {
        return selected.some((index) => data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const handleRowEdit = useCallback(
        (id: string) => {
            if (facility) {
                navigate(`/workorders/edit/${id}/facility/${facility.id}`);
            } else if (clientId) {
                navigate(`/workorders/edit/${id}/client/${clientId}`);
            } else {
                navigate(`/workorders/edit/${id}`);
            }
        },
        [clientId, facility, navigate]
    );

    const handleExport = useCallback(async () => {
        var allOrders = await getWorkOrdersForExport({
            parentId: clientId,
            params: {
                orderType: 0,
                facilityId: facility?.id,
                dispatchDateAfter: dispatchDateAfterFilter?.toISOString(),
                dispatchDateBefore: dispatchDateBeforeFilter?.toISOString(),
                contractorId: contractorFilter?.id,
                status: statusFilter?.id,
                clientId: clientFilter?.id,
                workOrderTypeId: typeFilter?.id,
            },
        });
        if (allOrders.data?.pageResults) {
            const { headers, data } = getExportData(allOrders.data?.pageResults, false);
            exportToCsv(data, headers, `Work Orders ${new Date().toDateString()}`);
        }
    }, [getWorkOrdersForExport, clientId, facility?.id, dispatchDateAfterFilter, dispatchDateBeforeFilter, contractorFilter?.id, statusFilter?.id, clientFilter?.id, typeFilter?.id, getExportData]);

    useMemo(() => {
        setClientFilter(client);
    }, [client]);

    useMemo(() => {
        setTypeFilter(types?.pageResults.find((t) => t.name === 'Proposal Acceptance Pending')!);
    }, [types]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Dialog open={open} maxWidth='xl' fullWidth>
            <StandardDialogHeader icon={<Warning sx={{ color: 'white' }} />} title={title} />
            <DialogContent>
                <Grid container direction='column' sx={{ marginTop: '10px' }} spacing={2}>
                    <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                        <Grid item container direction='row' alignItems='center' xs={6} spacing={1} sx={{ height: '100%', paddingLeft: '12px', marginTop: '0px' }}>
                            {dispatchDateAfterFilter && (
                                <Grid item>
                                    <Chip label={`Dispatch Date After: ${dispatchDateAfterFilter.toLocaleDateString()}`} color='secondary' onDelete={handleDispatchAfterFilterDelete} />
                                </Grid>
                            )}
                            {dispatchDateBeforeFilter && (
                                <Grid item>
                                    <Chip label={`Dispatch Date Before: ${dispatchDateBeforeFilter.toLocaleDateString()}`} color='secondary' onDelete={handleDispatchBeforeFilterDelete} />
                                </Grid>
                            )}
                            {statusFilter && (
                                <Grid item>
                                    <Chip label={`Status: ${statusFilter.name}`} color='secondary' onDelete={handleStatusFilterDelete} />
                                </Grid>
                            )}
                            {contractorFilter && (
                                <Grid item>
                                    <Chip label={`Dispatch ID: ${contractorFilter.dispatchId}`} color='secondary' onDelete={handleDispatchFilterDelete} />
                                </Grid>
                            )}
                            {facilityFilter && (
                                <Grid item>
                                    <Chip label={`Facility: ${facilityFilter.name}`} color='secondary' onDelete={handleFacilityFilterDelete} />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item container direction='row' alignItems='center' xs={6} spacing={1} justifyContent='flex-end'>
                            <AuthenticatedComponent
                                requiredPermissions={['delete:workorders']}
                                component={
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                            label='Show Inactive'
                                            labelPlacement='start'
                                        />
                                    </Grid>
                                }
                            />
                            <AuthenticatedComponent
                                requiredPermissions={['delete:workorders']}
                                component={
                                    <Grid item xs={1}>
                                        <Tooltip title='Make Inactive'>
                                            <span>
                                                <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive}>
                                                    <Delete fontSize='inherit' />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                }
                            />
                            <Grid item>
                                <Tooltip title={'Filter'}>
                                    <IconButton color='primary' size='large' onClick={handleFilter}>
                                        <FilterList fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:workordersFull', 'edit:workordersLimited']}
                                        logic='or'
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <Divider />
                                    <MenuItem onClick={handleMenuClose(handleFilter)}>Filter</MenuItem>
                                    <MenuItem onClick={handleMenuClose(handleExport)}>Export</MenuItem>
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:workorders']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id='search-box'
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    label='Search'
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row'>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isLoading}
                            queryResults={data}
                            defaultSortKey='WO_NUMBER'
                            setPagination={setPaginatedProps}
                            selected={selected}
                            setSelected={setSelected}
                            editPermissions={{ requiredPermissions: ['edit:workordersFull', 'edit:workordersLimmited'], logic: 'or' }}
                            onEdit={handleRowEdit}
                            disableSelection={!userHasAnyPermission(['create:workorders', 'edit:workordersFull', 'edit:workordersLimited', 'delete:workorders'])}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
            {filterOpen && (
                <FilterWorkOrdersAndCapitalProjectsDialog
                    open={filterOpen}
                    close={() => setFilterOpen(false)}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    dispatchDateAfterFilter={dispatchDateAfterFilter}
                    dispatchDateBeforeFilter={dispatchDateBeforeFilter}
                    setDispatchDateAfterFilter={setDispatchDateAfterFilter}
                    setDispatchDateBeforeFilter={setDispatchDateBeforeFilter}
                    contractorFilter={contractorFilter}
                    setContractorFilter={setContractorFilter}
                    clientFilter={clientFilter}
                    setClientFilter={setClientFilter}
                    facilityFilter={facilityFilter}
                    setFacilityFilter={setFacilityFilter}
                    typeFilter={typeFilter}
                    setTypeFilter={setTypeFilter}
                    isCapitalProject={false}
                    isOutstandingProposal={true}
                    isClientFacing
                />
            )}
        </Dialog >
    );
};
