import { AddCircle, Delete, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import AuthenticatedComponent from '../../../auth';
import { JsiReportRolloverItemDto } from '../../../models/JsiReportRolloverItemDto';
import {
    useArchiveJsiReportRolloverItemMutation, useGetJsiReportRolloverItemsQuery, useUnarchiveJsiReportRolloverItemMutation
} from '../../../store/apis/jsi-report-rollover-item-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { AddEditJsiReportRolloverItemDialog } from './AddEditJsiReportRolloverItemDialog';
import { getDueDate, IJsiReportRolloverItemListViewProps } from './types';

export const JsiReportRolloverItemListView: FunctionComponent<IJsiReportRolloverItemListViewProps> = React.memo((props) => {
    const { report, disabled } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [dialogInstanceId, setDialogInstanceId] = useState(1);
    const [isAddNewRolloverItemOpen, setIsAddNewRolloverItemOpen] = useState(false);
    const [selectedRolloverItem, setSelectedRolloverItem] = useState<JsiReportRolloverItemDto | undefined>(undefined);

    const { data, error, isLoading, refetch } = useGetJsiReportRolloverItemsQuery({
        parentId: report.id,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
        },
    });
    const [archiveSection, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveJsiReportRolloverItemMutation();
    const [unarchiveSection, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveJsiReportRolloverItemMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);

    const tableColumns: DataTableColumn<JsiReportRolloverItemDto>[] = [
        {
            key: 'itemDate',
            label: 'Date',
            sortKey: 'ITEM_DATE',
            fieldMapper: (row) => (row.itemDate ? format(new Date(row.itemDate.toString()), 'M/d/yyyy') : ''),
        },
        { key: 'area', label: 'Area', sortKey: 'AREA', fieldMapper: (row) => (row.area ? row.area : '') },
        { key: 'actionBy', label: 'Action By', sortKey: 'ACTION_BY', fieldMapper: (row) => (row.actionBy ? row.actionBy : '') },
        { key: 'dueDate', label: 'Due Date', sortKey: 'DUEDATE', fieldMapper: (row) => (row.dueDate ? getDueDate(row.dueDate) : '') },
        {
            key: 'dateCompleted',
            label: 'Date Completed',
            sortKey: 'DATE_COMPLETED',
            fieldMapper: (row) => (row.dateCompleted ? format(new Date(row.dateCompleted.toString()), 'M/d/yyyy') : ''),
        },
        { key: 'description', label: 'Description', sortKey: 'DESCRIPTION', width: '600px', fieldMapper: (row) => (row.description ? row.description : '') },
    ];

    const getEntityName = () => {
        return selected.length === 1 ? 'Rollover Item' : 'Rollover Items';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => { handleActionRequestCompletion(resetArchive); });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => { handleActionRequestCompletion(resetUnarchive); });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    };

    const handleCreateNew = () => {
        setDialogInstanceId(dialogInstanceId + 1);
        setIsAddNewRolloverItemOpen(true);
    };

    const handleMenuEdit = () => {
        const roofSectionReport = data?.pageResults[selected[0]];
        if (roofSectionReport) {
            setDialogInstanceId(dialogInstanceId + 1);
            setSelectedRolloverItem(roofSectionReport);
            setIsAddNewRolloverItemOpen(true);
        }
    };

    const handleRowEdit = (id: string) => {
        const roofSectionReport = data?.pageResults.find((x) => x.id === id);
        if (roofSectionReport) {
            setDialogInstanceId(dialogInstanceId + 1);
            setSelectedRolloverItem(roofSectionReport);
            setIsAddNewRolloverItemOpen(true);
        }
    };

    const handleSetActive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveSection({ parentId: data?.pageResults[index].id!, childId: data?.pageResults[index].id! });
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    const handleSetInactive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveSection({ parentId: data?.pageResults[index].id!, childId: data?.pageResults[index].id! });
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    const handleAddRolloverItemClose = (keepOpen: boolean) => {
        setIsAddNewRolloverItemOpen(keepOpen);
        setSelectedRolloverItem(undefined);
    };

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index]?.isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index]?.isActive);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    const disableEdit = (__: string) => {
        if (disabled) {
            return disabled;
        } else {
            return false;
        }
    };

    return (
        <>
            <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
                <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                    <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                    <Grid item container direction='row' alignItems='center' xs={6}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                label='Show Inactive'
                                labelPlacement='start'
                            />
                        </Grid>
                        <AuthenticatedComponent
                            requiredPermissions={['delete:reports']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title='Make Inactive'>
                                        <span>
                                            <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive() || disabled}>
                                                <Delete fontSize='inherit' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['create:reports']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title='Add New'>
                                        <IconButton color='primary' size='large' onClick={handleCreateNew} disabled={disabled}>
                                            <AddCircle fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['create:reports', 'edit:reports', 'delete:reports']}
                            logic='or'
                            component={
                                <Grid item xs={1}>
                                    <IconButton size='large' onClick={handleMenuOpen}>
                                        <MoreVert fontSize='inherit' />
                                    </IconButton>
                                    <Menu
                                        anchorEl={menuAnchorEl}
                                        open={menuOpen}
                                        onClose={handleMenuClose()}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}>
                                        <AuthenticatedComponent
                                            requiredPermissions={['create:reports']}
                                            component={
                                                <MenuItem onClick={handleMenuClose(handleCreateNew)} disabled={disabled}>
                                                    Add New
                                                </MenuItem>
                                            }
                                        />
                                        <AuthenticatedComponent
                                            requiredPermissions={['edit:reports']}
                                            component={
                                                <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1 || disabled}>
                                                    Edit
                                                </MenuItem>
                                            }
                                        />
                                        <AuthenticatedComponent
                                            requiredPermissions={['delete:reports']}
                                            component={
                                                <>
                                                    <Divider />
                                                    <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive() || disabled}>
                                                        Make Active
                                                    </MenuItem>
                                                    <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive() || disabled}>
                                                        Make Inactive
                                                    </MenuItem>
                                                </>
                                            }
                                        />
                                    </Menu>
                                </Grid>
                            } />
                        <Grid item xs={6}>
                            <TextField
                                id='search-box'
                                value={searchText}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Search'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <PaginatedDataTable
                        columns={tableColumns}
                        loading={isLoading}
                        queryResults={data}
                        defaultSortKey='ITEM_DATE'
                        setPagination={setPaginatedProps}
                        selected={selected}
                        setSelected={setSelected}
                        editPermissions={{ requiredPermissions: ['edit:reports'] }}
                        onEdit={handleRowEdit}
                        disableEdit={disableEdit}
                    />
                </Grid>
            </Grid>
            <AddEditJsiReportRolloverItemDialog
                key={dialogInstanceId}
                open={isAddNewRolloverItemOpen}
                onClose={handleAddRolloverItemClose}
                report={report}
                initValues={selectedRolloverItem}
            />
        </>
    );
});
