import { Grid } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ConfirmDialog } from '../ConfirmDialog';
import { IQuickAddDialogProps } from './types';
import { FormInput } from '../../Forms/FormFields';

export const QuickAddDialog: FunctionComponent<IQuickAddDialogProps> = (props) => {
    const { open,
        onClose,
        title,
        titleIcon,
        cancelText,
        confirmText,
        initValue,
        formLabel,
        save,
        multiline,
        disabled = false
    } = props;

    const [formText, setFormText] = useState<string>(initValue ?? '');
    const [errorText, setErrorText] = useState('');

    const beforeClose = useCallback(() => {
        onClose();
        setFormText('');
        setErrorText('');
    }, [onClose]);

    useEffect(() => {
        if (initValue) {
            setFormText(initValue);
        }
    }, [initValue]);

    const onSave = () => {
        if (formText !== '') {
            save(formText);
            beforeClose();
        } else {
            setErrorText('This field is required');
        }
    };

    return (
        <ConfirmDialog
            open={open}
            onClose={beforeClose}
            content={
                <Grid container direction='column' marginTop={2}>
                    <FormInput
                        value={formText}
                        onChange={(e) => {
                            setFormText(e.target.value);
                        }}
                        label={formLabel}
                        errorText={errorText}
                        error={errorText !== ''}
                        fullWidth
                        required
                        rows={multiline ? 3 : undefined}
                        disabled={disabled}
                    />
                </Grid>
            }
            title={title}
            titleIcon={titleIcon}
            cancelText={cancelText}
            confirmText={confirmText}
            onConfirm={onSave}
        />
    );
};
