import { PictureAsPdf } from '@mui/icons-material';
import { FunctionComponent } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { IClientConfirmPdfDialogProps } from '../../core/ConfirmPdfDialog/types';
import React from 'react';

export const ConfirmPdfDialog: FunctionComponent<IClientConfirmPdfDialogProps> = React.memo(props => {
    const { open, close, generatePdf } = props;

    const handleConfirm = () => {
        close();
        generatePdf();
    };

    return (
        <Dialog open={open} onClose={close} maxWidth='sm' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', marginBottom: '16px', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <PictureAsPdf />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Confirm Generate PDF
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>The previously-generated PDF will be deleted when you update this report. Do you want to continue?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={close}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={handleConfirm}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
});
