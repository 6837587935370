import { FunctionComponent, useCallback, useState } from 'react';
import { Button, FormControl, FormLabel, Grid, OutlinedInput, Paper, Typography } from '@mui/material';
import React from 'react';
import { ArrowBack, ArrowDownward, ArrowForward, ArrowUpward, Info, PanTool } from '@mui/icons-material';
import Draggable from 'react-draggable';
import { RoofSectionDto } from '../../../models';
import { getShortenedString, IRoofLeakDetailsDialog } from './types';
import { RoofLeakInfoCard } from './RoofLeakInfoCard';
import { Point } from '../../Forms/RoofMap';
import { IEntityAutocomplete } from '../IEntityAutocomplete';
import AuthenticatedComponent from '../../../auth/AuthenticatedComponent';

export const RoofLeakDetailsDialog: FunctionComponent<IRoofLeakDetailsDialog> = (props) => {
    const { open, onClose, onConfirm, roofLeak, idx, anchorEl, handleRoofLeakMove, roofSections = [], showDetails = false } = props;

    const [originalPosition] = useState<Point>({ x: roofLeak.x ?? 0, y: roofLeak.y ?? 0 });
    const [position, setPosition] = useState<Point>({ x: roofLeak.x ?? 0, y: roofLeak.y ?? 0 });
    const [pixels, setPixels] = useState(5);
    const [roofSection, setRoofSection] = useState<RoofSectionDto | null>(roofLeak.roofSection ?? null);

    const nodeRef = React.useRef(null);

    const calculateNewCoords = useCallback(
        (direction: string) => {
            const newPosition = { ...position };

            switch (direction) {
                case 'u':
                    newPosition.y! -= pixels;
                    break;
                case 'l':
                    newPosition.x! -= pixels;
                    break;
                case 'r':
                    newPosition.x! += pixels;
                    break;
                case 'd':
                    newPosition.y! += pixels;
                    break;
                default:
                    return originalPosition;
            }

            setPosition({ ...newPosition });

            return newPosition;
        },
        [originalPosition, pixels, position]
    );

    const handleArrowClick = useCallback(
        (direction: string) => {
            if (pixels) {
                const coords = calculateNewCoords(direction);
                handleRoofLeakMove(coords);
            }
        },
        [calculateNewCoords, handleRoofLeakMove, pixels]
    );

    const handleCancel = useCallback(() => {
        handleRoofLeakMove(calculateNewCoords(''));

        onClose();
    }, [calculateNewCoords, handleRoofLeakMove, onClose]);

    if (!open) {
        return <></>;
    }

    return (
        <Draggable nodeRef={nodeRef} key={idx}>
            <Paper
                sx={{ position: 'absolute', top: anchorEl.pageY - 150, left: anchorEl.pageX + 50, zIndex: 1, minWidth: showDetails ? '800px' : '400px' }}
                ref={nodeRef}>
                {showDetails ? (
                    <Grid
                        container
                        direction='row'
                        wrap='nowrap'
                        sx={{ backgroundColor: '#266490', color: 'white', cursor: 'grab', padding: '10px' }}
                        alignItems='center'>
                        <Grid item sx={{ paddingRight: '20px' }}>
                            <Info fontSize='large' />
                        </Grid>
                        <Grid item>
                            <Typography variant='h3'>Roof Leak Info</Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                <Grid container direction='row' wrap='nowrap' spacing={3}>
                    {showDetails ? (
                        <Grid item>
                            <RoofLeakInfoCard roofLeak={roofLeak} />
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <AuthenticatedComponent
                        requiredPermissions={['edit:roofLeaks']}
                        unauthorizedComponent={
                            <Grid item container direction='column'>
                                <Grid item container justifyContent='flex-end' spacing={2} sx={{ padding: '10px', marginTop: '40%' }}>
                                    <Grid item>
                                        <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        component={
                            <Grid item container direction='column'>
                                {!showDetails ? (
                                    <Grid container style={{ backgroundColor: '#266490', color: 'white', cursor: 'grab', padding: '20px', width: '400px' }}>
                                        <Grid item sx={{ paddingRight: '20px' }}>
                                            <PanTool />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='h3'>Move Roof Leak</Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                <Grid container direction='column' style={{ padding: '20px' }} alignItems='center' spacing={1}>
                                    <Grid item container direction='row' spacing={6}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <FormLabel>Roof Leak #</FormLabel>
                                                <OutlinedInput value={idx + 1} disabled />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth required>
                                                <FormLabel>Roof Section</FormLabel>
                                                <IEntityAutocomplete
                                                    options={roofSections}
                                                    onChange={(e, value) => {
                                                        setRoofSection(value ?? null);
                                                    }}
                                                    value={roofSection}
                                                    getOptionLabel={(option: RoofSectionDto) => option.roofNumber}
                                                    error={roofLeak.roofSection === null}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {showDetails ? (
                                        <Grid item>
                                            <Typography>{getShortenedString(roofLeak.details)}</Typography>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                    <Grid item container justifyContent='center'>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                sx={{ width: '72px', height: '72px' }}
                                                onClick={() => handleArrowClick('u')}>
                                                <ArrowUpward />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent='center' alignItems='center' spacing={1}>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                sx={{ width: '72px', height: '72px' }}
                                                onClick={() => handleArrowClick('l')}>
                                                <ArrowBack />
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <OutlinedInput
                                                value={pixels}
                                                type='number'
                                                inputProps={{
                                                    max: 99,
                                                    min: 0,
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    if ((e.target.valueAsNumber <= 99 && e.target.valueAsNumber >= 0) || e.target.value === '') {
                                                        setPixels(e.target.valueAsNumber);
                                                    }
                                                }}
                                                sx={{ width: '72px', height: '72px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                sx={{ width: '72px', height: '72px' }}
                                                onClick={() => handleArrowClick('r')}>
                                                <ArrowForward />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent='center'>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                sx={{ width: '72px', height: '72px' }}
                                                onClick={() => handleArrowClick('d')}>
                                                <ArrowDownward />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent='flex-end' spacing={2} sx={{ padding: '10px' }}>
                                    <Grid item>
                                        <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => {
                                                onConfirm(roofSection?.id);
                                            }}
                                            disabled={roofLeak.roofSectionId === undefined || roofLeak.roofSectionId === ''}>
                                            {showDetails ? 'Save' : 'Confirm'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }></AuthenticatedComponent>
                </Grid>
            </Paper>
        </Draggable>
    );
};
