import { serverApi } from '..';
import { QueryResultsDto, NoteDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';
import { ChildQueryParams, ChildSearchQueryParams } from '../types';

const noteApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkOrderNotes: builder.query<QueryResultsDto<NoteDto>, ChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`workorder/${params.parentId}/note`, params.params),
            providesTags: [{ type: 'Note' }]
        }),
        createWorkOrderNote: builder.mutation<NoteDto, NoteDto>({
            query: (noteDto) => ({
                url: `workorder/${noteDto.workOrderId}/note`,
                method: 'POST',
                body: noteDto
            }),
            invalidatesTags: [{ type: 'Note' }]
        }),
        archiveNote: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `workorder/${params.parentId}/note/${params.childId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Note' }]
        }),
        unarchiveNote: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `workorder/${params.parentId}/note/${params.childId}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Note' }]
        }),
        updateNote: builder.mutation<void, NoteDto>({
            query: (noteDto) => ({
                url: `workorder/${noteDto.workOrderId}/note`,
                method: 'PUT',
                body: noteDto
            }),
            invalidatesTags: [{ type: 'Note' }]
        })
    })
});

export const {
    useGetWorkOrderNotesQuery,
    useCreateWorkOrderNoteMutation,
    useArchiveNoteMutation,
    useUnarchiveNoteMutation,
    useUpdateNoteMutation
} = noteApi;