import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserForm } from '../../Components/Forms/UserForm';
import { UserDto } from '../../models';
import { useCreateUserMutation } from '../../store/apis/user-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddUserView: FunctionComponent = () => {
    const navigate = useNavigate();
    const [createUser, { data: createdUser, isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate }] = useCreateUserMutation();

    useFailedCreateSnackbar('user', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('User', isCreateSuccess, () => navigate(`/users/edit/${createdUser?.id}`));

    const handleSave = (values: UserDto) => {
        createUser(values);
    };

    const handleCancel = () => {
        navigate('/users');
    };

    return <UserForm save={handleSave} cancel={handleCancel} />;
};
