import * as AzureStorageBlob from '@azure/storage-blob';
import Resizer from 'react-image-file-resizer';

export interface FileUploadResponse {
    isSuccessful: boolean;
    fileName?: string;
}

// dataURLToBlob method found at: https://stackoverflow.com/q/27159179
export const dataURLToFile = (dataURL: string, fileName: string) => {
    const BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        const parts = dataURL.split(',');
        const contentType = parts[0].split(':')[1];
        const raw = decodeURIComponent(parts[1]);
        return new File([new Blob([raw], { type: contentType })], fileName);
    }
    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new File([new Blob([uInt8Array], { type: contentType })], fileName);
};

const resizeFile = (file: File) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1920,
                1080,
                'JPEG',
                70,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file'
            );
        });
    } else {
        return Promise.resolve(file);
    }
};

const addStringPadding = (input: number) => {
    return input.toString().padStart(2, '0');
};

const generateBlobName = (fileName: string): string => {
    const currentDate = new Date();
    const date = `${currentDate.getUTCFullYear()}${addStringPadding(currentDate.getUTCMonth() + 1)}${addStringPadding(currentDate.getUTCDate())}`;
    const time = `${addStringPadding(currentDate.getUTCHours())}${addStringPadding(currentDate.getUTCMinutes())}${addStringPadding(
        currentDate.getUTCSeconds()
    )}${addStringPadding(currentDate.getUTCMilliseconds())}`;
    return `${date}${time}-${fileName}`;
};

const generateBlobClientURL = (sasLink: string): string => {
    const account = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME!;
    return `https://${account}.blob.core.windows.net?${sasLink}`;
};

export const uploadFileToCloud = async (sasLink: string, file: File, shouldResizeFile: boolean = true): Promise<FileUploadResponse> => {
    try {
        const blobClientURL = generateBlobClientURL(sasLink);
        const blobServiceClient = new AzureStorageBlob.BlobServiceClient(blobClientURL);
        const containerName = process.env.REACT_APP_BLOB_CONTAINER_NAME!;
        const blobContainerClient = blobServiceClient.getContainerClient(containerName);

        const blobName = generateBlobName(file.name);
        const blockBlobClient = blobContainerClient.getBlockBlobClient(blobName);
        if (shouldResizeFile) {
            file = (await resizeFile(file)) as File;
        }
        await blockBlobClient.upload(file, file.size);
        return {
            isSuccessful: true,
            fileName: blobName,
        };
    } catch (error) {
        console.error(error);
        return {
            isSuccessful: false,
        };
    }
};
