import { AddCircle, Delete, Edit, Folder, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthenticatedComponent from '../auth';
import { ApiError } from '../Components/core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../Components/core/DataTable';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import { NavBreadcrumbs } from '../Components/core/NavBreadcrumbs';
import { DocumentFolderDto } from '../models';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../util/customHooks';
import { useArchiveDocumentFolderMutation, useGetDocumentFoldersQuery, useUnarchiveDocumentFolderMutation } from '../store/apis/document-folder-api';
import { AddDocumentFolderDialog } from '../Components/Forms/DocumentFolderForm';
import { useGetWorkOrderQuery } from '../store/apis/work-order-api';

export const DocumentFoldersListView: FunctionComponent = () => {
    const { workOrderId } = useParams();
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [selectedDefaultFolder, setSelectedDefaultFolder] = useState<DocumentFolderDto | undefined | null>();
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data: capitalProject, isLoading: isLoadingCapitalProject, error: errorCapitalProject, refetch: refetchCapitalProject } = useGetWorkOrderQuery(workOrderId!);
    const { data, isLoading, error, refetch } = useGetDocumentFoldersQuery({
        workOrderId: workOrderId ?? '',
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
    });
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<DocumentFolderDto>[] = [
        { key: 'name', label: 'Name', sortKey: 'NAME', fieldMapper: (row) => (row.isRequired ? `${row.name} (Required)` : row.name) },
    ];
    const [archiveDocumentFolder, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveDocumentFolderMutation();
    const [unarchiveDocumentFolder, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveDocumentFolderMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

    const getEntityName = useCallback(() => {
        return selected.length === 1 ? 'Document Folder' : 'Document Folders';
    }, [selected.length]);

    const handleAddClose = useCallback(() => {
        setAddDialogOpen(false);
        setSelectedDefaultFolder(null);
    }, []);

    const handleActionRequestCompletion = useCallback((resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    }, []);

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => { handleActionRequestCompletion(resetArchive); });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => { handleActionRequestCompletion(resetUnarchive); });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback((afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    }, []);

    const handleCreateNew = useCallback(() => {
        setAddDialogOpen(true);
    }, []);

    const handleMenuEdit = useCallback(() => {
        const selectedDocumentFolderId = data?.pageResults[selected[0]].id;
        if (selectedDocumentFolderId) {
            setSelectedDefaultFolder(data?.pageResults.find((x) => x.id === selectedDocumentFolderId) ?? null);
            setAddDialogOpen(true);
        }
    }, [data?.pageResults, selected]);

    const handleTableEdit = useCallback((id: string) => {
        setSelectedDefaultFolder(data?.pageResults.find((x) => x.id === id) ?? null);
        setAddDialogOpen(true);
    }, [data?.pageResults]);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveDocumentFolder(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [data?.pageResults, selected, unarchiveDocumentFolder]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveDocumentFolder(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [archiveDocumentFolder, data?.pageResults, selected]);

    const canSetActive = useMemo(() => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useMemo(() => {
        return selected.some((index) => data?.pageResults[index].isActive && data?.pageResults[index].isRequired === false && data?.pageResults[index].canArchive === true);
    }, [data?.pageResults, selected]);

    if (isLoading || isLoadingCapitalProject) {
        return <LoadingIndicator />;
    }

    if (error || errorCapitalProject) {
        return <ApiError onReloadClick={
            () => {
                refetch();
                refetchCapitalProject();
            }} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' xs={12}>
                <Grid item container direction='column' justifyContent='start' xs={12}>
                    <Grid item container spacing={1}>
                        <Grid item>

                            <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                                <Folder /> Capital Project: #{capitalProject?.woNumber}
                            </Typography>
                        </Grid>
                        <AuthenticatedComponent
                            requiredPermissions={['edit:workordersFull', 'edit:workordersLimited']}
                            logic='or'
                            component={
                                <Grid item>
                                    <IconButton onClick={() => {
                                        navigate(`/capitalProjects/edit/${capitalProject?.id}`);
                                    }}>
                                        <Edit />
                                    </IconButton>
                                </Grid>}
                        />
                    </Grid>
                    <NavBreadcrumbs links={[
                        { label: 'Home', navLink: '/' },
                        { label: capitalProject?.facilityClientName ?? '', navLink: `/clients/${capitalProject?.facilityClientId}` },
                        { label: capitalProject?.facilityName ?? '', navLink: `/clients/${capitalProject?.facilityClientId}/facility/${capitalProject?.facilityId}` }
                    ]} currentPageLabel={`Capital Project # ${capitalProject?.woNumber}`} />
                </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='start'>
                <Grid item container direction='row' alignItems='center' xs={12} justifyContent='end'>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:documentFolders']}
                        component={
                            <>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                        label='Show Inactive'
                                        labelPlacement='start'
                                    />
                                </Grid>
                                <Grid item>
                                    <Tooltip title='Make Inactive'>
                                        <span>
                                            <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive}>
                                                <Delete fontSize='inherit' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:documentFolders']}
                        component={
                            <Grid item>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:documentFolders', 'edit:documentFolders', 'delete:documentFolders']}
                        logic='or'
                        component={
                            <Grid item>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:documentFolders']}
                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:documentFolders']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:documentFolders']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        } />
                    <Grid item>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='NAME'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:documentFolders'] }}
                    onEdit={handleTableEdit}
                    onRowClick={(id) => {
                        navigate(`/downloads/${workOrderId}/documentFolder/${id}`);
                    }}
                />
            </Grid>
            {workOrderId && <AddDocumentFolderDialog
                open={addDialogOpen}
                onClose={handleAddClose}
                initValues={selectedDefaultFolder}
                workOrderId={workOrderId}
            />}
        </Grid>
    );
};
