import { PlayArrow } from '@mui/icons-material';
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useLazyGetContractorMetricsQuery } from '../../../store/apis/work-order-api';
import { formatCurrency } from '../../../util';
import LoadingIndicator from '../../core/LoadingIndicator';
import { IContractorContactsProps } from './types';

export const ContractorMetrics: FunctionComponent<IContractorContactsProps> = (props) => {
    const { contractorId } = props;
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [getMetricData, { data: metrics, isLoading: metricsLoading }] = useLazyGetContractorMetricsQuery();

    const populateMetricData = useCallback(() => {
        getMetricData({
            contractorId: contractorId,
            year: selectedYear.toFixed(),
        });
    }, [contractorId, getMetricData, selectedYear]);

    useEffect(() => {
        populateMetricData();
    }, [selectedYear, populateMetricData]);

    const handleYearChange = (increment: boolean) => {
        var year = selectedYear;
        if (increment) {
            year += 1;
        } else {
            year -= 1;
        }
        setSelectedYear(year);
    };

    if (metricsLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={3} sx={{ padding: '24px' }}>
            <Grid item container direction='row' alignItems={'center'}>
                <Grid item sx={{ marginLeft: '-5px' }}>
                    <IconButton
                        size='large'
                        onClick={() => {
                            handleYearChange(false);
                        }}
                        sx={{ paddingLeft: 0, paddingRight: '16px' }}
                        disabled={!metrics?.hasPreviousYearRecords}>
                        <PlayArrow sx={{ transform: 'rotate(180deg)' }} fontSize='inherit' />
                    </IconButton>
                </Grid>
                <Grid item justifyContent={'center'}>
                    <Typography>{selectedYear}</Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        size='large'
                        onClick={() => {
                            handleYearChange(true);
                        }}
                        disabled={!metrics?.hasFollowingYearRecords || selectedYear === new Date().getFullYear()}
                        sx={{ paddingLeft: '16px' }}>
                        <PlayArrow fontSize='inherit' />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer component={'div'}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ border: '1px solid grey' }}></TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Jan
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Feb
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Mar
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Apr
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    May
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Jun
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Jul
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Aug
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Sep
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Oct
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Nov
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    Dec
                                </TableCell>
                                <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white' }}>
                                    TOTAL
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row' align='left' sx={{ border: '1px solid grey' }}>
                                    Work Orders
                                </TableCell>
                                {metrics &&
                                    metrics.contractorMetrics.map((metric) => (
                                        <TableCell align='right' sx={{ border: '1px solid grey' }}>
                                            {metric.workOrderCount === 0 ? '-' : metric.workOrderCount}
                                        </TableCell>
                                    ))}
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row' align='left' sx={{ border: '1px solid grey' }}>
                                    Repeat Leaks
                                </TableCell>
                                {metrics &&
                                    metrics.contractorMetrics.map((metric) => (
                                        <TableCell align='right' sx={{ border: '1px solid grey' }}>
                                            {metric.repeatLeakCount === 0 ? '-' : metric.repeatLeakCount}
                                        </TableCell>
                                    ))}
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row' align='left' sx={{ border: '1px solid grey' }}>
                                    Average Days to Report
                                </TableCell>
                                {metrics &&
                                    metrics.contractorMetrics.map((metric) => (
                                        <TableCell align='right' sx={{ border: '1px solid grey' }}>
                                            {metric.daysToReportAverage === 0 ? '-' : metric.daysToReportAverage}
                                        </TableCell>
                                    ))}
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row' align='left' sx={{ border: '1px solid grey' }}>
                                    Average Days to Service
                                </TableCell>
                                {metrics &&
                                    metrics.contractorMetrics.map((metric) => (
                                        <TableCell align='right' sx={{ border: '1px solid grey' }}>
                                            {metric.daysToServiceAverage === 0 ? '-' : metric.daysToServiceAverage}
                                        </TableCell>
                                    ))}
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row' align='left' sx={{ border: '1px solid grey' }}>
                                    Total Invoiced ($)
                                </TableCell>
                                {metrics &&
                                    metrics.contractorMetrics.map((metric) => (
                                        <TableCell align='right' sx={{ border: '1px solid grey' }}>
                                            {metric.invoiceTotal === 0 ? '-' : formatCurrency(metric.invoiceTotal)}
                                        </TableCell>
                                    ))}
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row' align='left' sx={{ border: '1px solid grey' }}>
                                    Work Order By Crew
                                </TableCell>
                                {metrics &&
                                    metrics.contractorMetrics.map((metric) => (
                                        <TableCell
                                            sx={{
                                                backgroundColor: '#CCCCCC',
                                                border: '1px solid grey',
                                            }}
                                            align='right'></TableCell>
                                    ))}
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row' align='left' sx={{ border: '1px solid grey' }}>
                                    Report Rejections
                                </TableCell>
                                {metrics &&
                                    metrics.contractorMetrics.map((metric) => (
                                        <TableCell align='right' sx={{ border: '1px solid grey' }}>
                                            {metric.reportRejections === 0 ? '-' : metric.reportRejections}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};
