import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { JsiReportForm } from '../../Components/Forms/JsiReportForm';
import { JsiReportDto } from '../../models';
import { useGetJsiReportQuery, useUpdateJsiReportMutation } from '../../store/apis/jsi-report-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditJsiReportView: FunctionComponent = (props) => {
    const { id, workOrderId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading: jsiReportLoading, error: jsiReportError, refetch: jsiReportRefetch } = useGetJsiReportQuery(id!);
    const [updateJsiReport, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateJsiReportMutation();

    useFailedUpdateSnackbar('JSI report', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('JSI report', isUpdateSuccess);

    const handleSave = (values: JsiReportDto) => {
        updateJsiReport(values);
    };

    const handleCancel = () => {
        if (workOrderId) {
            navigate(`/capitalprojects/edit/${workOrderId}`);
        } else {
            navigate(`/clientreports/`);
        }
    };

    if (jsiReportLoading) {
        return <LoadingIndicator />;
    }

    if (jsiReportError) {
        return <ApiError onReloadClick={jsiReportRefetch} />;
    }

    return <JsiReportForm save={handleSave} cancel={handleCancel} initValues={data} workOrderId={workOrderId} />;
};
