import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientForm } from '../../Components/Forms/ClientForm';
import { ClientDto } from '../../models';
import { useCreateClientMutation } from '../../store/apis/client-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddClientView: FunctionComponent = () => {
    const navigate = useNavigate();
    const [createClient, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateClientMutation();

    useFailedCreateSnackbar('client', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Client', isCreateSuccess, () => {
        navigate(`/clients/edit/${data?.id}`);
    });

    const handleSave = (values: ClientDto) => {
        createClient(values);
    };

    const handleCancel = () => {
        navigate(`/clients`);
    };

    return <ClientForm save={handleSave} cancel={handleCancel} />;
};
