import { AddCircle, Delete, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { ImmediateRepairsChecklistItemDto } from '../../../models/ImmediateRepairsChecklistItemDto';
import {
    useArchiveMGOrRSReportDispatchImmediateRepairMutation,
    useGetMGOrRSReportDispatchImmediateRepairsQuery,
    useUnarchiveMGOrRSReportDispatchImmediateRepairMutation,
} from '../../../store/apis/report-dispatch-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { ContractorAddEditImmediateRepairsChecklistItemDialog } from './ContractorAddEditImmediateRepairsChecklistItemDialog';

export interface IContractorImmediateRepairsChecklistItemListViewProps {
    disabled?: boolean;
    dispatchId: string;
    reportId: string;
    roofSectionReportId?: string;
    roofNumber?: string;
}

export const ContractorImmediateRepairsChecklistItemListView: FC<IContractorImmediateRepairsChecklistItemListViewProps> = ({
    disabled,
    dispatchId,
    reportId,
    roofSectionReportId,
    roofNumber,
}) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [dialogInstanceId, setDialogInstanceId] = useState(1);
    const [isAddNewChecklistItemOpen, setIsAddNewChecklistItemOpen] = useState(false);
    const [selectedChecklistItem, setSelectedChecklistItem] = useState<ImmediateRepairsChecklistItemDto | undefined>(undefined);

    const { data, error, isLoading, refetch } = useGetMGOrRSReportDispatchImmediateRepairsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        dispatchId: dispatchId,
        roofSectionReportId: roofSectionReportId,
    });
    const [archiveSection, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] =
        useArchiveMGOrRSReportDispatchImmediateRepairMutation();
    const [unarchiveSection, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] =
        useUnarchiveMGOrRSReportDispatchImmediateRepairMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<ImmediateRepairsChecklistItemDto>[] = useMemo(
        () => [
            { key: 'roofSection', label: 'Roof Section', sortKey: 'ROOF_NUMBER', fieldMapper: (row) => (row.roofSection ? row.roofSection.roofNumber : '') },
            {
                key: 'checklistItem',
                label: 'Checklist Item',
                sortKey: 'CHECKLIST_ITEM',
                fieldMapper: (row) => row.checklistItem,
            },
        ],
        []
    );

    const entityName = useMemo(() => {
        return selected.length === 1 ? 'Checklist Item' : 'Checklist Items';
    }, [selected.length]);

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', entityName, isArchiveError, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useFailedActionSnackbar('activate', entityName, isUnarchiveError, () => {
        handleActionRequestCompletion(resetUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', entityName, isArchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useSuccessfulActionSnackbar('Activated', entityName, isUnarchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetUnarchive);
    });

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) afterClose();
        },
        []
    );

    const handleCreateNew = useCallback(() => {
        setDialogInstanceId(dialogInstanceId + 1);
        setIsAddNewChecklistItemOpen(true);
    }, [dialogInstanceId]);

    const handleMenuEdit = useCallback(() => {
        const roofSectionReport = data?.pageResults[selected[0]];
        if (roofSectionReport) {
            setDialogInstanceId(dialogInstanceId + 1);
            setSelectedChecklistItem(roofSectionReport);
            setIsAddNewChecklistItemOpen(true);
        }
    }, [data?.pageResults, dialogInstanceId, selected]);

    const handleRowEdit = useCallback(
        (id: string) => {
            const roofSectionReport = data?.pageResults.find((x) => x.id === id);
            if (roofSectionReport) {
                setDialogInstanceId(dialogInstanceId + 1);
                setSelectedChecklistItem(roofSectionReport);
                setIsAddNewChecklistItemOpen(true);
            }
        },
        [data?.pageResults, dialogInstanceId]
    );

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveSection({ dispatchId: dispatchId, immediateRepairId: data?.pageResults[index].id! });
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [data?.pageResults, selected, unarchiveSection, dispatchId]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveSection({ dispatchId: dispatchId, immediateRepairId: data?.pageResults[index].id! });
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [archiveSection, data?.pageResults, selected, dispatchId]);

    const handleAddChecklistItemClose = useCallback((keepOpen: boolean) => {
        setIsAddNewChecklistItemOpen(keepOpen);
        setSelectedChecklistItem(undefined);
    }, []);

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index]?.isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index]?.isActive);
    };

    const disableEdit = useCallback(
        (__: string) => {
            if (disabled) {
                return disabled;
            } else {
                return false;
            }
        },
        [disabled]
    );

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                <Grid item container direction='row' alignItems='center' xs={6}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title='Make Inactive'>
                            <span>
                                <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive() || disabled}>
                                    <Delete fontSize='inherit' />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title='Add New'>
                            <IconButton color='primary' size='large' onClick={handleCreateNew} disabled={disabled}>
                                <AddCircle fontSize='inherit' />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton size='large' onClick={handleMenuOpen}>
                            <MoreVert fontSize='inherit' />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <MenuItem onClick={handleMenuClose(handleCreateNew)} disabled={disabled}>
                                Add New
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1 || disabled}>
                                Edit
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive() || disabled}>
                                Make Active
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive() || disabled}>
                                Make Inactive
                            </MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='ROOF_NUMBER'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:reports'] }}
                    onEdit={handleRowEdit}
                    disableEdit={disableEdit}
                />
            </Grid>
            <ContractorAddEditImmediateRepairsChecklistItemDialog
                key={dialogInstanceId}
                open={isAddNewChecklistItemOpen}
                onClose={handleAddChecklistItemClose}
                initValues={selectedChecklistItem}
                reportId={reportId}
                disabled={disabled}
                defaultRoofNumber={roofNumber}
                dispatchId={dispatchId}
            />
        </Grid>
    );
};
