import { ArrowDownward, ArrowUpward, CloudUpload, Sort } from '@mui/icons-material';
import { Button, CardHeader, Dialog, DialogActions, DialogContent, Grid, LinearProgress, Typography } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { emptyGuid } from '../../../models';
import { useGetSasLinkQuery } from '../../../store/apis/cloud-storage-api';
import { uploadFileToCloud } from '../../../util';
import { IUploadPhotoDialogProps } from './types';
import { Attachment } from '../../Forms/types';
import { v4 as uuidv4 } from 'uuid';
import { PhotosDisplay } from './PhotosDisplay';
import Compressor from 'compressorjs';
import _ from 'lodash';

export const MultiPhotoUpload: FC<IUploadPhotoDialogProps> = memo(props => {
    const { open, onClose, afterPhotoUpload } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [attachmentHoverIndex, setAttachmentHoverIndex] = useState<number | undefined>();
    const { data: fileUploadLink } = useGetSasLinkQuery();
    const [selectedFileList, setSelectedFileList] = useState<File[]>([]);
    const [previewImages, setPreviewImages] = useState<Attachment[]>([]);
    const [sortAsc, setSortAsc] = useState<boolean>();

    const changeSort = useCallback(() => {
        switch (sortAsc) {
            case undefined:
                setSortAsc(false);
                setPreviewImages(previewImages.sort((a, b) => b.photo.createdOn!.valueOf() - a.photo.createdOn!.valueOf()));
                break;
            case true:
                setSortAsc(undefined);
                setPreviewImages(previewImages.sort((a, b) => a.photo.order - b.photo.order));
                break;
            case false:
                setSortAsc(true);
                setPreviewImages(previewImages.sort((a, b) => a.photo.createdOn!.valueOf() - b.photo.createdOn!.valueOf()));
                break;
        }
    }, [previewImages, sortAsc]);

    const onSave = useCallback(async () => {
        setIsUploading(true);
        for (let index = 0; index < previewImages.length; index++) {
            const file = previewImages[index].photo.file;
            const response = await uploadFileToCloud(fileUploadLink!.link, file!);
            if (response.isSuccessful) {
                previewImages[index].photo.displayName = file!.name;
                previewImages[index].photo.sourceName = response.fileName!;
                previewImages[index].photo.order = index;
                afterPhotoUpload(previewImages[index].id, previewImages[index].photo, previewImages[index].previewLink!);
            }
        }
        setIsUploading(false);
        onClose();
    }, [afterPhotoUpload, fileUploadLink, onClose, previewImages]);

    useEffect(() => {
        setSelectedFileList([]);
        setPreviewImages([]);
    }, [setSelectedFileList, setPreviewImages, open]);

    const beforeClose = useCallback(() => {
        onClose();
        setSortAsc(undefined);
        setSelectedFileList([]);
        setPreviewImages([]);
    }, [onClose]);

    const handleAdd = useCallback((files: File[]) => {
        setSortAsc(undefined);
        files.forEach((file, index) => {
            new Compressor(file, {
                quality: 0.6,
                mimeType: 'image/jpeg',
                success(result) {
                    setSelectedFileList((prevState) => {
                        var updatedState = _.cloneDeep(prevState);
                        updatedState.push(result as File);
                        return updatedState;
                    });
                    const attachment = {
                        id: uuidv4(),
                        photo: {
                            id: emptyGuid,
                            sourceName: file.name,
                            displayName: '',
                            isActive: true,
                            order: index,
                            createdOn: new Date(file.lastModified),
                            file: (result as File)
                        },
                        previewLink: URL.createObjectURL(result),
                    };
                    setPreviewImages((prevState) => {
                        var updatedState = _.cloneDeep(prevState);
                        updatedState.push(attachment);
                        return updatedState;
                    });
                },
                error(err) {
                    console.error(err.message);
                },
            });
        });
    }, []);

    const deleteSelectedPhoto = useCallback((index: number) => {
        setSortAsc(undefined);
        if (previewImages.length === 0) {
            setPreviewImages([]);
            setSelectedFileList([]);
        } else {
            setPreviewImages(
                previewImages.filter((preview) => {
                    return preview !== previewImages[index];
                })
            );
            setSelectedFileList(
                selectedFileList.filter((file) => {
                    return file !== selectedFileList[index];
                })
            );
        }
    }, [previewImages, selectedFileList]);

    return (
        <Dialog open={open} maxWidth='lg' fullWidth>
            <CardHeader
                title={
                    <Typography sx={{ color: '#ffffff' }} variant='h3'>
                        {<CloudUpload />} Photo Upload
                    </Typography>
                }
                sx={{ backgroundColor: '#266490' }}
            />
            <DialogContent>
                <Grid container direction='column' spacing={2} pb={2}>
                    <Grid item xs={6} alignItems='center'>
                        <DropzoneArea
                            fileObjects={selectedFileList}
                            filesLimit={50}
                            acceptedFiles={['image/*', '.jpeg', '.jpg', '.png']}
                            showPreviewsInDropzone={false}
                            showPreviews={false}
                            dropzoneText='Drag and drop photos here or click the icon below'
                            onDrop={(droppedFiles: File[], event) => {
                                handleAdd(droppedFiles);
                            }}
                            showAlerts={false}
                            maxFileSize={100000000}
                        />
                    </Grid>
                    {isUploading && (
                        <Grid item>
                            <LinearProgress />
                        </Grid>
                    )}
                    <Grid item container direction='row' spacing={2}>
                        <Grid item>
                            <Typography variant='subtitle1'>Preview</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                startIcon={sortAsc === undefined ? <Sort /> : (sortAsc === true ? <ArrowUpward /> : <ArrowDownward />)}
                                onClick={() => changeSort()}
                            >
                                Order By Date
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <PhotosDisplay
                            attachments={previewImages}
                            setAttachments={(value) => {
                                setPreviewImages(value);
                                setSortAsc(undefined);
                            }}
                            setAttachmentHoverIndex={setAttachmentHoverIndex}
                            attachmentHoverIndex={attachmentHoverIndex}
                            attachmentCaptions={[]}
                            setAttachmentCaptions={() => { }}
                            deleteSelectedPhoto={deleteSelectedPhoto}
                            hideNoPhotosMessage={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose} disabled={isUploading}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onSave} disabled={isUploading}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
});
