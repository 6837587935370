import { Button, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import { Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Menu, Settings } from '@mui/icons-material';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { INavigationDrawerProps, NavigationDrawerRouteDTO } from './types';
import AuthenticatedComponent from '../../../auth';
import _ from 'lodash';

const openedMixin = (theme: Theme): CSSObject => ({
    width: '256px',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '72px',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: '256px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const NavigationDrawer: React.FunctionComponent<INavigationDrawerProps> = (props) => {
    const { routes, adminRoutes } = props;
    const [isOpen, setIsOpen] = useState(true);
    const [isHoverOpen, setIsHoverOpen] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
        setIsHoverOpen(!isOpen);
    };

    const renderNavigationButtons = (routesToRender: NavigationDrawerRouteDTO[]) => {
        const visibleRoutes = routesToRender.filter((r) => !r.isHidden);
        return visibleRoutes.map((routeToRender) => {
            return createNavButton(routeToRender);
        });
    };

    const createNavButton = (route: NavigationDrawerRouteDTO) => {
        const NavButton =
            isOpen || isHoverOpen ? (
                <Button
                    key={route.route}
                    className='nav-button full-width'
                    onClick={() => {
                        navigate(route.route!);
                    }}
                    startIcon={route.icon}
                    sx={pathname === route.route ? { fontWeight: 'bold', backgroundColor: '#6596B9' } : { fontWeight: 'normal' }}>
                    {route.name}
                </Button>
            ) : (
                <IconButton
                    key={route.route}
                    className='nav-button'
                    onClick={() => {
                        navigate(route.route!);
                    }}
                    sx={pathname === route.route ? { fontWeight: 'bold', backgroundColor: '#6596B9' } : { fontWeight: 'normal' }}>
                    {route.icon}
                </IconButton>
            );

        return <AuthenticatedComponent key={route.route} component={NavButton} requiredPermissions={route.requiredPermissions ?? []} />;
    };

    return (
        <>
            <Drawer
                variant='permanent'
                anchor='left'
                open={isOpen || isHoverOpen}
            >
                <Grid container alignItems='start' sx={{ marginBottom: '20px' }}>
                    <IconButton edge='start' onClick={toggleDrawer}>
                        <Menu />
                    </IconButton>
                </Grid>
                <Grid
                    container
                    direction='column'
                    alignItems='start'
                    gap='8px'
                >
                    {renderNavigationButtons(routes)}
                    {!(isOpen || isHoverOpen) && adminRoutes && adminRoutes.length > 0 && (
                        <AuthenticatedComponent
                            logic={'or'}
                            requiredPermissions={_.flatMap(adminRoutes, (x) => _.flatMap(x.requiredPermissions, (y) => y))}
                            component={
                                <IconButton className='nav-button' sx={{ fontWeight: 'bold' }}>
                                    <Settings />
                                </IconButton>
                            }
                        />
                    )}
                </Grid>
                {(isOpen || isHoverOpen) && adminRoutes && adminRoutes.length > 0 && (
                    <AuthenticatedComponent
                        logic={'or'}
                        requiredPermissions={_.flatMap(adminRoutes, (x) => _.flatMap(x.requiredPermissions, (y) => y))}
                        component={
                            <>
                                <Divider sx={{ background: 'white', margin: '20px -20px 20px' }} />
                                <Grid container>
                                    <Typography sx={{ marginBottom: '10px' }}>Admin</Typography>
                                    {renderNavigationButtons(adminRoutes!)}
                                </Grid>
                            </>
                        }
                    />
                )}
            </Drawer>
            <Grid
                sx={
                    isOpen || isHoverOpen
                        ? { marginLeft: '256px', transition: 'margin .2s', transitionTimingFunction: 'ease-out' }
                        : { marginLeft: '72px', transition: 'margin .2s', transitionTimingFunction: 'ease-in', width: 'calc(100vw - 72px)' }
                }>
                {props.children}
            </Grid>
        </>
    );
};

export default NavigationDrawer;
