import { useCallback, useMemo } from 'react';
import { WorkOrderDto, WorkOrderListViewDto } from '../models';
import { format } from 'date-fns';
import { usePermissionChecker } from './usePermissionChecker';

export interface WorkOrderExportData {
    woNumber: string;
    owner: string;
    facility: string;
    roofProfile: string;
    calledInBy: string;
    division: string;
    poNumber: string;
    type: string;
    status: string;
    dispatchId: string;
    dispatchDate: string;
    scheduledDate: string;
    cost?: string;
    billedTo?: string;
    problemDescription?: string;
    summaryWorkPreformed?: string;
}

const CAPITAL_PROJECT_EXPORT_HEADERS = ['Project #', 'Facility', 'Roof Plan', 'Contact', 'Division', 'PO #', 'Type', 'Status', 'Dispatch ID'];

const WORK_ORDER_EXPORT_HEADERS = [
    'Work Order #',
    'Owner',
    'Facility',
    'Roof Plan',
    'Reported By',
    'Division',
    'PO #',
    'Type',
    'Status',
    'Dispatch ID',
    'Dispatch Date',
    'Scheduled Date',
    'Cost', // Cost and everything below it only applies to work orders not capital projects
    'Billed To',
    'Description of Problem',
    'Summary of Work Performed',
];

export type ExportDataResponse = { headers: string[]; data: WorkOrderExportData[] };

export function useWorkOrderExporter() {
    const { userHasPermission } = usePermissionChecker();
    const userCanViewWorkOrderCosts = useMemo(() => userHasPermission('read:workOrderCosts'), [userHasPermission]);

    const getBilledTo = useCallback((workOrder: WorkOrderListViewDto) => {
        if (workOrder.billedToClientId) {
            return workOrder.billedToClient?.name ?? '';
        } else if (workOrder.billedToContractorId) {
            return workOrder.billedToContractor?.name ?? '';
        } else {
            return workOrder.facilityClientName ?? ';';
        }
    }, []);

    const mapWorkOrderToExportData = useCallback(
        (workOrder: WorkOrderDto | WorkOrderListViewDto, includeWorkOrderFields: boolean): WorkOrderExportData => {
            var exportData: WorkOrderExportData = {
                woNumber: workOrder.woNumber,
                owner: workOrder.facilityClientName ?? '',
                facility: workOrder.facilityName ?? '',
                roofProfile: workOrder.roofProfileName ?? '',
                calledInBy: workOrder.calledInBy,
                division: workOrder.division?.name ?? '',
                poNumber: workOrder.poNumber,
                type: workOrder.type?.name ?? '',
                status: workOrder.status?.name ?? '',
                dispatchId: workOrder.contractor?.dispatchId ?? '',
                dispatchDate: workOrder.dispatchDate ? format(new Date(workOrder.dispatchDate.toString()), 'M/d/yyyy') : '',
                scheduledDate: workOrder.scheduledDate ? format(new Date(workOrder.scheduledDate.toString()), 'M/d/yyyy') : '',
            };

            if (includeWorkOrderFields && userCanViewWorkOrderCosts) {
                exportData.cost = workOrder.cost.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                });
            }

            if (includeWorkOrderFields) {
                exportData.billedTo = getBilledTo(workOrder);
                exportData.problemDescription = workOrder.problemDescription;
                exportData.summaryWorkPreformed = workOrder.summaryWorkPerformed;
            }

            return exportData;
        },
        [userCanViewWorkOrderCosts, getBilledTo]
    );

    const getExportData = (WorkOrders: WorkOrderListViewDto[], isCapitalProject: boolean): ExportDataResponse => {
        var headers = isCapitalProject ? CAPITAL_PROJECT_EXPORT_HEADERS : WORK_ORDER_EXPORT_HEADERS;
        // If the user does not permission to see work order costs then we are removing that header
        if (!isCapitalProject && !userCanViewWorkOrderCosts) {
            headers = headers.filter((h) => h !== 'Cost');
        }

        var configuredMap = (item: WorkOrderDto | WorkOrderListViewDto) => mapWorkOrderToExportData(item, !isCapitalProject);
        var data = WorkOrders.map(configuredMap);

        return { headers, data };
    };

    return { getExportData };
}
