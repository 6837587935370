import { serverApi } from '..';
import { RoofConditionDto, RoofSystemDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const roofApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoofSystems: builder.query<RoofSystemDto[], RoofQueryParams>({
            query: (params) => buildSearchQueryRoute(`roof/systems`, params),
            providesTags: [{ type: 'Roof System' }],
        }),
        getRoofConditions: builder.query<RoofConditionDto[], RoofQueryParams>({
            query: (params) => buildSearchQueryRoute(`roof/conditions`, params),
            providesTags: [{ type: 'Roof Condition' }],
        }),
        createRoofSystem: builder.mutation<RoofSystemDto, RoofSystemDto>({
            query: (dto) => ({
                url: `roof/systems`,
                method: 'POST',
                body: dto,
            }),
            invalidatesTags: [{ type: 'Roof System' }],
        }),
    }),
});

export const { useGetRoofSystemsQuery, useGetRoofConditionsQuery, useCreateRoofSystemMutation } = roofApi;

export interface RoofQueryParams {
    includeInactive: boolean;
}
