import { Map } from '@mui/icons-material';
import { Button, Card, CardHeader, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { ICreateRoofSectionDialog } from '.';

export const CreateRoofSectionDialog: FunctionComponent<ICreateRoofSectionDialog> = (props) => {
    const { open, onClose, roofNumber, setRoofNumber } = props;
    const [number, setNumber] = useState<string>('');

    useEffect(() => {
        setNumber(roofNumber);
    }, [roofNumber, open]);

    const onYes = () => {
        onClose(true);
    };

    const onNo = () => {
        setRoofNumber('');
        onClose(false);
    };

    return (
        <Dialog open={open} maxWidth='sm' fullWidth>
            <Card variant='outlined'>
                <CardHeader
                    title={
                        <Typography sx={{ color: '#ffffff' }} variant='h3'>
                            {<Map />} {'Create Roof Section?'}
                        </Typography>
                    }
                    sx={{ backgroundColor: '#266490' }}
                />
                <DialogContent>
                    <Grid container direction='column'>
                        <Grid item>
                            <Typography>Roof Section #{number} does not exist. Would you like to create it?</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='info' onClick={onNo}>
                        No
                    </Button>
                    <Button variant='contained' color='primary' onClick={onYes}>
                        Yes
                    </Button>
                </DialogActions>
            </Card>
        </Dialog>
    );
};
