import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import {FC, useCallback, useMemo} from 'react';
import { IEntityAutocomplete } from '../core/IEntityAutocomplete';
import { ContactDto } from '../../models';
import { useGetContactsQuery } from '../../store/apis/contact-api';

export interface IContactSelectProps {
    contractorId: string;
    value: string;
    onChange: (value: ContactDto | null) => void;
    disabled?: boolean;
    required?: boolean;
    label?: string;
    errorMessage?: string;
};

export const ContactSelect: FC<IContactSelectProps> = ({ contractorId, value, onChange, disabled, required, label = 'Contact', errorMessage }) => {
    const { data: contacts, isLoading: isLoadingContacts } = useGetContactsQuery({
        parentId: contractorId,
        params: {
            searchText: '',
            includeInactive: true,
            includeOther: false,
        },
    });

    const selectedValue = useMemo(() => contacts?.pageResults.find((contact) => contact.id === value) ?? null, [contacts, value]);

    const handleChange = useCallback((_: React.SyntheticEvent<Element, Event>, value: ContactDto) => {
        onChange(value);
    }, [onChange]);

    return (
        <FormControl fullWidth error={!!errorMessage} disabled={disabled} required={required}>
            <FormLabel>{label}</FormLabel>
            <IEntityAutocomplete
                options={contacts?.pageResults}
                onChange={handleChange}
                value={selectedValue}
                getOptionLabel={(option: ContactDto) => `${option.firstName} ${option.lastName}`}
                isLoading={isLoadingContacts}
                error={!!errorMessage}
                disabled={disabled}
            />
            <FormHelperText sx={{ whiteSpace: 'nowrap' }}>{errorMessage}</FormHelperText>
        </FormControl>
    );
};