import { useAuth0 } from '@auth0/auth0-react';
import { Comment, Description, Download, FormatAlignJustify, Map, PhotoLibrary, ReportProblem } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import { AuthRoles } from '../../Components/core/AuthRoles';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { NavBreadcrumbs } from '../../Components/core/NavBreadcrumbs';
import { DashboardCard } from '../../Components/Dashboard';
import { CoreInfoDashboardCard } from '../../Components/Dashboard/RoofSectionDashboard/CoreInfoDashboardCard';
import { RoofSectionDocuments } from '../../Components/Dashboard/RoofSectionDashboard/RoofSectionDocuments';
import { RoofSectionInfoCard } from '../../Components/Dashboard/RoofSectionDashboard/RoofSectionInfoCard';
import { ReportLeakDialog } from '../../Components/Forms/ReportLeakDialog';
import { PhotoDto, FacilityDto } from '../../models';
import { useGetFacilityQuery } from '../../store/apis/facility-api';
import { useGetRoofSectionQuery } from '../../store/apis/roof-section-api';
import AuthenticatedComponent from '../../auth';
import { Attachment } from '../../Components/Forms/types';
import _ from 'lodash';
import { PhotosDisplay } from '../../Components/core/Photos';
import { useLazyGetPhotoFileLinkQuery } from '../../store/apis/photo-api';
import { RoofSectionDashboardRoofSectionReportsCard } from '../../Components/Dashboard/RoofSectionDashboard/RoofSectionDashboardRoofSectionReportsCard';
import { ViewPhotoDialog } from '../../Components/Forms';

export const RoofSectionDashboard: FunctionComponent = () => {
    const { id, facilityId } = useParams();
    const { user } = useAuth0();
    const roofMapRef = useRef<null | HTMLDivElement>(null);
    const roofSectionsRef = useRef<null | HTMLDivElement>(null);
    const coreInfoRef = useRef<null | HTMLDivElement>(null);
    const inspectorCommentsRef = useRef<null | HTMLDivElement>(null);
    const photosRef = useRef<null | HTMLDivElement>(null);
    const documentsRef = useRef<null | HTMLDivElement>(null);
    const reportsRef = useRef<null | HTMLDivElement>(null);
    const { data: roofSection, isLoading: roofSectionLoading, error: roofSectionError, refetch: refetchRoofSection } = useGetRoofSectionQuery(id!);
    const { data: facilityData, isLoading: facilityLoading, error: facilityError, refetch: refetchFacility } = useGetFacilityQuery(facilityId!);
    const [facility, setFacility] = useState<FacilityDto | undefined>(facilityData);
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [attachmentCaptions, setAttachmentCaptions] = useState<string[]>([]);
    const [attachmentHoverIndex, setAttachmentHoverIndex] = useState<number | undefined>();
    const [userRole, setUserRole] = useState('');
    const [getFileLink] = useLazyGetPhotoFileLinkQuery();
    const [viewPhotoIsOpen, setViewPhotoIsOpen] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState<Attachment | undefined>();
    const [selectedCaption, setSelectedCaption] = useState<string | undefined>();

    const photoPreviewOnClick = useCallback(
        (attachment: Attachment, index: number) => {
            setSelectedAttachment(attachment);
            setSelectedCaption(attachmentCaptions[index]);
            setViewPhotoIsOpen(true);
        },
        [attachmentCaptions]
    );

    useEffect(() => {
        const rolesFieldName = process.env.REACT_APP_AUTH0_Roles!;
        if (user && user[rolesFieldName].length > 0) {
            setUserRole(user[rolesFieldName][0]);
        }
    }, [user]);

    const [reportLeakOpen, setReportLeakOpen] = useState(false);

    useEffect(() => {
        if (facilityData !== facility) {
            setFacility(facilityData);
        }
        if (facilityData && !facility) {
            setFacility(facilityData);
        }
        if (facilityError) {
            setFacility(undefined);
        }
    }, [facilityData, facilityError, facility, setFacility]);

    const createPreviewLink = useCallback(
        async (attachment: PhotoDto): Promise<Attachment> => {
            let response = await getFileLink(attachment.id, false);
            return { id: attachment.id, photo: attachment, previewLink: response.data?.link };
        },
        [getFileLink]
    );

    const initializeRoofPictures = useCallback(() => {
        if (roofSection?.attachments) {
            Promise.all(
                roofSection.attachments.map(async (attachment) => {
                    return await createPreviewLink(attachment);
                })
            ).then((attachmentsList) => {
                const list = _.orderBy(attachmentsList, (x) => x.photo.order);
                setAttachments(list);
                setAttachmentCaptions(list.map((attachment) => attachment.photo.caption ?? ''));
            });
        }
    }, [createPreviewLink, roofSection]);

    useEffect(() => {
        initializeRoofPictures();
    }, [initializeRoofPictures]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const scrollToCoreInfo = () => {
        coreInfoRef?.current!.scrollIntoView();
    };
    const scrollToInspectorComments = () => {
        inspectorCommentsRef?.current!.scrollIntoView();
    };

    const scrollToPhotos = () => {
        photosRef?.current!.scrollIntoView();
    };

    const scrollToDocuments = () => {
        documentsRef?.current!.scrollIntoView();
    };

    const scrollToReports = () => {
        reportsRef?.current!.scrollIntoView();
    };

    const handleCloseReportLeak = () => {
        setReportLeakOpen(false);
    };

    const handleOpenReportLeak = () => {
        setReportLeakOpen(true);
    };

    if (roofSectionLoading || facilityLoading) {
        return <LoadingIndicator />;
    }

    const refetchPage = () => {
        refetchRoofSection();
        refetchFacility();
    };

    if (roofSectionError) {
        return <ApiError onReloadClick={refetchPage} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start' justifyContent='space-between'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Map />
                        Roof Section #{roofSection?.roofNumber}
                    </Typography>
                    {userRole === AuthRoles.ClientManager ? (
                        <NavBreadcrumbs
                            links={[
                                { label: 'Home', navLink: `/clients/${facility?.client.id}` },
                                { label: facility?.name!, navLink: `/clients/${facility?.client.id}/facility/${facility?.id!}` },
                            ]}
                            currentPageLabel={`Roof Section #${roofSection?.roofNumber}`}
                        />
                    ) : (
                        <NavBreadcrumbs
                            links={[
                                { label: 'Home', navLink: '/' },
                                { label: 'Clients', navLink: '/clients' },
                                { label: facility?.client?.name!, navLink: `/clients/${facility?.client.id}` },
                                { label: facility?.name!, navLink: `/clients/${facility?.client.id}/facility/${facility?.id!}` },
                            ]}
                            currentPageLabel={`Roof Section #${roofSection?.roofNumber}`}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='space-between'>
                <Grid item container direction='row' xs={8} spacing={2}>
                    <Grid item>
                        <Button variant='contained' color='secondary' startIcon={<FormatAlignJustify />} onClick={scrollToCoreInfo}>
                            CORE INFO
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='secondary' startIcon={<Comment />} onClick={scrollToInspectorComments}>
                            INSPECTOR COMMENTS
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='secondary' startIcon={<PhotoLibrary />} onClick={scrollToPhotos}>
                            PHOTOS
                        </Button>
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['read:documentsEmployee', 'read:documentsClient', 'read:documentsAll']}
                        logic='or'
                        component={
                            <Grid item>
                                <Button variant='contained' color='secondary' startIcon={<Description />} onClick={scrollToDocuments}>
                                    DOCUMENTS
                                </Button>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:roofSectionReports']}
                        logic='or'
                        component={
                            <Grid item>
                                <Button variant='contained' color='secondary' startIcon={<Download />} onClick={scrollToReports}>
                                    REPORTS
                                </Button>
                            </Grid>
                        }
                    />
                </Grid>
                <AuthenticatedComponent
                    requiredPermissions={['create:roofLeaks']}
                    component={
                        <Grid item container direction='row' xs='auto'>
                            <Grid item>
                                <Button variant='contained' color='primary' startIcon={<ReportProblem />} onClick={handleOpenReportLeak}>
                                    Report Leak
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            {facility ? (
                <Grid item ref={roofMapRef}>
                    <RoofSectionInfoCard roofSection={roofSection!} facility={facility}></RoofSectionInfoCard>
                </Grid>
            ) : (
                <></>
            )}
            {reportLeakOpen && (
                <ReportLeakDialog
                    open={reportLeakOpen}
                    onClose={handleCloseReportLeak}
                    clientId={facility?.clientId ?? ''}
                    selectedFacility={facility}></ReportLeakDialog>
            )}
            <Grid item container direction='row' spacing={2}>
                <Grid ref={coreInfoRef} item xs={6}>
                    <CoreInfoDashboardCard
                        roofLayerSectionRef={roofSectionsRef}
                        roofLayers={roofSection?.roofLayers ? [...roofSection.roofLayers].sort((x, y) => (x.order > y.order ? 1 : -1)) : []}
                        viewOnly={true}
                    />
                </Grid>
                <Grid ref={inspectorCommentsRef} item xs={6}>
                    <DashboardCard headerTitle='Inspector Comments' headerIcon={<Comment />}>
                        <Typography sx={{ padding: '24px' }}>
                            {roofSection?.inspectorComments && roofSection?.inspectorComments !== ''
                                ? roofSection.inspectorComments
                                : 'No inspector comments for this roof section.'}
                        </Typography>
                    </DashboardCard>
                </Grid>
            </Grid>
            <Grid item container direction='row'>
                <Grid item xs={12}>
                    <DashboardCard headerTitle='Photos' headerIcon={<PhotoLibrary />}>
                        <Box ref={photosRef} padding={2} maxHeight={'70vh'} overflow='scroll'>
                            <PhotosDisplay
                                attachments={attachments}
                                setAttachments={setAttachments}
                                attachmentCaptions={attachmentCaptions}
                                setAttachmentCaptions={setAttachmentCaptions}
                                setAttachmentHoverIndex={setAttachmentHoverIndex}
                                attachmentHoverIndex={attachmentHoverIndex}
                                photoPreviewOnClick={photoPreviewOnClick}
                                disabled={true}
                            />
                            {viewPhotoIsOpen && (
                                <ViewPhotoDialog
                                    open={viewPhotoIsOpen}
                                    onClose={() => setViewPhotoIsOpen(false)}
                                    attachment={selectedAttachment}
                                    previewLink={selectedAttachment?.previewLink}
                                    caption={selectedCaption}
                                />
                            )}
                        </Box>
                    </DashboardCard>
                </Grid>
            </Grid>
            <AuthenticatedComponent
                requiredPermissions={['read:documentsEmployee', 'read:documentsClient', 'read:documentsAll']}
                logic='or'
                component={
                    <Grid item container direction='row'>
                        <Grid ref={documentsRef} item xs={12}>
                            <DashboardCard headerTitle='Documents' headerIcon={<Description />}>
                                <Box px={2} maxHeight={'70vh'} overflow='scroll'>
                                    <RoofSectionDocuments roofSectionId={roofSection?.id} />
                                </Box>
                            </DashboardCard>
                        </Grid>
                    </Grid>
                }
            />
            <AuthenticatedComponent
                requiredPermissions={['read:roofSectionReports']}
                logic='or'
                component={
                    <Grid item container direction='row'>
                        <Grid ref={reportsRef} item xs={12}>
                            <DashboardCard headerTitle='Roof Section Reports' headerIcon={<Download />}>
                                <Box px={2} maxHeight={'70vh'} overflow='scroll'>
                                    <RoofSectionDashboardRoofSectionReportsCard roofSectionId={roofSection?.id!} />
                                </Box>
                            </DashboardCard>
                        </Grid>
                    </Grid>
                }
            />
        </Grid>
    );
};
