import { Grid } from '@mui/material';
import { Assignment } from '@mui/icons-material';
import { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../../../auth';
import { OpenWorkOrdersCard } from '../StartDashboard';
import { IHomeDashboard } from './types';
import { HomeDashboardCard } from './HomeDashboardCard';
import { ExecutiveSummariesFacilitiesCard } from '../ClientDashboard';

export const HomeDashboard: FunctionComponent<IHomeDashboard> = () => {
    const navigate = useNavigate();

    const handleWorkOrdersClick = useCallback(() => {
        navigate('/workorders');
    }, [navigate]);

    const handleCapitalProjectsClick = useCallback(() => {
        navigate('/capitalprojects');
    }, [navigate]);

    return (
        <Grid container direction='column' spacing={3}>
            <Grid container item direction='row' spacing={3}>
                <AuthenticatedComponent
                    requiredPermissions={['read:facilities']}
                    component={
                        <Grid item xs={12}>
                            <HomeDashboardCard title={"Executive Summary of All Clients"} subCard={<ExecutiveSummariesFacilitiesCard />} />
                        </Grid>
                    }
                />
            </Grid>
            <Grid container item direction='row' spacing={3}>
                <AuthenticatedComponent
                    requiredPermissions={['read:workorders']}
                    component={
                        <Grid item xs={12}>
                            <HomeDashboardCard title={"Open Work Orders"} buttonIcon={<Assignment />} buttonOnClick={handleWorkOrdersClick} buttonText={"All Work Orders"} subCard={<OpenWorkOrdersCard isCapitalProjects={false} />} />
                        </Grid>
                    }
                />
            </Grid>
            <Grid container item direction='row' spacing={3}>
                <AuthenticatedComponent
                    requiredPermissions={['read:workorders']}
                    component={
                        <Grid item xs={12}>
                            <HomeDashboardCard title={"Open Capital Projects"} buttonIcon={<Assignment />} buttonOnClick={handleCapitalProjectsClick} buttonText={"All Capital Projects"} subCard={<OpenWorkOrdersCard isCapitalProjects={true} />} />
                        </Grid>
                    }
                />
            </Grid>
        </Grid >
    );
};
