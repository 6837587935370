import { FunctionComponent } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { IConfirmMapDeleteDialog } from './types';

export const ConfirmMapDeleteDialog: FunctionComponent<IConfirmMapDeleteDialog> = (props) => {
    const { open, onClose, onConfirm } = props;

    return (
        <Dialog onClose={onClose} open={open} maxWidth={false}>
            <DialogContent>
                <Grid container direction='column' style={{ maxWidth: '800px', maxHeight: '800px' }}>
                    <Grid item xs={6}>
                        <Typography>Are you sure you want to delete this map?</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
