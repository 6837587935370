import { FunctionComponent, useState } from 'react';
import { IWorkOrderSectionProps } from './types';
import LoadingIndicator from '../../core/LoadingIndicator';
import { ApiError } from '../../core/ApiError';
import { PaginatedProps } from '../../core/DataTable';
import { useGetWorkOrderDocumentsQuery } from '../../../store/apis/work-order-api';
import { useGetRoofSectionsQuery } from '../../../store/apis/roof-section-api';
import { DocumentsFormSection } from '../../core/Documents';
import React from 'react';

export const WorkOrderDocuments: FunctionComponent<IWorkOrderSectionProps> = React.memo((props) => {
    const { workOrderId, facilityId, setFormChanged, isCapitalProject } = props;
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data: roofSectionsData, isLoading: roofSectionsLoading } = useGetRoofSectionsQuery({
        searchText: '',
        sortKey: 'ROOF_NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 250,
        includeInactive: false,
        facilityId: facilityId,
    });
    const { data, isLoading, error, refetch } = useGetWorkOrderDocumentsQuery({
        parentId: workOrderId,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
        },
    });

    if (isLoading || roofSectionsLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <DocumentsFormSection
            searchText={searchText}
            setSearchText={setSearchText}
            setShowInactive={setShowInactive}
            showInactive={showInactive}
            workOrderId={workOrderId}
            facilityId={facilityId}
            setPaginatedProps={setPaginatedProps}
            isLoading={isLoading}
            roofSectionsData={roofSectionsData!}
            data={data!}
            setFormChanged={setFormChanged}
            isCapitalProjectView={isCapitalProject}
        />
    );
});
