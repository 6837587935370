import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DispatchListViewDto } from '../../../models/DispatchListViewDto';
import { useGetPendingDispatchesForContractorQuery } from '../../../store/apis/dispatch-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';

export const PendingDispatchesCardContent = () => {
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const navigate = useNavigate();
    const [selected, setSelected] = useState<readonly number[]>([]);

    const {
        data,
        isLoading,
        error,
        refetch: refetchWorkOrderDispatches,
    } = useGetPendingDispatchesForContractorQuery({
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: false,
    });

    const getRowStyle = useCallback((row: any) => {
        return row.daysPending && row.daysPending > 1 ? { color: '#CC0000' } : {};
    }, []);

    const tableColumns: DataTableColumn<DispatchListViewDto>[] = useMemo(() => [
        { key: 'dispatched', label: 'Dispatched', sortKey: 'DISPATCHED_DATE', fieldMapper: (row) => row.createdOn ? format(new Date(row.createdOn.toString()), 'M/d/yyyy') : '' },
        { key: 'typeName', label: 'Type', sortKey: '', unsorted: true },
        { key: 'woNumber', label: 'WO #', sortKey: 'WO_NUMBER' },
        { key: 'facility', label: 'Facility', sortKey: 'FACILITY', fieldMapper: (row) => row.facilityName },
        { key: 'urgency', label: 'Urgency', sortKey: 'URGENCY' },
        { key: 'days', label: 'Days Pending', sortKey: 'DAYS_PENDING', align: 'right', fieldMapper: (row) => row.daysPending ?? '' },
    ], []);

    const handleRowEdit = useCallback((id: string) => {
        navigate(`/dispatches/approve/${id}`);
    }, [navigate]);

    if (isLoading) {
        return <LoadingIndicator />;
    }
    if (error) {
        <ApiError onReloadClick={refetchWorkOrderDispatches} />;
    }

    return (
        <Box px={2}>
            <Grid container direction='row'>
                <Grid container item xs={12} direction='column' overflow='auto' pr={2}>
                    <Grid item xs={'auto'}>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isLoading}
                            queryResults={data}
                            defaultSortKey='DISPATCHED_DATE'
                            defaultSortDesc={true}
                            setPagination={setPaginatedProps}
                            selected={selected}
                            setSelected={setSelected}
                            isDashboardStyle={true}
                            onRowClick={handleRowEdit}
                            customRowStyling={getRowStyle}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent={'end'}></Grid>
            </Grid>
        </Box>
    );
};
