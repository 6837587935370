import { FC, useCallback, useMemo, useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ICollapsibleRowProps } from '../ClientDashboard/types';
import { formatCurrency } from '../../../util';

export const ExecutiveSummaryBudgetCollapsibleRow: FC<ICollapsibleRowProps> = (props) => {
    const { backgroundColor, headerTextColor, headerText, budgetItems, summaryTotals, additionalRows, budgetSummary } = props;
    const [isOpen, setIsOpen] = useState(false);

    const getNumericValue = useCallback((val?: number) => {
        return val ? val.toLocaleString('en-US') : '';
    }, []);

    const budgetRows = useMemo(() => {
        let shouldExpand = false;
        const rows = budgetItems.map((budgetItem) => {
            if (!budgetItem?.squareFootage && !budgetItem?.immediateRepairsBudget && !budgetItem?.year1Budget && !budgetItem?.year2Budget && !budgetItem?.year3Budget && !budgetItem?.year4Budget && !budgetItem?.year5Budget) {
                return <></>;
            } else {
                shouldExpand = true;
                return <TableRow key={budgetItem.label}>
                    <TableCell>{budgetItem?.label ?? ''}</TableCell>
                    <TableCell align='right'>{getNumericValue(budgetItem?.squareFootage)}</TableCell>
                    <TableCell align='right'>{budgetItem?.immediateRepairsBudget !== null ? formatCurrency(budgetItem?.immediateRepairsBudget) : ''}</TableCell>
                    {budgetSummary.includeYear1Column && <TableCell align='right'>{budgetItem?.year1Budget !== null ? formatCurrency(budgetItem?.year1Budget) : ''}</TableCell>}
                    {budgetSummary.includeYear2Column && <TableCell align='right'>{budgetItem?.year2Budget !== null ? formatCurrency(budgetItem?.year2Budget) : ''}</TableCell>}
                    {budgetSummary.includeYear3Column && <TableCell align='right'>{budgetItem?.year3Budget !== null ? formatCurrency(budgetItem?.year3Budget) : ''}</TableCell>}
                    {budgetSummary.includeYear4Column && <TableCell align='right'>{budgetItem?.year4Budget !== null ? formatCurrency(budgetItem?.year4Budget) : ''}</TableCell>}
                    {budgetSummary.includeYear5Column && <TableCell align='right'>{budgetItem?.year5Budget !== null ? formatCurrency(budgetItem?.year5Budget) : ''}</TableCell>}
                    <TableCell align='right'>{ }</TableCell>
                </TableRow>;
            }
        });
        setIsOpen(shouldExpand);
        return rows;
    }, [budgetItems, budgetSummary.includeYear1Column, budgetSummary.includeYear2Column, budgetSummary.includeYear3Column, budgetSummary.includeYear4Column, budgetSummary.includeYear5Column, getNumericValue]);

    const visibleColumnCount = useMemo(() => {
        return 4 + (budgetSummary.includeYear1Column ? 1 : 0) + (budgetSummary.includeYear2Column ? 1 : 0) + (budgetSummary.includeYear3Column ? 1 : 0) + (budgetSummary.includeYear4Column ? 1 : 0) + (budgetSummary.includeYear5Column ? 1 : 0);
    }, [budgetSummary.includeYear1Column, budgetSummary.includeYear2Column, budgetSummary.includeYear3Column, budgetSummary.includeYear4Column, budgetSummary.includeYear5Column]);

    return (
        <>
            <TableRow sx={{ backgroundColor: backgroundColor, color: headerTextColor, cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)}>
                <TableCell colSpan={visibleColumnCount - 1} sx={{ fontWeight: 'bold', fontSize: '18px', color: headerTextColor }}>
                    {headerText}
                </TableCell>
                <TableCell align='right'>
                    <IconButton size='small' sx={{ color: headerTextColor }}>
                        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            {isOpen && <>
                {budgetRows}
                <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{summaryTotals?.label}</TableCell>
                    <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                        {getNumericValue(summaryTotals?.squareFootage)}
                    </TableCell>
                    <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                        {summaryTotals?.immediateRepairsBudget !== null ? formatCurrency(summaryTotals?.immediateRepairsBudget) : ''}
                    </TableCell>
                    {budgetSummary.includeYear1Column && <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                        {summaryTotals?.year1Budget !== null ? formatCurrency(summaryTotals?.year1Budget) : ''}
                    </TableCell>}
                    {budgetSummary.includeYear2Column && <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                        {summaryTotals?.year2Budget !== null ? formatCurrency(summaryTotals?.year2Budget) : ''}
                    </TableCell>}
                    {budgetSummary.includeYear3Column && <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                        {summaryTotals?.year3Budget !== null ? formatCurrency(summaryTotals?.year3Budget) : ''}
                    </TableCell>}
                    {budgetSummary.includeYear4Column && <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                        {summaryTotals?.year4Budget !== null ? formatCurrency(summaryTotals?.year4Budget) : ''}
                    </TableCell>}
                    {budgetSummary.includeYear5Column && <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                        {summaryTotals?.year5Budget !== null ? formatCurrency(summaryTotals?.year5Budget) : ''}
                    </TableCell>}
                    <TableCell align='right'>{ }</TableCell>
                </TableRow>
                {additionalRows ? additionalRows : <></>}
            </>}
        </>
    );
};
