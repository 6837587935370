import { AddCircle, Delete, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { format } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../../../auth';
import { QueryResultsDto } from '../../../models';
import { RoofSectionReportDto } from '../../../models/RoofSectionReportDto';
import {
    useArchiveRoofSectionReportMutation,
    useUnarchiveRoofSectionReportMutation
} from '../../../store/apis/roof-section-report-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';

export interface IClientReportSectionsProps {
    reportId: string;
    dispatchId?: string;
    disabled?: boolean;
    getRoofSectionsQuery: UseQuery<QueryDefinition<any, any, any, QueryResultsDto<RoofSectionReportDto>>>;
}

export const ClientReportRoofSectionsCard: FC<IClientReportSectionsProps> = ({ reportId, dispatchId, disabled, getRoofSectionsQuery }) => {
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data, error, isLoading, refetch } = getRoofSectionsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        reportId: reportId,
        dispatchId: dispatchId
    });
    const [archiveSection, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive, error: archiveError }] = useArchiveRoofSectionReportMutation();
    const [unarchiveSection, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive, error: unarchiveError }] = useUnarchiveRoofSectionReportMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<RoofSectionReportDto>[] = [
        { key: 'roofNumber', label: 'Roof Number', sortKey: 'ROOF_NUMBER', fieldMapper: (row) => (row.roofNumber ? row.roofNumber : '') },
        {
            key: 'inspectionDate',
            label: 'Date of Inspection',
            sortKey: 'INSPECTION_DATE',

            fieldMapper: (row) => (row.inspectionDate ? format(new Date(row.inspectionDate.toString()), 'M/d/yyyy') : ''),
        },
        {
            key: 'squareFootage',
            label: 'Square Footage',
            sortKey: 'SQUARE_FOOTAGE',
            align: 'right',
            fieldMapper: (row) => (row.squareFootage ? row.squareFootage.toLocaleString() : ''),
        },
        {
            key: 'contact',
            label: 'Technician',
            sortKey: 'TECHNICIAN',
            fieldMapper: (row) => (row.contact ? row.contact.firstName + ' ' + row.contact.lastName : ''),
        },
        { key: 'roofType', label: 'Roof Type', unsorted: true, sortKey: '', fieldMapper: (row) => row.roofSystem?.name },
    ];

    const entityName = useMemo(() => {
        return selected.length === 1 ? 'Report Roof Section' : 'Report Roof Sections';
    }, [selected.length]);

    const handleActionRequestCompletion = useCallback((resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    }, []);

    useFailedActionSnackbar('inactivate', entityName, isArchiveError, () => { handleActionRequestCompletion(resetArchive); }, archiveError);
    useFailedActionSnackbar('activate', entityName, isUnarchiveError, () => { handleActionRequestCompletion(resetUnarchive); }, unarchiveError);
    useSuccessfulActionSnackbar('Inactivated', entityName, isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', entityName, isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback((afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    }, []);

    const handleCreateNew = useCallback(() => {
        if (dispatchId) {
            navigate(`/submitDispatch/${dispatchId}/roofSectionReport/create`);
        } else {
            navigate(`/clientreports/${reportId}/roofsectionreport/create`);
        }
    }, [navigate, reportId, dispatchId]);

    const handleMenuEdit = useCallback(() => {
        const roofSectionReport = data?.pageResults[selected[0]];
        if (roofSectionReport) {
            if (dispatchId) {
                navigate(`/submitDispatch/${dispatchId}/roofSectionReport/${roofSectionReport.id}`);
            } else {
                navigate(`/clientreports/${reportId}/roofsectionreport/edit/${roofSectionReport.id}`);
            }
        }
    }, [data?.pageResults, navigate, reportId, selected, dispatchId]);

    const handleRowEdit = useCallback((id: string) => {
        const roofSectionReport = data?.pageResults.find((x) => x.id === id);
        if (roofSectionReport) {
            if (dispatchId) {
                navigate(`/submitDispatch/${dispatchId}/roofSectionReport/${roofSectionReport.id}`);
            } else {
                navigate(`/clientreports/${reportId}/roofsectionreport/edit/${roofSectionReport.id}`);
            }
        }
    }, [data?.pageResults, navigate, reportId, dispatchId]);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveSection(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [data?.pageResults, selected, unarchiveSection]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveSection(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [archiveSection, data?.pageResults, selected]);

    const canSetActive = useMemo(() => {
        return selected.some((index) => !data?.pageResults[index]?.isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useMemo(() => {
        return selected.some((index) => data?.pageResults[index]?.isActive);
    }, [data?.pageResults, selected]);

    const disableEdit = useCallback((__: string) => {
        if (disabled) {
            return disabled;
        } else {
            return false;
        }
    }, [disabled]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                <Grid item container direction='row' alignItems='center' xs={6}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:reports']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive || disabled}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:reports']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew} disabled={disabled}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:reports', 'edit:reports', 'delete:reports']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:reports']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleCreateNew)} disabled={disabled}>
                                                Add New
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:reports']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1 || disabled}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:reports']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive || disabled}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive || disabled}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        } />
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='ROOF_NUMBER'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:reports', 'read:contractors'], logic: 'or' }}
                    onEdit={handleRowEdit}
                    disableEdit={disableEdit}
                />
            </Grid>
        </Grid>
    );
};
