import { FC } from 'react';
import { useGetClientBudgetItemsQuery } from '../../../store/apis/budget-item-api';
import { ApiError } from '../../core/ApiError';
import LoadingIndicator from '../../core/LoadingIndicator';
import { ExecutiveSummaryBudgetCard } from '../Components/types';
import { IClientCardProps } from './types';

export const DashboardExecutiveSummaryBudgetCard: FC<IClientCardProps> = ({ clientId, facility }) => {
    const { data, isLoading, error, refetch } = useGetClientBudgetItemsQuery({ clientId: clientId!, facilityId: facility?.id });

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        data ? <ExecutiveSummaryBudgetCard budgetSummary={data} /> : <></>
    );
};
