import { FilterList } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FacilityDto, WarrantyTypeDto } from '../../../models';
import { useGetWarrantyTypesQuery } from '../../../store/apis/warranty-type-api';
import { FormNumberInput, patterns } from '../../Forms/FormFields';
import { IFilterWarrantiesProps } from './types';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { useGetClientFacilitiesQuery } from '../../../store/apis/facility-api';

export const FilterWarrantiesDialog: FunctionComponent<IFilterWarrantiesProps> = (props) => {
    const { open, close, yearFilter, typeFilter, setTypeFilter, setYearFilter, facilityFilter, setFacilityFilter, clientId } = props;

    // Filter Values
    const [year, setYear] = useState(yearFilter?.toString() ?? '');
    const [type, setType] = useState<WarrantyTypeDto | null>(typeFilter ?? null);
    const [facility, setFacility] = useState<FacilityDto | null>(facilityFilter ?? null);

    // Error Messages
    const [yearError, setYearError] = useState('');

    // Data Fetching
    const { data: types, isLoading: typesLoading } = useGetWarrantyTypesQuery({ includeInactive: true });
    const { data: clientFacilities, isLoading: isLoadingClientFacilities } = useGetClientFacilitiesQuery({
        parentId: clientId,
        params: {
            pageSize: 10000,
            sortKey: 'NAME',
            sortAsc: true,
            includeInactive: true,
        },
    });
    const isFormDirty = useMemo(() => {
        const isFormClean = year === (yearFilter?.toString() ?? '') && type === (typeFilter ?? null) && facility === (facilityFilter ?? null);
        return !isFormClean;
    }, [type, typeFilter, year, yearFilter, facility, facilityFilter]);

    const onYearChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setYear(event.target.value);
        }
    }, []);

    const isYearValid = useMemo(() => {
        if (patterns.Year.validate.test(year)) {
            setYearError('');
            return true;
        } else {
            setYearError('Start Year must be a 4-digit year.');
            return false;
        }
    }, [year]);

    const clearFilters = useCallback(() => {
        setType(null);
        setYear('');
        setFacility(null);
    }, []);

    const filter = useCallback(() => {
        if (isYearValid) {
            setTypeFilter(type ?? undefined);
            setYearFilter(year !== '' ? parseInt(year) : undefined);
            setFacilityFilter(facility);
            close();
        }
    }, [close, isYearValid, setTypeFilter, setYearFilter, type, year, facility, setFacilityFilter]);

    return (
        <Dialog open={open} onClose={close} maxWidth='sm' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', marginBottom: '16px', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <FilterList />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Filter Warranties
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' spacing={2}>
                    <Grid item width='25%'>
                        <FormNumberInput
                            value={year}
                            onChange={onYearChange}
                            onBlur={() => {}}
                            label='Issued Year'
                            name='issuedYear'
                            errorText={yearError}
                            error={yearError !== ''}
                            fullWidth
                            inputProps={{ inputMode: 'numeric', pattern: patterns.Year.input.source }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <FormLabel>Type</FormLabel>
                            <IEntityAutocomplete
                                options={types}
                                onChange={(e, value) => {
                                    setType(value ?? null);
                                }}
                                value={type}
                                getOptionLabel={(option: WarrantyTypeDto) => option.name}
                                isLoading={typesLoading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <FormLabel>Facility</FormLabel>
                            <IEntityAutocomplete
                                options={clientFacilities?.pageResults ?? []}
                                onChange={(e, value) => {
                                    setFacility(value ?? null);
                                }}
                                value={facility}
                                getOptionLabel={(option: FacilityDto) => option.name}
                                isLoading={isLoadingClientFacilities}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item xs={2}>
                        <Button variant='text' color='secondary' onClick={clearFilters}>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={close}>
                                {isFormDirty ? 'Cancel' : 'Close'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={filter}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
