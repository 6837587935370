import { FormatListBulletedOutlined, Map as MapIcon, PhotoLibrary } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ContactDto, RoofConditionDto, RoofSectionReportDto } from '../../../models';
import { ColorSwatch } from '../../../util';
import { ContactSelect } from '../../CommonInputs';
import { DashboardCard } from '../../Dashboard';
import { IEntitySelect } from '../../core/IEntitySelect';
import { MenuButton } from '../../core/MenuButton';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { PhotosFormSection } from '../../core/Photos';
import { ContractorImmediateRepairsChecklistItemListView } from '../ClientReportForm/ContractorImmediateRepairsChecklistItemListView';
import { FormButton } from '../FormButton';
import { DateTimePicker, FormInput, FormNumberInput } from '../FormFields';
import { FormSection } from '../FormSection';
import { Attachment } from '../types';

export interface IContractorRoofSectionReportFormProps {
    roofSectionReport: RoofSectionReportDto;
    save: (values: RoofSectionReportDto) => void;
    cancel: () => void;
    dispatchId: string;
    defaultContactId?: string;
}

export const ContractorRoofSectionReportForm: FC<IContractorRoofSectionReportFormProps> = ({ roofSectionReport, save, cancel, dispatchId, defaultContactId }) => {
    const loadRoofSectionReportWithDefaultValues = useCallback((report: RoofSectionReportDto) => {
        var loadedRoofSection = _.cloneDeep(report);
        if (!loadedRoofSection.inspectionDate) {
            loadedRoofSection.inspectionDate = new Date();
        }
        if (!loadedRoofSection.contactId && defaultContactId) {
            loadedRoofSection.contactId = defaultContactId;
        }
        return loadedRoofSection;
    }, [defaultContactId]);
    const [formValue, setFormValue] = useState(loadRoofSectionReportWithDefaultValues(roofSectionReport));
    const [fieldErrors, setFieldErrors] = useState(new Map<keyof RoofSectionReportDto, string>());
    const checklistItemRef = useRef<null | HTMLDivElement>(null);
    const photosRef = useRef<null | HTMLDivElement>(null);
    const [photos, setPhotos] = useState<Attachment[]>([]);
    const [photoCaptions, setPhotoCaptions] = useState<string[]>([]);

    const formValueWithPhotos = useMemo(() => {
        return {
            ...formValue,
            photos: photos.map((attachment, index) => {
                return { ...attachment.photo, caption: photoCaptions[index], order: index };
            }),
        };
    }, [formValue, photos, photoCaptions]);

    const isChanged = useMemo(() => JSON.stringify(formValueWithPhotos) !== JSON.stringify(roofSectionReport), [formValueWithPhotos, roofSectionReport]);

    const validateForm = useCallback(() => {
        const errors = new Map<keyof RoofSectionReportDto, string>();
        if (!formValue.inspectionDate) {
            errors.set('inspectionDate', 'Inspection Date is required.');
        }
        if (!formValue.contactId) {
            errors.set('contactId', 'Technician is required.');
        }

        setFieldErrors(errors);
        return errors.size === 0;
    }, [formValue]);

    const handleSave = useCallback(() => {
        if (validateForm()) {
            save(formValueWithPhotos);
        }
    }, [validateForm, save, formValueWithPhotos]);

    const scrollToPhotos = () => {
        photosRef?.current!.scrollIntoView();
    };

    const scrollToChecklistItems = () => {
        checklistItemRef?.current!.scrollIntoView();
    };

    const handleTechnicianChange = useCallback((value: ContactDto | null) => {
        setFormValue((prev) => ({ ...prev, contactId: value?.id ?? undefined, contact: value ?? undefined }));
    }, []);
    const handleInspectionDateChange = useCallback((value: Date | null | undefined) => setFormValue((prev) => ({ ...prev, inspectionDate: value })), []);

    const handleMaintenancePerformedChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setFormValue((prev) => ({ ...prev, maintenancePerformed: event.target.value })),
        []
    );

    const handleInspectorCommentsChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setFormValue((prev) => ({ ...prev, inspectorComments: event.target.value })),
        []
    );

    const handleInspectorRecommendationsChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setFormValue((prev) => ({ ...prev, inspectorRecommendations: event.target.value })),
        []
    );

    // If the provided roofSectionReport changes then it will update the form values
    useEffect(() => {
        setFormValue(loadRoofSectionReportWithDefaultValues(roofSectionReport));
    }, [roofSectionReport, loadRoofSectionReportWithDefaultValues]);

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off'>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <MapIcon /> {`Roof Section # ${roofSectionReport.roofNumber}`}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: `Work Order #${roofSectionReport.reportWorkOrderNumber}`, navLink: `/submitDispatch/${dispatchId}` },
                        ]}
                        currentPageLabel={`Roof Section #${roofSectionReport.roofNumber}`}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={cancel}>
                            {isChanged ? 'Cancel' : 'Close'}
                        </FormButton>
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' onClick={handleSave} disabled={roofSectionReport.isLocked}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={2} mt={1}>
                    <Grid item>
                        <MenuButton onClick={scrollToPhotos} tooltip='PHOTOS'>
                            <PhotoLibrary />
                        </MenuButton>
                    </Grid>
                    <Grid item>
                        <MenuButton onClick={scrollToChecklistItems} tooltip='Immediate Repairs Checklist'>
                            <FormatListBulletedOutlined />
                        </MenuButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' spacing={6} alignItems={'baseline'}>
                        <Grid item xs={6} sm={3} md={2}>
                            <FormInput label='Roof #' required disabled value={formValue.roofNumber} onChange={() => {}} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <FormInput label='Roof Type' required disabled value={formValue.roofSystem?.name} onChange={() => {}} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <FormControl fullWidth disabled>
                                <FormLabel>Condition</FormLabel>
                                <IEntitySelect
                                    getItem={(item: RoofConditionDto) => <ColorSwatch condition={item} />}
                                    value={formValue.currentConditionId ?? ''}
                                    onChange={() => {}}
                                    menuItems={formValue.currentCondition ? [formValue.currentCondition] : []}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <FormNumberInput
                                value={formValue.squareFootage}
                                name='squareFootage'
                                onChange={() => {}}
                                disabled
                                label='Square Footage'
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <FormControl fullWidth>
                                <DateTimePicker
                                    label='Date of Inspection'
                                    value={formValue.inspectionDate}
                                    onChange={handleInspectionDateChange}
                                    disabled={roofSectionReport.isLocked}
                                    dateOnly={true}
                                    error={fieldErrors.has('inspectionDate')}
                                    required
                                />
                                <FormHelperText error>{fieldErrors.get('inspectionDate')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            {formValue.reportContractorId && (
                                <ContactSelect
                                    contractorId={formValue.reportContractorId}
                                    label='Technician'
                                    value={formValue.contactId ?? ""}
                                    onChange={handleTechnicianChange}
                                    disabled={formValue.isLocked}
                                    errorMessage={fieldErrors.get('contactId')}
                                    required
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item container direction='column' spacing={4}>
                        <Grid item xs={4}>
                            <FormInput
                                value={formValue.maintenancePerformed}
                                onChange={handleMaintenancePerformedChange}
                                disabled={roofSectionReport.isLocked}
                                label='Maintenance Performed'
                                fullWidth
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={formValue.inspectorComments}
                                onChange={handleInspectorCommentsChange}
                                disabled={roofSectionReport.isLocked}
                                label='Inspector Comments & Notes'
                                fullWidth
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={formValue.inspectorRecommendations}
                                onChange={handleInspectorRecommendationsChange}
                                disabled={roofSectionReport.isLocked}
                                label='Inspector Recommendations'
                                fullWidth
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <PhotosFormSection
                initValues={roofSectionReport}
                photos={photos}
                setPhotos={setPhotos}
                photoCaptions={photoCaptions}
                setPhotoCaptions={setPhotoCaptions}
            />
            <Grid item ref={checklistItemRef}>
                <DashboardCard headerTitle='Immediate Repairs Checklist' headerIcon={<FormatListBulletedOutlined />}>
                    <ContractorImmediateRepairsChecklistItemListView
                        disabled={roofSectionReport.isLocked}
                        dispatchId={dispatchId}
                        reportId={roofSectionReport.reportId}
                        roofSectionReportId={roofSectionReport.id}
                        roofNumber={roofSectionReport.roofNumber}
                    />
                </DashboardCard>
            </Grid>
        </Grid>
    );
};
