import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { DateTimePicker } from '../FormFields';
import { useGetClientsQuery } from '../../../store/apis/client-api';
import { useGetFacilitiesQuery } from '../../../store/apis/facility-api';
import { ClientDto, FacilityDto } from '../../../models';
import { IClientReportFormProps } from './types';
import { ReportType } from '../types';

export const FilterReportsDialog: FunctionComponent<IClientReportFormProps> = (props) => {
    const {
        open,
        onClose,
        fromDateFilter,
        setFromDateFilter,
        toDateFilter,
        setToDateFilter,
        clientFilter,
        setClientFilter,
        facilityFilter,
        setFacilityFilter,
        typeFilter,
        setTypeFilter,
    } = props;

    // Filter Values
    const [fromReportDate, setFromReportDate] = useState<Date | null>(fromDateFilter ?? null);
    const [toReportDate, setToReportDate] = useState<Date | null>(toDateFilter ?? null);
    const [client, setClient] = useState<ClientDto | null>(clientFilter ?? null);
    const [facility, setFacility] = useState<FacilityDto | null>(facilityFilter ?? null);
    const [type, setType] = useState(typeFilter || typeFilter === 0 ? ReportType[typeFilter] : '');

    // Data Fetching
    const { data: clients, isLoading: clientsLoading } = useGetClientsQuery({ pageSize: 100000, includeInactive: true });
    const { data: facilities, isLoading: facilitiesLoading } = useGetFacilitiesQuery({ pageSize: 100000, includeInactive: true });

    const isFormDirty = useMemo(() => {
        const isFormClean = (
            fromReportDate === (fromDateFilter ?? null)
            && toReportDate === (toDateFilter ?? null)
            && client === (clientFilter ?? null)
            && facility === (facilityFilter ?? null)
            && type === (typeFilter || typeFilter === 0 ? ReportType[typeFilter] : '')
        );
        return !isFormClean;
    }, [client, clientFilter, facility, facilityFilter, fromDateFilter, fromReportDate, toDateFilter, toReportDate, type, typeFilter]);

    const onTypeChange = useCallback((event: SelectChangeEvent) => {
        setType(event.target.value);
    }, []);

    const handleFromReportDateChange = useCallback((value: Date | null | undefined) => {
        setFromReportDate(value ?? null);
    }, []);

    const handleToReportDateChange = useCallback((value: Date | null | undefined) => {
        setToReportDate(value ?? null);
    }, []);

    const clearFilters = useCallback(() => {
        setType('');
        setTypeFilter(undefined);
        setClient(null);
        setFacility(null);
        setFromReportDate(null);
        setToReportDate(null);
    }, [setTypeFilter]);

    const filter = useCallback(() => {
        if (client) {
            setClientFilter(client);
        } else {
            setClientFilter(null);
        }
        if (facility) {
            setFacilityFilter(facility);
        } else {
            setFacilityFilter(null);
        }
        if (type) {
            switch (type) {
                case 'Binder Portfolio':
                    setTypeFilter(ReportType['Binder Portfolio']);
                    break;
                case 'Close Out Report':
                    setTypeFilter(ReportType['Close Out Report']);
                    break;
                case 'JSI Report':
                    setTypeFilter(ReportType['JSI Report']);
                    break;
                case 'Moisture Guard':
                    setTypeFilter(ReportType['Moisture Guard']);
                    break;
                case 'RoofSMART Report':
                    setTypeFilter(ReportType['RoofSMART Report']);
                    break;
                default:
                    break;
            }
        }
        setFromDateFilter(fromReportDate);
        setToDateFilter(toReportDate);
        onClose();
    }, [client, facility, fromReportDate, onClose, setClientFilter, setFacilityFilter, setFromDateFilter, setToDateFilter, setTypeFilter, toReportDate, type]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', marginBottom: '16px', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <FilterList />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Filter Client Reports
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' spacing={2}>
                    <Grid item container xs={6}>
                        <Grid item xs={6}>
                            <FormControl>
                                <FormLabel>Report Date</FormLabel>
                                <Grid item container direction='row' alignItems={'center'} spacing={2}>
                                    <Grid item xs={6}>
                                        <DateTimePicker label='' value={fromReportDate} onChange={handleFromReportDateChange} dateOnly={true} />
                                    </Grid>
                                    <Grid item xs={1} sx={{ justifyContent: 'center' }}>
                                        <Typography>to</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <DateTimePicker label='' value={toReportDate} onChange={handleToReportDateChange} dateOnly={true} />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Owner</FormLabel>
                                <Autocomplete
                                    value={client ?? null}
                                    onChange={(event, value) => {
                                        if (value) {
                                            setClient(value);
                                        } else {
                                            setClient(null);
                                        }
                                    }}
                                    options={clients?.pageResults.filter(option => option.isActive || option.id === client?.id) ?? []}
                                    loading={clientsLoading}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option: ClientDto) => `${option.name}${option.isActive ? '' : ' (Inactive)'}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {clientsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Facility</FormLabel>
                                <Autocomplete
                                    value={facility ?? null}
                                    onChange={(event, value) => {
                                        if (value) {
                                            setFacility(value);
                                        } else {
                                            setFacility(null);
                                        }
                                    }}
                                    options={facilities?.pageResults.filter(option => option.isActive || option.id === facility?.id) ?? []}
                                    loading={facilitiesLoading}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option: FacilityDto) => `${option.name}${option.isActive ? '' : ' (Inactive)'}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {facilitiesLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row'>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Report Type</FormLabel>
                                <Select value={type} onChange={onTypeChange}>
                                    <MenuItem value={''}></MenuItem>
                                    <MenuItem value={ReportType[3]}>{ReportType[3]}</MenuItem>
                                    <MenuItem value={ReportType[4]}>{ReportType[4]}</MenuItem>
                                    <MenuItem value={ReportType[2]}>{ReportType[2]}</MenuItem>
                                    <MenuItem value={ReportType[1]}>{ReportType[1]}</MenuItem>
                                    <MenuItem value={ReportType[0]}>{ReportType[0]}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item xs={2}>
                        <Button variant='text' color='secondary' onClick={clearFilters}>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                                {isFormDirty ? 'Cancel' : 'Close'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={filter}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
