import { serverApi } from '..';
import { QueryResultsDto, ReportPdfDto } from '../../models';
import { JsiReportDto } from '../../models/JsiReportDto';
import { buildSearchQueryRoute } from '../../util';
import { ReportSearchQueryParams } from '../types';

const JsiReportApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getJsiReports: builder.query<QueryResultsDto<JsiReportDto>, ReportSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('jsiReport', params),
            providesTags: [{ type: 'Report' }]
        }),
        getJsiReport: builder.query<JsiReportDto, string>({
            query: (reportId) => ({ url: `jsiReport/${reportId}` }),
            providesTags: [{ type: 'Report' }]
        }),
        getJsiReportPdf: builder.query<ReportPdfDto, string>({
            query: (reportId) => ({ url: `jsiReport/${reportId}/reportPdf` }),
            providesTags: [{ type: 'Report' }]
        }),
        createJsiReport: builder.mutation<JsiReportDto, JsiReportDto>({
            query: (reportDto) => ({
                url: 'jsiReport',
                method: 'POST',
                body: reportDto
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        createJsiReportCopy: builder.mutation<JsiReportDto, string>({
            query: (reportId) => ({
                url: `jsiReport/copy/${reportId}`,
                method: 'POST'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        createJsiReportPdf: builder.mutation<ReportPdfDto, string>({
            query: (reportId) => ({
                url: `jsiReport/${reportId}/pdf`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        unlockJsiReport: builder.mutation<void, string>({
            query: (reportId) => ({
                url: `jsiReport/${reportId}/unlock`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        updateJsiReport: builder.mutation<void, JsiReportDto>({
            query: (reportDto) => ({
                url: 'jsiReport',
                method: 'PUT',
                body: reportDto
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        archiveJsiReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `jsiReport/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Report' }]
        }),
        unarchiveJsiReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `jsiReport/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Report' }]
        })
    })
});

export const {
    useLazyGetJsiReportQuery,
    useLazyGetJsiReportPdfQuery,
    useGetJsiReportQuery,
    useCreateJsiReportPdfMutation,
    useCreateJsiReportMutation,
    useUnlockJsiReportMutation,
    useUpdateJsiReportMutation,
    useArchiveJsiReportMutation,
    useUnarchiveJsiReportMutation,
    useGetJsiReportsQuery,
    useCreateJsiReportCopyMutation
} = JsiReportApi;