import { Button, Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { StyledDashboardCard } from '../types';
import { IHomeDashboardCard } from './types';

export const HomeDashboardCard: FunctionComponent<IHomeDashboardCard> = (props) => {
    const { title, buttonIcon, buttonOnClick, buttonText, subCard } = props;

    return (
        <StyledDashboardCard variant='outlined' square>
            <Grid item container direction='row' justifyContent='space-between' alignItems={'baseline'} pr={2}>
                <Typography variant='h3'>{title}</Typography>
                {buttonText && <Button variant='contained' color='secondary' startIcon={buttonIcon} onClick={buttonOnClick}>
                    {buttonText}
                </Button>}
            </Grid>
            {subCard}
        </StyledDashboardCard>
    );
};
