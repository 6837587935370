import { History, Search } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useEffect, useState } from 'react';
import { WorkOrderDto } from '../../../models';
import { useGetLeakLocationQuery } from '../../../store/apis/leak-location-api';
import { useGetLeakLocationHistoryQuery } from '../../../store/apis/work-order-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { StandardDialogHeader } from '../StandardDialogHeader';
import { IWorkOrderLeakLocationHistoryDialogProps } from './types';

export const WorkOrderLeakLocationHistoryDialog: FunctionComponent<IWorkOrderLeakLocationHistoryDialogProps> = (props) => {
    const { leakLocationId, open, onClose } = props;
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [searchText, setSearchText] = useState('');
    const {
        data: workOrders,
        isLoading: workOrdersLoading,
        error: workOrderError,
        refetch: leakLocationRefetch,
    } = useGetLeakLocationHistoryQuery({
        leakLocationId: leakLocationId!,
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
    });
    const { data: leakLocation } = useGetLeakLocationQuery(leakLocationId!);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<WorkOrderDto>[] = [
        {
            key: 'woNumber',
            label: 'WO #',
            sortKey: 'WO_NUMBER',
        },
        {
            key: 'dispatchDate',
            label: 'Dispatch Date',
            sortKey: '',
            unsorted: true,
            fieldMapper: (row) => (row.dispatchDate ? format(new Date(row.dispatchDate.toString()), 'M/d/yyyy') : ''),
        },
        {
            key: 'contractor',
            label: 'Contractor',
            sortKey: '',
            unsorted: true,
            fieldMapper: (row) => row.contractor?.name,
        },
        {
            key: 'endDate',
            label: 'Work End Date',
            sortKey: '',
            unsorted: true,
            fieldMapper: (row) => (row.endDate ? format(new Date(row.endDate.toString()), 'M/d/yyyy') : ''),
        },
    ];

    useEffect(() => {
        leakLocationRefetch();
    }, [open, leakLocationRefetch]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    return (
        <Dialog onClose={onClose} open={open} maxWidth='md' fullWidth>
            <StandardDialogHeader icon={<History sx={{ color: 'white' }} />} title='Leak Location History' />
            <DialogContent>
                {workOrdersLoading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {workOrderError ? (
                            <ApiError onReloadClick={leakLocationRefetch} />
                        ) : (
                            <Grid item container direction='column' spacing={2} sx={{ paddingTop: 2, paddingX: 2 }}>
                                <Grid item container direction='row' alignItems={'center'}>
                                    <Grid item xs={8}>
                                        <Typography fontWeight={'bold'}>Leak Location: {leakLocation?.name}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id='search-box'
                                            value={searchText}
                                            onChange={handleSearchChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <Search />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label='Search'
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {workOrders && workOrders.pageResults.length > 0 ? (
                                        <PaginatedDataTable
                                            columns={tableColumns}
                                            loading={workOrdersLoading}
                                            queryResults={workOrders}
                                            defaultSortKey='WO_NUMBER'
                                            defaultSortDesc
                                            setPagination={setPaginatedProps}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    ) : (
                                        <Typography sx={{ fontStyle: 'italic' }}>No history found for this leak location</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions sx={{ margin: '16px 0', paddingX: '16px' }}>
                <Button variant='contained' color='primary' onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
