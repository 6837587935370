import { Close, ViewList } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { IInteriorDrawerComponentProps } from "./types";

export const InteriorDrawerComponent: React.FunctionComponent<IInteriorDrawerComponentProps> = (props) => {
    const { title, drawerContents, onExpand, onClose } = props;
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (expanded) {
            onExpand?.();
        } else {
            onClose?.();
        }
    }, [expanded, onClose, onExpand]);

    const toggleDrawer = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    return (
        <Grid container direction='row' wrap='nowrap'>
            <Grid item sx={{ borderRight: '1px solid #aaaaaa', backgroundColor: expanded ? 'white' : '#eeeeee', maxWidth: expanded ? '400px' : '70px' }} xs>
                <Grid container direction='column' alignItems='center'>
                    {!expanded && <Grid item>
                        <Tooltip title={title ?? ''}>
                            <IconButton onClick={toggleDrawer} size='large'>
                                <ViewList fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>}
                    {expanded &&
                        <Grid item container direction='column' sx={{ padding: '20px' }}>
                            <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
                                <Grid item>
                                    {title && <Typography variant='h3'>Legend</Typography>}
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={toggleDrawer}>
                                        <Close fontSize="large" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            {drawerContents}
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs maxWidth={'97% !important'}>
                {props.children}
            </Grid>
        </Grid >
    );
};