import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { BudgetForm } from '../../Components/Forms/BudgetForm';
import { BudgetDto } from '../../models';
import { useGetBudgetQuery, useUpdateBudgetMutation } from '../../store/apis/budget-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditBudgetView: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetBudgetQuery(id!);
    const [updateBudget, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateBudgetMutation();

    useFailedUpdateSnackbar('budget', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Budget', isUpdateSuccess, resetUpdate);

    const handleSave = (values: BudgetDto) => {
        updateBudget(values);
    };

    const handleCancel = () => {
        navigate('/budgets');
    };


    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <BudgetForm save={handleSave} cancel={handleCancel} budgetNumberError={""} initValues={data} />
    );
};