import { FormControl, Grid, Typography } from '@mui/material';
import { StatusLabelProps } from '.';
import { FC, useEffect } from 'react';
import { useLazyGetWorkOrderStatusHistoryLogsQuery } from '../../../../store/apis/work-order-dispatch-history-api';
import LoadingIndicator from '../../../core/LoadingIndicator';
import { dispatchStatusIcons } from '../../DispatchStatusIcons';
import { WorkOrderStatusHistoryLineItemDto } from '../../../../models/workOrderStatusHistoryDto';
import { format } from 'date-fns';
import { useFailedActionSnackbar } from '../../../../util/customHooks';
import { DateTimePicker } from '../../FormFields';
import { WorkOrderDto } from '../../../../models';
import { DispatchDto } from '../../../../models/DispatchDto';

export interface IDispatchInformationProps {
    workOrder?: WorkOrderDto;
    dispatch?: DispatchDto;
    hideWONumber?: boolean;
    scheduledDate?: {
        scheduledDate: Date | null | undefined;
        setScheduledDate: (date?: Date | null) => void;
        validate: (field: string) => void;
        setFieldErrors: (errors: any) => void;
        fieldErrors: any;
        disabled: boolean;
    };
}

export const DispatchInformation: FC<IDispatchInformationProps> = (props) => {
    const {
        workOrder,
        dispatch,
        hideWONumber,
        scheduledDate
    } = props;

    const [getHistory, { isError: isGetHistoryError, data: historyStatuses, isLoading: getHistoryLoading }] = useLazyGetWorkOrderStatusHistoryLogsQuery();

    const compareStatuses = (a: StatusLabelProps, b: StatusLabelProps) => {
        if (a.status.createdOn && b.status.createdOn) {
            if (a.status.createdOn > b.status.createdOn) {
                return 1;
            }
            else if (a.status.createdOn < b.status.createdOn) {
                return -1;
            }
        }
        return 0;
    };

    const getStatusLabelProps = (x: WorkOrderStatusHistoryLineItemDto): StatusLabelProps => {
        if (dispatch && dispatch.rejectionReason && x.statusType === "Rejected") {
            return ({ status: x, reason: dispatch.rejectionReason, color: "default" });
        }
        return ({ status: x, color: "default" });
    };

    const getHistoryLabels = () => {
        let statusLabelDetails: StatusLabelProps[] = (historyStatuses && historyStatuses.pageResults.map(x => getStatusLabelProps(x))) ?? [];

        statusLabelDetails.sort(compareStatuses);

        if (statusLabelDetails.length > 0 && statusLabelDetails[statusLabelDetails.length - 1].status.statusType === "Needs Attention") {
            statusLabelDetails[statusLabelDetails.length - 1].color = '#CC0000';
        }

        return statusLabelDetails.map(statusLabelInfo => getHistoryLabel(statusLabelInfo));
    };

    const getHistoryLabel = (labelProps: StatusLabelProps) => {
        return <Grid item key={labelProps.status.createdOn?.toString()}>
            <Typography sx={{ display: "flex", alignItems: "center", color: labelProps.color }}>
                {dispatchStatusIcons[labelProps.status.statusType as keyof typeof dispatchStatusIcons]}
                <Typography sx={{ marginLeft: '5px', fontWeight: 'bold' }}>
                    {labelProps.status.statusType} {labelProps.status.createdOn ? new Date(labelProps.status.createdOn).toLocaleDateString() : ''}{labelProps.reason ? ' ' + labelProps.reason : ''}
                </Typography>
            </Typography>
        </Grid >;
    };

    useFailedActionSnackbar('retrieving', 'dispatch', isGetHistoryError);

    useEffect(() => {
        if (dispatch?.id) {
            getHistory({
                pageSize: 999999,
                dispatchId: dispatch?.id,
            });
        }
    }, [dispatch, getHistory]);

    if (getHistoryLoading) {
        return <LoadingIndicator />;
    }
    return (
        <>
            <Grid container direction='row' xs={12} justifyContent='space-between' marginBottom={2}>
                <Grid item>
                    <Typography variant='h3'>
                        {dispatch?.clientName ?? workOrder?.facility?.client.name ?? workOrder?.facility?.client.contactName ?? ''}
                    </Typography>
                </Grid>
                <Grid item>
                    {!hideWONumber && <Typography variant='h3'>Work Order #{dispatch?.woNumber ?? workOrder?.woNumber ?? ''}</Typography>}
                </Grid>
            </Grid>
            <Grid container direction='row' xs={12} marginBottom={2} columnSpacing={2}>
                <Grid item xs={2}>
                    {dispatch?.facilityName || workOrder?.facility?.name ? (
                        <Typography>{dispatch?.facilityName ?? workOrder?.facility?.name ?? ''}</Typography>
                    ) : (
                        <></>
                    )}
                    {dispatch?.addressLine1 || workOrder?.facility?.addressLine1 ? (
                        <Typography>{dispatch?.addressLine1 ?? workOrder?.facility?.addressLine1 ?? ''}</Typography>
                    ) : (
                        <></>
                    )}
                    {dispatch?.addressLine2 || workOrder?.facility?.addressLine2 ? (
                        <Typography>{dispatch?.addressLine2 ?? workOrder?.facility?.addressLine2 ?? ''}</Typography>
                    ) : (
                        <></>
                    )}
                    {dispatch?.city ||
                        workOrder?.facility?.city ||
                        dispatch?.state ||
                        workOrder?.facility?.state ||
                        dispatch?.zipCode ||
                        workOrder?.facility?.zipCode ? (
                        <Typography>
                            {dispatch?.city ?? workOrder?.facility?.city ?? ''}, {dispatch?.state ?? workOrder?.facility?.state ?? ''}{' '}
                            {dispatch?.zipCode ?? workOrder?.facility?.zipCode ?? ''}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Grid>
                {dispatch && (
                    <>
                        <Grid item xs={2}>
                            <Typography sx={{ fontWeight: 'bold' }}>Urgency:</Typography>
                            <Typography>{dispatch.urgency && dispatch.urgency !== '' ? dispatch.urgency : '-'}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{ fontWeight: 'bold' }}>Not to Exceed:</Typography>
                            <Typography>{dispatch.nte && dispatch.nte !== '' ? dispatch.nte : '-'}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {dispatch.otherDescription && dispatch.otherDescription !== '' && (
                                <>
                                    <Typography sx={{ fontWeight: 'bold' }}>Other Description:</Typography>
                                    <Typography>{dispatch.otherDescription}</Typography>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            {!scheduledDate || scheduledDate.disabled ?
                                <>
                                    <Typography sx={{ fontWeight: 'bold' }}>Scheduled:</Typography>
                                    <Typography>{dispatch.scheduledDate ? format(new Date(dispatch.scheduledDate.toString()), 'M/d/yyyy') : 'TBD'}</Typography>
                                </>
                                :
                                <FormControl fullWidth disabled={scheduledDate.disabled}>
                                    <DateTimePicker
                                        onBlur={() => scheduledDate.validate('SCHEDULED_DATE')}
                                        errorText={scheduledDate.fieldErrors.SCHEDULED_DATE}
                                        label='Scheduled Date'
                                        value={scheduledDate.scheduledDate}
                                        onChange={(date) => {
                                            scheduledDate.setScheduledDate(date);
                                        }}
                                        onAcceptDate={() => scheduledDate.setFieldErrors({ ...scheduledDate.fieldErrors, SCHEDULED_DATE: '' })}
                                        dateOnly={true}
                                        required
                                        error={scheduledDate.fieldErrors.SCHEDULED_DATE !== ''}
                                        disabled={scheduledDate.disabled}
                                    />
                                </FormControl>
                            }
                        </Grid>
                    </>
                )}
            </Grid>
            {dispatch && (
                <Grid container direction='row' xs={12} columnSpacing={3} rowSpacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 'bold' }}>Description of Problem:</Typography>
                        <Typography paragraph>{dispatch.problemDescription ?? ''}</Typography>
                    </Grid>
                    {getHistoryLabels()}
                </Grid>
            )}
        </>
    );
};
