import { useAuth0 } from "@auth0/auth0-react";
import jwt from 'jsonwebtoken';
import { useCallback, useEffect, useState } from "react";
import { userHasPermissions } from "../../auth";
import { DispatchDto } from "../../models/DispatchDto";
import { useCloseOutDispatchMutation, useUnlockDispatchMutation } from "../../store/apis/dispatch-api";
import { useFailedActionSnackbar, useFailedCreateSnackbar, useSuccessfulActionSnackbar, useSuccessfulCreateSnackbar, useUserRoleChecker } from "../../util";

export function useSubmitDispatchForm(dispatch: DispatchDto, afterCloseOutComplete: (createdRecordId?: string) => void) {
    const auth = useAuth0();
    const [confirmUnlockOpen, setConfirmUnlockOpen] = useState(false);
    const [isFullEdit, setIsFullEdit] = useState(false);
    const { isAdmin, isEmployee } = useUserRoleChecker();

    const [closeOutDispatch, { data: closeOutReportCopy, isSuccess: isCreateCloseOutReportSuccess, isError: isCreateCloseOutReportError, reset: resetCloseOutReportCreate }] = useCloseOutDispatchMutation();
    useFailedCreateSnackbar('close out report', isCreateCloseOutReportError, resetCloseOutReportCreate);
    useSuccessfulCreateSnackbar('Close out report', isCreateCloseOutReportSuccess, () => {
        afterCloseOutComplete(closeOutReportCopy);
        resetCloseOutReportCreate();
    });

    const [unlockDispatch, { isError: isUnlockError, isSuccess: isUnlockSuccess, reset: resetUnlock }] = useUnlockDispatchMutation();
    useFailedActionSnackbar('unlock', 'dispatch', isUnlockError, resetUnlock);
    useSuccessfulActionSnackbar('Unlocked', 'Dispatch', isUnlockSuccess, resetUnlock);

    const isLocked =
        dispatch.lockedOn !== undefined && dispatch.lockedOn !== null && dispatch.lockedOn.toString() !== 'Invalid Date';

    const handleUnlock = useCallback((id: string) => {
        unlockDispatch(id);

        setConfirmUnlockOpen(false);
    }, [unlockDispatch]);

    const getSubmitButtonText = useCallback(() => {
        if ((dispatch.status === 'Completed' || dispatch.status === 'Needs Attention') && (isAdmin || isEmployee)) {
            return 'Close Out';
        } else {
            return 'Submit';
        }
    }, [isAdmin, isEmployee, dispatch.status]);

    useEffect(() => {
        async function getUserPermissions() {
            const token = await auth.getAccessTokenSilently();

            return jwt.decode(token);
        }

        if (!auth.isLoading && auth.isAuthenticated && auth.user) {
            getUserPermissions().then((result) => {
                const permissions = ((result as any)?.permissions as string[]) ?? [];

                setIsFullEdit(userHasPermissions(['edit:dispatches'], permissions));
            });
        }
    }, [auth, isFullEdit, setIsFullEdit]);

    return {
        closeOutDispatch,
        getSubmitButtonText,
        isLocked,
        isFullEdit,
        confirmUnlockOpen,
        setConfirmUnlockOpen,
        handleUnlock
    };
}