import { Button, Grid, TextField } from '@mui/material';
import { FunctionComponent, useCallback } from 'react';
import { ImportLineItemsStepOneProps } from './types';

export const ImportLineItemsStepOne: FunctionComponent<ImportLineItemsStepOneProps> = (props) => {
    const { selectedFile, setSelectedFile, setIsReadyForNext } = props;

    const handleChooseFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files instanceof FileList) {
            setSelectedFile(event.target.files[0]);
            setIsReadyForNext(true);
        }
    }, [setIsReadyForNext, setSelectedFile]);

    return (
        <Grid container direction='column' justifyContent={'space-around'} pt={4}>
            <Grid item container direction='row' xs={6}>
                <Grid item sx={{ pr: 2 }}>
                    <label htmlFor='choose-file'>
                        <input name='choose-file' id='choose-file' type='file' accept='.csv' style={{ display: 'none' }} onChange={handleChooseFile} />
                        <Button variant='contained' component='span' size='medium' color='secondary' sx={{ ml: 0 }}>
                            Choose File
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={11} sx={{ flex: 1 }}>
                    <TextField size='small' fullWidth label='File Name' value={selectedFile?.name} InputLabelProps={{ shrink: true }}>
                        {selectedFile?.name}
                    </TextField>
                </Grid>
            </Grid>
        </Grid>
    );
};
