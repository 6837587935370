import { FC, useCallback } from 'react';
import { IClientFacilityRoofPlanPicker } from './types';
import { FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import { ClientDto, FacilityDto, RoofProfileDto } from '../../../models';
import { useGetClientsQuery } from '../../../store/apis/client-api';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { useGetClientFacilitiesQuery } from '../../../store/apis/facility-api';
import { useFailedActionSnackbar } from '../../../util/customHooks';

export const ClientFacilityRoofPlanPicker: FC<IClientFacilityRoofPlanPicker> = (props) => {
    const { disabled, fieldErrors, facility, setFacility, setRoofProfile, setFormChanged, onFieldBlur, roofProfile, client, setClient, xsContainer = 8, xsItems = 4, spacing = 8 } = props;

    const { data: clients, isLoading: clientsLoading, isError: clientsError } = useGetClientsQuery({ includeInactive: true, pageSize: 99999 });
    const { data: facilities, isLoading: facilitiesLoading, isError: facilitiesError } = useGetClientFacilitiesQuery({ parentId: client?.id ?? '', params: { includeInactive: true, pageSize: 99999 } });
    useFailedActionSnackbar('retrieving', 'clients', clientsError);
    useFailedActionSnackbar('retrieving', 'facilities', facilitiesError);

    const handleValidation = useCallback(() => {
        onFieldBlur && onFieldBlur('FACILITY')();
        setRoofProfile && onFieldBlur && onFieldBlur('ROOF_PLAN')();
    }, [onFieldBlur, setRoofProfile]);


    const getClientSelect = useCallback(() => {
        return (
            <FormControl fullWidth required disabled={disabled}>
                <FormLabel>Owner</FormLabel>
                <IEntityAutocomplete
                    value={client}
                    onChange={(_event, value) => {
                        setClient(value);
                        setFacility(undefined);
                        setRoofProfile && setRoofProfile(undefined);
                        setFormChanged && setFormChanged(true);
                    }}
                    disabled={disabled}
                    options={clients?.pageResults ?? []}
                    isLoading={clientsLoading}
                    getOptionLabel={(option: ClientDto) => `${option.name}`}
                    validate={handleValidation}
                />
            </FormControl>
        );
    }, [disabled, client, clients?.pageResults, clientsLoading, handleValidation, setClient, setFacility, setRoofProfile, setFormChanged]);

    const getFacilitySelect = useCallback(() => {
        return (
            <FormControl error={fieldErrors?.FACILITY !== ''} fullWidth required disabled={disabled}>
                <FormLabel>Facility</FormLabel>
                <IEntityAutocomplete
                    value={facility}
                    onChange={(_event, value: FacilityDto) => {
                        setFacility(value);
                        setClient(value?.client);
                        setRoofProfile && setRoofProfile(value?.roofProfiles?.find(x => x.isCurrent));
                        setFormChanged && setFormChanged(true);
                    }}
                    disabled={disabled}
                    options={facilities?.pageResults ?? []}
                    isLoading={facilitiesLoading}
                    getOptionLabel={(option: FacilityDto) => `${option.name}`}
                    error={fieldErrors?.FACILITY !== ''}
                    validate={handleValidation}
                />
                <FormHelperText>{fieldErrors?.FACILITY}</FormHelperText>
            </FormControl>
        );
    }, [fieldErrors?.FACILITY, disabled, facility, facilities?.pageResults, facilitiesLoading, handleValidation, setFacility, setClient, setRoofProfile, setFormChanged]);

    const disableRoofPlan = facility === null || facility === undefined || disabled;

    const getRoofPlanSelect = useCallback(() => {
        return (
            <FormControl error={fieldErrors?.ROOF_PLAN !== ''} fullWidth required disabled={disableRoofPlan}>
                <FormLabel>Roof Plan</FormLabel>
                <IEntityAutocomplete
                    value={roofProfile}
                    onChange={(_event, value) => {
                        setRoofProfile && setRoofProfile(value ?? undefined);
                    }}
                    disabled={disableRoofPlan}
                    options={facility?.roofProfiles ?? []}
                    isLoading={facilitiesLoading}
                    getOptionLabel={(option: RoofProfileDto) => `${option.name}${option.isCurrent ? ' (Current)' : ''}`}
                    error={fieldErrors?.ROOF_PLAN !== ''}
                    validate={handleValidation}
                />

                <FormHelperText>{fieldErrors?.ROOF_PLAN}</FormHelperText>
            </FormControl>
        );
    }, [fieldErrors?.ROOF_PLAN, disableRoofPlan, roofProfile, facility?.roofProfiles, facilitiesLoading, handleValidation, setRoofProfile]);


    return <Grid item container direction={'row'} xs={xsContainer} spacing={spacing}>
        <Grid item xs={xsItems}>
            {getClientSelect()}
        </Grid>
        <Grid item xs={xsItems}>
            {getFacilitySelect()}
        </Grid>
        {setRoofProfile && <Grid item xs={xsItems}>
            {getRoofPlanSelect()}
        </Grid>}
    </Grid>;
};