import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BudgetForm } from '../../Components/Forms/BudgetForm';
import { BudgetDto } from '../../models';
import { useCreateBudgetMutation } from '../../store/apis/budget-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddBudgetView: FC = () => {
    const navigate = useNavigate();
    const [createBudget, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateBudgetMutation();

    useFailedCreateSnackbar('budget', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Budget', isCreateSuccess, () => {
        navigate(`/budgets/edit/${data?.id}`);
    });

    const handleSave = (values: BudgetDto) => {
        createBudget(values);
    };

    const handleCancel = () => {
        navigate(`/budgets`);
    };

    return <BudgetForm save={handleSave} cancel={handleCancel} budgetNumberError={""} />;
};
