import { useSortable } from "@dnd-kit/sortable";
import { Delete, Edit } from "@mui/icons-material";
import { Grid, Box, IconButton, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { Attachment } from "../../Forms/types";
import React from "react";
import { ISortablePhotoProps } from "./types";
import { FormInput } from "../../Forms/FormFields";

export const SortablePhoto: FunctionComponent<ISortablePhotoProps> = (props) => {
    const {
        attachment,
        index,
        setAttachmentHoverIndex,
        attachmentHoverIndex,
        photoPreviewOnClick,
        photoEditOnClick,
        deleteSelectedPhoto,
        handleCaptionInput,
        disabled,
        caption
    } = props;

    const sortable = useSortable({ id: `${attachment.id}`, disabled: disabled });
    const {
        attributes,
        listeners,
        setNodeRef,
        transform
    } = sortable;

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    const handlePhotoPreview = (attachment: Attachment, index: number) => {
        if (photoPreviewOnClick) {
            photoPreviewOnClick(attachment, index);
        }
    };

    const handleDelete = (index: number) => {
        if (deleteSelectedPhoto) {
            deleteSelectedPhoto(index);
        }
    };

    const handleEdit = (index: number) => {
        if (photoEditOnClick) {
            photoEditOnClick(attachment, index);
        }
    };

    const handleCaptionInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        if (handleCaptionInput) {
            handleCaptionInput(event, index);
        }
    };

    return (
        <Grid
            item
            container
            direction='column'
            wrap='nowrap'
            spacing={2}
            sx={{ cursor: !disabled ? 'pointer' : 'default', height: '400px' }}
            style={style}
        >
            <Grid item xs={8} alignSelf='center'>
                <Box position='relative'
                    ref={setNodeRef}
                    {...attributes}
                    {...listeners}
                >
                    <img
                        src={attachment.previewLink}
                        alt={attachment.photo.displayName}
                        onMouseEnter={() => {
                            setAttachmentHoverIndex(index);
                        }}
                        onMouseLeave={() => {
                            setAttachmentHoverIndex(undefined);
                        }}
                        onClick={() => handlePhotoPreview(attachment, index)}
                        style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '240px' }}
                    />
                    {attachmentHoverIndex === index && (
                        <Grid item container direction='row'>
                            {photoEditOnClick && <Grid item>
                                <IconButton
                                    size='large'
                                    disabled={disabled}
                                    onMouseEnter={() => {
                                        setAttachmentHoverIndex(index);
                                    }}
                                    onClick={() => handleEdit(index)}
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 40,
                                        fill: 'white',
                                        padding: 3,
                                        zIndex: 0,
                                        backgroundColor: '#eeeeee',
                                    }}>
                                    <Edit fontSize='inherit' />
                                </IconButton>
                            </Grid>}
                            {deleteSelectedPhoto && <Grid item>
                                <IconButton
                                    size='large'
                                    disabled={disabled}
                                    onMouseEnter={() => {
                                        setAttachmentHoverIndex(index);
                                    }}
                                    onClick={() => handleDelete(index)}
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        fill: 'white',
                                        padding: 3,
                                        zIndex: 0,
                                        backgroundColor: '#eeeeee',
                                    }}>
                                    <Delete fontSize='inherit' />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    )}
                </Box>
            </Grid>
            <Grid item xs={4}>
                {handleCaptionInput ? (
                    <FormInput
                        label=''
                        rows={3}
                        name={attachment.photo.sourceName}
                        value={caption}
                        onChange={(event) => handleCaptionInputChange(event, index)}
                        fullWidth
                        maxLength={100}
                        disabled={disabled}
                        placeholder='Photo Caption'
                    />
                ) : (
                    <Typography textAlign={'center'}>{caption}</Typography>
                )}
            </Grid>
        </Grid>
    );
};