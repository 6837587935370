import { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientReportForm } from '../../Components/Forms/ClientReportForm/';
import { emptyGuid } from '../../models';
import { ReportDto } from '../../models/ReportDto';
import { useCreateReportMutation } from '../../store/apis/report-api';
import { useGetWorkOrderQuery } from '../../store/apis/work-order-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';
import LoadingIndicator from '../../Components/core/LoadingIndicator';

export const AddClientReportView: FunctionComponent = () => {
    const { workOrderId } = useParams();
    const navigate = useNavigate();
    const [createClientReport, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateReportMutation();
    const { data: workOrder, isLoading } = useGetWorkOrderQuery(workOrderId!);
    const [report] = useState<ReportDto | undefined>({
        id: emptyGuid,
        isActive: true,
        reportDate: null,
        roofSectionReports: [],
        isVisibleToClients: false,
        isVisibleToEmployees: true,
        visibility: 'computed', // the value is computed on the back-end so this initial value does not actually matter
    });

    useFailedCreateSnackbar('client report', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Client report', isCreateSuccess, () => {
        navigate(`/clientreports/edit/${data?.id}`);
    });

    const handleSave = (values: ReportDto) => {
        createClientReport(values);
    };

    const handleCancel = () => {
        if (workOrderId) {
            navigate(`/workorders/edit/${workOrderId}`);
        } else {
            navigate(`/clientreports`);
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <ClientReportForm
            save={handleSave}
            cancel={handleCancel}
            workOrderId={workOrderId}
            initValues={{
                ...report,
                id: emptyGuid,
                isActive: true,
                roofSectionReports: [],
                reportDate: null,
                workOrder: workOrder,
                workOrderId: workOrder?.id,
                presentedToClientName: workOrder?.facilityClientName,
                presentedToContactName: workOrder?.billedToClient?.contactName ?? workOrder?.billedToContractor?.name,
                presentedToFacilityName: workOrder?.facilityName,
                presentedToAddress1: workOrder?.facility?.addressLine1,
                presentedToAddress2: workOrder?.facility?.addressLine2,
                presentedToCity: workOrder?.facility?.city,
                presentedToState: workOrder?.facility?.state,
                presentedToZip: workOrder?.facility?.zipCode,
                isVisibleToClients: false,
                isVisibleToEmployees: true,
                visibility: 'computed', // the value is computed on the back-end so this initial value does not actually matter
            }}
        />
    );
};
