import { OpenInFull } from '@mui/icons-material';
import { Grid, FormControl, FormLabel, TextField, Autocomplete, CircularProgress, FormHelperText, IconButton, Chip } from '@mui/material';
import { FC, useState } from 'react';
import { IRoofSectionsSelector } from '.';
import { RoofSectionDto } from '../../../models';
import { SelectRoofSectionsDialog } from '../SelectRoofSection';
import { sortByNumericPrefix } from '../../../util/sortByNumericPrefix';

export const RoofSectionsSelector: FC<IRoofSectionsSelector> = (props) => {
    const {
        roofSections,
        roofSectionsData,
        fieldErrors,
        setFormChanged,
        setRoofSections,
        onFieldBlur,
        roofSectionsLoading,
        facilityId,
        setRoofNumber,
        required,
    } = props;
    const [isRoofSectionOpen, setIsRoofSectionOpen] = useState(false);
    const [isSelectRoofSectionsDialogOpen, setIsSelectRoofSectionsDialogOpen] = useState(false);

    const sortedRoofSections = sortByNumericPrefix(roofSections, 'roofNumber');

    const handleConfirmRoofSectionDialog = () => {
        setIsSelectRoofSectionsDialogOpen(false);
    };
    const handleCancelRoofSectionDialog = () => {
        setIsSelectRoofSectionsDialogOpen(false);
    };

    const handleChipDelete = (roofNumber: any) => {
        setFormChanged(true);
        setRoofSections(roofSections.filter((section) => section.roofNumber !== roofNumber));
        setRoofNumber('');
    };

    return (
        <>
            <Grid container item xs={4}>
                <FormControl fullWidth required={required} {...(required && { error: fieldErrors!.ROOF_SECTIONS !== '' })}>
                    <FormLabel>Roof Sections</FormLabel>
                    <Grid item container alignItems={'center'} direction={'row'}>
                        <Grid item xs={11}>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                onChange={(event, newValue: any) => {
                                    if (newValue) {
                                        setFormChanged(true);
                                        setRoofSections(newValue);
                                    }
                                }}
                                value={roofSections ?? null}
                                {...(required && { onBlur: onFieldBlur && onFieldBlur('ROOF_SECTIONS') })}
                                open={isRoofSectionOpen}
                                onOpen={() => setIsRoofSectionOpen(true)}
                                onClose={() => setIsRoofSectionOpen(false)}
                                options={
                                    roofSectionsData?.pageResults.filter((x) => x.isActive && !roofSections.some((y) => x.roofNumber === y.roofNumber)) ?? []
                                }
                                getOptionLabel={(option: RoofSectionDto) => `${option.roofNumber}${option.isActive ? '' : ' (Inactive)'}`}
                                selectOnFocus
                                disableClearable
                                handleHomeEndKeys
                                renderTags={() => null}
                                onKeyDown={(event: any) => {
                                    if (event.key === 'Enter') {
                                        event.defaultMuiPrevented = true;
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        {...(required && { error: fieldErrors!.ROOF_SECTIONS !== '' })}
                                        {...(required && { onBlur: onFieldBlur && onFieldBlur('ROOF_SECTIONS') })}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {roofSectionsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1} alignItems='center'>
                            <IconButton onClick={() => setIsSelectRoofSectionsDialogOpen(true)}>
                                <OpenInFull sx={{ fontSize: 36 }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {required && <FormHelperText>{fieldErrors!.ROOF_SECTIONS}</FormHelperText>}
                </FormControl>
                <SelectRoofSectionsDialog
                    open={isSelectRoofSectionsDialogOpen}
                    onCancel={handleCancelRoofSectionDialog}
                    onConfirm={handleConfirmRoofSectionDialog}
                    setRoofSections={setRoofSections}
                    currentRoofSections={roofSections}
                    isMultipleSelectable={true}
                    facilityId={facilityId}
                />
            </Grid>
            <Grid container item xs={8} alignItems='center'>
                <Grid item container direction='row'>
                    {sortedRoofSections.map((section) => {
                        return (
                            <Grid item xs={1}>
                                <Chip
                                    label={section.roofNumber}
                                    color='secondary'
                                    onDelete={() => {
                                        handleChipDelete(section.roofNumber);
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
};
