import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { FC } from 'react';

export interface IConfirmDialogProps {
    open: boolean;
    onClose: () => void;
    content: any;
    title: any;
    titleIcon: any;
    onConfirm: () => void;
    confirmText?: string;
    cancelText?: string;
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({ open, onClose, content, title, titleIcon, cancelText, confirmText, onConfirm }) => {
    return (
        <Dialog onClose={onClose} open={open} maxWidth='xs' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    {titleIcon}
                    {title}
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' marginTop={2}>
                    <Grid item>{content}</Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>
                    {cancelText ?? 'Cancel'}
                </Button>
                <Button variant='contained' onClick={onConfirm}>
                    {confirmText ?? 'Yes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
