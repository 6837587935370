import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Button, Divider, Grid, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavigationDrawerContainer } from '../../../containers/NavigationDrawerContainer';
import { useLazyGetUserByAuth0IdQuery } from '../../../store/apis/user-api';
import theme from '../../../Theme';
import { AuthRoles } from '../AuthRoles';
import GeneratedAvatar from '../GeneratedAvatar';
import LoadingIndicator from '../LoadingIndicator';
import img from './RoofAdvisor_logo_horizontal.png';
import { IShellProps } from './types';
import { NotAuthorizedError } from '../NotAuthorizedError/NotAuthorizedError';

const Shell: React.FunctionComponent<IShellProps> = (props) => {
    const navigate = useNavigate();
    const { isProtected } = props;
    const { user, isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [userRole, setUserRole] = useState('');
    const profileMenuOpen = Boolean(anchorEl);
    const [getUserData, { data: userData, isLoading: isLoadingUser, error: userError }] = useLazyGetUserByAuth0IdQuery();

    useEffect(() => {
        if (isAuthenticated) {
            const rolesFieldName = process.env.REACT_APP_AUTH0_Roles!;

            setUserRole(user![rolesFieldName][0]);
            if (user?.sub) {
                getUserData(user?.sub);
            }

            const path = localStorage.getItem('redirectUrl');
            if (!!path) {
                navigate(path);
                localStorage.removeItem('redirectUrl');
            }
        }
    }, [user, getUserData, isAuthenticated, navigate]);

    if (isLoading || isLoadingUser) {
        return <LoadingIndicator />;
    }

    if (isProtected) {
        if (!isAuthenticated) {
            localStorage.setItem('redirectUrl', window.location.pathname);
            loginWithRedirect();
            return <LoadingIndicator />;
        } else if (userError) {
            return <NotAuthorizedError
                children={
                    <Grid item>
                        <Button variant='contained' color='secondary' onClick={() => {
                            logout({ returnTo: `${window.location.origin}`, federated: true });
                        }}>Logout</Button>
                    </Grid>
                } />;
        }
    }

    const handleLogout = () => {
        setAnchorEl(null);
        logout({ returnTo: `${window.location.origin}`, federated: true });
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleImageClick = () => {
        if (userRole === AuthRoles.ClientManager && userData?.clientId) {
            navigate(`/clients/${userData.clientId}`);
            return;
        }
        navigate('/');
    };
    return (
        <>
            <NavigationDrawerContainer>
                <AppBar position='sticky' elevation={0} sx={{ height: '72px' }}>
                    <Toolbar variant='dense'>
                        <img
                            src={img}
                            alt='Moisture Management'
                            onClick={handleImageClick}
                            style={{ cursor: 'pointer', padding: theme.spacing(1), height: '100%' }}
                        />
                        {isAuthenticated && (
                            <Grid container direction='row' justifyContent='end'>
                                <div style={{ cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                                    <GeneratedAvatar name={user?.name} />
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={profileMenuOpen}
                                    onClose={handleProfileMenuClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    PaperProps={{
                                        sx: {
                                            mt: 1.5,
                                            border: 'solid 1px #AAAAAA',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            minWidth: '240px',
                                        },
                                    }}>
                                    <Grid container direction='column' alignItems='center'>
                                        <Grid item container direction='row' justifyContent='center'>
                                            <GeneratedAvatar name={user?.name} />
                                        </Grid>
                                        <Grid item container direction='row' justifyContent='center'>
                                            <Typography sx={{ fontSize: '18px', marginTop: '12px' }}>
                                                {userData ? userData?.displayName : user?.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction='row' justifyContent='center'>
                                            <Typography sx={{ fontSize: '14px', marginBottom: '12px', paddingLeft: '8px', paddingRight: '8px' }} noWrap={true}>
                                                {user?.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ marginBottom: '12px' }} />
                                    <MenuItem onClick={handleLogout} sx={{ fontSize: '14px' }}>
                                        Sign Out
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        )}
                    </Toolbar>
                </AppBar>
                <div style={{ padding: '24px', minHeight: 'calc(100vh - 72px)' }}>
                    <Outlet />
                </div>
            </NavigationDrawerContainer>
        </>
    );
};

export default Shell;
