import { SearchQueryParams, serverApi } from '..';
import { QueryResultsDto, ReportPdfDto } from '../../models';
import { BinderPortfolioReportDto } from '../../models/BinderPortfolioReportDto';
import { buildSearchQueryRoute } from '../../util';

const BinderPortfolioReportApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getBPReports: builder.query<QueryResultsDto<BinderPortfolioReportDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('binderPortfolioReport', params),
            providesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        getBPReport: builder.query<BinderPortfolioReportDto, string>({
            query: (reportId) => ({ url: `binderPortfolioReport/${reportId}` }),
            providesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        getBPReportPdf: builder.query<ReportPdfDto, string>({
            query: (reportId) => ({ url: `binderPortfolioReport/${reportId}/reportPdf` }),
            providesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        getCurrentBinderPortfolioReportPdfDownloadLinkForClient: builder.query<ReportPdfDto, string>({
            query: (clientId) => ({ url: `binderPortfolioReport/client/${clientId}/reportPdf` }),
            providesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        createBPReport: builder.mutation<BinderPortfolioReportDto, BinderPortfolioReportDto>({
            query: (reportDto) => ({
                url: 'binderPortfolioReport',
                method: 'POST',
                body: reportDto,
            }),
            invalidatesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        createBPReportCopy: builder.mutation<BinderPortfolioReportDto, string>({
            query: (reportId) => ({
                url: `binderPortfolioReport/copy/${reportId}`,
                method: 'POST',
            }),
            invalidatesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        createBPReportPdf: builder.mutation<ReportPdfDto, string>({
            query: (reportId) => ({
                url: `binderPortfolioReport/${reportId}/pdf`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        unlockBPReport: builder.mutation<ReportPdfDto, string>({
            query: (reportId) => ({
                url: `binderPortfolioReport/${reportId}/unlock`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        updateBPReport: builder.mutation<void, BinderPortfolioReportDto>({
            query: (reportDto) => ({
                url: 'binderPortfolioReport',
                method: 'PUT',
                body: reportDto,
            }),
            invalidatesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        archiveBPReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `binderPortfolioReport/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Binder Portfolio Report' }],
        }),
        unarchiveBPReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `binderPortfolioReport/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Binder Portfolio Report' }],
        }),
    }),
});

export const {
    useLazyGetBPReportQuery,
    useLazyGetBPReportPdfQuery,
    useGetBPReportQuery,
    useCreateBPReportPdfMutation,
    useCreateBPReportMutation,
    useUnlockBPReportMutation,
    useUpdateBPReportMutation,
    useArchiveBPReportMutation,
    useUnarchiveBPReportMutation,
    useGetBPReportsQuery,
    useCreateBPReportCopyMutation,
    useGetCurrentBinderPortfolioReportPdfDownloadLinkForClientQuery,
} = BinderPortfolioReportApi;
