import { serverApi } from '..';
import { QueryResultsDto, ReportPdfDto } from '../../models';
import { RoofSectionReportDto } from '../../models/RoofSectionReportDto';
import { buildSearchQueryRoute } from '../../util';
import { ChildQueryParams, RoofSectionReportSearchQueryParams } from '../types';

const RoofSectionReportApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoofSectionReports: builder.query<QueryResultsDto<RoofSectionReportDto>, RoofSectionReportSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`roofSectionReport`, params),
            providesTags: [{ type: 'Roof Section Report' }],
        }),
        getRoofSectionReport: builder.query<RoofSectionReportDto, ChildQueryParams>({
            query: (params) => ({ url: `roofSectionReport/${params.childId}` }),
            providesTags: [{ type: 'Roof Section Report' }],
        }),
        getRoofSectionReportPdf: builder.query<ReportPdfDto, string>({
            query: (reportId) => ({ url: `roofSectionReport/${reportId}/reportPdf` }),
            providesTags: [{ type: 'Report' }],
        }),
        createRoofSectionReport: builder.mutation<RoofSectionReportDto, RoofSectionReportDto>({
            query: (roofSectionReportDto) => ({
                url: `roofSectionReport`,
                method: 'POST',
                body: roofSectionReportDto,
            }),
            invalidatesTags: [{ type: 'Roof Section Report' }],
        }),
        updateRoofSectionReport: builder.mutation<void, RoofSectionReportDto>({
            query: (roofSectionReportDto) => ({
                url: `roofSectionReport`,
                method: 'PUT',
                body: roofSectionReportDto,
            }),
            invalidatesTags: [{ type: 'Roof Section Report' }],
        }),
        archiveRoofSectionReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `roofSectionReport/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Roof Section Report' }],
        }),
        unarchiveRoofSectionReport: builder.mutation<void, string>({
            query: (id) => ({
                url: `roofSectionReport/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Roof Section Report' }],
        }),
    }),
});

export const {
    useLazyGetRoofSectionReportQuery,
    useLazyGetRoofSectionReportPdfQuery,
    useGetRoofSectionReportQuery,
    useCreateRoofSectionReportMutation,
    useUpdateRoofSectionReportMutation,
    useArchiveRoofSectionReportMutation,
    useUnarchiveRoofSectionReportMutation,
    useGetRoofSectionReportsQuery,
} = RoofSectionReportApi;
