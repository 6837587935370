import { AddCircle, RemoveCircle, VerifiedUser } from '@mui/icons-material';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Link,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormButton, FormSection } from '..';
import AuthenticatedComponent from '../../../auth';
import { ClientDto, DocumentDto, FacilityDto, RoofSectionDto, WarrantorDto, WarrantyTypeDto, emptyGuid } from '../../../models';
import { useGetSasLinkQuery } from '../../../store/apis/cloud-storage-api';
import { useGetDocumentFileLinkQuery } from '../../../store/apis/document-api';
import { useGetRoofSectionsQuery } from '../../../store/apis/roof-section-api';
import { useGetWarrantorsQuery } from '../../../store/apis/warrantor-api';
import { useGetWarrantyTypesQuery } from '../../../store/apis/warranty-type-api';
import { uploadFileToCloud } from '../../../util';
import { ApiError } from '../../core/ApiError';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import LoadingIndicator from '../../core/LoadingIndicator';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { FormInput, RoofSectionsSelector } from '../FormFields';
import { ClientFacilityRoofPlanPicker } from '../FormFields/ClientFacilityRoofPlanPicker';
import { DateTimePicker } from '../FormFields/DateTimePicker';
import { AddWarrantorDialog } from './AddWarrantorDialog';
import { AddWarrantyTypeDialog } from './AddWarrantyTypeDialog';
import { IWarrantyForm } from './types';

export const WarrantyForm: FunctionComponent<IWarrantyForm> = (props) => {
    const { save, cancel, initValues, client } = props;
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [warrantyNo, setWarrantyNo] = useState(initValues ? initValues.warrantyNumber : '');
    const [clientSelected, setClientSelected] = useState<ClientDto | undefined>(initValues?.facility?.client ?? undefined);
    const [facility, setFacility] = useState<FacilityDto | null | undefined>(initValues ? initValues.facility : null);
    const [roofNumber, setRoofNumber] = useState('');
    const [roofSections, setRoofSections] = useState<RoofSectionDto[]>(
        initValues?.warrantyRoofSections ? initValues.warrantyRoofSections.map((x) => x.roofSection!) : []
    );
    const [warrantor, setWarrantor] = useState<WarrantorDto | null>(initValues?.warrantor ?? null);
    const [type, setType] = useState<WarrantyTypeDto | null>(initValues?.warrantyType ?? null);
    const [isWarrantorDialogOpen, setIsWarrantorDialogOpen] = useState(false);
    const [isTypeDialogOpen, setIsTypeDialogOpen] = useState(false);
    const [issuedDate, setIssuedDate] = useState<Date | undefined>(initValues ? initValues.issuedDate : undefined);
    const [expirationDate, setExpirationDate] = useState<Date | undefined>(initValues ? initValues.expirationDate : undefined);
    var termRange = ['', '5 years', '10 years', '12 years', '15 years', '20 years', '30 years'];
    const [term, setTerm] = useState(initValues?.warrantyTerm ? initValues.warrantyTerm : termRange[0]);
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [fileName, setFileName] = useState(initValues ? initValues.attachment?.sourceName : '');
    const [isNewFile, setIsNewFile] = useState(false);
    const { data: fileUploadLink } = useGetSasLinkQuery();
    const [downloadFile, setDownloadFile] = useState(false);
    const [documentToDownload, setDocumentToDownload] = useState<DocumentDto>();
    const [formChanged, setFormChanged] = useState(false);
    const { data: fileDownloadLink, isLoading: fileDownloadLinkLoading } = useGetDocumentFileLinkQuery(documentToDownload?.id!);
    const {
        data: warrantors,
        isLoading: warrantorsLoading,
        error: warrantorsError,
        refetch: refetchWarrantors,
    } = useGetWarrantorsQuery({ includeInactive: true });
    const {
        data: warrantyTypes,
        isLoading: warrantyTypesLoading,
        error: warrantyTypesError,
        refetch: refetchWarrantyTypes,
    } = useGetWarrantyTypesQuery({ includeInactive: true });
    const {
        data: roofSectionsData,
        isLoading: roofSectionsLoading,
        error: roofSectionsError,
        refetch: roofSectionsRefetch,
    } = useGetRoofSectionsQuery({
        searchText: roofNumber,
        sortKey: 'ROOF_NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 250,
        includeInactive: true,
        facilityId: facility?.id,
    });
    const [fieldErrors, setFieldErrors] = useState({
        NUMBER: '',
        FACILITY: '',
        ROOF_SECTIONS: '',
        FILE_NAME: '',
    });
    const refetch = () => {
        refetchWarrantors();
        refetchWarrantyTypes();
    };

    useEffect(() => {
        if (facility?.id) {
            roofSectionsRefetch();
        }
    }, [facility, roofSectionsRefetch]);

    useEffect(() => {
        if (fileDownloadLink && !fileDownloadLinkLoading && downloadFile) {
            const link = document.createElement('a');
            link.href = fileDownloadLink.link;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadFile(false);
        }
    }, [setDownloadFile, fileDownloadLink, fileDownloadLinkLoading, downloadFile]);

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'NUMBER') {
            if (warrantyNo) {
                fieldErrors.NUMBER = '';
                isValid = true;
            } else {
                fieldErrors.NUMBER = 'Warranty Number is required';
                isValid = false;
            }
        } else if (fieldName === 'FACILITY') {
            if (facility) {
                fieldErrors.FACILITY = '';
                isValid = true;
            } else {
                fieldErrors.FACILITY = 'Facility is required';
                isValid = false;
            }
        } else if (fieldName === 'ROOF_SECTIONS') {
            if (roofSections.length > 0) {
                fieldErrors.ROOF_SECTIONS = '';
                isValid = true;
            } else {
                fieldErrors.ROOF_SECTIONS = 'Roof sections are required';
                isValid = false;
            }
        } else if (fieldName === 'FILE_NAME') {
            if ((isNewFile && selectedFile) || (!isNewFile && initValues?.attachment)) {
                fieldErrors.FILE_NAME = '';
                isValid = true;
            } else {
                fieldErrors.FILE_NAME = 'Document is required';
                isValid = false;
            }
        }
        setFieldErrors({
            NUMBER: fieldErrors.NUMBER,
            FACILITY: fieldErrors.FACILITY,
            ROOF_SECTIONS: fieldErrors.ROOF_SECTIONS,
            FILE_NAME: fieldErrors.FILE_NAME,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('NUMBER');
        isValid = validate('FACILITY') && isValid;
        isValid = validate('FILE_NAME') && isValid;
        isValid = validate('ROOF_SECTIONS') && isValid;
        return isValid;
    };

    const handleSave = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            var attachment: DocumentDto;
            if (isNewFile) {
                const response = await uploadFileToCloud(fileUploadLink!.link, selectedFile!);
                attachment = {
                    id: emptyGuid,
                    clientId: facility?.clientId!,
                    facilityId: facility?.id!,
                    displayName: selectedFile?.name!,
                    sourceName: response.fileName!,
                    isVisibleToClients: true,
                    isVisibleToEmployees: true,
                    visibility: 'computed', // the value is computed on the back-end so this initial value does not actually matter
                    isActive: isActive,
                };
            } else {
                attachment = {
                    id: initValues?.attachment?.id!,
                    clientId: facility?.clientId!,
                    facilityId: facility?.id!,
                    displayName: initValues?.attachment?.displayName!,
                    sourceName: initValues?.attachment?.sourceName!,
                    isVisibleToClients: true,
                    isVisibleToEmployees: true,
                    visibility: 'computed', // the value is computed on the back-end so this initial value does not actually matter
                    isActive: isActive,
                };
            }
            save({
                ...initValues,
                id: initValues ? initValues.id : emptyGuid,
                isActive: true,
                warrantyNumber: warrantyNo,
                facilityId: facility?.id!,
                facility: facility!,
                warrantyRoofSections: roofSections.map((roofSection) => {
                    return {
                        roofSectionId: roofSection.id,
                        warrantyId: initValues ? initValues.id : emptyGuid,
                    };
                }),
                warrantyTypeId: type?.id,
                warrantyType: type ?? undefined,
                warrantorId: warrantor?.id,
                warrantor: warrantor ?? undefined,
                issuedDate: issuedDate,
                expirationDate: expirationDate,
                warrantyTerm: term,
                attachmentId: attachment.id,
                attachment: isNewFile ? attachment : initValues?.attachment,
            });
            setFormChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    const handleWarrantyNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setWarrantyNo(event.target.value);
    };
    
    const handleIssuedDateChange = (value: Date | null | undefined) => {
        setFormChanged(true);
        if (value) {
            setIssuedDate(value);
        }
    };

    const handleExpirationDateChange = (value: Date | null | undefined) => {
        setFormChanged(true);
        if (value) {
            setExpirationDate(value);
        }
    };
    const handleTermChange = (event: SelectChangeEvent) => {
        setFormChanged(true);
        setTerm(event.target.value);
    };
    const handleChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsNewFile(true);
        if (event.target.files instanceof FileList) {
            setFormChanged(true);
            setSelectedFile(event.target.files[0]);
        }
    };
    const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setFileName(event.target.value);
    };

    const handleAddWarrantorClose = (newWarrantor: WarrantorDto) => {
        if (newWarrantor) {
            setWarrantor(newWarrantor);
        }
        setIsWarrantorDialogOpen(false);
    };

    const handleAddWarrantyTypeClose = (newType: WarrantyTypeDto) => {
        if (newType) {
            setType(newType);
        }
        setIsTypeDialogOpen(false);
    };
    const handleRemoveFile = () => {
        setFormChanged(true);
        setSelectedFile(undefined);
        setFileName('');
        setIsNewFile(true);
    };
    const onFileLinkClick = (document: DocumentDto) => {
        setDownloadFile(true);
        setDocumentToDownload(document);
    };

    if (warrantorsLoading || warrantyTypesLoading) {
        return <LoadingIndicator />;
    }

    if (warrantorsError || warrantyTypesError || roofSectionsError) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <VerifiedUser /> {warrantyNo ? 'Warranty #' + warrantyNo : 'New Warranty'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Clients', navLink: '/clients' },
                            { label: client.name, navLink: `/clients/${client.id}` },
                        ]}
                        currentPageLabel={warrantyNo ? 'Warranty #' + warrantyNo : 'New Warranty'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        {formChanged || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='column'>
                <FormSection>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Warranty #'
                                onBlur={onFieldBlur('NUMBER')}
                                value={warrantyNo}
                                fullWidth
                                required
                                error={fieldErrors.NUMBER !== ''}
                                errorText={fieldErrors.NUMBER}
                                onChange={handleWarrantyNoChange}
                            />
                        </Grid>
                        <ClientFacilityRoofPlanPicker
                            fieldErrors={fieldErrors}
                            setFacility={setFacility}
                            setFormChanged={setFormChanged}
                            onFieldBlur={onFieldBlur}
                            facility={facility}
                            client={clientSelected}
                            setClient={setClientSelected}
                            xsContainer={4}
                            xsItems={6}
                        />
                        <Grid item xs={4}>
                            <FormLabel>Address</FormLabel>
                            <Typography>
                                {facility ? (
                                    <>
                                        {facility.addressLine1} {facility.addressLine2}
                                        <br />
                                        {facility.city}, {facility.state} {facility.zipCode}
                                    </>
                                ) : (
                                    'No facility selected'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <RoofSectionsSelector
                            setRoofNumber={setRoofNumber}
                            facilityId={facility?.id}
                            roofSectionsLoading={roofSectionsLoading}
                            onFieldBlur={onFieldBlur}
                            setRoofSections={setRoofSections}
                            setFormChanged={setFormChanged}
                            fieldErrors={fieldErrors}
                            roofSections={roofSections}
                            roofSectionsData={roofSectionsData}
                            required={true}
                        />
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item container direction='row' xs={4} alignItems='center'>
                            <Grid item xs={11}>
                                <FormControl fullWidth>
                                    <FormLabel> Warrantor</FormLabel>
                                    <IEntityAutocomplete
                                        options={warrantors}
                                        onChange={(_e, value) => { setFormChanged(true); setWarrantor(value); }}
                                        value={warrantor}
                                        getOptionLabel={(option: WarrantorDto) => option.name}
                                        isLoading={warrantorsLoading}
                                        validate={onFieldBlur('ISSUED_BY')}
                                    />
                                </FormControl>
                            </Grid>
                            <AuthenticatedComponent
                                requiredPermissions={['create:warrantors']}
                                component={
                                    <Grid item xs={1} alignItems='base-line' pt={2} pl={1}>
                                        <IconButton onClick={() => setIsWarrantorDialogOpen(true)}>
                                            <AddCircle color='primary' sx={{ verticalAlign: 'middle', fontSize: 35 }} />
                                        </IconButton>
                                    </Grid>
                                }
                            />
                        </Grid>
                        <Grid item container direction='row' xs={4} alignItems='center'>
                            <Grid item xs={11}>
                                <FormControl fullWidth>
                                    <FormLabel>Type</FormLabel>
                                    <IEntityAutocomplete
                                        options={warrantyTypes}
                                        onChange={(_e, value) => { setFormChanged(true); setType(value); }}
                                        value={type}
                                        getOptionLabel={(option: WarrantyTypeDto) => option.name}
                                        isLoading={warrantyTypesLoading}
                                    />
                                </FormControl>
                            </Grid>
                            <AuthenticatedComponent
                                requiredPermissions={['create:warrantyTypes']}
                                component={
                                    <Grid item xs={1} alignItems='base-line' pt={2} pl={1}>
                                        <IconButton onClick={() => setIsTypeDialogOpen(true)}>
                                            <AddCircle color='primary' sx={{ verticalAlign: 'middle', fontSize: 35 }} />
                                        </IconButton>
                                    </Grid>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container item direction='row' spacing={8}>
                        <Grid container item xs={4} justifyItems={'space-around'} spacing={4}>
                            <Grid item xs={5}>
                                <FormControl>
                                    <DateTimePicker label='Issued' value={issuedDate} onChange={handleIssuedDateChange} dateOnly={true} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl>
                                    <DateTimePicker label='Expiration' value={expirationDate} onChange={handleExpirationDateChange} dateOnly={true} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ minWidth: '50%' }}>
                                <FormLabel>Term</FormLabel>
                                <Select value={term} onChange={handleTermChange} onBlur={onFieldBlur('TERM')}>
                                    {termRange.map((term) => (
                                        <MenuItem key={term} value={term}>
                                            {term}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item container direction='row' xs={4} justifyItems={'flex-start'}>
                        {fileName ? (
                            <Grid item container xs={4} spacing={1}>
                                <Grid item xs={2}>
                                    <IconButton onClick={handleRemoveFile}>
                                        <RemoveCircle fontSize='large' sx={{ color: 'orange' }} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <Link sx={{ cursor: 'pointer' }} onClick={() => onFileLinkClick(initValues?.attachment!)}>
                                        {initValues?.attachment?.displayName}
                                    </Link>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container direction='row' spacing={2}>
                                <Grid item container direction='column' xs={2}>
                                    <Grid item>
                                        <FormLabel> Document</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <label htmlFor='choose-file'>
                                            <input name='choose-file' id='choose-file' type='file' style={{ display: 'none' }} onChange={handleChooseFile} />
                                            <Button variant='contained' component='span' color='secondary'>
                                                Choose File
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                                <Grid item xs={10}>
                                    <FormControl error={fieldErrors.FILE_NAME !== ''} fullWidth required>
                                        <FormLabel> File Name</FormLabel>
                                        <OutlinedInput value={selectedFile?.name} disabled onChange={handleFileNameChange} />
                                        <FormHelperText>{fieldErrors.FILE_NAME}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <AddWarrantorDialog open={isWarrantorDialogOpen} onClose={handleAddWarrantorClose} />
                    <AddWarrantyTypeDialog open={isTypeDialogOpen} onClose={handleAddWarrantyTypeClose} setFormChanged={setFormChanged} />
                </FormSection>
            </Grid>
        </Grid>
    );
};
