import { AccountBox } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { IUserFormConfirmDialogProps } from './types';
import { AuthRoles } from '../../core/AuthRoles';

export const UserFormConfirmDialog: FunctionComponent<IUserFormConfirmDialogProps> = (props) => {
    const { open, close, userRole, save } = props;

    const [confirm, setConfirm] = useState(false);

    const confirmationText = useMemo(() => {
        switch (userRole) {
            case AuthRoles.ClientManager:
                return "CLIENT";
            default:
                return userRole.toUpperCase();
        }
    }, [userRole]);

    const validateConfirm = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        var text = event.currentTarget.value.toLocaleUpperCase() ?? '';

        if (text === confirmationText) {
            setConfirm(true);
        }
        else {
            setConfirm(false);
        }

    }, [confirmationText]);

    const accessListItems = useMemo(() => {
        switch (userRole) {
            case AuthRoles.ClientManager:
                return ['Client Dashboard'];
            case AuthRoles.Contractor:
                return ['Contractor Dashboard', 'Work Orders (Limited)'];
            case AuthRoles.Admin:
                return ['All Clients', 'All Budgets', 'All Work Orders', 'All Capital Projects', 'All Client Reports', 'All Admin'];
            case AuthRoles.Employee:
                return ['Clients (Limited)', 'Work Orders (Limited)', 'Client Reports (Limited)'];
            case AuthRoles.EmployeeContractor:
                return ['Clients (Limited)', 'Work Orders (Limited) + Contractor Dashboard', 'Client Reports (Limited)'];
            default:
                return [];
        }
    }, [userRole]);

    return (
        <Dialog open={open} onClose={close} maxWidth='md' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', marginBottom: '16px', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <AccountBox />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Confirm User Role
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ display: 'inline' }}>User Role is being set to </DialogContentText>
                <DialogContentText style={{ display: 'inline', fontWeight: 'bold' }}>{userRole}</DialogContentText>
                <DialogContentText style={{ display: 'inline' }}> and will have access to the following:</DialogContentText>
                <List >
                    {accessListItems.map((accessItem, idx) => <ListItem key={idx} ><ListItemIcon><CheckIcon color='secondary' /></ListItemIcon><ListItemText>{accessItem}</ListItemText></ListItem>)}
                </List>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between' paddingBottom={2}>
                    <Typography >Type "{confirmationText}" in the box below to confirm your selection.</Typography>
                    <Grid item xs={8}>
                        <OutlinedInput onKeyUp={validateConfirm} />
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto' alignContent={'center'} >
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={close}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={save} disabled={!confirm}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
