import { Map } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { IMapError } from '.';
export const NoMapFoundError: FunctionComponent<IMapError> = (props) => {
    const { errorText } = props;
    return (
        <Box width='100%' height='100%' sx={{ position: 'relative' }}>
            <Map sx={{ height: '100%', width: '100%', opacity: '10%' }} />
            <Typography
                textAlign='center'
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: `translate(${'-50%'}, ${'-50%'})`,
                }}>
                {errorText}
            </Typography>
        </Box>
    );
};
export default NoMapFoundError;
