import { serverApi } from '..';
import { SurfacingDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const surfacingTypeApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getSurfacingTypes: builder.query<SurfacingDto[], SurfacingTypeQueryParams>({
            query: (params) => buildSearchQueryRoute(`surfacingType`, params),
            providesTags: [{ type: 'Surfacing Type' }],
        }),
        createSurfacingType: builder.mutation<SurfacingDto, SurfacingDto>({
            query: (dto) => ({
                url: `surfacingType`,
                method: 'POST',
                body: dto,
            }),
            invalidatesTags: [{ type: 'Surfacing Type' }],
        }),
    }),
});

export const { useGetSurfacingTypesQuery, useCreateSurfacingTypeMutation } = surfacingTypeApi;

export interface SurfacingTypeQueryParams {
    includeInactive?: boolean;
}
