import { serverApi } from '..';
import { RoofAccessDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const roofAccessApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoofAccesses: builder.query<RoofAccessDto[], RoofAccessQueryParams>({
            query: (params) => buildSearchQueryRoute(`roofaccess`, params),
            providesTags: [{ type: 'Roof Access' }]
        })
    })
});

export const {
    useGetRoofAccessesQuery
} = roofAccessApi;

export interface RoofAccessQueryParams {
    includeInactive?: boolean;
}