import { QueryResultsDto } from '../../models';
import { ContactDto } from '../../models/ContactDto';
import { buildSearchQueryRoute } from '../../util';
import { serverApi } from '../api';
import { ChildQueryParams, ChildSearchQueryParams } from '../types';

const contactApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getContacts: builder.query<QueryResultsDto<ContactDto>, ChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`contractor/${params.parentId}/contact`, params.params),
            providesTags: [{ type: 'Contact' }]
        }),
        getContact: builder.query<ContactDto, ChildQueryParams>({
            query: (params) => ({url: `/contractor/${params.parentId}/contact/${params.childId}`}),
            providesTags: [{type: 'Contact'}]
        }),
        createContact: builder.mutation<ContactDto, ContactDto>({
            query: (contactDto) => ({
                url: `contractor/${contactDto.contractorId}/contact`,
                method: 'POST',
                body: contactDto
            }),
            invalidatesTags: [{ type: 'Contact' }]
        }),
        updateContact: builder.mutation<void, ContactDto>({
            query: (contactDto) => ({
                url: `contractor/${contactDto.contractorId}/contact`,
                method: 'PUT',
                body: contactDto
            }),
            invalidatesTags: [{ type: 'Contact' }]
        }),
        archiveContact: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `contractor/${params.parentId}/contact/${params.childId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Contact' }]
        }),
        unarchiveContact: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `contractor/${params.parentId}/contact/${params.childId}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Contact' }]
        })
    }),
});

export const {useLazyGetContactsQuery, useGetContactsQuery, useCreateContactMutation, useArchiveContactMutation, useUnarchiveContactMutation, useUpdateContactMutation, useGetContactQuery } = contactApi;
