import { AddCircle, Delete, MoreVert, People, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../auth';
import { ApiError } from '../Components/core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../Components/core/DataTable';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import { NavBreadcrumbs } from '../Components/core/NavBreadcrumbs';
import { ClientDto } from '../models';
import { useArchiveClientMutation, useGetClientsQuery, useUnarchiveClientMutation } from '../store/apis/client-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../util/customHooks';

export const ClientsListView: FunctionComponent = () => {
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data, isLoading, error, refetch } = useGetClientsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
    });
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<ClientDto>[] = [
        { key: 'name', label: 'Client', sortKey: 'NAME' },
        { key: 'city', label: 'City', sortKey: 'CITY' },
        { key: 'state', label: 'State', sortKey: 'STATE' },
        { key: 'contactName', label: 'Contact Name', sortKey: 'CONTACT_NAME' },
        { key: 'contactPhone', label: 'Contact Phone', sortKey: 'CONTACT_PHONE' },
    ];
    const [archiveClient, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveClientMutation();
    const [unarchiveClient, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveClientMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

    const getEntityName = () => {
        return selected.length === 1 ? 'Client' : 'Clients';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => {handleActionRequestCompletion(resetArchive);});
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => {handleActionRequestCompletion(resetUnarchive);});
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => {handleActionRequestCompletion(resetArchive);});
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => {handleActionRequestCompletion(resetUnarchive);});

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    };

    const handleCreateNew = () => {
        navigate('/clients/create');
    };

    const handleMenuEdit = () => {
        const selectedClientId = data?.pageResults[selected[0]].id;
        if (selectedClientId) {
            navigate(`/clients/edit/${selectedClientId}`);
        }
    };

    const handleTableEdit = (id: string) => {
        navigate(`/clients/edit/${id}`);
    };

    const handleSetActive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveClient(data?.pageResults[index].id!);
        });
    };

    const handleSetInactive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveClient(data?.pageResults[index].id!);
        });
    };

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index].isActive);
    };

    const handleTableRowClick = (id: string) => {
        navigate(`/clients/${id}`);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start' justifyContent={'space-between'}>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <People /> Clients
                    </Typography>
                    <NavBreadcrumbs links={[{ label: 'Home', navLink: '/' }]} currentPageLabel='Clients' />
                </Grid>
                <Grid item container direction='row' alignItems='center' xs={6} justifyContent={'end'}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:clients']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive()}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:clients']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:clients', 'edit:clients', 'delete:clients']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:clients']}
                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:clients']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:clients']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        }
                    />
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='NAME'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:clients'] }}
                    onEdit={handleTableEdit}
                    onRowClick={handleTableRowClick}
                />
            </Grid>
        </Grid>
    );
};
