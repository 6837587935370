import { AddCircle, Delete, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../../../auth';
import { RoofProfileDto } from '../../../models';
import { useArchiveRoofProfileMutation, useGetRoofProfilesQuery, useUnarchiveRoofProfileMutation } from '../../../store/apis/roof-profile-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { IRoofProfilesProps } from './types';

export const RoofProfiles: FunctionComponent<IRoofProfilesProps> = (props) => {
    const { facilityId, clientId } = props;
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data, error, isLoading, refetch } = useGetRoofProfilesQuery({
        facilityId: facilityId,
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
    });
    const [archiveRoofProfile, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveRoofProfileMutation();
    const [unarchiveRoofProfile, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveRoofProfileMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<RoofProfileDto>[] = [
        { key: 'name', label: 'Profile Name', sortKey: 'NAME' },
        { key: 'mapLoaded', label: 'Map Loaded', sortKey: 'MAP_LOADED', unsorted: true, fieldMapper: (row) => (row.mapLoaded ? 'Yes' : 'No') },
        { key: 'isCurrent', label: 'Current', sortKey: 'IS_CURRENT', fieldMapper: (row) => (row.isCurrent ? 'Yes' : 'No') },
        {
            key: 'createdOn',
            label: 'Created On',
            sortKey: 'CREATED_ON',
            fieldMapper: (row) => (row.createdOn ? format(new Date(row.createdOn.toString()), 'M/d/yyyy') : ''),
        },
    ];

    const getEntityName = () => {
        return selected.length === 1 ? 'Roof Plan' : 'Roof Plans';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => {handleActionRequestCompletion(resetArchive);});
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => {handleActionRequestCompletion(resetUnarchive);});
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => {handleActionRequestCompletion(resetArchive);});
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => {handleActionRequestCompletion(resetUnarchive);});

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    };

    const handleCreateNew = () => {
        navigate(`/clients/${clientId}/facilities/${facilityId}/roofprofiles/create`);
    };

    const handleMenuEdit = () => {
        const selectedRoofProfileId = data?.pageResults[selected[0]].id;
        if (selectedRoofProfileId) {
            navigate(`/clients/${clientId}/facilities/${facilityId}/roofprofiles/edit/${selectedRoofProfileId}`);
        }
    };

    const handleRowEdit = (id: string) => {
        navigate(`/clients/${clientId}/facilities/${facilityId}/roofprofiles/edit/${id}`);
    };

    const handleSetActive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveRoofProfile(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    const handleSetInactive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveRoofProfile(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index]?.isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index]?.isActive);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }
    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                <Grid item container direction='row' alignItems='center' xs={6}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:roofProfiles']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive()}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:roofProfiles']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:roofProfiles', 'edit:roofProfiles', 'delete:roofProfiles']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:roofProfiles']}
                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:roofProfiles']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:roofProfiles']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        } />
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='CREATED_ON'
                    defaultSortDesc
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:roofProfiles'] }}
                    onEdit={handleRowEdit}
                />
            </Grid>
        </Grid>
    );
};
