import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { WarrantyForm } from '../../Components/Forms/WarrantyForm';
import { WarrantyDto } from '../../models';
import { useGetClientQuery } from '../../store/apis/client-api';
import { useGetWarrantyQuery, useUpdateWarrantyMutation } from '../../store/apis/warranty-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditWarrantyView: FunctionComponent = (props) => {
    const { clientId, id } = useParams();
    const navigate = useNavigate();
    const { data: client, error: clientError, isLoading: clientLoading, refetch: clientRefetch } = useGetClientQuery(clientId!);
    const { data: warranty, error: warrantyError, isLoading: warrantyLoading, refetch: warrantyRefresh } = useGetWarrantyQuery(id!);
    const [updateWarranty, { isSuccess, isError, reset }] = useUpdateWarrantyMutation();

    useFailedUpdateSnackbar('Warranty', isError, reset);
    useSuccessfulUpdateSnackbar('Warranty', isSuccess, () => navigate(`/clients/${clientId!}`));

    const handleSave = (values: WarrantyDto) => {
        updateWarranty(values);
    };

    const handleCancel = () => {
        navigate(`/clients/${clientId!}`);
    };

    if (clientLoading || warrantyLoading) {
        return <LoadingIndicator />;
    }

    if (clientError) {
        return <ApiError onReloadClick={clientRefetch} />;
    }

    if (warrantyError) {
        return <ApiError onReloadClick={warrantyRefresh} />;
    }

    return <WarrantyForm client={client!} save={handleSave} cancel={handleCancel} initValues={warranty} />;
};
