import { JsiReportRolloverItemDto, QueryResultsDto } from "../../models";
import { buildSearchQueryRoute } from "../../util";
import { serverApi } from "../api";
import { ChildQueryParams, ChildSearchQueryParams } from "../types";

const jsiReportRolloverItemApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getJsiReportRolloverItems: builder.query<QueryResultsDto<JsiReportRolloverItemDto>, ChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`jsiReport/${params.parentId}/jsiReportRolloverItem`, params.params),
            providesTags: [{ type: 'JSI Report Rollover Items' }]
        }),
        createJsiReportRolloverItem: builder.mutation<JsiReportRolloverItemDto, JsiReportRolloverItemDto>({
            query: (jsiReportRolloverItem) => ({
                url: `jsiReport/${jsiReportRolloverItem.jsiReportId}/jsiReportRolloverItem`,
                method: 'POST',
                body: jsiReportRolloverItem
            }),
            invalidatesTags: [{ type: 'JSI Report Rollover Items' }]
        }),
        archiveJsiReportRolloverItem: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `jsiReport/${params.parentId}/jsiReportRolloverItem/${params.childId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'JSI Report Rollover Items' }]
        }),
        unarchiveJsiReportRolloverItem: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `jsiReport/${params.parentId}/jsiReportRolloverItem/${params.childId}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'JSI Report Rollover Items' }]
        }),
        updateJsiReportRolloverItem: builder.mutation<void, JsiReportRolloverItemDto>({
            query: (jsiReportRolloverItem) => ({
                url: `jsiReport/${jsiReportRolloverItem.jsiReportId}/jsiReportRolloverItem`,
                method: 'PUT',
                body: jsiReportRolloverItem
            }),
            invalidatesTags: [{ type: 'JSI Report Rollover Items' }]
        })
    })
});
export const {
    useGetJsiReportRolloverItemsQuery,
    useCreateJsiReportRolloverItemMutation,
    useArchiveJsiReportRolloverItemMutation,
    useUnarchiveJsiReportRolloverItemMutation,
    useUpdateJsiReportRolloverItemMutation
} = jsiReportRolloverItemApi;