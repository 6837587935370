import { AddCircle, Delete, FilterList, MoreVert, Search, VerifiedUser } from '@mui/icons-material';
import { Chip, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../../../auth';
import { DocumentDto, FacilityDto, RoofSectionDto, WarrantyDto, WarrantyTypeDto } from '../../../models';
import { useLazyGetDocumentFileLinkQuery } from '../../../store/apis/document-api';
import { useArchiveWarrantyMutation, useGetClientWarrantiesQuery, useUnarchiveWarrantyMutation } from '../../../store/apis/warranty-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { DashboardCard } from '../DashboardCard';
import { FilterWarrantiesDialog } from './FilterWarrantiesDialog';
import { IClientCardProps } from './types';
import { usePermissionChecker } from '../../../Hooks';

export const WarrantiesCard: FunctionComponent<IClientCardProps> = (props) => {
    const { clientId, facility } = props;
    const navigate = useNavigate();
    const { userHasAnyPermission } = usePermissionChecker();
    const location = useLocation();
    const [searchText, setSearchText] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [showInactive, setShowInactive] = useState(false);
    const [typeFilter, setTypeFilter] = useState<WarrantyTypeDto>();
    const [yearFilter, setYearFilter] = useState<number | undefined>();
    const [facilityFilter, setFacilityFilter] = useState<FacilityDto | undefined | null>();
    const [filterOpen, setFilterOpen] = useState(false);
    const [downloadFile, setDownloadFile] = useState(false);
    const [
        getDocumentFileLink,
        { currentData: fileDownloadLink, isLoading: fileDownloadLinkLoading, isError: isDownloadDocumentError, error: downloadDocumentError },
    ] = useLazyGetDocumentFileLinkQuery();
    useFailedActionSnackbar('download', 'warranty', isDownloadDocumentError, () => {}, downloadDocumentError);

    const { data, isLoading, error, refetch } = useGetClientWarrantiesQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        clientId: clientId,
        facilityId: facility?.id ?? facilityFilter?.id,
        issuedYear: yearFilter,
        type: typeFilter?.id,
    });
    const [archiveWarranty, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveWarrantyMutation();
    const [unarchiveWarranty, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveWarrantyMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);

    const entityName = useMemo(() => {
        return selected.length === 1 ? 'Warranty' : 'Warranties';
    }, [selected.length]);

    const handleActionRequestCompletion = useCallback((resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    }, []);

    useFailedActionSnackbar('inactivate', entityName, isArchiveError, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useFailedActionSnackbar('activate', entityName, isUnarchiveError, () => {
        handleActionRequestCompletion(resetUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', entityName, isArchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useSuccessfulActionSnackbar('Activated', entityName, isUnarchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetUnarchive);
    });

    useEffect(() => {
        if (fileDownloadLink && !fileDownloadLinkLoading && downloadFile) {
            const link = document.createElement('a');
            link.href = fileDownloadLink.link;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadFile(false);
            setSelected([]);
        }
    }, [setDownloadFile, fileDownloadLink, fileDownloadLinkLoading, downloadFile]);

    const formatRoofSections = useCallback((roofSections: RoofSectionDto[]) => {
        let sectionsText = '';
        const sortedSections = [...roofSections].sort((a, b) => Number(a.roofNumber) - Number(b.roofNumber));
        sortedSections.forEach((roofSection, index) => {
            sectionsText = sectionsText.concat(`${roofSection.roofNumber}`);
            if (index < roofSections.length - 1) {
                sectionsText = sectionsText.concat(', ');
            }
        });
        return sectionsText;
    }, []);

    const onFileLinkClick = useCallback(
        async (document: DocumentDto | undefined) => {
            if (document) {
                setDownloadFile(true);
                await getDocumentFileLink(document.id, false).unwrap();
            }
        },
        [getDocumentFileLink]
    );

    const tableColumns: DataTableColumn<WarrantyDto>[] = [
        { key: 'warrantyNumber', label: 'Number', sortKey: 'WARRANTY_NUMBER' },
        { key: 'facility', label: 'Facility', sortKey: 'FACILITY', fieldMapper: (row) => row.facility.name },
        {
            key: 'roofSections',
            label: 'Roof Section(s)',
            sortKey: 'ROOF_SECTIONS',
            unsorted: true,
            fieldMapper: (row) => (row.warrantyRoofSections ? formatRoofSections(row.warrantyRoofSections.map((x) => x.roofSection!)) : ''),
        },
        { key: 'warrantor', label: 'Issued By', sortKey: 'WARRANTOR', fieldMapper: (row) => row.warrantor?.name },
        { key: 'warrantyType', label: 'Type', sortKey: 'TYPE', fieldMapper: (row) => row.warrantyType?.name },
        {
            key: 'issued',
            label: 'Issued',
            sortKey: 'ISSUED',
            fieldMapper: (row) => (row.issuedDate ? format(new Date(row.issuedDate?.toString()), 'M/d/yyyy') : ''),
        },
        {
            key: 'expiration',
            label: 'Expiration',
            sortKey: 'EXPIRATION',
            fieldMapper: (row) => (row.expirationDate ? format(new Date(row.expirationDate?.toString()), 'M/d/yyyy') : ''),
        },
        {
            key: 'attachment',
            label: 'Document',
            sortKey: 'ATTACHMENT',
            unsorted: true,
            fieldMapper: (row) => {
                return row.attachment ? (
                    <Link sx={{ cursor: 'pointer' }} onClick={() => onFileLinkClick(row.attachment)}>
                        View
                    </Link>
                ) : (
                    <></>
                );
            },
        },
    ];

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) {
                afterClose();
            }
        },
        []
    );

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleCreateNew = useCallback(() => {
        navigate(`warranties/create`);
    }, [navigate]);

    const handleMenuEdit = useCallback(() => {
        const selectedWarrantyId = data?.pageResults[selected[0]].id;
        if (selectedWarrantyId) {
            navigate(`/warranties/edit/${selectedWarrantyId}`);
        }
    }, [data?.pageResults, navigate, selected]);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleFilter = useCallback(() => {
        setFilterOpen(true);
    }, []);

    const handleYearFilterDelete = useCallback(() => {
        setYearFilter(undefined);
    }, []);

    const handleTypeFilterDelete = useCallback(() => {
        setTypeFilter(undefined);
    }, []);

    const handleFacilityFilterDelete = useCallback(() => {
        setFacilityFilter(undefined);
    }, []);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveWarranty(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [data?.pageResults, selected, unarchiveWarranty]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveWarranty(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [archiveWarranty, data?.pageResults, selected]);

    const canSetActive = useMemo(() => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useMemo(() => {
        return selected.some((index) => data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const handleRowEdit = useCallback(
        (id: string) => {
            navigate(`warranties/edit/${id}`);
        },
        [navigate]
    );

    const hashElement = useMemo(() => {
        const hash = location.hash;
        if (data && hash) {
            const id = hash.slice(1);
            return document.getElementById(id);
        }
        return null;
    }, [data, location.hash]);

    useEffect(() => {
        if (hashElement) {
            hashElement.scrollIntoView({
                behavior: 'smooth',
                inline: 'nearest',
            });
        }
    }, [hashElement]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <DashboardCard headerIcon={<VerifiedUser />} headerTitle='Warranties'>
            <Grid id='warranties' container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
                <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                    <Grid item container direction='row' alignItems='center' xs={6} spacing={1} sx={{ height: '100%', paddingLeft: '12px', marginTop: '0px' }}>
                        {yearFilter && (
                            <Grid item>
                                <Chip label={`Issued Year: ${yearFilter}`} color='secondary' onDelete={handleYearFilterDelete} />
                            </Grid>
                        )}
                        {typeFilter && (
                            <Grid item>
                                <Chip label={`Type: ${typeFilter.name}`} color='secondary' onDelete={handleTypeFilterDelete} />
                            </Grid>
                        )}
                        {facilityFilter && (
                            <Grid item>
                                <Chip label={`Facility: ${facilityFilter.name}`} color='secondary' onDelete={handleFacilityFilterDelete} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container direction='row' alignItems='center' xs={6} spacing={1} justifyContent={'flex-end'}>
                        <AuthenticatedComponent
                            requiredPermissions={['delete:warranties']}
                            component={
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                        label='Show Inactive'
                                        labelPlacement='start'
                                    />
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['delete:warranties']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title='Make Inactive'>
                                        <span>
                                            <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive}>
                                                <Delete fontSize='inherit' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                        <Grid item xs={1}>
                            <Tooltip title={'Filter'}>
                                <IconButton color='primary' size='large' onClick={handleFilter}>
                                    <FilterList fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <AuthenticatedComponent
                            requiredPermissions={['create:warranties']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title='Add New'>
                                        <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                            <AddCircle fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                        <Grid item xs={1}>
                            <IconButton size='large' onClick={handleMenuOpen}>
                                <MoreVert fontSize='inherit' />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose()}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <AuthenticatedComponent
                                    requiredPermissions={['create:warranties', 'edit:warranties']}
                                    logic='or'
                                    component={
                                        <>
                                            <AuthenticatedComponent
                                                requiredPermissions={['create:warranties']}
                                                component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                            />
                                            <AuthenticatedComponent
                                                requiredPermissions={['edit:warranties']}
                                                component={
                                                    <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                        Edit
                                                    </MenuItem>
                                                }
                                            />
                                            <Divider />
                                        </>
                                    }
                                />
                                <MenuItem onClick={handleMenuClose(handleFilter)}>Filter</MenuItem>
                                <AuthenticatedComponent
                                    requiredPermissions={['delete:warranties']}
                                    component={
                                        <>
                                            <Divider />
                                            <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive}>
                                                Make Active
                                            </MenuItem>
                                            <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive}>
                                                Make Inactive
                                            </MenuItem>
                                        </>
                                    }
                                />
                            </Menu>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                id='search-box'
                                value={searchText}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Search'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <PaginatedDataTable
                        columns={tableColumns}
                        loading={isLoading}
                        queryResults={data}
                        defaultSortKey='WARRANTY_NUMBER'
                        setPagination={setPaginatedProps}
                        selected={selected}
                        setSelected={setSelected}
                        editPermissions={{ requiredPermissions: ['edit:warranties'] }}
                        onEdit={handleRowEdit}
                        disableSelection={!userHasAnyPermission(['create:warranties', 'edit:warranties', 'delete:warranties'])}
                    />
                </Grid>
            </Grid>
            {filterOpen && (
                <FilterWarrantiesDialog
                    open={filterOpen}
                    close={() => setFilterOpen(false)}
                    typeFilter={typeFilter}
                    setTypeFilter={setTypeFilter}
                    yearFilter={yearFilter}
                    setYearFilter={setYearFilter}
                    facilityFilter={facilityFilter}
                    setFacilityFilter={setFacilityFilter}
                    clientId={clientId}
                />
            )}
        </DashboardCard>
    );
};
