import { FormLabel, OutlinedInput, FormHelperText, FormControl, debounce, InputAdornment } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FunctionComponent } from "react";
import { IFormNumberInputProps } from "./types";

export const FormNumberInput: FunctionComponent<IFormNumberInputProps> = React.memo(props => {
    const {
        disabled,
        error,
        errorText,
        fullWidth,
        label,
        labelFontSize,
        name,
        onBlur,
        onChange,
        required,
        value,
        inputProps,
        sx,
        isCurrency = false
    } = props;

    const [fieldValue, setFieldValue] = useState(value);

    useEffect(() => {
        setFieldValue(value);
    }, [value]);

    const debouncedHandleChange = useMemo(
        () => debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event);
        }, 300)
        , [onChange]);

    return (
        <FormControl error={error} fullWidth={fullWidth} required={required} disabled={disabled}>
            <FormLabel sx={{ fontSize: labelFontSize }}>{label}</FormLabel>
            <OutlinedInput
                disabled={disabled}
                name={name}
                onBlur={onBlur}
                onChange={
                    (event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value;
                        setFieldValue(value);
                        debouncedHandleChange(event);
                    }
                }
                type={'number'}
                required={required}
                value={fieldValue ?? null}
                inputProps={inputProps ? { ...inputProps, step: isCurrency ? '.01' : undefined } : undefined}
                sx={sx}
                startAdornment={isCurrency ? <InputAdornment position="start">$</InputAdornment> : undefined}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
});