import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { FunctionComponent } from 'react';
import { IViewPhotoDialogProps } from './types';

export const ViewPhotoDialog: FunctionComponent<IViewPhotoDialogProps> = React.memo(props => {
    const { open, onClose, attachment, caption } = props;

    return (
        <Dialog onClose={onClose} open={open} maxWidth={false}>
            <DialogContent>
                <Grid container direction='column' style={{ maxWidth: '800px', maxHeight: '800px' }}>
                    <Grid item xs={6}>
                        {
                            <img
                                src={attachment?.previewLink}
                                alt={attachment?.photo.displayName ?? 'Attachment'}
                                style={{ maxWidth: '600px', maxHeight: '600px', objectFit: 'contain' }}
                            />
                        }
                    </Grid>
                    <Grid item xs={6} style={{ maxHeight: '600px', maxWidth: '600px', objectFit: 'contain', marginTop: '24px' }}>
                        <Typography noWrap={false}>{caption}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
});
