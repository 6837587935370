import { useAuth0 } from '@auth0/auth0-react';
import { FunctionComponent, useEffect, useState } from 'react';
import { AuthRoles } from '../Components/core/AuthRoles';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import { useLazyGetUserByAuth0IdQuery } from '../store/apis/user-api';
import { HomeDashboard } from '../Components/Dashboard/HomeDashboard';
import { ClientDashboard } from './Client';
import { ContractorHomeDashboard } from './Contractor/ContractorHomeDashboard';
import { Grid, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

export const DashboardView: FunctionComponent = () => {
    const { user, isLoading } = useAuth0();
    const [getUserData, { data: userData, isLoading: userLoading }] = useLazyGetUserByAuth0IdQuery();
    const [selectedTabIndex, setSelectedTabIndex] = useState('1');

    const isRole = (AuthRole: AuthRoles) => {
        var rolesFieldName = process.env.REACT_APP_AUTH0_Roles;
        return user && rolesFieldName && user[rolesFieldName] && user[rolesFieldName].length > 0 && user[rolesFieldName].includes(AuthRole);
    };

    const isAlternateDashboard = isRole(AuthRoles.ClientManager) || isRole(AuthRoles.Contractor) || isRole(AuthRoles.EmployeeContractor);

    useEffect(() => {
        if (isAlternateDashboard) {
            if (user?.sub != null) {
                if (!userData) {
                    getUserData(user.sub);
                }
            }
        }
    }, [user, userData, getUserData, isAlternateDashboard]);

    if (isLoading || userLoading) {
        return <LoadingIndicator />;
    } else if (isRole(AuthRoles.ClientManager)) {
        if (userData) {
            return <ClientDashboard clientId={userData.clientId} />;
        } else {
            return <LoadingIndicator />;
        }
    } else if (isRole(AuthRoles.Contractor)) {
        if (userData) {
            return <ContractorHomeDashboard contractorId={userData.contractorId} />;
        } else {
            return <LoadingIndicator />;
        }
    } else if (isRole(AuthRoles.EmployeeContractor)) {
        if (userData) {
            return (
                <TabContext value={selectedTabIndex}>
                    <Grid container direction='column'>
                        <Grid item sx={{ background: 'white' }} mx={-3} mt={-3}>
                            <Tabs
                                value={selectedTabIndex}
                                onChange={(_, value) => setSelectedTabIndex(value)}
                                variant='fullWidth'
                                textColor='secondary'
                                indicatorColor='secondary'
                            >
                                <Tab label={'Employee'} key={0} value={'0'} />
                                <Tab label={'Contractor'} key={1} value={'1'} />
                            </Tabs>
                        </Grid>
                        <Grid item mx={-3}>
                            <TabPanel value={'0'}>
                                <HomeDashboard/>
                            </TabPanel>
                            <TabPanel value={'1'}>
                                <ContractorHomeDashboard contractorId={userData.contractorId} />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </TabContext>
            );
        } else {
            return <LoadingIndicator />;
        }
    } else {
        return <HomeDashboard />;
    }
};
