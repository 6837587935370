import { Build } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticatedComponent from '../../auth';
import { NavBreadcrumbs } from '../../Components/core/NavBreadcrumbs';
import { useGetContractorQuery } from '../../store/apis/contractor-api';
import { HomeDashboardCard } from '../../Components/Dashboard/HomeDashboard/HomeDashboardCard';
import { PendingDispatchesCardContent } from '../../Components/Dashboard/ContractorDashboard';
import { OpenDispatchesCardContent } from '../../Components/Dashboard/ContractorDashboard/OpenDispatchesCardContent';

export interface IContractorHomeDashboardProps {
    contractorId?: string;
}

export const ContractorHomeDashboard: FC<IContractorHomeDashboardProps> = (props) => {
    const { id } = useParams();
    const contractorId = id ?? props.contractorId;
    const { data: contractor } = useGetContractorQuery(contractorId!);

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start' justifyContent='space-between'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Build /> {contractor?.name}
                    </Typography>
                    <NavBreadcrumbs links={[]} currentPageLabel={'Home'!} />
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={4}>
                <Grid item xs={6}>
                    <AuthenticatedComponent
                        requiredPermissions={['read:contractors']}
                        component={
                            <HomeDashboardCard
                                title={'Pending Work Orders'}
                                subCard={<PendingDispatchesCardContent />}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <AuthenticatedComponent
                        requiredPermissions={['read:contractors']}
                        component={
                            <HomeDashboardCard
                                title={'Open Work Orders'}
                                subCard={<OpenDispatchesCardContent contractorId={contractorId ?? ''} />}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
