import { IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import { useGetWorkOrderDocumentsQuery } from '../../../store/apis/work-order-api';
import {
    useLazyGetDocumentFileLinkQuery
} from '../../../store/apis/document-api';

export interface IDownloadIconForWorkOrderProps {
    workOrderId: string;
}

export const DownloadIconForWorkOrder: FunctionComponent<IDownloadIconForWorkOrderProps> = React.memo((props) => {
    const { workOrderId } = props;

    const [getDocumentLink, { data: fileDownloadLink }] = useLazyGetDocumentFileLinkQuery();

    const { data: documents } = useGetWorkOrderDocumentsQuery({
        parentId: workOrderId,
        params: {
            includeInactive: true,
        },
    });

    const downloadPdf = (url?: string) => {
        if (url) {
            window.open(url);
        }
    };

    useEffect(() => {
        const proposalId = documents?.pageResults.find((doc) => doc.documentType === 'proposal' && doc.isActive)?.id;
        if (proposalId) {
            getDocumentLink(proposalId);
        }
    }, [documents, getDocumentLink]);

    return (
        <IconButton
            disabled={!fileDownloadLink}
            onClick={() => downloadPdf(fileDownloadLink?.link)}
        >
            <Download />
        </IconButton>
    );
});
