import { LeakLocationSearchQueryParams, serverApi } from '..';
import { LeakLocationDto, QueryResultsDto } from '../../models';

const leakLocationApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getLeakLocationsByFacility: builder.query<QueryResultsDto<LeakLocationDto>, LeakLocationSearchQueryParams>({
            query: (params) => ({ url: `leakLocation/facility/${params.facilityId}` }),
            providesTags: [{ type: 'Leak Location' }],
        }),
        getLeakLocation: builder.query<LeakLocationDto, string>({
            query: (id) => ({ url: `leakLocation/${id}` }),
            providesTags: [{ type: 'Leak Location' }]
        }),
        createLeakLocation: builder.mutation<LeakLocationDto, LeakLocationDto>({
            query: (leakLocationDto) => ({
                url: 'leakLocation',
                method: 'POST',
                body: leakLocationDto,
            }),
            invalidatesTags: [{ type: 'Leak Location' }],
        }),
        updateLeakLocation: builder.mutation<void, LeakLocationDto>({
            query: (leakLocationDto) => ({
                url: 'leakLocation',
                method: 'PUT',
                body: leakLocationDto,
            }),
            invalidatesTags: [{ type: 'Leak Location' }],
        }),
        archiveLeakLocation: builder.mutation<void, string>({
            query: (id) => ({
                url: `leakLocation/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Leak Location' }],
        }),
        unarchiveLeakLocation: builder.mutation<void, string>({
            query: (id) => ({
                url: `leakLocation/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Leak Location' }],
        }),
    }),
});

export const {useGetLeakLocationsByFacilityQuery, useCreateLeakLocationMutation, useGetLeakLocationQuery} = leakLocationApi;
