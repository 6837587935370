import { Lock, Undo } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, Grid, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { useGetDispatchQuery } from '../../../../store/apis/dispatch-api';
import LoadingIndicator from '../../../core/LoadingIndicator';
import { isLocked } from '../WorkOrderDispatchSection';
import { DispatchInformation } from './DispatchInformation';
import { ApiError } from '../../../core/ApiError';

export interface IRecallDispatchDialogProps {
    onClose: (action: string) => void;
    dispatchId: string;
    recallDispatch: (id: string) => void;
    unlockDispatch: (id: string) => void;
}

export const RecallDispatchDialog: FC<IRecallDispatchDialogProps> = React.memo((props) => {
    const { dispatchId, onClose, recallDispatch, unlockDispatch } = props;
    const { data: dispatch, isLoading: isLoadingDispatch, isError: isErrorLoadingDispatch, refetch: refetchDispatch } = useGetDispatchQuery(dispatchId );
    const isDispatchLocked = dispatch && isLocked(dispatch);

    if (isLoadingDispatch) {
        return (
            <DialogContent>
                <Grid item marginTop={2}>
                    <LoadingIndicator />
                </Grid>
            </DialogContent>
        );
    }

    if (isErrorLoadingDispatch || !dispatch) {
        return <ApiError onReloadClick={refetchDispatch} />;
    }

    return (
        <>
            <DialogContent>
                <Grid item marginTop={2}>
                    <DispatchInformation dispatch={dispatch} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => recallDispatch(dispatch.id)}
                    startIcon={<Undo />}
                    variant='contained'
                    color='error'
                    disabled={isDispatchLocked || !dispatch.isActive}
                >
                    Recall
                </Button>
                {isDispatchLocked && (
                    <IconButton
                        onClick={() => unlockDispatch(dispatch.id)}
                        disabled={!dispatch.isActive ?? true}
                    >
                        <Lock sx={{ fontSize: '32px' }} />
                    </IconButton>
                )}

                <Grid item sx={{ flexGrow: 1 }} />
                <Button variant='contained' onClick={() => onClose('')}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
});
