import { Edit, Visibility } from '@mui/icons-material';
import {
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import AuthenticatedComponent from '../../../auth';
import { BaseDto } from '../../../models';
import LoadingIndicator from '../LoadingIndicator';
import { PaginationActions } from './PaginationActions';
import { DataTableColumn, IPaginatedDataTableProps } from './types';
import { v4 as uuidv4 } from 'uuid';
import { Theme } from '@emotion/react';
import { SxProps } from '@mui/system';

type Order = 'asc' | 'desc';

export const PaginatedDataTable: FunctionComponent<IPaginatedDataTableProps> = React.memo((props) => {
    const {
        columns,
        defaultSortKey,
        defaultSortDesc,
        queryResults,
        loading,
        setPagination,
        selected,
        setSelected,
        isViewOnly,
        disableEdit,
        editPermissions = { requiredPermissions: [], logic: 'and' },
        onEdit,
        onRowClick,
        isDashboardStyle,
        customRowStyling = (row: any): SxProps<Theme> => { return {}; },
        disableSelection,
        noResultsMessage = 'No results found.',
    } = props;
    const [order, setOrder] = useState<Order>(defaultSortDesc ? 'desc' : 'asc');
    const [orderBy, setOrderBy] = useState(defaultSortKey);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(isDashboardStyle ? 10 : 25);
    const [hoverRow, setHoverRow] = useState('');

    const shouldShowCheckboxes = useMemo(() => !isDashboardStyle && !disableSelection, [isDashboardStyle, disableSelection]);

    useEffect(() => {
        setPagination({ sortKey: orderBy, sortAsc: order === 'asc', page: page, pageSize: pageSize });
    }, [orderBy, order, page, pageSize, setPagination]);

    const handleRequestSort = useCallback((sortKey: string) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === sortKey && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(sortKey);
    }, [order, orderBy]);

    const handleChangePage = useCallback((event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    }, []);

    const handleSelectAllClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = queryResults!.pageResults!.map((n, index) => index);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    }, [queryResults, setSelected]);

    const handleSelectClick = useCallback((event: React.MouseEvent<unknown>, row: number) => {
        const selectedIndex = selected.indexOf(row);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    }, [selected, setSelected]);

    const handleRowOnMouseEnter = useCallback((id: string) => {
        setHoverRow(id);
    }, []);

    const handleRowOnMouseLeave = useCallback((id: string) => {
        if (hoverRow === id) {
            setHoverRow('');
        }
    }, [hoverRow]);

    const handleRowClick = useCallback((event: React.MouseEvent<unknown>, row: number, id: string) => {
        event.preventDefault();
        event.stopPropagation();
        if (onRowClick) {
            onRowClick(id);
        }
        handleSelectClick(event, row);
    }, [handleSelectClick, onRowClick]);

    const renderHeaders = useCallback((headers: DataTableColumn<unknown>[]) => {
        return headers.map((header) => {
            return (
                <TableCell
                    key={uuidv4()}
                    align={header.align}
                    sx={
                        {
                            width: header.width ? header.width : 'auto',
                            minWidth: header.width ? header.width : 'auto',
                            maxWidth: header.width ? header.width : 'auto',
                            pr: 1,
                        }}
                >
                    {!header.unsorted ? (
                        <TableSortLabel
                            active={orderBy === header.sortKey}
                            direction={orderBy === header.sortKey ? order : 'asc'}
                            onClick={handleRequestSort(header.sortKey)}
                            sx={{ px: 0, mx: 0 }}>
                            {header.label}
                        </TableSortLabel>
                    ) : (
                        <>{header.label}</>
                    )}
                </TableCell>
            );
        });
    }, [handleRequestSort, order, orderBy]);

    const renderFields = useCallback((row: BaseDto, rowIdx: number) => {
        return columns.map((header, index) => {
            let field: any = null;
            if (header.fieldMapper) {
                field = header.fieldMapper(row);
            } else {
                const accessors = header.key.split('.');
                field = row;
                accessors.forEach((key) => {
                    field = field[key];
                });
            }

            if (typeof field === 'number') {
                field = field.toLocaleString();
            }

            if (typeof field === 'string') {
                field = (
                    <Tooltip title={field} followCursor>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{field}</div>
                    </Tooltip>
                );
            }

            let isFirstColumn = index === 0 && !shouldShowCheckboxes;
            let shouldAddPaddingLeft = isFirstColumn;
            return (
                <TableCell
                    onClick={(event) => handleRowClick(event, rowIdx, row.id)}
                    key={header.key}
                    align={header.align}
                    size={isDashboardStyle ? 'small' : 'medium'}
                    sx={{
                        ...(row.isActive === true
                            ? {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                px: 0,
                            }
                            : {
                                color: '#AAAAAA',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }),
                        fontSize: isDashboardStyle ? '12px' : '',
                        lineHeight: isDashboardStyle ? '12px' : '14px',
                        textOverflow: 'ellipsis',
                        width: header.width ? header.width : 'auto',
                        minWidth: header.width ? header.width : 'auto',
                        maxWidth: header.width ? header.width : 'auto',
                        pl: shouldAddPaddingLeft ? 1 : 0,
                        pr: 1,
                        py: isDashboardStyle ? 0.2 : 1,
                    }}>
                    {field}
                </TableCell>
            );
        });
    }, [columns, handleRowClick, isDashboardStyle, shouldShowCheckboxes]);

    const isSelected = useCallback((row: number) => {
        return selected.indexOf(row) !== -1;
    }, [selected]);

    const rowCount = useCallback(() => {
        return queryResults ? queryResults.pageResults.length : 0;
    }, [queryResults]);

    return (
        <Paper elevation={0} sx={{ border: isDashboardStyle ? '' : 'solid 1px #AAAAAA', width: '100%' }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow key={uuidv4()}>
                            {shouldShowCheckboxes && (
                                <TableCell padding='checkbox' sx={{ py: 0 }}>
                                    <Checkbox
                                        color='primary'
                                        indeterminate={selected.length > 0 && selected.length < rowCount()}
                                        checked={rowCount() > 0 && selected.length === rowCount()}
                                        onChange={handleSelectAllClick}
                                    />
                                </TableCell>
                            )}
                            {renderHeaders(columns)}
                            {onEdit && editPermissions && (
                                <AuthenticatedComponent
                                    requiredPermissions={editPermissions.requiredPermissions}
                                    logic={editPermissions.logic}
                                    component={<TableCell padding='checkbox' width={'100px'} />}
                                />
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {queryResults && queryResults.pageResults.length > 0
                            ? queryResults.pageResults.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                return (
                                    <TableRow
                                        key={`${row.id}-${index}-${row.createdOn}`}
                                        hover
                                        onMouseEnter={() => {
                                            handleRowOnMouseEnter(row.id);
                                        }}
                                        onMouseLeave={() => handleRowOnMouseLeave(row.id)}
                                        role='checkbox'
                                        selected={isItemSelected}
                                        tabIndex={-1}
                                        className='hover-content-container'
                                        sx={customRowStyling(row)}
                                    >
                                        {shouldShowCheckboxes && (
                                            <TableCell
                                                padding='checkbox'
                                                sx={row.isActive === undefined || row.isActive ? {} : { borderLeft: '#AAAAAA 10px solid' }}>
                                                <Checkbox
                                                    color='primary'
                                                    sx={row.isActive === undefined || row.isActive ? {} : { marginLeft: '-5px' }}
                                                    onClick={(event) => handleSelectClick(event, index)}
                                                    checked={isItemSelected}
                                                />
                                            </TableCell>
                                        )}
                                        {renderFields(row, index)}
                                        {onEdit && editPermissions && (
                                            <AuthenticatedComponent
                                                requiredPermissions={editPermissions.requiredPermissions}
                                                logic={editPermissions.logic}
                                                component={
                                                    <TableCell
                                                        padding='checkbox'
                                                    >
                                                        {(!disableEdit || !disableEdit(row.id)) && hoverRow === row.id && (
                                                            <Tooltip title={isViewOnly ? 'View' : 'Edit '}>
                                                                <span>
                                                                    <IconButton
                                                                        className='hover-content'
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            onEdit(row.id);
                                                                        }}>
                                                                        {isViewOnly ? (
                                                                            <Visibility />
                                                                        ) : (
                                                                            <Edit sx={{ fontSize: isDashboardStyle ? '16px' : '24px' }} />
                                                                        )}
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                }
                                            />
                                        )}
                                    </TableRow>
                                );
                            })
                            : !loading && (
                                <TableRow>
                                    <TableCell align='center' colSpan={columns.length + 1 + (onEdit ? 1 : 0)}>
                                        {noResultsMessage}
                                    </TableCell>
                                </TableRow>
                            )}
                        {loading && (
                            <TableRow>
                                <TableCell align='center' colSpan={columns.length + 1 + (onEdit ? 1 : 0)}>
                                    <LoadingIndicator />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    {!loading && (
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={columns.length + 1}
                                    rowsPerPageOptions={isDashboardStyle ? [] : [25, 50, 100]}
                                    count={queryResults?.totalQueryResults! ?? 0}
                                    ActionsComponent={PaginationActions}
                                    rowsPerPage={isDashboardStyle ? 10 : pageSize}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    sx={isDashboardStyle ? { border: 'none' } : {}}
                                />
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>
        </Paper >
    );
});
