import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, Link, TextField } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { RoofSectionReportDto } from '../../../models/RoofSectionReportDto';
import {
    useGetRoofSectionReportsQuery,
    useLazyGetRoofSectionReportPdfQuery
} from '../../../store/apis/roof-section-report-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { ReportPdfStatus } from '../../../models';
import { useNavigate } from 'react-router-dom';

export interface IRoofSectionDashboardRoofSectionReportsCard {
    roofSectionId: string;
}

export const RoofSectionDashboardRoofSectionReportsCard: FC<IRoofSectionDashboardRoofSectionReportsCard> = ({ roofSectionId }) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [getReportPdf] = useLazyGetRoofSectionReportPdfQuery();

    const { data, error, isLoading, refetch } = useGetRoofSectionReportsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: false,
        roofSectionId
    });
    const [selected, setSelected] = useState<readonly number[]>([]);
    const handleDownloadPdf = (id: string) => {
        getReportPdf(id).then((response) => {
            if (!response.isError && response.data) {
                window.open(response.data.downloadUrl);
            }
        });
    };

    const tableColumns: DataTableColumn<RoofSectionReportDto>[] = [
        {
            key: 'reportName',
            label: 'Report Name',
            sortKey: 'REPORT_NAME',
            fieldMapper: (row) =>
                row.reportPdf?.status === ReportPdfStatus.Created ? (
                    <Link sx={{ cursor: 'pointer' }} onClick={() => handleDownloadPdf(row.id)}>
                        {row.reportName}
                    </Link>
                ) : (
                    <>{row.reportName}</>
                ),
        },
        {
            key: 'inspectionDate',
            label: 'Date of Inspection',
            sortKey: 'INSPECTION_DATE',
            fieldMapper: (row) => (row.inspectionDate ? format(new Date(row.inspectionDate.toString()), 'M/d/yyyy') : ''),
        },
        {
            key: 'squareFootage',
            label: 'Square Footage',
            sortKey: 'SQUARE_FOOTAGE',
            align: 'right',
            fieldMapper: (row) => (row.squareFootage ? row.squareFootage.toLocaleString() : ''),
        },
        {
            key: 'contact',
            label: 'Technician',
            sortKey: 'TECHNICIAN',
            fieldMapper: (row) => (row.contact ? row.contact.firstName + ' ' + row.contact.lastName : ''),
        },
        { key: 'roofType', label: 'Roof Type', unsorted: true, sortKey: '', fieldMapper: (row) => row.roofSystem?.name },
    ];

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleRowEdit = useCallback((id: string) => {
        const roofSectionReport = data?.pageResults.find((x) => x.id === id);
        if (roofSectionReport) {
            navigate(`/clientreports/${roofSectionReport.reportId}/roofsectionreport/edit/${roofSectionReport.id}`);
        }
    }, [data?.pageResults, navigate]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                <Grid item container direction='row' alignItems='center' justifyContent='end' xs={6}>
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='ROOF_NUMBER'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:reports'] }}
                    onEdit={handleRowEdit}
                />
            </Grid>
        </Grid>
    );
};
