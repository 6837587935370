import { AddCircle, Delete, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import AuthenticatedComponent from '../../../auth';
import { ImmediateRepairsChecklistItemDto } from '../../../models/ImmediateRepairsChecklistItemDto';
import { useArchiveImmediateRepairsChecklistItemMutation, useGetImmediateRepairsChecklistItemsQuery, useUnarchiveImmediateRepairsChecklistItemMutation } from '../../../store/apis/immediate-repairs-checklist-item-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { AddEditImmediateRepairsChecklistItemDialog } from './AddEditImmediateRepairsChecklistItemDialog';

export interface IImmediateRepairsChecklistItemListViewProps {
    disabled?: boolean;
    reportId: string;
    facilityId?: string;
    roofProfileId?: string;
    roofSectionDetails?: {
        id: string;
        roofNumber: string;
    }
}

export const ImmediateRepairsChecklistItemListView: FC<IImmediateRepairsChecklistItemListViewProps> = ({ disabled, reportId, facilityId, roofProfileId, roofSectionDetails }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [dialogInstanceId, setDialogInstanceId] = useState(1);
    const [isAddNewChecklistItemOpen, setIsAddNewChecklistItemOpen] = useState(false);
    const [selectedChecklistItem, setSelectedChecklistItem] = useState<ImmediateRepairsChecklistItemDto | undefined>(undefined);

    const { data, error, isLoading, refetch } = useGetImmediateRepairsChecklistItemsQuery({
        parentId: reportId,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
            roofSectionId: roofSectionDetails?.id
        }
    });
    const [archiveSection, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveImmediateRepairsChecklistItemMutation();
    const [unarchiveSection, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveImmediateRepairsChecklistItemMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<ImmediateRepairsChecklistItemDto>[] = useMemo(() => [
        { key: 'roofSection', label: 'Roof Section', sortKey: 'ROOF_NUMBER', fieldMapper: (row) => (row.roofSection ? row.roofSection.roofNumber : '') },
        {
            key: 'checklistItem',
            label: 'Checklist Item',
            sortKey: 'CHECKLIST_ITEM',
            fieldMapper: (row) => (row.checklistItem),
        }
    ], []);

    const entityName = useMemo(() => {
        return selected.length === 1 ? 'Checklist Item' : 'Checklist Items';
    }, [selected.length]);

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', entityName, isArchiveError, () => { handleActionRequestCompletion(resetArchive); });
    useFailedActionSnackbar('activate', entityName, isUnarchiveError, () => { handleActionRequestCompletion(resetUnarchive); });
    useSuccessfulActionSnackbar('Inactivated', entityName, isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', entityName, isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback((afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    }, []);

    const handleCreateNew = useCallback(() => {
        setDialogInstanceId(dialogInstanceId + 1);
        setIsAddNewChecklistItemOpen(true);
    }, [dialogInstanceId]);

    const handleMenuEdit = useCallback(() => {
        const roofSectionReport = data?.pageResults[selected[0]];
        if (roofSectionReport) {
            setDialogInstanceId(dialogInstanceId + 1);
            setSelectedChecklistItem(roofSectionReport);
            setIsAddNewChecklistItemOpen(true);
        }
    }, [data?.pageResults, dialogInstanceId, selected]);

    const handleRowEdit = useCallback((id: string) => {
        const roofSectionReport = data?.pageResults.find((x) => x.id === id);
        if (roofSectionReport) {
            setDialogInstanceId(dialogInstanceId + 1);
            setSelectedChecklistItem(roofSectionReport);
            setIsAddNewChecklistItemOpen(true);
        }
    }, [data?.pageResults, dialogInstanceId]);

    // TODO: for both the activate and inactivate methods below the parentId and childId is set to the same value. (It's not supposed to do that...)
    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveSection({ parentId: data?.pageResults[index].id!, childId: data?.pageResults[index].id! });
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [data?.pageResults, selected, unarchiveSection]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveSection({ parentId: data?.pageResults[index].id!, childId: data?.pageResults[index].id! });
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [archiveSection, data?.pageResults, selected]);

    const handleAddChecklistItemClose = useCallback((keepOpen: boolean) => {
        setIsAddNewChecklistItemOpen(keepOpen);
        setSelectedChecklistItem(undefined);
    }, []);

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index]?.isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index]?.isActive);
    };

    const disableEdit = useCallback((__: string) => {
        if (disabled) {
            return disabled;
        } else {
            return false;
        }
    }, [disabled]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                <Grid item container direction='row' alignItems='center' xs={6}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:reports']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive() || disabled}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:reports']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew} disabled={disabled}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:reports', 'edit:reports', 'delete:reports']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:reports']}
                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)} disabled={disabled}>Add New</MenuItem>}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:reports']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1 || disabled}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:reports']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive() || disabled}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive() || disabled}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        } />
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='ROOF_NUMBER'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:reports'] }}
                    onEdit={handleRowEdit}
                    disableEdit={disableEdit}
                />
            </Grid>
            <AddEditImmediateRepairsChecklistItemDialog
                key={dialogInstanceId}
                open={isAddNewChecklistItemOpen}
                onClose={handleAddChecklistItemClose}
                initValues={selectedChecklistItem}
                reportId={reportId}
                disabled={disabled}
                facilityId={facilityId}
                roofProfileId={roofProfileId}
                defaultRoofNumber={roofSectionDetails?.roofNumber}
            />
        </Grid>
    );
};
