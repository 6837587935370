import { useMemo } from 'react';
import { useStandardSnackbar } from './useStandardSnackbar';
import { checkForCustomErrorMessage } from './utils';

export function useFailedUpdateSnackbar(entityName: string, isCreateSuccessful: boolean, onSnackbarDisplay: () => void = () => {}, errorObject: any = null) {
    const message = useMemo(() => {
        const customErrorMessage = checkForCustomErrorMessage(errorObject);
        if (!!customErrorMessage) {
            return customErrorMessage;
        }
        return `An error occurred while attempting to update ${entityName.toLowerCase()}`;
    }, [entityName, errorObject]);

    useStandardSnackbar(isCreateSuccessful, message, 'error', onSnackbarDisplay);
}
