import { useAuth0 } from "@auth0/auth0-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IAuthenticatedComponentProps } from "./types";
import jwt from 'jsonwebtoken';
import { userHasPermissions } from "./userHelper";

const AuthenticatedComponent: FC<IAuthenticatedComponentProps> = props => {
    const auth = useAuth0();
    const { requiredPermissions, logic = 'and', component, unauthorizedComponent = <></> } = props;
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const getUserPermissions = useCallback(async () => {
        const token = await auth.getAccessTokenSilently();

        return jwt.decode(token);
    }, [auth]);

    useEffect(() => {
        if (requiredPermissions.length > 0) {
            if (!auth.isLoading && auth.isAuthenticated) {
                getUserPermissions().then((result) => {
                    const permissions = ((result as any)?.permissions as string[]) ?? [];

                    setIsAuthenticated(userHasPermissions(requiredPermissions, permissions, logic));
                });
            }
        } else {
            setIsAuthenticated(true);
        }
    }, [auth, requiredPermissions, isAuthenticated, setIsAuthenticated, logic, getUserPermissions]);

    return useMemo(() => isAuthenticated ? component : unauthorizedComponent, [component, isAuthenticated, unauthorizedComponent]);
};

export default AuthenticatedComponent;