import { DefaultDocumentFolderSearchQueryParams, serverApi } from '..';
import { DefaultDocumentFolderDto, QueryResultsDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const defaultDocumentFolderApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDefaultDocumentFolders: builder.query<QueryResultsDto<DefaultDocumentFolderDto>, DefaultDocumentFolderSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`defaultDocumentFolder`, params),
            providesTags: [{ type: 'DefaultDocumentFolder' }],
        }),
        createDefaultDocumentFolder: builder.mutation<DefaultDocumentFolderDto, DefaultDocumentFolderDto>({
            query: (defaultDocumentFolderDto) => ({
                url: `defaultDocumentFolder`,
                method: 'POST',
                body: defaultDocumentFolderDto,
            }),
            invalidatesTags: [{ type: 'DefaultDocumentFolder' }],
        }),
        updateDefaultDocumentFolder: builder.mutation<void, DefaultDocumentFolderDto>({
            query: (defaultDocumentFolderDto) => ({
                url: 'defaultDocumentFolder',
                method: 'PUT',
                body: defaultDocumentFolderDto,
            }),
            invalidatesTags: [{ type: 'DefaultDocumentFolder' }],
        }),
        archiveDefaultDocumentFolder: builder.mutation<void, string>({
            query: (id) => ({
                url: `defaultDocumentFolder/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'DefaultDocumentFolder' }],
        }),
        unarchiveDefaultDocumentFolder: builder.mutation<void, string>({
            query: (id) => ({
                url: `defaultDocumentFolder/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'DefaultDocumentFolder' }],
        }),
    }),
});

export const {
    useGetDefaultDocumentFoldersQuery,
    useLazyGetDefaultDocumentFoldersQuery,
    useCreateDefaultDocumentFolderMutation,
    useUpdateDefaultDocumentFolderMutation,
    useArchiveDefaultDocumentFolderMutation,
    useUnarchiveDefaultDocumentFolderMutation,
} = defaultDocumentFolderApi;
