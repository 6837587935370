import { ImportDto } from '../../models';
import { serverApi } from "..";

const importApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        createBudgetLineItemsImport: builder.mutation<ImportDto, ImportDto>({
            query: (importDto) => ({
                url: 'import',
                method: 'POST',
                body: importDto
            }),
            invalidatesTags: [{ type: 'Import' }, { type: 'Budget Line Item' }]
        })
    })
});

export const {
    useCreateBudgetLineItemsImportMutation,
} = importApi;