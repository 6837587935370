import { FunctionComponent, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { ContractorForm } from '../../Components/Forms/ContractorForm';
import { ContractorDto } from '../../models/ContractorDto';
import { useGetContractorQuery, useUpdateContractorMutation } from '../../store/apis/contractor-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditContractorView: FunctionComponent = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetContractorQuery(id!);
    const [updateContractor, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateContractorMutation();

    useFailedUpdateSnackbar('contractor', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Contractor', isUpdateSuccess);

    const handleSave = useCallback((values: ContractorDto) => {
        updateContractor(values);
    }, [updateContractor]);

    const handleCancel = useCallback(() => {
        navigate('/contractors');
    }, [navigate]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <ContractorForm save={handleSave} cancel={handleCancel} initValues={data} />;
};
