import { BaseDto } from './BaseDto';

export interface ReportPdfDto extends BaseDto {
    displayName: string;
    sourceName: string;
    status: number;
    downloadUrl?: string;
}

export enum ReportPdfStatus {
    Creating,
    Created,
    Error
}