import { serverApi } from '..';
import { QueryResultsDto, WorkOrderTypeDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';
import { WorkOrderTypeSearchQuery } from '../types';

const workOrderTypeApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkOrderTypes: builder.query<QueryResultsDto<WorkOrderTypeDto>, WorkOrderTypeSearchQuery>({
            query: (params) => buildSearchQueryRoute('workorder/type', params),
            providesTags: [{ type: 'Work Order Type' }],
        }),
        createWorkOrderType: builder.mutation<WorkOrderTypeDto, WorkOrderTypeDto>({
            query: (workOrderTypeDto) => ({
                url: 'workorder/type',
                method: 'POST',
                body: workOrderTypeDto,
            }),
            invalidatesTags: [{ type: 'Work Order Type' }],
        }),
    }),
});

export const { useGetWorkOrderTypesQuery, useCreateWorkOrderTypeMutation } = workOrderTypeApi;
