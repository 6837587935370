import { Lock } from '@mui/icons-material';
import { format } from "date-fns";
import { useCallback, useMemo, useState } from 'react';
import { WorkOrderDto } from "../../../../models";
import { DispatchListViewDto } from '../../../../models/DispatchListViewDto';
import { useGetDispatchesQuery } from '../../../../store/apis/dispatch-api';
import { DataTableColumn, PaginatedProps } from "../../../core/DataTable";
import { getStatusRow } from "../../DispatchStatusIcons";
import { isLocked } from "../WorkOrderDispatchSection";

function getOtherDescriptionText(otherDescription?: string) {
    const maxRowChars = 20;
    return otherDescription ? (otherDescription.substring(0, maxRowChars) + (otherDescription.length > maxRowChars ? '...' : '')) : '';
};

const TABLE_COLUMNS: DataTableColumn<DispatchListViewDto>[] = [
    {
        key: 'createdOn',
        label: 'Dispatched',
        sortKey: 'CREATED',
        fieldMapper: (row) => (row.createdOn && format(new Date(row.createdOn.toString()), 'M/d/yyyy')),
    },
    {
        key: 'typeName',
        label: 'Type',
        sortKey: '',
        unsorted: true
    },
    {
        key: 'contractorDispatchId',
        label: 'Dispatch ID',
        sortKey: 'CONTRACTOR'
    },
    {
        key: 'contactName',
        label: 'Contact',
        sortKey: 'CONTACT',
        width:'120px'
    },
    {
        key: 'email',
        label: 'Email',
        sortKey: 'EMAIL'
    },
    {
        key: 'urgency',
        label: 'Urgency',
        sortKey: 'URGENCY'
    },
    {
        key: 'nte',
        label: 'Not to Exceed',
        sortKey: 'NTE',
        fieldMapper: (row) => (row.nte ? (row.nte === 'Other' ? getOtherDescriptionText(row.otherDescription) : row.nte) : ''),
    },
    {
        key: 'status',
        label: 'Status',
        sortKey: 'STATUS',
        fieldMapper: (row) => (row.status ? getStatusRow(row.status) : ''),
    },
    {
        key: 'scheduledDate',
        label: 'Scheduled',
        sortKey: 'SCHEDULED',
        fieldMapper: (row) => (row.scheduledDate && format(new Date(row.scheduledDate.toString()), 'M/d/yyyy')),
    },
    {
        key: 'lockedOn',
        label: 'Locked',
        sortKey: 'LOCKED_ON',
        align: 'center',
        fieldMapper: (row) => (isLocked(row) ? <Lock /> : ''),
    },
];

export function useWorkOrderDispatchesTableManager(workOrder: WorkOrderDto, setCurrentDispatch: React.Dispatch<React.SetStateAction<DispatchListViewDto | undefined>>) {
    const [searchText, setSearchText] = useState('');
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const {
        data: dispatches,
        isLoading: isDispatchesLoading,
        error: dispatchesError,
        refetch: dispatchesRefetch,
    } = useGetDispatchesQuery({
        workOrderId: workOrder.id,
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: true
    });

    const setCurrentSelected = useCallback((indexes: readonly number[]) => {
        setSelected(indexes);
        setCurrentDispatch((indexes.length === 1 && dispatches) ? dispatches.pageResults[indexes[0]] : undefined);
    }, [dispatches, setCurrentDispatch]);

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentSelected([]);
        setSearchText(event.target.value);
    }, [setCurrentSelected]);

    const selectedDispatches = useMemo(() => (dispatches && selected.map((index) => dispatches.pageResults[index])) ?? [], [selected, dispatches]);

    return {
        dispatches,
        selectedDispatches,
        handleSearchChange,
        isDispatchesLoading,
        dispatchesError,
        dispatchesRefetch,
        searchText,
        setPaginatedProps,
        selected,
        setCurrentSelected,
        TABLE_COLUMNS
    };
}