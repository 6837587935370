import { Grid, Link } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { DocumentDto, RoofSectionDto } from '../../../models';
import { useGetDocumentFileLinkQuery } from '../../../store/apis/document-api';
import { useGetRoofSectionsDocumentsQuery } from '../../../store/apis/roof-section-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { IRoofSectionDocuments } from './types';

export const RoofSectionDocuments: FunctionComponent<IRoofSectionDocuments> = (props) => {
    const { roofSectionId } = props;
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data, isLoading, error, refetch } = useGetRoofSectionsDocumentsQuery({
        parentId: roofSectionId!,
        includePrivate: false,
        params: {
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
        },
    });
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [documentToDownload, setDocumentToDownload] = useState<DocumentDto>();
    const [downloadFile, setDownloadFile] = useState(false);
    const { data: fileDownloadLink, isLoading: fileDownloadLinkLoading } = useGetDocumentFileLinkQuery(documentToDownload?.id!);

    const onFileLinkClick = useCallback((document: DocumentDto) => {
        setDownloadFile(true);
        setDocumentToDownload(document);
    }, []);

    const formatRoofSections = useCallback((roofSections: RoofSectionDto[]) => {
        let sectionsText = '';
        roofSections.sort((x, y) => (x.roofNumber > y.roofNumber ? 1 : -1));
        roofSections.forEach((roofSection, index) => {
            sectionsText = sectionsText.concat(`${roofSection.roofNumber}`);
            if (index < roofSections.length - 1) {
                sectionsText = sectionsText.concat(', ');
            }
        });
        return sectionsText;
    }, []);

    const tableColumns: DataTableColumn<DocumentDto>[] = useMemo(() => [
        {
            key: 'filename',
            label: 'Filename',
            sortKey: 'FILENAME',
            fieldMapper: (row) => (
                <Link sx={{ cursor: 'pointer' }} onClick={() => onFileLinkClick(row)}>
                    {row.displayName}
                </Link>
            ),
        },
        { key: 'description', label: 'Description', sortKey: 'DESCRIPTION' },
        {
            key: 'roofSections',
            label: 'Roof Section(s)',
            sortKey: 'ROOF_SECTIONS',
            unsorted: true,
            fieldMapper: (row) => (row.roofSections ? formatRoofSections(row.roofSections.map((x) => x.roofSection!)) : ''),
        },
        {
            key: 'uploadedOn',
            label: 'Uploaded',
            sortKey: 'UPLOADED_ON',
            fieldMapper: (row) => (row.createdOn ? format(new Date(row.createdOn.toString()), 'M/d/yyyy') : ''),
        },
    ], [formatRoofSections, onFileLinkClick]);

    useEffect(() => {
        if (fileDownloadLink && !fileDownloadLinkLoading && downloadFile) {
            const link = document.createElement('a');
            link.href = fileDownloadLink.link;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadFile(false);
        }
    }, [fileDownloadLink, fileDownloadLinkLoading, downloadFile, setDownloadFile, setSelected, selected]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '40px' }}>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='UPLOADED_ON'
                    defaultSortDesc
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                />
            </Grid>
        </Grid>
    );
};
