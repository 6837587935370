import { FunctionComponent } from 'react';
import { Grid, Paper } from '@mui/material';
import { IFormSectionProps } from './types';

export const FormSection: FunctionComponent<IFormSectionProps> = props => {
    const { hideBorder } = props;

    return (
        <Paper elevation={0} sx={{ border: hideBorder ? 'none' : 'solid 1px #AAAAAA', padding: '24px', position: 'relative' }}>
            <Grid container direction="column" spacing={2}>
                {props.children}
            </Grid>
        </Paper>
    );
};