import { Grid, FormLabel, FormControlLabel, Checkbox, GridSize, Theme, Tooltip } from '@mui/material';
import { FC, useCallback } from 'react';
import AuthenticatedComponent from '../../auth/AuthenticatedComponent';
import _ from 'lodash';
import { VisibilityControlledDto } from '../../models';
import { SxProps } from '@mui/system';

export const DEFAULT_VISIBILITY_CONFIG: VisibilityConfig = {
    isVisibleToClients: false,
    isVisibleToEmployees: true,
};

export const currentVisibilityOrDefault = (dto?: VisibilityControlledDto) => {
    if (dto) {
        return { isVisibleToClients: dto.isVisibleToClients, isVisibleToEmployees: dto.isVisibleToEmployees };
    }
    return DEFAULT_VISIBILITY_CONFIG;
};

export interface VisibilityConfig {
    isVisibleToEmployees: boolean;
    isVisibleToClients: boolean;
}

export interface IVisibilityControlProps {
    value: VisibilityConfig;
    onValueChange: (updatedValue: VisibilityConfig) => void;
    disabled?: boolean;
    gridProps?: {
        xs?: boolean | GridSize | undefined;
        sx?: SxProps<Theme> | undefined
    }
    isWorkOrderDocument?: boolean;
}

export const VisibilityControl: FC<IVisibilityControlProps> = ({ value, onValueChange, disabled, gridProps = {}, isWorkOrderDocument = false }) => {
    const setConfigValue = useCallback(
        (field: keyof VisibilityConfig, newConfigValue: boolean) => {
            var updatedValue = _.cloneDeep(value);
            updatedValue[field] = newConfigValue;
            onValueChange(updatedValue);
        },
        [value, onValueChange]
    );

    return (
        <AuthenticatedComponent
            requiredPermissions={['edit:visibility']}
            component={
                <Grid item {...gridProps}>
                    <FormLabel required sx={{ pr: 0.5 }} disabled={disabled}>
                        Visibility
                    </FormLabel>
                    <Grid item display='flex' flexDirection='row' justifyContent='space-around' alignItems='center' border='1px solid #AAAAAA' borderRadius={1} height={56}>
                        <Tooltip title={isWorkOrderDocument ? 'Clients can not see Work Order Documents' : ''} children={
                            <FormControlLabel
                                sx={{ mr: 0 }}
                                control={
                                    <Checkbox
                                        checked={value.isVisibleToClients}
                                        onChange={(_, isChecked) => setConfigValue('isVisibleToClients', isChecked)}
                                        disabled={disabled || isWorkOrderDocument}
                                    />
                                }
                                label='Client'
                            />
                        } />

                        <FormControlLabel
                            sx={{ mr: 0 }}
                            control={
                                <Checkbox
                                    checked={value.isVisibleToEmployees}
                                    onChange={(_, isChecked) => setConfigValue('isVisibleToEmployees', isChecked)}
                                    disabled={disabled}
                                />
                            }
                            label='Employee'
                        />
                    </Grid>
                </Grid>
            }
        />
    );
};
