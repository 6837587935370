import { Notes } from '@mui/icons-material';
import { format } from 'date-fns';
import { FunctionComponent } from 'react';
import { emptyGuid } from '../../../models';
import { useCreateWorkOrderNoteMutation, useUpdateNoteMutation } from '../../../store/apis/note-api';
import { useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../../util/customHooks';
import { QuickAddDialog } from '../../core/QuickAddDialog';
import { IAddNotesDialogProps } from './types';

export const AddNotesDialog: FunctionComponent<IAddNotesDialogProps> = (props) => {
    const { open, onClose, workOrderId, initValues } = props;
    const [create, { isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateWorkOrderNoteMutation();
    const [update, { isError: isUpdateError, isSuccess: isUpdateSuccess, reset: resetUpdate }] = useUpdateNoteMutation();

    const save = (formValue: string) => {
        if (initValues && workOrderId !== emptyGuid) {
            update({
                id: initValues.id,
                workOrderId: workOrderId,
                isActive: true,
                createdOn: initValues.createdOn,
                text: formValue,
            });
        } else {
            create({
                id: emptyGuid,
                text: formValue,
                workOrderId: workOrderId,
                isActive: true,
            });
        }
    };

    useFailedCreateSnackbar('note', isCreateError, resetCreate);
    useFailedUpdateSnackbar('note', isUpdateError, resetUpdate);
    useSuccessfulCreateSnackbar('Note', isCreateSuccess, resetCreate);
    useSuccessfulUpdateSnackbar('Note', isUpdateSuccess, resetUpdate);

    return (
        <QuickAddDialog
            open={open}
            onClose={onClose}
            formLabel={"Note"}
            title={initValues?.createdOn ? 'Note from ' + format(new Date(initValues?.createdOn.toString()), 'M/d/yyyy') : 'New Note'}
            titleIcon={<Notes />}
            cancelText={"Cancel"}
            confirmText={"Save"}
            initValue={initValues?.text}
            save={save}
            multiline={true}
        />
    );
};

