import { Label } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, OutlinedInput, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { emptyGuid } from '../../../models';
import { useCreateWarrantyTypeMutation } from '../../../store/apis/warranty-type-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../util/customHooks';
import { IAddWarrantyTypeDialog } from './types';

export const AddWarrantyTypeDialog: FunctionComponent<IAddWarrantyTypeDialog> = (props) => {
    const { open, onClose } = props;
    const [createType, { data, isSuccess: isCreateSuccess, isError: isCreateError, error: createTypeError, reset: resetCreate }] = useCreateWarrantyTypeMutation();
    const [type, setType] = useState<string>('');
    const [errorText, setErrorText] = useState('');

    const beforeClose = useCallback(() => {
        setType('');
        setErrorText('');
        resetCreate();
        onClose(data);
    }, [data, onClose, resetCreate]);

    useFailedCreateSnackbar('warranty', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Warranty', isCreateSuccess, () => {
        if (open) {
            beforeClose();
        }
    });

    useEffect(() => {
        if (createTypeError && 'data' in createTypeError) {
            if (createTypeError.data === 'Warranty type already exists.') {
                setErrorText('Warranty type already exists.');
            }
        }
    }, [createTypeError]);

    const onSave = () => {
        if (type) {
            createType({
                id: emptyGuid,
                name: type,
                isActive: true,
            });
        } else {
            setErrorText('This field is required');
        }
    };

    return (
        <Dialog onClose={beforeClose} open={open} maxWidth='xs' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <Label />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        New Type
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' marginTop={2}>
                    <FormControl fullWidth required>
                        <FormLabel>Type</FormLabel>
                        <OutlinedInput
                            fullWidth
                            multiline
                            value={type}
                            onChange={(e) => {
                                setType(e.target.value);
                            }}
                        />
                    </FormControl>
                    <FormHelperText error={errorText !== ''} sx={{ width: '60%' }}>
                        {errorText}
                    </FormHelperText>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
