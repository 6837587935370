import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import Highcharts, { PointOptionsObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useLazyGetClientFacilitySummaryQuery, useLazyGetGlobalFacilitySummaryQuery } from '../../../store/apis/client-api';
import LoadingIndicator from '../../core/LoadingIndicator';
import { FacilityDto } from '../../../models';
import { FacilitySummaryDto } from '../../../models/FacilitySummaryDto';
import { formatCurrency } from '../../../util';

export interface IExecutiveSummariesFacilitiesCardProps {
    clientId?: string;
    facility?: FacilityDto;
}

export const ExecutiveSummariesFacilitiesCard: FunctionComponent<IExecutiveSummariesFacilitiesCardProps> = (props) => {
    const { clientId, facility } = props;
    const [currentFacility, setCurrentFacility] = useState<FacilityDto>();
    const [showSquareFootage, setShowSquareFootage] = useState(true);
    const [shouldResetGraphs, setShouldResetGraphs] = useState(true);
    const [getClientFacilitySummary, { isLoading: isClientFacilitySummaryLoading }] = useLazyGetClientFacilitySummaryQuery();
    const [getGlobalFacilitySummary, { isLoading: isGlobalFacilitySummaryLoading }] = useLazyGetGlobalFacilitySummaryQuery();
    const [summary, setSummary] = useState<FacilitySummaryDto>();
    const [conditionIndexOptions, setConditionIndexOptions] = useState<Highcharts.Options>({
        title: {
            text: 'Condition Index',
            align: 'center',
            verticalAlign: 'bottom',
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },
        legend: {
            align: 'left',
            verticalAlign: 'bottom',
            enabled: true,
            layout: 'vertical',
            floating: true,
            y: -24,
            backgroundColor: 'rgba(255, 255, 255, .4)',
        },
        series: [
            {
                type: 'pie',
                name: '',
                data: [],
            },
        ],
    });
    const [roofSystemTypesOptions, setRoofSystemTypesOptions] = useState<Highcharts.Options>({
        title: {
            text: 'Roofs by Roof Type',
            align: 'center',
            verticalAlign: 'bottom',
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },
        legend: {
            align: 'left',
            verticalAlign: 'bottom',
            enabled: true,
            layout: 'vertical',
            floating: true,
            y: -24,
            backgroundColor: 'rgba(255, 255, 255, .4)',
        },
        series: [
            {
                type: 'pie',
                name: '',
                data: [],
            },
        ],
    });

    const getSummaryDataList = useCallback(
        (type: number, summaryData?: any) => {
            return {
                name: `${type === 1 ? summaryData?.roofSystem : summaryData?.rating ?? 'None'} (${
                    showSquareFootage ? summaryData?.squareFootage.toLocaleString('en-US') : summaryData?.count
                })`,
                y: showSquareFootage ? summaryData?.squareFootage : summaryData.count,
                color: summaryData?.colorCode,
            } as PointOptionsObject;
        },
        [showSquareFootage]
    );

    useEffect(() => {
        if (!shouldResetGraphs && currentFacility?.id !== facility?.id) {
            setCurrentFacility(facility);
            setShouldResetGraphs(true);
        }
    }, [currentFacility, facility, shouldResetGraphs]);

    useEffect(() => {
        const renderDataFunction = (data: FacilitySummaryDto | undefined) => {
            if (data) {
                setSummary(data);
                setConditionIndexOptions({
                    ...conditionIndexOptions,
                    series: [
                        {
                            type: 'pie',
                            name: '',
                            data: data.conditionIndexSummary.map((__, idx) => getSummaryDataList(0, data.conditionIndexSummary[idx])),
                        },
                    ],
                });

                setRoofSystemTypesOptions({
                    ...roofSystemTypesOptions,
                    series: [
                        {
                            type: 'pie',
                            name: '',
                            data: data.roofSystemTypeSummary.map((__, idx) => getSummaryDataList(1, data.roofSystemTypeSummary[idx])),
                        },
                    ],
                });
                setShouldResetGraphs(false);
            }
        };

        if (!summary || (shouldResetGraphs && !isClientFacilitySummaryLoading && !isGlobalFacilitySummaryLoading)) {
            if (clientId) {
                getClientFacilitySummary({
                    clientId: clientId,
                    facilityId: facility?.id,
                }).then((response) => renderDataFunction(response.data));
            } else {
                getGlobalFacilitySummary().then((response) => renderDataFunction(response.data));
            }
        }
    }, [
        clientId,
        conditionIndexOptions,
        facility,
        getClientFacilitySummary,
        getGlobalFacilitySummary,
        getSummaryDataList,
        isGlobalFacilitySummaryLoading,
        isClientFacilitySummaryLoading,
        roofSystemTypesOptions,
        shouldResetGraphs,
        summary,
    ]);

    const handleChartViewClick = useCallback(
        (value: boolean) => () => {
            setShouldResetGraphs(true);
            setSummary(undefined);
            setShowSquareFootage(value);
        },
        []
    );

    if (isClientFacilitySummaryLoading || isGlobalFacilitySummaryLoading || shouldResetGraphs) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={1} sx={{ padding: '8px 32px' }}>
            <Grid item container direction='row' justifyContent='center'>
                <Grid item container direction='row' xs='auto' spacing={1}>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>View by:</Typography>
                    </Grid>
                    <Grid item>
                        {!showSquareFootage ? (
                            <Link color='secondary' underline='hover' onClick={handleChartViewClick(true)} sx={{ cursor: 'pointer' }}>
                                Square Footage
                            </Link>
                        ) : (
                            <Typography>Square Footage</Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Typography>|</Typography>
                    </Grid>
                    <Grid item>
                        {showSquareFootage ? (
                            <Link color='secondary' underline='hover' onClick={handleChartViewClick(false)} sx={{ cursor: 'pointer' }}>
                                Roof Section
                            </Link>
                        ) : (
                            <Typography>Roof Section</Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='row' sx={{ paddingBottom: '24px' }}>
                <Grid item xs={6}>
                    <HighchartsReact highcharts={Highcharts} options={conditionIndexOptions} updateArgs={[true, true, true]} />
                </Grid>
                <Grid item xs={6}>
                    <HighchartsReact highcharts={Highcharts} options={roofSystemTypesOptions} updateArgs={[true, true, true]} />
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='space-between' sx={{ paddingBottom: '12px' }}>
                <Grid item>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Total Number of Roofs: {summary?.numberOfRoofs}</Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                        Total Square Footage: {summary?.totalSquareFootage.toLocaleString('en-US')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Total Asset Value: {formatCurrency(summary?.totalAssetValue ?? 0)}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Typography sx={{ fontSize: '12px' }}>
                    *Roof Condition Indexing is based on 10 key components: Leak History, Importance Factor, Age, Flashing Condition, Roof Covering Condition,
                    Slope and Drainage, Constructability, Durability, Damage Susceptibility, &amp; Attachment
                </Typography>
            </Grid>
        </Grid>
    );
};
