import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetOpenDispatchesForContractorQuery } from '../../../store/apis/dispatch-api';
import { getStatusRow } from '../../Forms/DispatchStatusIcons';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { IOpenDispatchesCard } from './types';
import { DispatchListViewDto } from '../../../models/DispatchListViewDto';


const defaultPaginationProps = {
    sortKey: 'SCHEDULED',
    sortAsc: true,
    page: 0,
    pageSize: 10
};

export const OpenDispatchesCardContent: FC<IOpenDispatchesCard> = ({ contractorId }) => {
    const navigate = useNavigate();
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>(defaultPaginationProps);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const { data, isLoading, error, refetch: refetchWorkOrderDispatches } = useGetOpenDispatchesForContractorQuery({
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: false,
    });

    const tableColumns: DataTableColumn<DispatchListViewDto>[] = useMemo(() => [
        { key: 'scheduled', label: 'Scheduled', sortKey: 'SCHEDULED', fieldMapper: (row) => row.scheduledDate ? format(new Date(row.scheduledDate.toString()), 'M/d/yyyy') : '' },
        { key: 'typeName', label: 'Type', sortKey: '', unsorted: true },
        { key: 'woNumber', label: 'WO #', sortKey: 'WO_NUMBER' },
        { key: 'facility', label: 'Facility', sortKey: 'FACILITY', fieldMapper: (row) => row.facilityName ?? '' },
        { key: 'urgency', label: 'Urgency', sortKey: 'URGENCY' },
        { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: (row) => row.status ? getStatusRow(row.status) : '' },
    ], []);

    const getRowStyle = useCallback((row: any) => {
        return row.status && row.status === "Needs Attention" ? { color: '#CC0000' } : {};
    }, []);

    const handleRowClick = useCallback((id: string) => {
        navigate(`/submitDispatch/${id}`);
    }, [navigate]);

    if (isLoading) {
        return <LoadingIndicator />;
    }
    if (error) {
        <ApiError onReloadClick={refetchWorkOrderDispatches} />;
    }
    return (
        <Box px={2}>
            <Grid container direction='row'>
                <Grid container item xs={12} direction='column' overflow='auto' pr={2}>
                    <Grid item xs={'auto'}>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isLoading}
                            queryResults={data}
                            defaultSortKey='SCHEDULED'
                            setPagination={setPaginatedProps}
                            selected={selected}
                            setSelected={setSelected}
                            isDashboardStyle={true}
                            onRowClick={handleRowClick}
                            customRowStyling={getRowStyle}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent={'end'}></Grid>
            </Grid>
        </Box>
    );
};
