import { FacilityDto } from '../../../models';
import { BinderPortfolioReportFacilityDto } from '../../../models/BinderPortfolioReportFacilityDto';
import { ITransferListItem } from '../../core/BasicTransferList';

export function convertFacilitiesToTransferListItems(items: FacilityDto[]): ITransferListItem[] {
    return items.map((item) => ({
        label: item.name ?? '',
        value: item.id,
    }));
}

export function convertBinderPortfolioReportFacilitiesToTransferListItems(items: BinderPortfolioReportFacilityDto[]): ITransferListItem[] {
    return items.map((item) => ({
        label: item.facility?.name ?? '',
        value: item.facilityId,
    }));
}

export function convertTransferListItemsToDtos(items: ITransferListItem[]): BinderPortfolioReportFacilityDto[] {
    return items.map((item) => ({
        facilityId: item.value,
    }));
}
