import { DocumentSearchQueryParams, serverApi } from '..';
import { DocumentDto, CloudStorageLink, QueryResultsDto } from '../../models';
import { DocumentVisibilityChangeDto } from '../../models/DocumentVisibilityChangeDto';
import { buildSearchQueryRoute } from '../../util';

const documentApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDocuments: builder.query<QueryResultsDto<DocumentDto>, DocumentSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`document`, params),
            providesTags: [{ type: 'Document' }],
        }),
        getDocumentFileLink: builder.query<CloudStorageLink, string>({
            query: (id) => ({ url: `document/${id}/file` }),
        }),
        createDocument: builder.mutation<DocumentDto, DocumentDto>({
            query: (documentDto) => ({
                url: `document`,
                method: 'POST',
                body: documentDto,
            }),
            invalidatesTags: [{ type: 'Document' }],
        }),
        updateDocument: builder.mutation<void, DocumentDto>({
            query: (documentDto) => ({
                url: 'document',
                method: 'PUT',
                body: documentDto,
            }),
            invalidatesTags: [{ type: 'Document' }],
        }),
        archiveDocument: builder.mutation<void, string>({
            query: (id) => ({
                url: `document/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Document' }],
        }),
        deleteDocument: builder.mutation<void, string>({
            query: (id) => ({
                url: `document/${id}/hard`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Document' }],
        }),
        unarchiveDocument: builder.mutation<void, string>({
            query: (id) => ({
                url: `document/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Document' }],
        }),
        setDocumentPublic: builder.mutation<void, DocumentVisibilityChangeDto[]>({
            query: (args) => ({
                url: `document/public`,
                body: args,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Document' }],
        }),
        setDocumentPrivate: builder.mutation<void, DocumentVisibilityChangeDto[]>({
            query: (args) => ({
                url: `document/private`,
                body: args,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Document' }],
        }),
    }),
});

export const {
    useGetDocumentsQuery,
    useLazyGetDocumentsQuery,
    useGetDocumentFileLinkQuery,
    useLazyGetDocumentFileLinkQuery,
    useCreateDocumentMutation,
    useUpdateDocumentMutation,
    useArchiveDocumentMutation,
    useDeleteDocumentMutation,
    useUnarchiveDocumentMutation,
    useSetDocumentPublicMutation,
    useSetDocumentPrivateMutation,
} = documentApi;
