import { Assessment, PictureAsPdf } from '@mui/icons-material';
import {
    Autocomplete,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Link,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormButton, FormSection, IFormProps } from '..';
import AuthenticatedComponent from '../../../auth';
import { ClientDto, emptyGuid } from '../../../models';
import { BinderPortfolioReportDto } from '../../../models/BinderPortfolioReportDto';
import { ReportPdfStatus } from '../../../models/ReportPdfDto';
import { useCreateBPReportPdfMutation, useLazyGetBPReportPdfQuery } from '../../../store/apis/binder-portfolio-report-api';
import { useGetClientsQuery } from '../../../store/apis/client-api';
import { useGetClientFacilitiesQuery } from '../../../store/apis/facility-api';
import { useFailedActionSnackbar, useStandardSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ConfirmPdfDialog } from '../../core/ConfirmPdfDialog/ConfirmPdfDialog';
import LoadingIndicator from '../../core/LoadingIndicator';
import { MenuButton } from '../../core/MenuButton';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { DateTimePicker, FormInput } from '../FormFields';
import { convertBinderPortfolioReportFacilitiesToTransferListItems, convertFacilitiesToTransferListItems, convertTransferListItemsToDtos } from './BinderPortfolioFormMappers';
import { BasicTransferList, ITransferListItem } from '../../core/BasicTransferList';
import { BinderPortfolioReportFacilityDto } from '../../../models/BinderPortfolioReportFacilityDto';
import { currentVisibilityOrDefault, VisibilityConfig, VisibilityControl } from '../../CommonInputs';

export interface IBinderPortfolioFormProps extends IFormProps<BinderPortfolioReportDto> {
    clientId?: string;
}

export const BinderPortfolioForm: FunctionComponent<IBinderPortfolioFormProps> = (props) => {
    const { save, cancel, initValues, isEditView, clientId } = props;
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [name, setName] = useState(initValues ? initValues.reportName : '');
    const [visibilityConfig, setVisibilityConfig] = useState(currentVisibilityOrDefault(initValues));
    const [reportDate, setReportDate] = useState<Date | null | undefined>(initValues && initValues.reportDate ? new Date(initValues.reportDate) : new Date());
    const [reportPdf, setReportPdf] = useState(initValues?.reportPdf);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [includeBudgetPlan, setIncludeBudgetPlan] = useState(initValues?.includeBudgetPlan ?? true);
    const [selectedFacilities, setSelectedFacilities] = useState<BinderPortfolioReportFacilityDto[]>(initValues?.facilities ?? []);
    const [changed, setFormChanged] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        REPORT_DATE: '',
        CLIENT_ID: '',
    });
    const [selectedClientId, setClientId] = useState(initValues ? initValues.clientId : '');
    const [client, setClient] = useState(initValues ? initValues.client : null);
    const [clientOpen, setClientOpen] = useState(false);
    const [createReportPdf, { data: reportPdfData, isLoading: isGeneratingReport, isSuccess: isGenerateReportSuccessful, isError: isGenerateReportError, reset: resetGeneratePdf }] = useCreateBPReportPdfMutation();
    const [getReportPdf] = useLazyGetBPReportPdfQuery();
    const { data: clients, isLoading: clientsLoading } = useGetClientsQuery({ includeInactive: true });
    const { data: clientFacilities, isLoading: isLoadingClientFacilities, } = useGetClientFacilitiesQuery({
        parentId: selectedClientId,
        params: {
            pageSize: 10000,
            sortKey: 'NAME',
            sortAsc: true,
            includeInactive: true,
        },
    });

    useEffect(() => {
        if (clientId && !client && clients) {
            setClientId(clientId);
            setClient(clients.pageResults.find(x => x.id === selectedClientId));
        }
    }, [client, clientId, clients, selectedClientId]);

    const isLocked = useCallback((date?: Date) => {
        if (date && new Date() >= new Date(date)) {
            return true;
        }
        return false;
    }, []);

    const [isReportLocked] = useState(initValues?.id ? isLocked(initValues.lockDate) : false);

    const handleAssignedFacilitiesChange = useCallback((updatedFacilities: any[]) => {
        setSelectedFacilities(updatedFacilities.map((x: any, idx: number) => {
            const clientFacility = clientFacilities?.pageResults.find(y => y.id === x.facilityId);
            return { facilityId: x.facilityId, facility: clientFacility, order: idx };
        }));
    }, [clientFacilities?.pageResults]);

    const transferListCurrentItems: ITransferListItem[] = useMemo(() => {
        return convertBinderPortfolioReportFacilitiesToTransferListItems(selectedFacilities);
    }, [selectedFacilities]);

    const transferListAllItems: ITransferListItem[] = useMemo(() => {
        return convertFacilitiesToTransferListItems(clientFacilities?.pageResults ?? []);
    }, [clientFacilities]);

    const handleTransferListUpdate = useCallback(
        (updatedItems: ITransferListItem[]) => {
            handleAssignedFacilitiesChange(convertTransferListItemsToDtos(updatedItems));
        },
        [handleAssignedFacilitiesChange]
    );

    const handlePdfDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    }, []);

    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setName(event.target.value);
    }, []);

    const handleVisibilityChange = useCallback((updatedVisibility: VisibilityConfig) => {
        setFormChanged(true);
        setVisibilityConfig(updatedVisibility);
    }, []);

    const handleReportDateChange = useCallback((value: Date | null | undefined) => {
        setFormChanged(true);
        setReportDate(value);
    }, []);

    const validate =
        (fieldName: string) => {
            let isValid = false;
            if (fieldName === 'NAME') {
                if (name) {
                    fieldErrors.NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.NAME = 'Report name is required';
                    isValid = false;
                }
            } else if (fieldName === 'REPORT_DATE') {
                if (reportDate) {
                    fieldErrors.REPORT_DATE = '';
                    isValid = true;
                } else {
                    fieldErrors.REPORT_DATE = 'Report Date is required';
                    isValid = false;
                }
            } else if (fieldName === 'CLIENT_ID') {
                if (selectedClientId) {
                    fieldErrors.CLIENT_ID = '';
                    isValid = true;
                } else {
                    fieldErrors.CLIENT_ID = 'Client is required';
                    isValid = false;
                }
            }
            setFieldErrors({
                NAME: fieldErrors.NAME,
                REPORT_DATE: fieldErrors.REPORT_DATE,
                CLIENT_ID: fieldErrors.CLIENT_ID,
            });
            return isValid;
        };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const formIsValid = () => {
        let isValid = validate('NAME');
        isValid = validate('REPORT_DATE') && isValid;
        isValid = validate('CLIENT_ID') && isValid;
        return isValid;
    };

    useStandardSnackbar(isGeneratingReport, 'Report PDF Requested. Please wait while it is generated.', 'default');

    const handleGeneratePdf = useCallback(
        (event: React.SyntheticEvent) => {
            if (reportPdf && reportPdf.status === ReportPdfStatus.Created) {
                handlePdfDialog();
            } else {
                event.preventDefault();
                createReportPdf(initValues?.id ?? '');
            }
        },
        [createReportPdf, handlePdfDialog, reportPdf, initValues?.id]
    );

    const handleSave =
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            if (formIsValid()) {
                const updatedReport: BinderPortfolioReportDto = {
                    id: initValues ? initValues.id : emptyGuid,
                    isActive: isActive,
                    reportName: name ?? '',
                    reportDate: reportDate,
                    clientId: selectedClientId,
                    facilities: selectedFacilities,
                    includeBudgetPlan: includeBudgetPlan,
                    reportPdfId: initValues?.reportPdfId,
                    isVisibleToClients: visibilityConfig.isVisibleToClients,
                    isVisibleToEmployees: visibilityConfig.isVisibleToEmployees,
                    visibility: 'computed' // this is a computed field on the back-end the value set here does not matter
                };
                save(updatedReport);
                setFormChanged(false);
            }
        };

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    useEffect(() => {
        if (initValues && !reportPdf && !reportPdf && initValues.reportPdfId) {
            getReportPdf(initValues.id).then((response) => {
                if (!response.isError) {
                    setReportPdf(response.data);
                }
            });
        }
    }, [initValues, reportPdf, setReportPdf, getReportPdf]);

    useSuccessfulActionSnackbar('Generated', 'Report PDF', isGenerateReportSuccessful && reportPdfData?.status === ReportPdfStatus.Created, () => {
        setReportPdf(reportPdfData);
        resetGeneratePdf();
    });
    useFailedActionSnackbar('generate', 'Report PDF', isGenerateReportError || reportPdfData?.status === ReportPdfStatus.Error, () => {
        setReportPdf(reportPdfData);
        resetGeneratePdf();
    });

    const downloadPdf = useCallback(() => {
        if (reportPdf?.downloadUrl) {
            window.open(reportPdf?.downloadUrl);
        }
    }, [reportPdf]);

    if (isLoadingClientFacilities || clientsLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center' xs={12}>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Assessment /> {name ? name : 'New Binder Portfolio'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Client Reports', navLink: '/clientreports' }
                        ]}
                        currentPageLabel={name ? name : 'New Binder Portfolio'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={isActive} onChange={handleActiveToggleChange} />}
                            label='Active'
                            labelPlacement='start'
                            disabled={isReportLocked}
                        />
                    </Grid>
                    <Grid item>
                        {changed || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave} disabled={isReportLocked}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
                <Grid item container direction='row' xs={6} spacing={2}>
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        logic='or'
                        component={
                            <Grid item>
                                <MenuButton
                                    onClick={handleGeneratePdf}
                                    disabled={
                                        initValues?.id === emptyGuid ||
                                        (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ||
                                        isGeneratingReport ||
                                        (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ||
                                        isReportLocked
                                    }
                                    tooltip='Generate PDF'>
                                    <PictureAsPdf />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    {dialogOpen && <ConfirmPdfDialog open={dialogOpen} close={() => setDialogOpen(false)} generatePdf={() => createReportPdf(initValues?.id ?? '')} />}
                </Grid>
                {isGeneratingReport || (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ? (
                    <Grid item container xs={6} justifyContent='end' alignItems='center' wrap='nowrap'>
                        <Grid item>
                            <i>Please check again later... Your PDF is still generating</i>
                        </Grid>
                        <Grid item>
                            <LoadingIndicator />
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                {reportPdf && reportPdf.status === ReportPdfStatus.Error ? (
                    <Grid item>
                        <i style={{ color: 'red' }}>The PDF failed to generate. Please try again.</i>
                    </Grid>
                ) : (
                    <></>
                )}
                {reportPdf && reportPdf.status === ReportPdfStatus.Created && reportPdf.createdOn && !isGeneratingReport ? (
                    <Grid item container direction='column' xs={6} alignItems='end'>
                        <Grid item container direction='row' spacing={1} justifyContent='end'>
                            <Grid item>
                                <PictureAsPdf />
                            </Grid>
                            <Grid item>
                                <Link onClick={downloadPdf} sx={{ cursor: 'pointer' }}>
                                    {reportPdf.displayName}
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <i>
                                Generated on {new Date(reportPdf.updatedOn ? reportPdf.updatedOn : reportPdf.createdOn).toLocaleDateString()} at{' '}
                                {new Date(reportPdf.updatedOn ? reportPdf.updatedOn : reportPdf.createdOn).toLocaleString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                })}
                            </i>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' columnSpacing={2}>
                        <Grid item xs={3}>
                            <FormInput
                                value={name}
                                onChange={handleNameChange}
                                onBlur={onFieldBlur('NAME')}
                                disabled={isReportLocked}
                                label='Report Name'
                                name='reportName'
                                error={fieldErrors.NAME !== ''}
                                errorText={fieldErrors.NAME}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item container xs={3}>
                            <FormControl error={fieldErrors.REPORT_DATE !== ''} fullWidth required>
                                <DateTimePicker
                                    label='Report Date'
                                    value={reportDate}
                                    onChange={handleReportDateChange}
                                    dateOnly={true}
                                    onBlur={onFieldBlur('REPORT_DATE')}
                                    onAcceptDate={() => {
                                        setFieldErrors({
                                            ...fieldErrors,
                                            REPORT_DATE: '',
                                        });
                                    }}
                                    disabled={isReportLocked}
                                />
                                <FormHelperText>{fieldErrors.REPORT_DATE}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl error={fieldErrors.CLIENT_ID !== ''} fullWidth required disabled={isReportLocked}>
                                <FormLabel>Owner</FormLabel>
                                <Autocomplete
                                    value={client ?? null}
                                    onChange={(_, value) => {
                                        setClient(value);
                                        setClientId(value?.id ?? '');
                                        setFormChanged(true);
                                    }}
                                    open={clientOpen}
                                    onOpen={() => setClientOpen(true)}
                                    onClose={() => setClientOpen(false)}
                                    options={clients?.pageResults.filter((option) => option.isActive || option.id === client?.id) ?? []}
                                    loading={clientsLoading}
                                    disabled={isReportLocked || isEditView}
                                    onBlur={onFieldBlur('CLIENT_ID')}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option: ClientDto) => `${option.name}${option.isActive ? '' : ' (Inactive)'}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {clientsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                <FormHelperText>{fieldErrors.CLIENT_ID}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <VisibilityControl gridProps={{ xs: 3 }} value={visibilityConfig} onValueChange={handleVisibilityChange} disabled={isReportLocked} />
                    </Grid>
                    <Grid item container direction='column' spacing={2}>
                        <Grid item>
                            <FormLabel>Include in Report</FormLabel>
                            <BasicTransferList
                                targetColumnName='Selected Facilities'
                                sourceColumnName='Available Facilities'
                                currentItems={transferListCurrentItems}
                                allItems={transferListAllItems}
                                onCurrentItemsChanged={handleTransferListUpdate}
                                orderable
                                disabled={isReportLocked}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={includeBudgetPlan}
                                    onChange={(() => {
                                        setIncludeBudgetPlan(!includeBudgetPlan);
                                        setFormChanged(true);
                                    })}
                                    disabled={isReportLocked}
                                />
                            } label='5 Year Budget Plan' />

                        </Grid >
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
