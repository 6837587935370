import { AcceptedDispatchesSearchQueryParams, DispatchAcceptQueryParams, DispatchNeedsAttentionQueryParams, DispatchRejectionQueryParams, SearchQueryParams, WorkOrderDispatchesSearchQueryParams, serverApi } from '..';
import { QueryResultsDto, ReportPdfDto, WorkOrderDispatchDto } from '../../models';
import { DispatchListViewDto } from '../../models/DispatchListViewDto';
import { DispatchRequestDto } from '../../models/DispatchRequestDto';
import { MGOrRSReportDispatchDto } from '../../models/MGOrRSReportDispatchDto';
import { buildSearchQueryRoute } from '../../util';

const dispatchApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDispatches: builder.query<QueryResultsDto<DispatchListViewDto>, WorkOrderDispatchesSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('dispatch', params),
            providesTags: [{ type: 'Dispatch' }],
        }),
        getPendingDispatchesForContractor: builder.query<QueryResultsDto<DispatchListViewDto>, SearchQueryParams>({
            query: (params) => ({
                url: `dispatch/myPending`,
                method: 'GET',
                params,
            }),
            providesTags: [{ type: 'Dispatch' }],
        }),
        getOpenDispatchesForContractor: builder.query<QueryResultsDto<DispatchListViewDto>, SearchQueryParams>({
            query: (params) => ({
                url: `dispatch/myOpen`,
                method: 'GET',
                params,
            }),
            providesTags: [{ type: 'Dispatch' }],
        }),
        getDispatch: builder.query<WorkOrderDispatchDto | MGOrRSReportDispatchDto, string>({
            query: (id) => ({ url: `dispatch/${id}` }),
            providesTags: [{ type: 'Dispatch' }],
        }),
        getDispatchDefaultContactId: builder.query<string | undefined, string>({
            query: (id) => ({ url: `dispatch/${id}/defaultContactId` }),
            providesTags: [{ type: 'Dispatch' }, { type: 'Contact' }, { type: 'User' }],
        }),
        createDispatch: builder.mutation<void, DispatchRequestDto>({
            query: (DispatchRequestDto) => ({
                url: 'dispatch',
                method: 'POST',
                body: DispatchRequestDto,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Work Order Status History' }, { type: 'Report' }, { type: 'Work Order' }],
        }),
        recallDispatch: builder.mutation<void, string>({
            query: (id) => ({
                url: `dispatch/${id}/recall`,
                method: 'PATCH',
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Report' }, { type: 'Work Order' }],
        }),
        unlockDispatch: builder.mutation<void, string>({
            query: (id) => ({
                url: `dispatch/${id}/unlock`,
                method: 'PATCH',
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Work Order Status History' }],
        }),
        acceptDispatch: builder.mutation<void, DispatchAcceptQueryParams>({
            query: (params) => ({
                url: `dispatch/${params.id}/accept`,
                method: 'PATCH',
                params,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Work Order Status History' }],
        }),
        rejectDispatch: builder.mutation<void, DispatchRejectionQueryParams>({
            query: (params) => ({
                url: `dispatch/${params.id}/reject`,
                method: 'PATCH',
                params,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Work Order Status History' }],
        }),
        createDispatchPdf: builder.mutation<ReportPdfDto, string>({
            query: (dispatchId) => ({
                url: `dispatch/${dispatchId}/pdf`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Dispatch' }],
        }),
        getAcceptedDispatches: builder.query<QueryResultsDto<DispatchListViewDto>, AcceptedDispatchesSearchQueryParams>({
            query: (params) => ({
                url: `dispatch/accepted`,
                method: 'GET',
                params,
            }),
            providesTags: [{ type: 'Dispatch' }],
        }),
        getDispatchPdf: builder.query<ReportPdfDto, string>({
            query: (dispatchId) => ({ url: `dispatch/${dispatchId}/reportPdf` }),
            providesTags: [{ type: 'Dispatch' }],
        }),
        requestDispatchNeedsAttention: builder.mutation<void, DispatchNeedsAttentionQueryParams>({
            query: (params) => ({
                url: `dispatch/${params.id}/needsAttention`,
                method: 'PATCH',
                params,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Work Order Status History' }],
        }),
        closeOutDispatch: builder.mutation<string, { dispatchId: string, shouldCopyOverDispatchPhotos: boolean }>({
            query: ({ dispatchId, shouldCopyOverDispatchPhotos }) => ({
                url: `dispatch/${dispatchId}/closeOut?shouldCopyOverDispatchPhotos=${shouldCopyOverDispatchPhotos}`,
                method: 'POST'
            }),
            invalidatesTags: [{ type: 'Report' }, { type: 'Dispatch' }]
        }),
    }),
});

export const {
    useGetDispatchesQuery,
    useGetPendingDispatchesForContractorQuery,
    useGetOpenDispatchesForContractorQuery,
    useGetDispatchQuery,
    useGetDispatchDefaultContactIdQuery,
    useCreateDispatchMutation,
    useRecallDispatchMutation,
    useUnlockDispatchMutation,
    useAcceptDispatchMutation,
    useRejectDispatchMutation,
    useCreateDispatchPdfMutation,
    useLazyGetAcceptedDispatchesQuery,
    useLazyGetDispatchPdfQuery,
    useRequestDispatchNeedsAttentionMutation,
    useCloseOutDispatchMutation
} = dispatchApi;
