import { FormLabel, OutlinedInput, FormHelperText, FormControl } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { FunctionComponent } from "react";
import { IFormInputProps } from "./types";

export const FormInput: FunctionComponent<IFormInputProps> = React.memo(props => {
    const {
        disabled,
        error,
        errorText,
        fullWidth,
        label,
        labelFontSize,
        name,
        onBlur,
        onChange,
        required,
        rows,
        value,
        sx,
        debounceTime = 300,
        maxLength,
        placeholder
    } = props;

    const [fieldValue, setFieldValue] = useState(value);
    const [currentCharacterCount, setCurrentCharacterCount] = useState(0);

    useEffect(() => {
        setFieldValue(value);
        setCurrentCharacterCount(value?.length ?? 0);
    }, [value]);

    const debouncedHandleChange = useMemo(
        () => debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event);
        }, debounceTime)
        , [debounceTime, onChange]);

    const remainingCharacters = useMemo(() => {
        if (maxLength) {
            return `${Math.max(maxLength - (currentCharacterCount), 0)} characters remaining`;
        }
        return '';
    }, [currentCharacterCount, maxLength]);

    return (
        <FormControl error={error} fullWidth={fullWidth} required={required} disabled={disabled}>
            <FormLabel sx={{ fontSize: labelFontSize }}>{label}</FormLabel>
            <OutlinedInput
                disabled={disabled}
                name={name}
                onBlur={onBlur}
                onChange={
                    (event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(event.target.value);
                        debouncedHandleChange(event);
                        setCurrentCharacterCount(event.target.value?.length ?? 0);
                    }
                }
                required={required}
                multiline={!!rows}
                rows={rows}
                value={fieldValue ?? null}
                sx={sx}
                inputProps={{ maxLength }}
                placeholder={placeholder}
            />
            {maxLength ? <FormHelperText>{remainingCharacters}</FormHelperText> : <></>}
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
});