import { Description, FilterList, Search } from '@mui/icons-material';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { ClientDto, FacilityDto, WorkOrderListViewDto, WorkOrderStatusDto, WorkOrderTypeDto } from '../../../models';
import { ContractorDto } from '../../../models/ContractorDto';
import { useGetWorkOrdersQuery } from '../../../store/apis/work-order-api';
import { formatCurrency } from '../../../util';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { FilterWorkOrdersAndCapitalProjectsDialog } from '../../Dashboard/ClientDashboard';
import { ISelectWorkOrdersDialog } from './types';
import { usePermissionChecker } from '../../../Hooks';

export const SelectWorkOrdersDialog: FunctionComponent<ISelectWorkOrdersDialog> = React.memo((props) => {
    const { open, onCancel, onConfirm, setWorkOrders, isMultipleSelectable, currentWorkOrders, workOrderType = 0 } = props;
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [showInactive, setShowInactive] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [statusFilter, setStatusFilter] = useState<WorkOrderStatusDto>();
    const [dispatchDateAfterFilter, setDispatchDateAfterFilter] = useState<Date | null | undefined>(null);
    const [dispatchDateBeforeFilter, setDispatchDateBeforeFilter] = useState<Date | null | undefined>(null);
    const [contractorFilter, setContractorFilter] = useState<ContractorDto>();
    const [clientFilter, setClientFilter] = useState<ClientDto | null>();
    const [facilityFilter, setFacilityFilter] = useState<FacilityDto | null>();
    const [typeFilter, setTypeFilter] = useState<WorkOrderTypeDto>();
    const [filterOpen, setFilterOpen] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const {
        data,
        isLoading,
        error,
        refetch: refetchWorkOrder,
    } = useGetWorkOrdersQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: false,
        dispatchDateAfter: dispatchDateAfterFilter?.toISOString(),
        dispatchDateBefore: dispatchDateBeforeFilter?.toISOString(),
        contractorId: contractorFilter?.id,
        clientId: clientFilter?.id,
        facilityId: facilityFilter?.id,
        workOrderTypeId: typeFilter?.id,
        status: statusFilter?.id,
        orderType: workOrderType,
    });

    useEffect(() => {
        if (data) {
            var previouslySelectedIndexes = currentWorkOrders.map((currentSection) => data.pageResults.findIndex((y) => y.id === currentSection.id));
            setSelected(previouslySelectedIndexes);
        }
    }, [currentWorkOrders, data, open]);

    const { userHasPermission } = usePermissionChecker();
    const userCanViewWorkOrderCosts = useMemo(() => userHasPermission('read:workOrderCosts'), [userHasPermission]);
    const tableColumns: DataTableColumn<WorkOrderListViewDto>[] = useMemo(() => {
        const columns: DataTableColumn<WorkOrderListViewDto>[] = [];
        const addColumn = (key: string, label: string, sortKey: string, fieldMapper?: (row: WorkOrderListViewDto) => string) =>
            columns.push({ key, label, sortKey, fieldMapper });
        
        addColumn('woNumber', 'WO #', 'WO_NUMBER');
        addColumn('facilityClientName', 'Client', 'CLIENT');
        addColumn('facilityName', 'Facility', 'FACILITY');
        addColumn('type', 'Type', 'TYPE', (row) => row.type?.name ?? '');
        addColumn('poNumber', 'PO #', 'PO_NUMBER');
        addColumn('startDate', 'Start Date', 'START_DATE', (row) => (row.startDate ? format(new Date(row.startDate.toString()), 'M/d/yyyy') : ''));
        addColumn('contractor', 'Dispatch ID', 'CONTRACTOR', (row) => row.contractor?.dispatchId ?? '');
        if (userCanViewWorkOrderCosts) {
            addColumn('cost', 'Cost', 'COST', (row) => formatCurrency(row.cost));
        }
        addColumn('status', 'Status', 'STATUS', (row) => row.status?.name ?? '');
        return columns;
    }, [userCanViewWorkOrderCosts]);

    const beforeClose = () => {
        onCancel();
    };

    const handleSelect = () => {
        var selectedSections = selected?.map((index) => {
            return data?.pageResults[index];
        });
        if (setWorkOrders) {
            setWorkOrders(selectedSections);
        }
        onConfirm(selectedSections[0]);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleFilter = () => {
        setFilterOpen(true);
    };

    const handleDispatchAfterFilterDelete = useCallback(() => {
        setDispatchDateAfterFilter(undefined);
    }, []);

    const handleDispatchBeforeFilterDelete = useCallback(() => {
        setDispatchDateBeforeFilter(undefined);
    }, []);

    const handleStatusFilterDelete = () => {
        setStatusFilter(undefined);
    };

    const handleDispatchFilterDelete = () => {
        setContractorFilter(undefined);
    };

    const handleClientFilterDelete = () => {
        setClientFilter(undefined);
    };

    const handleFacilityFilterDelete = () => {
        setFacilityFilter(undefined);
    };

    const handleTypeFilterDelete = () => {
        setTypeFilter(undefined);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetchWorkOrder} />;
    }

    return (
        <Dialog maxWidth='xl' fullWidth open={open}>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <Description />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Work Orders
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' pt={4} spacing={2}>
                    <Grid container item direction='row' justifyContent={'end'} alignItems={'center'} xs={6} spacing={4}>
                        <Grid item>
                            <FormControlLabel
                                control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                label='Show Inactive'
                                labelPlacement='start'
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title={'Filter'}>
                                <IconButton color='primary' size='large' onClick={handleFilter}>
                                    <FilterList fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id='search-box'
                                value={searchText}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Search'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' alignItems='center' xs={6} spacing={1} sx={{ height: '100%', paddingLeft: '12px', marginTop: '0px' }}>
                        {dispatchDateAfterFilter && (
                            <Grid item>
                                <Chip label={`Dispatch Date After: ${dispatchDateAfterFilter.toLocaleDateString()}`} color='secondary' onDelete={handleDispatchAfterFilterDelete} />
                            </Grid>
                        )}
                        {dispatchDateBeforeFilter && (
                            <Grid item>
                                <Chip label={`Dispatch Date Before: ${dispatchDateBeforeFilter.toLocaleDateString()}`} color='secondary' onDelete={handleDispatchBeforeFilterDelete} />
                            </Grid>
                        )}
                        {statusFilter && (
                            <Grid item>
                                <Chip label={`Status: ${statusFilter.name}`} color='secondary' onDelete={handleStatusFilterDelete} />
                            </Grid>
                        )}
                        {contractorFilter && (
                            <Grid item>
                                <Chip label={`Dispatch ID: ${contractorFilter.dispatchId}`} color='secondary' onDelete={handleDispatchFilterDelete} />
                            </Grid>
                        )}
                        {clientFilter && (
                            <Grid item>
                                <Chip label={`Client: ${clientFilter.name}`} color='secondary' onDelete={handleClientFilterDelete} />
                            </Grid>
                        )}
                        {facilityFilter && (
                            <Grid item>
                                <Chip label={`Facility: ${facilityFilter.name}`} color='secondary' onDelete={handleFacilityFilterDelete} />
                            </Grid>
                        )}
                        {typeFilter && (
                            <Grid item>
                                <Chip label={`Type: ${typeFilter.name}`} color='secondary' onDelete={handleTypeFilterDelete} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isLoading}
                            queryResults={data}
                            defaultSortKey='WO_NUMBER'
                            defaultSortDesc
                            setPagination={setPaginatedProps}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Grid>
                    {filterOpen && (
                        <FilterWorkOrdersAndCapitalProjectsDialog
                            open={filterOpen}
                            close={() => setFilterOpen(false)}
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}
                            dispatchDateAfterFilter={dispatchDateAfterFilter}
                            dispatchDateBeforeFilter={dispatchDateBeforeFilter}
                            setDispatchDateAfterFilter={setDispatchDateAfterFilter}
                            setDispatchDateBeforeFilter={setDispatchDateBeforeFilter}
                            contractorFilter={contractorFilter}
                            setContractorFilter={setContractorFilter}
                            clientFilter={clientFilter}
                            setClientFilter={setClientFilter}
                            facilityFilter={facilityFilter}
                            setFacilityFilter={setFacilityFilter}
                            typeFilter={typeFilter}
                            setTypeFilter={setTypeFilter}
                            isCapitalProject={false}
                        />
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row'>
                    <Grid item xs={9}>
                        {!isMultipleSelectable && selected.length > 1 && <Typography color='error'>Selection is limited to one work order only.</Typography>}
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs={3} justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={selected.length === 0 || (!isMultipleSelectable && selected.length > 1)}
                                onClick={handleSelect}>
                                Select
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
});
