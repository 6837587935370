import { Box, Grid, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { AboveOrBelow, ITransferListItem, TransferListItem } from './TransferListItem';
import _ from 'lodash';

export interface ITransferListColumnProps {
    columnLabel: string;
    options: ITransferListItem[];
    selectedOptions: ITransferListItem[];
    onSelectedItemsChange: (selectedItems: ITransferListItem[]) => void;
    orderable: boolean;
    handleReorderRequest: (sourceItem: string, targetItem: string, aboveOrBelow: AboveOrBelow) => void;
    disabled?: boolean;
}

export const TransferListColumn: FC<ITransferListColumnProps> = props => {
    const { columnLabel, options, selectedOptions, onSelectedItemsChange, orderable, handleReorderRequest, disabled } = props;

    const toggleOption = useCallback((option: ITransferListItem) => {
        const isCurrentlySelected = selectedOptions.some(o => o.value === option.value);
        var updatedSelectedOptions = _.cloneDeep(selectedOptions);
        if (isCurrentlySelected) {
            updatedSelectedOptions = updatedSelectedOptions.filter(o => o.value !== option.value);
        } else {
            updatedSelectedOptions.push(option);
        }
        onSelectedItemsChange(updatedSelectedOptions);
    }, [selectedOptions, onSelectedItemsChange]);

    const renderListItems = useMemo(() => {
        return options.map(option => <TransferListItem listItem={option} onClick={toggleOption} isSelected={selectedOptions.some(o => o.value === option.value)} orderable={orderable} handleReorderRequest={handleReorderRequest} disabled={disabled} />);
    }, [options, toggleOption, selectedOptions, orderable, handleReorderRequest, disabled]);

    return (
        <Grid item flexGrow={1} border='1px solid #AAAAAA' borderRadius={1} height='100%' overflow='hidden'>
            <Box width='100%' textAlign='center' borderBottom='1px solid #AAAAAA'>
                <Typography>{columnLabel}</Typography>
            </Box>
            <Box height='calc(100% - 24px)' overflow='auto' >
                {renderListItems}
            </Box>
        </Grid>
    );
};