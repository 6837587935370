import { AddCircle, ChevronLeft, Send } from '@mui/icons-material';
import { Alert, Button, DialogActions, DialogContent, FormControl, FormHelperText, FormLabel, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AddContactDialog } from '../../../../Views/Contact';
import { WorkOrderDto } from '../../../../models';
import { ContactDto } from '../../../../models/ContactDto';
import { DispatchRequestDto } from '../../../../models/DispatchRequestDto';
import { DispatchType } from '../../../../models/DispatchType';
import { useLazyGetContactsQuery } from '../../../../store/apis/contact-api';
import { useFailedActionSnackbar } from '../../../../util/customHooks';
import { isEmail } from '../../../../util/isEmail';
import { IEntityAutocomplete } from '../../../core/IEntityAutocomplete';
import LoadingIndicator from '../../../core/LoadingIndicator';
import { FormInput } from '../../FormFields';
import { DispatchInformation } from './DispatchInformation';

export interface ISendDispatchDialogProps {
    onClose: (action: string) => void;
    workOrder?: WorkOrderDto;
    createDispatch: (dispatchRequest: DispatchRequestDto) => void;
    reportDetails?: {
        id: string;
        lockDate?: Date;
    };
    onBackClicked?: () => void;
}

export const SendDispatchDialog: FC<ISendDispatchDialogProps> = React.memo(({ workOrder, onClose, createDispatch, reportDetails, onBackClicked }) => {
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState<ContactDto | null>(null);
    const [urgency, setUrgency] = useState('');
    const [nte, setNTE] = useState('');
    const [otherDescription, setOtherDescription] = useState('');
    const [problemDescription, setProblemDescription] = useState(workOrder?.problemDescription ?? '');
    const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false);
    const dispatchType = useMemo(() => (reportDetails?.id ? DispatchType.MGOrRSReport : DispatchType.CloseOut), [reportDetails?.id]);
    const isOtherDescriptionRequired = nte === 'Other';
    const urgencyOptions = ['48 Hours', 'Emergency', 'Schedule ASAP'];
    const nteOptions = ['$1,000', '$1,500', 'Per Proposal', 'Repeat', 'Other'];

    const [fieldErrors, setFieldErrors] = useState({
        CONTACT: '',
        EMAIL: '',
        PROBLEM_DESCRIPTION: '',
        OTHER_DESCRIPTION: '',
    });

    const [getContacts, { data: contacts, isSuccess: contactsSuccess, isLoading: contactsLoading, isError: isGetContactsError }] = useLazyGetContactsQuery();

    useEffect(() => {
        if (workOrder?.contractorId) {
            getContacts({
                parentId: workOrder.contractorId,
                params: {
                    pageSize: 999999,
                    includeTechnicians: true,
                    sortKey: 'FIRST_NAME',
                    includeInactive: true,
                },
            });
        }
    }, [workOrder, getContacts]);

    useEffect(() => {
        setEmail(contact?.email ?? '');
        if (contact && contact.email && contact.email !== '') {
            setFieldErrors((f) => ({
                ...f,
                EMAIL: '',
            }));
        }
    }, [contact, contacts]);

    useEffect(() => {
        if (contactsSuccess && contacts && contacts.pageResults.length === 1 && contacts.pageResults![0].isActive) {
            setContact(contacts!.pageResults![0]);
        }
    }, [contactsSuccess, contacts]);

    useFailedActionSnackbar('retrieving', 'contacts', isGetContactsError);

    const clearOtherDescription = useCallback(() => {
        setOtherDescription('');
        setFieldErrors({
            ...fieldErrors,
            OTHER_DESCRIPTION: '',
        });
    }, [fieldErrors]);

    const handleAddContactDialogClose = useCallback(
        (newContact: ContactDto) => {
            if (newContact && workOrder && newContact.contractorId === workOrder?.contractorId) {
                setContact(newContact);
            }
            setIsAddContactDialogOpen(false);
        },
        [workOrder]
    );

    const validate = useCallback(
        (fieldName: string) => {
            let isValid = false;
            if (fieldName === 'CONTACT') {
                if (contact) {
                    fieldErrors.CONTACT = '';
                    isValid = true;
                } else {
                    fieldErrors.CONTACT = 'Technician is required';
                    isValid = false;
                }
            } else if (fieldName === 'EMAIL') {
                if (email && isEmail(email)) {
                    fieldErrors.EMAIL = '';
                    isValid = true;
                } else {
                    fieldErrors.EMAIL = 'Valid Email is required';
                    isValid = false;
                }
            } else if (fieldName === 'PROBLEM_DESCRIPTION') {
                if (problemDescription) {
                    fieldErrors.PROBLEM_DESCRIPTION = '';
                    isValid = true;
                } else {
                    fieldErrors.PROBLEM_DESCRIPTION = 'Description of problem is required';
                    isValid = false;
                }
            } else if (fieldName === 'OTHER_DESCRIPTION') {
                if (!isOtherDescriptionRequired || (isOtherDescriptionRequired && otherDescription)) {
                    fieldErrors.OTHER_DESCRIPTION = '';
                    isValid = true;
                } else {
                    fieldErrors.OTHER_DESCRIPTION = 'Other Description is required when Not to Exceed option is Other';
                    isValid = false;
                }
            }
            setFieldErrors({
                CONTACT: fieldErrors.CONTACT,
                EMAIL: fieldErrors.EMAIL,
                PROBLEM_DESCRIPTION: fieldErrors.PROBLEM_DESCRIPTION,
                OTHER_DESCRIPTION: fieldErrors.OTHER_DESCRIPTION,
            });
            return isValid;
        },
        [contact, email, fieldErrors, isOtherDescriptionRequired, otherDescription, problemDescription]
    );

    const onFieldBlur = useCallback(
        (fieldName: string) => () => {
            validate(fieldName);
        },
        [validate]
    );

    const formIsValid = useCallback(() => {
        let isValid = validate('CONTACT');
        isValid = validate('EMAIL') && isValid;
        isValid = validate('PROBLEM_DESCRIPTION') && isValid;
        isValid = validate('OTHER_DESCRIPTION') && isValid;
        return isValid;
    }, [validate]);

    const onSave = useCallback(() => {
        if (formIsValid()) {
            createDispatch({
                workOrderId: workOrder!.id,
                urgency: urgency,
                nte: nte,
                otherDescription: otherDescription,
                problemDescription: problemDescription,
                contactId: contact?.id ?? '',
                email: email,
                dispatchType: dispatchType,
                reportId: reportDetails?.id,
            });

            onClose('Dispatched');
        }
    }, [contact?.id, createDispatch, email, formIsValid, nte, onClose, otherDescription, problemDescription, urgency, workOrder, dispatchType, reportDetails?.id]);

    const isFutureDate = useCallback((dateThatThinksItIsADateButIsReallyAString: string) => {
        const date = new Date(dateThatThinksItIsADateButIsReallyAString);
        return date > new Date();
    }, []);

    if (contactsLoading) {
        return (
            <DialogContent>
                <LoadingIndicator />
            </DialogContent>
        );
    }
    return (
        <>
            <DialogContent>
                <Grid container direction='column' justifyContent='space-between' marginTop={2}>
                    <DispatchInformation workOrder={workOrder} />
                    <Grid container direction='column' spacing={2}>
                        <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel>Urgency</FormLabel>
                                    <Select value={urgency} onChange={(e) => setUrgency(e.target.value)}>
                                        <MenuItem key='none' value=''>
                                            <em>None</em>
                                        </MenuItem>
                                        {urgencyOptions.map((text) => (
                                            <MenuItem key={text} value={text}>
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel>Not to Exceed</FormLabel>
                                    <Select
                                        value={nte}
                                        onChange={(e) => {
                                            setNTE(e.target.value);
                                            clearOtherDescription();
                                        }}>
                                        <MenuItem key='none' value=''>
                                            <em>None</em>
                                        </MenuItem>
                                        {nteOptions.map((text) => (
                                            <MenuItem key={text} value={text}>
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={5}>
                                {isOtherDescriptionRequired && (
                                    <FormInput
                                        label='Other Description'
                                        onBlur={onFieldBlur('OTHER_DESCRIPTION')}
                                        value={otherDescription}
                                        fullWidth
                                        required
                                        error={fieldErrors.OTHER_DESCRIPTION !== ''}
                                        errorText={fieldErrors.OTHER_DESCRIPTION}
                                        onChange={(e) => {
                                            setOtherDescription(e.target.value);
                                        }}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={2} />
                        </Grid>
                        <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
                            <Grid item xs={12}>
                                <FormInput
                                    label='Description of Problem'
                                    onBlur={onFieldBlur('PROBLEM_DESCRIPTION')}
                                    value={problemDescription}
                                    fullWidth
                                    required
                                    error={fieldErrors.PROBLEM_DESCRIPTION !== ''}
                                    errorText={fieldErrors.PROBLEM_DESCRIPTION}
                                    onChange={(e) => {
                                        setProblemDescription(e.target.value);
                                    }}
                                    rows={8}
                                />
                            </Grid>
                        </Grid>

                        <Grid item direction='row' xs={2}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {workOrder?.contractor?.name ? 'Dispatch to ' + workOrder?.contractor?.name : ''}
                            </Typography>
                        </Grid>
                        <Grid item container direction='row' justifyContent='space-between' alignItems='center' xs={9} spacing={2}>
                            <Grid item xs={3}>
                                <FormControl fullWidth required error={fieldErrors.CONTACT !== ''}>
                                    <FormLabel>Contact</FormLabel>
                                    <IEntityAutocomplete
                                        options={contacts?.pageResults}
                                        onChange={(_e, value) => {
                                            setContact(value);
                                        }}
                                        value={contact}
                                        getOptionLabel={(option: ContactDto) => `${option.firstName} ${option.lastName}`}
                                        isLoading={contactsLoading}
                                        error={fieldErrors.CONTACT !== ''}
                                        validate={() => {
                                            onFieldBlur('CONTACT');
                                            onFieldBlur('EMAIL');
                                        }}
                                    />
                                    <FormHelperText>{fieldErrors.CONTACT}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} marginTop={2}>
                                <IconButton
                                    color='primary'
                                    size='large'
                                    onClick={() => {
                                        setIsAddContactDialogOpen(true);
                                    }}>
                                    <AddCircle fontSize='inherit' />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Email'
                                    onBlur={onFieldBlur('EMAIL')}
                                    value={email}
                                    fullWidth
                                    required
                                    error={fieldErrors.EMAIL !== ''}
                                    errorText={fieldErrors.EMAIL}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}></Grid>
                        </Grid>
                    </Grid>
                    {reportDetails?.lockDate && (
                        <Grid item mt={2}>
                            <Alert severity='warning' variant='filled'>
                                The report you are about to dispatch is {isFutureDate(reportDetails.lockDate as unknown as string) ? 'scheduled to be ' : ''}locked, because a PDF was generated. If you continue, the report will be unlocked.
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => onClose('')}>
                    Cancel
                </Button>
                {onBackClicked && (
                    <Button variant='outlined' color='primary' onClick={onBackClicked} startIcon={<ChevronLeft />}>
                        Back
                    </Button>
                )}
                <Button variant='contained' color='primary' startIcon={<Send />} onClick={onSave}>
                    Send
                </Button>
            </DialogActions>
            <AddContactDialog open={isAddContactDialogOpen} onClose={handleAddContactDialogClose} contractor={workOrder?.contractor} />
        </>
    );
});
