import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import Highcharts, { PointOptionsObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IOpenWorkOrdersCard } from '.';
import { WorkOrderListViewDto } from '../../../models';
import { useGetOpenWorkOrdersSummaryQuery, useGetWorkOrdersQuery } from '../../../store/apis/work-order-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { usePermissionChecker } from '../../../Hooks';

export const OpenWorkOrdersCard: FunctionComponent<IOpenWorkOrdersCard> = (props) => {
    const { isCapitalProjects } = props;
    const {
        data: summaryData,
        isLoading: isSummaryLoading,
        error: summaryError,
        refetch: refetchSummary,
    } = useGetOpenWorkOrdersSummaryQuery({
        orderType: isCapitalProjects ? 1 : 0,
    });
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const navigate = useNavigate();

    const {
        data,
        isLoading,
        error,
        refetch: refetchWorkOrders,
    } = useGetWorkOrdersQuery({
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: 10,
        includeInactive: false,
        orderType: isCapitalProjects ? 1 : 0,
        getOnlyOpen: true,
    });

    const { userHasPermission } = usePermissionChecker();
    const userCanViewWorkOrderCosts = useMemo(() => userHasPermission('read:workOrderCosts'), [userHasPermission]);
    const tableColumns = useMemo(() => {
        const columns: DataTableColumn<WorkOrderListViewDto>[] = [];
        const addColumn = (key: string, label: string, sortKey: string, fieldMapper?: (row: WorkOrderListViewDto) => string) =>
            columns.push({ key, label, sortKey, fieldMapper });
        addColumn('woNumber', isCapitalProjects ? 'Project #' : 'WO #', 'WO_NUMBER');
        addColumn('facilityName', 'Facility', 'FACILITY');
        addColumn('startDate', 'Start', 'START_DATE', (row) => (row.startDate ? format(new Date(row.startDate.toString()), 'M/d/yyyy') : ''));
        addColumn('dispatchId', 'Dispatch ID', 'CONTRACTOR', (row) => row.contractor?.dispatchId ?? '');
        if (userCanViewWorkOrderCosts) {
            columns.push({
                key: 'cost',
                label: 'Cost',
                sortKey: 'COST',
                align: 'right',
                fieldMapper: (row) =>
                    row.cost
                        ? row.cost.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 0,
                          })
                        : '',
            });
        }
        addColumn('status', 'Status', 'STATUS', (row) => row.status?.name ?? '');
        return columns;
    }, [isCapitalProjects, userCanViewWorkOrderCosts]);

    const getRoofSystemTypeSummaryList = useCallback((idx: number) => {
        return {
            name: `${summaryData?.workOrderStatusSummaries[idx].name}`,
            y: summaryData?.workOrderStatusSummaries[idx].count,
        } as PointOptionsObject;
    }, [summaryData?.workOrderStatusSummaries]);

    const roofSystemTypesOptions: Highcharts.Options = useMemo(() => {
        return {
            title: {
                text: ' ',
            },
            plotOptions: {
                pie: {
                    size: '75%',
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                },
            },
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 0,
                layout: 'horizontal',
            },

            series: [
                {
                    type: 'pie',
                    name: '',
                    data: summaryData?.workOrderStatusSummaries.map((__: any, idx: number) => getRoofSystemTypeSummaryList(idx)),
                },
            ],
        };
    }, [getRoofSystemTypeSummaryList, summaryData?.workOrderStatusSummaries]);

    const refetch = useCallback(() => {
        refetchSummary();
        refetchWorkOrders();
    }, [refetchSummary, refetchWorkOrders]);

    const handleTableEdit = useCallback((id: string) => {
        if (isCapitalProjects) {
            navigate(`/capitalprojects/edit/${id}`);
            return;
        }
        navigate(`/workorders/edit/${id}`);
    }, [isCapitalProjects, navigate]);

    if (isLoading || isSummaryLoading) {
        return <LoadingIndicator />;
    }
    if (error || summaryError) {
        <ApiError onReloadClick={refetch} />;
    }

    return (
        <Box px={2}>
            <Grid container direction='row'>
                <Grid item xs={3} pb={2} zIndex={2}>
                    <HighchartsReact highcharts={Highcharts} options={roofSystemTypesOptions} />
                </Grid>
                <Grid container item xs={9} direction='column' overflow='auto' pr={2}>
                    <Grid item xs={'auto'}>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isLoading}
                            queryResults={data}
                            defaultSortKey='WO_NUMBER'
                            setPagination={setPaginatedProps}
                            selected={[]}
                            setSelected={() => { }}
                            isDashboardStyle={true}
                            onEdit={handleTableEdit}
                            onRowClick={handleTableEdit}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent={'end'}></Grid>
            </Grid>
        </Box>
    );
};
