import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContractorForm } from '../../Components/Forms/ContractorForm';
import { ContractorDto } from '../../models/ContractorDto';
import { useCreateContractorMutation } from '../../store/apis/contractor-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddContractorView: FunctionComponent = () => {
    const navigate = useNavigate();
    const [createContractor, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateContractorMutation();

    useFailedCreateSnackbar('contractor', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Contractor', isCreateSuccess, () => {
        navigate(`/contractors/edit/${data?.id}`);
    });

    const handleSave = (values: ContractorDto) => {
        createContractor(values);
    };

    const handleCancel = () => {
        navigate(`/contractors`);
    };

    return <ContractorForm save={handleSave} cancel={handleCancel} />;
};
