import { BaseDto } from './BaseDto';

export interface CoreLayerTypeDto extends BaseDto {
    name: string;
    colorCode: string;
}

export const CoreLayerTypeIds = {
    DECK: 'a4e5d9b5-30d0-41d3-84e9-a59f42d34caf',
    INSULATION: '17cfb0b5-dbfa-4f22-bf23-61b42b963c78',
    ROOF_SYSTEM: 'e6fe57a8-90b2-417b-9689-b59bee1b20b8',
    SURFACING: '6cf19457-1393-4f88-89e4-a62819f99dd8'
};