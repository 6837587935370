import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { ILocalDataTableProps } from '.';
import LoadingIndicator from '../LoadingIndicator';
import { DataTableColumn } from './types';

export const LocalDataTable: FunctionComponent<ILocalDataTableProps> = (props) => {
    const { columns, rowData, loading, height, tableFooter } = props;

    const [hoverRow, setHoverRow] = useState('');

    const handleRowOnMouseEnter = (id: string) => {
        setHoverRow(id);
    };

    const handleRowOnMouseLeave = (id: string) => {
        if (hoverRow === id) setHoverRow('');
    };

    const renderHeaders = (headers: DataTableColumn<unknown>[]) => {
        return headers.map((header) => {
            return (
                <TableCell key={header.key} align={header.align}>
                    {header.label}
                </TableCell>
            );
        });
    };

    const renderFields = (row: String, headers: DataTableColumn<unknown>[]) => {
        return headers.map((header) => {
            let field: any = null;
            if (header.fieldMapper) {
                field = header.fieldMapper(row);
            } else {
                const accessors = header.key.split('.');
                field = row;
                accessors.forEach((key) => (field = field[key]));
            }
            return (
                <TableCell key={header.key} align={header.align} color={header.color}>
                    {field}
                </TableCell>
            );
        });
    };

    return (
        <Paper elevation={0} sx={{ border: 'solid 1px #AAAAAA' }}>
            <TableContainer sx={{ height: height }}>
                <Table stickyHeader sx={{ height: 'max-content' }}>
                    <TableHead>
                        <TableRow>{renderHeaders(columns)}</TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData && rowData.length > 0
                            ? rowData.map((row, index) => {
                                  return (
                                      <TableRow
                                          key={row.id}
                                          hover
                                          onMouseEnter={() => handleRowOnMouseEnter(row.id)}
                                          onMouseLeave={() => handleRowOnMouseLeave(row.id)}
                                          role='checkbox'
                                          tabIndex={-1}>
                                          {renderFields(row, columns)}
                                      </TableRow>
                                  );
                              })
                            : !loading && (
                                  <TableRow>
                                      <TableCell align='center' colSpan={columns.length + 1}>
                                          No results found.
                                      </TableCell>
                                  </TableRow>
                              )}
                        {loading && (
                            <TableRow>
                                <TableCell align='center' colSpan={columns.length + 1}>
                                    <LoadingIndicator />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    {tableFooter ? tableFooter : <></>}
                </Table>
            </TableContainer>
        </Paper>
    );
};
