import { Business } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { emptyGuid } from '../../../models';
import { useCreateWarrantorMutation } from '../../../store/apis/warrantor-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../util/customHooks';
import { IAddWarrantorDialog } from './types';
import { FormInput } from '../FormFields';

export const AddWarrantorDialog: FunctionComponent<IAddWarrantorDialog> = (props) => {
    const { open, onClose } = props;
    const [createWarrantor, { data, isSuccess: isCreateSuccess, isError: isCreateError, error: createWarrantorError, reset: resetCreate }] = useCreateWarrantorMutation();
    const [warrantorName, setWarrantorName] = useState('');
    const [errorText, setErrorText] = useState('');

    const beforeClose = useCallback(() => {
        setWarrantorName('');
        setErrorText('');
        resetCreate();
        onClose(data);
    }, [data, onClose, resetCreate]);

    useFailedCreateSnackbar('warrantor', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Warrantor', isCreateSuccess, () => {
        if (open) {
            beforeClose();
        }
    });

    useEffect(() => {
        if (createWarrantorError && 'data' in createWarrantorError) {
            if (createWarrantorError.data === 'Warrantor already exists.') {
                setErrorText('Warrantor already exists');
            }
        }
    }, [createWarrantorError]);

    const onSave = () => {
        if (warrantorName) {
            createWarrantor({
                id: emptyGuid,
                name: warrantorName,
                isActive: true,
            });
        } else {
            setErrorText('This field is required');
        }
    };

    return (
        <Dialog onClose={beforeClose} open={open} maxWidth='xs' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <Business />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        New Warrantor
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' marginTop={2}>
                    <FormInput
                        label='New Warrantor'
                        value={warrantorName}
                        fullWidth
                        onChange={(e) => {
                            setWarrantorName(e.target.value);
                        }}
                        required
                        error={errorText !== ''}
                        errorText={errorText}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
