import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, SelectChangeEvent, Switch, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { BudgetLineItemYearDto, RoofConditionDto } from '../../../models';
import { ColorSwatch } from '../../../util';
import { IEntitySelect } from '../../core/IEntitySelect';
import { FormNumberInput, patterns } from '../FormFields';
import { KeyboardReturn } from '@mui/icons-material';

export interface IBudgetLineItemYearLineErrors {
    amount: string;
    condition: string;
}

export interface IBudgetLineItemYearLineProps {
    value: BudgetLineItemYearDto;
    onChange: (updatedValue: BudgetLineItemYearDto) => void;
    errors: IBudgetLineItemYearLineErrors;
    showLabel?: boolean;
    squareFeet?: number;
    roofConditions: RoofConditionDto[];
    showCopyDown?: boolean;
    onCopyDownClicked: () => void;
}

export const BudgetLineItemYearLine: FC<IBudgetLineItemYearLineProps> = ({
    value,
    onChange,
    errors,
    showLabel,
    squareFeet,
    roofConditions,
    showCopyDown,
    onCopyDownClicked,
}) => {
    const handleMultiplierChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const updatedMultiplier = event.target.valueAsNumber;
            onChange({ ...value, multiplier: updatedMultiplier, amount: squareFeet ? Math.round(updatedMultiplier * squareFeet) : value.amount });
        },
        [value, onChange, squareFeet]
    );

    const handleAmountChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const updatedAmount = event.target.valueAsNumber;
            onChange({
                ...value,
                amount: isNaN(updatedAmount) ? undefined : Math.round(updatedAmount),
                multiplier: squareFeet && !isNaN(updatedAmount) ? parseFloat((updatedAmount / squareFeet).toFixed(5)) : 1,
            });
        },
        [value, onChange, squareFeet]
    );

    const handleConditionChange = useCallback(
        (event: SelectChangeEvent) => {
            onChange({ ...value, conditionId: event.target.value === '' ? undefined : event.target.value });
        },
        [value, onChange]
    );

    const handleImmediateRepairsChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...value, needsImmediateRepairs: event.target.checked });
        },
        [value, onChange]
    );

    return (
        <Grid item direction='row' container spacing={2} alignItems='center'>
            <Grid item xs={1}>
                <Typography>{value.year}</Typography>
            </Grid>
            <Grid item xs={2}>
                <FormNumberInput
                    value={value.multiplier}
                    onChange={handleMultiplierChange}
                    label={showLabel ? 'Multiplier' : ''}
                    labelFontSize='12px'
                    name={`year-multiplier`}
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: patterns.Decimal.input.source, style: { textAlign: 'right' } }}
                />
            </Grid>
            <Grid item xs={2}>
                <FormNumberInput
                    value={value.amount}
                    onChange={handleAmountChange}
                    label={showLabel ? 'Amount' : ''}
                    labelFontSize='12px'
                    name={`year-amount`}
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: patterns.Decimal.input.source, style: { textAlign: 'right' } }}
                    error={!!errors.amount}
                    errorText={errors.amount}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl error={!!errors.condition} fullWidth>
                    {showLabel && <FormLabel sx={{ fontSize: '12px' }}>Budget Category</FormLabel>}
                    <IEntitySelect
                        getItem={(item: RoofConditionDto) => <ColorSwatch condition={item} />}
                        value={value.conditionId ?? ''}
                        onChange={handleConditionChange}
                        menuItems={roofConditions}
                    />
                    <FormHelperText>{errors.condition}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Switch checked={value.needsImmediateRepairs} onChange={handleImmediateRepairsChange} />}
                    label='Immediate Repairs'
                    labelPlacement='start'
                />
            </Grid>
            {showCopyDown && (
                <Grid item>
                    <Button onClick={onCopyDownClicked} variant='outlined'>
                        <KeyboardReturn />
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};
