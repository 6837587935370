import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { WorkOrderAndCapitalProjectForm } from '../../Components/Forms/WorkOrderForm';
import { WorkOrderDto } from '../../models';
import { useDuplicateWorkOrderMutation, useGetWorkOrderQuery, useUpdateWorkOrderMutation } from '../../store/apis/work-order-api';
import { useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulActionSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditCapitalProjectView: FunctionComponent = () => {
    const { id, clientId, facilityId } = useParams();
    const navigate = useNavigate();
    const [woSaveError, setWoSaveError] = useState('');
    const { data, isLoading, error, refetch } = useGetWorkOrderQuery(id!);
    const [updateWorkOrder, { error: updateError, isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateWorkOrderMutation();
    useFailedUpdateSnackbar('capital project', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Capital project', isUpdateSuccess);
    const [
        duplicateWorkOrder,
        {
            data: duplicatedWorkOrderId,
            isLoading: isDuplicatingWorkOrder,
            isError: isDuplicateWorkOrderError,
            isSuccess: isDuplicateWorkOrderSuccess,
            reset: resetDuplicateWorkOrder,
        },
    ] = useDuplicateWorkOrderMutation();
    useFailedActionSnackbar('clone', 'capital project', isDuplicateWorkOrderError, resetDuplicateWorkOrder);
    useSuccessfulActionSnackbar('clone', 'capital project', isDuplicateWorkOrderSuccess, () => {
        navigate(`/capitalprojects/edit/${duplicatedWorkOrderId}`);
        resetDuplicateWorkOrder();
    });

    useEffect(() => {
        if (updateError && 'data' in updateError) {
            if (updateError.data === 'Capital Project number already exists') {
                setWoSaveError(updateError.data);
            }
        }
    }, [updateError]);

    const handleSave = (values: WorkOrderDto) => {
        updateWorkOrder(values);
    };

    const handleCancel = () => {
        navigate('/capitalprojects');
    };

    const handleClone = () => {
        if (isDuplicatingWorkOrder || !id) {
            return;
        }
        duplicateWorkOrder(id);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <WorkOrderAndCapitalProjectForm
        save={handleSave}
        cancel={handleCancel}
        initValues={data}
        woNumberError={woSaveError}
        isCapitalProject={true}
        clientId={clientId ?? undefined}
        facilityId={facilityId ?? undefined}
        handleClone={handleClone} />;
};
