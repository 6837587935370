import { Folder } from '@mui/icons-material';
import { FunctionComponent } from 'react';
import { DefaultDocumentFolderDto, emptyGuid } from '../../../models';
import { useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../../util/customHooks';
import { QuickAddDialog } from '../../core/QuickAddDialog';
import { useCreateDefaultDocumentFolderMutation, useUpdateDefaultDocumentFolderMutation } from '../../../store/apis/default-document-folder-api';

export interface IAddDefaultDocumentFolderDialogProps {
    open: boolean;
    onClose: () => void;
    initValues?: DefaultDocumentFolderDto | null;
}

export const AddDefaultDocumentFolderDialog: FunctionComponent<IAddDefaultDocumentFolderDialogProps> = (props) => {
    const { open, onClose, initValues } = props;
    const [create, { isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateDefaultDocumentFolderMutation();
    const [update, { isError: isUpdateError, isSuccess: isUpdateSuccess, reset: resetUpdate }] = useUpdateDefaultDocumentFolderMutation();

    const save = (formValue: string) => {
        if (initValues && initValues?.id !== emptyGuid) {
            update({
                id: initValues.id,
                isActive: true,
                createdOn: initValues.createdOn,
                name: formValue,
            });
        } else {
            create({
                id: emptyGuid,
                name: formValue,
                isActive: true,
            });
        }
    };

    useFailedCreateSnackbar('folder', isCreateError, resetCreate);
    useFailedUpdateSnackbar('folder', isUpdateError, resetUpdate);
    useSuccessfulCreateSnackbar('Folder', isCreateSuccess, resetCreate);
    useSuccessfulUpdateSnackbar('Folder', isUpdateSuccess, resetUpdate);

    return (
        <QuickAddDialog
            open={open}
            onClose={onClose}
            formLabel={`Name${initValues?.isRequired ? ' (Required and cannot be changed)' : ''}`}
            title={initValues?.id ? 'Edit Folder' : 'New Folder'}
            titleIcon={<Folder />}
            cancelText={"Cancel"}
            confirmText={"Save"}
            initValue={initValues?.name}
            save={save}
            disabled={initValues?.isRequired}
        />
    );
};

