import { useAuth0 } from '@auth0/auth0-react';
import { Warning } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormHelperText,
    Grid
} from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ClientDto, FacilityDto, RoofLeakDto, emptyGuid } from '../../models';
import { useCreateRoofLeakMutation } from '../../store/apis/roof-leak-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';
import { NoMapFoundError } from '../core/NoMapFoundError';
import { SvgEditor, SvgWrapper } from '../svg';
import { FormInput } from './FormFields';
import { ClientFacilityRoofPlanPicker } from './FormFields/ClientFacilityRoofPlanPicker';
import { StandardDialogHeader } from './StandardDialogHeader';
import { IReportLeakDialogProps } from './types';

export const ReportLeakDialog: FunctionComponent<IReportLeakDialogProps> = (props) => {
    const { open, onClose, selectedFacility } = props;
    const { user } = useAuth0();
    const [errorText] = useState('');
    const [facility, setFacility] = useState<FacilityDto | null | undefined>(selectedFacility ? selectedFacility : null);
    const [clientSelected, setClientSelected] = useState<ClientDto | undefined>(selectedFacility?.client ?? undefined);

    const [reportDetails, setReportDetails] = useState('');
    const [svgContents, setSvgContents] = useState<string | null | undefined>();
    const [reporterName, setReporterName] = useState(user ? user.name : '');
    const [roofLeak, setRoofLeak] = useState<RoofLeakDto>();
    const [createRoofLeak, { isSuccess: isCreateRoofLeakSuccess, isError: isCreateRoofLeakError, reset: resetCreateRoofLeak }] = useCreateRoofLeakMutation();

    const beforeClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const [fieldErrors, setFieldErrors] = useState({
        FACILITY: '',
        REPORTER_NAME: '',
        DETAILS: '',
    });

    useSuccessfulCreateSnackbar('Roof Leak', isCreateRoofLeakSuccess, () => {
        resetCreateRoofLeak();
        beforeClose();
    });
    useFailedCreateSnackbar('roof leak', isCreateRoofLeakError, resetCreateRoofLeak);

    useEffect(() => {
        if (facility && facility.roofProfiles?.length! > 0) {
            let roofProfile = facility.roofProfiles?.find((profile) => profile.isCurrent && profile.isActive);

            if (roofProfile) {
                setSvgContents(roofProfile?.svgContents ?? '');
            }
        } else {
            setSvgContents(null);
        }
    }, [facility]);

    const handleReporterNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReporterName(event.target.value);
    };

    const handleDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReportDetails(event.target.value);
    };

    const handleClear = () => {
        setRoofLeak(undefined);
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'DETAILS') {
            if (reportDetails) {
                fieldErrors.DETAILS = '';
                isValid = true;
            } else {
                fieldErrors.DETAILS = 'Report details are required';
                isValid = false;
            }
        } else if (fieldName === 'REPORTER_NAME') {
            if (reporterName) {
                fieldErrors.REPORTER_NAME = '';
                isValid = true;
            } else {
                fieldErrors.REPORTER_NAME = 'Reporter Name is Required';
                isValid = false;
            }
        } else if (fieldName === 'FACILITY') {
            if (facility) {
                fieldErrors.FACILITY = '';
                isValid = true;
            } else {
                fieldErrors.FACILITY = 'Facility is Required';
            }
        }

        setFieldErrors({
            FACILITY: fieldErrors.FACILITY,
            REPORTER_NAME: fieldErrors.REPORTER_NAME,
            DETAILS: fieldErrors.DETAILS,
        });

        return isValid;
    };

    const handleAddRoofLeak = (x: number, y: number) => {
        let roofProfileId = facility?.roofProfiles?.find((profile) => profile.isCurrent && profile.isActive)?.id;
        setRoofLeak({
            id: emptyGuid,
            isActive: true,
            createdOn: new Date(),
            facilityId: facility?.id ?? '',
            roofProfileId: roofProfileId ?? '',
            details: reportDetails ?? '',
            reporterName: reporterName ?? '',
            x: x,
            y: y
        });
    };

    const formIsValid = () => {
        let isValid = validate('DETAILS');
        isValid = validate('REPORTER_NAME') && isValid;
        isValid = validate('FACILITY') && isValid;
        return isValid;
    };

    const handleSubmitReportLeak = () => {
        if (formIsValid()) {
            let roofProfileId = facility?.roofProfiles?.find((profile) => profile.isCurrent && profile.isActive)?.id;
            createRoofLeak({
                id: emptyGuid,
                isActive: true,
                createdOn: new Date(),
                facilityId: facility?.id ?? '',
                roofProfileId: roofProfileId ?? '',
                details: reportDetails ?? '',
                reporterName: reporterName ?? '',
                x: roofLeak?.x,
                y: roofLeak?.y
            });
        }
    };

    return (
        <Dialog open={open} maxWidth='xl' fullWidth>
            <StandardDialogHeader icon={<Warning sx={{ color: 'white' }} />} title='Report Leak' />
            <DialogContent>
                <Grid container direction='column' sx={{ marginTop: '40px' }}>
                    <Grid item container direction='row' alignItems='center' spacing={2}>
                        <ClientFacilityRoofPlanPicker
                            fieldErrors={fieldErrors}
                            setFacility={setFacility}
                            facility={facility}
                            client={clientSelected}
                            setClient={setClientSelected}
                            xsContainer={8}
                            xsItems={6}
                            spacing={2}
                        />
                        <Grid item xs={4} spacing={6}>
                            <FormInput
                                value={reporterName}
                                onChange={handleReporterNameChange}
                                label='Reporter Name'
                                name='reporterName'
                                errorText={fieldErrors.REPORTER_NAME}
                                error={fieldErrors.REPORTER_NAME !== ''}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row'>
                        <FormInput
                            value={reportDetails}
                            onChange={handleDetailsChange}
                            label='Details'
                            name='details'
                            errorText={fieldErrors.DETAILS}
                            error={fieldErrors.DETAILS !== ''}
                            fullWidth
                            required
                            rows={3}
                        />
                    </Grid>
                    <Grid item container direction='row'>
                        <Grid item xs={6}></Grid>
                        <Grid
                            item
                            container
                            xs={6}
                            justifyContent={'flex-end'}
                            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '75%' }}>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={handleClear}
                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '75%' }}>
                                &nbsp;CLEAR&nbsp;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' justifyContent='center'>
                        {svgContents ? (
                            <Box sx={{ position: 'relative', height: '75%', width: '75%' }}>
                                <SvgWrapper>
                                    <SvgEditor
                                        svgContents={svgContents}
                                        colorSections={false}
                                        canReportMultipleLeaks={false}
                                        onMapSectionClick={handleAddRoofLeak}
                                        roofLeaks={roofLeak ? [roofLeak] : []}
                                        displayLeakNotice
                                    />
                                </SvgWrapper>
                            </Box>
                        ) : (
                            <Box sx={{ height: '50%', width: '50%' }}>
                                <NoMapFoundError errorText={'No map loaded for this facility.'}></NoMapFoundError>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <FormHelperText error={errorText !== ''} sx={{ width: '60%' }}>
                    {errorText}
                </FormHelperText>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={handleSubmitReportLeak}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};
