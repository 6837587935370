import React, { FunctionComponent, useCallback, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import { Person } from '@mui/icons-material';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { FormButton, FormSection } from '..';
import { emptyGuid } from '../../../models';
import { IContactFormProps } from './types';
import { isEmail } from '../../../util/isEmail';
import { FormInput } from '../FormFields';

export const ContactForm: FunctionComponent<IContactFormProps> = (props) => {
    const { save, cancel, initValues, contractor } = props;
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [firstName, setFirstName] = useState(initValues ? initValues.firstName : '');
    const [lastName, setLastName] = useState(initValues ? initValues.lastName : '');
    const [type, setType] = useState(initValues ? initValues.type : '');
    const [email, setEmail] = useState(initValues ? initValues.email : '');
    const [phone, setPhone] = useState(initValues ? initValues.phoneNumber : '');
    const [fieldErrors, setFieldErrors] = useState({
        FIRST_NAME: '',
        LAST_NAME: '',
        TYPE: '',
        EMAIL: '',
    });
    const [changed, setFormChanged] = useState(false);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    }, []);

    const handleFirstNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setFirstName(event.target.value);
    }, []);

    const handleLastNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setLastName(event.target.value);
    }, []);

    const handleTypeChange = useCallback((event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        setType(event.target.value);
    }, []);

    const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setEmail(event.target.value);
    }, []);

    const handlePhoneChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPhone(event.target.value);
    }, []);

    const validate = useCallback((fieldName: string) => {
        let isValid = false;
        if (fieldName === 'FIRST_NAME') {
            if (firstName) {
                fieldErrors.FIRST_NAME = '';
                isValid = true;
            } else {
                fieldErrors.FIRST_NAME = 'First name is required';
                isValid = false;
            }
        } else if (fieldName === 'LAST_NAME') {
            if (lastName) {
                fieldErrors.LAST_NAME = '';
                isValid = true;
            } else {
                fieldErrors.LAST_NAME = 'Last name is required';
                isValid = false;
            }
        } else if (fieldName === 'TYPE') {
            if (type) {
                fieldErrors.TYPE = '';
                isValid = true;
            } else {
                fieldErrors.TYPE = 'Type is required';
                isValid = false;
            }
        } else if (fieldName === 'EMAIL') {
            if (!email || isEmail(email)) {
                fieldErrors.EMAIL = '';
                isValid = true;
            } else {
                fieldErrors.EMAIL = 'Email is invalid';
                isValid = false;
            }
        }
        setFieldErrors({
            FIRST_NAME: fieldErrors.FIRST_NAME,
            LAST_NAME: fieldErrors.LAST_NAME,
            TYPE: fieldErrors.TYPE,
            EMAIL: fieldErrors.EMAIL,
        });
        return isValid;
    }, [email, fieldErrors, firstName, lastName, type]);

    const formIsValid = useCallback(() => {
        let isValid = validate('FIRST_NAME');
        isValid = validate('LAST_NAME') && isValid;
        isValid = validate('TYPE') && isValid;
        isValid = validate('EMAIL') && isValid;
        return isValid;
    }, [validate]);

    const onFieldBlur = useCallback((fieldName: string) => () => {
        validate(fieldName);
    }, [validate]);

    const handleSave = useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            save({
                id: initValues ? initValues.id : emptyGuid,
                isActive: isActive,
                firstName: firstName,
                lastName: lastName,
                type: type,
                email: email,
                phoneNumber: phone,
                contractorId: contractor.id,
            });
            setFormChanged(false);
        }
    }, [contractor.id, email, firstName, formIsValid, initValues, isActive, lastName, phone, save, type]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Person /> {firstName || lastName ? (firstName + ' ' + lastName).trim() : 'New Contact'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Contractors', navLink: '/contractors' },
                            { label: contractor.name, navLink: `/contractors/edit/${contractor.id}` },
                        ]}
                        currentPageLabel={firstName && lastName ? firstName + ' ' + lastName : 'New Contact'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        {changed || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='First Name'
                                onBlur={onFieldBlur('FIRST_NAME')}
                                value={firstName}
                                fullWidth
                                required
                                error={fieldErrors.FIRST_NAME !== ''}
                                errorText={fieldErrors.FIRST_NAME}
                                onChange={handleFirstNameChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Last Name'
                                onBlur={onFieldBlur('LAST_NAME')}
                                value={lastName}
                                fullWidth
                                required
                                error={fieldErrors.LAST_NAME !== ''}
                                errorText={fieldErrors.LAST_NAME}
                                onChange={handleLastNameChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth error={fieldErrors.TYPE !== ''} required>
                                <FormLabel>Type</FormLabel>
                                <Select value={type} onChange={handleTypeChange}>
                                    <MenuItem key='none' value=''>
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem key='Technician' value='Technician'>
                                        Technician
                                    </MenuItem>
                                    <MenuItem key='Other' value='Other'>
                                        Other
                                    </MenuItem>
                                </Select>
                                <FormHelperText>{fieldErrors.TYPE}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Email'
                                onBlur={onFieldBlur('EMAIL')}
                                value={email}
                                fullWidth
                                error={fieldErrors.EMAIL !== ''}
                                errorText={fieldErrors.EMAIL}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Phone'
                                value={phone}
                                fullWidth
                                onChange={handlePhoneChange}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
