export const buildSearchQueryRoute = (baseRoute: string, params: any) => {
    let route = baseRoute;
    let addedParam = false;
    if (params.searchText) {
        route = `${route}?searchText=${params.searchText}`;
        addedParam = true;
    }
    if (params.sortKey) {
        if (addedParam) {
            route = `${route}&sortKey=${params.sortKey}`;
        } else {
            route = `${route}?sortKey=${params.sortKey}`;
            addedParam = true;
        }
    }
    if (params.sortAsc != null) {
        if (addedParam) {
            route = `${route}&sortAsc=${params.sortAsc}`;
        } else {
            route = `${route}?sortAsc=${params.sortAsc}`;
            addedParam = true;
        }
    }
    if (params.page != null) {
        if (addedParam) {
            route = `${route}&page=${params.page}`;
        } else {
            route = `${route}?page=${params.page}`;
            addedParam = true;
        }
    }
    if (params.pageSize) {
        if (addedParam) {
            route = `${route}&pageSize=${params.pageSize}`;
        } else {
            route = `${route}?pageSize=${params.pageSize}`;
            addedParam = true;
        }
    }
    if (params.includeInactive != null) {
        if (addedParam) {
            route = `${route}&includeInactive=${params.includeInactive}`;
        } else {
            route = `${route}?includeInactive=${params.includeInactive}`;
            addedParam = true;
        }
    }
    if (params.orderType != null) {
        if (addedParam) {
            route = `${route}&orderType=${params.orderType}`;
        } else {
            route = `${route}?orderType=${params.orderType}`;
            addedParam = true;
        }
    }
    if (params.facilityId != null) {
        if (addedParam) {
            route = `${route}&facilityId=${params.facilityId}`;
        } else {
            route = `${route}?facilityId=${params.facilityId}`;
            addedParam = true;
        }
    }
    if (params.roofProfileId != null) {
        if (addedParam) {
            route = `${route}&roofProfileId=${params.roofProfileId}`;
        } else {
            route = `${route}?roofProfileId=${params.roofProfileId}`;
            addedParam = true;
        }
    }
    if (params.startYear != null) {
        if (addedParam) {
            route = `${route}&startYear=${params.startYear}`;
        } else {
            route = `${route}?startYear=${params.startYear}`;
            addedParam = true;
        }
    }
    if (params.dispatchDateAfter != null) {
        if (addedParam) {
            route = `${route}&dispatchDateAfter=${params.dispatchDateAfter}`;
        } else {
            route = `${route}?dispatchDateAfter=${params.dispatchDateAfter}`;
            addedParam = true;
        }
    }
    if (params.dispatchDateBefore != null) {
        if (addedParam) {
            route = `${route}&dispatchDateBefore=${params.dispatchDateBefore}`;
        } else {
            route = `${route}?dispatchDateBefore=${params.dispatchDateBefore}`;
            addedParam = true;
        }
    }
    if (params.status != null) {
        if (addedParam) {
            route = `${route}&status=${params.status}`;
        } else {
            route = `${route}?status=${params.status}`;
            addedParam = true;
        }
    }
    if (params.issuedYear != null) {
        if (addedParam) {
            route = `${route}&issuedYear=${params.issuedYear}`;
        } else {
            route = `${route}?issuedYear=${params.issuedYear}`;
            addedParam = true;
        }
    }
    if (params.type != null) {
        if (addedParam) {
            route = `${route}&type=${params.type}`;
        } else {
            route = `${route}?type=${params.type}`;
            addedParam = true;
        }
    }
    if (params.clientId != null) {
        if (addedParam) {
            route = `${route}&clientId=${params.clientId}`;
        } else {
            route = `${route}?clientId=${params.clientId}`;
            addedParam = true;
        }
    }
    if (params.getOnlyOpen != null) {
        if (addedParam) {
            route = `${route}&getOnlyOpen=${params.getOnlyOpen}`;
        } else {
            route = `${route}?getOnlyOpen=${params.getOnlyOpen}`;
            addedParam = true;
        }
    }
    if (params.dispatchId != null) {
        if (addedParam) {
            route = `${route}&dispatchId=${params.dispatchId}`;
        } else {
            route = `${route}?dispatchId=${params.dispatchId}`;
            addedParam = true;
        }
    }
    if (params.contractorId != null) {
        if (addedParam) {
            route = `${route}&contractorId=${params.contractorId}`;
        } else {
            route = `${route}?contractorId=${params.contractorId}`;
            addedParam = true;
        }
    }
    if (params.workOrderId != null) {
        if (addedParam) {
            route = `${route}&workOrderId=${params.workOrderId}`;
        } else {
            route = `${route}?workOrderId=${params.workOrderId}`;
            addedParam = true;
        }
    }
    if (params.woNumber != null) {
        if (addedParam) {
            route = `${route}&woNumber=${params.woNumber}`;
        } else {
            route = `${route}?woNumber=${params.woNumber}`;
            addedParam = true;
        }
    }
    if (params.reportId != null) {
        if (addedParam) {
            route = `${route}&reportId=${params.reportId}`;
        } else {
            route = `${route}?reportId=${params.reportId}`;
            addedParam = true;
        }
    }
    if (params.workOrderTypeId != null) {
        if (addedParam) {
            route = `${route}&workOrderTypeId=${params.workOrderTypeId}`;
        } else {
            route = `${route}?workOrderTypeId=${params.workOrderTypeId}`;
            addedParam = true;
        }
    }
    if (params.includeOnlyInstallingContractors != null) {
        if (addedParam) {
            route = `${route}&includeOnlyInstallingContractors=${params.includeOnlyInstallingContractors}`;
        } else {
            route = `${route}?includeOnlyInstallingContractors=${params.includeOnlyInstallingContractors}`;
            addedParam = true;
        }
    }
    if (params.includeNonStandard != null) {
        if (addedParam) {
            route = `${route}&includeNonStandard=${params.includeNonStandard}`;
        } else {
            route = `${route}?includeNonStandard=${params.includeNonStandard}`;
            addedParam = true;
        }
    }
    if (params.includeDispatched != null) {
        if (addedParam) {
            route = `${route}&includeDispatched=${params.includeDispatched}`;
        } else {
            route = `${route}?includeDispatched=${params.includeDispatched}`;
            addedParam = true;
        }
    }
    if (params.includeTechnicians != null) {
        if (addedParam) {
            route = `${route}&includeTechnicians=${params.includeTechnicians}`;
        } else {
            route = `${route}?includeTechnicians=${params.includeTechnicians}`;
            addedParam = true;
        }
    }
    if (params.techniciansOnly != null) {
        if (addedParam) {
            route = `${route}&techniciansOnly=${params.techniciansOnly}`;
        } else {
            route = `${route}?techniciansOnly=${params.techniciansOnly}`;
            addedParam = true;
        }
    }
    if (params.includeOther != null) {
        if (addedParam) {
            route = `${route}&includeOther=${params.includeOther}`;
        } else {
            route = `${route}?includeOther=${params.includeOther}`;
            addedParam = true;
        }
    }
    if (params.year != null) {
        if (addedParam) {
            route = `${route}&year=${params.year}`;
        } else {
            route = `${route}?year=${params.year}`;
            addedParam = true;
        }
    }
    if (params.roofSectionId != null) {
        if (addedParam) {
            route = `${route}&roofSectionId=${params.roofSectionId}`;
        } else {
            route = `${route}?roofSectionId=${params.roofSectionId}`;
            addedParam = true;
        }
    }
    if (params.reportType != null) {
        if (addedParam) {
            route = `${route}&reportType=${params.reportType}`;
        } else {
            route = `${route}?reportType=${params.reportType}`;
            addedParam = true;
        }
    }
    if (params.fromReportDate != null) {
        if (addedParam) {
            route = `${route}&fromReportDate=${params.fromReportDate}`;
        } else {
            route = `${route}?fromReportDate=${params.fromReportDate}`;
            addedParam = true;
        }
    }
    if (params.toReportDate != null) {
        if (addedParam) {
            route = `${route}&toReportDate=${params.toReportDate}`;
        } else {
            route = `${route}?toReportDate=${params.toReportDate}`;
            addedParam = true;
        }
    }
    if (params.workOrderDispatchId != null) {
        if (addedParam) {
            route = `${route}&workOrderDispatchId=${params.workOrderDispatchId}`;
        } else {
            route = `${route}?workOrderDispatchId=${params.workOrderDispatchId}`;
            addedParam = true;
        }
    }
    if (params.roofSectionReportId != null) {
        if (addedParam) {
            route = `${route}&roofSectionReportId=${params.roofSectionReportId}`;
        } else {
            route = `${route}?roofSectionReportId=${params.roofSectionReportId}`;
            addedParam = true;
        }
    }
    if (params.scheduledDate != null) {
        if (addedParam) {
            route = `${route}&scheduledDate=${params.scheduledDate}`;
        } else {
            route = `${route}?scheduledDate=${params.scheduledDate}`;
            addedParam = true;
        }
    }
    if (params.isClientDashboardView != null) {
        if (addedParam) {
            route = `${route}&isClientDashboardView=${params.isClientDashboardView}`;
        } else {
            route = `${route}?isClientDashboardView=${params.isClientDashboardView}`;
            addedParam = true;
        }
    }
    if (params.downloadFolderId != null) {
        if (addedParam) {
            route = `${route}&downloadFolderId=${params.downloadFolderId}`;
        } else {
            route = `${route}?downloadFolderId=${params.downloadFolderId}`;
            addedParam = true;
        }
    }

    return { url: route };
};
