import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { ContactForm } from '../../Components/Forms/ContactForm';
import { ContactDto } from '../../models/ContactDto';
import { useCreateContactMutation } from '../../store/apis/contact-api';
import { useGetContractorQuery } from '../../store/apis/contractor-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddContactView: FunctionComponent = () => {
    const navigate = useNavigate();
    const { contractorId } = useParams();
    const [createContact, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateContactMutation();
    const { data: contractor, isLoading: contractorLoading, isError: contractorError, refetch: contractorRefetch } = useGetContractorQuery(contractorId!);

    useFailedCreateSnackbar('contact', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Contact', isCreateSuccess, () => {
        navigate(`/contractors/${contractorId}/contacts/edit/${data?.id}`);
    });

    const handleSave = (values: ContactDto) => {
        createContact(values);
    };

    const handleCancel = () => {
        navigate(`/contractors/edit/${contractorId}`);
    };

    if (contractorLoading) {
        return <LoadingIndicator />;
    }

    if (contractorError) {
        return <ApiError onReloadClick={contractorRefetch} />;
    }

    return <ContactForm contractor={contractor!} save={handleSave} cancel={handleCancel} />;
};
