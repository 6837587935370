import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { ClientReportForm } from '../../Components/Forms/ClientReportForm/';
import { ReportDto } from '../../models/ReportDto';
import { useGetReportQuery, useUpdateReportMutation } from '../../store/apis/report-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditClientReportView: FC = () => {
    const { id, workOrderId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading: clientReportLoading, error: clientReportError, refetch: clientReportRefetch } = useGetReportQuery(id!);
    const [updateClientReport, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateReportMutation();

    useFailedUpdateSnackbar('client report', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Client report', isUpdateSuccess);

    const handleSave = (values: ReportDto) => {
        updateClientReport(values);
    };

    const handleCancel = () => {
        if (workOrderId) {
            navigate(`/workorders/edit/${workOrderId}`);
        } else {
            navigate(`/clientreports/`);
        }
    };

    if (clientReportLoading) {
        return <LoadingIndicator />;
    }

    if (clientReportError) {
        return <ApiError onReloadClick={clientReportRefetch} />;
    }

    return <ClientReportForm save={handleSave} cancel={handleCancel} initValues={data} workOrderId={workOrderId} />;
};
