import { QueryResultsDto, UserDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';
import { serverApi } from '../api';
import { SearchQueryParams } from '../types';

const userApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserByAuth0Id: builder.query<UserDto, string>({
            query: (id) => ({ url: `user/auth0UserId/${id}` }),
            providesTags: [{ type: 'User' }],
        }),
        getUsers: builder.query<QueryResultsDto<UserDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('user', params),
            providesTags: [{ type: 'User' }]
        }),
        getUser: builder.query<UserDto, string>({
            query: (id) => ({url: `user/userId/${id}`}),
            providesTags: [{type: 'User'}]
        }),
        createUser: builder.mutation<UserDto, UserDto>({
            query: (userDto) => ({
                url: 'user',
                method: 'POST',
                body: userDto
            }),
            invalidatesTags: [{ type: 'User' }]
        }),
        updateUser: builder.mutation<void, UserDto>({
            query: (userDto) => ({
                url: 'user',
                method: 'PUT',
                body: userDto
            }),
            invalidatesTags: [{ type: 'User' }]
        }),
        archiveUser: builder.mutation<void, string>({
            query: (id) => ({
                url: `user/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'User' }]
        }),
        unarchiveUser: builder.mutation<void, string>({
            query: (id) => ({
                url: `user/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'User' }]
        })
    }),
});

export const { useGetUserByAuth0IdQuery, useLazyGetUserByAuth0IdQuery, useGetUsersQuery, useCreateUserMutation, useArchiveUserMutation, useUnarchiveUserMutation, useUpdateUserMutation, useGetUserQuery } = userApi;
