import { ChevronRight, Description } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { ReportDto } from '../../../models';
import { useGetReportsQuery } from '../../../store/apis/report-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { StandardDialogHeader } from '../StandardDialogHeader';
import { ReportType } from '../types';

const TABLE_COLUMNS: DataTableColumn<ReportDto>[] = [
    {
        key: 'reportDate',
        label: 'Report Date',
        sortKey: 'REPORT_DATE',
        fieldMapper: (row) => (row.reportDate ? format(new Date(row.reportDate.toString()), 'M/d/yyyy') : ''),
    },
    { key: 'clientName', label: 'Client', sortKey: 'CLIENT' },
    { key: 'facilityName', label: 'Facility', sortKey: 'FACILITY' },
    { key: 'woNumber', label: 'WO/Cap Proj #', sortKey: 'WO_NUMBER' },
    { key: 'reportName', label: 'Report Name', sortKey: 'REPORT_NAME' },
    { key: 'reportType', label: 'Report Type', sortKey: 'REPORT_TYPE', fieldMapper: (row) => ReportType[row.reportType ?? 0] },
];

export interface IWorkOrderDispatchSelectReportDialogProps {
    open: boolean;
    onClose: () => void;
    onSelected: (report: ReportDto) => void;
    workOrderId: string;
}

export const WorkOrderDispatchSelectReportDialog: FC<IWorkOrderDispatchSelectReportDialogProps> = ({ open, onClose, onSelected, workOrderId }) => {
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const {
        data: reports,
        isLoading: isLoadingReports,
        error: errorLoadingReports,
        refetch: refetchReports,
    } = useGetReportsQuery({
        workOrderId: workOrderId,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeDispatched: false,
    });
    const [selected, setSelected] = useState<readonly number[]>([]);
    const selectedReport = useMemo(
        () => (selected.length === 1 && reports && reports.pageResults.length > selected[0] ? reports.pageResults[selected[0]] : null),
        [selected, reports]
    );

    const handleNextClick = useCallback(() => {
        if (selectedReport) {
            onSelected(selectedReport);
            setSelected([]);
        }
    }, [selectedReport, onSelected]);

    return (
        <Dialog onClose={onClose} open={open} maxWidth='lg' fullWidth>
            <StandardDialogHeader icon={<Description sx={{ color: 'white' }} />} title='Select Report to Dispatch' />
            <DialogContent>
                {isLoadingReports && <LoadingIndicator />}
                {errorLoadingReports && !isLoadingReports && <ApiError onReloadClick={refetchReports} />}
                {!isLoadingReports && !errorLoadingReports && reports && (
                    <Grid container direction='column'>
                        <Grid item pb={1} pt={2}>
                            <Typography fontWeight='bold'>Which report would you like to send out?</Typography>
                        </Grid>
                        <Grid item>
                            <PaginatedDataTable
                                columns={TABLE_COLUMNS}
                                loading={isLoadingReports}
                                queryResults={reports}
                                defaultSortKey='REPORT_DATE'
                                setPagination={setPaginatedProps}
                                selected={selected}
                                setSelected={setSelected}
                                noResultsMessage='No reports found that have not already been dispatched.'
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Box marginRight='auto'>
                    {selected.length > 1 && (
                        <Typography color='error'>
                            Selection is limited to one report only.
                        </Typography>
                    )}
                </Box>
                <Button variant='outlined' color='primary' onClick={onClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={handleNextClick} disabled={!selectedReport} startIcon={<ChevronRight />}>
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};
