import { Delete, History, MoreVert, Search } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { format } from 'date-fns/esm';
import { FunctionComponent, useState } from 'react';
import AuthenticatedComponent from '../../../auth';
import { RoofSectionChangeLogDto } from '../../../models';
import {
    useArchiveRoofSectionChangeLogMutation,
    useGetRoofSectionChangeLogsQuery,
    useUnarchiveRoofSectionChangeLogMutation
} from '../../../store/apis/roof-section-api';
import { ColorSwatch } from '../../../util';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { StandardDialogHeader } from '../StandardDialogHeader';
import { IRoofSectionHistoryDialogProps } from './types';

export const RoofSectionHistoryDialog: FunctionComponent<IRoofSectionHistoryDialogProps> = (props) => {
    const { roofSection, open, onClose } = props;
    const [searchText, setSearchText] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [archiveRoofSection, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveRoofSectionChangeLogMutation();
    const [unarchiveRoofSection, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveRoofSectionChangeLogMutation();

    const tableColumns: DataTableColumn<RoofSectionChangeLogDto>[] = [
        {
            key: 'modifiedOn',
            label: 'Date',
            sortKey: 'MODIFIED_ON',
            fieldMapper: (row) => (row.modifiedOn ? format(new Date(row.modifiedOn.toString()), 'M/d/yyyy hh:mm a') : ''),
        },
        {
            key: 'roofCondition',
            label: 'Condition Index',
            sortKey: 'CONDITION',
            fieldMapper: (row) => (row.roofCondition ? <ColorSwatch condition={row.roofCondition} fontSize={'.875rem'} /> : ''),
        },
        {
            key: 'recommendedAction',
            label: 'Recommended Corrective Action',
            sortKey: 'CONDITION',
            fieldMapper: (row) => (row.roofCondition ? row.roofCondition?.recommendedAction : ''),
        },
        {
            key: 'user',
            label: 'User',
            sortKey: 'USER',
            fieldMapper: (row) => (row.modifiedByUser ? `${row.modifiedByUser?.firstName} ${row.modifiedByUser?.lastName}` : ''),
        },
    ];

    const { data, isLoading, error, refetch } = useGetRoofSectionChangeLogsQuery({
        parentId: roofSection.id,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
        },
    });

    const handleSetActive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            const changeLog = data?.pageResults[index];
            if (!changeLog?.modelId || !changeLog.id) {
                console.warn('Unable to recover the following record, missing required information: ', changeLog);
                return;
            }
            unarchiveRoofSection({
                roofSectionId: changeLog.modelId,
                changeLogId: changeLog.id,
            });
        });
        setIsUpdatingStatus(false);
    };

    const handleSetInactive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            const changeLog = data?.pageResults[index];
            if (!changeLog?.modelId || !changeLog.id) {
                console.warn('Unable to recover the following record, missing required information: ', changeLog);
                return;
            }
            archiveRoofSection({
                roofSectionId: changeLog.modelId,
                changeLogId: changeLog.id,
            });
        });
        setIsUpdatingStatus(false);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    };

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index].isActive);
    };

    const getEntityName = () => {
        return selected.length === 1 ? 'Condition Index History' : 'Condition Index Histories';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => { handleActionRequestCompletion(resetArchive); });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => { handleActionRequestCompletion(resetUnarchive); });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    return (
        <Dialog onClose={onClose} open={open} maxWidth='lg' fullWidth>
            <StandardDialogHeader icon={<History sx={{ color: 'white' }} />} title='Condition Index History' />
            <DialogContent>
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {error ? (
                            <ApiError onReloadClick={refetch} />
                        ) : (
                            <>
                                {data && (
                                    <Grid container direction='column' spacing={2} sx={{ paddingTop: '20px' }}>
                                        <Grid item container direction='row' justifyContent='space-between'>
                                            <Grid item xs={3}>
                                                <Typography variant='h3'>Roof Section #{roofSection.roofNumber}</Typography>
                                            </Grid>
                                            <Grid item xs={9} container alignItems='center' justifyContent='end'>
                                                <Grid item xs={3}>
                                                    <FormControlLabel
                                                        control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                                        label='Show Inactive'
                                                        labelPlacement='start'
                                                    />
                                                </Grid>
                                                <AuthenticatedComponent
                                                    requiredPermissions={['edit:roofSections']}
                                                    component={
                                                        <>
                                                            <Grid item xs={1}>
                                                                <Tooltip title='Make Inactive'>
                                                                    <span>
                                                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive()}>
                                                                            <Delete fontSize='inherit' />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <IconButton size='large' onClick={handleMenuOpen}>
                                                                    <MoreVert fontSize='inherit' />
                                                                </IconButton>
                                                                <Menu
                                                                    anchorEl={menuAnchorEl}
                                                                    open={menuOpen}
                                                                    onClose={handleMenuClose()}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}>
                                                                    <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                                                        Make Active
                                                                    </MenuItem>
                                                                    <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                                                        Make Inactive
                                                                    </MenuItem>
                                                                </Menu>
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                                <Grid item>
                                                    <TextField
                                                        id='search-box'
                                                        value={searchText}
                                                        onChange={handleSearchChange}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    <Search />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        label='Search'
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <PaginatedDataTable
                                                columns={tableColumns}
                                                loading={isLoading}
                                                queryResults={data}
                                                defaultSortKey='MODIFIED_ON'
                                                defaultSortDesc={true}
                                                setPagination={setPaginatedProps}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
