import { History, Search } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Grid, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { FC, useState } from 'react';
import { WorkOrderStatusHistoryLineItemDto } from '../../../models/workOrderStatusHistoryDto';
import { useGetWorkOrderStatusHistoryLogsQuery } from '../../../store/apis/work-order-dispatch-history-api';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { StandardDialogHeader } from '../StandardDialogHeader';

export interface IWorkOrderHistoryDialogProps {
    open: boolean;
    onClose: () => void;
    workOrderId: string;
    workOrderNumber: string;
}

export const WorkOrderHistoryDialog: FC<IWorkOrderHistoryDialogProps> = React.memo((props) => {
    const { workOrderId, workOrderNumber, open, onClose } = props;
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [searchText, setSearchText] = useState('');
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>({
        sortKey: 'CREATED_DATE',
        sortAsc: false,
        page: 0,
        pageSize: 10,
    });
    const { data, isLoading, error, refetch } = useGetWorkOrderStatusHistoryLogsQuery({
        workOrderId: workOrderId,
        searchText: searchText,
        sortKey: paginatedProps.sortKey,
        sortAsc: paginatedProps.sortAsc,
        page: paginatedProps.page,
        pageSize: paginatedProps.pageSize,
        includeInactive: false,
    });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const tableColumns: DataTableColumn<WorkOrderStatusHistoryLineItemDto>[] = [
        {
            key: 'createdOn',
            label: 'Date',
            sortKey: 'CREATED_DATE',
            fieldMapper: (row) => (row.createdOn ? format(new Date(row.createdOn.toString()), 'M/d/yyyy hh:mm a') : ''),
        },
        {
            key: 'statusMessage',
            label: 'Action',
            sortKey: 'STATUS_MESSAGE',
            fieldMapper: (row) => (
                <Tooltip title={row.statusMessage} placement='bottom-start'>
                    <span>{row.statusMessage}</span>
                </Tooltip>
            ),
        },
        {
            key: 'workOrderStatusId',
            label: 'Status',
            sortKey: 'STATUS',
            fieldMapper: (row) => row.workOrderStatus?.name ?? '',
        },
        {
            key: 'createdByUser',
            label: 'User',
            sortKey: 'USER',
            fieldMapper: (row) => (row.createdByUser ? `${row.createdByUser.firstName} ${row.createdByUser.lastName}` : ''),
        },
    ];

    const renderDialogContent = () => {
        if (isLoading) {
            return <LoadingIndicator />;
        }

        if (error) {
            return <ApiError onReloadClick={refetch} />;
        }

        return (
            <>
                <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
                    <Grid item container direction='row' alignItems='start' justifyContent='space-between'>
                        <Typography variant='h3'>Work Order #{workOrderNumber}</Typography>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                        />
                    </Grid>
                    <Grid item>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isLoading}
                            queryResults={data}
                            defaultSortKey='CREATED_DATE'
                            defaultSortDesc={true}
                            setPagination={setPaginatedProps}
                            selected={selected}
                            setSelected={setSelected}
                            isDashboardStyle={true}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <Dialog onClose={onClose} open={open} maxWidth='lg' fullWidth>
            <StandardDialogHeader icon={<History sx={{ color: 'white' }} />} title='Work Order History' />
            <DialogContent>{renderDialogContent()}</DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
});
