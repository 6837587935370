import { Cancel, Done, Error, Send, ThumbUp, Undo } from '@mui/icons-material';
import { Stack } from '@mui/material';

export const dispatchStatusIcons = {
    Dispatched: <Send />,
    Accepted: <ThumbUp />,
    Rejected: <Cancel />,
    Completed: <Done />,
    Recalled: <Undo />,
    "Needs Attention": <Error />
};

export const getStatusRow = (status: string) => {
    return <Stack direction="row" alignItems="center" gap={1}>
        {status in dispatchStatusIcons &&
            dispatchStatusIcons[status as keyof typeof dispatchStatusIcons]}
        {status}
    </Stack>;
};
