import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { RoofProfileForm } from '../../Components/Forms/RoofProfileForm';
import { RoofProfileDto } from '../../models';
import { useGetFacilityQuery } from '../../store/apis/facility-api';
import { useCreateRoofProfileMutation } from '../../store/apis/roof-profile-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddRoofProfileView: FunctionComponent = () => {
    const { facilityId } = useParams();
    const navigate = useNavigate();
    const { data: facility, isLoading: facilityLoading, error: facilityError, refetch: facilityRefetch } = useGetFacilityQuery(facilityId!);
    const [createRoofProfile, { data, isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate }] = useCreateRoofProfileMutation();

    useFailedCreateSnackbar('roof plan', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Roof plan', isCreateSuccess, () => {
        navigate(`/clients/${facility?.clientId}/facilities/${facility?.id}/roofprofiles/edit/${data?.id}`);
    });

    const handleSave = (values: RoofProfileDto) => {
        createRoofProfile(values);
    };

    const handleCancel = () => {
        navigate(`/clients/${facility?.clientId}/facilities/edit/${facility?.id}`);
    };

    if (facilityLoading) {
        return <LoadingIndicator />;
    }

    if (facilityError) {
        return <ApiError onReloadClick={facilityRefetch} />;
    }

    return <RoofProfileForm client={facility!.client} facility={facility!} save={handleSave} cancel={handleCancel} />;
};
