import { FC } from "react";
import { InsertChart } from "@mui/icons-material";
import { DashboardCard } from "../DashboardCard";
import { ClientReportsListView } from "../../../Views/ClientReportsListView";
import { IClientCardProps } from "./types";

export const ClientReportsListViewCard: FC<IClientCardProps> = ({ clientId, facility }) => {

    return (
        <DashboardCard headerIcon={<InsertChart />} headerTitle={`Reports`}>
            <ClientReportsListView clientId={clientId} facility={facility} isClientDashboardView={true} />
        </DashboardCard>
    );
};