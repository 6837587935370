import { AddCircle, Assignment, ContentCopy, Delete, FilterList, MoreVert, Search } from '@mui/icons-material';
import { Chip, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterWorkOrdersAndCapitalProjectsDialog } from '../Components/Dashboard/ClientDashboard';
import { ApiError } from '../Components/core/ApiError';
import { PaginatedDataTable, PaginatedProps } from '../Components/core/DataTable';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import { NavBreadcrumbs } from '../Components/core/NavBreadcrumbs';
import { usePermissionChecker } from '../Hooks';
import { useWorkOrderExporter } from '../Hooks/useWorkOrderExporter';
import AuthenticatedComponent from '../auth';
import { ClientDto, FacilityDto, WorkOrderStatusDto, WorkOrderTypeDto } from '../models';
import { ContractorDto } from '../models/ContractorDto';
import { useArchiveWorkOrderMutation, useDuplicateWorkOrderMutation, useGetWorkOrdersQuery, useLazyGetWorkOrdersQuery, useUnarchiveWorkOrderMutation } from '../store/apis/work-order-api';
import { getStandardWorkOrderTableColumns } from '../util';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../util/customHooks';
import { exportToCsv } from '../util/fileDownloadHelper';

interface Filters {
    dispatchDateAfterFilter?: Date | null;
    dispatchDateBeforeFilter?: Date | null;
    statusFilter?: WorkOrderStatusDto;
    contractorFilter?: ContractorDto;
    clientFilter?: ClientDto | null;
    facilityFilter?: FacilityDto | null;
    typeFilter?: WorkOrderTypeDto;
}

export const WorkOrdersListView: FunctionComponent = () => {
    const getFilterCookies = () => {
        const value = "; " + document.cookie;
        const parts = value.split('; WO_FILTER=');

        if (parts.length === 2) {
            const cookieValueAsString = parts.pop()?.split(";").shift();
            const cookieValueAsObject: Filters = JSON.parse(cookieValueAsString ?? '{}');
            return cookieValueAsObject;
        }
        return {};
    };
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [statusFilter, setStatusFilter] = useState<WorkOrderStatusDto | undefined>(getFilterCookies().statusFilter);
    const [dispatchDateAfterFilter, setDispatchDateAfterFilter] = useState<Date | null | undefined>(getFilterCookies().dispatchDateAfterFilter ? new Date(getFilterCookies().dispatchDateAfterFilter!) : null);
    const [dispatchDateBeforeFilter, setDispatchDateBeforeFilter] = useState<Date | null | undefined>(getFilterCookies().dispatchDateBeforeFilter ? new Date(getFilterCookies().dispatchDateBeforeFilter!) : null);
    const [contractorFilter, setContractorFilter] = useState<ContractorDto | undefined>(getFilterCookies().contractorFilter);
    const [clientFilter, setClientFilter] = useState<ClientDto | null | undefined>(getFilterCookies().clientFilter);
    const [facilityFilter, setFacilityFilter] = useState<FacilityDto | null | undefined>(getFilterCookies().facilityFilter);
    const [typeFilter, setTypeFilter] = useState<WorkOrderTypeDto | undefined>(getFilterCookies().typeFilter);
    const [filterOpen, setFilterOpen] = useState(false);
    const { data, isLoading, error, refetch } = useGetWorkOrdersQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        orderType: 0,
        dispatchDateAfter: dispatchDateAfterFilter ? dispatchDateAfterFilter.toISOString() : undefined,
        dispatchDateBefore: dispatchDateBeforeFilter ? dispatchDateBeforeFilter.toISOString() : undefined,
        contractorId: contractorFilter?.id,
        clientId: clientFilter?.id,
        facilityId: facilityFilter?.id,
        workOrderTypeId: typeFilter?.id,
        status: statusFilter?.id,
    });
    const [getWorkOrdersForExport] = useLazyGetWorkOrdersQuery();
    const [selected, setSelected] = useState<readonly number[]>([]);
    const { userHasPermission } = usePermissionChecker();
    const userCanViewWorkOrderCosts = useMemo(() => userHasPermission('read:workOrderCosts'), [userHasPermission]);
    const tableColumns = useMemo(() => getStandardWorkOrderTableColumns(userCanViewWorkOrderCosts, true, 'WO #'), [userCanViewWorkOrderCosts]);
    const [archiveClient, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveWorkOrderMutation();
    const [unarchiveClient, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveWorkOrderMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const { getExportData } = useWorkOrderExporter();

    const getEntityName = () => {
        return selected.length === 1 ? 'Work Order' : 'Work Orders';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    const setCookie = (name: string, val: string) => {
        const date = new Date();
        const value = val;

        // Set it expire in 1 hour
        date.setTime(date.getTime() + (60 * 60 * 1000));

        // Set it
        document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
    };

    useEffect(() => { // when filters are updated, store them to cookie
        const filters: Filters = {
            dispatchDateAfterFilter: dispatchDateAfterFilter,
            dispatchDateBeforeFilter: dispatchDateBeforeFilter,
            statusFilter: statusFilter,
            contractorFilter: contractorFilter,
            clientFilter: clientFilter,
            facilityFilter: !!facilityFilter ? { ...facilityFilter, roofProfiles: undefined, droneFootageUrl: undefined } : null,
            typeFilter: typeFilter
        };
        setCookie("WO_FILTER", JSON.stringify(filters));
    }, [statusFilter, contractorFilter, clientFilter, facilityFilter, typeFilter, dispatchDateAfterFilter, dispatchDateBeforeFilter]);

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => { handleActionRequestCompletion(resetArchive); });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => { handleActionRequestCompletion(resetUnarchive); });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    const [
        duplicateWorkOrder,
        {
            data: duplicatedWorkOrderId,
            isLoading: isDuplicatingWorkOrder,
            isError: isDuplicateWorkOrderError,
            isSuccess: isDuplicateWorkOrderSuccess,
            reset: resetDuplicateWorkOrder,
        },
    ] = useDuplicateWorkOrderMutation();
    useFailedActionSnackbar('clone', 'Work Order', isDuplicateWorkOrderError, resetDuplicateWorkOrder);
    useSuccessfulActionSnackbar('clone', 'Work Order', isDuplicateWorkOrderSuccess, () => {
        navigate(`/workorders/edit/${duplicatedWorkOrderId}`);
        resetDuplicateWorkOrder();
    });

    const handleClone = () => {
        if (isDuplicatingWorkOrder || selected.length !== 1) {
            return;
        }
        const selectedCapitalProjectId = data?.pageResults[selected[0]].id;
        if (!selectedCapitalProjectId) {
            return;
        }
        duplicateWorkOrder(selectedCapitalProjectId);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    };

    const handleCreateNew = () => {
        navigate('/workorders/create');
    };

    const handleMenuEdit = () => {
        const selectedWorkOrderId = data?.pageResults[selected[0]].id;
        if (selectedWorkOrderId) {
            navigate(`/workorders/edit/${selectedWorkOrderId}`);
        }
    };

    const handleTableEdit = (id: string) => {
        navigate(`/workorders/edit/${id}`);
    };

    const handleSetActive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveClient(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    };

    const handleSetInactive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveClient(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    };

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index].isActive);
    };

    const handleFilter = () => {
        setFilterOpen(true);
    };

    const handleDispatchAfterFilterDelete = useCallback(() => {
        setDispatchDateAfterFilter(undefined);
    }, []);

    const handleDispatchBeforeFilterDelete = useCallback(() => {
        setDispatchDateBeforeFilter(undefined);
    }, []);

    const handleStatusFilterDelete = () => {
        setStatusFilter(undefined);
    };

    const handleDispatchFilterDelete = () => {
        setContractorFilter(undefined);
    };

    const handleClientFilterDelete = () => {
        setClientFilter(undefined);
    };

    const handleFacilityFilterDelete = () => {
        setFacilityFilter(undefined);
    };

    const handleTypeFilterDelete = () => {
        setTypeFilter(undefined);
    };

    const disableEdit = (id: string) => {
        return false;
    };

    const handleExport = async () => {
        var allOrders = await getWorkOrdersForExport({
            includeInactive: showInactive,
            orderType: 0,
            dispatchDateAfter: dispatchDateAfterFilter?.toISOString(),
            dispatchDateBefore: dispatchDateBeforeFilter?.toISOString(),
            contractorId: contractorFilter?.id,
            status: statusFilter?.id,
            facilityId: facilityFilter?.id,
            clientId: clientFilter?.id,
            workOrderTypeId: typeFilter?.id,
        });

        if (allOrders.data?.pageResults) {
            const { headers, data } = getExportData(allOrders.data?.pageResults, false);
            exportToCsv(data, headers, `Work Orders ${new Date().toDateString()}`);
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start' justifyContent={'space-between'}>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Assignment /> Work Orders
                    </Typography>
                    <NavBreadcrumbs links={[{ label: 'Home', navLink: '/' }]} currentPageLabel='Work Orders' />
                </Grid>
                <Grid item container direction='row' alignItems='center' xs={6} justifyItems='end' justifyContent={'end'}>
                    <Grid item mr={1}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:workorders']}
                        component={
                            <Grid item>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive()}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <Grid item>
                        <Tooltip title={'Filter'}>
                            <IconButton color='primary' size='large' onClick={handleFilter}>
                                <FilterList fontSize='inherit' />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['create:workorders']}
                        component={
                            <Tooltip title='Copy'>
                                <Grid item>
                                    <IconButton color='primary' disabled={selected.length !== 1} onClick={handleClone}>
                                        <ContentCopy />
                                    </IconButton>
                                </Grid>
                            </Tooltip>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:workorders']}
                        component={
                            <Grid item>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <Grid item>
                        <IconButton size='large' onClick={handleMenuOpen}>
                            <MoreVert fontSize='inherit' />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <AuthenticatedComponent
                                requiredPermissions={['create:workorders']}
                                component={<MenuItem disabled={selected.length !== 1} onClick={handleClone}> Copy </MenuItem>}
                            />
                            <AuthenticatedComponent
                                requiredPermissions={['create:workorders']}
                                component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                            />
                            <AuthenticatedComponent
                                requiredPermissions={['edit:workordersFull', 'edit:workordersLimited']}
                                logic='or'
                                component={
                                    <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                        Edit
                                    </MenuItem>
                                }
                            />
                            <Divider />
                            <MenuItem onClick={handleMenuClose(handleFilter)}>Filter</MenuItem>
                            <MenuItem onClick={handleMenuClose(handleExport)}>Export</MenuItem>
                            <AuthenticatedComponent
                                requiredPermissions={['delete:workorders']}
                                component={
                                    <>
                                        <Divider />
                                        <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                            Make Active
                                        </MenuItem>
                                        <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                            Make Inactive
                                        </MenuItem>
                                    </>
                                }
                            />
                        </Menu>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='center' xs={6} spacing={1} sx={{ height: '100%', paddingLeft: '12px', marginTop: '0px' }}>
                {dispatchDateAfterFilter && (
                    <Grid item>
                        <Chip label={`Dispatch Date After: ${dispatchDateAfterFilter.toLocaleDateString()}`} color='secondary' onDelete={handleDispatchAfterFilterDelete} />
                    </Grid>
                )}
                {dispatchDateBeforeFilter && (
                    <Grid item>
                        <Chip label={`Dispatch Date Before: ${dispatchDateBeforeFilter.toLocaleDateString()}`} color='secondary' onDelete={handleDispatchBeforeFilterDelete} />
                    </Grid>
                )}
                {statusFilter && (
                    <Grid item>
                        <Chip label={`Status: ${statusFilter.name}`} color='secondary' onDelete={handleStatusFilterDelete} />
                    </Grid>
                )}
                {contractorFilter && (
                    <Grid item>
                        <Chip label={`Dispatch ID: ${contractorFilter.dispatchId}`} color='secondary' onDelete={handleDispatchFilterDelete} />
                    </Grid>
                )}
                {clientFilter && (
                    <Grid item>
                        <Chip label={`Owner: ${clientFilter.name}`} color='secondary' onDelete={handleClientFilterDelete} />
                    </Grid>
                )}
                {facilityFilter && (
                    <Grid item>
                        <Chip label={`Facility: ${facilityFilter.name}`} color='secondary' onDelete={handleFacilityFilterDelete} />
                    </Grid>
                )}
                {typeFilter && (
                    <Grid item>
                        <Chip label={`Type: ${typeFilter.name}`} color='secondary' onDelete={handleTypeFilterDelete} />
                    </Grid>
                )}
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='WO_NUMBER'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:workordersFull', 'edit:workordersLimited'], logic: 'or' }}
                    onEdit={handleTableEdit}
                    disableEdit={disableEdit}
                    onRowClick={handleTableEdit}
                />
            </Grid>
            {filterOpen && (
                <FilterWorkOrdersAndCapitalProjectsDialog
                    open={filterOpen}
                    close={() => setFilterOpen(false)}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    dispatchDateAfterFilter={dispatchDateAfterFilter}
                    dispatchDateBeforeFilter={dispatchDateBeforeFilter}
                    setDispatchDateAfterFilter={setDispatchDateAfterFilter}
                    setDispatchDateBeforeFilter={setDispatchDateBeforeFilter}
                    contractorFilter={contractorFilter}
                    setContractorFilter={setContractorFilter}
                    clientFilter={clientFilter}
                    setClientFilter={setClientFilter}
                    facilityFilter={facilityFilter}
                    setFacilityFilter={setFacilityFilter}
                    typeFilter={typeFilter}
                    setTypeFilter={setTypeFilter}
                    isCapitalProject={false}
                />
            )}
        </Grid>
    );
};
