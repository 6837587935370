import { FunctionComponent } from "react";
import { IClientCardProps } from "./types";
import { Download } from "@mui/icons-material";
import { DashboardCard } from "../DashboardCard";
import { DownloadsListView } from "../../../Views/Downloads";

export const DownloadsCard: FunctionComponent<IClientCardProps> = (props) => {
    const { clientId, facility } = props;

    return (
        <DashboardCard headerIcon={<Download />} headerTitle={`Other Downloads`}>
            <DownloadsListView clientId={clientId} facility={facility} />
        </DashboardCard>
    );
};