import { Business, Delete, History, Link, Map } from '@mui/icons-material';
import { DatePicker, TabContext, TabPanel } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import _ from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AuthenticatedComponent from '../../../auth';
import { DrainageDto, ManufacturerDto, PhotoDto, RoofAccessDto, RoofConditionDto, RoofLayerDto, RoofSystemDto, emptyGuid } from '../../../models';
import { ContractorDto } from '../../../models/ContractorDto';
import { useLazyGetSasLinkQuery } from '../../../store/apis/cloud-storage-api';
import { useGetContractorsQuery } from '../../../store/apis/contractor-api';
import { useCreateDrainageTypeMutation, useGetDrainagesQuery } from '../../../store/apis/drainage-api';
import { useCreateManufacturerMutation, useGetManufacturersQuery } from '../../../store/apis/manufacturer-api';
import { useLazyGetPhotoFileLinkQuery } from '../../../store/apis/photo-api';
import { useGetRoofAccessesQuery } from '../../../store/apis/roof-access-api';
import { useCreateRoofSystemMutation, useGetRoofConditionsQuery, useGetRoofSystemsQuery } from '../../../store/apis/roof-api';
import { useGetRoofProfileQuery } from '../../../store/apis/roof-profile-api';
import {
    useArchiveRoofSectionMutation,
    useCreateRoofSectionMutation,
    useLazyGetRoofSectionQuery,
    useUnlinkRoofSectionMutation,
    useUpdateRoofSectionMutation
} from '../../../store/apis/roof-section-api';
import { ColorSwatch, uploadFileToCloud } from '../../../util';
import { useFailedActionSnackbar, useFailedCreateSnackbar, useFailedUpdateSnackbar, useStandardSnackbar, useSuccessfulActionSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../../util/customHooks';
import { getErrorMessage } from '../../../util/errorHelper';
import { ApiError } from '../../core/ApiError';
import { ConfirmDialog } from '../../core/ConfirmDialog';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { IEntitySelect } from '../../core/IEntitySelect';
import LoadingIndicator from '../../core/LoadingIndicator';
import { NoMapFoundError } from '../../core/NoMapFoundError';
import { MultiPhotoUpload, PhotoEditorDialog, PhotosDisplay } from '../../core/Photos';
import { ViewPhotoDialog } from '../../core/Photos/ViewPhotoDialog';
import { SvgEditor, SvgWrapper } from '../../svg';
import { FormInput, FormNumberInput, patterns } from '../FormFields';
import { FormSection } from '../FormSection';
import { IRoofMapSectionDialogProps, ISectionRef } from '../RoofMap/types';
import { StandardDialogHeader } from '../StandardDialogHeader';
import { Attachment } from '../types';
import { RoofSectionCoreInfo } from './RoofSectionCoreInfo';
import { RoofSectionHistoryDialog } from './RoofSectionHistoryDialog';

export const RoofSectionDialog: FunctionComponent<IRoofMapSectionDialogProps> = (props) => {
    const { open, onClose, facilityId, roofProfileId, initValues, reloadProfile } = props;
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const [roofNumber, setRoofNumber] = useState(initValues ? initValues.roofNumber : '');
    const [roofName, setRoofName] = useState(initValues ? initValues.name : '');
    const [manufacturer, setManufacturer] = useState<ManufacturerDto | null | undefined>(initValues ? initValues.manufacturer : null);
    const [roofAccess, setRoofAccess] = useState<RoofAccessDto | null>(initValues?.roofAccess ?? null);
    const [drainage, setDrainage] = useState<DrainageDto | null>(initValues?.drainage ?? null);
    const [estimatedAge, setEstimatedAge] = useState(initValues ? initValues.estimatedAge : '');
    const [installDate, setInstallDate] = useState<Date | undefined | null>(initValues ? initValues.installDate : null);
    const [installingContractorOpen, setInstallingContractorOpen] = useState(false);
    const [installingContractor, setInstallingContractor] = useState<ContractorDto | null | undefined>(initValues ? initValues.contractor : null);
    const [assetValue, setAssetValue] = useState(initValues?.assetValue ?? 0);
    const [assetValueMultiplier, setAssetValueMultiplier] = useState(initValues ? initValues?.assetValueMultiplier : 0);
    const [squareFootage, setSquareFootage] = useState(initValues?.squareFootage ?? null);
    const [isManual, setIsManual] = useState(initValues ? initValues.isManual : false);
    const [roofConditionId, setRoofConditionId] = useState(initValues ? initValues.roofConditionId : '');
    const [roofCondition, setRoofCondition] = useState<RoofConditionDto | undefined>(initValues ? initValues.roofCondition : undefined);
    const [inspectorComments, setInspectorComments] = useState(initValues ? initValues.inspectorComments : '');
    const [roofLayers, setRoofLayers] = useState<RoofLayerDto[]>(initValues?.roofLayers ? initValues.roofLayers : []);
    const [svgContents, setSvgContents] = useState<string>('');
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [selectedAttachment, setSelectedAttachment] = useState<Attachment>();
    const [selectedCaption, setSelectedCaption] = useState<string>('');
    const [attachmentHoverIndex, setAttachmentHoverIndex] = useState<number | undefined>();
    const [attachmentCaptions, setAttachmentCaptions] = useState<string[]>([]);
    const [roofSystem, setRoofSystem] = useState<RoofSystemDto | null | undefined>(initValues ? initValues.roofSystem : null);
    const [warranty, setWarranty] = useState<string>(initValues ? initValues?.warrantyStatus ?? '' : '');
    const [fieldErrors, setFieldErrors] = useState({
        ROOF_NUMBER: '',
        ASSET_VALUE: '',
        SQUARE_FOOTAGE: '',
        ROOF_CONDITION: '',
    });
    const { data: roofProfile, isLoading: roofProfileLoading, error: roofProfileError } = useGetRoofProfileQuery({ id: roofProfileId!, includeSvg: true });
    const { data: installingContractors, isLoading: contractorsLoading } = useGetContractorsQuery({
        includeOnlyInstallingContractors: true,
        pageSize: 100000,
        includeInactive: true,
    });
    const { data: manufacturers, isLoading: manufacturersLoading } = useGetManufacturersQuery({ includeInactive: true });
    const {
        data: roofConditions,
        isLoading: roofConditionsLoading,
        error: roofConditionsError,
        refetch: roofConditionsRefetch,
    } = useGetRoofConditionsQuery({ includeInactive: true });
    const { data: drainages, isLoading: drainagesLoading, error: drainagesError, refetch: drainagesRefetch } = useGetDrainagesQuery({ includeInactive: true });
    const [createDrainageType, { isSuccess: isCreateDrainageTypeSuccess, isError: isCreateDrainageTypeError, error: createDrainageTypeError, reset: resetDrainageType, data: newDrainageType }] = useCreateDrainageTypeMutation();
    const {
        data: roofAccesses,
        isLoading: roofAccessesLoading,
        error: roofAccessesError,
        refetch: roofAccessesRefetch,
    } = useGetRoofAccessesQuery({ includeInactive: true });
    const {
        data: roofSystems,
        error: roofSystemsError,
        isLoading: roofSystemsLoading,
        refetch: refetchRoofSystems,
    } = useGetRoofSystemsQuery({ includeInactive: true });
    const [createRoofSystem, { isSuccess: isCreateRoofSystemSuccess, isError: isCreateRoofSystemError, error: createRoofSystemError, reset: resetRoofSystem, data: newRoofSystem }] = useCreateRoofSystemMutation();


    const [getFileLink] = useLazyGetPhotoFileLinkQuery();
    const [getSasLink] = useLazyGetSasLinkQuery();
    const [getRoofSection, { isLoading: isReLoadingRoofSection }] = useLazyGetRoofSectionQuery();
    const [createRoofSection, { isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate, error: createError }] = useCreateRoofSectionMutation();
    const [updateRoofSection, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate, error: updateError }] = useUpdateRoofSectionMutation();
    const [archiveRoofSection, { isSuccess: isArchiveSuccess, isError: isArchiveError, reset: resetArchive }] = useArchiveRoofSectionMutation();
    const [unlinkRoofSection, { isSuccess: isUnlinkSuccess, isError: isUnlinkError, reset: resetUnlink }] = useUnlinkRoofSectionMutation();
    const [createManufacturer, { isSuccess: isCreateManufacturerSuccess, isError: isCreateManufacturerError, error: createManufacturerError, reset: resetManufacturer, data: newManufacturer }] = useCreateManufacturerMutation();

    const roofLayerSectionRef = useRef<ISectionRef>(null);
    const [addPhotoIsOpen, setAddPhotoIsOpen] = useState(false);
    const [viewPhotoIsOpen, setViewPhotoIsOpen] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState('0');
    const [editPhotoIsOpen, setEditPhotoIsOpen] = useState(false);
    const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

    const saveManufacturer = (formValue: string) => {
        createManufacturer({
            id: emptyGuid,
            name: formValue,
            isActive: true
        });
    };

    const saveDrainage = (formValue: string) => {
        createDrainageType({
            id: emptyGuid,
            name: formValue,
            isActive: true
        });
    };

    const saveRoofSystem = (formValue: string) => {
        createRoofSystem({
            id: emptyGuid,
            name: formValue,
            isActive: true
        });
    };

    useFailedCreateSnackbar('roof section', isCreateError, resetCreate, createError);
    useSuccessfulCreateSnackbar('Roof section', isCreateSuccess, () => {
        resetCreate();
        onClose();
    });

    useFailedUpdateSnackbar('roof section', isUpdateError, resetUpdate, updateError);
    useSuccessfulUpdateSnackbar('Roof section', isUpdateSuccess, () => {
        resetUpdate();
        onClose();
        if (reloadProfile) {
            reloadProfile();
        }
    });

    useFailedActionSnackbar('inactivate', 'Roof section', isArchiveError, resetArchive);
    useSuccessfulActionSnackbar('Inactivated', 'Roof section', isArchiveSuccess, () => {
        resetArchive();
        onClose();
    });

    useFailedActionSnackbar('unlink', 'Roof section', isUnlinkError, resetUnlink);
    useSuccessfulActionSnackbar('Unlinked', 'Roof section', isUnlinkSuccess, () => {
        resetUnlink();
        onClose();
        if (reloadProfile) {
            reloadProfile();
        }

    });

    const isUniqueError = useMemo(() => !!createManufacturerError && (getErrorMessage(createManufacturerError) === "Manufacturer already exists"), [createManufacturerError]);
    useFailedCreateSnackbar('manufacturer', isCreateManufacturerError && !isUniqueError, resetManufacturer);
    useStandardSnackbar(isUniqueError, "Manufacturer already exists", 'error', resetManufacturer);
    useSuccessfulCreateSnackbar('Manufacturer', isCreateManufacturerSuccess, () => {
        setManufacturer(newManufacturer);
        resetManufacturer();
    });

    const isDrainageTypeUniqueError = !!createDrainageTypeError && (getErrorMessage(createDrainageTypeError) === "Drainage Type already exists");
    useFailedCreateSnackbar('drainage Type', isCreateDrainageTypeError && !isDrainageTypeUniqueError, resetDrainageType);
    useStandardSnackbar(isDrainageTypeUniqueError, "Drainage Type already exists", 'error', resetDrainageType);
    useSuccessfulCreateSnackbar('Drainage Type', isCreateDrainageTypeSuccess, () => {
        setDrainage(newDrainageType ?? null);
        resetDrainageType();
    });

    const isRoofSystemUniqueError = !!createRoofSystemError && (getErrorMessage(createRoofSystemError) === "Roof System already exists");
    useFailedCreateSnackbar('roof System', isCreateRoofSystemError && !isRoofSystemUniqueError, resetRoofSystem);
    useStandardSnackbar(isRoofSystemUniqueError, "Roof System already exists", 'error', resetRoofSystem);
    useSuccessfulCreateSnackbar('Roof System', isCreateRoofSystemSuccess, () => {
        setRoofSystem(newRoofSystem ?? null);
        resetRoofSystem();
    });

    useEffect(() => {
        const svg = roofProfile?.svgContents;
        setSvgContents(svg ?? '');
    }, [roofProfile, setSvgContents]);

    const handleTabChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: any) => {
        if (selectedTabIndex === '1') {
            if (roofLayerSectionRef?.current?.isValid()) {
                setSelectedTabIndex(`${value}`);
            } else {
                return;
            }
        }
        setSelectedTabIndex(`${value}`);
    }, [selectedTabIndex]);

    const createPreviewLink = useCallback(
        async (attachment: PhotoDto): Promise<Attachment> => {
            let response = await getFileLink(attachment.id, false);
            return { id: attachment.id, photo: attachment, previewLink: response.data?.link };
        },
        [getFileLink]
    );

    const initializeRoofPictures = useCallback(() => {
        if (initValues?.attachments) {
            Promise.all(
                initValues.attachments.map(async (attachment) => {
                    return await createPreviewLink(attachment);
                })
            ).then((attachmentsList) => {
                const list = _.orderBy(attachmentsList, (x) => x.photo.order);
                setAttachments(list);
                setAttachmentCaptions(list.map((attachment) => attachment.photo.caption ?? ''));
            });
        }
    }, [createPreviewLink, initValues?.attachments]);

    useEffect(() => {
        initializeRoofPictures();
    }, [initializeRoofPictures]);

    const handleCaptionInput = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { value } = event.target;
        const updatedCaptionList = [...attachmentCaptions];
        updatedCaptionList[index] = value;
        setAttachmentCaptions(updatedCaptionList);
    }, [attachmentCaptions]);

    const handleRoofNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRoofNumber(event.target.value);
    }, []);

    const handleRoofNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRoofName(event.target.value);
    }, []);

    const handleEstimatedAgeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEstimatedAge(event.target.value);
    }, []);

    const handleInstallDateChange = useCallback((newValue: Date | null) => {
        setInstallDate(newValue);
    }, []);

    const handleWarrantyChange = useCallback((event: SelectChangeEvent<string>) => {
        setWarranty(event.target.value);
    }, []);

    const handleAssetValueMultiplierChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAssetValueMultiplier(event.target.valueAsNumber);
        const assetValue = event.target.valueAsNumber * (squareFootage ?? 0);
        const truncated = parseFloat(assetValue.toFixed(5));
        setAssetValue(Math.round(truncated));
    }, [squareFootage]);

    const handleAssetValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            const rValue = Math.round(event.target.valueAsNumber);
            setAssetValue(rValue);
            if (squareFootage) {
                const multiplier = rValue / squareFootage;
                const truncated = parseFloat(multiplier.toFixed(5));
                setAssetValueMultiplier(truncated);
            } else {
                setAssetValueMultiplier(1.0);
            }
        }
    }, [squareFootage]);

    const handleSquareFootageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            const sqFeetInput = event.target.valueAsNumber;
            const rSqFeetInput = Math.round(sqFeetInput);
            setSquareFootage(rSqFeetInput);
            const assetValue = rSqFeetInput * (assetValueMultiplier ?? 0);
            setAssetValue(Math.round(assetValue));
        }
    }, [assetValueMultiplier]);

    const handleIsManualChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsManual(checked);
    }, []);

    const handleRoofConditionChange = useCallback((event: SelectChangeEvent) => {
        setRoofConditionId(event.target.value);
        setRoofCondition(roofConditions?.find((condition) => condition.id === event.target.value));
    }, [roofConditions]);

    const handleInspectorCommentsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInspectorComments(event.target.value);
    }, []);

    const handleUnlink = useCallback(() => {
        const mapSection = initValues?.mapSections?.find((x) => x.roofProfileId === roofProfileId);
        if (mapSection) {
            unlinkRoofSection({
                id: mapSection.id,
                roofSectionId: mapSection.roofSectionId,
                roofProfileId: mapSection.roofProfileId,
            });
        }
    }, [initValues?.mapSections, roofProfileId, unlinkRoofSection]);


    const handleDeleteSection = useCallback(() => {
        if (initValues) {
            archiveRoofSection(initValues.id);
        }
    }, [archiveRoofSection, initValues]);

    const afterPhotoUpload = useCallback((id: string, photo: PhotoDto, previewLink: string) => {
        setAttachments((currentAttachments) => currentAttachments.concat({ id: id, photo: photo, previewLink: previewLink }));
        setAttachmentCaptions((currentAttachmentCaptions) => currentAttachmentCaptions.concat(''));
    }, []);

    const deleteSelectedPhoto = useCallback((index: number) => {
        if (attachments.length === 0) {
            setAttachments([]);
            setAttachmentCaptions([]);
        } else {
            const newCaptionList = [...attachmentCaptions];
            newCaptionList.splice(index, 1);
            setAttachmentCaptions(newCaptionList);

            const newAttachmentList = [...attachments];
            newAttachmentList.splice(index, 1);
            setAttachments(newAttachmentList);
        }
    }, [attachmentCaptions, attachments]);

    const photoPreviewOnClick = useCallback((attachment: Attachment, index: number) => {
        setSelectedAttachment(attachment);
        setSelectedCaption(attachmentCaptions[index]);
        setViewPhotoIsOpen(true);
    }, [attachmentCaptions]);

    const refetch = useCallback(() => {
        roofConditionsRefetch();
        drainagesRefetch();
        roofAccessesRefetch();
        refetchRoofSystems();
    }, [drainagesRefetch, refetchRoofSystems, roofAccessesRefetch, roofConditionsRefetch]);

    const handlePhotoEditOnClick = useCallback((photo: Attachment, index: number) => {
        setSelectedAttachment(photo);
        setSelectedCaption(attachmentCaptions[index]);
        setEditPhotoIsOpen(true);
    }, [attachmentCaptions]);

    const handleUpdateEditedPhoto = useCallback(async (updatedPhoto: File, attachmentId: string) => {
        getSasLink().then(async (response) => {
            if (response.data?.link) {
                const uploadResponse = await uploadFileToCloud(response.data?.link, updatedPhoto);
                if (uploadResponse.isSuccessful) {
                    setAttachments(currentAttachments => currentAttachments.map(currentAttachment => {
                        if (currentAttachment.id === attachmentId) {
                            return {
                                ...currentAttachment,
                                photo: { ...currentAttachment.photo, sourceName: uploadResponse.fileName ?? '' },
                                previewLink: URL.createObjectURL(updatedPhoto),
                            };
                        } else {
                            return currentAttachment;
                        }
                    }));
                    setEditPhotoIsOpen(false);
                }
            }
        });
    }, [getSasLink]);

    const handleHistoryDialogClose = useCallback(async () => {
        setHistoryDialogOpen(false);
        if (initValues && initValues?.id) {
            const { data: reloadedRoofSection } = await getRoofSection(initValues.id);
            if (roofConditionId !== reloadedRoofSection?.roofConditionId) {
                setRoofConditionId(reloadedRoofSection?.roofConditionId ?? '');
                setRoofCondition(reloadedRoofSection?.roofCondition);
            }
        }
    }, [getRoofSection, initValues, roofConditionId]);

    const isAssignedToRoofMap = useCallback(() => {
        if (initValues && initValues.mapSections?.find((x) => x.roofProfileId === roofProfileId)) {
            return true;
        }
        return false;
    }, [initValues, roofProfileId]);

    const validate = useCallback((fieldName: string) => {
        let isValid = false;
        if (fieldName === 'ROOF_NUMBER') {
            if (roofNumber) {
                fieldErrors.ROOF_NUMBER = '';
                isValid = true;
                if (roofNumber.length > 4) {
                    fieldErrors.ROOF_NUMBER = 'Roof # must be 4 or less characters';
                    isValid = false;
                }
            } else {
                fieldErrors.ROOF_NUMBER = 'Roof # is required';
                isValid = false;
            }
        } else if (fieldName === 'ASSET_VALUE') {
            if (assetValue !== undefined && assetValue !== null) {
                fieldErrors.ASSET_VALUE = '';
                isValid = true;
            } else {
                fieldErrors.ASSET_VALUE = 'Asset Value is required';
                isValid = false;
            }
            if (patterns.Currency.validate.test(assetValue?.toString() ?? '')) {
                fieldErrors.ASSET_VALUE = '';
                isValid = true;
            } else {
                fieldErrors.ASSET_VALUE = 'Asset Value must be in decimal format';
                isValid = false;
            }
        } else if (fieldName === 'SQUARE_FOOTAGE') {
            if (squareFootage !== undefined && squareFootage !== null) {
                fieldErrors.SQUARE_FOOTAGE = '';
                isValid = true;
            } else {
                fieldErrors.SQUARE_FOOTAGE = 'Square Footage is required';
                isValid = false;
            }
            if (patterns.Decimal.validate.test(squareFootage?.toString() ?? '')) {
                fieldErrors.SQUARE_FOOTAGE = '';
                isValid = true;
            } else {
                fieldErrors.SQUARE_FOOTAGE = 'Square Footage must be in decimal format';
                isValid = false;
            }
        }
        else if (fieldName === 'ROOF_CONDITION') {
            if (initValues?.hasBudgetLineItems) {
                if (roofConditionId) {
                    fieldErrors.ROOF_CONDITION = '';
                    isValid = true;
                } else {
                    fieldErrors.ROOF_CONDITION = 'Condition Index is required';
                    isValid = false;
                }
            } else {
                fieldErrors.ROOF_CONDITION = '';
                isValid = true;
            }
        }
        setFieldErrors({
            ROOF_NUMBER: fieldErrors.ROOF_NUMBER,
            ASSET_VALUE: fieldErrors.ASSET_VALUE,
            SQUARE_FOOTAGE: fieldErrors.SQUARE_FOOTAGE,
            ROOF_CONDITION: fieldErrors.ROOF_CONDITION
        });
        return isValid;
    }, [assetValue, fieldErrors, initValues?.hasBudgetLineItems, roofConditionId, roofNumber, squareFootage]);

    const onFieldBlur = useCallback((fieldName: string) => () => {
        validate(fieldName);
    }, [validate]);

    const formIsValid = useCallback(() => {
        let isValid = validate('ROOF_NUMBER');
        isValid = isValid && validate('ASSET_VALUE');
        isValid = isValid && validate('SQUARE_FOOTAGE');
        isValid = isValid && validate('ROOF_CONDITION');
        return isValid;
    }, [validate]);

    const handleSave = useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            if (initValues?.id) {
                updateRoofSection({
                    id: initValues.id,
                    isActive: true,
                    roofNumber: roofNumber,
                    name: roofName,
                    manufacturerId: manufacturer?.id,
                    roofAccessId: roofAccess?.id,
                    drainageId: drainage?.id,
                    estimatedAge: estimatedAge,
                    installDate: installDate,
                    contractorId: installingContractor?.id,
                    assetValue: assetValue ?? 0,
                    assetValueMultiplier: assetValueMultiplier ?? undefined,
                    squareFootage: squareFootage ?? 0,
                    isManual: isManual,
                    roofConditionId: roofConditionId ? roofConditionId : undefined,
                    inspectorComments: inspectorComments,
                    roofLayers: roofLayers,
                    mapSections: [],
                    attachments: attachments.map((attachment, index) => {
                        return { ...attachment.photo, caption: attachmentCaptions[index], order: index };
                    }),
                    facilityId: facilityId,
                    roofSystemId: roofSystem?.id,
                    warrantyStatus: warranty ?? ''
                });
            } else {
                createRoofSection({
                    id: emptyGuid,
                    isActive: true,
                    roofNumber: roofNumber,
                    name: roofName,
                    manufacturerId: manufacturer?.id,
                    roofAccessId: roofAccess?.id,
                    drainageId: drainage?.id,
                    estimatedAge: estimatedAge,
                    installDate: installDate,
                    contractorId: installingContractor?.id,
                    assetValue: assetValue ?? 0,
                    assetValueMultiplier: assetValueMultiplier ?? undefined,
                    squareFootage: squareFootage ?? 0,
                    isManual: isManual,
                    roofConditionId: roofConditionId ? roofConditionId : undefined,
                    inspectorComments: inspectorComments,
                    roofLayers: roofLayers,
                    mapSections: [],
                    attachments: attachments.map((attachment, index) => {
                        return { ...attachment.photo, caption: attachmentCaptions[index], order: index };
                    }),
                    facilityId: facilityId,
                    roofSystemId: roofSystem?.id,
                    warrantyStatus: warranty ?? ''
                });
            }
        }
    }, [formIsValid, initValues?.id, updateRoofSection, roofNumber, roofName, manufacturer?.id, roofAccess?.id, drainage?.id, estimatedAge, installDate, installingContractor?.id, assetValue, assetValueMultiplier, squareFootage, isManual, roofConditionId, inspectorComments, roofLayers, attachments, facilityId, roofSystem?.id, warranty, attachmentCaptions, createRoofSection]);


    if (roofConditionsLoading || drainagesLoading || roofAccessesLoading || roofProfileLoading || roofSystemsLoading) {
        return <LoadingIndicator />;
    }

    if (roofConditionsError || drainagesError || roofAccessesError || roofProfileError || roofSystemsError) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='xl'>
            <StandardDialogHeader icon={<Map sx={{ color: 'white' }} />} title={`Roof Plan - ${roofNumber !== '' ? `Section ${roofNumber}` : 'New Section'}`} />
            <TabContext value={selectedTabIndex}>
                <Box sx={{ backgroundColor: 'lightGray' }}>
                    <Tabs
                        value={selectedTabIndex}
                        onChange={(event, value) => handleTabChange(event, value)}
                        variant='fullWidth'
                        textColor='secondary'
                        indicatorColor='secondary'
                        sx={{ width: '60%' }}>
                        <Tab label={'ROOF INFO'} key={0} value={'0'} />
                        <Tab label={'CORE INFO'} key={1} value={'1'} />
                        <Tab label={'PHOTOS'} key={2} value={'2'} />
                    </Tabs>
                </Box>
                <DialogContent>
                    <TabPanel value={'0'} sx={{ height: '70vh' }}>
                        <Grid container direction='column'>
                            <Grid item container direction='row'>
                                <Grid item xs={9}>
                                    <FormSection hideBorder>
                                        <Grid item container direction='row' spacing={8}>
                                            <Grid item container direction='row' spacing={2} xs={6}>
                                                <Grid item xs={4}>
                                                    <FormInput
                                                        label='Roof #'
                                                        onBlur={onFieldBlur('ROOF_NUMBER')}
                                                        value={roofNumber}
                                                        fullWidth
                                                        required
                                                        error={fieldErrors.ROOF_NUMBER !== ''}
                                                        errorText={fieldErrors.ROOF_NUMBER}
                                                        onChange={handleRoofNumberChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormInput
                                                        label='Roof Name'
                                                        value={roofName}
                                                        fullWidth
                                                        onChange={handleRoofNameChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item container direction='row' xs={6}>
                                                <Grid item xs={11}>
                                                    <FormControl fullWidth>
                                                        <FormLabel>Manufacturer</FormLabel>
                                                        <IEntityAutocomplete
                                                            options={
                                                                manufacturers?.pageResults.filter(
                                                                    (option) => option.isActive || option.id === manufacturer?.id
                                                                ) ?? []
                                                            }
                                                            getOptionLabel={(option: ManufacturerDto) =>
                                                                `${option.name}${option.isActive ? '' : ' (Inactive)'}`
                                                            }
                                                            onChange={(_e, value) => { setManufacturer(value); }}
                                                            value={manufacturer}
                                                            isLoading={manufacturersLoading}
                                                            quickAddProps={{
                                                                requiredPermissions: ['create:manufacturers'],
                                                                objectName: 'Manufacturer',
                                                                dialogIcon: <Business />,
                                                                handleSave: saveManufacturer
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction='row' spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControl fullWidth>
                                                    <FormLabel>Roof Access</FormLabel>
                                                    <IEntityAutocomplete
                                                        options={roofAccesses}
                                                        onChange={(_e, value) => { setRoofAccess(value); }}
                                                        value={roofAccess}
                                                        getOptionLabel={(option: RoofAccessDto) => option.name}
                                                        isLoading={roofAccessesLoading}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl fullWidth>
                                                    <FormLabel>Roof Type</FormLabel>
                                                    <IEntityAutocomplete
                                                        options={roofSystems}
                                                        onChange={(e, value) => {
                                                            setRoofSystem(value);
                                                        }}
                                                        value={roofSystem}
                                                        getOptionLabel={(option: RoofSystemDto) => option.name}
                                                        isLoading={roofSystemsLoading}
                                                        quickAddProps={{
                                                            requiredPermissions: ['create:roofSystems'],
                                                            objectName: 'Roof Type',
                                                            handleSave: saveRoofSystem
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel>Drainage</FormLabel>
                                                    <IEntityAutocomplete
                                                        options={drainages}
                                                        onChange={(_e, value) => { setDrainage(value); }}
                                                        value={drainage}
                                                        getOptionLabel={(option: DrainageDto) => option.name}
                                                        isLoading={drainagesLoading}
                                                        quickAddProps={{
                                                            requiredPermissions: ['create:drainage'],
                                                            objectName: 'Drainage Type',
                                                            handleSave: saveDrainage
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction='row' spacing={2}>
                                            <Grid item container direction='row' spacing={2} xs={6}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <FormLabel>Estimated Install Year</FormLabel>
                                                        <OutlinedInput value={estimatedAge} onChange={handleEstimatedAgeChange} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <FormLabel>Install Date</FormLabel>
                                                        <DatePicker
                                                            value={installDate}
                                                            onChange={handleInstallDateChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel>Installing Contractor</FormLabel>
                                                    <Autocomplete
                                                        value={installingContractor ?? null}
                                                        onChange={(event, value) => setInstallingContractor(value)}
                                                        open={installingContractorOpen}
                                                        onOpen={() => setInstallingContractorOpen(true)}
                                                        onClose={() => setInstallingContractorOpen(false)}
                                                        options={
                                                            installingContractors?.pageResults.filter(
                                                                (option) => option.isActive || option.id === installingContractor?.id
                                                            ) ?? []
                                                        }
                                                        loading={contractorsLoading}
                                                        isOptionEqualToValue={(option, value) => option.name === value.name}
                                                        getOptionLabel={(option: ContractorDto) => `${option.name}${option.isActive ? '' : ' (Inactive)'}`}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <>
                                                                            {contractorsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction='row' spacing={2}>
                                            <Grid item container direction='row' spacing={2} xs={6}>
                                                <Grid item xs={6}>
                                                    <FormNumberInput
                                                        value={squareFootage}
                                                        onChange={handleSquareFootageChange}
                                                        onBlur={onFieldBlur('SQUARE_FOOTAGE')}
                                                        label='Square Footage'
                                                        name='sqFootage'
                                                        error={fieldErrors.SQUARE_FOOTAGE !== ''}
                                                        errorText={fieldErrors.SQUARE_FOOTAGE}
                                                        fullWidth
                                                        required
                                                        inputProps={{ inputMode: 'numeric', pattern: patterns.Decimal.input.source }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormNumberInput
                                                        value={assetValueMultiplier ?? ''}
                                                        onChange={handleAssetValueMultiplierChange}
                                                        label='Multiplier'
                                                        name='multiplier'
                                                        fullWidth
                                                        inputProps={{ inputMode: 'numeric', pattern: patterns.Decimal.input.source, style: { textAlign: 'right' } }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item container direction='row' alignItems='center' spacing={4} xs={6}>
                                                <Grid item xs={5}>
                                                    <FormNumberInput
                                                        value={assetValue}
                                                        onChange={handleAssetValueChange}
                                                        onBlur={onFieldBlur('ASSET_VALUE')}
                                                        label='Asset Value'
                                                        name='assetValue'
                                                        error={fieldErrors.ASSET_VALUE !== ''}
                                                        errorText={fieldErrors.ASSET_VALUE}
                                                        fullWidth
                                                        required
                                                        inputProps={{ inputMode: 'numeric', pattern: patterns.Currency.input.source, style: { textAlign: 'right' } }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={isManual} onChange={handleIsManualChange} />}
                                                        label='Manual'
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <FormLabel>Warranty</FormLabel>
                                                        <Select value={warranty ?? ''} onChange={handleWarrantyChange} disabled={warranty === 'Yes (Documented)'}>
                                                            <MenuItem key='none' value=''>
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {[
                                                                <MenuItem key={0} value={'No'}>
                                                                    {'No'}
                                                                </MenuItem>,
                                                                <MenuItem key={1} value={'Yes'}>
                                                                    {'Yes'}
                                                                </MenuItem>,
                                                                <MenuItem key={2} value={'Yes (Documented)'} disabled>
                                                                    {'Yes (Documented)'}
                                                                </MenuItem>,
                                                            ]}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='h3' sx={{ fontSize: '18px' }}>
                                                Current Assessment
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction='row' spacing={8} alignItems='center'>
                                            <Grid item xs={6}>
                                                <Grid item container direction='row' alignItems='center' xs={12} spacing={2}>
                                                    <Grid item xs={8}>
                                                        <FormControl fullWidth disabled={isReLoadingRoofSection} required={initValues?.hasBudgetLineItems} error={fieldErrors.ROOF_CONDITION !== ''}>
                                                            <FormLabel>Condition Index</FormLabel>
                                                            <IEntitySelect
                                                                getItem={(item: RoofConditionDto) => <ColorSwatch condition={item} />}
                                                                value={roofConditionId ?? ''}
                                                                onChange={handleRoofConditionChange}
                                                                onBlur={onFieldBlur('ROOF_CONDITION')}
                                                                menuItems={roofConditions}
                                                                required
                                                                error={fieldErrors.ROOF_CONDITION !== ''}
                                                            />
                                                            <FormHelperText>{fieldErrors.ROOF_CONDITION}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <IconButton
                                                            color='primary'
                                                            disabled={!initValues || initValues.id === emptyGuid}
                                                            onClick={() => setHistoryDialogOpen(true)}
                                                            sx={{ marginTop: '20px' }}>
                                                            <History />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item container direction='row' spacing={2} xs={6}>
                                                <Grid item>
                                                    <Typography>Recommended Corrective Action:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>{roofCondition?.recommendedAction}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FormSection>
                                </Grid>
                                <Grid item container direction='column' xs={3}>
                                    <Grid item xs={6}>
                                        {svgContents ? (
                                            <SvgWrapper>
                                                <SvgEditor
                                                    svgContents={svgContents}
                                                    singleFilledSection={initValues ?? undefined}
                                                    colorSections={true}
                                                    height={'100%'}
                                                    width={'100%'}
                                                />
                                            </SvgWrapper>
                                        ) : (
                                            <NoMapFoundError errorText='Not assigned to a map roof section' />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <FormSection hideBorder>
                                <Grid item>
                                    <FormInput
                                        label='Inspector Comments'
                                        value={inspectorComments}
                                        fullWidth
                                        onChange={handleInspectorCommentsChange}
                                        rows={4}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={'1'} sx={{ height: '70vh' }}>
                        <RoofSectionCoreInfo
                            ref={roofLayerSectionRef}
                            roofLayers={[...roofLayers].sort((x, y) => (x.order > y.order ? 1 : -1))}
                            setRoofLayers={setRoofLayers}
                        />
                    </TabPanel>
                    <TabPanel value={'2'} sx={{ height: '70vh' }}>
                        <PhotosDisplay
                            attachments={attachments}
                            setAttachments={setAttachments}
                            attachmentCaptions={attachmentCaptions}
                            setAttachmentCaptions={setAttachmentCaptions}
                            attachmentHoverIndex={attachmentHoverIndex}
                            setAddPhotoIsOpen={setAddPhotoIsOpen}
                            setAttachmentHoverIndex={setAttachmentHoverIndex}
                            deleteSelectedPhoto={deleteSelectedPhoto}
                            photoEditOnClick={handlePhotoEditOnClick}
                            photoPreviewOnClick={photoPreviewOnClick}
                            handleCaptionInput={handleCaptionInput}
                        />
                        <MultiPhotoUpload
                            open={addPhotoIsOpen}
                            onClose={() => setAddPhotoIsOpen(false)}
                            afterPhotoUpload={afterPhotoUpload}
                            allowedFileTypes={['jpg', 'jpeg', 'png']}
                        />
                        {viewPhotoIsOpen && (
                            <ViewPhotoDialog
                                open={viewPhotoIsOpen}
                                onClose={() => setViewPhotoIsOpen(false)}
                                attachment={selectedAttachment}
                                previewLink={selectedAttachment?.previewLink}
                                caption={selectedCaption}
                            />
                        )}
                        {editPhotoIsOpen && selectedAttachment && (
                            <PhotoEditorDialog
                                open={editPhotoIsOpen}
                                onClose={() => setEditPhotoIsOpen(false)}
                                onUpdate={handleUpdateEditedPhoto}
                                attachment={selectedAttachment}
                            />
                        )}
                    </TabPanel>
                </DialogContent>
            </TabContext>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between' wrap='nowrap'>
                    <Grid item container direction='row' spacing={1} xs={6} alignItems='center'>
                        {initValues?.id && (
                            <>
                                <Grid item sx={{ marginTop: '5px' }}>
                                    <Link />
                                </Grid>
                                <Grid item>{isAssignedToRoofMap() ? 'Roof section assigned to map.' : 'Roof section not assigned to map.'}</Grid>
                                {isAssignedToRoofMap() && (
                                    <Grid item>
                                        <AuthenticatedComponent
                                            requiredPermissions={['unassign:roofSections']}
                                            component={
                                                <Button color='secondary' onClick={handleUnlink}>
                                                    Unassign
                                                </Button>
                                            }
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                    <Grid item container direction='row' spacing={2} justifyContent='flex-end' xs={6}>
                        {initValues?.id && (
                            <Grid item>
                                <Button color='secondary' sx={{ width: 'auto' }} onClick={() => setDeleteConfirmationDialogOpen(true)}>
                                    Delete Roof Section
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={handleSave}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
            {initValues && initValues.id !== emptyGuid && (
                <RoofSectionHistoryDialog open={historyDialogOpen} onClose={handleHistoryDialogClose} roofSection={initValues} />
            )}
            <ConfirmDialog
                open={deleteConfirmationDialogOpen}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                content={<Typography>Are you sure you want to delete this Roof Section?</Typography>}
                titleIcon={<Delete />}
                title={<Typography variant='h3'>Delete Roof Section?</Typography>}
                cancelText='No'
                onConfirm={() => handleDeleteSection()}
            />
        </Dialog>
    );
};
