import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { CloseOutReportForm } from '../../Components/Forms/CloseOutReportForm/CloseOutReportForm';
import { CloseOutReportProps } from '../../Components/Forms/CloseOutReportForm/types';
import { useCreateCloseOutReportMutation } from '../../store/apis/close-out-report-api';
import { useGetWorkOrderQuery } from '../../store/apis/work-order-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddCloseOutReportView: FunctionComponent = () => {
    const { workOrderId } = useParams();
    const navigate = useNavigate();
    const [createClientReport, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateCloseOutReportMutation();
    const { data: workOrder, isLoading } = useGetWorkOrderQuery(workOrderId!);

    useFailedCreateSnackbar('close out Report', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Close out Report', isCreateSuccess, () => {
        navigate(`/closeOutReports/edit/${data?.id}`);
    });

    const handleSave = (values: CloseOutReportProps) => {
        createClientReport(values.dto!);
    };

    const handleCancel = () => {
        navigate(`/clientreports`);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return <CloseOutReportForm save={handleSave} cancel={handleCancel} initValues={{
            edit: false,
            workOrder: workOrder
        }}
    />;
};
