import { FormatAlignJustify } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { RoofSectionCoreInfo } from '../../Forms/RoofSectionForm';
import { DashboardCard } from '../DashboardCard';
import { ICoreInfoDashboardCard } from './types';

export const CoreInfoDashboardCard: FunctionComponent<ICoreInfoDashboardCard> = (props) => {
    const { roofLayers, roofLayerSectionRef, setRoofLayers } = props;

    return (
        <DashboardCard headerTitle='Core Info' headerIcon={<FormatAlignJustify />}>
            <Grid container item direction='row' sx={{ padding: '24px' }}>
                <Grid item container>
                    {roofLayers.length < 1 ? (
                        <Typography>No layers defined for this roof section.</Typography>
                    ) : (
                        <RoofSectionCoreInfo
                            ref={roofLayerSectionRef}
                            roofLayers={[...roofLayers].sort((x, y) => (x.order > y.order ? 1 : -1))}
                            setRoofLayers={setRoofLayers}
                            viewOnly={true}
                        />
                    )}
                </Grid>
            </Grid>
        </DashboardCard>
    );
};
