import { FormLabel, FormHelperText, FormControl, TextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { FunctionComponent } from "react";
import { IFormCurrencyInputProps } from "./types";
import { NumericFormat } from "react-number-format";

export const FormCurrencyInput: FunctionComponent<IFormCurrencyInputProps> = React.memo(props => {
    const {
        disabled,
        error,
        errorText,
        fullWidth,
        label,
        labelFontSize,
        onBlur,
        onChange,
        required,
        value,
        sx,
        debounceTime = 300
    } = props;

    const [fieldValue, setFieldValue] = useState(value);

    useEffect(() => {
        setFieldValue(value);
    }, [value]);

    const debouncedHandleChange = useMemo(
        () => debounce((values, sourceInfo) => {
            onChange(values, sourceInfo);
        }, debounceTime)
        , [debounceTime, onChange]);

    return (
        <FormControl error={error} fullWidth={fullWidth} required={required} disabled={disabled}>
            <FormLabel sx={{ fontSize: labelFontSize }}>{label}</FormLabel>
            <NumericFormat
                value={fieldValue ?? null}
                prefix="$"
                thousandSeparator
                decimalScale={2}
                placeholder="$0.00"
                fixedDecimalScale
                onValueChange={
                    (values, sourceInfo) => {
                        setFieldValue(values.value);
                        debouncedHandleChange(values, sourceInfo);
                    }
                }
                onBlur={onBlur}
                customInput={TextField}
                sx={sx}
                disabled={disabled}
            />

            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
});