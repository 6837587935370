import { Build, Person } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Typography
} from '@mui/material';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormButton, FormSection } from '..';
import AuthenticatedComponent from '../../../auth';
import { emptyGuid, usStates } from '../../../models';
import { ContractorDto } from '../../../models/ContractorDto';
import { useGetContractorsQuery } from '../../../store/apis/contractor-api';
import { useFailedActionSnackbar } from '../../../util/customHooks';
import { isEmail } from '../../../util/isEmail';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { DashboardCard } from '../../Dashboard';
import { ContractorContacts } from './ContractorContacts';
import { IContractorFormProps } from './types';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { FormInput } from '../FormFields';

export const ContractorForm: FunctionComponent<IContractorFormProps> = React.memo((props) => {
    const { save, cancel, initValues, isDialogView, open } = props;
    const [name, setName] = useState(initValues ? initValues.name : '');
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [addressLine1, setAddressLine1] = useState(initValues ? initValues.addressLine1 : '');
    const [addressLine2, setAddressLine2] = useState(initValues ? initValues.addressLine2 : '');
    const [city, setCity] = useState(initValues ? initValues.city : '');
    const [state, setState] = useState(initValues ? initValues.state : '');
    const [zipCode, setZipCode] = useState(initValues ? initValues.zipCode : '');
    const [dispatchId, setDispatchId] = useState(initValues ? initValues.dispatchId : '');
    const [parentContractor, setParentContractor] = useState<ContractorDto | null>(initValues?.parentContractor ?? null);
    const [email, setEmail] = useState(initValues ? initValues.email : '');
    const [phoneNumber, setPhoneNumber] = useState(initValues ? initValues.phoneNumber : '');
    const [isStandard, setIsStandard] = useState(initValues ? initValues.isStandard : true);
    const [isInstalling, setIsInstalling] = useState(initValues ? initValues.isInstalling : false);
    const [formChanged, setFormChanged] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        ADDRESS_LINE: '',
        CITY: '',
        STATE: '',
        ZIP_CODE: '',
        DISPATCH_ID: '',
        EMAIL: '',
        PARENT_CONTRACTOR_ID: '',
    });
    const { data: otherContractors, isLoading: isLoadingOtherContractors, isError: isGetOtherContractorsError } = useGetContractorsQuery({ pageSize: 100000, includeInactive: true });

    useFailedActionSnackbar('retrieving', 'Contractors', isGetOtherContractorsError);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    }, []);

    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setName(event.target.value);
    }, []);

    const handleAddressLine1Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setAddressLine1(event.target.value);
    }, []);

    const handleAddressLine2Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setAddressLine2(event.target.value);
    }, []);

    const handleCityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setCity(event.target.value);
    }, []);

    const handleStateChange = useCallback((event: SelectChangeEvent) => {
        setFormChanged(true);
        setState(event.target.value);
    }, []);

    const handleZipCodeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setZipCode(event.target.value);
    }, []);

    const handleDispatchIDChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setDispatchId(event.target.value);
    }, []);

    const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setEmail(event.target.value);
    }, []);

    const handlePhoneChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPhoneNumber(event.target.value);
    }, []);

    const handleIsStandardChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsStandard(event.target.checked);
        setDispatchId(undefined);
    }, []);

    const handleIsInstallingChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsInstalling(event.target.checked);
    }, []);

    const validate = useCallback((fieldName: string) => {
        let isValid = false;
        if (fieldName === 'NAME') {
            if (name) {
                fieldErrors.NAME = '';
                isValid = true;
            } else {
                fieldErrors.NAME = 'Client Name is required';
                isValid = false;
            }
        } else if (fieldName === 'ADDRESS_LINE') {
            if (addressLine1) {
                fieldErrors.ADDRESS_LINE = '';
                isValid = true;
            } else {
                fieldErrors.ADDRESS_LINE = 'Address Line 1 is required';
                isValid = false;
            }
        } else if (fieldName === 'CITY') {
            if (city) {
                fieldErrors.CITY = '';
                isValid = true;
            } else {
                fieldErrors.CITY = 'City is required';
                isValid = false;
            }
        } else if (fieldName === 'STATE') {
            if (state) {
                fieldErrors.STATE = '';
                isValid = true;
            } else {
                fieldErrors.STATE = 'State is required';
                isValid = false;
            }
        } else if (fieldName === 'ZIP_CODE') {
            if (zipCode) {
                fieldErrors.ZIP_CODE = '';
                isValid = true;
            } else {
                fieldErrors.ZIP_CODE = 'Zip Code is required';
                isValid = false;
            }
        } else if (fieldName === 'DISPATCH_ID') {
            if (dispatchId || !isStandard) {
                fieldErrors.DISPATCH_ID = '';
                isValid = true;
            } else {
                fieldErrors.DISPATCH_ID = 'Dispatch ID is required';
                isValid = false;
            }
        } else if (fieldName === 'EMAIL') {
            if (isEmail(email) || email === '') {
                fieldErrors.EMAIL = '';
                isValid = true;
            } else {
                fieldErrors.EMAIL = 'Must be a valid email address';
                isValid = false;
            }
        }
        setFieldErrors({
            NAME: fieldErrors.NAME,
            ADDRESS_LINE: fieldErrors.ADDRESS_LINE,
            CITY: fieldErrors.CITY,
            STATE: fieldErrors.STATE,
            ZIP_CODE: fieldErrors.ZIP_CODE,
            DISPATCH_ID: fieldErrors.DISPATCH_ID,
            EMAIL: fieldErrors.EMAIL,
            PARENT_CONTRACTOR_ID: fieldErrors.PARENT_CONTRACTOR_ID,
        });
        return isValid;
    }, [addressLine1, city, dispatchId, email, fieldErrors, isStandard, name, state, zipCode]);

    const onFieldBlur = useCallback((fieldName: string) => () => {
        validate(fieldName);
    }, [validate]);

    const formIsValid = useCallback(() => {
        let isValid = validate('NAME');
        isValid = validate('ADDRESS_LINE') && isValid;
        isValid = validate('CITY') && isValid;
        isValid = validate('STATE') && isValid;
        isValid = validate('ZIP_CODE') && isValid;
        isValid = validate('DISPATCH_ID') && isValid;
        isValid = validate('EMAIL') && isValid;

        return isValid;
    }, [validate]);

    const handleSave = useCallback((event: any) => {
        event.preventDefault();
        if (formIsValid()) {
            save({
                ...initValues,
                id: initValues ? initValues.id : emptyGuid,
                isActive: isActive,
                name: name,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                zipCode: zipCode,
                email: email,
                phoneNumber: phoneNumber,
                dispatchId: dispatchId,
                isStandard: isStandard,
                isInstalling: isInstalling,
                parentContractorId: parentContractor?.id,
            });
        }
    }, [addressLine1, addressLine2, city, dispatchId, email, formIsValid, initValues, isActive, isInstalling, isStandard, name, parentContractor?.id, phoneNumber, save, state, zipCode]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    const buildFormFields = useCallback(() => {
        return (
            <>
                <Grid item container direction='row' spacing={8} alignItems='center'>
                    <Grid item xs={4}>
                        <FormInput
                            label='Contractor Name'
                            onBlur={onFieldBlur('NAME')}
                            value={name}
                            fullWidth
                            required
                            error={fieldErrors.NAME !== ''}
                            errorText={fieldErrors.NAME}
                            onChange={handleNameChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormInput
                            label='Address Line 1'
                            onBlur={onFieldBlur('ADDRESS_LINE')}
                            value={addressLine1}
                            fullWidth
                            required
                            error={fieldErrors.ADDRESS_LINE !== ''}
                            errorText={fieldErrors.ADDRESS_LINE}
                            onChange={handleAddressLine1Change}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormInput
                            label='Address Line 2'
                            value={addressLine2}
                            fullWidth
                            onChange={handleAddressLine2Change}
                        />
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={8}>
                    <Grid item xs={4}>
                        <FormInput
                            label='City'
                            onBlur={onFieldBlur('CITY')}
                            value={city}
                            fullWidth
                            required
                            error={fieldErrors.CITY !== ''}
                            errorText={fieldErrors.CITY}
                            onChange={handleCityChange}
                        />
                    </Grid>
                    <Grid item container direction='row' spacing={4} xs={4}>
                        <Grid item xs={8}>
                            <FormControl error={fieldErrors.STATE !== ''} fullWidth required>
                                <FormLabel>State</FormLabel>
                                <Select value={state} onChange={handleStateChange} onBlur={onFieldBlur('STATE')}>
                                    {usStates.map((usState) => (
                                        <MenuItem key={usState.abbreviation} value={usState.abbreviation}>
                                            {usState.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{fieldErrors.STATE}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Zip Code'
                                onBlur={onFieldBlur('ZIP_CODE')}
                                value={zipCode}
                                fullWidth
                                required
                                error={fieldErrors.ZIP_CODE !== ''}
                                errorText={fieldErrors.ZIP_CODE}
                                onChange={handleZipCodeChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={4} xs={4}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Dispatch ID'
                                onBlur={onFieldBlur('DISPATCH_ID')}
                                value={dispatchId ?? ''}
                                fullWidth
                                required={isStandard}
                                error={fieldErrors.DISPATCH_ID !== ''}
                                errorText={fieldErrors.DISPATCH_ID}
                                onChange={handleDispatchIDChange}
                                disabled={!isStandard}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl error={fieldErrors.PARENT_CONTRACTOR_ID !== ''} disabled={isLoadingOtherContractors || isGetOtherContractorsError} fullWidth>
                                <FormLabel>Parent Contractor</FormLabel>
                                <IEntityAutocomplete
                                    options={otherContractors?.pageResults.filter((contractor) => contractor.id !== initValues?.id)}
                                    onChange={(e, value) => {
                                        setFormChanged(true);
                                        setParentContractor(value);
                                    }}
                                    value={parentContractor}
                                    getOptionLabel={(option: ContractorDto) => `${option.dispatchId} - ${option.name}`}
                                    isLoading={isLoadingOtherContractors}
                                />
                                <FormHelperText>{fieldErrors.PARENT_CONTRACTOR_ID}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={8} alignItems='center'>
                    <Grid item xs={4}>
                        <FormInput
                            label='Email'
                            onBlur={onFieldBlur('EMAIL')}
                            value={email}
                            fullWidth
                            required
                            error={fieldErrors.EMAIL !== ''}
                            errorText={fieldErrors.EMAIL}
                            onChange={handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormInput
                            label='Phone'
                            value={phoneNumber}
                            onBlur={onFieldBlur('PHONE')}
                            fullWidth
                            onChange={handlePhoneChange}
                        />
                    </Grid>
                    <Grid item container direction='row' xs={4} spacing={4}>
                        <Grid item>
                            <FormControlLabel
                                control={<Switch checked={isStandard} onChange={handleIsStandardChange} />}
                                label='Standard'
                                labelPlacement='start'
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={<Switch checked={isInstalling} onChange={handleIsInstallingChange} />}
                                label='Installing'
                                labelPlacement='start'
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }, [fieldErrors.NAME, fieldErrors.ADDRESS_LINE, fieldErrors.CITY, fieldErrors.STATE, fieldErrors.ZIP_CODE, fieldErrors.DISPATCH_ID, fieldErrors.PARENT_CONTRACTOR_ID, fieldErrors.EMAIL, name, handleNameChange, onFieldBlur, addressLine1, handleAddressLine1Change, addressLine2, handleAddressLine2Change, city, handleCityChange, state, handleStateChange, zipCode, handleZipCodeChange, dispatchId, handleDispatchIDChange, isLoadingOtherContractors, isGetOtherContractorsError, otherContractors?.pageResults, parentContractor, email, handleEmailChange, phoneNumber, handlePhoneChange, isStandard, handleIsStandardChange, isInstalling, handleIsInstallingChange, initValues?.id]);

    const buildFormPage = useCallback(() => {
        return (
            <Grid component='form' container direction='column' spacing={3} autoComplete='off'>
                <Grid position='sticky' item container direction='row' alignItems='center'>
                    <Grid item container direction='column' justifyContent='start' xs={8}>
                        <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                            <Build /> {name ? name : 'New Contractor'}
                        </Typography>
                        <NavBreadcrumbs
                            links={[
                                { label: 'Home', navLink: '/' },
                                { label: 'Contractors', navLink: '/contractors' },
                            ]}
                            currentPageLabel={name ? name : 'New Contractor'}
                        />
                    </Grid>
                    <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                        <Grid item>
                            <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                        </Grid>
                        <Grid item>
                            {formChanged || !initValues ? (
                                <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                    Cancel
                                </FormButton>
                            ) : (
                                <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                    Close
                                </FormButton>
                            )}
                        </Grid>
                        <Grid item>
                            <FormButton itemType='button' variant='contained' color='primary' type='button' onClick={handleSave}>
                                Save
                            </FormButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormSection>
                        {buildFormFields()}
                    </FormSection>
                </Grid>
                {initValues ? (
                    <AuthenticatedComponent
                        requiredPermissions={['read:contacts']}
                        component={
                            <Grid item>
                                <DashboardCard headerTitle='Contacts' headerIcon={<Person />}>
                                    <ContractorContacts contractorId={initValues.id} />
                                </DashboardCard>
                            </Grid>
                        }
                    />
                ) : (
                    <></>
                )}
            </Grid>
        );
    }, [buildFormFields, formChanged, handleActiveToggleChange, handleCancel, handleSave, initValues, isActive, name]);

    const buildDialogForm = useCallback(() => {
        return (
            <Dialog maxWidth='xl' fullWidth open={open ?? false} onClose={handleCancel}>
                <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                    <Grid container direction='row' alignItems='center' gap={1}>
                        <Build />
                        <Typography fontSize='inherit' fontWeight='inherit'>
                            New Contractor
                        </Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container sx={{ padding: '20px' }}>
                        {buildFormFields()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={handleSave}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }, [buildFormFields, handleCancel, handleSave, open]);

    return (
        isDialogView ? <>{buildDialogForm()}</> : <>{buildFormPage()}</>
    );
});
