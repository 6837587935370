import { serverApi } from '..';
import { CoreLayerTypeDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const coreLayerTypeApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getCoreLayerTypes: builder.query<CoreLayerTypeDto[], ICoreLayerTypeQueryParams>({
            query: (params) => buildSearchQueryRoute(`corelayertype`, params),
            providesTags: [{ type: 'Core Layer Type' }]
        })
    })
});

export const {
    useGetCoreLayerTypesQuery
} = coreLayerTypeApi;

export interface ICoreLayerTypeQueryParams {
    includeInactive?: boolean;
}