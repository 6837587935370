import { serverApi } from '..';
import { BudgetLineItemDto, FacilityDto, QueryResultsDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';
import { ChildSearchQueryParams, FacilityRoofMapPdfQueryParams, SearchQueryParams } from '../types';

const facilityApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getFacilities: builder.query<QueryResultsDto<FacilityDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('facility', params),
            providesTags: [{ type: 'Facility' }],
        }),
        getClientFacilities: builder.query<QueryResultsDto<FacilityDto>, ChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`facility/client/${params.parentId}`, params.params),
            providesTags: [{ type: 'Facility' }],
        }),
        getBudgetLineItemsForFacility: builder.query<BudgetLineItemDto[], string>({
            query: (facilityId) => ({ url: `facility/${facilityId}/budgetLineItems` }),
            providesTags: [{ type: 'Budget Line Item' }],
        }),
        getFacility: builder.query<FacilityDto, string>({
            query: (facilityId) => ({ url: `facility/${facilityId}` }),
            providesTags: [{ type: 'Facility' }],
        }),
        getFacilityRoofMapPdf: builder.query<string, FacilityRoofMapPdfQueryParams>({
            query: (params) => ({
                url: `facility/${params.facilityId}/roofMapPdf?underWarrantyFilter=${params.underWarrantyFilter}&roofConditionFilter=${params.roofConditionFilter}&roofLeaksFilter=${params.roofLeaksFilter}`,
            }),
            providesTags: [{ type: 'Facility' }],
        }),
        createFacility: builder.mutation<FacilityDto, FacilityDto>({
            query: (facilityDto) => ({
                url: 'facility',
                method: 'POST',
                body: facilityDto,
            }),
            invalidatesTags: [{ type: 'Facility' }],
        }),
        updateFacility: builder.mutation<void, FacilityDto>({
            query: (facilityDto) => ({
                url: 'facility',
                method: 'PUT',
                body: facilityDto,
            }),
            invalidatesTags: [{ type: 'Facility' }],
        }),
        archiveFacility: builder.mutation<void, string>({
            query: (id) => ({
                url: `facility/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Facility' }],
        }),
        unarchiveFacility: builder.mutation<void, string>({
            query: (id) => ({
                url: `facility/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Facility' }],
        }),
    }),
});

export const {
    useGetFacilitiesQuery,
    useLazyGetFacilityQuery,
    useGetClientFacilitiesQuery,
    useGetBudgetLineItemsForFacilityQuery,
    useGetFacilityQuery,
    useCreateFacilityMutation,
    useUpdateFacilityMutation,
    useArchiveFacilityMutation,
    useUnarchiveFacilityMutation,
    useLazyGetClientFacilitiesQuery,
    useLazyGetFacilityRoofMapPdfQuery,
} = facilityApi;
