import { AttachmentMethodQueryParams } from "../store/apis/attachment-method-api";

export const buildAttachmentMethodQueryRoute = (baseRoute: string, params: AttachmentMethodQueryParams) => {
    let route = baseRoute;
    let addedParam = false;
    if (params.layerType != null) {
        if (addedParam) {
            route = `${route}&layerType=${params.layerType}`;
        } else {
            route = `${route}?layerType=${params.layerType}`;
            addedParam = true;
        }
    }
    if (params.includeInactive != null) {
        if (addedParam) {
            route = `${route}&includeInactive=${params.includeInactive}`;
        } else {
            route = `${route}?includeInactive=${params.includeInactive}`;
            addedParam = true;
        }
    }
    return { url: route };
};