import { serverApi } from '..';
import { DeckTypeDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const deckTypeApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeckTypes: builder.query<DeckTypeDto[], DeckTypeQueryParams>({
            query: (params) => buildSearchQueryRoute(`deckType`, params),
            providesTags: [{ type: 'Deck Type' }],
        }),
        createDeckType: builder.mutation<DeckTypeDto, DeckTypeDto>({
            query: (dto) => ({
                url: `deckType`,
                method: 'POST',
                body: dto,
            }),
            invalidatesTags: [{ type: 'Deck Type' }],
        }),
    }),
});

export const { useGetDeckTypesQuery, useCreateDeckTypeMutation } = deckTypeApi;

export interface DeckTypeQueryParams {
    includeInactive?: boolean;
}
