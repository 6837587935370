import { DocumentFolderSearchQueryParams, serverApi } from '..';
import { DocumentFolderDto, QueryResultsDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const documentFolderApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDocumentFolders: builder.query<QueryResultsDto<DocumentFolderDto>, DocumentFolderSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`documentFolder`, params),
            providesTags: [{ type: 'DocumentFolder' }],
        }),
        getDocumentFolder: builder.query<DocumentFolderDto, string>({
            query: (id) => `documentFolder/${id}`,
            providesTags: [{ type: 'DocumentFolder' }],
        }),
        createDocumentFolder: builder.mutation<DocumentFolderDto, DocumentFolderDto>({
            query: (documentFolderDto) => ({
                url: `documentFolder`,
                method: 'POST',
                body: documentFolderDto,
            }),
            invalidatesTags: [{ type: 'DocumentFolder' }],
        }),
        updateDocumentFolder: builder.mutation<void, DocumentFolderDto>({
            query: (documentFolderDto) => ({
                url: 'documentFolder',
                method: 'PUT',
                body: documentFolderDto,
            }),
            invalidatesTags: [{ type: 'DocumentFolder' }],
        }),
        archiveDocumentFolder: builder.mutation<void, string>({
            query: (id) => ({
                url: `documentFolder/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'DocumentFolder' }],
        }),
        deleteDocumentFolder: builder.mutation<void, string>({
            query: (id) => ({
                url: `documentFolder/${id}/hard`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'DocumentFolder' }],
        }),
        unarchiveDocumentFolder: builder.mutation<void, string>({
            query: (id) => ({
                url: `documentFolder/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'DocumentFolder' }],
        }),
    }),
});

export const {
    useGetDocumentFoldersQuery,
    useLazyGetDocumentFoldersQuery,
    useGetDocumentFolderQuery,
    useLazyGetDocumentFolderQuery,
    useCreateDocumentFolderMutation,
    useUpdateDocumentFolderMutation,
    useArchiveDocumentFolderMutation,
    useDeleteDocumentFolderMutation,
    useUnarchiveDocumentFolderMutation,
} = documentFolderApi;
