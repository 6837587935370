import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { IFilterWorkOrderDialogProps as IFilterWorkOrdersAndCapitalProjectsDialogProps } from './types';
import { useGetWorkOrderStatusesQuery } from '../../../store/apis/work-order-status-api';
import { useGetContractorsQuery } from '../../../store/apis/contractor-api';
import { useGetClientsQuery } from '../../../store/apis/client-api';
import { useGetFacilitiesQuery } from '../../../store/apis/facility-api';
import { useGetWorkOrderTypesQuery } from '../../../store/apis/work-order-type-api';
import { ClientDto, FacilityDto, OrderType, WorkOrderStatusDto, WorkOrderTypeDto } from '../../../models';
import { ContractorDto } from '../../../models/ContractorDto';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { DatePicker } from '@mui/lab';
import { StatusConverterMap } from './StatusConverterMap';
import AuthenticatedComponent from '../../../auth/AuthenticatedComponent';

export const FilterWorkOrdersAndCapitalProjectsDialog: FunctionComponent<IFilterWorkOrdersAndCapitalProjectsDialogProps> = (props) => {
    const {
        open,
        close,
        dispatchDateAfterFilter,
        dispatchDateBeforeFilter,
        statusFilter,
        contractorFilter,
        clientFilter,
        facilityFilter,
        typeFilter,
        setStatusFilter,
        setDispatchDateAfterFilter,
        setDispatchDateBeforeFilter,
        setContractorFilter,
        setClientFilter,
        setFacilityFilter,
        setTypeFilter,
        isCapitalProject = true,
        isOutstandingProposal,
        isClientFacing
    } = props;

    // Filter Values
    const [dispatchDateAfter, setDispatchDateAfter] = useState(dispatchDateAfterFilter ?? null);
    const [dispatchDateBefore, setDispatchDateBefore] = useState(dispatchDateBeforeFilter ?? null);
    const [contractor, setContractor] = useState(contractorFilter ?? null);
    const [client, setClient] = useState<ClientDto | null>(clientFilter ?? null);
    const [facility, setFacility] = useState<FacilityDto | null>(facilityFilter ?? null);
    const [type, setType] = useState<WorkOrderTypeDto | null>(typeFilter ?? null);
    const [status, setStatus] = useState<WorkOrderStatusDto | null>(statusFilter ?? null);

    // Data Fetching
    const { data: statuses, isLoading: statusesLoading } = useGetWorkOrderStatusesQuery({});
    const { data: contractors, isLoading: contractorsLoading } = useGetContractorsQuery({ pageSize: 100000, includeNonStandard: false });
    const { data: clients, isLoading: clientsLoading } = useGetClientsQuery({ pageSize: 100000 });
    const { data: facilities, isLoading: facilitiesLoading } = useGetFacilitiesQuery({ pageSize: 100000 });
    const { data: types, isLoading: typesLoading } = useGetWorkOrderTypesQuery({
        pageSize: 100000,
        type: isCapitalProject ? OrderType.CapitalProject : OrderType.WorkOrder,
    });

    const isFormDirty = useMemo(() => {
        const isFormClean =
            dispatchDateAfter === dispatchDateAfterFilter &&
            dispatchDateBefore === dispatchDateBeforeFilter &&
            contractor === (contractorFilter ?? null) &&
            client === (clientFilter ?? null) &&
            facility === (facilityFilter ?? null) &&
            type === (typeFilter ?? null) &&
            status === (statusFilter ?? null);
        return !isFormClean;
    }, [
        client,
        clientFilter,
        contractor,
        contractorFilter,
        dispatchDateAfter,
        dispatchDateAfterFilter,
        dispatchDateBefore,
        dispatchDateBeforeFilter,
        facility,
        facilityFilter,
        status,
        statusFilter,
        type,
        typeFilter,
    ]);

    const clearFilters = useCallback(() => {
        setStatus(null);
        setDispatchDateAfter(null);
        setDispatchDateBefore(null);
        setContractor(null);
        setType(null);
        setClient(null);
        setFacility(null);
    }, []);

    const filter = useCallback(() => {
        setStatusFilter(status ?? undefined);
        setDispatchDateAfterFilter(dispatchDateAfter ?? null);
        setDispatchDateBeforeFilter(dispatchDateBefore ?? null);
        setClientFilter(client ?? null);
        setFacilityFilter(facility ?? null);
        setTypeFilter(type ?? undefined);
        setContractorFilter(contractor ?? undefined);
        close();
    }, [
        client,
        close,
        contractor,
        dispatchDateAfter,
        dispatchDateBefore,
        facility,
        setClientFilter,
        setContractorFilter,
        setDispatchDateAfterFilter,
        setDispatchDateBeforeFilter,
        setFacilityFilter,
        setStatusFilter,
        setTypeFilter,
        status,
        type,
    ]);

    const mappedStatuses = StatusConverterMap.getWorkOrderStatusMappedValues();
    const filteredStatuses = useMemo(() => isClientFacing ? mappedStatuses : statuses?.pageResults, [isClientFacing, mappedStatuses, statuses]);

    return (
        <Dialog open={open} onClose={close} maxWidth='md' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', marginBottom: '16px', fontWeight: 'bold' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <FilterList />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Filter {isCapitalProject ? 'Capital Projects' : 'Work Orders'}
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' spacing={2}>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Owner</FormLabel>
                                <IEntityAutocomplete
                                    options={clients?.pageResults.filter((option) => option.isActive) ?? []}
                                    onChange={(e, value) => {
                                        setClient(value ?? null);
                                    }}
                                    value={client}
                                    getOptionLabel={(option: ContractorDto) => option.name}
                                    isLoading={clientsLoading}
                                    disabled={isOutstandingProposal}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Facility</FormLabel>
                                <IEntityAutocomplete
                                    options={facilities?.pageResults.filter((option) => option.isActive) ?? []}
                                    onChange={(e, value) => {
                                        setFacility(value ?? null);
                                    }}
                                    value={facility}
                                    getOptionLabel={(option: FacilityDto) => option.name}
                                    isLoading={facilitiesLoading}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Type</FormLabel>
                                <IEntityAutocomplete
                                    options={types?.pageResults}
                                    onChange={(e, value) => {
                                        setType(value ?? null);
                                    }}
                                    value={type}
                                    getOptionLabel={(option: WorkOrderTypeDto) => option.name}
                                    isLoading={typesLoading}
                                    disabled={isOutstandingProposal}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <FormLabel>{isCapitalProject ? 'Completion' : 'Dispatch'} Date After</FormLabel>
                                <DatePicker
                                    value={dispatchDateAfter ?? null}
                                    onChange={(date: Date | null | undefined) => {
                                        if (date) {
                                            if (!isNaN(Date.parse(date.toString()))) {
                                                setDispatchDateAfter(date ? new Date(date) : null);
                                            }
                                        }
                                    }}
                                    mask='__/__/____'
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <FormLabel>{isCapitalProject ? 'Completion' : 'Dispatch'} Date Before</FormLabel>
                                <DatePicker
                                    value={dispatchDateBefore ?? null}
                                    onChange={(date: Date | null | undefined) => {
                                        if (date) {
                                            if (!isNaN(Date.parse(date.toString()))) {
                                                setDispatchDateBefore(date ? new Date(date) : null);
                                            }
                                        }
                                    }}
                                    mask='__/__/____'
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={2}>
                        <AuthenticatedComponent
                            requiredPermissions={['edit:workorders']}
                            component={
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Dispatch ID</FormLabel>
                                        <IEntityAutocomplete
                                            options={contractors?.pageResults}
                                            onChange={(e, value) => {
                                                setContractor(value ?? null);
                                            }}
                                            value={contractor}
                                            getOptionLabel={(option: ContractorDto) => `${option.dispatchId} - ${option.name}`}
                                            isLoading={contractorsLoading}
                                        />
                                    </FormControl>
                                </Grid>
                            }
                        />
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Status</FormLabel>
                                <IEntityAutocomplete
                                    options={filteredStatuses}
                                    onChange={(e, value) => {
                                        setStatus(value ?? null);
                                    }}
                                    value={status}
                                    getOptionLabel={(option: WorkOrderStatusDto) => option.name}
                                    isLoading={statusesLoading}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item xs={2}>
                        <Button variant='text' color='secondary' onClick={clearFilters}>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={close}>
                                {isFormDirty ? 'Cancel' : 'Close'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={filter}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
