import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    LinearProgress,
    OutlinedInput,
} from '@mui/material';
import { uploadFileToCloud } from '../../../util';
import { useGetSasLinkQuery } from '../../../store/apis/cloud-storage-api';
import { ApiError } from '../ApiError';
import { emptyGuid } from '../../../models';
import { v4 as uuidv4 } from 'uuid';
import { IUploadPhotoDialogProps } from './types';

export const UploadPhotoDialog: FunctionComponent<IUploadPhotoDialogProps> = (props) => {
    const { open, onClose, afterPhotoUpload, allowedFileTypes, shouldResizeFiles = true } = props;
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewLink, setPreviewLink] = useState('');
    const [shouldUpload, setShouldUpload] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const fileSelectRef = useRef<null | HTMLInputElement>(null);
    const { data: fileUploadLink, isLoading: isLinkLoading, error, refetch: refetchLink } = useGetSasLinkQuery();
    const [linkRefreshed, setLinkRefreshed] = useState(false);
    const [errorText, setErrorText] = useState('');

    const startFileUploadCallback = useCallback(async () => {
        setIsUploading(true);
        const response = await uploadFileToCloud(fileUploadLink!.link, selectedFile!, shouldResizeFiles);
        if (response.isSuccessful) {
            afterPhotoUpload(
                uuidv4(),
                {
                    id: emptyGuid,
                    isActive: true,
                    displayName: selectedFile!.name,
                    sourceName: response.fileName!,
                    caption: '',
                    order: 0
                },
                previewLink
            );
            setErrorText('');
            setSelectedFile(null);
            setPreviewLink('');
            if (fileSelectRef.current) {
                fileSelectRef.current.value = '';
            }
            onClose();
        } else {
            setErrorText('Error uploading file. Please try again.');
        }
        setLinkRefreshed(false);
        setShouldUpload(false);
        setIsUploading(false);
    }, [fileUploadLink, selectedFile, shouldResizeFiles, afterPhotoUpload, previewLink, onClose]);

    useEffect(() => {
        if (fileUploadLink && !isLinkLoading && selectedFile && shouldUpload && !isUploading) {
            if (!linkRefreshed) {
                refetchLink(); // to prevent link expiration
                setLinkRefreshed(true);
            } else if (!isLinkLoading) {
                startFileUploadCallback();
            }
        }
    }, [
        fileUploadLink,
        selectedFile,
        shouldUpload,
        isUploading,
        startFileUploadCallback,
        onClose,
        isLinkLoading,
        linkRefreshed,
        refetchLink,
        afterPhotoUpload,
    ]);

    const openFilePicker = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (fileSelectRef.current) {
            fileSelectRef.current.click();
        }
    };

    const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files?.length > 0) {
            const file = e.target.files.item(0)!;
            if (allowedFileTypes?.find((x) => 'image/' + x === file.type)) {
                setSelectedFile(file);
                setPreviewLink(URL.createObjectURL(file));
            } else {
                setErrorText('Please use an allowed file type.');
            }
        }
    };

    const onSave = () => {
        if (selectedFile) {
            setErrorText('');
            setShouldUpload(true);
        } else {
            setErrorText('You must select a file to upload.');
        }
    };

    const beforeClose = () => {
        setErrorText('');
        setSelectedFile(null);
        setPreviewLink('');
        if (fileSelectRef.current) {
            fileSelectRef.current.value = '';
        }
        onClose();
    };

    if (error) {
        return <ApiError onReloadClick={refetchLink} />;
    }

    return (
        <Dialog onClose={beforeClose} open={open} maxWidth='sm' fullWidth>
            <DialogTitle>Upload New Photo</DialogTitle>
            <DialogContent>
                <Grid container direction='column' spacing={2}>
                    <Grid item container direction='row' alignItems='center'>
                        <input ref={fileSelectRef} style={{ display: 'none' }} type='file' accept='image/*' onChange={onFileSelected} />
                        <Grid item onClick={openFilePicker} xs={3}>
                            <Button variant='contained' disabled={isUploading} color='secondary' sx={{ marginTop: '12px' }}>
                                Choose File
                            </Button>
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl fullWidth required>
                                <FormLabel>Filename</FormLabel>
                                <OutlinedInput value={selectedFile?.name} disabled />
                                <FormHelperText>{allowedFileTypes && 'Allowed Types: (' + allowedFileTypes?.join(', ') + ')'}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {isUploading && (
                        <Grid item>
                            <LinearProgress />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <FormHelperText error={errorText !== ''} sx={{ width: '60%' }}>
                    {errorText}
                </FormHelperText>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
