import { FormatListBulletedOutlined, InsertChart, LockOpen, Map, OpenInFull, PictureAsPdf, Send, Undo, Visibility } from '@mui/icons-material';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormButton, FormSection, ReportType } from '..';
import AuthenticatedComponent from '../../../auth';
import { WorkOrderListViewDto, emptyGuid, usStates } from '../../../models';
import { ReportListItemDto } from '../../../models/ReportListItemDto';
import { ReportPdfStatus } from '../../../models/ReportPdfDto';
import { useCreatePdfMutation, useCreateReportCopyMutation, useLazyGetReportPdfQuery } from '../../../store/apis/report-api';
import { useLazyGetDoesReportExistForWorkOrderQuery } from '../../../store/apis/report-list-api';
import { useGetRoofSectionReportsQuery } from '../../../store/apis/roof-section-report-api';
import { useGetWorkOrdersQuery } from '../../../store/apis/work-order-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { VisibilityConfig, VisibilityControl, currentVisibilityOrDefault } from '../../CommonInputs';
import { DashboardCard } from '../../Dashboard';
import { ConfirmDialog } from '../../core/ConfirmDialog';
import { ConfirmPdfDialog } from '../../core/ConfirmPdfDialog/ConfirmPdfDialog';
import LoadingIndicator from '../../core/LoadingIndicator';
import { MenuButton } from '../../core/MenuButton';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { DateTimePicker, FormInput } from '../FormFields';
import { SelectClientReportDialog } from '../SelectClientReport';
import { SelectWorkOrdersDialog } from '../SelectWorkOrder';
import { useWorkOrderActions } from '../WorkOrderForm/Hooks/useWorkOrderActions';
import { DispatchDialogView } from '../WorkOrderForm/WorkOrderDispatch';
import { ClientReportRoofSectionsCard } from './ClientReportRoofSectionsCard';
import { ImmediateRepairsChecklistItemListView } from './ImmediateRepairsChecklistItemListView';
import { IReportFormProps } from './types';

export const ClientReportForm: FC<IReportFormProps> = ({ save, cancel, initValues, workOrderId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [name, setName] = useState(initValues ? initValues.reportName : '');
    const [visibilityConfig, setVisibilityConfig] = useState(currentVisibilityOrDefault(initValues));
    const [workOrder, setWorkOrder] = useState(initValues ? initValues.workOrder : undefined);
    const [workOrderOpen, setWorkOrderOpen] = useState(false);
    const [reportDate, setReportDate] = useState<Date | null | undefined>(initValues && initValues.reportDate ? new Date(initValues.reportDate) : new Date());
    const [reportType, setReportType] = useState(
        initValues && initValues.reportType !== undefined ? (initValues.reportType === 0 ? 'RoofSMART' : 'Moisture Guard') : undefined
    );
    const [isSelectWorkOrdersDialogOpen, setIsSelectWorkOrdersDialogOpen] = useState(false);
    const [isSelectExistingReportDialogOpen, setIsSelectExistingReportDialogOpen] = useState(false);
    const clientReportType = -1;
    const [createReportPdf, { data: reportPdfData, isLoading: isGeneratingReport }] = useCreatePdfMutation();
    const [createReportCopy, { data: copy, isSuccess: copySuccess, isError: isCopyError, reset: resetCopy }] = useCreateReportCopyMutation();

    const [doesReportExistForWOrkOrderQuery] = useLazyGetDoesReportExistForWorkOrderQuery();
    const [getReportPdf] = useLazyGetReportPdfQuery();

    const [reportPdf, setReportPdf] = useState(initValues?.reportPdf);
    const roofSectionsRef = useRef<null | HTMLDivElement>(null);
    const checklistItemRef = useRef<null | HTMLDivElement>(null);
    const isLocked = (date?: Date) => {
        if (date && new Date() >= new Date(date)) {
            return true;
        }

        return false;
    };
    const [dialogOpen, setDialogOpen] = useState(false);
    const [addDispatchDialogOpen, setAddDispatchDialogOpen] = useState(false);
    const [confirmRecallOpen, setConfirmRecallOpen] = useState(false);
    const [confirmUnlockOpen, setConfirmUnlockOpen] = useState(false);
    const [isReportLocked] = useState(initValues?.id ? isLocked(initValues.lockDate) : false);

    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        WO_NUMBER: '',
        REPORT_DATE: '',
        REPORT_TYPE: '',
        PRESENTED_TO_CLIENT: '',
        PRESENTED_TO_FACILITY: '',
        PRESENTED_TO_CONTACT_NAME: '',
        PRESENTED_TO_ADDRESS: '',
        PRESENTED_TO_CITY: '',
        PRESENTED_TO_STATE: '',
        PRESENTED_TO_ZIP: '',
    });
    const [changed, setFormChanged] = useState(false);
    const { data: workOrders, isLoading: workOrdersLoading } = useGetWorkOrdersQuery({
        getOnlyOpen: false,
        orderType: 0,
        includeInactive: true,
    });

    const [presentedToClientName, setPresentedToClientName] = useState(initValues ? initValues.presentedToClientName : '');
    const [presentedToFacilityName, setPresentedToFacilityName] = useState(initValues ? initValues.presentedToFacilityName : '');
    const [presentedToContactName, setPresentedToContactName] = useState(initValues ? initValues.presentedToContactName : '');
    const [presentedToAddressLine1, setPresentedToAddressLine1] = useState(initValues ? initValues.presentedToAddress1 : '');
    const [presentedToAddressLine2, setPresentedToAddressLine2] = useState(initValues ? initValues.presentedToAddress2 : '');
    const [presentedToCity, setPresentedToCity] = useState(initValues ? initValues.presentedToCity : '');
    const [presentedToState, setPresentedToState] = useState(initValues ? initValues.presentedToState : '');
    const [presentedToZip, setPresentedToZip] = useState(initValues ? initValues.presentedToZip : '');

    useEffect(() => {
        if (initValues) {
            setWorkOrder(initValues.workOrder);
        }
    }, [initValues]);

    const handlePdfDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    }, []);

    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setName(event.target.value);
    }, []);

    const handleVisibilityChange = useCallback((updatedVisibility: VisibilityConfig) => {
        setFormChanged(true);
        setVisibilityConfig(updatedVisibility);
    }, []);

    const handleReportDateChange = useCallback((value: Date | null | undefined) => {
        setFormChanged(true);
        setReportDate(value);
    }, []);

    const handleReportTypeChange = useCallback((event: SelectChangeEvent) => {
        setFormChanged(true);
        setReportType(event.target.value);
    }, []);

    const handlePresentedToClientNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToClientName(event.target.value);
    }, []);

    const handlePresentedToFacilityNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToFacilityName(event.target.value);
    }, []);

    const handlePresentedToContactNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToContactName(event.target.value);
    }, []);

    const handlePresentedToAddressLine1Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToAddressLine1(event.target.value);
    }, []);

    const handlePresentedToAddressLine2Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToAddressLine2(event.target.value);
    }, []);

    const handlePresentedToCityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToCity(event.target.value);
    }, []);

    const handlePresentedToStateChange = useCallback((event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        setPresentedToState(event.target.value);
    }, []);

    const handlePresentedToZipChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToZip(event.target.value);
    }, []);

    const validate = useCallback(
        (fieldName: string) => {
            let isValid = false;
            if (fieldName === 'NAME') {
                if (name) {
                    fieldErrors.NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.NAME = 'Report name is required';
                    isValid = false;
                }
            } else if (fieldName === 'WO_NUMBER') {
                if (workOrder) {
                    fieldErrors.WO_NUMBER = '';
                    isValid = true;
                } else {
                    fieldErrors.WO_NUMBER = 'Work Order # is required';
                    isValid = false;
                }
            } else if (fieldName === 'REPORT_DATE') {
                if (reportDate) {
                    fieldErrors.REPORT_DATE = '';
                    isValid = true;
                } else {
                    fieldErrors.REPORT_DATE = 'Report Date is required';
                    isValid = false;
                }
            } else if (fieldName === 'REPORT_TYPE') {
                if (reportType) {
                    fieldErrors.REPORT_TYPE = '';
                    isValid = true;
                } else {
                    fieldErrors.REPORT_TYPE = 'Report Type is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CLIENT') {
                if (presentedToClientName) {
                    fieldErrors.PRESENTED_TO_CLIENT = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CLIENT = 'Client is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_FACILITY') {
                if (presentedToFacilityName) {
                    fieldErrors.PRESENTED_TO_FACILITY = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_FACILITY = 'Facility is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CONTACT_NAME') {
                if (presentedToContactName) {
                    fieldErrors.PRESENTED_TO_CONTACT_NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CONTACT_NAME = 'Contact Name is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_ADDRESS') {
                if (presentedToAddressLine1) {
                    fieldErrors.PRESENTED_TO_ADDRESS = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_ADDRESS = 'Address Line 1 is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CITY') {
                if (presentedToCity) {
                    fieldErrors.PRESENTED_TO_CITY = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CITY = 'City is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_STATE') {
                if (presentedToState) {
                    fieldErrors.PRESENTED_TO_STATE = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_STATE = 'State is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_ZIP') {
                if (presentedToZip) {
                    fieldErrors.PRESENTED_TO_ZIP = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_ZIP = 'Zip Code is required';
                    isValid = false;
                }
            }
            setFieldErrors({
                NAME: fieldErrors.NAME,
                WO_NUMBER: fieldErrors.WO_NUMBER,
                REPORT_DATE: fieldErrors.REPORT_DATE,
                REPORT_TYPE: fieldErrors.REPORT_TYPE,
                PRESENTED_TO_CLIENT: fieldErrors.PRESENTED_TO_CLIENT,
                PRESENTED_TO_FACILITY: fieldErrors.PRESENTED_TO_FACILITY,
                PRESENTED_TO_ADDRESS: fieldErrors.PRESENTED_TO_ADDRESS,
                PRESENTED_TO_CITY: fieldErrors.PRESENTED_TO_CITY,
                PRESENTED_TO_CONTACT_NAME: fieldErrors.PRESENTED_TO_CONTACT_NAME,
                PRESENTED_TO_STATE: fieldErrors.PRESENTED_TO_STATE,
                PRESENTED_TO_ZIP: fieldErrors.PRESENTED_TO_ZIP,
            });
            return isValid;
        },
        [
            fieldErrors,
            name,
            presentedToAddressLine1,
            presentedToCity,
            presentedToClientName,
            presentedToContactName,
            presentedToFacilityName,
            presentedToState,
            presentedToZip,
            reportDate,
            reportType,
            workOrder,
        ]
    );

    const formIsValid = useCallback(() => {
        let isValid = validate('NAME');
        isValid = validate('WO_NUMBER') && isValid;
        isValid = validate('REPORT_DATE') && isValid;
        isValid = validate('REPORT_TYPE') && isValid;
        isValid = validate('PRESENTED_TO_CLIENT') && isValid;
        isValid = validate('PRESENTED_TO_FACILITY') && isValid;
        isValid = validate('PRESENTED_TO_ADDRESS') && isValid;
        isValid = validate('PRESENTED_TO_CITY') && isValid;
        isValid = validate('PRESENTED_TO_CONTACT_NAME') && isValid;
        isValid = validate('PRESENTED_TO_STATE') && isValid;
        isValid = validate('PRESENTED_TO_ZIP') && isValid;
        return isValid;
    }, [validate]);

    const onFieldBlur = useCallback((fieldName: string) => () => {
        validate(fieldName);
    }, [validate]);

    const generatePdf = useCallback(() => {
        enqueueSnackbar('Report PDF Requested. Please wait while it is generated.');
        createReportPdf(initValues?.id ?? '');
    }, [createReportPdf, enqueueSnackbar, initValues]);

    const handleGeneratePdf = useCallback(
        (event: React.SyntheticEvent) => {
            if (reportPdf && reportPdf.status === ReportPdfStatus.Created) {
                handlePdfDialog();
            } else {
                event.preventDefault();
                generatePdf();
            }
        },
        [generatePdf, handlePdfDialog, reportPdf]
    );

    const handleSave = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            if (formIsValid()) {
                save({
                    id: initValues ? initValues.id : emptyGuid,
                    isActive: isActive,
                    reportName: name,
                    reportDate: reportDate,
                    workOrderId: workOrder?.id,
                    workOrder: workOrder!,
                    reportType: reportType === 'RoofSMART' ? ReportType['RoofSMART Report'] : ReportType['Moisture Guard'],
                    roofSectionReports: initValues ? initValues.roofSectionReports : [],
                    presentedToContactName: presentedToContactName,
                    presentedToClientName: presentedToClientName,
                    presentedToFacilityName: presentedToFacilityName,
                    presentedToAddress1: presentedToAddressLine1,
                    presentedToAddress2: presentedToAddressLine2,
                    presentedToState: presentedToState,
                    presentedToCity: presentedToCity,
                    presentedToZip: presentedToZip,
                    reportPdfId: initValues?.reportPdfId,
                    isVisibleToClients: visibilityConfig.isVisibleToClients,
                    isVisibleToEmployees: visibilityConfig.isVisibleToEmployees,
                    visibility: 'computed' // this is a computed field on the back-end the value set here does not matter
                });
                setFormChanged(false);
            }
        },
        [
            formIsValid,
            initValues,
            isActive,
            name,
            presentedToAddressLine1,
            presentedToAddressLine2,
            presentedToCity,
            presentedToClientName,
            presentedToContactName,
            presentedToFacilityName,
            presentedToState,
            presentedToZip,
            reportDate,
            reportType,
            save,
            visibilityConfig,
            workOrder,
        ]
    );

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    const handleExistingReportDialogOpen = useCallback(() => {
        setIsSelectExistingReportDialogOpen(true);
    }, []);

    const handleExistingReportDialogClose = useCallback(() => {
        setIsSelectExistingReportDialogOpen(false);
    }, []);

    const handleExistingReportDialogConfirm = useCallback(
        (selectedReport: ReportListItemDto) => {
            setIsSelectExistingReportDialogOpen(false);
            createReportCopy(selectedReport.id);
        },
        [createReportCopy]
    );

    const handleWorkOrderOpen = useCallback(() => {
        setIsSelectWorkOrdersDialogOpen(true);
    }, []);

    const handleWorkOrdersDialogClose = useCallback(() => {
        setIsSelectWorkOrdersDialogOpen(false);
    }, []);

    const handleWorkOrdersDialogConfirm = useCallback(
        (sectionSelected: WorkOrderListViewDto) => {
            setFormChanged(true);
            setWorkOrder(sectionSelected);
            setPresentedToClientName(sectionSelected.facilityClientName);
            setPresentedToFacilityName(sectionSelected.facilityName);
            setPresentedToContactName(sectionSelected.facilityClientContactName);
            setPresentedToAddressLine1(sectionSelected.facilityAddress?.addressLine1);
            setPresentedToAddressLine2(sectionSelected.facilityAddress?.addressLine2);
            setPresentedToCity(sectionSelected.facilityAddress?.city);
            setPresentedToState(sectionSelected.facilityAddress?.state);
            setPresentedToZip(sectionSelected.facilityAddress?.zipCode);
            setIsSelectWorkOrdersDialogOpen(false);
            doesReportExistForWOrkOrderQuery({
                facilityId: sectionSelected.facilityId,
                clientId: sectionSelected.facilityClientId ?? emptyGuid,
                reportType: 'clientReports',
            }).then((response) => {
                if (response && response.data) {
                    handleExistingReportDialogOpen();
                }
            });
        },
        [doesReportExistForWOrkOrderQuery, handleExistingReportDialogOpen]
    );

    useEffect(() => {
        if (initValues && !reportPdf && !reportPdf && initValues.reportPdfId) {
            getReportPdf(initValues.id).then((response) => {
                if (!response.isError) {
                    setReportPdf(response.data);
                }
            });
        }
    }, [initValues, reportPdf, setReportPdf, getReportPdf]);

    useSuccessfulActionSnackbar('Generated', 'Report PDF', reportPdfData?.status === ReportPdfStatus.Created, () => {
        setReportPdf(reportPdfData);
    });
    useFailedActionSnackbar('generate', 'Report PDF', reportPdfData?.status === ReportPdfStatus.Error, () => {
        setReportPdf(reportPdfData);
    });

    useEffect(() => {
        if (initValues?.id === emptyGuid && initValues?.workOrderId) {
            if (initValues?.workOrder?.facilityId && initValues?.workOrder?.facility) {
                doesReportExistForWOrkOrderQuery({
                    facilityId: initValues.workOrder.facilityId,
                    clientId: initValues.workOrder.facilityClientId!,
                    reportType: 'clientReports',
                }).then((response) => {
                    if (response && response.data) {
                        handleExistingReportDialogOpen();
                    }
                });
            }
        }
    }, [doesReportExistForWOrkOrderQuery, handleExistingReportDialogOpen, initValues]);

    useSuccessfulActionSnackbar('created', 'Report', copySuccess, () => {
        navigate(`/clientreports/edit/${copy?.id}`);
        resetCopy();
    });
    useFailedActionSnackbar('copy', 'Report', isCopyError, resetCopy);
    
    const { recallDispatch, createDispatch, unlockDispatch, canCreate } = useWorkOrderActions(
        initValues?.workOrder,
        initValues?.workOrder?.contractorId,
        initValues?.currentDispatch
    );
    const handleAddCloseOutDispatch = useCallback(() => {
        setAddDispatchDialogOpen(true);
    }, []);
    const onCloseOutDispatchClose = () => {
        setAddDispatchDialogOpen(false);
    };
    const handleRecall = useCallback(
        (id: string) => {
            recallDispatch(id);
            setConfirmRecallOpen(false);
            setAddDispatchDialogOpen(false);
        },
        [recallDispatch]
    );

    const handleUnlock = useCallback(
        (id: string) => {
            unlockDispatch(id);
            setConfirmUnlockOpen(false);
        },
        [unlockDispatch]
    );

    const confirmRecall = useCallback(() => {
        setConfirmRecallOpen(true);
    }, []);

    const confirmUnlock = useCallback(() => {
        setConfirmUnlockOpen(true);
    }, []);

    const handleView = useCallback(
        () => {
            var dispatch = initValues?.currentDispatch;
            if (!dispatch) {
                return;
            }
            if (dispatch.status === 'Completed' || dispatch.status === 'Needs Attention') {
                navigate(`/submitDispatch/${dispatch.id}`);
            } else {
                setAddDispatchDialogOpen(true);
            }
        },
        [navigate, initValues?.currentDispatch]
    );

    const downloadPdf = useCallback(() => {
        if (reportPdf?.downloadUrl) {
            window.open(reportPdf?.downloadUrl);
        }
    }, [reportPdf]);

    const scrollToRoofSections = useCallback(() => {
        roofSectionsRef?.current!.scrollIntoView();
    }, []);

    const scrollToChecklistItems = useCallback(() => {
        checklistItemRef?.current!.scrollIntoView();
    }, []);

    if (workOrdersLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center' xs={12}>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <InsertChart /> {name ? name : 'Add New Moisture Guard / RoofSMART Report'}
                    </Typography>
                    <NavBreadcrumbs
                        links={workOrderId ?
                            [
                                { label: 'Home', navLink: '/' },
                                { label: 'Work Orders', navLink: '/workorders' },
                                { label: `Work Order #${workOrder?.woNumber ?? 'N/A'}`, navLink: `/workorders/edit/${workOrderId}` },
                            ] : [
                                { label: 'Home', navLink: '/' },
                                { label: 'Client Reports', navLink: '/clientreports' },
                            ]}
                        currentPageLabel={name ? name : 'Add New Moisture Guard / RoofSMART Report'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={isActive} onChange={handleActiveToggleChange} />}
                            label='Active'
                            labelPlacement='start'
                            disabled={isReportLocked}
                        />
                    </Grid>
                    <Grid item>
                        {changed || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave} disabled={isReportLocked}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
                <Grid item container direction='row' xs={6} spacing={2}>
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToRoofSections} disabled={initValues?.id === emptyGuid} tooltip='Roof Sections'>
                                    <Map />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        logic='or'
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToChecklistItems} disabled={initValues?.id === emptyGuid} tooltip='Immediate Repairs Checklist'>
                                    <FormatListBulletedOutlined />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        logic='or'
                        component={
                            <Grid item>
                                <MenuButton
                                    onClick={handleGeneratePdf}
                                    disabled={
                                        initValues?.id === emptyGuid ||
                                        (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ||
                                        isGeneratingReport ||
                                        (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ||
                                        isReportLocked
                                    }
                                    tooltip='Generate PDF'>
                                    <PictureAsPdf />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:dispatches']}
                        component={
                            <Grid item>
                                <MenuButton
                                    tooltip='Dispatch'
                                    disabled={!initValues || initValues.id === emptyGuid || !canCreate}
                                    onClick={initValues?.currentDispatch ? handleView : handleAddCloseOutDispatch}>
                                    {initValues?.currentDispatch ? <Visibility /> : <Send />}
                                </MenuButton>
                            </Grid>
                        }
                    />
                    {dialogOpen && <ConfirmPdfDialog open={dialogOpen} close={() => setDialogOpen(false)} generatePdf={generatePdf} />}
                </Grid>
                {isGeneratingReport || (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ? (
                    <Grid item container xs={6} justifyContent='end' alignItems='center' wrap='nowrap'>
                        <Grid item>
                            <i>Please check again later... Your PDF is still generating</i>
                        </Grid>
                        <Grid item>
                            <LoadingIndicator />
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                {reportPdf && reportPdf.status === ReportPdfStatus.Error ? (
                    <Grid item>
                        <i style={{ color: 'red' }}>The PDF failed to generate. Please try again.</i>
                    </Grid>
                ) : (
                    <></>
                )}
                {reportPdf && reportPdf.status === ReportPdfStatus.Created && reportPdf.createdOn && !isGeneratingReport ? (
                    <Grid item container direction='column' xs={6} alignItems='end'>
                        <Grid item container direction='row' spacing={1} justifyContent='end'>
                            <Grid item>
                                <PictureAsPdf />
                            </Grid>
                            <Grid item>
                                <Link onClick={downloadPdf} sx={{ cursor: 'pointer' }}>
                                    {reportPdf.displayName}
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <i>
                                Generated on {new Date(reportPdf.updatedOn ? reportPdf.updatedOn : reportPdf.createdOn).toLocaleDateString()} at{' '}
                                {new Date(reportPdf.updatedOn ? reportPdf.updatedOn : reportPdf.createdOn).toLocaleString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                })}
                            </i>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' columnSpacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Report Name'
                                onBlur={onFieldBlur('NAME')}
                                value={name}
                                fullWidth
                                required
                                error={fieldErrors.NAME !== ''}
                                errorText={fieldErrors.NAME}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item container xs={4} spacing={4}>
                            <Grid item container xs={5}>
                                <FormControl error={fieldErrors.REPORT_DATE !== ''} fullWidth required>
                                    <DateTimePicker
                                        label='Report Date'
                                        value={reportDate}
                                        onChange={handleReportDateChange}
                                        dateOnly={true}
                                        onBlur={onFieldBlur('REPORT_DATE')}
                                        onAcceptDate={() => {
                                            setFieldErrors({
                                                ...fieldErrors,
                                                REPORT_DATE: '',
                                            });
                                        }}
                                        disabled={isReportLocked}
                                    />
                                    <FormHelperText>{fieldErrors.REPORT_DATE}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={7}>
                                <FormControl error={fieldErrors.WO_NUMBER !== ''} fullWidth required>
                                    <FormLabel>Work Order #</FormLabel>
                                    <Grid item container alignItems={'center'} direction='row'>
                                        <Grid item xs={10}>
                                            <Autocomplete
                                                value={workOrder ?? null}
                                                onChange={(_event, value) => {
                                                    if (value) {
                                                        handleWorkOrdersDialogConfirm(value);
                                                    }
                                                }}
                                                open={workOrderOpen}
                                                onOpen={() => setWorkOrderOpen(true)}
                                                onClose={() => setWorkOrderOpen(false)}
                                                options={workOrders?.pageResults.filter((option) => option.isActive || option.id === workOrder?.id) ?? []}
                                                loading={workOrdersLoading}
                                                isOptionEqualToValue={(option, value) => option.woNumber === value.woNumber}
                                                getOptionLabel={(option: WorkOrderListViewDto) => `${option.woNumber}${option.isActive ? '' : ' (Inactive)'}`}
                                                disabled={isReportLocked || initValues?.workOrder !== undefined}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={fieldErrors.WO_NUMBER !== ''}
                                                        onBlur={onFieldBlur('WO_NUMBER')}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {workOrdersLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton disabled={isReportLocked || initValues?.workOrder !== undefined} onClick={() => handleWorkOrderOpen()}>
                                                <OpenInFull sx={{ fontSize: 36 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <FormHelperText>{fieldErrors.WO_NUMBER}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <VisibilityControl gridProps={{ xs: 4 }} value={visibilityConfig} onValueChange={handleVisibilityChange} disabled={isReportLocked} />
                        <Grid item container direction='column' xs={4}>
                            <FormControl error={fieldErrors.REPORT_TYPE !== ''} fullWidth required>
                                <FormLabel>Report Type</FormLabel>
                                <Select value={reportType} onChange={handleReportTypeChange} onBlur={onFieldBlur('REPORT_TYPE')} disabled={isReportLocked}>
                                    <MenuItem key='none' value=''>
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem key='roofSmart' value={'RoofSMART'}>
                                        RoofSMART
                                    </MenuItem>
                                    <MenuItem key='moistureGuard' value={'Moisture Guard'}>
                                        Moisture Guard
                                    </MenuItem>
                                </Select>
                                <FormHelperText>{fieldErrors.REPORT_TYPE}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>Presented To</Typography>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Owner'
                                onBlur={onFieldBlur('PRESENTED_TO_CLIENT')}
                                value={presentedToClientName}
                                fullWidth
                                required
                                error={fieldErrors.PRESENTED_TO_CLIENT !== ''}
                                errorText={fieldErrors.PRESENTED_TO_CLIENT}
                                onChange={handlePresentedToClientNameChange}
                                disabled={isReportLocked}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Facility'
                                onBlur={onFieldBlur('PRESENTED_TO_FACILITY')}
                                value={presentedToFacilityName}
                                fullWidth
                                required
                                error={fieldErrors.PRESENTED_TO_FACILITY !== ''}
                                errorText={fieldErrors.PRESENTED_TO_FACILITY}
                                onChange={handlePresentedToFacilityNameChange}
                                disabled={isReportLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Contact Name'
                                onBlur={onFieldBlur('PRESENTED_TO_CONTACT_NAME')}
                                value={presentedToContactName}
                                fullWidth
                                required
                                error={fieldErrors.PRESENTED_TO_CONTACT_NAME !== ''}
                                errorText={fieldErrors.PRESENTED_TO_CONTACT_NAME}
                                onChange={handlePresentedToContactNameChange}
                                disabled={isReportLocked}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Address Line 1'
                                onBlur={onFieldBlur('PRESENTED_TO_ADDRESS')}
                                value={presentedToAddressLine1}
                                fullWidth
                                required
                                error={fieldErrors.PRESENTED_TO_ADDRESS !== ''}
                                errorText={fieldErrors.PRESENTED_TO_ADDRESS}
                                onChange={handlePresentedToAddressLine1Change}
                                disabled={isReportLocked}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Address Line 2'
                                value={presentedToAddressLine2}
                                fullWidth
                                onChange={handlePresentedToAddressLine2Change}
                                disabled={isReportLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8} alignItems={'end'}>
                        <Grid item container direction='column' spacing={4} xs={8}>
                            <Grid item container direction='row' spacing={8}>
                                <Grid item xs={4}>
                                    <FormInput
                                        label='City'
                                        onBlur={onFieldBlur('PRESENTED_TO_CITY')}
                                        value={presentedToCity}
                                        fullWidth
                                        required
                                        error={fieldErrors.PRESENTED_TO_CITY !== ''}
                                        errorText={fieldErrors.PRESENTED_TO_CITY}
                                        onChange={handlePresentedToCityChange}
                                        disabled={isReportLocked}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl error={fieldErrors.PRESENTED_TO_STATE !== ''} fullWidth required>
                                        <FormLabel>State</FormLabel>
                                        <Select
                                            value={presentedToState}
                                            onChange={handlePresentedToStateChange}
                                            onBlur={onFieldBlur('PRESENTED_TO_STATE')}
                                            disabled={isReportLocked}>
                                            {usStates.map((usState, idx) => (
                                                <MenuItem key={`${usState.abbreviation}-${idx}`} value={usState.abbreviation}>
                                                    {usState.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{fieldErrors.PRESENTED_TO_STATE}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormInput
                                        label='Zip Code'
                                        onBlur={onFieldBlur('PRESENTED_TO_ZIP')}
                                        value={presentedToZip}
                                        fullWidth
                                        required
                                        error={fieldErrors.PRESENTED_TO_ZIP !== ''}
                                        errorText={fieldErrors.PRESENTED_TO_ZIP}
                                        onChange={handlePresentedToZipChange}
                                        disabled={isReportLocked}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            {initValues && initValues.id !== emptyGuid && (
                <>
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        component={
                            <Grid item ref={roofSectionsRef}>
                                <DashboardCard headerTitle='Roof Sections' headerIcon={<Map />}>
                                    <ClientReportRoofSectionsCard reportId={initValues.id} disabled={isReportLocked} getRoofSectionsQuery={useGetRoofSectionReportsQuery} />
                                </DashboardCard>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:reports']}
                        component={
                            <Grid item ref={checklistItemRef}>
                                <DashboardCard headerTitle='Immediate Repairs Checklist' headerIcon={<FormatListBulletedOutlined />}>
                                    <ImmediateRepairsChecklistItemListView disabled={isReportLocked} reportId={initValues.id} facilityId={initValues.workOrder?.facilityId} roofProfileId={initValues.workOrder?.roofProfileId} />
                                </DashboardCard>
                            </Grid>
                        }
                    />
                </>
            )}
            <SelectWorkOrdersDialog
                open={isSelectWorkOrdersDialogOpen}
                onCancel={handleWorkOrdersDialogClose}
                onConfirm={handleWorkOrdersDialogConfirm}
                isMultipleSelectable={false}
                currentWorkOrders={workOrders?.pageResults.filter((x) => x.id === workOrder?.id) ?? []}
            />
            {workOrder && (
                <SelectClientReportDialog
                    open={isSelectExistingReportDialogOpen}
                    onCancel={handleExistingReportDialogClose}
                    onConfirm={handleExistingReportDialogConfirm}
                    reportType={clientReportType}
                    facilityId={workOrder.facilityId}
                    clientId={workOrder.facilityClientId!}
                />
            )}
            {initValues && workOrder && (
                <DispatchDialogView
                    workOrder={workOrder}
                    open={addDispatchDialogOpen}
                    onClose={onCloseOutDispatchClose}
                    dispatch={initValues.currentDispatch}
                    unlockDispatch={confirmUnlock}
                    recallDispatch={confirmRecall}
                    createDispatch={createDispatch}
                    reportDetails={{
                        id: initValues.id,
                        lockDate: initValues.lockDate,
                        type: reportType !== '' ? reportType === 'RoofSMART' ? ReportType['RoofSMART Report'] : ReportType['Moisture Guard'] : initValues.reportType ?? 0
                    }}
                />
            )}
            <ConfirmDialog
                open={confirmRecallOpen}
                onClose={() => setConfirmRecallOpen(false)}
                content={<Typography>Do you want to recall this dispatch?</Typography>}
                titleIcon={<Undo />}
                title={<Typography variant='h3'>Recall Dispatch?</Typography>}
                cancelText='No'
                onConfirm={() => handleRecall(initValues!.currentDispatch!.id)}
            />
            <ConfirmDialog
                open={confirmUnlockOpen}
                onClose={() => setConfirmUnlockOpen(false)}
                content={<Typography>Do you want to unlock this dispatch?</Typography>}
                titleIcon={<LockOpen />}
                title={<Typography variant='h3'>Unlock Dispatch?</Typography>}
                cancelText='No'
                onConfirm={() => handleUnlock(initValues!.currentDispatch!.id)}
            />
        </Grid>
    );
};
