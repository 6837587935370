import { FC, useCallback, useMemo } from 'react';
import { Dialog, DialogContent, Grid, DialogActions, Button, Typography, DialogTitle } from '@mui/material';
import { Videocam } from '@mui/icons-material';
import { FacilityDto } from '../../models';
import YouTube from 'react-youtube';

export interface IViewDroneFootageModalProps {
    isOpen: boolean;
    close: () => void;
    facility: FacilityDto;
};

export const ViewDroneFootageModal: FC<IViewDroneFootageModalProps> = ({ isOpen, close, facility }) => {
    
    const youtubeVideoIdFromUrl = useMemo(() => {
        var url = facility.droneFootageUrl ?? '';
        const VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        if (!!url && !!VID_REGEX) {
            return url.match(VID_REGEX) ? url.match(VID_REGEX)![1] : '';
        }
    },[facility]);
    
    const handleCloseClicked = useCallback(() => {
        close();
    }, [close]);

    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth='md'>
            <DialogTitle sx={{ backgroundColor: '#185983', color: '#ffffff', fontWeight: 'bold' }}>
            <Grid container direction='row' alignItems='center' gap={1}>
                <Videocam />
                <Typography fontSize='inherit' fontWeight='inherit'>
                    View Drone Footage
                </Typography>
            </Grid>
        </DialogTitle>
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} justifyContent={'center'} paddingTop={'16px'}>
                    <YouTube videoId={youtubeVideoIdFromUrl}/>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent='end'>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleCloseClicked}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};