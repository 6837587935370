import { Upload } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import Papa from 'papaparse';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { ImportLineItemDto } from '../../../../models/ImportLineItemDto';
import { useGetBudgetLineItemsQuery } from '../../../../store/apis/budget-line-item-api';
import { useGetRoofConditionsQuery, useGetRoofSystemsQuery } from '../../../../store/apis/roof-api';
import { formatCurrency, sortByRoofNumber } from '../../../../util';
import { ApiError } from '../../../core/ApiError';
import { DataTableColumn } from '../../../core/DataTable';
import LoadingIndicator from '../../../core/LoadingIndicator';
import { LocalDataTable } from '../../../core/LocalDataTable';
import { ImportLineItemsStepTwoProps } from './types';

export const ImportLineItemsStepTwo: FunctionComponent<ImportLineItemsStepTwoProps> = (props) => {
    const {
        selectedFile,
        parsedFileData: csvFileData,
        setParsedFileData: setCsvFileData,
        setIsReadyForNext,
        budgetId,
        updateExisting,
        setUpdateExisting,
    } = props;
    const [isFileValid, setIsFileValid] = useState<boolean>(true);
    const [isParsingData, setIsParsingData] = useState<boolean>(false);
    const [isParseComplete, setIsParseComplete] = useState<boolean>(false);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [goodRecords, setGoodRecords] = useState<number>(0);
    const [badRecords, setBadRecords] = useState<number>(0);
    const {
        data: roofSystems,
        error: roofSystemsError,
        isLoading: roofSystemsLoading,
        refetch: refetchRoofSystems,
    } = useGetRoofSystemsQuery({ includeInactive: true });
    const {
        data: roofConditions,
        isLoading: roofConditionsLoading,
        error: roofConditionsError,
        refetch: roofConditionsRefetch,
    } = useGetRoofConditionsQuery({ includeInactive: true });
    const {
        data: existingItems,
        isLoading: existingItemsLoading,
        error: existingItemsError,
        refetch: existingItemsRefetch,
    } = useGetBudgetLineItemsQuery({
        parentId: budgetId,
        params: {
            sortKey: 'ROOF_NUMBER',
            sortAsc: true,
            page: 0,
            pageSize: 1000,
            includeInactive: false,
        },
    });

    const handleUpdateExistingChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateExisting(event.target.checked);
    }, [setUpdateExisting]);

    const mapStatus = useCallback(
        (row: ImportLineItemDto) => {
            if (row.isExistingSection) {
                updateExisting ? (row.isValid = true) : (row.isValid = false);
                return (
                    <Typography>
                        {'Roof # already exists.'} {updateExisting ? 'Will be updated.' : 'Will NOT be updated.'}
                    </Typography>
                );
            }
            if (row.status === 'Good') {
                return <Typography>{row.status}</Typography>;
            }
            return <Typography sx={{ color: '#DF623B' }}>{row.status}</Typography>;
        },
        [updateExisting]
    );

    const tableColumns: DataTableColumn<ImportLineItemDto>[] = useMemo(() => [
        { key: 'roofNumber', label: 'Roof #', sortKey: 'ROOF_NUMBER' },
        { key: 'sqFeet', label: 'Sq Ft', align: 'right', sortKey: 'SQUARE_FOOTAGE' },
        {
            key: 'assetValue',
            label: 'Asset Value',
            sortKey: 'ASSET_VALUE',
            align: 'right',
            fieldMapper: (row) => (row.assetValue ? formatCurrency(row.assetValue) : ''),
        },
        { key: 'warranty', label: 'Warranty', sortKey: 'WARRANTY', fieldMapper: (row) => (row.warranty ? 'Yes' : 'No') },
        { key: 'roofSystem', label: 'Roof System', sortKey: 'ROOF_SYSTEM' },
        { key: 'currentCondition', label: 'Current Condition', sortKey: 'CURRENT_CONDITION' },
        {
            key: 'year1Amount',
            label: 'Year 1 Amount',
            align: 'right',
            sortKey: 'YEAR_1_AMOUNT',
            fieldMapper: (row) => (row.year1Amount ? formatCurrency(row.year1Amount) : ''),
        },
        {
            key: 'year2Amount',
            label: 'Year 2 Amount',
            align: 'right',
            sortKey: 'YEAR_2_AMOUNT',
            fieldMapper: (row) => (row.year2Amount ? formatCurrency(row.year2Amount) : ''),
        },
        {
            key: 'year3Amount',
            label: 'Year 3 Amount',
            align: 'right',
            sortKey: 'YEAR_3_AMOUNT',
            fieldMapper: (row) => (row.year3Amount ? formatCurrency(row.year3Amount) : ''),
        },
        {
            key: 'year4Amount',
            label: 'Year 4 Amount',
            align: 'right',
            sortKey: 'YEAR_4_AMOUNT',
            fieldMapper: (row) => (row.year4Amount ? formatCurrency(row.year4Amount) : ''),
        },
        {
            key: 'year5Amount',
            label: 'Year 5 Amount',
            align: 'right',
            sortKey: 'YEAR_5_AMOUNT',
            fieldMapper: (row) => (row.year5Amount ? formatCurrency(row.year5Amount) : ''),
        },
        { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: mapStatus },
    ], [mapStatus]);

    const headersToCamelCase = useCallback((header: string) => {
        return header.slice(0, 1).toLowerCase() + header.slice(1, header.length);
    }, []);

    const convertYNToBoolean = useCallback((value?: string | null) => {
        if (value === undefined || value === null || value === '') {
            return null;
        } else if (value?.toUpperCase() === 'YES' || value?.toUpperCase() === 'Y' || value?.toUpperCase() === 'TRUE') {
            return true;
        } else if (value?.toUpperCase() === 'NO' || value?.toUpperCase() === 'N' || value?.toUpperCase() === 'FALSE') {
            return false;
        } else {
            return undefined;
        }
    }, []);

    const isRowYearDataValid = useCallback((rowData: ImportLineItemDto) => {
        if (rowData.year1Amount || rowData.year1Condition || rowData.year1ImmediateRepairs !== null || rowData.year1Multiplier) {
            if (!(rowData.year1Amount && rowData.year1Condition && rowData.year1ImmediateRepairs !== null && rowData.year1Multiplier)) {
                return 'Year 1 is missing required data';
            }
        }

        if (rowData.year2Amount || rowData.year2Condition || rowData.year2ImmediateRepairs !== null || rowData.year2Multiplier) {
            if (!(rowData.year2Amount && rowData.year2Condition && rowData.year2ImmediateRepairs !== null && rowData.year2Multiplier)) {
                return 'Year 2 is missing required data';
            } else {
                if (!(rowData.year1Amount && rowData.year1Condition && rowData.year1ImmediateRepairs !== null && rowData.year1Multiplier)) {
                    return 'Year 2 data found without data for year 1';
                }
            }
        }

        if (rowData.year3Amount || rowData.year3Condition || rowData.year3ImmediateRepairs !== null || rowData.year3Multiplier) {
            if (!(rowData.year3Amount && rowData.year3Condition && rowData.year3ImmediateRepairs !== null && rowData.year3Multiplier)) {
                return 'Year 3 is missing required data';
            } else {
                if (!(rowData.year2Amount && rowData.year2Condition && rowData.year2ImmediateRepairs !== null && rowData.year2Multiplier)) {
                    return 'Year 3 data found without data for year 2';
                }
            }
        }

        if (rowData.year4Amount || rowData.year4Condition || rowData.year4ImmediateRepairs !== null || rowData.year4Multiplier) {
            if (!(rowData.year4Amount && rowData.year4Condition && rowData.year4ImmediateRepairs !== null && rowData.year4Multiplier)) {
                return 'Year 4 is missing required data';
            } else {
                if (!(rowData.year3Amount && rowData.year3Condition && rowData.year3ImmediateRepairs !== null && rowData.year3Multiplier)) {
                    return 'Year 4 data found without data for year 3';
                }
            }
        }

        if (rowData.year5Amount || rowData.year5Condition || rowData.year5ImmediateRepairs !== null || rowData.year5Multiplier) {
            if (!(rowData.year5Amount && rowData.year5Condition && rowData.year5ImmediateRepairs !== null && rowData.year5Multiplier)) {
                return 'Year 5 is missing required data';
            } else {
                if (!(rowData.year4Amount && rowData.year4Condition && rowData.year4ImmediateRepairs !== null && rowData.year4Multiplier)) {
                    return 'Year 5 data found without data for year 4';
                }
            }
        }

        return '';
    }, []);

    const parseTable = useCallback(() => {
        if (selectedFile !== undefined) {
            Papa.parse<ImportLineItemDto>(selectedFile, {
                header: true,
                transformHeader: headersToCamelCase,
                transform(value, field) {
                    if (value !== '') {
                        return value;
                    }
                    return null;
                },
                complete: (results) => {
                    let requiredHeaders: string[] = [
                        'roofNumber',
                        'roofName',
                        'sqFeet',
                        'multiplier',
                        'assetValue',
                        'warrantyYN',
                        'roofSystem',
                        'currentCondition',
                        'year1Multiplier',
                        'year1Amount',
                        'year1Condition',
                        'year1ImmediateRepairsYN',
                        'year2Multiplier',
                        'year2Amount',
                        'year2Condition',
                        'year2ImmediateRepairsYN',
                        'year3Multiplier',
                        'year3Amount',
                        'year3Condition',
                        'year3ImmediateRepairsYN',
                        'year4Multiplier',
                        'year4Amount',
                        'year4Condition',
                        'year4ImmediateRepairsYN',
                        'year5Multiplier',
                        'year5Amount',
                        'year5Condition',
                        'year5ImmediateRepairsYN',
                    ];
                    let isFileValid = requiredHeaders.every((requiredHeader) => {
                        return results.meta.fields?.includes(requiredHeader);
                    });
                    let recordsInError = 0;
                    const validatedData = results.data.map((data) => {
                        data.warranty = data.warrantyYN !== '' ? data.warrantyYN?.toUpperCase() === 'YES' || data.warrantyYN?.toUpperCase() === 'Y' : undefined;
                        data.year1ImmediateRepairs = convertYNToBoolean(data.year1ImmediateRepairsYN);
                        data.year2ImmediateRepairs = convertYNToBoolean(data.year2ImmediateRepairsYN);
                        data.year3ImmediateRepairs = convertYNToBoolean(data.year3ImmediateRepairsYN);
                        data.year4ImmediateRepairs = convertYNToBoolean(data.year4ImmediateRepairsYN);
                        data.year5ImmediateRepairs = convertYNToBoolean(data.year5ImmediateRepairsYN);

                        data.status = 'Good';
                        data.isValid = false;

                        const rowYearDataStatus = isRowYearDataValid(data);

                        if (data.roofNumber === null) {
                            data.status = 'Missing Roof Number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.roofName === null) {
                            data.status = 'Missing Roof Name';
                            data.isValid = false;
                            recordsInError++;
                        } else if (!data.sqFeet) {
                            data.status = 'Missing Square Feet';
                            data.isValid = false;
                            recordsInError++;
                        } else if (isNaN(data.sqFeet)) {
                            data.status = 'Square Feet is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.multiplier && isNaN(data.multiplier)) {
                            data.status = 'Multiplier is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (!data.assetValue) {
                            data.status = 'Missing Asset Value';
                            data.isValid = false;
                            recordsInError++;
                        } else if (isNaN(data.assetValue)) {
                            data.status = 'Asset Value is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (!roofSystems?.some((x) => x.name === data.roofSystem)) {
                            data.status = 'Invalid Roof System';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.currentCondition === null) {
                            data.status = 'Missing Current Condition';
                            data.isValid = false;
                            recordsInError++;
                        } else if (!roofConditions?.some((x) => x.rating === data.currentCondition)) {
                            data.status = 'Invalid Current Condition';
                            data.isValid = false;
                            recordsInError++;
                        } else if (rowYearDataStatus !== '') {
                            data.status = rowYearDataStatus;
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year1Multiplier && isNaN(data.year1Multiplier)) {
                            data.status = 'Year 1 Multiplier is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year1Amount && isNaN(data.year1Amount)) {
                            data.status = 'Year 1 Amount is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year1Condition && !roofConditions?.some((x) => x.rating === data.year1Condition)) {
                            data.status = 'Invalid Year 1 Condition';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year1ImmediateRepairs === undefined) {
                            data.status = 'Invalid Year 1 ImmediateRepairsYN';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year2Multiplier && isNaN(data.year2Multiplier)) {
                            data.status = 'Year 2 Multiplier is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year2Amount && isNaN(data.year2Amount)) {
                            data.status = 'Year 2 Amount is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year2Condition && !roofConditions?.some((x) => x.rating === data.year2Condition)) {
                            data.status = 'Invalid Year 2 Condition';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year2ImmediateRepairs === undefined) {
                            data.status = 'Invalid Year 2 ImmediateRepairsYN';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year3Multiplier && isNaN(data.year3Multiplier)) {
                            data.status = 'Year 3 Multiplier is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year3Amount && isNaN(data.year3Amount)) {
                            data.status = 'Year 3 Amount is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year3Condition && !roofConditions?.some((x) => x.rating === data.year3Condition)) {
                            data.status = 'Invalid Year 3 Condition';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year3ImmediateRepairs === undefined) {
                            data.status = 'Invalid Year 3 ImmediateRepairsYN';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year4Multiplier && isNaN(data.year4Multiplier)) {
                            data.status = 'Year 4 Multiplier is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year4Amount && isNaN(data.year4Amount)) {
                            data.status = 'Year 4 Amount is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year4Condition && !roofConditions?.some((x) => x.rating === data.year4Condition)) {
                            data.status = 'Invalid Year 4 Condition';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year4ImmediateRepairs === undefined) {
                            data.status = 'Invalid Year 4 ImmediateRepairsYN';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year5Multiplier && isNaN(data.year5Multiplier)) {
                            data.status = 'Year 5 Multiplier is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year5Amount && isNaN(data.year5Amount)) {
                            data.status = 'Year 5 Amount is not a number';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year5Condition && !roofConditions?.some((x) => x.rating === data.year5Condition)) {
                            data.status = 'Invalid Year 5 Condition';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.year5ImmediateRepairs === undefined) {
                            data.status = 'Invalid Year 5 ImmediateRepairsYN';
                            data.isValid = false;
                            recordsInError++;
                        } else if (data.roofNumber && existingItems?.pageResults.some((x) => x.roofSection?.roofNumber === data.roofNumber)) {
                            data.isExistingSection = true;
                        }
                        return data;
                    });
                    setIsFileValid(isFileValid);
                    setCsvFileData(validatedData);
                    setTotalRecords(validatedData.length);
                    setGoodRecords(validatedData.length - recordsInError);
                    setBadRecords(recordsInError);
                    setIsParsingData(false);
                    setIsParseComplete(true);
                    setIsReadyForNext(true);
                },
                error: () => {
                    setCsvFileData(null);
                    setIsParsingData(true);
                },
            });
        }
    }, [selectedFile, headersToCamelCase, setCsvFileData, setIsReadyForNext, convertYNToBoolean, isRowYearDataValid, roofSystems, roofConditions, existingItems?.pageResults]);

    useEffect(() => {
        parseTable();
    }, [updateExisting, parseTable, existingItems]);

    useEffect(() => {
        if (isParseComplete) {
            return;
        }
        if (!isParsingData) {
            parseTable();
            setIsParsingData(true);
        }
    }, [parseTable, isParsingData, isParseComplete, roofConditions, roofSystems]);


    const refetch = useCallback(() => {
        refetchRoofSystems();
        refetchRoofSystems();
        roofConditionsRefetch();
        existingItemsRefetch();
        parseTable();
    }, [existingItemsRefetch, parseTable, refetchRoofSystems, roofConditionsRefetch]);

    const sortedCsvFileData = useMemo(() => sortByRoofNumber<any>(csvFileData ?? [], (x: any) => x.roofNumber), [csvFileData]);

    if (!isFileValid) {
        return (
            <Typography
                color={'secondary'}
                sx={{
                    fontWeight: 'bold',
                }}>
                Error: Selected file is not of the correct type or contains invalid header information.
            </Typography>
        );
    }

    if (roofSystemsLoading || roofConditionsLoading || isParsingData || existingItemsLoading) {
        return <LoadingIndicator />;
    }

    if (roofSystemsError || roofConditionsError || existingItemsError) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' justifyContent={'space-between'}>
            <Grid container item direction='row' xs={6}>
                <Grid item>
                    <Upload />
                </Grid>
                <Grid item>
                    <Typography>{selectedFile?.name}</Typography>
                </Grid>
            </Grid>
            <Grid container item direction='row'>
                <Grid item container xs={8} spacing={3}>
                    <Grid item>
                        <Typography>Total records found: {totalRecords}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>Good: {goodRecords}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ color: '#DF623B' }}>Error: {badRecords}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={4}>
                    <Grid item justifySelf={'end'}>
                        <FormControlLabel
                            control={<Checkbox onChange={handleUpdateExistingChange} />}
                            label='Update existing roof numbers if they already exist'
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                {!isParsingData && <LocalDataTable columns={tableColumns} loading={isParsingData} rowData={sortedCsvFileData} />}
            </Grid>
        </Grid>
    );
};
