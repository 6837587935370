import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    LinearProgress,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { IUploadRoofMapDialogProps } from './types';
import { Map } from '@mui/icons-material';

export const UploadRoofMapDialog: FunctionComponent<IUploadRoofMapDialogProps> = (props) => {
    const { open, onClose, setSvgContents, setNeedsToSaveSvg, setFormChanged } = props;
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileSelectRef = useRef<null | HTMLInputElement>(null);
    const [shouldUpload, setShouldUpload] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [errorText, setErrorText] = useState('');

    const startFileReadCallback = useCallback(async () => {
        setIsUploading(true);
        if (selectedFile) {
            const content = await selectedFile.text();
            setNeedsToSaveSvg(true);
            setSvgContents(content);
            setErrorText('');
            setSelectedFile(null);
            if (fileSelectRef.current) {
                fileSelectRef.current.value = '';
            }
            onClose();
        }
    }, [selectedFile, setIsUploading, setSvgContents, setErrorText, setSelectedFile, fileSelectRef, onClose, setNeedsToSaveSvg]);

    useEffect(() => {
        if (shouldUpload && selectedFile && !isUploading) {
            startFileReadCallback();
        }
    }, [shouldUpload, selectedFile, isUploading, startFileReadCallback]);

    const openFilePicker = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (fileSelectRef.current) {
            fileSelectRef.current.click();
        }
    };

    const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files?.length > 0) {
            const file = e.target.files.item(0)!;
            setSelectedFile(file);
        }
    };

    const onSave = () => {
        if (selectedFile) {
            setErrorText('');
            setShouldUpload(true);
        } else {
            setErrorText('You must select a file to upload.');
        }
        setFormChanged!(true);
    };

    const beforeClose = () => {
        setErrorText('');
        setSelectedFile(null);
        if (fileSelectRef.current) {
            fileSelectRef.current.value = '';
        }
        onClose();
    };

    return (
        <Dialog onClose={beforeClose} open={open} maxWidth='sm' fullWidth>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    <Map />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Add Roof Map
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' spacing={2} marginTop={2}>
                    <Grid item container direction='row' alignItems='center'>
                        <input ref={fileSelectRef} style={{ display: 'none' }} type='file' accept='.svg' onChange={onFileSelected} />
                        <Grid item onClick={openFilePicker} xs={3}>
                            <Button variant='contained' color='secondary' sx={{ marginTop: '12px' }}>
                                Choose File
                            </Button>
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl fullWidth required>
                                <FormLabel>Filename</FormLabel>
                                <OutlinedInput value={selectedFile?.name} disabled />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {isUploading && (
                        <Grid item>
                            <LinearProgress />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <FormHelperText error={errorText !== ''} sx={{ width: '60%' }}>
                    {errorText}
                </FormHelperText>
                <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={beforeClose}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
