import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormHelperText, FormLabel, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { IDateTimePickerProps } from './types';
import { DatePicker } from '@mui/lab';
import { patterns } from '.';
import React from 'react';

export const DateTimePicker: FunctionComponent<IDateTimePickerProps> = React.memo((props) => {
    const { value, onChange, onBlur, onAcceptDate, label, error, errorText, required, dateOnly, disabled } = props;
    const [dateValue, setDateValue] = useState<Date | null | undefined>(value ? value : null);
    const convertHours = (hours: number) => {
        if (hours === 0) {
            return '12';
        } else if (hours > 12) {
            return (hours - 12).toString();
        } else {
            return hours.toString();
        }
    };
    const [hour, setHour] = useState(value && !dateOnly ? convertHours(value.getHours()) : '');
    const [minute, setMinute] = useState(value && !dateOnly ? value.getMinutes().toString().padStart(2, '0') : '');
    const [meridiem, setMeridiem] = useState(value && !dateOnly ? (value.getHours() > 11 ? 'PM' : 'AM') : 'AM');
    const [hourError, setHourError] = useState(false);
    const [minError, setMinError] = useState(false);
    const [doUpdateValue, setDoUpdateValue] = useState(false);

    useEffect(() => {
        if (!dateValue) {
            setDateValue(null);
            setMinute('');
            setHour('');
            setMeridiem('AM');
            onChange(null);
        } else if (!doUpdateValue && value && !dateValue) {
            setDateValue(value);
            setMinute(value && !dateOnly ? value.getMinutes().toString().padStart(2, '0') : '');
            setHour(value && !dateOnly ? convertHours(value.getHours()) : '');
            setMeridiem(value && !dateOnly ? (value.getHours() > 11 ? 'PM' : 'AM') : 'AM');
            onChange(value);
        }
        if (doUpdateValue) {
            if (!hourError && !minError) {
                let newValue = dateValue;
                if (dateValue && hour && minute) {
                    newValue = new Date(`${dateValue.getFullYear()}-${dateValue.getMonth() + 1}-${dateValue.getDate()} ${hour}:${minute} ${meridiem}`);
                }
                onChange(newValue);
            }
            setDoUpdateValue(false);
        }
    }, [doUpdateValue, setDoUpdateValue, hourError, minError, dateValue, hour, minute, meridiem, onChange, value, dateOnly]);

    const handleDateChange = useCallback((newValue: Date | null | undefined) => {
        setDateValue(newValue);
        if (newValue) {
            setMinute('00');
            setHour('12');
            setMeridiem('AM');
        }
        setDoUpdateValue(true);
    }, []);

    const handleHourChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setHour(event.target.value);
            setDoUpdateValue(true);
        }
    }, []);

    const handleHourBlur = useCallback(() => {
        if (hour) {
            setHourError(!patterns.Hour.validate.test(hour));
        } else {
            setHourError(false);
        }
        setDoUpdateValue(true);
    }, [hour]);

    const handleMinChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setMinute(event.target.value);
            setDoUpdateValue(true);
        }
    }, []);

    const handleMinBlur = useCallback(() => {
        if (minute) {
            setMinError(!patterns.Minute.validate.test(minute));
        } else {
            setMinError(false);
        }
        setDoUpdateValue(true);
    }, [minute]);

    const handleMeridiemChange = useCallback((event: React.MouseEvent<HTMLElement>, value: any) => {
        setMeridiem(value);
        setDoUpdateValue(true);
    }, []);

    return (
        <>
            <FormLabel error={error} required={required} disabled={disabled}>
                {label}
            </FormLabel>
            <Grid container direction='row' alignItems='center' spacing={1}>
                <Grid item xs={dateOnly ? 12 : 6}>
                    <DatePicker
                        value={dateValue ?? null}
                        onChange={handleDateChange}
                        onAccept={onAcceptDate}
                        disabled={disabled}
                        clearable={true}
                        renderInput={(params) => <TextField {...params} error={error} required={required} fullWidth={dateOnly} onBlur={onBlur} />}
                    />
                </Grid>
                {!dateOnly && (
                    <Grid item container direction='row' alignItems='center' spacing={1} xs={6}>
                        <Grid item container direction='row' alignItems='center' xs={9}>
                            <Grid item xs={5}>
                                <TextField
                                    inputProps={{ inputMode: 'numeric', pattern: patterns.Hour.input.source }}
                                    value={hour ?? null}
                                    error={hourError || error}
                                    onChange={handleHourChange}
                                    onBlur={handleHourBlur}
                                    disabled={disabled}
                                    required={required}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography sx={{ fontSize: '16px', textAlign: 'center' }}>:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    inputProps={{ inputMode: 'numeric', pattern: patterns.Minute.input.source }}
                                    value={minute ?? null}
                                    error={minError || error}
                                    onChange={handleMinChange}
                                    onBlur={handleMinBlur}
                                    disabled={disabled}
                                    required={required}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <ToggleButtonGroup
                                color='primary'
                                orientation='vertical'
                                size='small'
                                value={meridiem ?? null}
                                onChange={handleMeridiemChange}
                                exclusive
                                disabled={disabled}
                            >
                                <ToggleButton sx={{ lineHeight: 1 }} value='AM'>
                                    AM
                                </ToggleButton>
                                <ToggleButton sx={{ lineHeight: 1 }} value='PM'>
                                    PM
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <FormHelperText error={error || hourError || minError}>
                {error ? errorText : <>{hourError || minError ? 'Invalid time format' : ''}</>}
            </FormHelperText>
        </>
    );
});
