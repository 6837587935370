import { useAuth0 } from '@auth0/auth0-react';
import { Assessment, Info, Map, OpenInFull, PictureAsPdf } from '@mui/icons-material';
import { Autocomplete, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Link, MenuItem, Select, SelectChangeEvent, Switch, TextField, Tooltip, Typography } from '@mui/material';
import jwt from 'jsonwebtoken';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AuthenticatedComponent, { userHasPermissions } from '../../../auth';
import { emptyGuid, ReportPdfStatus, usStates, WorkOrderDispatchDto, WorkOrderListViewDto } from '../../../models';
import { useCreateCloseOutPdfMutation, useLazyGetCloseOutReportPdfQuery } from '../../../store/apis/close-out-report-api';
import { useGetWorkOrdersQuery } from '../../../store/apis/work-order-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ConfirmPdfDialog } from '../../core/ConfirmPdfDialog';
import LoadingIndicator from '../../core/LoadingIndicator';
import { MenuButton } from '../../core/MenuButton';
import { INavBreadcrumbLink, NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { FormButton } from '../FormButton';
import { DateTimePicker, FormInput } from '../FormFields';
import { FormSection } from '../FormSection';
import { SelectWorkOrdersDialog } from '../SelectWorkOrder';
import { Attachment, IFormProps } from '../types';
import { CloseOutReportProps } from './types';
import { IEntityAutocomplete } from '../../core/IEntityAutocomplete';
import { format } from 'date-fns';
import { useLazyGetUserByAuth0IdQuery } from '../../../store/apis/user-api';
import { PhotosFormSection } from '../../core/Photos';
import { currentVisibilityOrDefault, VisibilityConfig, VisibilityControl } from '../../CommonInputs';
import { isDateGreaterThanOrEqual } from '../../../util';
import { useLazyGetAcceptedDispatchesQuery } from '../../../store/apis/dispatch-api';

export const CloseOutReportForm: FC<IFormProps<CloseOutReportProps>> = (props) => {
    const { save, cancel, initValues } = props;
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuth0();
    const [getUserData, { data: userData }] = useLazyGetUserByAuth0IdQuery();

    const [changed, setFormChanged] = useState(false);
    const [workOrder, setWorkOrder] = useState(initValues?.edit ? initValues?.dto?.workOrder : (initValues?.workOrder ? initValues.workOrder : undefined));

    const [getAcceptedDispatches, { data: acceptedDispatches }] = useLazyGetAcceptedDispatchesQuery();
    const { data: workOrders, isLoading: workOrdersLoading } = useGetWorkOrdersQuery({
        getOnlyOpen: false,
        orderType: 0,
        includeInactive: true,
    });

    const [workOrderDispatch, setWorkOrderDispatch] = useState<WorkOrderDispatchDto | undefined>(initValues?.dto ? initValues.dto.workOrderDispatch : undefined);
    const [name, setName] = useState(initValues?.dto ? initValues.dto.reportName : '');
    const [visibilityConfig, setVisibilityConfig] = useState(currentVisibilityOrDefault(initValues?.dto));
    const [isActive, setIsActive] = useState(initValues?.dto ? initValues?.dto.isActive : true);
    const [reportDate, setReportDate] = useState<Date | null | undefined>(initValues?.dto && initValues?.dto.reportDate ? new Date(initValues?.dto.reportDate) : new Date());
    const [presentedToClientName, setPresentedToClientName] = useState(initValues?.dto ? initValues?.dto.presentedToClientName : '');
    const [presentedToFacilityName, setPresentedToFacilityName] = useState(initValues?.dto ? initValues?.dto.presentedToFacilityName : '');
    const [presentedToContactName, setPresentedToContactName] = useState(initValues?.dto ? initValues?.dto.presentedToContactName : '');
    const [presentedToAddressLine1, setPresentedToAddressLine1] = useState(initValues?.dto ? initValues?.dto.presentedToAddress1 : '');
    const [presentedToAddressLine2, setPresentedToAddressLine2] = useState(initValues?.dto ? initValues?.dto.presentedToAddress2 : '');
    const [presentedToCity, setPresentedToCity] = useState(initValues?.dto ? initValues?.dto.presentedToCity : '');
    const [presentedToState, setPresentedToState] = useState(initValues?.dto ? initValues?.dto.presentedToState : '');
    const [presentedToZip, setPresentedToZip] = useState(initValues?.dto ? initValues?.dto.presentedToZip : '');
    const [arrival, setArrival] = useState<Date | null | undefined>(initValues?.dto?.arrivalDateTime ? new Date(initValues?.dto.arrivalDateTime) : null);
    const [departure, setDeparture] = useState<Date | null | undefined>(initValues?.dto?.departureDateTime ? new Date(initValues?.dto.departureDateTime) : null);
    const [proposalForthcoming, setProposalForthcoming] = useState(initValues?.dto ? initValues?.dto.proposalForthcoming : false);
    const [technicianName, setTechnicianName] = useState(initValues?.dto ? initValues?.dto.technicianName : '');
    const [roofSections, setRoofSections] = useState(initValues?.dto ? initValues?.dto.roofSections : '');
    const [descriptionOfProblem, setDescriptionOfProblem] = useState(initValues?.dto ? initValues?.dto.descriptionOfProblem : '');
    const [summaryWorkPerformed, setSummaryWorkPerformed] = useState(initValues?.dto ? initValues?.dto.summaryOfWorkPerformed : '');
    const [reportedBy, setReportedBy] = useState(initValues?.dto ? initValues?.dto.reportedBy : '');

    const [isSelectWorkOrdersDialogOpen, setIsSelectWorkOrdersDialogOpen] = useState(false);
    const [workOrderOpen, setWorkOrderOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [isFullEdit, setIsFullEdit] = useState(false);

    const isReportLocked =
        initValues?.dto && initValues?.dto.lockDate !== undefined && initValues?.dto.lockDate !== null && initValues?.dto.lockDate.toString() !== 'Invalid Date';

    const [photos, setPhotos] = useState<Attachment[]>([]);
    const [photoCaptions, setPhotoCaptions] = useState<string[]>([]);

    const photosRef = useRef<null | HTMLDivElement>(null);

    const [createReportPdf, { data: reportPdfData, isLoading: isGeneratingReport }] = useCreateCloseOutPdfMutation();
    const [getReportPdf] = useLazyGetCloseOutReportPdfQuery();
    const [reportPdf, setReportPdf] = useState(initValues?.dto?.reportPdf);

    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        WO_NUMBER: '',
        REPORT_DATE: '',
        ARRIVAL: '',
        DEPARTURE: '',
        DESCRIPTION_OF_PROBLEM: '',
        SUMMARY_OF_WORK_PERFORMED: '',
        PRESENTED_TO_CLIENT: '',
        PRESENTED_TO_FACILITY: '',
        PRESENTED_TO_CONTACT_NAME: '',
        PRESENTED_TO_ADDRESS: '',
        PRESENTED_TO_CITY: '',
        PRESENTED_TO_STATE: '',
        PRESENTED_TO_ZIP: '',
        REPORTED_BY: '',
    });

    useSuccessfulActionSnackbar('Generated', 'Report PDF', reportPdfData?.status === ReportPdfStatus.Created, () => {
        setReportPdf(reportPdfData);
    });
    useFailedActionSnackbar('generate', 'Report PDF', reportPdfData?.status === ReportPdfStatus.Error, () => {
        setReportPdf(reportPdfData);
    });

    useEffect(() => {
        if (!userData && auth.user?.sub) {
            getUserData(auth.user.sub);
        }
    }, [auth, getUserData, userData]);

    useEffect(() => {
        async function getUserPermissions() {
            const token = await auth.getAccessTokenSilently();

            return jwt.decode(token);
        }

        if (!auth.isLoading && auth.isAuthenticated) {
            getUserPermissions().then((result) => {
                const permissions = ((result as any)?.permissions as string[]) ?? [];

                setIsFullEdit(userHasPermissions(['edit:dispatches'], permissions));
            });
        }
    }, [auth, isFullEdit, setIsFullEdit]);

    useEffect(() => {
        if (initValues?.dto && !reportPdf && !reportPdf && initValues?.dto.reportPdfId) {
            getReportPdf(initValues?.dto.id).then((response) => {
                if (!response.isError) {
                    setReportPdf(response.data);
                }
            });
        }
    }, [initValues, reportPdf, setReportPdf, getReportPdf]);

    useEffect(() => {
        const workOrder = initValues?.dto?.workOrder ?? initValues?.workOrder;
        if (workOrder) {
            getAcceptedDispatches({
                page: 0,
                pageSize: 100,
                includeInactive: false,
                workOrderId: workOrder?.id,
                isNotClosedOut: true,
                sortKey: 'CREATED',
                sortAsc: false
            });
        }
    }, [getAcceptedDispatches, initValues]);

    useEffect(() => {
        if (workOrder?.id !== initValues?.dto?.workOrder?.id) {
            setPresentedToClientName(workOrder?.facilityClientName ?? '');
            setPresentedToFacilityName(workOrder?.facilityName ?? '');
            setPresentedToContactName(workOrder?.facilityClientContactName ?? '');
            setPresentedToAddressLine1(workOrder?.facilityAddress?.addressLine1 ?? '');
            setPresentedToAddressLine2(workOrder?.facilityAddress?.addressLine2 ?? '');
            setPresentedToCity(workOrder?.facilityAddress?.city ?? '');
            setPresentedToState(workOrder?.facilityAddress?.state ?? '');
            setPresentedToZip(workOrder?.facilityAddress?.zipCode ?? '');
            setDescriptionOfProblem(workOrder?.problemDescription ?? '');
            setTechnicianName('');
            setRoofSections('');
            setReportedBy(workOrder?.calledInBy ?? '');
            setProposalForthcoming(false);
            setSummaryWorkPerformed('');
            setArrival(null);
            setDeparture(null);
        }
    }, [initValues, userData, workOrder]);

    const validate = useCallback(
        (fieldName: string) => {
            let isValid = false;
            if (fieldName === 'NAME') {
                if (name === '' || name === null || name === undefined) {
                    fieldErrors.NAME = 'Report name is required';
                    isValid = false;
                } else {
                    fieldErrors.NAME = '';
                    isValid = true;
                }
            } else if (fieldName === 'WO_NUMBER') {
                if (workOrder) {
                    fieldErrors.WO_NUMBER = '';
                    isValid = true;
                } else {
                    fieldErrors.WO_NUMBER = 'Work Order # is required';
                    isValid = false;
                }
            } else if (fieldName === 'REPORT_DATE') {
                if (reportDate) {
                    fieldErrors.REPORT_DATE = '';
                    isValid = true;
                } else {
                    fieldErrors.REPORT_DATE = 'Report Date is required';
                    isValid = false;
                }
            } else if (fieldName === 'ARRIVAL') {
                if (arrival && (departure ? isDateGreaterThanOrEqual(departure, arrival) : false)) {
                    fieldErrors.ARRIVAL = '';
                    isValid = true;
                } else {
                    fieldErrors.ARRIVAL = 'Arrival Date & Time is required and must be before departure';
                    isValid = false;
                }
            } else if (fieldName === 'DEPARTURE') {
                if (departure && (arrival ? isDateGreaterThanOrEqual(departure, arrival) : false)) {
                    fieldErrors.DEPARTURE = '';
                    isValid = true;
                } else {
                    fieldErrors.DEPARTURE = 'Departure Date & Time is required and must be after arrival';
                    isValid = false;
                }
            } else if (fieldName === 'SUMMARY_OF_WORK_PERFORMED') {
                if (summaryWorkPerformed && summaryWorkPerformed !== '') {
                    fieldErrors.SUMMARY_OF_WORK_PERFORMED = '';
                    isValid = true;
                } else {
                    fieldErrors.SUMMARY_OF_WORK_PERFORMED = 'Summary of Work Performed is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CLIENT') {
                if (presentedToClientName) {
                    fieldErrors.PRESENTED_TO_CLIENT = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CLIENT = 'Client is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_FACILITY') {
                if (presentedToFacilityName) {
                    fieldErrors.PRESENTED_TO_FACILITY = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_FACILITY = 'Facility is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CONTACT_NAME') {
                if (presentedToContactName) {
                    fieldErrors.PRESENTED_TO_CONTACT_NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CONTACT_NAME = 'Contact Name is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_ADDRESS') {
                if (presentedToAddressLine1) {
                    fieldErrors.PRESENTED_TO_ADDRESS = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_ADDRESS = 'Address Line 1 is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_CITY') {
                if (presentedToCity) {
                    fieldErrors.PRESENTED_TO_CITY = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_CITY = 'City is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_STATE') {
                if (presentedToState) {
                    fieldErrors.PRESENTED_TO_STATE = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_STATE = 'State is required';
                    isValid = false;
                }
            } else if (fieldName === 'PRESENTED_TO_ZIP') {
                if (presentedToZip) {
                    fieldErrors.PRESENTED_TO_ZIP = '';
                    isValid = true;
                } else {
                    fieldErrors.PRESENTED_TO_ZIP = 'Zip Code is required';
                    isValid = false;
                }
            } else if (fieldName === 'DESCRIPTION_OF_PROBLEM') {
                if (presentedToZip) {
                    fieldErrors.DESCRIPTION_OF_PROBLEM = '';
                    isValid = true;
                } else {
                    fieldErrors.DESCRIPTION_OF_PROBLEM = 'Description of problem is required';
                    isValid = false;
                }
            }
            setFieldErrors({
                ...fieldErrors,
            });
            return isValid;
        },
        [arrival, departure, fieldErrors, name, presentedToAddressLine1, presentedToCity, presentedToClientName, presentedToContactName, presentedToFacilityName, presentedToState, presentedToZip, reportDate, summaryWorkPerformed, workOrder]
    );

    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setName(event.target.value);
    }, []);

    const handleVisibilityChange = useCallback((updatedVisibility: VisibilityConfig) => {
        setFormChanged(true);
        setVisibilityConfig(updatedVisibility);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    }, []);

    const handleReportDateChange = useCallback((value: Date | null | undefined) => {
        setFormChanged(true);
        setReportDate(value);
    }, []);

    const handlePresentedToClientNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToClientName(event.target.value);
    }, []);

    const handlePresentedToFacilityNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToFacilityName(event.target.value);
    }, []);

    const handlePresentedToContactNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToContactName(event.target.value);
    }, []);

    const handlePresentedToAddressLine1Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToAddressLine1(event.target.value);
    }, []);

    const handlePresentedToAddressLine2Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToAddressLine2(event.target.value);
    }, []);

    const handlePresentedToCityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToCity(event.target.value);
    }, []);

    const handlePresentedToStateChange = useCallback((event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        setPresentedToState(event.target.value);
    }, []);

    const handlePresentedToZipChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPresentedToZip(event.target.value);
    }, []);

    const onFieldBlur = useCallback(
        (fieldName: string) => () => {
            validate(fieldName);
        },
        [validate]
    );

    const handleWorkOrderOpen = useCallback(() => {
        setIsSelectWorkOrdersDialogOpen(true);
    }, []);

    const handleWorkOrdersDialogClose = useCallback(() => {
        setIsSelectWorkOrdersDialogOpen(false);
    }, []);

    const handleWorkOrdersDialogSelect = useCallback(
        (sectionSelected: WorkOrderListViewDto) => {
            setWorkOrder(sectionSelected);
            setWorkOrderDispatch(undefined);
            if (sectionSelected?.id !== initValues?.dto?.workOrderId) {
                setPresentedToClientName(sectionSelected?.facilityClientName ?? '');
                setPresentedToFacilityName(sectionSelected?.facilityName ?? '');
                setPresentedToContactName(sectionSelected?.facilityClientContactName ?? '');
                setPresentedToAddressLine1(sectionSelected?.facilityAddress?.addressLine1 ?? '');
                setPresentedToAddressLine2(sectionSelected?.facilityAddress?.addressLine2 ?? '');
                setPresentedToCity(sectionSelected?.facilityAddress?.city ?? '');
                setPresentedToState(sectionSelected?.facilityAddress?.state ?? '');
                setPresentedToZip(sectionSelected?.facilityAddress?.zipCode ?? '');
                setDescriptionOfProblem(sectionSelected?.problemDescription ?? '');
                setTechnicianName('');
                setRoofSections('');
                setReportedBy(sectionSelected?.calledInBy ?? '');
                setProposalForthcoming(false);
                setSummaryWorkPerformed('');
                setArrival(null);
                setDeparture(null);
            }
            setIsSelectWorkOrdersDialogOpen(false);
            getAcceptedDispatches({
                page: 0,
                pageSize: 100,
                includeInactive: false,
                workOrderId: sectionSelected.id,
                isNotClosedOut: true,
                sortKey: 'CREATED',
                sortAsc: false
            });
        },
        [getAcceptedDispatches, initValues]
    );

    const handleWorkOrderDispatchSelect = useCallback((dispatch?: WorkOrderDispatchDto) => {
        setWorkOrderDispatch(dispatch);
        setFormChanged(true);
        if (dispatch && dispatch?.id !== initValues?.dto?.workOrderDispatch?.id) {
            setPresentedToClientName(dispatch?.clientName ?? '');
            setPresentedToFacilityName(dispatch?.facilityName ?? '');
            setPresentedToContactName(dispatch?.workOrder ? `${dispatch.workOrder?.facilityClientContactName}` : '');
            setPresentedToAddressLine1(dispatch?.addressLine1 ?? '');
            setPresentedToAddressLine2(dispatch?.addressLine2 ?? '');
            setPresentedToCity(dispatch?.city ?? '');
            setPresentedToState(dispatch?.state ?? '');
            setPresentedToZip(dispatch?.zipCode ?? '');
            setReportedBy(dispatch?.workOrder?.calledInBy ?? '');
            setDescriptionOfProblem(dispatch?.problemDescription ?? '');
            setTechnicianName(dispatch?.technicianName ?? '');
            setRoofSections(dispatch?.roofSections ?? '');
            setProposalForthcoming(dispatch?.proposalForthcoming ?? false);
            setSummaryWorkPerformed(dispatch?.summaryWorkPerformed ?? '');
            setArrival(dispatch?.arrivalDateTime ? new Date(dispatch.arrivalDateTime) : null);
            setDeparture(dispatch?.departureDateTime ? new Date(dispatch.departureDateTime) : null);
        }
    }, [initValues]);

    const clearForm = useCallback(() => {
        setWorkOrderDispatch(undefined);
        setWorkOrder(undefined);
        setFormChanged(true);
        setPresentedToClientName('');
        setPresentedToFacilityName('');
        setPresentedToContactName('');
        setPresentedToAddressLine1('');
        setPresentedToAddressLine2('');
        setPresentedToCity('');
        setPresentedToState('');
        setPresentedToZip('');
        setReportedBy('');
        setDescriptionOfProblem('');
        setTechnicianName('');
        setRoofSections('');
        setProposalForthcoming(false);
        setSummaryWorkPerformed('');
        setArrival(null);
        setDeparture(null);
    }, []);

    const formIsValid = useCallback(() => {
        let isValid = validate('ARRIVAL');
        isValid = validate('NAME') && isValid;
        isValid = validate('DEPARTURE') && isValid;
        isValid = validate('SUMMARY_OF_WORK_PERFORMED') && isValid;
        isValid = validate('WO_NUMBER') && isValid;
        isValid = validate('REPORT_DATE') && isValid;
        isValid = validate('PRESENTED_TO_CLIENT') && isValid;
        isValid = validate('PRESENTED_TO_FACILITY') && isValid;
        isValid = validate('PRESENTED_TO_ADDRESS') && isValid;
        isValid = validate('PRESENTED_TO_CITY') && isValid;
        isValid = validate('PRESENTED_TO_CONTACT_NAME') && isValid;
        isValid = validate('PRESENTED_TO_STATE') && isValid;
        isValid = validate('PRESENTED_TO_ZIP') && isValid;
        isValid = validate('DESCRIPTION_OF_PROBLEM') && isValid;
        isValid = validate('PRESENTED_TO_ZIP') && isValid;
        return isValid;
    }, [validate]);

    const getSaveObject = useCallback(() => {
        return {
            dto: {
                id: initValues?.dto?.id ?? emptyGuid,
                isActive: isActive,
                arrivalDateTime: arrival ?? undefined,
                departureDateTime: departure ?? undefined,
                proposalForthcoming: proposalForthcoming,
                summaryOfWorkPerformed: summaryWorkPerformed,
                technicianName: technicianName,
                roofSections: roofSections,
                workOrderDispatchId: workOrderDispatch?.id ?? undefined,
                workOrderId: workOrder?.id ?? undefined,
                reportDate: reportDate,
                reportName: name,
                descriptionOfProblem: descriptionOfProblem,
                presentedToAddress1: presentedToAddressLine1,
                presentedToAddress2: presentedToAddressLine2,
                presentedToCity: presentedToCity,
                presentedToClientName: presentedToClientName,
                presentedToContactName: presentedToContactName,
                presentedToFacilityName: presentedToFacilityName,
                presentedToState: presentedToState,
                presentedToZip: presentedToZip,
                reportedBy: reportedBy ?? '',
                photos: photos.map((attachment, index) => {
                    return { ...attachment.photo, caption: photoCaptions[index], order: index };
                }),
                isVisibleToClients: visibilityConfig.isVisibleToClients,
                isVisibleToEmployees: visibilityConfig.isVisibleToEmployees,
                reportPdfId: reportPdf?.id ?? undefined,
                visibility: 'computed' // this is a computed field on the back-end the value set here does not matter
            }
        };
    }, [initValues?.dto?.id, isActive, arrival, departure, proposalForthcoming, summaryWorkPerformed, technicianName, roofSections, workOrderDispatch?.id, workOrder?.id, reportDate, name, descriptionOfProblem, presentedToAddressLine1, presentedToAddressLine2, presentedToCity, presentedToClientName, presentedToContactName, presentedToFacilityName, presentedToState, presentedToZip, reportedBy, photos, visibilityConfig.isVisibleToClients, visibilityConfig.isVisibleToEmployees, photoCaptions, reportPdf]);

    const handleSave = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            if (formIsValid()) {
                save(getSaveObject());
                setFormChanged(false);
            }
        }, [formIsValid, save, getSaveObject]
    );

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    const scrollToPhotos = useCallback(() => {
        photosRef?.current?.scrollIntoView();
    }, []);

    const downloadPdf = useCallback(() => {
        if (reportPdf?.downloadUrl) {
            window.open(reportPdf?.downloadUrl);
        }
    }, [reportPdf?.downloadUrl]);

    const handlePdfDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const generatePdf = useCallback(() => {
        enqueueSnackbar('Report PDF Requested. Please wait while it is generated.');
        createReportPdf(initValues?.dto?.id ?? '');
    }, [createReportPdf, enqueueSnackbar, initValues?.dto?.id]);

    const handleGeneratePdf = useCallback(
        (event: React.SyntheticEvent) => {
            if (reportPdf && reportPdf.status === ReportPdfStatus.Created) {
                handlePdfDialog();
            } else {
                event.preventDefault();
                generatePdf();
            }
        },
        [generatePdf, handlePdfDialog, reportPdf]
    );

    const breadcrumbLinks = useMemo((): INavBreadcrumbLink[] => {
        var links = [
            { label: 'Home', navLink: '/' },
            { label: 'Client Reports', navLink: '/clientreports' },
        ];

        // TODO: get clarity on how close out reports relate to different work order types
        // Right now this logic is setup so we can handle work orders or capital projects in this report. However, it is unclear right now if it is
        // valid for a close out report to be generated from a capital project. I would like to leave this logic here for now but if we can get clarity
        // from the client on this in the future we may decide that this extra logic is not needed.
        if (workOrder) {
            const isWorkOrderOrderType = workOrder?.orderType === 0;
            const workOrderLinkLabel = isWorkOrderOrderType ? 'Work Order' : 'Capital Project';
            const workOrderLinkUrl = `/${isWorkOrderOrderType ? 'workorders' : 'capitalprojects'}/edit/${workOrder?.id}`;
            links.push({ label: workOrderLinkLabel, navLink: workOrderLinkUrl });
        }

        return links;
    }, [workOrder]);

    return (
        <Grid container direction='column' rowSpacing={2}>
            <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
                <Grid position='sticky' item container direction='row' alignItems='center' xs={12}>
                    <Grid item container direction='column' justifyContent='start' xs={8}>
                        <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                            <Assessment /> {name ? name : 'Add New Close Out Report'}
                        </Typography>
                        <NavBreadcrumbs links={breadcrumbLinks} currentPageLabel={name ? name : 'Add New Close Out Report'} />
                    </Grid>
                    <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                        <Grid item>
                            <FormControlLabel
                                control={<Switch checked={isActive} onChange={handleActiveToggleChange} />}
                                label='Active'
                                labelPlacement='start'
                                disabled={isReportLocked}
                            />
                        </Grid>
                        <Grid item>
                            {changed || !initValues?.dto ? (
                                <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                    Cancel
                                </FormButton>
                            ) : (
                                <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                    Close
                                </FormButton>
                            )}
                        </Grid>
                        <Grid item>
                            <FormButton variant='contained' color='primary' type='submit' onClick={handleSave} disabled={isReportLocked}>
                                Save
                            </FormButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
                    <Grid item container direction='row' xs={6} spacing={2}>
                        <AuthenticatedComponent
                            requiredPermissions={['read:reports']}
                            component={
                                <Grid item>
                                    <MenuButton onClick={scrollToPhotos} disabled={initValues?.dto?.id === emptyGuid} tooltip='Photos'>
                                        <Map />
                                    </MenuButton>
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['read:reports']}
                            logic='or'
                            component={
                                <Grid item>
                                    <MenuButton
                                        onClick={handleGeneratePdf}
                                        disabled={
                                            initValues!.dto?.id === emptyGuid ||
                                            (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ||
                                            isGeneratingReport ||
                                            (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ||
                                            isReportLocked
                                        }
                                        tooltip='Generate PDF'>
                                        <PictureAsPdf />
                                    </MenuButton>
                                </Grid>
                            }
                        />
                        {dialogOpen && <ConfirmPdfDialog open={dialogOpen} close={() => setDialogOpen(false)} generatePdf={generatePdf} />}
                    </Grid>
                    {isGeneratingReport || (reportPdf && reportPdf.status === ReportPdfStatus.Creating) ? (
                        <Grid item container xs={6} justifyContent='end' alignItems='center' wrap='nowrap'>
                            <Grid item>
                                <i>Please check again later... Your PDF is still generating</i>
                            </Grid>
                            <Grid item>
                                <LoadingIndicator />
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {reportPdf && reportPdf.status === ReportPdfStatus.Error ? (
                        <Grid item>
                            <i style={{ color: 'red' }}>The PDF failed to generate. Please try again.</i>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {reportPdf && reportPdf.status === ReportPdfStatus.Created && reportPdf.createdOn && !isGeneratingReport ? (
                        <Grid item container direction='column' xs={6} alignItems='end'>
                            <Grid item container direction='row' spacing={1} justifyContent='end'>
                                <Grid item>
                                    <PictureAsPdf />
                                </Grid>
                                <Grid item>
                                    <Link onClick={downloadPdf} sx={{ cursor: 'pointer' }}>
                                        {reportPdf.displayName}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <i>
                                    Generated on {new Date(reportPdf.updatedOn ? reportPdf.updatedOn : reportPdf.createdOn).toLocaleDateString()} at{' '}
                                    {new Date(reportPdf.updatedOn ? reportPdf.updatedOn : reportPdf.createdOn).toLocaleString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    })}
                                </i>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item>
                    <FormSection>
                        <Grid item container direction='row' spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormInput
                                    label='Report Name'
                                    onBlur={onFieldBlur('NAME')}
                                    value={name}
                                    fullWidth
                                    required
                                    error={fieldErrors.NAME !== ''}
                                    errorText={fieldErrors.NAME}
                                    onChange={handleNameChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl error={fieldErrors.REPORT_DATE !== ''} fullWidth required>
                                    <DateTimePicker
                                        label='Report Date'
                                        value={reportDate}
                                        onChange={handleReportDateChange}
                                        dateOnly={true}
                                        onBlur={onFieldBlur('REPORT_DATE')}
                                        error={fieldErrors.REPORT_DATE !== ''}
                                        onAcceptDate={() => {
                                            setFieldErrors({
                                                ...fieldErrors,
                                                REPORT_DATE: '',
                                            });
                                        }}
                                        disabled={isReportLocked}
                                    />
                                    <FormHelperText>{fieldErrors.REPORT_DATE}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item container sm={12} md={3}>
                                <FormControl
                                    error={fieldErrors.WO_NUMBER !== ''}
                                    fullWidth
                                    required
                                    disabled={isReportLocked || initValues?.dto?.id !== undefined}>
                                    <FormLabel>Work Order #</FormLabel>
                                    <Grid item container alignItems={'center'} direction='row' spacing={1}>
                                        <Grid item xs={10}>
                                            <Autocomplete
                                                value={workOrder ?? null}
                                                onChange={(_event, value) => {
                                                    if (value) {
                                                        handleWorkOrdersDialogSelect(value);
                                                    } else {
                                                        clearForm();
                                                    }
                                                }}
                                                open={workOrderOpen}
                                                onOpen={() => setWorkOrderOpen(true)}
                                                onClose={() => setWorkOrderOpen(false)}
                                                options={workOrders?.pageResults.filter((option) => option.isActive || option.id === workOrder?.id) ?? []}
                                                loading={workOrdersLoading}
                                                isOptionEqualToValue={(option, value) => option.woNumber === value.woNumber}
                                                getOptionLabel={(option: WorkOrderListViewDto) => `${option.woNumber}${option.isActive ? '' : ' (Inactive)'}`}
                                                disabled={isReportLocked || initValues?.dto?.id !== undefined}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={fieldErrors.WO_NUMBER !== ''}
                                                        onBlur={onFieldBlur('WO_NUMBER')}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {workOrdersLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton disabled={isReportLocked || initValues?.dto?.id !== undefined} onClick={() => handleWorkOrderOpen()}>
                                                <OpenInFull sx={{ fontSize: 36 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <FormHelperText>{fieldErrors.WO_NUMBER}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <VisibilityControl gridProps={{ xs: 3 }} value={visibilityConfig} onValueChange={handleVisibilityChange} disabled={isReportLocked} />
                            <Grid item container sm={12} md={3}>
                                <FormControl fullWidth disabled={isReportLocked || initValues?.dto?.id !== undefined}>
                                    <FormLabel>
                                        Accepted Dispatch{' '}
                                        <Tooltip title='Accepted dispatches without an existing closeout report.'>
                                            <Info sx={{ fontSize: '.85rem' }} />
                                        </Tooltip>
                                    </FormLabel>
                                    <IEntityAutocomplete
                                        options={acceptedDispatches?.pageResults ?? []}
                                        onChange={(e, value) => {
                                            handleWorkOrderDispatchSelect(value);
                                        }}
                                        value={workOrderDispatch ?? null}
                                        getOptionLabel={(option: WorkOrderDispatchDto) =>
                                            `${option.contractor?.dispatchId} - ${format(new Date(option.scheduledDate!.toString()), 'M/d/yyyy')}` ?? ''
                                        }
                                        disabled={isReportLocked || initValues?.dto?.id !== undefined}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ fontWeight: 'bold' }}>Presented To</Typography>
                        </Grid>
                        <Grid item container direction='row' spacing={8}>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Owner'
                                    onBlur={onFieldBlur('PRESENTED_TO_CLIENT')}
                                    value={presentedToClientName}
                                    fullWidth
                                    required
                                    error={fieldErrors.PRESENTED_TO_CLIENT !== ''}
                                    errorText={fieldErrors.PRESENTED_TO_CLIENT}
                                    onChange={handlePresentedToClientNameChange}
                                    disabled={isReportLocked}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Facility'
                                    onBlur={onFieldBlur('PRESENTED_TO_FACILITY')}
                                    value={presentedToFacilityName}
                                    fullWidth
                                    required
                                    error={fieldErrors.PRESENTED_TO_FACILITY !== ''}
                                    errorText={fieldErrors.PRESENTED_TO_FACILITY}
                                    onChange={handlePresentedToFacilityNameChange}
                                    disabled={isReportLocked}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={8}>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Contact Name'
                                    onBlur={onFieldBlur('PRESENTED_TO_CONTACT_NAME')}
                                    value={presentedToContactName}
                                    fullWidth
                                    required
                                    error={fieldErrors.PRESENTED_TO_CONTACT_NAME !== ''}
                                    errorText={fieldErrors.PRESENTED_TO_CONTACT_NAME}
                                    onChange={handlePresentedToContactNameChange}
                                    disabled={isReportLocked}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Address Line 1'
                                    onBlur={onFieldBlur('PRESENTED_TO_ADDRESS')}
                                    value={presentedToAddressLine1}
                                    fullWidth
                                    required
                                    error={fieldErrors.PRESENTED_TO_ADDRESS !== ''}
                                    errorText={fieldErrors.PRESENTED_TO_ADDRESS}
                                    onChange={handlePresentedToAddressLine1Change}
                                    disabled={isReportLocked}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Address Line 2'
                                    value={presentedToAddressLine2}
                                    fullWidth
                                    onChange={handlePresentedToAddressLine2Change}
                                    disabled={isReportLocked}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={8} alignItems={'end'}>
                            <Grid item container direction='column' spacing={4} xs={8}>
                                <Grid item container direction='row' spacing={8}>
                                    <Grid item xs={4}>
                                        <FormInput
                                            label='City'
                                            onBlur={onFieldBlur('PRESENTED_TO_CITY')}
                                            value={presentedToCity}
                                            fullWidth
                                            required
                                            error={fieldErrors.PRESENTED_TO_CITY !== ''}
                                            errorText={fieldErrors.PRESENTED_TO_CITY}
                                            onChange={handlePresentedToCityChange}
                                            disabled={isReportLocked}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl error={fieldErrors.PRESENTED_TO_STATE !== ''} fullWidth required>
                                            <FormLabel>State</FormLabel>
                                            <Select
                                                value={presentedToState ?? ''}
                                                onChange={handlePresentedToStateChange}
                                                onBlur={onFieldBlur('PRESENTED_TO_STATE')}
                                                disabled={isReportLocked}>
                                                {usStates.map((usState, idx) => (
                                                    <MenuItem key={`${usState.abbreviation}-${idx}`} value={usState.abbreviation}>
                                                        {usState.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{fieldErrors.PRESENTED_TO_STATE}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormInput
                                            label='Zip Code'
                                            onBlur={onFieldBlur('PRESENTED_TO_ZIP')}
                                            value={presentedToZip}
                                            fullWidth
                                            required
                                            error={fieldErrors.PRESENTED_TO_ZIP !== ''}
                                            errorText={fieldErrors.PRESENTED_TO_ZIP}
                                            onChange={handlePresentedToZipChange}
                                            disabled={isReportLocked}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ fontWeight: 'bold' }}>Service Order</Typography>
                        </Grid>
                        <Grid item container direction='row'>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Reported By'
                                    onBlur={onFieldBlur('REPORTED_BY')}
                                    value={reportedBy}
                                    fullWidth
                                    required
                                    error={fieldErrors.REPORTED_BY !== ''}
                                    errorText={fieldErrors.REPORTED_BY}
                                    onChange={() => { }}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row'>
                            <Grid item xs={12}>
                                <FormInput
                                    label='Description of Problem'
                                    onBlur={onFieldBlur('DESCRIPTION_OF_PROBLEM')}
                                    value={descriptionOfProblem}
                                    fullWidth
                                    required
                                    error={fieldErrors.DESCRIPTION_OF_PROBLEM !== ''}
                                    errorText={fieldErrors.DESCRIPTION_OF_PROBLEM}
                                    rows={5}
                                    onChange={(e) => {
                                        setDescriptionOfProblem(e.target.value);
                                        setFormChanged(true);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' alignItems='baseline' xs={12} spacing={2} justifyContent='space-evenly'>
                            <Grid item xs={4}>
                                <FormControl fullWidth required error={fieldErrors.ARRIVAL !== ''}>
                                    <DateTimePicker
                                        onBlur={onFieldBlur('ARRIVAL')}
                                        error={fieldErrors.ARRIVAL !== ''}
                                        errorText={fieldErrors.ARRIVAL}
                                        label='Arrival Date & Time'
                                        value={arrival ? new Date(arrival) : null}
                                        onChange={(date) => {
                                            date && setArrival(date);
                                            setFormChanged(true);
                                        }}
                                        onAcceptDate={() => {
                                            setFieldErrors({
                                                ...fieldErrors,
                                                ARRIVAL: '',
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={4}>
                                <FormControl fullWidth required error={fieldErrors.DEPARTURE !== ''}>
                                    <DateTimePicker
                                        onBlur={onFieldBlur('DEPARTURE')}
                                        error={fieldErrors.DEPARTURE !== ''}
                                        errorText={fieldErrors.DEPARTURE}
                                        label='Departure Date & Time'
                                        value={departure ? new Date(departure) : null}
                                        onChange={(date) => {
                                            date && setDeparture(date);
                                            setFormChanged(true);
                                        }}
                                        onAcceptDate={() => {
                                            setFieldErrors({
                                                ...fieldErrors,
                                                DEPARTURE: '',
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>
                        <Grid item container direction='row' alignItems='baseline' xs={12} spacing={2}>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Technician Name'
                                    value={technicianName}
                                    fullWidth
                                    onChange={(e) => {
                                        setTechnicianName(e.target.value);
                                        setFormChanged(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={4}>
                                <FormInput
                                    label='Roof Sections'
                                    value={roofSections}
                                    fullWidth
                                    onChange={(e) => {
                                        setRoofSections(e.target.value);
                                        setFormChanged(true);
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={3} alignSelf={'center'} justifyContent={'center'}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={proposalForthcoming}
                                            onChange={() => {
                                                setProposalForthcoming(!proposalForthcoming);
                                                setFormChanged(true);
                                            }}
                                        />
                                    }
                                    label='Proposal Forthcoming?'
                                    labelPlacement='start'
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row'>
                            <Grid item xs={12}>
                                <FormInput
                                    label='Summary of Work Performed'
                                    onBlur={onFieldBlur('SUMMARY_OF_WORK_PERFORMED')}
                                    value={summaryWorkPerformed ?? ''}
                                    fullWidth
                                    required
                                    error={fieldErrors.SUMMARY_OF_WORK_PERFORMED !== ''}
                                    errorText={fieldErrors.SUMMARY_OF_WORK_PERFORMED}
                                    rows={5}
                                    onChange={(e) => {
                                        setSummaryWorkPerformed(e.target.value);
                                        setFormChanged(true);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
                <SelectWorkOrdersDialog
                    open={isSelectWorkOrdersDialogOpen}
                    onCancel={handleWorkOrdersDialogClose}
                    onConfirm={handleWorkOrdersDialogSelect}
                    isMultipleSelectable={false}
                    currentWorkOrders={workOrders?.pageResults.filter((x) => x.id === workOrder?.id) ?? []}
                />
            </Grid>
            {initValues && initValues.edit && (
                <Grid item ref={photosRef}>
                    <PhotosFormSection
                        initValues={initValues?.dto}
                        photos={photos}
                        setPhotos={setPhotos}
                        photoCaptions={photoCaptions}
                        setPhotoCaptions={setPhotoCaptions}
                        disabled={isReportLocked}
                    />
                </Grid>
            )}
        </Grid>
    );
};
