import { FunctionComponent } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { IApiErrorProps } from './types';

export const ApiError: FunctionComponent<IApiErrorProps> = props => {
    const { onReloadClick } = props;

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item>
                <Typography>There was an error while loading. Click the button below to retry.</Typography>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={onReloadClick}>Reload</Button>
            </Grid>
        </Grid>
    );
};