import { useAuth0 } from '@auth0/auth0-react';
import { Assessment, Assignment, AttachMoney, Business, Description, Download, Edit, Map, People, PieChart, ReportProblem, VerifiedUser, Videocam } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthenticatedComponent from '../../auth';
import { ApiError } from '../../Components/core/ApiError';
import { AuthRoles } from '../../Components/core/AuthRoles';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { MenuButton } from '../../Components/core/MenuButton';
import { NavBreadcrumbs } from '../../Components/core/NavBreadcrumbs';
import { DashboardCard } from '../../Components/Dashboard';
import {
    CapitalProjectsCard,
    ClientDashboardDocumentsCard,
    ClientReportsListViewCard,
    DownloadsCard,
    ExecutiveSummariesFacilitiesCard,
    FacilitiesCard,
    WarrantiesCard,
    WorkOrdersCard,
} from '../../Components/Dashboard/ClientDashboard';
import { FacilityBudgetLineItemsCard } from '../../Components/Dashboard/ClientDashboard/FacilityBudgetLineItemsCard';
import { ReportLeakDialog } from '../../Components/Forms/ReportLeakDialog';
import { RoofMap } from '../../Components/Forms/RoofMap';
import { FacilityDto } from '../../models';
import { useGetClientQuery } from '../../store/apis/client-api';
import { useLazyGetFacilityQuery } from '../../store/apis/facility-api';
import { useLazyGetUserByAuth0IdQuery } from '../../store/apis/user-api';
import { useLazyGetRoofSmartCalculationQuery } from '../../store/apis/work-order-api';
import { DashboardExecutiveSummaryBudgetCard } from '../../Components/Dashboard/ClientDashboard/DashboardExecutiveSummaryBudgetCard';
import { ViewDroneFootageModal } from './ViewDroneFootageModal';
import { useGetCurrentBinderPortfolioReportPdfDownloadLinkForClientQuery } from '../../store/apis/binder-portfolio-report-api';
import { OutstandingProposalsCard } from '../../Components/Dashboard/ClientDashboard/OutstandingProposalsCard';
import { OutstandingProposalsModal } from '../../Components/Dashboard/ClientDashboard/OutstandingProposalsModal';

export interface IClientDashboardProps {
    clientId?: string;
};

export const ClientDashboard: FC<IClientDashboardProps> = (props) => {
    const { id: routeClientId, facilityId } = useParams();
    const navigate = useNavigate();
    const clientId = routeClientId ?? props.clientId;
    const { user } = useAuth0();
    const roofMapRef = useRef<null | HTMLDivElement>(null);
    const workOrdersRef = useRef<null | HTMLDivElement>(null);
    const executiveSummaryBudgetRef = useRef<null | HTMLDivElement>(null);
    const capitalProjectsRef = useRef<null | HTMLDivElement>(null);
    const warrantiesRef = useRef<null | HTMLDivElement>(null);
    const documentsRef = useRef<null | HTMLDivElement>(null);
    const { data: client, isLoading: clientLoading, error: clientError, refetch: refetchClient } = useGetClientQuery(clientId!);
    const [getFacility, { data: facilityData, isLoading: facilityLoading, error: facilityError }] = useLazyGetFacilityQuery();
    const [facility, setFacility] = useState<FacilityDto | undefined>(facilityData);
    const [userRole, setUserRole] = useState('');
    const [getUserData, { data: userData }] = useLazyGetUserByAuth0IdQuery();

    const [reportLeakOpen, setReportLeakOpen] = useState(false);
    const [viewProposalsOpen, setViewProposalsOpen] = useState(false);
    const [viewDroneFootageOpen, setViewDroneFootageOpen] = useState(false);
    const [getRoofSmartSavings, { data: roofSmartSavings }] = useLazyGetRoofSmartCalculationQuery();
    const { data: bprReport, isFetching: brpReportLinkLoading } = useGetCurrentBinderPortfolioReportPdfDownloadLinkForClientQuery(clientId ?? '');

    useEffect(() => {
        const rolesFieldName = process.env.REACT_APP_AUTH0_Roles!;
        if (user && user[rolesFieldName].length > 0) {
            setUserRole(user[rolesFieldName][0]);
        }
        if (user?.sub) {
            getUserData(user?.sub);
        }
    }, [user, getUserData]);

    useEffect(() => {
        getRoofSmartSavings({ clientId: clientId!, facilityId: facilityId ?? undefined });
    }, [clientId, facilityId, roofSmartSavings, getRoofSmartSavings]);

    useEffect(() => {
        if (facilityId && (!facilityData || facilityData.id !== facilityId)) {
            getFacility(facilityId);
        }
        if (facilityData !== facility) {
            setFacility(facilityData);
        }
        if (facilityData && !facility) {
            setFacility(facilityData);
        }
        if (facilityError) {
            setFacility(undefined);
        }
        if (!facilityId) {
            setFacility(undefined);
            refetchClient();
        }
        getRoofSmartSavings({ clientId: clientId!, facilityId: facilityId ?? undefined });
    }, [facilityData, facilityError, facility, setFacility, facilityId, getFacility, refetchClient, getRoofSmartSavings, clientId]);

    const scrollToWorkOrders = useCallback(() => {
        if (workOrdersRef) {
            workOrdersRef?.current!.scrollIntoView();
        }
    }, []);

    const scrollToRoofMap = useCallback(() => {
        if (roofMapRef) {
            roofMapRef?.current!.scrollIntoView();
        }
    }, []);

    const scrollToExecutiveSummaryBudget = useCallback(() => {
        if (executiveSummaryBudgetRef) {
            executiveSummaryBudgetRef?.current!.scrollIntoView();
        }
    }, []);

    const scrollToCapitalProjects = useCallback(() => {
        if (capitalProjectsRef) {
            capitalProjectsRef?.current!.scrollIntoView();
        }
    }, []);

    const scrollToWarranties = useCallback(() => {
        if (warrantiesRef && warrantiesRef.current) {
            warrantiesRef?.current!.scrollIntoView();
        }
    }, [warrantiesRef]);

    const scrollToDocuments = useCallback(() => {
        if (documentsRef && documentsRef.current) {
            documentsRef?.current!.scrollIntoView();
        }
    }, [documentsRef]);

    const handleCloseReportLeak = useCallback(() => {
        setReportLeakOpen(false);
    }, []);

    const handleOpenReportLeak = useCallback(() => {
        setReportLeakOpen(true);
    }, []);

    const handleCloseViewProposals = useCallback(() => {
        setViewProposalsOpen(false);
    }, []);

    const handleOpenViewProposals = useCallback(() => {
        setViewProposalsOpen(true);
    }, []);

    const handleViewDroneFootage = useCallback(() => {
        setViewDroneFootageOpen(true);
    }, []);

    const handleCloseDroneFootage = useCallback(() => {
        setViewDroneFootageOpen(false);
    }, []);

    const downloadBPRPdf = useCallback(() => {
        if (bprReport?.downloadUrl) {
            window.open(bprReport?.downloadUrl);
        }
    }, [bprReport]);

    const refetchPage = useCallback(() => {
        refetchClient();
        if (facilityId) {
            getFacility(facilityId);
        }
    }, [facilityId, getFacility, refetchClient]);

    if (clientLoading || facilityLoading) {
        return <LoadingIndicator />;
    }

    if (clientError) {
        return <ApiError onReloadClick={refetchPage} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start' justifyContent='space-between'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    {facility ? (
                        <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                            <Business /> {facility?.name}
                        </Typography>
                    ) : (
                        <Grid item container direction='row' alignItems={'center'} spacing={1}>
                            <Grid item>
                                <Typography variant='h1' >
                                    <People />  {client?.name}
                                </Typography>
                            </Grid>
                            <AuthenticatedComponent
                                requiredPermissions={['edit:clients']}
                                component={
                                    <Grid item>
                                        <IconButton onClick={() => navigate(`/clients/edit/${clientId}`)}><Edit /></IconButton>
                                    </Grid>
                                }
                            />
                        </Grid>
                    )}
                    {facility ? (
                        userRole === AuthRoles.ClientManager ? (
                            <NavBreadcrumbs links={[{ label: 'Home', navLink: `/clients/${userData?.clientId}` }]} currentPageLabel={facility?.name!} />
                        ) : (
                            <NavBreadcrumbs
                                links={[
                                    { label: 'Home', navLink: '/' },
                                    { label: 'Clients', navLink: '/clients' },
                                    { label: client?.name!, navLink: `/clients/${client?.id!}` },
                                ]}
                                currentPageLabel={facility?.name!}
                            />
                        )
                    ) : userRole === AuthRoles.ClientManager ? (
                        <NavBreadcrumbs links={[]} currentPageLabel='Home' />
                    ) : (
                        <NavBreadcrumbs
                            links={[
                                { label: 'Home', navLink: '/' },
                                { label: 'Clients', navLink: '/clients' },
                            ]}
                            currentPageLabel={client?.name!}
                        />
                    )}
                </Grid>
                {/* <Grid item> Hidden for now until they decide what to do with it. - Justin 3/2/2023
                    <Typography variant='h1'>
                        Current RoofSMART Savings: $
                        {(roofSmartSavings ?? 0) <= 0 ? '0.00' : roofSmartSavings?.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </Typography>
                </Grid> */}
            </Grid>
            <Grid item container direction='row' justifyContent='space-between' >
                <Grid item container direction='row' xs={8} spacing={2} >
                    {facility ? (
                        <AuthenticatedComponent
                            requiredPermissions={['read:roofMaps']}
                            component={
                                <Grid item>
                                    <MenuButton onClick={scrollToRoofMap} tooltip='Roof Map'>
                                        <Map />
                                    </MenuButton>
                                </Grid>
                            }
                        />
                    ) : (
                        <></>
                    )}
                    <AuthenticatedComponent
                        requiredPermissions={['read:workorders']}
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToWorkOrders} tooltip='Work Orders'>
                                    <Assignment />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:budgets']}
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToExecutiveSummaryBudget} tooltip='Executive Summary Budget'>
                                    <AttachMoney />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:workorders']}
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToCapitalProjects} tooltip='Capital Projects'>
                                    <Assessment />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:warranties']}
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToWarranties} tooltip='Warranties'>
                                    <VerifiedUser />
                                </MenuButton>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['read:documentsAll', 'read:documentsEmployee', 'read:documentsClient']}
                        logic='or'
                        component={
                            <Grid item>
                                <MenuButton onClick={scrollToDocuments} tooltip='Documents'>
                                    <Description />
                                </MenuButton>
                            </Grid>
                        }
                    />
                </Grid>
                <Grid item container xs={'auto'} spacing={2}>
                    <AuthenticatedComponent
                        requiredPermissions={['read:facilities']}
                        component={
                            <Grid item container direction='row' xs='auto' alignContent='center'>
                                <Grid item>
                                    {!!facility && (
                                        <Button variant='contained' color='primary' startIcon={<Videocam />} onClick={handleViewDroneFootage} disabled={!facility?.droneFootageUrl}>
                                            View Drone Footage
                                        </Button>
                                    )}

                                </Grid>
                            </Grid>
                        }
                    />
                    {!facilityId && <AuthenticatedComponent
                        requiredPermissions={['create:roofLeaks']}
                        component={
                            <Grid item container direction='row' xs='auto' alignContent='center'>
                                <Grid item>
                                    <Button variant='contained' color='primary' startIcon={brpReportLinkLoading ? <CircularProgress size='1rem' /> : <Download />} onClick={downloadBPRPdf} disabled={brpReportLinkLoading || !bprReport}>
                                        Strategic Plan
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    />}
                    <AuthenticatedComponent
                        requiredPermissions={['create:roofLeaks']}
                        component={
                            <Grid item container direction='row' xs='auto' alignContent='center'>
                                <Grid item>
                                    <Button variant='contained' color='primary' startIcon={<ReportProblem />} onClick={handleOpenReportLeak}>
                                        Report Leak
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
            <AuthenticatedComponent
                requiredPermissions={['read:workorders']}
                component={
                    <Grid item container direction='row' alignItems='stretch' spacing={2}>
                        <OutstandingProposalsCard clientId={clientId!} facility={facility} handleViewProposals={handleOpenViewProposals} />
                    </Grid>

                }
            />
            <AuthenticatedComponent
                requiredPermissions={['read:facilities']}
                component={
                    <Grid item container direction='row' alignItems='stretch' spacing={2} >
                        <Grid item xs={3}>
                            <FacilitiesCard clientId={clientId!} facility={facility} />
                        </Grid>
                        <Grid item xs={9}>
                            <DashboardCard headerIcon={<PieChart />} headerTitle={facility ? `Executive Summary of ${facility.name}` : `Executive Summary of All Facilities`}>
                                <ExecutiveSummariesFacilitiesCard clientId={clientId!} facility={facility} />
                            </DashboardCard>
                        </Grid>
                    </Grid>
                }
            />
            {facility ? (
                <AuthenticatedComponent
                    requiredPermissions={['read:roofMaps']}
                    component={
                        <Grid item ref={roofMapRef} maxWidth={'100% !important'}>
                            <DashboardCard headerTitle='Roof Map' headerIcon={<Map />}>
                                <RoofMap
                                    facility={facility!}
                                    roofProfileId={facility?.roofProfiles?.find((x) => x.isCurrent)?.id}
                                    svgContents={facility?.roofProfiles?.find((x) => x.isCurrent)?.svgContents}
                                    showTableInit={false}
                                    canLinkSections={true}
                                    reloadProfile={() => getFacility(facilityId!)}
                                />
                            </DashboardCard>
                        </Grid>
                    }
                />
            ) : (
                <></>
            )}
            <AuthenticatedComponent
                requiredPermissions={['read:workorders']}
                component={
                    <Grid item ref={workOrdersRef} maxWidth={'100% !important'}>
                        <WorkOrdersCard clientId={clientId!} facility={facility} isClientDashboardView={true} />
                    </Grid>
                }
            />
            <AuthenticatedComponent
                requiredPermissions={['read:budgets']}
                component={
                    <Grid item ref={executiveSummaryBudgetRef} maxWidth={'100% !important'}>
                        {facility ? <FacilityBudgetLineItemsCard facilityId={facility!.id} /> : <DashboardExecutiveSummaryBudgetCard clientId={clientId!} />}
                    </Grid>
                }
            />
            <AuthenticatedComponent
                requiredPermissions={['read:workorders']}
                component={
                    <Grid item ref={capitalProjectsRef} maxWidth={'100% !important'}>
                        <CapitalProjectsCard clientId={clientId!} facility={facility} isClientDashboardView={true} />
                    </Grid>
                }
            />
            <AuthenticatedComponent
                requiredPermissions={['read:warranties']}
                component={
                    <Grid item ref={warrantiesRef} maxWidth={'100% !important'}>
                        <WarrantiesCard clientId={clientId!} facility={facility} />
                    </Grid>
                }
            />
            <AuthenticatedComponent
                requiredPermissions={['read:documentsAll', 'read:documentsEmployee', 'read:documentsClient']}
                logic='or'
                component={
                    <Grid item ref={documentsRef} maxWidth={'100% !important'}>
                        <ClientDashboardDocumentsCard client={client!} facility={facility} isClientDashboardView={true} />
                    </Grid>
                }
            />
            <AuthenticatedComponent
                requiredPermissions={['nav:reports']}
                component={
                    <Grid item ref={undefined} maxWidth={'100% !important'}>
                        <ClientReportsListViewCard clientId={clientId!} facility={facility} />
                    </Grid>
                }
            />
            <AuthenticatedComponent
                requiredPermissions={['read:downloadsAll', 'read:downloadsEmployee', 'read:downloadsClient']}
                logic='or'
                component={
                    <Grid item ref={undefined} maxWidth={'100% !important'}>
                        <DownloadsCard clientId={clientId!} facility={facility} />
                    </Grid>
                }
            />
            {reportLeakOpen && (
                <ReportLeakDialog open={reportLeakOpen} onClose={handleCloseReportLeak} clientId={clientId!} selectedFacility={facility}></ReportLeakDialog>
            )}
            {viewProposalsOpen && (
                <OutstandingProposalsModal clientId={clientId!} facility={facility} isClientDashboardView={true} open={viewProposalsOpen} onClose={handleCloseViewProposals}></OutstandingProposalsModal>
            )}
            {viewDroneFootageOpen && (<ViewDroneFootageModal isOpen={viewDroneFootageOpen} close={handleCloseDroneFootage} facility={facility!} />)}
        </Grid>
    );
};
