import { Assignment, Description, Done, LockOpen, PictureAsPdf, Refresh, Warning } from '@mui/icons-material';
import { FormControl, FormControlLabel, Grid, IconButton, Link, Switch, Typography } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkOrderDispatchRoofLeaksCard } from '.';
import { DashboardCard } from '../../Components/Dashboard';
import { Attachment, FormSection } from '../../Components/Forms';
import { DateTimePicker, FormInput, FormWrapper } from '../../Components/Forms/FormFields';
import { Point } from '../../Components/Forms/RoofMap';
import { DispatchInformation } from '../../Components/Forms/WorkOrderForm/WorkOrderDispatch/DispatchInformation';
import { ApiError } from '../../Components/core/ApiError';
import { ConfirmDialog } from '../../Components/core/ConfirmDialog';
import { PaginatedProps } from '../../Components/core/DataTable';
import { DocumentsFormSection } from '../../Components/core/Documents';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { INavBreadcrumbsProps } from '../../Components/core/NavBreadcrumbs';
import { PhotosFormSection } from '../../Components/core/Photos/PhotosFormSection';
import { RoofLeakDetailsDialog } from '../../Components/core/RoofLeakDialog';
import AuthenticatedComponent from '../../auth';
import { ReportPdfStatus, RoofLeakDto, WorkOrderDispatchDto, emptyGuid } from '../../models';
import { useCreateDispatchPdfMutation, useLazyGetDispatchPdfQuery } from '../../store/apis/dispatch-api';
import { useGetRoofSectionsQuery } from '../../store/apis/roof-section-api';
import { useCompleteWorkOrderDispatchMutation, useGetWorkOrderDispatchDocumentsQuery, useUpdateWorkOrderDispatchMutation } from '../../store/apis/work-order-dispatch-api';
import { useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulActionSnackbar, useSuccessfulUpdateSnackbar, useUserRoleChecker } from '../../util/customHooks';
import { DispatchNeedsAttentionSection } from './DispatchNeedsAttentionSection';
import { useSubmitDispatchForm } from './useSubmitDispatchForm';

export interface WorkOrderDispatchFormProps {
    dispatch: WorkOrderDispatchDto;
}

export const SubmitWorkOrderDispatchForm: FC<WorkOrderDispatchFormProps> = ({ dispatch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const isExistingCloseOutReportWithPhotos = useMemo(() => (dispatch.closeOutReport?.photos.length ?? 0) > 0, [dispatch.closeOutReport]);
    
    const [scheduledDate, setScheduledDate] = useState<Date | null | undefined>(dispatch.scheduledDate ? new Date(dispatch.scheduledDate) : null);

    const [arrival, setArrival] = useState<Date | null | undefined>(dispatch.arrivalDateTime ? new Date(dispatch.arrivalDateTime) : null);
    const [departure, setDeparture] = useState<Date | null | undefined>(dispatch.departureDateTime ? new Date(dispatch.departureDateTime) : null);
    const [proposalForthcoming, setProposalForthcoming] = useState(dispatch.proposalForthcoming ?? false);
    const [technicianName, setTechnicianName] = useState(dispatch.technicianName);
    const [roofSections, setRoofSections] = useState(dispatch.roofSections);
    const [summaryWorkPerformed, setSummaryWorkPerformed] = useState(dispatch.summaryWorkPerformed ?? '');

    const [searchText, setSearchText] = useState('');
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [roofLeaks, setRoofLeaks] = useState<RoofLeakDto[] | undefined>(undefined);

    const [selectedRoofLeakAnchorEl, setSelectedRoofLeakAnchorEl] = useState<null | any>(null);
    const [selectedRoofLeak, setSelectedRoofLeak] = useState<number | undefined>();

    const [isConfirmCloseOutOpen, setIsConfirmCloseOutOpen] = useState(false);
    const [shouldOverwriteCloseOutReportPhotos, setShouldOverwriteCloseOutReportPhotos] = useState(false);

    const [updateDispatch, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: updateReset }] =
        useUpdateWorkOrderDispatchMutation();
    useFailedUpdateSnackbar('work order dispatch', isUpdateError, updateReset);
    useSuccessfulUpdateSnackbar('Work order dispatch', isUpdateSuccess, updateReset);

    const [submitDispatch, { isSuccess: isSubmitSuccess, isError: isSubmitError, reset: submitReset }] =
        useCompleteWorkOrderDispatchMutation();
    useFailedActionSnackbar('submit', 'work order dispatch', isSubmitError, submitReset);
    useSuccessfulActionSnackbar('submitted', 'Work order dispatch', isSubmitSuccess, () => {
        submitReset();
        navigate(`/`);
    });

    const onCloseOutComplete = useCallback((closeOutReportId?: string) => {
        navigate(`/closeOutReports/edit/${closeOutReportId}`);
    }, [navigate]);

    const {
        closeOutDispatch,
        getSubmitButtonText,
        isLocked,
        isFullEdit,
        confirmUnlockOpen,
        setConfirmUnlockOpen,
        handleUnlock
    } = useSubmitDispatchForm(dispatch, onCloseOutComplete);

    const { isContractor, isEmployee, isAdmin } = useUserRoleChecker();

    const {
        data: documentsData,
        isLoading: documentsLoading,
        error: documentsError,
        refetch: documentsRefetch,
    } = useGetWorkOrderDispatchDocumentsQuery({
        parentId: dispatch.id!,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: false,
        },
    });

    const {
        data: roofSectionsData,
        isLoading: roofSectionsLoading,
        error: roofSectionsError,
        refetch: roofSectionsRefetch,
    } = useGetRoofSectionsQuery({
        searchText: '',
        sortKey: 'ROOF_NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 9999,
        includeInactive: true,
        facilityId: dispatch.facilityId,
    });

    const [getPdf] = useLazyGetDispatchPdfQuery();

    const [photos, setPhotos] = useState<Attachment[]>([]);
    const [photoCaptions, setPhotoCaptions] = useState<string[]>([]);

    const [fieldErrors, setFieldErrors] = useState({
        ARRIVAL: '',
        DEPARTURE: '',
        SUMMARY_OF_WORK_PERFORMED: '',
        SCHEDULED_DATE: ''
    });

    const [dispatchPdf, setDispatchPdf] = useState(dispatch?.reportPdf);
    const [createPdf, { data: pdfData, isLoading: isGeneratingPdf }] = useCreateDispatchPdfMutation();

    const generatePdf = useCallback(() => {
        enqueueSnackbar('PDF Requested. Please wait while it is generated.');
        createPdf(dispatch?.id ?? '');
    }, [createPdf, enqueueSnackbar, dispatch?.id]);

    const handleGeneratePdf = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            generatePdf();
        },
        [generatePdf]
    );

    useEffect(() => {
        if (dispatch && !dispatchPdf && !dispatchPdf && dispatch.reportPdfId) {
            getPdf(dispatch.id).then((response) => {
                if (!response.isError) {
                    setDispatchPdf(response.data);
                }
            });
        }
    }, [dispatch, dispatchPdf, getPdf]);

    useSuccessfulActionSnackbar('Generated', 'Report PDF', pdfData?.status === ReportPdfStatus.Created, () => {
        setDispatchPdf(pdfData);
    });
    useFailedActionSnackbar('generate', 'Report PDF', pdfData?.status === ReportPdfStatus.Error, () => {
        setDispatchPdf(pdfData);
    });

    const downloadPdf = () => {
        if (dispatchPdf?.downloadUrl) {
            window.open(dispatchPdf?.downloadUrl);
        }
    };

    useEffect(() => {
        if (!roofLeaks && dispatch) {
            setRoofLeaks(_.orderBy(dispatch.roofLeaks, (x) => x.createdOn) ?? []);
        }
    }, [roofLeaks, dispatch]);

    const validate = useCallback(
        (fieldName: string) => {
            let isValid = false;
            if (fieldName === 'ARRIVAL') {
                if (arrival && (departure ? departure > arrival : false)) {
                    fieldErrors.ARRIVAL = '';
                    isValid = true;
                } else {
                    fieldErrors.ARRIVAL = 'Arrival Date & Time is required and must be before departure';
                    isValid = false;
                }
            } else if (fieldName === 'DEPARTURE') {
                if (departure && (arrival ? departure > arrival : false)) {
                    fieldErrors.DEPARTURE = '';
                    isValid = true;
                } else {
                    fieldErrors.DEPARTURE = 'Departure Date & Time is required and must be after arrival';
                    isValid = false;
                }
            } else if (fieldName === 'SUMMARY_OF_WORK_PERFORMED') {
                if (summaryWorkPerformed && summaryWorkPerformed !== '') {
                    fieldErrors.SUMMARY_OF_WORK_PERFORMED = '';
                    isValid = true;
                } else {
                    fieldErrors.SUMMARY_OF_WORK_PERFORMED = 'Summary of Work Performed is required';
                    isValid = false;
                }
            } else if (fieldName === 'SCHEDULED_DATE') {
                if ((scheduledDate && scheduledDate.toString() !== 'Invalid Date')) {
                    fieldErrors.SCHEDULED_DATE = '';
                    isValid = true;
                } else {
                    fieldErrors.SCHEDULED_DATE = 'Scheduled Date is required to accept work order';
                    isValid = false;
                }
            }
            setFieldErrors({
                ...fieldErrors,
            });
            return isValid;
        },
        [arrival, departure, fieldErrors, scheduledDate, summaryWorkPerformed]
    );

    const onFieldBlur = useCallback(
        (fieldName: string) => () => {
            validate(fieldName);
        },
        [validate]
    );

    const formIsValidForSubmit = useCallback(() => {
        let isValid = validate('ARRIVAL');
        isValid = validate('DEPARTURE') && isValid;
        isValid = validate('SUMMARY_OF_WORK_PERFORMED') && isValid;
        isValid = validate('SCHEDULED_DATE') && isValid;
        return isValid;
    }, [validate]);

    const buildPageTitle = useCallback(() => {
        return `Work Order #${dispatch.woNumber} - ${dispatch.typeName}`;
    }, [dispatch]);

    const pageBreadCrumbs: INavBreadcrumbsProps = {
        links: [{ label: 'Home', navLink: '/' }],
        currentPageLabel: buildPageTitle(),
    };

    const getSaveObject = useCallback(() => {
        return {
            ...dispatch,
            arrivalDateTime: arrival ?? undefined,
            departureDateTime: departure ?? undefined,
            proposalForthcoming: proposalForthcoming,
            summaryWorkPerformed: summaryWorkPerformed,
            technicianName: technicianName,
            roofSections: roofSections,
            scheduledDate: scheduledDate ?? undefined,
            photos: photos.map((attachment, index) => {
                return { ...attachment.photo, caption: photoCaptions[index], order: index };
            }),
            roofLeaks: roofLeaks,
        };
    }, [dispatch, arrival, departure, proposalForthcoming, summaryWorkPerformed, technicianName, roofSections, scheduledDate, photos, roofLeaks, photoCaptions]);

    const handleSave = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            updateDispatch(getSaveObject());
            setIsFormDirty(false);
        },
        [updateDispatch, getSaveObject]
    );

    const closeConfirmCloseOutDialog = useCallback(() => {
        setIsConfirmCloseOutOpen(false);
        setShouldOverwriteCloseOutReportPhotos(false);
    }, []);

    const handleConfirmCloseOut = useCallback(() => {
        closeOutDispatch({ dispatchId: dispatch.id, shouldCopyOverDispatchPhotos: shouldOverwriteCloseOutReportPhotos });
        closeConfirmCloseOutDialog();
    }, [closeOutDispatch, shouldOverwriteCloseOutReportPhotos, closeConfirmCloseOutDialog, dispatch.id]);

    const handleSubmit = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            if (formIsValidForSubmit()) {
                if (dispatch.status === 'Completed') {
                    if (isExistingCloseOutReportWithPhotos) {
                        setIsConfirmCloseOutOpen(true);
                    } else {
                        closeOutDispatch({ dispatchId: dispatch.id, shouldCopyOverDispatchPhotos: true });
                    }
                } else {
                    submitDispatch(getSaveObject());
                }
                setIsFormDirty(false);
            }
        },
        [formIsValidForSubmit, submitDispatch, getSaveObject, dispatch.status, dispatch.id, closeOutDispatch, isExistingCloseOutReportWithPhotos]
    );

    const handleCancel = useCallback(() => {
        if (isContractor) {
            navigate('/');
        } else {
            navigate(`/workorders/edit/${dispatch.workOrderId}`);
        }
    }, [navigate, dispatch, isContractor]);

    const handleRemoveRoofLeak = useCallback(
        (idx: number) => {
            if (roofLeaks) {
                const temp = [...roofLeaks];
                temp.splice(idx, 1);
                setRoofLeaks(temp);
            }
        },
        [roofLeaks]
    );


    const handleAddRoofLeak = useCallback(
        (x: number, y: number, roofSectionId: string) => {
            if (roofLeaks && dispatch.workOrder && roofLeaks.length < 10) {
                setRoofLeaks([
                    ...roofLeaks,
                    {
                        id: emptyGuid,
                        isActive: true,
                        createdOn: new Date(),
                        facilityId: dispatch.workOrder.facilityId,
                        roofProfileId: dispatch.workOrder.roofProfileId,
                        roofSectionId: roofSectionId,
                        roofSection: roofSectionsData?.pageResults.find(x => x.id === roofSectionId),
                        details: '',
                        reporterName: '',
                        x: x,
                        y: y,
                    },
                ]);
                setIsFormDirty(true);
            }
        },
        [roofLeaks, roofSectionsData?.pageResults, dispatch.workOrder]
    );

    const handleOpenRoofLeakModifierDialog = useCallback(
        (event: any, idx: number) => {
            setSelectedRoofLeakAnchorEl(undefined);
            setSelectedRoofLeak(undefined);
            if (event && roofLeaks) {
                setSelectedRoofLeakAnchorEl(event);
                setSelectedRoofLeak(idx);
            }
        },
        [roofLeaks]
    );

    const moveRoofLeak = useCallback((
        (position: Point) => {
            if (!isLocked && roofLeaks && selectedRoofLeak !== undefined) {
                const temp = [
                    ...roofLeaks.map((x) => {
                        return { ...x };
                    }),
                ];

                if (temp[selectedRoofLeak].y === undefined) {
                    temp[selectedRoofLeak].y = 0;
                }
                if (temp[selectedRoofLeak].x === undefined) {
                    temp[selectedRoofLeak].x = 0;
                }

                temp[selectedRoofLeak].x = position.x;
                temp[selectedRoofLeak].y = position.y;

                setRoofLeaks([...temp]);
                setIsFormDirty(true);
            }
        }
    ), [isLocked, roofLeaks, selectedRoofLeak]);

    const changeRoofLeakSection = useCallback((
        (newRoofSectionId: string) => {
            if (!isLocked && roofLeaks && selectedRoofLeak !== undefined) {
                const temp = [
                    ...roofLeaks.map((x) => {
                        return { ...x };
                    }),
                ];


                temp[selectedRoofLeak].roofSectionId = newRoofSectionId;
                if (roofSectionsData) {
                    temp[selectedRoofLeak].roofSection = roofSectionsData?.pageResults.find(x => x.id === newRoofSectionId);
                }

                setRoofLeaks([...temp]);
                setIsFormDirty(true);
            }
        }
    ), [isLocked, roofLeaks, roofSectionsData, selectedRoofLeak]);

    const handleRoofLeakDetailsChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
            if (roofLeaks) {
                const temp = [
                    ...roofLeaks.map((x) => {
                        return { ...x };
                    }),
                ];

                temp[idx].details = event.target.value;

                setRoofLeaks([...temp]);
                setIsFormDirty(true);
            }
        },
        [roofLeaks]
    );

    const handleClearAllRoofLeaks = useCallback(() => {
        setRoofLeaks([]);
        setIsFormDirty(true);
    }, []);

    const isEditDisabled = useMemo(() => {
        if (isLocked) {
            return true;
        }
        var isEmployeeOrAdminEditDisabled = !["Completed", "Needs Attention"].includes(dispatch.status ?? "");
        var isContractorEditDisabled = ["Completed", "Recalled"].includes(dispatch.status ?? "");
        
        if (isEmployee && isContractor) {
            // employee contractors should still be able to edit unless both of these are true;
            return isEmployeeOrAdminEditDisabled && isContractorEditDisabled;
        } else if (isEmployee || isAdmin) {
            return isEmployeeOrAdminEditDisabled;
        } else if (isContractor) {
            return isContractorEditDisabled;
        } else {
            return true;
        }
    }, [dispatch.status, isContractor, isEmployee, isAdmin, isLocked]);

    const canSubmit = useMemo(() => {
        var canContractorSubmit = ["Accepted", "Needs Attention"].includes(dispatch.status ?? "");
        var canEmployeeOrAdminSubmit = ["Completed"].includes(dispatch.status ?? "");

        if (isEmployee && isContractor) {
            // employee contractors should still be able to submit unless both of these are false;
            return canEmployeeOrAdminSubmit || canContractorSubmit;
        } else if (isContractor) {
            return canContractorSubmit;
        } else {
            return canEmployeeOrAdminSubmit;
        }
    }, [isContractor, isEmployee, dispatch.status]);

    if (documentsLoading || roofSectionsLoading) {
        return <LoadingIndicator />;
    }
    if (roofSectionsError) {
        return <ApiError onReloadClick={roofSectionsRefetch} />;
    } else if (documentsError) {
        return <ApiError onReloadClick={documentsRefetch} />;
    }

    return (
        <FormWrapper
            icon={<Assignment />}
            breadCrumbProps={pageBreadCrumbs}
            isFormDirty={isFormDirty}
            name={buildPageTitle()}
            save={handleSave}
            submit={handleSubmit}
            submitButtonText={getSubmitButtonText()}
            cancel={handleCancel}
            disabledSubmit={!canSubmit}
            isLocked={isLocked}
            unlock={isFullEdit ? () => {
                setConfirmUnlockOpen(true);
            } : undefined}
        >
            <Grid item>
                <FormSection>
                    <Grid item container direction='row'>
                        <DispatchInformation
                            dispatch={dispatch}
                            hideWONumber={true}
                            scheduledDate={{
                                scheduledDate: scheduledDate,
                                setScheduledDate: setScheduledDate,
                                validate: validate,
                                setFieldErrors: setFieldErrors,
                                fieldErrors: fieldErrors,
                                disabled: isLocked
                            }}
                        />
                    </Grid>
                    <DispatchNeedsAttentionSection dispatch={dispatch} isLocked={isLocked} />
                </FormSection>
            </Grid >
            <Grid item>
                <DashboardCard headerTitle='Work Completion Detail' headerIcon={<Done />}>
                    <FormSection>
                        <Grid item container direction='row' alignItems='baseline' xs={12} spacing={2} justifyContent='space-evenly'>
                            <Grid item xs={4}>
                                <FormControl fullWidth required error={fieldErrors.ARRIVAL !== ''}>
                                    <DateTimePicker
                                        onBlur={onFieldBlur('ARRIVAL')}
                                        error={fieldErrors.ARRIVAL !== ''}
                                        errorText={fieldErrors.ARRIVAL}
                                        label='Arrival Date & Time'
                                        value={arrival}
                                        required={true}
                                        onChange={(date) => {
                                            setArrival(date);
                                            setIsFormDirty(true);
                                        }}
                                        onAcceptDate={() => {
                                            setFieldErrors({
                                                ...fieldErrors,
                                                ARRIVAL: '',
                                            });
                                        }}
                                        disabled={isEditDisabled}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={4}>
                                <FormControl fullWidth required error={fieldErrors.DEPARTURE !== ''}>
                                    <DateTimePicker
                                        onBlur={onFieldBlur('DEPARTURE')}
                                        error={fieldErrors.DEPARTURE !== ''}
                                        errorText={fieldErrors.DEPARTURE}
                                        label='Departure Date & Time'
                                        value={departure}
                                        required={true}
                                        onChange={(date) => {
                                            setDeparture(date);
                                            setIsFormDirty(true);
                                        }}
                                        onAcceptDate={() => {
                                            setFieldErrors({
                                                ...fieldErrors,
                                                DEPARTURE: '',
                                            });
                                        }}
                                        disabled={isEditDisabled}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>
                        <Grid item container direction='row' alignItems='baseline' xs={12} spacing={2}>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Technician Name'
                                    value={technicianName}
                                    fullWidth
                                    onChange={(e) => {
                                        setTechnicianName(e.target.value);
                                        setIsFormDirty(true);
                                    }}
                                    disabled={isEditDisabled}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={4}>
                                <FormInput
                                    label='Roof Sections'
                                    value={roofSections}
                                    fullWidth
                                    onChange={(e) => {
                                        setRoofSections(e.target.value);
                                        setIsFormDirty(true);
                                    }}
                                    disabled={isEditDisabled}
                                />
                            </Grid>
                            <Grid item container xs={3} alignSelf={'center'} justifyContent={'center'}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={proposalForthcoming}
                                            onChange={() => {
                                                setProposalForthcoming(!proposalForthcoming);
                                                setIsFormDirty(true);
                                            }}
                                        />
                                    }
                                    label='Proposal Forthcoming?'
                                    labelPlacement='start'
                                    disabled={isEditDisabled}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row'>
                            <Grid item xs={12}>
                                <FormInput
                                    label='Summary of Work Performed'
                                    onBlur={onFieldBlur('SUMMARY_OF_WORK_PERFORMED')}
                                    value={summaryWorkPerformed}
                                    fullWidth
                                    required
                                    error={fieldErrors.SUMMARY_OF_WORK_PERFORMED !== ''}
                                    errorText={fieldErrors.SUMMARY_OF_WORK_PERFORMED}
                                    rows={5}
                                    onChange={(e) => {
                                        setSummaryWorkPerformed(e.target.value);
                                        setIsFormDirty(true);
                                    }}
                                    disabled={isEditDisabled}
                                />
                            </Grid>
                        </Grid>
                        {dispatchPdf && !isGeneratingPdf ? (
                            <>
                                <Grid item container direction='row' spacing={1} wrap='nowrap' xs={12}>
                                    <Grid item sx={{ marginTop: '15px' }}>
                                        <PictureAsPdf />
                                    </Grid>
                                    <Grid item sx={{ marginTop: '15px' }}>
                                        <Link onClick={downloadPdf} sx={{ cursor: 'pointer' }}>
                                            {dispatchPdf.displayName}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={handleGeneratePdf} disabled={isEditDisabled}>
                                            <Refresh fontSize='large' />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <></>
                        )}
                    </FormSection>
                </DashboardCard>
            </Grid>
            <Grid item>
                <PhotosFormSection
                    initValues={dispatch}
                    photos={photos}
                    setPhotos={setPhotos}
                    photoCaptions={photoCaptions}
                    setPhotoCaptions={setPhotoCaptions}
                    disabled={isEditDisabled}
                />
            </Grid>
            <AuthenticatedComponent
                requiredPermissions={['read:documentsEmployee', 'read:documentsClient', 'read:documentsAll']}
                logic='or'
                component={
                    <Grid item>
                        <DashboardCard headerTitle='Documents' headerIcon={<Description />}>
                            <DocumentsFormSection
                                workOrderDispatchId={dispatch.id}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                workOrderId={dispatch.workOrder?.id}
                                facilityId={dispatch.facility?.id}
                                setPaginatedProps={setPaginatedProps}
                                isLoading={documentsLoading}
                                roofSectionsData={roofSectionsData!}
                                data={documentsData!}
                                setFormChanged={setIsFormDirty}
                                disabled={isEditDisabled}
                                isHardDeleteMode={true}
                                isSimplifiedColumns={true}
                                isSimplifiedAddEdit={true}
                            />
                        </DashboardCard>
                    </Grid>
                }
            />
            <Grid item>
                {roofLeaks && dispatch.workOrder && (
                    <WorkOrderDispatchRoofLeaksCard
                        roofMap={dispatch.workOrder.roofProfile!.svgContents}
                        roofLeaks={roofLeaks}
                        removeRoofLeak={handleRemoveRoofLeak}
                        addRoofLeak={handleAddRoofLeak}
                        openRoofLeakModifierDialog={handleOpenRoofLeakModifierDialog}
                        roofLeakDetailsChanged={handleRoofLeakDetailsChanged}
                        clearRoofLeaks={handleClearAllRoofLeaks}
                        disabled={isEditDisabled}
                    />
                )}
            </Grid>
            {!isLocked && selectedRoofLeak !== undefined && roofLeaks && <RoofLeakDetailsDialog
                open={selectedRoofLeak !== undefined}
                onClose={() => {
                    setSelectedRoofLeakAnchorEl(undefined);
                    setSelectedRoofLeak(undefined);
                }}
                onConfirm={(roofSectionId?: string) => {
                    setSelectedRoofLeakAnchorEl(undefined);
                    setSelectedRoofLeak(undefined);
                    if (roofSectionId) {
                        changeRoofLeakSection(roofSectionId);
                    }
                }}
                handleRoofLeakMove={moveRoofLeak}
                roofLeak={roofLeaks[selectedRoofLeak]}
                idx={selectedRoofLeak}
                anchorEl={selectedRoofLeakAnchorEl}
                roofSections={roofSectionsData ? roofSectionsData.pageResults : []}
            />}
            <ConfirmDialog
                open={confirmUnlockOpen}
                onClose={() => setConfirmUnlockOpen(false)}
                content={<Typography>Do you want to unlock this dispatch?</Typography>}
                titleIcon={<LockOpen />}
                title={<Typography variant='h3'>Unlock Dispatch?</Typography>}
                cancelText='No'
                onConfirm={() => handleUnlock(dispatch!.id)}
            />
            <ConfirmDialog
                open={isConfirmCloseOutOpen}
                onClose={closeConfirmCloseOutDialog}
                content={
                    <FormControlLabel
                        control={<Switch checked={shouldOverwriteCloseOutReportPhotos} onChange={(_, isChecked) => setShouldOverwriteCloseOutReportPhotos(isChecked)} />}
                        label={`Overwrite the Close Out Report's photos?`}
                        labelPlacement='start'
                    />
                }
                titleIcon={<Warning />}
                title={<Typography variant='h3'>Confirm Close Out</Typography>}
                onConfirm={handleConfirmCloseOut}
            />
        </FormWrapper >
    );
};
