/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormatListBulleted, OpenInFull } from '@mui/icons-material';
import {
    Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText,
    FormLabel, Grid, IconButton, Switch, TextField, Typography
} from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { emptyGuid, ImmediateRepairsChecklistItemDto, RoofSectionDto } from '../../../models';
import {
    useCreateImmediateRepairsChecklistItemMutation,
    useUpdateImmediateRepairsChecklistItemMutation
} from '../../../store/apis/immediate-repairs-checklist-item-api';
import { useGetRoofSectionsQuery } from '../../../store/apis/roof-section-api';
import { useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import LoadingIndicator from '../../core/LoadingIndicator';
import { SelectRoofSectionsDialog } from '../SelectRoofSection';
import { FormInput } from '../FormFields';
import { StandardDialogHeader } from '../StandardDialogHeader';

export interface IAddEditImmediateRepairsChecklistItemDialogProps {
    open: boolean;
    onClose: any;
    initValues?: ImmediateRepairsChecklistItemDto;
    reportId: string;
    disabled?: boolean;
    facilityId?: string;
    roofProfileId?: string;
    defaultRoofNumber?: string;
}

export const AddEditImmediateRepairsChecklistItemDialog: FC<IAddEditImmediateRepairsChecklistItemDialogProps> = ({ initValues, open, onClose, reportId, disabled, facilityId, roofProfileId, defaultRoofNumber }) => {
    const [isSelectRoofSectionsDialogOpen, setIsSelectRoofSectionsDialogOpen] = useState(false);
    const [checklistItem, setChecklistItem] = useState<string>(initValues?.checklistItem ? initValues.checklistItem : '');
    const [changed, setFormChanged] = useState(false);
    const [isGeneralNote, setIsGeneralNote] = useState(initValues?.isGeneralNote ? initValues.isGeneralNote : false);
    const [addNewAfterSave, setAddNewAfterSave] = useState(false);
    const [roofNumber, setRoofNumber] = useState<string>(
        initValues?.roofSection ? initValues.roofSection.roofNumber : defaultRoofNumber ?? ''
    );

    const {
        data: roofSectionsData,
        isLoading: roofSectionsLoading,
        error: roofSectionsError,
        refetch: roofSectionsRefetch,
    } = useGetRoofSectionsQuery({
        searchText: roofNumber,
        sortKey: 'ROOF_NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 250,
        includeInactive: true,
        facilityId: facilityId,
        roofProfileId: roofProfileId,
    });

    const [
        createImmediateRepairsChecklistItem,
        {
            isSuccess: isChecklistCreateSuccess,
            isError: isChecklistCreateError,
            reset: resetCreateImmediateRepairsChecklistItem,
        },
    ] = useCreateImmediateRepairsChecklistItemMutation();
    const [
        updateImmediateRepairsChecklistItem,
        {
            isSuccess: isChecklistUpdateSuccess,
            isError: isChecklistUpdateError,
            reset: resetUpdateImmediateRepairsChecklistItem,
        },
    ] = useUpdateImmediateRepairsChecklistItemMutation();

    const currentRoofSection = useMemo(() => {
        const roofSections = roofSectionsData?.pageResults.filter((x) => x.roofNumber === roofNumber);
        // If there are multiple roof sections with the same roof number, return the active one
        if (roofSections && roofSections.length > 1) {
            return roofSections.find((x) => x.isActive);
        }
        return roofSectionsData?.pageResults.find((x) => x.roofNumber === roofNumber);
    }, [roofSectionsData, roofNumber]);

    const [fieldErrors, setFieldErrors] = useState({
        ROOF_NUMBER: '',
        CHECKLIST_ITEM: '',
    });

    const beforeClose = useCallback(
        (shouldAddNewAfterSave: boolean) => {
            setRoofNumber('');
            setChecklistItem('');
            setIsGeneralNote(false);
            setFormChanged(false);
            resetCreateImmediateRepairsChecklistItem();
            resetUpdateImmediateRepairsChecklistItem();
            onClose(shouldAddNewAfterSave);
        },
        [onClose, resetCreateImmediateRepairsChecklistItem, resetUpdateImmediateRepairsChecklistItem]
    );

    const validate = useCallback((fieldName: string) => {
        let isValid = false;
        if (fieldName === 'ROOF_NUMBER') {
            if (roofNumber || isGeneralNote) {
                fieldErrors.ROOF_NUMBER = '';
                isValid = true;
                if (roofNumber && roofNumber.length > 4) {
                    fieldErrors.ROOF_NUMBER = 'Roof # must be 4 or less characters';
                    isValid = false;
                } else if (roofNumber && !currentRoofSection) {
                    fieldErrors.ROOF_NUMBER = 'Roof # not found';
                    isValid = false;
                }
            } else {
                fieldErrors.ROOF_NUMBER = 'Roof # is required';
                isValid = false;
            }
        } else if (fieldName === 'CHECKLIST_ITEM') {
            if (checklistItem) {
                fieldErrors.CHECKLIST_ITEM = '';
                isValid = true;
            } else {
                fieldErrors.CHECKLIST_ITEM = 'Checklist item is required';
                isValid = false;
            }
        }
        setFieldErrors({
            ROOF_NUMBER: fieldErrors.ROOF_NUMBER,
            CHECKLIST_ITEM: fieldErrors.CHECKLIST_ITEM,
        });
        return isValid;
    }, [checklistItem, fieldErrors, isGeneralNote, roofNumber, currentRoofSection]);

    const formIsValid = useCallback(() => {
        let isRoofNumberValid = validate('ROOF_NUMBER');
        let iChecklistItemValid = validate('CHECKLIST_ITEM');

        if (isRoofNumberValid && iChecklistItemValid) {
            return true;
        }
    }, [validate]);

    const handleSave = useCallback(async () => {
        if (formIsValid()) {
            const formValues = {
                id: initValues?.id ?? emptyGuid,
                isActive: true,
                reportId: reportId,
                isGeneralNote: isGeneralNote,
                checklistItem: checklistItem,
                roofSectionId: isGeneralNote ? undefined : currentRoofSection?.id,
            };

            if (initValues?.id) {
                await updateImmediateRepairsChecklistItem(formValues);
            } else {
                await createImmediateRepairsChecklistItem(formValues);
            }
            setFormChanged(false);
        }
    }, [checklistItem, createImmediateRepairsChecklistItem, currentRoofSection?.id, formIsValid, initValues?.id, isGeneralNote, reportId, updateImmediateRepairsChecklistItem]);

    useFailedCreateSnackbar('checklist item', isChecklistCreateError, resetCreateImmediateRepairsChecklistItem);
    useFailedUpdateSnackbar('checklist item', isChecklistUpdateError, resetUpdateImmediateRepairsChecklistItem);
    useSuccessfulCreateSnackbar('Checklist item', isChecklistCreateSuccess, () => {
        if (open) {
            beforeClose(addNewAfterSave);
        }
    });

    useSuccessfulUpdateSnackbar('Checklist item', isChecklistUpdateSuccess, () => {
        if (open) {
            beforeClose(addNewAfterSave);
        }
    });

    const onIsGeneralNoteChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFormChanged(true);
        if (checked) {
            setRoofNumber('');
        }
        setIsGeneralNote(checked);
    }, []);

    const onAddNewAfterSaveChange = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setAddNewAfterSave(checked);
    }, []);

    const onFieldBlur = useCallback((fieldName: string) => () => {
        validate(fieldName);
    }, [validate]);

    const handleRoofNumberChange = useCallback((value: RoofSectionDto | null | undefined | string) => {
        if (value === null || value === undefined) {
            setRoofNumber('');
            return;
        }
        if (_.isString(value)) {
            setRoofNumber(value as string);
        }
        setFieldErrors({ ...fieldErrors });
        onFieldBlur('ROOF_NUMBER');
        setFormChanged(true);
    }, [fieldErrors, onFieldBlur]);

    const handleChecklistItemChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setChecklistItem(event.target.value);
    }, []);

    const handleSelectRoofSectionsDialogClose = useCallback(() => {
        setIsSelectRoofSectionsDialogOpen(false);
    }, []);

    const handleSelectRoofSectionsDialogConfirm = useCallback((sectionSelected: RoofSectionDto) => {
        setRoofNumber(sectionSelected.roofNumber);
        setIsSelectRoofSectionsDialogOpen(false);
        setFormChanged(true);
    }, []);

    if (roofSectionsLoading) {
        return <LoadingIndicator />;
    }

    if (roofSectionsError) {
        return <ApiError onReloadClick={roofSectionsRefetch} />;
    }

    return (
        <Dialog maxWidth='xl' fullWidth open={open}>
            <StandardDialogHeader icon={<FormatListBulleted sx={{ color: '#ffffff' }} />} title={`${initValues?.id ? 'Edit' : 'New'} Immediate Repairs Checklist Item`}  />
            <DialogContent>
                <Grid component='form' container direction='column' mt='16px' spacing={3} autoComplete='off' onSubmit={handleSave}>
                    <Grid item container direction='row' spacing={3} xs={12} alignItems='center'>
                        <Grid item xs={2}>
                            <FormControl error={!isGeneralNote && fieldErrors.ROOF_NUMBER !== ''} fullWidth required={!isGeneralNote}>
                                <FormLabel sx={{ fontSize: '12px' }}>Roof #</FormLabel>
                                <Grid item container alignItems={'center'} direction='row'>
                                    <Grid item xs={10}>
                                        <Autocomplete
                                            value={roofNumber ?? null}
                                            onChange={(event, value, reason, details) => {
                                                if (typeof value === 'string') {
                                                    setTimeout(() => {
                                                        setRoofNumber(value.substring(0, 4));
                                                    });
                                                }
                                            }}
                                            options={
                                                roofSectionsData?.pageResults
                                                    .filter((option) => option.isActive || option.roofNumber === roofNumber)
                                                    .map((x) => `${x.roofNumber}${x.isActive ? '' : ' (Inactive)'}`) ?? []
                                            }
                                            getOptionLabel={(option: string) => {
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                return option;
                                            }}
                                            disabled={disabled || isGeneralNote}
                                            selectOnFocus
                                            handleHomeEndKeys
                                            renderOption={(props, option) => <li {...props}>{option}</li>}
                                            freeSolo
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onBlur={onFieldBlur('ROOF_NUMBER')}
                                                    inputProps={{ ...params.inputProps, maxLength: 4 }}
                                                    error={!isGeneralNote && fieldErrors.ROOF_NUMBER !== ''}
                                                    onChange={(event) => handleRoofNumberChange(event.target.value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton onClick={() => setIsSelectRoofSectionsDialogOpen(true)} disabled={disabled || isGeneralNote}>
                                            <OpenInFull sx={{ fontSize: 36 }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <FormHelperText>{fieldErrors.ROOF_NUMBER}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControlLabel
                                control={<Checkbox checked={isGeneralNote} onChange={onIsGeneralNoteChange} disabled={disabled} />}
                                label='General Note (appears at end of checklist)'
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormInput
                            label='Checklist Item'
                            onBlur={onFieldBlur('CHECKLIST_ITEM')}
                            value={checklistItem}
                            fullWidth
                            required
                            error={fieldErrors.CHECKLIST_ITEM !== ''}
                            errorText={fieldErrors.CHECKLIST_ITEM}
                            onChange={handleChecklistItemChanged}
                            rows={4}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={addNewAfterSave} onChange={onAddNewAfterSaveChange} />}
                            label='Add new checklist item after save'
                        />
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            {changed || !initValues ? (
                                <Button
                                    variant='outlined'
                                    style={{ boxShadow: 'none' }}
                                    onClick={() => {
                                        beforeClose(false);
                                    }}>
                                    Cancel
                                </Button>
                            ) : (
                                <Button
                                    variant='outlined'
                                    style={{ boxShadow: 'none' }}
                                    onClick={() => {
                                        beforeClose(false);
                                    }}>
                                    Close
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={handleSave}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
            <SelectRoofSectionsDialog
                open={isSelectRoofSectionsDialogOpen}
                onCancel={handleSelectRoofSectionsDialogClose}
                onConfirm={handleSelectRoofSectionsDialogConfirm}
                currentRoofSections={currentRoofSection ? [currentRoofSection] : []}
                isMultipleSelectable={false}
                facilityId={facilityId}
                roofProfileId={roofProfileId}
            />
        </Dialog>
    );
};
