import { ArchiveMGOrRSReportDispatchChecklistItemParameters, CreateMGOrRSReportDispatchChecklistItemParameters, GetMGOrRSReportSectionQueryParameters as GetMGOrRSReportDispatchSectionQueryParameters, GetMGOrRSReportImmediateRepairQueryParameters, ReportDispatchImmediateRepairSearchQueryParams, ReportDispatchRoofSectionReportSearchQueryParams, ReportDispatchRoofSectionSearchQueryParams, UnarchiveMGOrRSReportDispatchChecklistItemParameters, UpdateMGOrRSReportDispatchChecklistItemParameters, UpdateMGOrRSReportSectionParameters, serverApi } from '..';
import { ImmediateRepairsChecklistItemDto, QueryResultsDto, ReportDto, RoofSectionDto, RoofSectionReportDto, UpdateMGOrRSReportDispatchRequest } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const reportDispatchApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getMGOrRSReportDispatchRoofSections: builder.query<QueryResultsDto<RoofSectionDto>, ReportDispatchRoofSectionSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`reportDispatch/${params.dispatchId}/roofSections`, params),
            providesTags: [{ type: 'Roof Section' }],
        }),
        getMGOrRSReportDispatchRoofSectionReports: builder.query<QueryResultsDto<RoofSectionReportDto>, ReportDispatchRoofSectionReportSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`reportDispatch/${params.dispatchId}/roofSectionReports`, params),
            providesTags: [{ type: 'Roof Section Report' }],
        }),
        getMGOrRSReportDispatchRoofSectionReport: builder.query<RoofSectionReportDto, GetMGOrRSReportDispatchSectionQueryParameters>({
            query: (params) => ({ url: `reportDispatch/${params.dispatchId}/roofSectionReports/${params.reportRoofSectionId}` }),
            providesTags: [{ type: 'Roof Section Report' }],
        }),
        updateMGOrRSReportDispatch: builder.mutation<void, UpdateMGOrRSReportDispatchRequest>({
            query: (dispatch) => ({
                url: 'reportDispatch',
                method: 'PUT',
                body: dispatch,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Roof Section Report' }],
        }),
        completeMGOrRSReportDispatch: builder.mutation<void, UpdateMGOrRSReportDispatchRequest>({
            query: (dispatch) => ({
                url: `reportDispatch/complete`,
                method: 'PUT',
                body: dispatch,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Roof Section Report' }, { type: 'Work Order Status History' }],
        }),
        updateMGOrRSReportDispatchRoofSectionReport: builder.mutation<void, UpdateMGOrRSReportSectionParameters>({
            query: (args) => ({
                url: `reportDispatch/${args.dispatchId}/roofSectionReports/${args.reportRoofSectionId}`,
                method: 'PATCH',
                body: args.roofSectionReport,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Roof Section Report' }],
        }),
        getMGOrRSReportDispatchImmediateRepairs: builder.query<QueryResultsDto<ImmediateRepairsChecklistItemDto>, ReportDispatchImmediateRepairSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`reportDispatch/${params.dispatchId}/immediateRepairs`, params),
            providesTags: [{ type: 'Immediate Repairs Checklist Item' }],
        }),
        getMGOrRSReportDispatchImmediateRepair: builder.query<ImmediateRepairsChecklistItemDto, GetMGOrRSReportImmediateRepairQueryParameters>({
            query: (params) => `reportDispatch/${params.dispatchId}/immediateRepairs/${params.immediateRepairId}`,
            providesTags: [{ type: 'Immediate Repairs Checklist Item' }],
        }),
        createMGOrRSReportDispatchImmediateRepair: builder.mutation<ImmediateRepairsChecklistItemDto, CreateMGOrRSReportDispatchChecklistItemParameters>({
            query: (params) => ({
                url: `reportDispatch/${params.dispatchId}/immediateRepairs`,
                method: 'POST',
                body: params.immediateRepair,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Immediate Repairs Checklist Item' }],
        }),
        updateMGOrRSReportDispatchImmediateRepair: builder.mutation<void, UpdateMGOrRSReportDispatchChecklistItemParameters>({
            query: (params) => ({
                url: `reportDispatch/${params.dispatchId}/immediateRepairs/${params.immediateRepair.id}`,
                method: 'PUT',
                body: params.immediateRepair,
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Immediate Repairs Checklist Item' }],
        }),
        archiveMGOrRSReportDispatchImmediateRepair: builder.mutation<void, ArchiveMGOrRSReportDispatchChecklistItemParameters>({
            query: (params) => ({
                url: `reportDispatch/${params.dispatchId}/immediateRepairs/${params.immediateRepairId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Immediate Repairs Checklist Item' }],
        }),
        unarchiveMGOrRSReportDispatchImmediateRepair: builder.mutation<void, UnarchiveMGOrRSReportDispatchChecklistItemParameters>({
            query: (params) => ({
                url: `reportDispatch/${params.dispatchId}/immediateRepairs/${params.immediateRepairId}`,
                method: 'PATCH'
            }),
            invalidatesTags: [{ type: 'Dispatch' }, { type: 'Immediate Repairs Checklist Item' }],
        }),
        getReportForMGOrRSReportDispatch: builder.query<ReportDto, string>({
            query: (id) => ({ url: `reportDispatch/${id}/report` }),
            providesTags: [{ type: 'Dispatch' }, { type: 'Report' }],
        }),
    }),
});

export const {
    useGetMGOrRSReportDispatchRoofSectionsQuery,
    useGetMGOrRSReportDispatchRoofSectionReportsQuery,
    useGetMGOrRSReportDispatchRoofSectionReportQuery,
    useUpdateMGOrRSReportDispatchMutation,
    useCompleteMGOrRSReportDispatchMutation,
    useUpdateMGOrRSReportDispatchRoofSectionReportMutation,
    useGetMGOrRSReportDispatchImmediateRepairsQuery,
    useGetMGOrRSReportDispatchImmediateRepairQuery,
    useCreateMGOrRSReportDispatchImmediateRepairMutation,
    useUpdateMGOrRSReportDispatchImmediateRepairMutation,
    useArchiveMGOrRSReportDispatchImmediateRepairMutation,
    useUnarchiveMGOrRSReportDispatchImmediateRepairMutation,
    useGetReportForMGOrRSReportDispatchQuery
} = reportDispatchApi;
