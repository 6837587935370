import { AddCircle, Delete, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { FunctionComponent, useCallback, useState } from 'react';
import AuthenticatedComponent from '../../../auth/AuthenticatedComponent';
import { NoteDto } from '../../../models';
import { useArchiveNoteMutation, useGetWorkOrderNotesQuery, useUnarchiveNoteMutation } from '../../../store/apis/note-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { AddNotesDialog } from './AddNotesDialog';
import { IWorkOrderSectionProps } from './types';

export const WorkOrderNotes: FunctionComponent<IWorkOrderSectionProps> = React.memo((props) => {
    const { workOrderId } = props;
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [addNotesDialogOpen, setAddNotesDialogOpen] = useState(false);
    const { data, isLoading, error, refetch } = useGetWorkOrderNotesQuery({
        parentId: workOrderId,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
        },
    });
    const [archiveNote, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveNoteMutation();
    const [unarchiveNote, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveNoteMutation();
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [selectedNote, setSelectedNote] = useState<NoteDto | undefined | null>();
    const tableColumns: DataTableColumn<NoteDto>[] = [
        {
            key: 'text',
            label: 'Note',
            sortKey: '',
            fieldMapper: (row) => (
                <Typography>
                    {row.text.slice(0, 125)}
                    {row.text.length > 125 ? '...' : ''}
                </Typography>
            ),
        },
        {
            key: 'createdOn',
            label: 'Created On',
            sortKey: 'CREATED_ON',
            fieldMapper: (row) => (row.createdOn ? format(new Date(row.createdOn.toString()), 'M/d/yyyy') : ''),
            width:'100px'
        },
    ];
    const [dialogInstanceId, setDialogInstanceId] = useState(1);

    const getEntityName = () => {
        return selected.length === 1 ? 'Note' : 'Notes';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => {handleActionRequestCompletion(resetArchive);});
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => {handleActionRequestCompletion(resetUnarchive);});
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => {handleActionRequestCompletion(resetArchive);});
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => {handleActionRequestCompletion(resetUnarchive);});

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };
    const handleAddNotesClose = useCallback(() => {
        setAddNotesDialogOpen(false);
        setSelectedNote(null);
        setDialogInstanceId(dialogInstanceId + 1);
    }, [dialogInstanceId]);

    const handleCreateNew = useCallback(() => {
        setSelectedNote(null);
        setDialogInstanceId(dialogInstanceId + 1);
        setAddNotesDialogOpen(true);
    }, [dialogInstanceId]);

    const handleMenuEdit = useCallback(() => {
        setSelectedNote(data?.pageResults[selected[0]]);
        setAddNotesDialogOpen(true);
    }, [data?.pageResults, selected]);

    const handleRowEdit = useCallback((id: string) => {
        setSelectedNote(data?.pageResults.find((x) => x.id === id));
        setAddNotesDialogOpen(true);
    }, [data?.pageResults]);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveNote({
                parentId: workOrderId,
                childId: data?.pageResults[index].id!,
            });
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [data?.pageResults, selected, unarchiveNote, workOrderId]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveNote({
                parentId: workOrderId,
                childId: data?.pageResults[index].id!,
            });
        });
        setIsUpdatingStatus(false);
        setSelected([]);
    }, [archiveNote, data?.pageResults, selected, workOrderId]);

    const canSetActive = useCallback(() => {
        return selected.some((index) => !data?.pageResults[index]?.isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useCallback(() => {
        return selected.some((index) => data?.pageResults[index]?.isActive);
    }, [data?.pageResults, selected]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }
    return (
        <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
            <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                <Grid item container direction='row' alignItems='center' xs={6}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                            checked={showInactive}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title='Make Inactive'>
                            <span>
                                <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive()}>
                                    <Delete fontSize='inherit' />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton color='primary' size='large' onClick={handleCreateNew}>
                            <AddCircle fontSize='inherit' />
                        </IconButton>
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['create:notes', 'edit:notes', 'delete:notes']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:notes']}
                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:notes']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:notes']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        } />
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='CREATED_ON'
                    defaultSortDesc
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    onEdit={handleRowEdit}
                />
            </Grid>
            <AddNotesDialog
                key={dialogInstanceId}
                open={addNotesDialogOpen}
                onClose={handleAddNotesClose}
                workOrderId={workOrderId}
                initValues={selectedNote}
            />
        </Grid>
    );
});
