import { useGetDispatchQuery } from "../../store/apis/dispatch-api";
import { useFailedActionSnackbar } from "../../util";

export function useSubmitDispatchActions(workOrderDispatchId: string) {
    const { data: loadedDispatch, isLoading: isLoadingWorkOrderDispatch, isError: isGetWorkOrderDispatchError, refetch } =
        useGetDispatchQuery(workOrderDispatchId);

    useFailedActionSnackbar('retrieving', 'work order dispatch', isGetWorkOrderDispatchError);

    return {
        loadedDispatch,
        isLoadingWorkOrderDispatch,
        isGetWorkOrderDispatchError,
        refetch
    };
}