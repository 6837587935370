import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { RoofSectionDto } from "../../../models";
import { useGetRoofSectionsQuery } from "../../../store/apis/roof-section-api";
import { InteriorDrawerComponent } from "../../core/InteriorDrawerComponent";
import { RoofConditionIndexTable } from "./RoofConditionIndexTable";
import { IRoofMapDrawerProps } from "./types";

export const RoofMapDrawer: React.FunctionComponent<IRoofMapDrawerProps> = React.memo((props) => {
    const { facilityId, roofProfileId } = props;
    const {
        data: roofSections,
    } = useGetRoofSectionsQuery({
        facilityId: facilityId,
        roofProfileId: roofProfileId,
        searchText: undefined,
        sortKey: 'ROOF_NUMBER',
        page: 0,
        pageSize: 1000,
        includeInactive: false,
    });

    return (
        
        <InteriorDrawerComponent
            title='Legend'
            drawerContents={
                <Grid item container direction='column' spacing={2}>
                    <Grid item>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Roof Condition Index:</Typography>
                    </Grid>
                    <Grid item>
                        <RoofConditionIndexTable />
                    </Grid>
                    <Grid item>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Roof Area</Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer style={{ maxHeight: '500px' }}>
                            <Table size='small' sx={{ border: '2px solid black' }} stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: '#266490', color: 'white', fontWeight: 'bold' }}>
                                            Roof #
                                        </TableCell>
                                        <TableCell align='right' sx={{ backgroundColor: '#266490', color: 'white', fontWeight: 'bold' }}>
                                            Sq. Ft.
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {roofSections ? roofSections?.pageResults.map((section: RoofSectionDto) => (
                                        <TableRow key={section.id}>
                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        whiteSpace: 'normal',
                                                        overflow: 'hidden',
                                                        textOverflow: 'clip',
                                                    }}>
                                                    {section.roofNumber}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <Typography
                                                    sx={{
                                                        whiteSpace: 'normal',
                                                        overflow: 'hidden',
                                                        textOverflow: 'clip',
                                                    }}>
                                                    {section.squareFootage?.toLocaleString("en-US") ?? 0}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                    )) : <></>}
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    overflow: 'hidden',
                                                    textOverflow: 'clip',
                                                    fontWeight: 'bold'
                                                }}>
                                                TOTAL
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Typography
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    overflow: 'hidden',
                                                    textOverflow: 'clip',
                                                    fontWeight: 'bold'
                                                }}>
                                                {roofSections ? _.sumBy(roofSections?.pageResults, x => x.squareFootage).toLocaleString("en-US") : 0}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            }>
            {props.children}
        </InteriorDrawerComponent>
        

    );
});