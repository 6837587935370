import { Grid, Box, Typography } from '@mui/material';
import { RoofConditionDto } from '../models';
import { FunctionComponent, useMemo } from 'react';

export interface IColorSwatchProps {
    condition?: RoofConditionDto;
    fontSize?: number | string;
}
export const ColorSwatch: FunctionComponent<IColorSwatchProps> = (props) => {
    const { condition, fontSize } = props;
    const swatch = useMemo(() => {
        if (condition) {
            return (
                <Grid container direction='row' alignItems='center' spacing={1} justifyItems={'flex-start'} wrap='nowrap'>
                    <Grid item>
                        <Box sx={{ display: 'flex', width: '16px', height: '16px', backgroundColor: condition.colorCode }} />
                    </Grid>
                    <Grid item>
                        <Typography
                            fontSize={fontSize ?? 16}
                            sx={{
                                whiteSpace: 'normal',
                                overflow: 'hidden',
                                textOverflow: 'clip',
                            }}>
                            {condition.rating}{condition.isActive ? '' : ' (Inactive)'}
                        </Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return <></>;
        }
    }, [condition, fontSize]);

    return <>{swatch}</>;
};
