import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { FacilityForm } from '../../Components/Forms/FacilityForm';
import { FacilityDto } from '../../models';
import { useGetClientQuery } from '../../store/apis/client-api';
import { useCreateFacilityMutation } from '../../store/apis/facility-api';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../util/customHooks';

export const AddFacilityView: FunctionComponent = () => {
    const { clientId } = useParams();
    const navigate = useNavigate();
    const { data: client, error: clientError, isLoading: clientLoading, refetch: clientRefetch } = useGetClientQuery(clientId!);
    const [createFacility, { data, isError: isCreateError, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateFacilityMutation();

    useFailedCreateSnackbar('facility', isCreateError, resetCreate);
    useSuccessfulCreateSnackbar('Facility', isCreateSuccess, () => {
        navigate(`/clients/${clientId}/facilities/edit/${data?.id}`, { replace: true });
    });

    const handleSave = (values: FacilityDto) => {
        createFacility(values);
    };

    const handleCancel = () => {
        navigate(`/clients/edit/${clientId}`);
    };

    if (clientLoading) {
        return <LoadingIndicator />;
    }

    if (clientError) {
        return <ApiError onReloadClick={clientRefetch} />;
    }

    return <FacilityForm client={client!} save={handleSave} cancel={handleCancel} />;
};
