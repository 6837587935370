import { serverApi } from '..';
import { CloudStorageLink } from '../../models';

const photoApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getPhotoFileLink: builder.query<CloudStorageLink, string>({
            query: (id) => ({ url: `photo/${id}/file` })
        }),
    })
});

export const {
    useGetPhotoFileLinkQuery,
    useLazyGetPhotoFileLinkQuery,
} = photoApi;