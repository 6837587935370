import { IRoute } from './types';
import { BudgetsListView, ClientsListView, DashboardView, DefaultDocumentFoldersListView, DocumentFoldersListView, WorkOrdersListView } from '../Views';
import { AddClientView, ClientDashboard, EditClientView } from '../Views/Client';
import { AddFacilityView, EditFacilityView } from '../Views/Facility';
import { AddWorkOrderView, EditWorkOrderView } from '../Views/WorkOrder';
import { AddBudgetView, EditBudgetView } from '../Views/Budgets';
import { CapitalProjectsListView } from '../Views/CapitalProjectsListView';
import { AddCapitalProjectView } from '../Views/CapitalProject/AddCaptialProjectView';
import { EditCapitalProjectView } from '../Views/CapitalProject/EditCapitalProjectView';
import { AddWarrantyView, EditWarrantyView } from '../Views/Warranty';
import { AddRoofProfileView, EditRoofProfileView } from '../Views/RoofProfile';
import { RoofSectionDashboard } from '../Views/RoofSection';
import { RoofLeakView } from '../Views/RoofLeak/RoofLeakView';
import { UsersListView } from '../Views/UserListView';
import { AddUserView } from '../Views/User/AddUserView';
import { EditUserView } from '../Views/User/EditUserView';
import { ContractorsListView } from '../Views/ContractorsListView';
import { AddContractorView, EditContractorView } from '../Views/Contractor';
import { AddContactView } from '../Views/Contact/AddContactView';
import { EditContactView } from '../Views/Contact/EditContactView';
import { ContractorDashboard } from '../Views/Contractor/ContractorDashboard';
import { ClientReportsListView } from '../Views/ClientReportsListView';
import { AddClientReportView, EditClientReportView } from '../Views/ClientReport';
import { AddRoofSectionReportView, DispatchAddRoofSectionReportView, EditRoofSectionReportView } from '../Views/RoofSectionReport';
import { AddJsiReportView, EditJsiReportView } from '../Views/JsiReport';
import { ContractorHomeDashboard } from '../Views/Contractor/ContractorHomeDashboard';
import { AcceptDispatchView, SubmitDispatchView } from '../Views/Dispatch';
import { AddBinderPortfolioReportView, EditBinderPortfolioReportView } from '../Views/BinderPortfolioReport';
import { AddCloseOutReportView, EditCloseOutReportView } from '../Views/CloseOutReport';
import { DownloadsListView } from '../Views/Downloads';
import { ContractorEditRoofSectionView } from '../Views/RoofSectionReport/ContractorEditRoofSectionView';

export const routes: IRoute[] = [
    {
        component: <DashboardView />,
        path: '/',
        requiredPermissions: [],
    },
    {
        component: <BudgetsListView />,
        path: '/budgets',
        requiredPermissions: ['read:budgets'],
    },
    {
        component: <AddBudgetView />,
        path: '/budgets/create',
        requiredPermissions: ['create:budgets'],
    },
    {
        component: <EditBudgetView />,
        path: '/budgets/edit/:id',
        requiredPermissions: ['edit:budgets'],
    },
    {
        component: <CapitalProjectsListView />,
        path: '/capitalprojects',
        requiredPermissions: ['read:workorders'],
    },
    {
        component: <AddCapitalProjectView />,
        path: '/capitalprojects/create',
        requiredPermissions: ['create:workorders'],
    },
    {
        component: <AddCapitalProjectView />,
        path: '/capitalprojects/create/client/:clientId',
        requiredPermissions: ['create:workorders'],
    },
    {
        component: <AddCapitalProjectView />,
        path: '/capitalprojects/create/facility/:facilityId',
        requiredPermissions: ['create:workorders'],
    },
    {
        component: <EditCapitalProjectView />,
        path: '/capitalprojects/edit/:id',
        requiredPermissions: ['edit:workorders'],
    },
    {
        component: <EditCapitalProjectView />,
        path: '/capitalprojects/edit/:id/client/:clientId',
        requiredPermissions: ['edit:workorders'],
    },
    {
        component: <EditCapitalProjectView />,
        path: '/capitalprojects/edit/:id/facility/:facilityId',
        requiredPermissions: ['edit:workorders'],
    },
    {
        component: <ClientsListView />,
        path: '/clients',
        requiredPermissions: ['nav:clients', 'read:clients'],
    },
    {
        component: <ClientDashboard />,
        path: '/clients/:id',
        requiredPermissions: ['read:clients'],
    },
    {
        component: <ClientDashboard />,
        path: '/clients/:id/facility/:facilityId',
        requiredPermissions: ['read:clients', 'read:facilities'],
    },
    {
        component: <AddClientView />,
        path: '/clients/create',
        requiredPermissions: ['create:clients'],
    },
    {
        component: <EditClientView />,
        path: '/clients/edit/:id',
        requiredPermissions: ['edit:clients'],
    },
    {
        component: <AddFacilityView />,
        path: '/clients/:clientId/facilities/create',
        requiredPermissions: ['create:facilities'],
    },
    {
        component: <EditFacilityView />,
        path: '/clients/:clientId/facilities/edit/:id',
        requiredPermissions: ['edit:facilities'],
    },
    {
        component: <AddRoofProfileView />,
        path: '/clients/:clientId/facilities/:facilityId/roofprofiles/create',
        requiredPermissions: ['create:roofProfiles'],
    },
    {
        component: <EditRoofProfileView />,
        path: '/clients/:clientId/facilities/:facilityId/roofprofiles/edit/:id',
        requiredPermissions: ['edit:roofProfiles'],
    },
    {
        component: <RoofSectionDashboard />,
        path: 'clients/:clientId/facility/:facilityId/roofsection/:id',
        requiredPermissions: ['read:clients', 'read:facilities', 'read:roofSections'],
    },
    {
        component: <WorkOrdersListView />,
        path: '/workorders',
        requiredPermissions: ['nav:workorders', 'read:workorders'],
    },
    {
        component: <AddWorkOrderView />,
        path: '/workorders/create',
        requiredPermissions: ['create:workorders'],
    },
    {
        component: <AddWorkOrderView />,
        path: '/workorders/create/client/:clientId',
        requiredPermissions: ['create:workorders'],
    },
    {
        component: <AddWorkOrderView />,
        path: '/workorders/create/facility/:facilityId',
        requiredPermissions: ['create:workorders'],
    },
    {
        component: <EditWorkOrderView />,
        path: '/workorders/edit/:id',
        requiredPermissions: ['edit:workorders'],
    },
    {
        component: <EditWorkOrderView />,
        path: '/workorders/edit/:id/client/:clientId',
        requiredPermissions: ['edit:workorders'],
    },
    {
        component: <EditWorkOrderView />,
        path: '/workorders/edit/:id/facility/:facilityId',
        requiredPermissions: ['edit:workorders'],
    },
    {
        component: <AddWarrantyView />,
        path: 'clients/:clientId/warranties/create',
        requiredPermissions: ['create:warranties'],
    },
    {
        component: <AddWarrantyView />,
        path: 'clients/:clientId/facility/:facilityId/warranties/create',
        requiredPermissions: ['create:warranties'],
    },
    {
        component: <EditWarrantyView />,
        path: 'clients/:clientId/warranties/edit/:id',
        requiredPermissions: ['edit:warranties'],
    },
    {
        component: <EditWarrantyView />,
        path: 'clients/:clientId/facility/:facilityId/warranties/edit/:id',
        requiredPermissions: ['edit:warranties'],
    },
    {
        component: <RoofLeakView />,
        path: 'roofLeak/:id',
        requiredPermissions: ['read:roofLeaks'],
    },
    {
        component: <UsersListView />,
        path: '/users',
        requiredPermissions: ['nav:users', 'read:users'],
    },
    {
        component: <AddUserView />,
        path: '/users/create',
        requiredPermissions: ['create:users'],
    },
    {
        component: <EditUserView />,
        path: '/users/edit/:id',
        requiredPermissions: ['edit:users'],
    },
    {
        component: <ContractorsListView />,
        path: '/contractors',
        requiredPermissions: ['nav:contractors', 'read:contractors'],
    },
    {
        component: <ContractorDashboard />,
        path: '/contractors/:id',
        requiredPermissions: ['read:contractors'],
    },
    {
        component: <AddContractorView />,
        path: '/contractors/create',
        requiredPermissions: ['create:contractors'],
    },
    {
        component: <EditContractorView />,
        path: '/contractors/edit/:id',
        requiredPermissions: ['edit:contractors'],
    },
    {
        component: <AddContactView />,
        path: '/contractors/:contractorId/contacts/create',
        requiredPermissions: ['create:contacts'],
    },
    {
        component: <EditContactView />,
        path: '/contractors/:contractorId/contacts/edit/:id',
        requiredPermissions: ['edit:contacts'],
    },
    {
        component: <AcceptDispatchView />,
        path: '/dispatches/approve/:id',
        // requiredPermissions: ['edit:contractors'],
    },
    {
        component: <ClientReportsListView />,
        path: '/clientreports',
        requiredPermissions: ['nav:reports', 'read:reports'],
    },
    {
        component: <AddClientReportView />,
        path: '/clientreports/create',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <AddClientReportView />,
        path: '/clientreports/create/:workOrderId',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <EditClientReportView />,
        path: '/clientreports/edit/:id',
        requiredPermissions: ['edit:reports'],
    },
    {
        component: <EditClientReportView />,
        path: '/clientreports/:workOrderId/edit/:id',
        requiredPermissions: ['edit:reports'],
    },
    {
        component: <AddBinderPortfolioReportView />,
        path: '/binderPortfolioReports/create',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <AddBinderPortfolioReportView />,
        path: '/binderPortfolioReports/create/client/:clientId',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <EditBinderPortfolioReportView />,
        path: '/binderPortfolioReports/edit/:id',
        requiredPermissions: ['edit:reports'],
    },
    {
        component: <EditBinderPortfolioReportView />,
        path: '/binderPortfolioReports/edit/:id/client/:clientId',
        requiredPermissions: ['edit:reports'],
    },
    {
        component: <AddCloseOutReportView />,
        path: '/closeOutReports/create',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <AddCloseOutReportView />,
        path: '/closeOutReports/create/:workOrderId',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <EditCloseOutReportView />,
        path: '/closeOutReports/edit/:id',
        requiredPermissions: ['edit:reports'],
    },
    {
        component: <AddRoofSectionReportView />,
        path: '/clientreports/:reportId/roofsectionreport/create',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <EditRoofSectionReportView />,
        path: '/clientreports/:reportId/roofsectionreport/edit/:id',
        requiredPermissions: ['edit:reports'],
    },
    {
        component: <AddJsiReportView />,
        path: '/jsireports/create',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <AddJsiReportView />,
        path: '/jsireports/create/:workOrderId',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <EditJsiReportView />,
        path: '/jsireports/edit/:id',
        requiredPermissions: ['edit:reports'],
    },
    {
        component: <EditJsiReportView />,
        path: '/jsireports/:workOrderId/edit/:id',
        requiredPermissions: ['edit:reports'],
    },
    {
        component: <ContractorHomeDashboard />,
        path: '/contractorDashboard/:id',
        requiredPermissions: ['read:contractors'],
    },
    {
        component: <SubmitDispatchView />,
        path: '/submitDispatch/:workOrderDispatchId',
        requiredPermissions: ['read:contractors'],
    },
    {
        component: <DispatchAddRoofSectionReportView />,
        path: '/submitDispatch/:workOrderDispatchId/roofSectionReport/create',
        requiredPermissions: ['create:reports'],
    },
    {
        component: <ContractorEditRoofSectionView />,
        path: '/submitDispatch/:workOrderDispatchId/roofSectionReport/:roofSectionReportId',
        requiredPermissions: ['read:contractors'],
    },
    {
        component: <DownloadsListView />,
        path: '/downloads/:workOrderId',
        requiredPermissions: ['read:downloadsAll', 'read:downloadsEmployee', 'read:downloadsClient'],
        logic: 'or'
    },
    {
        component: <DownloadsListView />,
        path: '/downloads/:workOrderId/documentFolder/:documentFolderId',
        requiredPermissions: ['read:downloadsAll', 'read:downloadsEmployee', 'read:downloadsClient'],
        logic: 'or'
    },
    {
        component: <DefaultDocumentFoldersListView />,
        path: '/defaultFolders',
        requiredPermissions: ['read:defaultDocumentFolders']
    },
    {
        component: <DocumentFoldersListView />,
        path: '/capitalprojects/edit/:workOrderId/documentFolders',
        requiredPermissions: ['read:documentFolders']
    }
];

export const publicRoutes: IRoute[] = [];
