import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { DispatchType } from '../../models/DispatchType';
import { SubmitWorkOrderDispatchForm } from './SubmitWorkOrderDispatchForm';
import { useSubmitDispatchActions } from './useSubmitDispatchActions';
import { MGOrRSReportDispatchDto } from '../../models/MGOrRSReportDispatchDto';
import { WorkOrderDispatchDto } from '../../models';
import { SubmitMGOrRSReportDispatchForm } from './SubmitMGOrRSReportDispatchForm';

export const SubmitDispatchView: FC = () => {
    const { workOrderDispatchId = "" } = useParams();
    const {
        loadedDispatch,
        isLoadingWorkOrderDispatch,
        isGetWorkOrderDispatchError,
        refetch
    } = useSubmitDispatchActions(workOrderDispatchId);

    if (isLoadingWorkOrderDispatch) {
        return <LoadingIndicator />;
    }

    if (isGetWorkOrderDispatchError || !loadedDispatch) {
        return <ApiError onReloadClick={() => refetch} />;
    }
    
    switch (loadedDispatch.type) {
        case DispatchType.CloseOut:
            return <SubmitWorkOrderDispatchForm dispatch={loadedDispatch as WorkOrderDispatchDto} />;
        case DispatchType.MGOrRSReport:
            return <SubmitMGOrRSReportDispatchForm dispatch={loadedDispatch as MGOrRSReportDispatchDto} />;
        default:
            return null;
    }
};
