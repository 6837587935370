import { FacilityDto, PhotoDto } from '../../models';
import { JsiReportChecklistItemDto } from '../../models/JsiReportChecklistItemDto';

export interface IFormProps<T> {
    save: (values: T) => void;
    cancel: () => void;
    initValues?: T;
    isEditView?: boolean;
}

export interface IFormSectionProps {
    hideBorder?: boolean;
}

export interface IReportLeakDialogProps {
    open: boolean;
    onClose: () => void;
    clientId: string;
    selectedFacility?: FacilityDto;
}

export interface Attachment {
    id: string;
    photo: PhotoDto;
    previewLink?: string;
}

export enum ReportType {
    'RoofSMART Report',
    'Moisture Guard',
    'JSI Report',
    'Binder Portfolio',
    'Close Out Report'
}
export interface IQualityAssuranceChecklistProps {
    checklistItems: JsiReportChecklistItemDto[];
    handleRadioButtonClicked: (event: React.MouseEvent<HTMLButtonElement>, idx: number, category: string) => void;
    disabled: boolean;
}
