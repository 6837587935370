import {serverApi } from '..';
import { RoofLeakDto } from '../../models';

const roofLeakApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        createRoofLeak: builder.mutation<RoofLeakDto, RoofLeakDto>({
            query: (roofLeakDto) => ({
                url: 'roofLeak',
                method: 'POST',
                body: roofLeakDto
            }),
            invalidatesTags: [{ type: 'Roof Leak' }]
        }),
        updateRoofLeak: builder.mutation<RoofLeakDto, RoofLeakDto>({
            query: (roofLeakDto) => ({
                url: 'roofLeak',
                method: 'PUT',
                body: roofLeakDto
            }),
            invalidatesTags: [{ type: 'Roof Leak' }]
        }),
        getRoofLeak: builder.query<RoofLeakDto, string>({
            query: (id) => ({ url: `roofLeak/${id}` }),
            providesTags: [{ type: 'Roof Leak' }]
        }),
        getActiveRoofLeaksForWorkOrder: builder.query<RoofLeakDto[], string>({
            query: (workOrderId) => ({ url: `roofLeak/workOrder/${workOrderId}` }),
            providesTags: [{ type: 'Roof Leak' }]
        }),
        getActiveRoofLeaksForFacility: builder.query<RoofLeakDto[], string>({
            query: (facilityId) => ({ url: `roofLeak/facility/${facilityId}` }),
            providesTags: [{ type: 'Roof Leak' }]
        }),
    })
});

export const {
    useCreateRoofLeakMutation,
    useUpdateRoofLeakMutation,
    useGetRoofLeakQuery,
    useGetActiveRoofLeaksForWorkOrderQuery,
    useGetActiveRoofLeaksForFacilityQuery,
    useLazyGetActiveRoofLeaksForFacilityQuery
} = roofLeakApi;