import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { DownloadDto, FacilityDto } from "../../models";
import { Folder, Search } from "@mui/icons-material";
import { Typography, Grid, FormControlLabel, Switch, TextField, InputAdornment, Tooltip, Link } from "@mui/material";
import { format } from "date-fns";
import { ApiError } from "../../Components/core/ApiError";
import { PaginatedProps, DataTableColumn, PaginatedDataTable } from "../../Components/core/DataTable";
import LoadingIndicator from "../../Components/core/LoadingIndicator";
import AuthenticatedComponent from "../../auth";
import { useLazyGetBPReportPdfQuery } from "../../store/apis/binder-portfolio-report-api";
import { useLazyGetCloseOutReportPdfQuery } from "../../store/apis/close-out-report-api";
import { useLazyGetDocumentFileLinkQuery } from "../../store/apis/document-api";
import { useGetDownloadsQuery } from "../../store/apis/downloads-api";
import { useLazyGetJsiReportPdfQuery } from "../../store/apis/jsi-report-api";
import { useLazyGetReportPdfQuery } from "../../store/apis/report-api";
import { useParams } from "react-router-dom";
import { useLazyGetWorkOrderQuery } from "../../store/apis/work-order-api";
import { NavBreadcrumbs } from "../../Components/core/NavBreadcrumbs";
import { useLazyGetDocumentFolderQuery } from "../../store/apis/document-folder-api";

export interface IDownloadsListViewProps {
    clientId?: string;
    facility?: FacilityDto;
}

export const DownloadsListView: FunctionComponent<IDownloadsListViewProps> = (props) => {
    const { clientId, facility } = props;
    const { workOrderId, documentFolderId } = useParams();
    const [searchText, setSearchText] = useState('');
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [showInactive, setShowInactive] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [documentToDownload, setDocumentToDownload] = useState<string>();
    const [downloadFile, setDownloadFile] = useState(false);
    const [getDocumentFolder, { data: documentFolder }] = useLazyGetDocumentFolderQuery();
    const [getCapitalProject, { data: capitalProject }] = useLazyGetWorkOrderQuery();
    const { data, isLoading, error, refetch } = useGetDownloadsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        downloadType: '',
        clientId: clientId,
        facilityId: facility?.id,
        workOrderId: workOrderId,
        downloadFolderId: documentFolderId
    });

    const [getBinderPortfolioReportPdf] = useLazyGetBPReportPdfQuery();
    const [getCloseOutReportPdf] = useLazyGetCloseOutReportPdfQuery();
    const [getJsiReportPdf] = useLazyGetJsiReportPdfQuery();
    const [getReportPdf] = useLazyGetReportPdfQuery();
    const [getDocument, { data: fileDownloadLink, isLoading: fileDownloadLinkLoading }] = useLazyGetDocumentFileLinkQuery();

    useEffect(() => {
        if (workOrderId && !capitalProject) {
            getCapitalProject(workOrderId);
        }
    }, [capitalProject, getCapitalProject, workOrderId]);

    useEffect(() => {
        if (documentFolderId && !documentFolder) {
            getDocumentFolder(documentFolderId);
        }
    }, [documentFolder, documentFolderId, getDocumentFolder]);

    useEffect(() => {
        if (documentToDownload) {
            getDocument(documentToDownload);
        }
    }, [documentToDownload, getDocument]);

    useEffect(() => {
        if (fileDownloadLink && !fileDownloadLinkLoading && downloadFile) {
            const link = document.createElement('a');
            link.href = fileDownloadLink.link;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadFile(false);
        }
    }, [
        fileDownloadLink,
        fileDownloadLinkLoading,
        downloadFile,
        setDownloadFile
    ]);

    const handleDownloadPdf = useCallback((id: string, downloadType?: string) => {
        if (downloadType === "JSI Report") {
            getJsiReportPdf(id).then((response) => {
                if (!response.isError && response.data) {
                    if (response.data.downloadUrl) {
                        window.open(response.data.downloadUrl);
                    }
                }
            });
        } else if (downloadType === 'Binder Portfolio') {
            getBinderPortfolioReportPdf(id).then((response) => {
                if (!response.isError && response.data) {
                    if (response.data.downloadUrl) {
                        window.open(response.data.downloadUrl);
                    }
                }
            });
        } else if (downloadType === 'Close Out Report') {
            getCloseOutReportPdf(id).then((response) => {
                if (!response.isError && response.data) {
                    if (response.data.downloadUrl) {
                        window.open(response.data.downloadUrl);
                    }
                }
            });
        } else if (downloadType === 'Document') {
            setDownloadFile(true);
            setDocumentToDownload(id);
        } else {
            getReportPdf(id).then((response) => {
                if (!response.isError && response.data) {
                    if (response.data.downloadUrl) {
                        window.open(response.data.downloadUrl);
                    }
                }
            });
        }
    }, [getBinderPortfolioReportPdf, getCloseOutReportPdf, getJsiReportPdf, getReportPdf]);

    const tableColumns: DataTableColumn<DownloadDto>[] = useMemo(() => {
        let columns = [] as DataTableColumn<DownloadDto>[];
        columns.push({
            key: 'name',
            label: 'Name',
            sortKey: 'NAME',
            fieldMapper: (row) =>
            (row.sourceName ? <Link sx={{ cursor: 'pointer' }} onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleDownloadPdf(row.id, row.downloadType);
            }}
            >
                {row.name}
            </Link> : <Tooltip title='No download link'><Typography>{row.name ?? 'N/A'}</Typography></Tooltip>)
        });

        columns.push({ key: 'workOrderNumber', label: 'Work Order #', sortKey: 'WO_NUMBER' });

        columns = columns.concat([
            { key: 'description', label: 'Description', sortKey: 'DESCRIPTION' },
            {
                key: 'roofSections',
                label: 'Roof Section(s)',
                sortKey: 'ROOF_SECTIONS',
                unsorted: true,
                fieldMapper: (row) => (row.roofSections ? row.roofSections : 'None'),
            },
            {
                key: 'uploaded',
                label: 'Uploaded',
                sortKey: 'UPLOADED',
                fieldMapper: (row) => (row.uploaded ? format(new Date(row.uploaded.toString()), 'M/d/yyyy') : ''),
            }
        ]);
        return columns;
    }, [handleDownloadPdf]);

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }
    return (
        <Grid container direction='column' spacing={3}>
            {capitalProject && <Grid item container direction='row' xs={12}>
                <Grid item container direction='column' justifyContent='start' xs={12}>
                    <Grid item container spacing={1}>
                        <Grid item>
                            <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                                <Folder />{documentFolder?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <NavBreadcrumbs links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Clients', navLink: '/clients' },
                            { label: capitalProject?.facilityClientName ?? '', navLink: `/clients/${capitalProject?.facilityClientId}` },
                            { label: capitalProject?.facilityName ?? '', navLink: `/clients/${capitalProject?.facilityClientId}/facility/${capitalProject?.facilityId}` },
                            { label: `Capital Project #${capitalProject?.woNumber ?? ''}`, navLink: `/capitalProjects/edit/${capitalProject?.id}/documentFolders` }

                        ]} currentPageLabel={`${documentFolder?.name}`} />
                    </Grid>
                </Grid>
            </Grid>}
            <Grid item container direction='row' alignItems='start'>
                <Grid item container direction='row' alignItems='center' justifyContent='end' xs={12} spacing={3}>
                    <AuthenticatedComponent
                        requiredPermissions={['read:downloadsAll']}
                        component={
                            <Grid item>
                                <FormControlLabel
                                    control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                    label='Show Inactive'
                                    labelPlacement='start'
                                />
                            </Grid>
                        }
                    />
                    <Grid item alignSelf={'end'}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='WO_NUMBER'
                    defaultSortDesc={true}
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:reports'] }}
                    disableSelection
                />
            </Grid>
        </Grid>
    );
};