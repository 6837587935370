import { serverApi } from '..';
import { CloudStorageLink } from '../../models';

const cloudStorageApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getSasLink: builder.query<CloudStorageLink, void>({
            query: () => ({ url: 'cloudstorage' })
        })
    })
});

export const {
    useGetSasLinkQuery,
    useLazyGetSasLinkQuery
} = cloudStorageApi;