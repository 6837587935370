import { BaseDto } from './BaseDto';

export interface RoofConditionDto extends BaseDto {
    rating: string;
    colorCode: string;
    recommendedAction: string;
    category: BudgetCategory;
    order?: number;
}

export enum BudgetCategory {
    Budget,
    CapitalProject
}