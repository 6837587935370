import { WorkOrderStatusDto } from "../../../models";

export class StatusConverterMap {
    static convert(status: string): string {
        switch (status) {
            case '':
                return '';
            case 'In Progress':
                return 'Pending';
            case 'Scheduled':
            case 'Complete':
                return status;
            default:
                return 'In Progress';
        }
    }

    static getWorkOrderStatusMappedValues(): WorkOrderStatusDto[] {
        return [
            { id: 'd98c1dd4-8f25-4013-91eb-e33635810c8a', name: 'In Progress', isActive: true },
            { id: '8e2e9143-3b1b-4d4c-8f5b-4fc5f68e11e7', name: 'Scheduled', isActive: true },
            { id: 'f685d5da-25b2-4b2e-85a2-0a4f15d75dcd', name: 'Complete', isActive: true },
            { id: 'f1b7b510-5a98-4743-b1eb-85d7f17f3d2f', name: 'Pending', isActive: true }
        ];
    };
}