import styled from '@emotion/styled';
import { ReactElement } from 'react';
import MuiPaper from '@mui/material/Paper';
export interface IDashboardCardProps {
    headerTitle: string;
    headerIcon: ReactElement;
}

export const StyledDashboardCard = styled(MuiPaper)(() => ({
    height: '100%',
    width: '100%',
    border: 'solid 1px #AAAAAA',
    '& h3': {
        marginLeft: '24px',
        marginTop: '24px',
    },
}));