import { serverApi } from '..';
import { DownloadDto, QueryResultsDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';
import { DownloadsSearchQueryParams } from '../types';

const DownloadsApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDownloads: builder.query<QueryResultsDto<DownloadDto>, DownloadsSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('downloads', params),
            providesTags: [{ type: 'Report' }, { type: 'Document' }],
        }),
    }),
});

export const { useGetDownloadsQuery } = DownloadsApi;
