import { FC, useCallback } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { RemoveCircle, ReportProblem } from '@mui/icons-material';
import { IWorkOrderDispatchRoofLeaksCardProps } from './types';
import { DashboardCard } from '../../Components/Dashboard';
import { NoMapFoundError } from '../../Components/core/NoMapFoundError';
import { SvgEditor, SvgWrapper } from '../../Components/svg';
import React from 'react';
import { FormInput } from '../../Components/Forms/FormFields';
import { useSnackbar } from 'notistack';

export const WorkOrderDispatchRoofLeaksCard: FC<IWorkOrderDispatchRoofLeaksCardProps> = React.memo(props => {
    const { roofMap, roofLeaks, removeRoofLeak, addRoofLeak, roofLeakDetailsChanged, clearRoofLeaks, disabled, openRoofLeakModifierDialog } = props;
    const { enqueueSnackbar } = useSnackbar();

    const handleRemoveRoofLeak = useCallback((idx: number) => () => {
        removeRoofLeak(idx);
    }, [removeRoofLeak]);

    const handleClearAllRoofLeaks = useCallback(() => {
        clearRoofLeaks();
    }, [clearRoofLeaks]);

    const handleAddRoofLeak = useCallback((x: number, y: number, roofSectionId?: string | null) => {
        if (roofSectionId) {
            addRoofLeak(x, y, roofSectionId);
        } else {
            enqueueSnackbar('Roof Section is not mapped. Cannot place a roof leak here.', { variant: 'error' });
        }
    }, [addRoofLeak, enqueueSnackbar]);

    const handleOnPinClick = useCallback((event: any, idx?: number, _id?: string) => {
        if (idx !== undefined) {
            openRoofLeakModifierDialog(event, idx);
        }
    }, [openRoofLeakModifierDialog]);

    const handleRoofLeakDetailsChanged = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
        roofLeakDetailsChanged(event, idx);
    }, [roofLeakDetailsChanged]);

    return (
        <DashboardCard headerIcon={<ReportProblem />} headerTitle='Roof Leaks'>
            <Grid container direction='column' justifyContent='space-between' sx={{ padding: '25px' }}>
                <Grid item container direction='row' xs={12}>
                    <Grid item xs={9}>
                        <Grid item container direction='column' justifyContent='center'>
                            <Grid item>
                                {roofMap ? (
                                    <div style={{ maxWidth: '950px', height: '800px', cursor: 'normal' }}>
                                        <SvgWrapper>
                                            <SvgEditor
                                                svgContents={roofMap}
                                                colorSections={false}
                                                roofLeaks={roofLeaks ?? []}
                                                canReportMultipleLeaks={true}
                                                onMapSectionClick={handleAddRoofLeak}
                                                onPinClick={handleOnPinClick}
                                                disabled={disabled}
                                                width={'100%'}
                                                height={'100%'}
                                                displayLeakNotice
                                            />
                                        </SvgWrapper>
                                    </div>
                                ) : (
                                    <NoMapFoundError errorText='Not assigned to a map roof section' />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid item container direction='column' sx={{ borderLeft: '1px solid black', height: '100%', maxHeight: '800px', paddingLeft: '25px', overflowY: 'auto' }} spacing={2} wrap='nowrap'>
                            <Grid item container direction='row' justifyContent='space-between'>
                                <Grid item>
                                    <Typography variant='h3'>Roof Leaks</Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color='secondary'
                                        onClick={handleClearAllRoofLeaks}
                                        disabled={disabled}
                                    >
                                        Clear All
                                    </Button>
                                </Grid>
                            </Grid>
                            {roofLeaks.map((roofLeak, idx) => {
                                return (
                                    <Grid key={idx} item container direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                                        <Grid item xs={7}>
                                            <FormInput
                                                label={`Roof Leak #${idx + 1}`}
                                                name={`roofLeak${idx + 1}`}
                                                value={roofLeak.details}
                                                disabled={disabled}
                                                fullWidth
                                                onChange={(e) => {
                                                    handleRoofLeakDetailsChanged(e, idx);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormInput
                                                label={`Roof #`}
                                                name={`roofNumber${idx + 1}`}
                                                value={roofLeak.roofSection?.roofNumber ?? 'N/A'}
                                                disabled={true}
                                                fullWidth
                                                onChange={() => { }}
                                            />
                                        </Grid>
                                        <Grid item sx={{ paddingTop: '25px' }} xs={2}>
                                            <IconButton
                                                color='secondary'
                                                size='large'
                                                onClick={handleRemoveRoofLeak(idx)}
                                                disabled={disabled}
                                            >
                                                <RemoveCircle fontSize='inherit' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>);
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={2} xs={12}>
                    <Grid item>
                        <span>Leaks Reported: {roofLeaks?.length ?? 0}</span>
                    </Grid>
                    <Grid item>
                        {!disabled && <span>Max Remaining: {10 - (roofLeaks ?? []).length}</span>}
                    </Grid>
                </Grid>
            </Grid>
        </DashboardCard>
    );
});
