import React, { FunctionComponent, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import { AccountBox } from '@mui/icons-material';
import { UserDto, emptyGuid, ClientDto } from '../../../models';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { FormButton, FormSection, IFormProps } from '..';
import { useGetClientsQuery } from '../../../store/apis/client-api';
import { AuthRoles } from '../../core/AuthRoles';
import { isEmail } from '../../../util/isEmail';
import { ContractorDto } from '../../../models/ContractorDto';
import { useGetContractorsQuery } from '../../../store/apis/contractor-api';
import { UserFormField } from './UserFormField';
import { UserFormConfirmDialog } from './UserFormConfirmDialog';
import { FormInput } from '../FormFields';

export const UserForm: FunctionComponent<IFormProps<UserDto>> = (props) => {
    const { save, cancel, initValues } = props;
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [firstName, setFirstName] = useState(initValues ? initValues.firstName : '');
    const [lastName, setLastName] = useState(initValues ? initValues.lastName : '');
    const [client, setClient] = useState<ClientDto | undefined>(initValues ? initValues.client : undefined);
    const [clientId, setClientId] = useState(initValues ? initValues.clientId ?? '' : '');
    const [contractor, setContractor] = useState<ContractorDto | undefined>(initValues ? initValues.contractor : undefined);
    const [contractorId, setContractorId] = useState(initValues ? initValues.contractorId ?? '' : '');
    const [userRole, setUserRole] = useState(initValues ? initValues.role : '');
    const [email, setEmail] = useState(initValues ? initValues.email : '');
    const [phone, setPhone] = useState(initValues ? initValues.phoneNumber : '');
    const [fieldErrors, setFieldErrors] = useState({
        FIRST_NAME: '',
        LAST_NAME: '',
        USER_ROLE: '',
        EMAIL: '',
        CLIENT: '',
        CONTRACTOR: '',
    });
    const [changed, setFormChanged] = useState(false);
    const [roleChanged, setRoleChanged] = useState(false);
    const { data: clients } = useGetClientsQuery({});
    const { data: contractors } = useGetContractorsQuery({ sortKey: 'DISPATCH_ID' });
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setLastName(event.target.value);
    };

    const handleClientIdChange = (event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        updateClient(event.target.value);
    };

    const handleContractorIdChange = (event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        updateContractor(event.target.value);
    };

    const updateContractor = (id: string) => {
        setContractorId(id);
        setContractor(contractors?.pageResults.filter((x) => x.id === id)[0]);
    };

    const updateClient = (id: string) => {
        setClientId(id);
        setClient(clients?.pageResults.filter((x) => x.id === id)[0]);
    };

    const handleUserRoleChange = (event: SelectChangeEvent<string>) => {
        setRoleChanged(true);
        setFormChanged(true);
        setUserRole(event.target.value);
        if (event.target.value !== AuthRoles.ClientManager) {
            updateClient('');
        }
        if (event.target.value !== AuthRoles.Contractor) {
            updateContractor('');
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPhone(event.target.value);
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'FIRST_NAME') {
            if (firstName) {
                fieldErrors.FIRST_NAME = '';
                isValid = true;
            } else {
                fieldErrors.FIRST_NAME = 'First name is required';
                isValid = false;
            }
        } else if (fieldName === 'LAST_NAME') {
            if (lastName) {
                fieldErrors.LAST_NAME = '';
                isValid = true;
            } else {
                fieldErrors.LAST_NAME = 'Last name is required';
                isValid = false;
            }
        } else if (fieldName === 'USER_ROLE') {
            if (userRole) {
                fieldErrors.USER_ROLE = '';
                isValid = true;
            } else {
                fieldErrors.USER_ROLE = 'User Role is required';
                isValid = false;
            }
        } else if (fieldName === 'EMAIL') {
            if (email && isEmail(email)) {
                fieldErrors.EMAIL = '';
                isValid = true;
            } else if (!email) {
                fieldErrors.EMAIL = 'Email is required';
                isValid = false;
            } else {
                fieldErrors.EMAIL = 'Must be a valid email address';
                isValid = false;
            }
        } else if (fieldName === 'CLIENT') {
            if (clientId || userRole !== AuthRoles.ClientManager) {
                fieldErrors.CLIENT = '';
                isValid = true;
            } else {
                fieldErrors.CLIENT = 'Client required for the selected user role.';
                isValid = false;
            }
        } else if (fieldName === 'CONTRACTOR') {
            if (contractorId || (userRole !== AuthRoles.Contractor && userRole !== AuthRoles.EmployeeContractor)) {
                fieldErrors.CONTRACTOR = '';
                isValid = true;
            } else {
                fieldErrors.CONTRACTOR = 'Contractor required for the selected user role.';
                isValid = false;
            }
        }
        setFieldErrors({
            FIRST_NAME: fieldErrors.FIRST_NAME,
            LAST_NAME: fieldErrors.LAST_NAME,
            USER_ROLE: fieldErrors.USER_ROLE,
            EMAIL: fieldErrors.EMAIL,
            CLIENT: fieldErrors.CLIENT,
            CONTRACTOR: fieldErrors.CONTRACTOR,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('FIRST_NAME');
        isValid = validate('LAST_NAME') && isValid;
        isValid = validate('USER_ROLE') && isValid;
        isValid = validate('EMAIL') && isValid;
        isValid = validate('CLIENT') && isValid;
        isValid = validate('CONTRACTOR') && isValid;
        return isValid;
    };
    const handleSaveAttempt = (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (formIsValid()) {
            if (roleChanged) {
                setDialogOpen(true);
            } else {
                saveUser();
            }
        }
    };

    const saveUser = () => {
        save({
            id: initValues ? initValues.id : emptyGuid,
            isActive: isActive,
            firstName: firstName,
            lastName: lastName,
            clientId: client?.id,
            contractorId: contractor?.id,
            role: userRole,
            email: email,
            phoneNumber: phone,
            displayName: firstName + ' ' + lastName,
            auth0UserId: initValues ? initValues.auth0UserId : '',
        });
        setFormChanged(false);
    };

    const handleCancel = () => {
        cancel();
    };

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSaveAttempt}>
            <Grid position='sticky' item container direction='row' alignItems='center' zIndex={5}>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <AccountBox /> {firstName && lastName ? firstName + ' ' + lastName : 'New User'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Admin', navLink: '', isText: true },
                            { label: 'Users', navLink: '/users' },
                        ]}
                        currentPageLabel={firstName && lastName ? firstName + ' ' + lastName : 'New User'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        {changed || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSaveAttempt}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                value={firstName}
                                onChange={handleFirstNameChange}
                                onBlur={onFieldBlur('FIRST_NAME')}
                                label='First Name'
                                error={fieldErrors.FIRST_NAME !== ''}
                                errorText={fieldErrors.FIRST_NAME}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                value={lastName}
                                onChange={handleLastNameChange}
                                onBlur={onFieldBlur('LAST_NAME')}
                                label='Last Name'
                                error={fieldErrors.LAST_NAME !== ''}
                                errorText={fieldErrors.LAST_NAME}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Email'
                                onBlur={onFieldBlur('EMAIL')}
                                value={email}
                                fullWidth
                                required
                                error={fieldErrors.EMAIL !== ''}
                                errorText={fieldErrors.EMAIL}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Phone'
                                value={phone}
                                onBlur={onFieldBlur('PHONE')}
                                fullWidth
                                onChange={handlePhoneChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl error={fieldErrors.USER_ROLE !== ''} fullWidth required>
                                <FormLabel>User Role</FormLabel>
                                <Select value={userRole} onChange={handleUserRoleChange}>
                                    <MenuItem key='none' value=''>
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={AuthRoles.Admin}>{AuthRoles.Admin}</MenuItem>
                                    <MenuItem value={AuthRoles.Contractor}>{AuthRoles.Contractor}</MenuItem>
                                    <MenuItem value={AuthRoles.ClientManager}>{AuthRoles.ClientManager}</MenuItem>
                                    <MenuItem value={AuthRoles.Employee}>{AuthRoles.Employee}</MenuItem>
                                    <MenuItem value={AuthRoles.EmployeeContractor}>{AuthRoles.EmployeeContractor}</MenuItem>
                                </Select>
                                <FormHelperText>{fieldErrors.USER_ROLE}</FormHelperText>
                            </FormControl>
                        </Grid>
                        {dialogOpen && <UserFormConfirmDialog open={dialogOpen} close={() => setDialogOpen(false)} userRole={userRole} save={saveUser} />}
                        {userRole === AuthRoles.Contractor || userRole === AuthRoles.EmployeeContractor ? (
                            <UserFormField
                                expectedRoles={[AuthRoles.Contractor, AuthRoles.EmployeeContractor]}
                                label={'Contractor'}
                                clientId={contractorId}
                                fieldError={fieldErrors.CONTRACTOR}
                                userRole={userRole}
                                options={contractors!}
                                handleChange={handleContractorIdChange}></UserFormField>
                        ) : (
                            <></>
                        )}
                        {userRole === AuthRoles.ClientManager ? (
                            <UserFormField
                                expectedRoles={[AuthRoles.ClientManager]}
                                label={'Client'}
                                clientId={clientId}
                                fieldError={fieldErrors.CLIENT}
                                userRole={userRole}
                                options={clients!}
                                handleChange={handleClientIdChange}></UserFormField>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
