import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { ClientForm } from '../../Components/Forms/ClientForm';
import { ClientDto } from '../../models';
import { useGetClientQuery, useUpdateClientMutation } from '../../store/apis/client-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';

export const EditClientView: FunctionComponent = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetClientQuery(id!);
    const [updateClient, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateClientMutation();

    useFailedUpdateSnackbar('client', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Client', isUpdateSuccess, () => {
        navigate(`/clients/${id}`);
    });

    const handleSave = (values: ClientDto) => {
        updateClient(values);
    };

    const handleCancel = () => {
        navigate(`/clients/${id}`);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <ClientForm save={handleSave} cancel={handleCancel} initValues={data} />;
};
