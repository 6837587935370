import { serverApi } from '..';
import { ClientDto, QueryResultsDto } from '../../models';
import { FacilitySummaryDto } from '../../models/FacilitySummaryDto';
import { buildSearchQueryRoute } from '../../util';
import { buildClientFacilitySummaryQueryRoute } from '../../util/buildClientFacilitySummaryQueryRoute';
import { ClientFacilitySummaryParams, SearchQueryParams } from '../types';

const clientApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getClients: builder.query<QueryResultsDto<ClientDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('client', params),
            providesTags: [{ type: 'Client' }],
        }),
        getClient: builder.query<ClientDto, string>({
            query: (id) => ({ url: `client/${id}` }),
            providesTags: [{ type: 'Client' }],
        }),
        getClientFacilitySummary: builder.query<FacilitySummaryDto, ClientFacilitySummaryParams>({
            query: (params) => buildClientFacilitySummaryQueryRoute(`client/${params.clientId}/facilitySummary`, params.facilityId),
            providesTags: [{ type: 'Client' }, { type: 'Roof Section' }, { type: 'Facility' }, { type: 'Roof Profile' }],
        }),
        getGlobalFacilitySummary: builder.query<FacilitySummaryDto, void>({
            query: (_) => ({ url: 'client/facilitySummary' }),
            providesTags: [{ type: 'Client' }, { type: 'Roof Section' }, { type: 'Facility' }, { type: 'Roof Profile' }],
        }),
        createClient: builder.mutation<ClientDto, ClientDto>({
            query: (clientDto) => ({
                url: 'client',
                method: 'POST',
                body: clientDto,
            }),
            invalidatesTags: [{ type: 'Client' }],
        }),
        updateClient: builder.mutation<void, ClientDto>({
            query: (clientDto) => ({
                url: 'client',
                method: 'PUT',
                body: clientDto,
            }),
            invalidatesTags: [{ type: 'Client' }],
        }),
        archiveClient: builder.mutation<void, string>({
            query: (id) => ({
                url: `client/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Client' }],
        }),
        unarchiveClient: builder.mutation<void, string>({
            query: (id) => ({
                url: `client/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Client' }],
        }),
    }),
});

export const {
    useGetClientsQuery,
    useGetClientQuery,
    useLazyGetClientFacilitySummaryQuery,
    useLazyGetGlobalFacilitySummaryQuery,
    useCreateClientMutation,
    useUpdateClientMutation,
    useArchiveClientMutation,
    useUnarchiveClientMutation,
} = clientApi;
