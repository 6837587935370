import { Build, InsertChart } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticatedComponent from '../../auth';
import { NavBreadcrumbs } from '../../Components/core/NavBreadcrumbs';
import { DashboardCard } from '../../Components/Dashboard';
import { useGetContractorQuery } from '../../store/apis/contractor-api';
import { ContractorMetrics } from '../../Components/Forms/ContractorForm/ContractorMetrics';

export const ContractorDashboard: FunctionComponent = () => {
    const { id } = useParams();
    const { data: contractor } = useGetContractorQuery(id!);

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start' justifyContent='space-between'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Build /> {contractor?.name}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Contractors', navLink: '/contractors' },
                        ]}
                        currentPageLabel={contractor?.name!}
                    />
                </Grid>
            </Grid>
            <Grid item container direction='row' sx={{ marginTop: '16px' }}>
                <Grid item xs={12}>
                    <AuthenticatedComponent
                        requiredPermissions={['read:contractors']}
                        component={
                            <DashboardCard headerTitle='Metric Reporting' headerIcon={<InsertChart />}>
                                <ContractorMetrics contractorId={contractor?.id!} />
                            </DashboardCard>
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
