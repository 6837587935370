import { styled } from '@mui/material';

export const SvgStyledWrapper = styled('div')(() => ({
    polyline: {
        fill: 'white',
        stroke: 'black',
        strokeWidth: '0.25px',
        fillOpacity: '100%',
    },
    text: {
        fillOpacity: '100%',
    },
    circle: {
        cursor: 'pointer',
        fill: 'red',
        stroke: 'black',
    },
    '.selected': {
        stroke: 'black',
        strokeWidth: '3px',
    },
    '.maintainable': {
        fill: '#21923E',
    },
    '.good': {
        fill: '#0378C8',
    },
    '.fair-to-good': {
        fill: '#FFF836',
    },
    '.fair-to-poor': {
        fill: '#553974',
    },
    '.poor': {
        fill: '#F2642C',
    },
    '.failed': {
        fill: '#EC0824',
    },
    '.under-warranty-needs-repair': {
        fill: '#575E56',
    },
}));
