import React, { FC, useCallback, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { Assignment, Cancel, ThumbUp } from '@mui/icons-material';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { FormButton, FormSection } from '..';
import { DispatchInformation } from '../WorkOrderForm/WorkOrderDispatch/DispatchInformation';
import { DateTimePicker, FormInput } from '../FormFields';
import { DispatchDto } from '../../../models/DispatchDto';
import { DispatchAcceptQueryParams, DispatchRejectionQueryParams } from '../../../store';

type ActionOption = 'accept' | 'reject';

export interface IDispatchFormProps {
    initValues?: DispatchDto;
    accept: (params: DispatchAcceptQueryParams) => void;
    reject: (params: DispatchRejectionQueryParams) => void;
}

export const AcceptDispatchForm: FC<IDispatchFormProps> = ({ accept, initValues, reject, }) => {
    const [reason, setReason] = useState('');
    const [scheduledDate, setScheduledDate] = useState<Date | null | undefined>();

    const [actionOption, setAcceptedSelected] = useState<ActionOption>('accept');
    const [fieldErrors, setFieldErrors] = useState({
        REASON: '',
        SCHEDULED_DATE: ''
    });

    const validate = useCallback((fieldName: string) => {
        let isValid = false;
        if (fieldName === 'REASON') {
            if ((reason && reason !== '') || actionOption !== 'reject') {
                fieldErrors.REASON = '';
                isValid = true;
            } else {
                fieldErrors.REASON = 'Reason for Rejection is required to reject work order';
                isValid = false;
            }
        } else if (fieldName === 'SCHEDULED_DATE') {
            if ((scheduledDate && scheduledDate.toString() !== 'Invalid Date') || actionOption !== 'accept') {
                fieldErrors.SCHEDULED_DATE = '';
                isValid = true;
            } else {
                fieldErrors.SCHEDULED_DATE = 'Scheduled Date is required to accept work order';
                isValid = false;
            }
        }
        setFieldErrors({
            REASON: fieldErrors.REASON,
            SCHEDULED_DATE: fieldErrors.SCHEDULED_DATE,
        });
        return isValid;
    }, [actionOption, fieldErrors, reason, scheduledDate]);

    const formIsValid = () => {
        let isValid = validate('REASON');
        isValid = isValid && validate('SCHEDULED_DATE');
        return isValid;
    };

    const handleAccept = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            accept(
                {
                    scheduledDate: scheduledDate!.toISOString(),
                    id: initValues!.id,
                }
            );
        }
    };

    const handleReject = (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (formIsValid()) {
            reject({
                id: initValues!.id,
                rejectionReason: reason
            });
        }
    };

    const handleActionOptionChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setAcceptedSelected(value as ActionOption);
        formIsValid();
    };

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off'>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Assignment /> {`Work Order #${initValues?.woNumber} - ${initValues?.typeName}`}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                        ]}
                        currentPageLabel={`Work Order #${initValues?.woNumber}`}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} >
                <FormSection >
                    <Grid item container direction='column' >
                        <DispatchInformation dispatch={initValues} hideWONumber={true} />
                    </Grid>
                    <Grid item container direction='row' spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <RadioGroup value={actionOption} onChange={handleActionOptionChange} row>
                                <FormControlLabel value='accept' control={<Radio />} label='Accept' />
                                <FormControlLabel value='reject' control={<Radio />} label='Reject' />
                            </RadioGroup>
                        </Grid>
                        {actionOption === 'accept' ? <>
                            <Grid item xs={2}>
                                <FormControl fullWidth >
                                    <DateTimePicker
                                        onBlur={() => validate('SCHEDULED_DATE')}
                                        errorText={fieldErrors.SCHEDULED_DATE}
                                        label='Scheduled Date'
                                        value={scheduledDate}
                                        onChange={(date) => {
                                            setScheduledDate(date);
                                        }}
                                        onAcceptDate={() => setFieldErrors({ ...fieldErrors, SCHEDULED_DATE: '' })}
                                        dateOnly={true}
                                        required
                                        error={fieldErrors.SCHEDULED_DATE !== ''}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} marginTop={2.5} >
                                <FormButton variant='contained' color='secondary' type='submit' startIcon={<ThumbUp />}
                                    onClick={handleAccept}
                                >
                                    Accept
                                </FormButton>
                            </Grid>
                        </> : <>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Reason for Rejection'
                                    onBlur={() => validate('REASON')}
                                    value={reason}
                                    fullWidth
                                    required
                                    error={fieldErrors.REASON !== ''}
                                    errorText={fieldErrors.REASON}
                                    onChange={(e) => { setReason(e.target.value); }}
                                />
                            </Grid>
                            <Grid item marginTop={2.5} >
                                <FormButton
                                    variant='contained'
                                    color='error'
                                    type='submit'
                                    startIcon={<Cancel />}
                                    onClick={handleReject}
                                >
                                    Reject
                                </FormButton>
                            </Grid>
                        </>}
                    </Grid>
                </FormSection>
            </Grid>
        </Grid >
    );
};
