import { AddCircle, Assignment, Delete, FilterList, MoreVert, Search } from '@mui/icons-material';
import { Chip, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissionChecker } from '../../../Hooks';
import { useWorkOrderExporter } from '../../../Hooks/useWorkOrderExporter';
import AuthenticatedComponent from '../../../auth';
import { ClientDto, FacilityDto, WorkOrderStatusDto, WorkOrderTypeDto } from '../../../models';
import { ContractorDto } from '../../../models/ContractorDto';
import {
    useArchiveWorkOrderMutation,
    useGetClientWorkOrdersQuery,
    useLazyGetClientWorkOrdersQuery,
    useUnarchiveWorkOrderMutation,
} from '../../../store/apis/work-order-api';
import { formatCurrency, getStandardWorkOrderTableColumns } from '../../../util';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { exportToCsv } from '../../../util/fileDownloadHelper';
import { ApiError } from '../../core/ApiError';
import { PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { DashboardCard } from '../DashboardCard';
import { FilterWorkOrdersAndCapitalProjectsDialog } from './FilterWorkOrdersAndCapitalProjectsDialog';
import { IClientCardProps } from './types';

export interface IWorkOrdersCard extends IClientCardProps {
    isClientDashboardView?: boolean;
}

export const WorkOrdersCard: FunctionComponent<IWorkOrdersCard> = (props) => {
    const { clientId, facility, isClientDashboardView } = props;
    const navigate = useNavigate();
    const { userHasAnyPermission, userHasPermission } = usePermissionChecker();
    const [searchText, setSearchText] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [showInactive, setShowInactive] = useState(false);
    const [statusFilter, setStatusFilter] = useState<WorkOrderStatusDto>();
    const currentDate = useMemo(() => new Date(), []);
    const [dispatchDateAfterFilter, setDispatchDateAfterFilter] = useState<Date | null | undefined>(new Date(currentDate.getFullYear(), 0, 1));
    const [dispatchDateBeforeFilter, setDispatchDateBeforeFilter] = useState<Date | null | undefined>(currentDate);
    const [contractorFilter, setContractorFilter] = useState<ContractorDto>();
    const [clientFilter, setClientFilter] = useState<ClientDto | null>();
    const [facilityFilter, setFacilityFilter] = useState<FacilityDto | null>();
    const [typeFilter, setTypeFilter] = useState<WorkOrderTypeDto>();
    const [filterOpen, setFilterOpen] = useState(false);
    const { data, isLoading, error, refetch } = useGetClientWorkOrdersQuery({
        parentId: clientId,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
            orderType: 0,
            status: statusFilter?.id,
            facilityId: facility?.id ?? facilityFilter?.id,
            dispatchDateAfter: dispatchDateAfterFilter?.toISOString(),
            dispatchDateBefore: dispatchDateBeforeFilter?.toISOString(),
            contractorId: contractorFilter?.id,
            isClientDashboardView: isClientDashboardView,
            workOrderTypeId: typeFilter?.id
        },
    });
    const [getWorkOrdersForExport] = useLazyGetClientWorkOrdersQuery();
    const [archiveWorkOrder, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveWorkOrderMutation();
    const [unarchiveWorkOrder, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveWorkOrderMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const userCanViewWorkOrderCosts = useMemo(() => userHasPermission('read:workOrderCosts'), [userHasPermission]);
    const tableColumns = useMemo(() => getStandardWorkOrderTableColumns(userCanViewWorkOrderCosts, false, 'WO #'), [userCanViewWorkOrderCosts]);
    const { getExportData } = useWorkOrderExporter();

    const entityName = useMemo(() => {
        return selected.length === 1 ? 'Work Order' : 'Work Orders';
    }, [selected.length]);

    const handleActionRequestCompletion = useCallback((resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    }, []);

    useFailedActionSnackbar('inactivate', entityName, isArchiveError, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useFailedActionSnackbar('activate', entityName, isUnarchiveError, () => {
        handleActionRequestCompletion(resetUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', entityName, isArchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useSuccessfulActionSnackbar('Activated', entityName, isUnarchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetUnarchive);
    });

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) afterClose();
        },
        []
    );

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    }, []);

    const handleCreateNew = useCallback(() => {
        if (facility) {
            navigate(`/workorders/create/facility/${facility.id}`);
        } else {
            navigate(`/workorders/create/client/${clientId}`);
        }
    }, [clientId, facility, navigate]);

    const handleMenuEdit = useCallback(() => {
        const selectedProjectId = data?.pageResults[selected[0]].id;
        if (selectedProjectId) {
            if (facility) {
                navigate(`/workorders/edit/${selectedProjectId}/facility/${facility.id}`);
            } else if (clientId) {
                navigate(`/workorders/edit/${selectedProjectId}/client/${clientId}`);
            } else {
                navigate(`/workorders/edit/${selectedProjectId}`);
            }
        }
    }, [clientId, data?.pageResults, facility, navigate, selected]);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    }, []);

    const handleFilter = useCallback(() => {
        setFilterOpen(true);
    }, []);

    const handleDispatchAfterFilterDelete = useCallback(() => {
        setDispatchDateAfterFilter(undefined);
    }, []);

    const handleDispatchBeforeFilterDelete = useCallback(() => {
        setDispatchDateBeforeFilter(undefined);
    }, []);

    const handleStatusFilterDelete = useCallback(() => {
        setStatusFilter(undefined);
    }, []);

    const handleDispatchFilterDelete = useCallback(() => {
        setContractorFilter(undefined);
    }, []);

    const handleClientFilterDelete = useCallback(() => {
        setClientFilter(undefined);
    }, []);

    const handleFacilityFilterDelete = useCallback(() => {
        setFacilityFilter(undefined);
    }, []);

    const handleTypeFilterDelete = useCallback(() => {
        setTypeFilter(undefined);
    }, []);

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveWorkOrder(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [data?.pageResults, selected, unarchiveWorkOrder]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveWorkOrder(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [archiveWorkOrder, data?.pageResults, selected]);

    const canSetActive = useMemo(() => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useMemo(() => {
        return selected.some((index) => data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const handleRowEdit = useCallback(
        (id: string) => {
            if (facility) {
                navigate(`/workorders/edit/${id}/facility/${facility.id}`);
            } else if (clientId) {
                navigate(`/workorders/edit/${id}/client/${clientId}`);
            } else {
                navigate(`/workorders/edit/${id}`);
            }
        },
        [clientId, facility, navigate]
    );

    const handleExport = useCallback(async () => {
        var allOrders = await getWorkOrdersForExport({
            parentId: clientId,
            params: {
                orderType: 0,
                facilityId: facility?.id,
                dispatchDateAfter: dispatchDateAfterFilter?.toISOString(),
                dispatchDateBefore: dispatchDateBeforeFilter?.toISOString(),
                contractorId: contractorFilter?.id,
                status: statusFilter?.id,
            },
        });
        if (allOrders.data?.pageResults) {
            const { headers, data } = getExportData(allOrders.data?.pageResults, false);
            exportToCsv(data, headers, `Work Orders ${new Date().toDateString()}`);
        }
    }, [getExportData, clientId, contractorFilter?.id, dispatchDateAfterFilter, dispatchDateBeforeFilter, facility?.id, getWorkOrdersForExport, statusFilter?.id]);

    const cardTitle = useMemo(() => {
        var title = 'Work Orders';
        if (userCanViewWorkOrderCosts) {
            title += ` - Total Spend ${formatCurrency(data?.totalSpend)}`;
        }
        return title;
    }, [userCanViewWorkOrderCosts, data]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <DashboardCard headerIcon={<Assignment />} headerTitle={cardTitle}>
            <Grid container direction='column' spacing={3} sx={{ paddingTop: '24px' }}>
                <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                    <Grid item container direction='row' alignItems='center' xs={6} spacing={1} sx={{ height: '100%', paddingLeft: '12px', marginTop: '0px' }}>
                        {dispatchDateAfterFilter && (
                            <Grid item>
                                <Chip label={`Dispatch Date After: ${dispatchDateAfterFilter.toLocaleDateString()}`} color='secondary' onDelete={handleDispatchAfterFilterDelete} />
                            </Grid>
                        )}
                        {dispatchDateBeforeFilter && (
                            <Grid item>
                                <Chip label={`Dispatch Date Before: ${dispatchDateBeforeFilter.toLocaleDateString()}`} color='secondary' onDelete={handleDispatchBeforeFilterDelete} />
                            </Grid>
                        )}
                        {statusFilter && (
                            <Grid item>
                                <Chip label={`Status: ${statusFilter.name}`} color='secondary' onDelete={handleStatusFilterDelete} />
                            </Grid>
                        )}
                        {contractorFilter && (
                            <Grid item>
                                <Chip label={`Dispatch ID: ${contractorFilter.dispatchId}`} color='secondary' onDelete={handleDispatchFilterDelete} />
                            </Grid>
                        )}
                        {clientFilter && (
                            <Grid item>
                                <Chip label={`Client: ${clientFilter.name}`} color='secondary' onDelete={handleClientFilterDelete} />
                            </Grid>
                        )}
                        {facilityFilter && (
                            <Grid item>
                                <Chip label={`Facility: ${facilityFilter.name}`} color='secondary' onDelete={handleFacilityFilterDelete} />
                            </Grid>
                        )}
                        {typeFilter && (
                            <Grid item>
                                <Chip label={`Type: ${typeFilter.name}`} color='secondary' onDelete={handleTypeFilterDelete} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container direction='row' alignItems='center' xs={6} spacing={1} justifyContent='flex-end'>
                        <AuthenticatedComponent
                            requiredPermissions={['delete:workorders']}
                            component={
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                        label='Show Inactive'
                                        labelPlacement='start'
                                    />
                                </Grid>
                            }
                        />
                        <AuthenticatedComponent
                            requiredPermissions={['delete:workorders']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title='Make Inactive'>
                                        <span>
                                            <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive}>
                                                <Delete fontSize='inherit' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                        <Grid item xs={1}>
                            <Tooltip title={'Filter'}>
                                <IconButton color='primary' size='large' onClick={handleFilter}>
                                    <FilterList fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <AuthenticatedComponent
                            requiredPermissions={['create:workorders']}
                            component={
                                <Grid item xs={1}>
                                    <Tooltip title='Add New'>
                                        <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                            <AddCircle fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                        <Grid item xs={1}>
                            <IconButton size='large' onClick={handleMenuOpen}>
                                <MoreVert fontSize='inherit' />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose()}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <AuthenticatedComponent
                                    requiredPermissions={['create:workorders']}
                                    component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                />
                                <AuthenticatedComponent
                                    requiredPermissions={['edit:workordersFull', 'edit:workordersLimited']}
                                    logic='or'
                                    component={
                                        <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                            Edit
                                        </MenuItem>
                                    }
                                />
                                <Divider />
                                <MenuItem onClick={handleMenuClose(handleFilter)}>Filter</MenuItem>
                                <MenuItem onClick={handleMenuClose(handleExport)}>Export</MenuItem>
                                <AuthenticatedComponent
                                    requiredPermissions={['delete:workorders']}
                                    component={
                                        <>
                                            <Divider />
                                            <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive}>
                                                Make Active
                                            </MenuItem>
                                            <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive}>
                                                Make Inactive
                                            </MenuItem>
                                        </>
                                    }
                                />
                            </Menu>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                id='search-box'
                                value={searchText}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Search'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <PaginatedDataTable
                        columns={tableColumns}
                        loading={isLoading}
                        queryResults={data}
                        defaultSortKey='WO_NUMBER'
                        setPagination={setPaginatedProps}
                        selected={selected}
                        setSelected={setSelected}
                        editPermissions={{ requiredPermissions: ['edit:workordersFull', 'edit:workordersLimmited'], logic: 'or' }}
                        onEdit={handleRowEdit}
                        disableSelection={!userHasAnyPermission(['create:workorders', 'edit:workordersFull', 'edit:workordersLimited', 'delete:workorders'])}
                    />
                </Grid>
            </Grid>
            {filterOpen && (
                <FilterWorkOrdersAndCapitalProjectsDialog
                    open={filterOpen}
                    close={() => setFilterOpen(false)}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    dispatchDateAfterFilter={dispatchDateAfterFilter}
                    dispatchDateBeforeFilter={dispatchDateBeforeFilter}
                    setDispatchDateAfterFilter={setDispatchDateAfterFilter}
                    setDispatchDateBeforeFilter={setDispatchDateBeforeFilter}
                    contractorFilter={contractorFilter}
                    setContractorFilter={setContractorFilter}
                    clientFilter={clientFilter}
                    setClientFilter={setClientFilter}
                    facilityFilter={facilityFilter}
                    setFacilityFilter={setFacilityFilter}
                    typeFilter={typeFilter}
                    setTypeFilter={setTypeFilter}
                    isCapitalProject={false}
                    isClientFacing={true}
                />
            )}
        </DashboardCard>
    );
};
