import { RoofLeakDto, RoofSectionDto } from "../../../models";
import { Point } from "../../Forms/RoofMap";

export interface IRoofLeakDetailsDialog {
    anchorEl: any;
    handleRoofLeakMove: (point: Point) => void;
    idx: number;
    onClose: () => void;
    onConfirm: (roofSectionId?: string) => void;
    open: boolean;
    roofLeak: RoofLeakDto;
    roofSections: RoofSectionDto[];
    showDetails?: boolean;
}

export interface IRoofLeakInfoCard {
    roofLeak: RoofLeakDto;
}

export function getShortenedString(original?: string) {
    if (original === undefined) {
        return '';
    } else if (original.length < 72) {
        return original;
    } else {
        return `${original.substring(0, 72)}...`;
    }
};