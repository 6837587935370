import { serverApi } from '..';
import { QueryResultsDto } from '../../models';
import { ContractorDto } from '../../models/ContractorDto';
import { buildSearchQueryRoute } from '../../util';
import { SearchQueryParams } from '../types';

const contractorApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getContractors: builder.query<QueryResultsDto<ContractorDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('contractor', params),
            providesTags: [{ type: 'Contractor' }]
        }),
        getContractor: builder.query<ContractorDto, string>({
            query: (contractorId) => ({ url: `contractor/${contractorId}` }),
            providesTags: [{ type: 'Contractor' }]
        }),
        createContractor: builder.mutation<ContractorDto, ContractorDto>({
            query: (contractorDto) => ({
                url: 'contractor',
                method: 'POST',
                body: contractorDto
            }),
            invalidatesTags: [{ type: 'Contractor' }]
        }),
        updateContractor: builder.mutation<void, ContractorDto>({
            query: (contractorDto) => ({
                url: 'contractor',
                method: 'PUT',
                body: contractorDto
            }),
            invalidatesTags: [{ type: 'Contractor' }]
        }),
        archiveContractor: builder.mutation<void, string>({
            query: (id) => ({
                url: `contractor/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Contractor' }]
        }),
        unarchiveContractor: builder.mutation<void, string>({
            query: (id) => ({
                url: `contractor/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: 'Contractor' }]
        })
    })
});

export const {
    useLazyGetContractorQuery,
    useGetContractorQuery,
    useCreateContractorMutation,
    useUpdateContractorMutation,
    useArchiveContractorMutation,
    useUnarchiveContractorMutation,
    useGetContractorsQuery,
} = contractorApi;