import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from './store';

export const serverApi = createApi({
    reducerPath: 'serverApi',
    keepUnusedDataFor: 5,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        },
    }),
    tagTypes: [
        'Attachment Method',
        'Binder Portfolio Report',
        'Budget Item',
        'Budget Line Item',
        'Budget',
        'Capital Project',
        'Client',
        'Contact',
        'Contractor',
        'Core Layer Type',
        'Deck Type',
        'DefaultDocumentFolder',
        'Dispatch',
        'Division',
        'Document',
        'DocumentFolder',
        'Drainage',
        'Facility',
        'Immediate Repairs Checklist Item',
        'Import',
        'Installing Contractor',
        'Insulation Thickness',
        'Insulation',
        'JSI Report Rollover Items',
        'JsiReport',
        'Leak Location',
        'Manufacturer',
        'Note',
        'Report',
        'Roof Access',
        'Roof Condition',
        'Roof Leak',
        'Roof Profile',
        'Roof Section Report',
        'Roof Section',
        'Roof System',
        'Surfacing Type',
        'User',
        'Warrantor',
        'Warranty Type',
        'Warranty',
        'Dispatch',
        'Work Order Status History',
        'Work Order Status',
        'Work Order Type',
        'Work Order',
    ],
    endpoints: () => ({}),
});
