import { serverApi } from '..';
import { QueryResultsDto } from '../../models';
import { WorkOrderStatusHistoryDto, WorkOrderStatusHistoryLineItemDto } from '../../models/workOrderStatusHistoryDto';
import { buildSearchQueryRoute } from '../../util';
import { WorkOrderStatusHistorySearchParams } from '../types';

const workOrderStatusHistoryApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkOrderStatusHistoryLogs: builder.query<QueryResultsDto<WorkOrderStatusHistoryLineItemDto>, WorkOrderStatusHistorySearchParams>({
            query: (params) => buildSearchQueryRoute('workOrderStatusHistory', params),
            providesTags: [{ type: 'Work Order Status History' }],
        }),
        getWorkOrderStatusHistoryLog: builder.query<WorkOrderStatusHistoryDto, string>({
            query: (id) => ({ url: `workOrderStatusHistory/${id}` }),
            providesTags: [{ type: 'Work Order Status History' }],
        }),
    }),
});

export const {
    useGetWorkOrderStatusHistoryLogsQuery,
    useGetWorkOrderStatusHistoryLogQuery,
    useLazyGetWorkOrderStatusHistoryLogQuery,
    useLazyGetWorkOrderStatusHistoryLogsQuery,
} = workOrderStatusHistoryApi;
