import { DragHandle, InfoOutlined } from '@mui/icons-material';
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { DragEventHandler, FC, useCallback } from 'react';

export interface ITransferListItem {
    label: string;
    value: string;
    description?: string;
}

export type AboveOrBelow = 'above' | 'below';

export interface ITransferListItemProps {
    listItem: ITransferListItem;
    onClick: (item: ITransferListItem) => void;
    isSelected: boolean;
    orderable: boolean;
    handleReorderRequest: (sourceItem: string, targetItem: string, aboveOrBelow: AboveOrBelow) => void;
    disabled?: boolean;
}

export const TransferListItem: FC<ITransferListItemProps> = ({ onClick, listItem, isSelected, orderable, handleReorderRequest, disabled }) => {
    const handleItemClicked = useCallback(() => {
        onClick(listItem);
    }, [onClick, listItem]);

    const handleOnDragStart: DragEventHandler<HTMLDivElement> = useCallback((e) => {
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.setData('text/plain', listItem.value);
    }, [listItem.value]);

    const handleOnDragOver: DragEventHandler<HTMLDivElement> = useCallback((e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    }, []);

    const handleOnDrop: DragEventHandler<HTMLDivElement> = useCallback((e) => {
        e.preventDefault();
        var coords = e.currentTarget.getBoundingClientRect();
        var dragLocation: AboveOrBelow = e.clientY - coords.y > coords.height / 2 ? 'below' : 'above';
        handleReorderRequest(e.dataTransfer.getData('text/plain'), listItem.value, dragLocation);
    }, [handleReorderRequest, listItem.value]);

    return (
        <ListItemButton
            disabled={disabled}
            role='listitem'
            onClick={handleItemClicked}
            disableRipple
            draggable
            onDragStart={handleOnDragStart}
            onDragOver={handleOnDragOver}
            onDrop={handleOnDrop}>
            <ListItemIcon sx={{ display: 'flex', alignItems: 'center' }}>
                {orderable && <DragHandle />}
                <Checkbox checked={isSelected} disableRipple disabled={disabled} />
            </ListItemIcon>
            <ListItemText id={listItem.value} primary={listItem.label} sx={{ pointerEvents: 'none' }} />
            {listItem.description && (
                <Tooltip title={listItem.description}>
                    <InfoOutlined sx={{ pointerEvents: 'none' }} />
                </Tooltip>
            )}
        </ListItemButton>
    );
};
