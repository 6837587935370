import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { createElement, MouseEvent, useEffect, useState } from 'react';
import { emptyGuid } from '../../models';
import NoMapFoundError from '../core/NoMapFoundError/NoMapFoundError';
import { ISvgProps } from './types';
import { RoofConditionIndexTable } from '../Forms/RoofMap';

export const SvgEditor: React.FunctionComponent<ISvgProps> = React.memo(props => {
    const {
        svgContents,
        selectedElement,
        onGroupSelected,
        singleFilledSection,
        colorSections,
        roofLeaks,
        canReportMultipleLeaks,
        onMapSectionClick,
        onPinClick,
        onPinHover,
        disabled,
        height = '768px',
        width = '1024px',
        superMapView = false,
        showRoofConditions = true,
        showUnderWarranty = false,
        displayLeakNotice = false,
        isPrintView = false
    } = props;
    const svgId = useMemo(() => { return `svg-${Math.random()}`; }, []);
    const [component, setComponent] = useState<Element>();
    const [isAnySectionFilled, setIsAnySectionFilled] = useState(false);

    const [mouseStartPosition] = useState({ x: 0, y: 0 });
    const [mousePosition] = useState({ x: 0, y: 0 });
    const [viewboxStartPosition] = useState({ x: 0, y: 0 });
    const [viewboxPosition] = useState({ x: 0, y: 0 });
    const [viewboxSize] = useState({ x: 1024, y: 768 });
    const [viewboxScale, setViewboxScale] = useState(1.0);

    useEffect(() => {
        const selectSection = (element: SVGGElement) => {
            if (onGroupSelected) {
                const sectionId = element.attributes.getNamedItem('id')?.value ?? '';
                onGroupSelected(sectionId);
            }
        };

        const convertDocEleToReact = (element: any, props: any) => {
            try {
                return innerFunction(element, props);
            } catch (ex) {
                return createElement('span', {}, `Error loading svg image: ${ex}`);
            }
        };

        const getColor = (element: any) => {
            if (showUnderWarranty && element.attributes.getNamedItem('underWarranty')?.value === 'True') {
                return 'under-warranty-needs-repair';
            } else if (showRoofConditions) {
                return element.attributes.getNamedItem('condition')?.value ?? '';
            } else {
                return '';
            }
        };

        const createClassString = (element: any) => {
            let selectedString = selectedElement && selectedElement === element.attributes.getNamedItem('id')?.value ? 'selected' : '';
            if (!singleFilledSection && colorSections) {
                if (setIsAnySectionFilled) {
                    setIsAnySectionFilled(true);
                }
                return `${getColor(element)} ${selectedString} ${'selectable'}`;
            }

            if (singleFilledSection?.mapSections?.some((x) => x.id === element.attributes.getNamedItem('id')?.value)) {
                if (setIsAnySectionFilled) {
                    setIsAnySectionFilled(true);
                }
                return `${getColor(element)} ${selectedString}`;
            } else {
                return `${''} ${selectedString}`;
            }
        };

        const toSVGPoint = (svg: SVGGraphicsElement, x: number, y: number) => {
            let point = new DOMPoint(x, y);
            if (svg) {
                let domMatrix = point.matrixTransform(svg.getScreenCTM()?.inverse());
                return domMatrix;
            }
        };

        const addRoofLeakPin = (element: SVGGraphicsElement, idx: number, roofLeakId?: string, x?: number, y?: number) => {
            let pt = toSVGPoint(element, x ?? 0, y ?? 0);
            let ptx = pt?.x;
            let pty = pt?.y;

            if (!ptx || !pty) {
                return;
            }

            if (!canReportMultipleLeaks) {
                let pinElement = document.getElementById('pin');

                if (pinElement) {
                    pinElement.remove();
                }
            }

            let circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            circle.setAttribute('cx', ptx?.toString() ?? '');
            circle.setAttribute('cy', pty?.toString() ?? '');
            circle.setAttribute('r', (superMapView ? 5 : 15 * viewboxScale).toString());
            circle.setAttribute('stroke-width', `${(1 * viewboxScale).toString()}px`);
            circle.setAttribute('id', `pin-${idx}`);
            circle.setAttribute('idx', idx.toString());
            if (roofLeakId && roofLeakId !== emptyGuid) {
                circle.setAttribute('roofLeakId', roofLeakId);
            }

            let text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            text.setAttribute('x', ptx.toString());
            text.setAttribute('y', (pty + (5 * viewboxScale)).toString());
            text.setAttribute('text-anchor', 'middle');
            text.setAttribute('stroke', '1px black');
            text.setAttribute('fill', 'white');
            text.setAttribute('font-family', "Lucida Sans,'sans-serif'");
            text.setAttribute('idx', idx.toString());
            text.setAttribute('font-size', (14 * viewboxScale).toString());
            text.setAttribute('id', `pin-text-${idx.toString()}`);
            text.setAttribute('class', `pin-text`);

            element.appendChild(circle);
            element.appendChild(text);
        };

        const innerFunction = (element: any, props?: any): any => {
            const tagName = element.tagName;
            let _props = { ...props, key: `${Date.now()}.${Math.random()}` };

            if (tagName === 'svg') {
                let vp = { x: 0, y: 0 };
                let vs = { x: 0, y: 0 };

                vp.x = viewboxPosition.x;
                vp.y = viewboxPosition.y;

                vs.x = viewboxSize.x * viewboxScale;
                vs.y = viewboxSize.y * viewboxScale;
                element.setAttribute('id', svgId);
                element!.setAttribute("viewBox", vp.x + " " + vp.y + " " + vs.x + " " + vs.y);
            }

            for (let i = 0; i < element.attributes.length; i++) {
                if (element.attributes[i].nodeName === 'style' || element.attributes[i].nodeName.includes(':')) {
                    continue;
                }
                const propertyName = element.attributes[i].nodeName.replace(/-([a-z])/g, (g: any) => {
                    return g[1].toUpperCase();
                });
                _props[propertyName] = element.attributes[i].nodeValue;
            }

            let children = Array.from(element.children).map((item) => innerFunction(item));

            if (tagName.toLowerCase() === 'text') {
                if (_props['idx'] && !superMapView) {
                    return createElement(tagName, {
                        ..._props,
                        onClick: (e: MouseEvent<any>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (onPinClick) {
                                onPinClick(e, parseInt(e.currentTarget.getAttribute('idx')) - 1);
                            }
                        }
                    }, _props['idx']);
                } else {
                    return createElement(tagName, _props, (element as HTMLElement).childNodes[0]?.textContent);
                }
            }
            if (tagName.toLowerCase() === 'circle') {
                return createElement(
                    tagName,
                    {
                        ..._props,
                        onMouseEnter: (e: MouseEvent<any>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (superMapView && onPinHover) {
                                onPinHover(e, parseInt(e.currentTarget.getAttribute('idx')) - 1, e.currentTarget.getAttribute('roofLeakId'));
                            }
                        },
                        onClick: (e: MouseEvent<any>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (onPinClick) {
                                onPinClick(e, parseInt(e.currentTarget.getAttribute('idx')) - 1, e.currentTarget.getAttribute('roofLeakId'));
                            }
                        }
                    },
                    children);
            }
            if (tagName.toLowerCase() !== 'polyline') {
                return createElement(tagName, _props, children);
            }

            let classString = createClassString(element);

            let roofSectionId = element.attributes.getNamedItem('roofSectionId'); // Gets RoofSectionId if the map section is mapped to a roof section.

            return createElement(
                tagName,
                {
                    ..._props,
                    onClick: (e: MouseEvent<SVGPolylineElement>) => {
                        if (!e.shiftKey) {
                            e.preventDefault();
                            e.stopPropagation();
                            if (onMapSectionClick && !disabled) {
                                const target = e.currentTarget.parentElement!;
                                const SVGParent = target.parentElement! as unknown as SVGGraphicsElement;
                                let pt = toSVGPoint(SVGParent, e.clientX ?? 0, e.clientY ?? 0);

                                onMapSectionClick(pt?.x ?? 0, pt?.y ?? 0, roofSectionId?.nodeValue);
                            } else {
                                selectSection(e.currentTarget);
                                e.stopPropagation();
                            }
                        }
                    },
                    class: classString,
                    ...(!disabled && { style: { cursor: roofSectionId || !canReportMultipleLeaks ? 'pointer' : 'auto' } }),
                },
                children
            );
        };

        const element = new DOMParser().parseFromString(svgContents ?? '', 'image/svg+xml');

        _.orderBy(roofLeaks ?? [], x => x.createdOn).forEach((roofLeak, idx) => {
            if (roofLeak.x && roofLeak.y) {
                addRoofLeakPin(element.documentElement! as unknown as SVGGraphicsElement, idx + 1, roofLeak.id, roofLeak.x, roofLeak.y);
            }
        });

        const component = convertDocEleToReact(element.documentElement, {}) as Element;

        setComponent(component);
    }, [svgContents, selectedElement, onGroupSelected, singleFilledSection, colorSections, roofLeaks, canReportMultipleLeaks, onMapSectionClick, onPinClick, disabled, showRoofConditions, superMapView, onPinHover, width, height, viewboxPosition, viewboxSize, viewboxScale, svgId, showUnderWarranty]);

    useEffect(() => {
        // This is using: https://codepen.io/mrobin604/pen/yjmrjj
        // As an example of how to implement this.
        if (component) {
            let svg = document.getElementById(svgId);
            if (svg) {
                let newMouseStartPosition = mouseStartPosition;
                let newMousePosition = mousePosition;
                let newViewboxStartPosition = viewboxStartPosition;
                let newViewboxPosition = viewboxPosition;
                let newViewboxSize = viewboxSize;
                let newViewboxScale = viewboxScale;

                let mouseDown = false;

                const mousedown = (e: any) => {
                    if (e.shiftKey) {

                        newMouseStartPosition.x = e.pageX;
                        newMouseStartPosition.y = e.pageY;

                        newViewboxStartPosition.x = newViewboxPosition.x;
                        newViewboxStartPosition.y = newViewboxPosition.y;

                        window.addEventListener("mouseup", mouseup);

                        mouseDown = true;
                    }
                };

                const setViewBox = () => {
                    let vp = { x: 0, y: 0 };
                    let vs = { x: 0, y: 0 };

                    vp.x = newViewboxPosition.x;
                    vp.y = newViewboxPosition.y;

                    vs.x = newViewboxSize.x * newViewboxScale;
                    vs.y = newViewboxSize.y * newViewboxScale;

                    svg = document.getElementById(svgId);
                    svg!.setAttribute("viewBox", vp.x + " " + vp.y + " " + vs.x + " " + vs.y);

                };

                const mousemove = (e: any) => {
                    newMousePosition.x = e.offsetX;
                    newMousePosition.y = e.offsetY;

                    if (mouseDown) {
                        newViewboxPosition.x = newViewboxStartPosition.x + (newMouseStartPosition.x - e.pageX) * newViewboxScale;
                        newViewboxPosition.y = newViewboxStartPosition.y + (newMouseStartPosition.y - e.pageY) * newViewboxScale;

                        setViewBox();
                    }
                };

                const mouseup = (e: any) => {
                    window.removeEventListener("mouseup", mouseup);

                    mouseDown = false;
                };

                const wheel = (e: any) => {
                    if (e.shiftKey) {
                        e.preventDefault();
                        e.stopPropagation();

                        let scale = (e.wheelDelta > 0) ? 0.8 : 1.2;

                        if ((newViewboxScale * scale < 8.) && (newViewboxScale * scale > 1. / 256.)) {
                            let mpos = { x: newMousePosition.x * newViewboxScale, y: newMousePosition.y * newViewboxScale };
                            let vpos = { x: newViewboxPosition.x, y: newViewboxPosition.y };
                            let cpos = { x: mpos.x + vpos.x, y: mpos.y + vpos.y };

                            newViewboxPosition.x = (newViewboxPosition.x - cpos.x) * scale + cpos.x;
                            newViewboxPosition.y = (newViewboxPosition.y - cpos.y) * scale + cpos.y;
                            newViewboxScale *= scale;

                            const circles = [].slice.call(document.getElementsByTagName('circle'));
                            const text = [].slice.call(document.getElementsByClassName('pin-text'));

                            circles.forEach((circle: Element) => {
                                circle.setAttribute('r', (superMapView ? 5 : 15 * newViewboxScale).toString());
                            });
                            text.forEach((t: Element) => {
                                t.setAttribute('font-size', (12 * newViewboxScale).toString());
                            });
                            setViewBox();
                            setViewboxScale(newViewboxScale);
                        }
                    }
                };

                svg.addEventListener("mousemove", mousemove);
                svg.addEventListener("mousedown", mousedown);
                svg.addEventListener("wheel", wheel);
            }
        }
    }, [component, mousePosition, mouseStartPosition, superMapView, svgId, viewboxPosition, viewboxScale, viewboxSize, viewboxStartPosition]);

    if (singleFilledSection && !isAnySectionFilled) {
        return (
            <Box width='50%' height='50%'>
                <NoMapFoundError errorText='Not assigned to a map roof section.' />
            </Box>
        );
    }

    if (isPrintView) {
        return (
            <Grid container direction='column'>
                <Grid item>
                    <RoofConditionIndexTable isPrintView />
                </Grid>
                <Grid item>
                    {component}
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {component || (singleFilledSection && isAnySectionFilled) ? (
                <div style={{ width: width, height: height }}>
                    <Grid container direction='column'>
                        {!disabled && displayLeakNotice && !canReportMultipleLeaks && <Grid item alignSelf='center'>
                            <Typography>Click on the map below to indicate where the leak is. (Optional)</Typography>
                        </Grid>}
                        {!disabled && displayLeakNotice && canReportMultipleLeaks && <Grid item alignSelf='center'>
                            <Typography>Click on the map below to indicate where each roof leak is (maximum 10)</Typography>
                        </Grid>}
                        <Grid item alignSelf='center'>
                            <Typography>Shift+Click to drag. Shift+Mousewheel to zoom</Typography>
                        </Grid>
                        <Grid item>
                            {component}
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <NoMapFoundError errorText='No map found' />
            )}
        </>
    );
});
