import { useMemo } from 'react';
import { useStandardSnackbar } from './useStandardSnackbar';
import { checkForCustomErrorMessage } from './utils';

export function useFailedCreateSnackbar(entityName: string, isCreateSuccessful: boolean, onSnackbarDisplay: () => void = () => {}, errorObject: any = null) {
    const message = useMemo(() => {
        const customErrorMessage = checkForCustomErrorMessage(errorObject);
        if (!!customErrorMessage) {
            return customErrorMessage;
        }
        return `An Error occurred while attempting to create ${entityName}. Please try again, if the issue persists contact technical support.`;
    }, [entityName, errorObject]);

    useStandardSnackbar(isCreateSuccessful, message, 'error', onSnackbarDisplay);
}
