import { FunctionComponent, useCallback, useState } from 'react';
import { Card, CardContent, CardHeader, Collapse, IconButton, IconButtonProps, styled, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { IDashboardCardProps } from './types';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMoreButton = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export const DashboardCard: FunctionComponent<IDashboardCardProps> = props => {
    const { headerTitle, headerIcon } = props;
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    return (
        <Card variant='outlined' sx={{ height: expanded ? '100%' : 'auto' }}>
            <CardHeader
                title={<Typography sx={{ color: '#ffffff' }} variant="h3">{headerIcon} {headerTitle}</Typography>}
                action={
                    <ExpandMoreButton
                        expand={expanded}
                        onClick={handleExpandClick}
                        size="small"
                        color="info"
                    >
                        <ExpandMore />
                    </ExpandMoreButton>
                }
                sx={{ backgroundColor: '#266490' }}
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }}>
                    {props.children}
                </CardContent>
            </Collapse>
        </Card>
    );
};