import { serverApi } from '..';
import { WarrantorDto } from '../../models';
import { buildSearchQueryRoute } from '../../util';

const warrantorApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWarrantors: builder.query<WarrantorDto[], WarrantorQueryParams>({
            query: (params) => buildSearchQueryRoute(`warrantor`, params),
            providesTags: [{ type: 'Warrantor' }]
        }),
        createWarrantor: builder.mutation<WarrantorDto, WarrantorDto>({
            query: (warrantorDto) => ({
                url: 'warrantor',
                method: 'POST',
                body: warrantorDto
            }),
            invalidatesTags: [{ type: 'Warrantor' }]
        }),
    })
});

export const {
    useGetWarrantorsQuery,
    useCreateWarrantorMutation
} = warrantorApi;

export interface WarrantorQueryParams {
    includeInactive: boolean;
}