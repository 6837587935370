import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContractorRoofSectionReportForm } from '../../Components/Forms/RoofSectionReportForm/ContractorRoofSectionReportForm';
import { ApiError } from '../../Components/core/ApiError';
import LoadingIndicator from '../../Components/core/LoadingIndicator';
import { RoofSectionReportDto } from '../../models/RoofSectionReportDto';
import { useGetMGOrRSReportDispatchRoofSectionReportQuery, useUpdateMGOrRSReportDispatchRoofSectionReportMutation } from '../../store/apis/report-dispatch-api';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../util/customHooks';
import { useGetDispatchDefaultContactIdQuery } from '../../store/apis/dispatch-api';

export const ContractorEditRoofSectionView: FC = () => {
    const { workOrderDispatchId = '', roofSectionReportId = '' } = useParams();
    const navigate = useNavigate();
    const { data: roofSectionReport, isLoading: roofSectionReportLoading, error: roofSectionReportError, refetch: roofSectionReportRefetch } = useGetMGOrRSReportDispatchRoofSectionReportQuery({ dispatchId: workOrderDispatchId, reportRoofSectionId: roofSectionReportId});
    const [updateRoofSectionReport, { isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate }] = useUpdateMGOrRSReportDispatchRoofSectionReportMutation();
    const { data: defaultContactId, isLoading: isLoadingDefaultContactId } = useGetDispatchDefaultContactIdQuery(workOrderDispatchId);

    useFailedUpdateSnackbar('roof section', isUpdateError, resetUpdate);
    useSuccessfulUpdateSnackbar('Roof section', isUpdateSuccess);

    const handleSave = (values: RoofSectionReportDto) => {
        updateRoofSectionReport({
            dispatchId: workOrderDispatchId,
            reportRoofSectionId: roofSectionReportId,
            roofSectionReport: values,
        });
    };

    const handleCancel = () => {
        navigate(`/submitDispatch/${workOrderDispatchId}`);
    };

    if (roofSectionReportLoading || isLoadingDefaultContactId) {
        return <LoadingIndicator />;
    }

    if (roofSectionReportError || !roofSectionReport) {
        return <ApiError onReloadClick={roofSectionReportRefetch} />;
    }

    return <ContractorRoofSectionReportForm save={handleSave} cancel={handleCancel} roofSectionReport={roofSectionReport} dispatchId={workOrderDispatchId} defaultContactId={defaultContactId} />;
};
